export const training = {
  typology: "",
  title: "",
  description: "",
  subject: [],
  status: "",
  alumniAccessible: false,
  duration: 0,
  sections: [
    {
      sectionTitle: "",
      sectionDuration: "",
      sectionDescription: "",
      sectionContent: [],
    },
  ],
  eventDetails: {
    location: "",
    type: "pres",
    date: new Date(),
    startTimeHour: "15",
    startTimeMinute: "00",
    endTimeHour: "18",
    endTimeMinute: "00",
    code_validation: "",
    section: {
      sectionTitle: "Contenu à télécharger",
      sectionDescription: "Description de la section Contenu à télécharger",
      files: [
        {
          type: "upload",
          file: "",
          fileType: "",
          preview: "",
          fileName: "",
          fileContentType: "",
        },
      ],
    },
  },
  feedback: {
    enable: false,
    stars: false,
    comments: false,
  },
};

export const section = {
  sectionTitle: "",
  duration: "",
  sectionDescription: "",
  sectionContent: [],
};
export const sectionContentUpload = {
  type: "upload",
  file: "",
  fileType: "",
  preview: "",
  fileName: "",
  fileContentType: "",
};
export const sectionContentText = { type: "text", html: "" };
export const sectionContentVideo = {
  type: "video",
  fileType: "link",
  fileContentType: 'link',
  link: ""
};
export const sectionContentEvalQuiz = {
  type: "eval",
  autoEval: false,
  questions: [
    { title: "", choice: "unique", answers: [{ title: "", isCorrect: false }] },
  ],
};
export const sectionContentAutoEval = {
  type: "eval",
  autoEval: true,
  themes: [{ title: "", topics: [{ title: "" }] }],
};
export const question = {
  title: "",
  choice: "",
  answers: [],
};
export const answer = {
  title: "",
};
export const GLOBAL_MEDIA_QUERIES = {
  small: "(max-width: 991px)",
  large: "(min-width: 992px)",
};

export const events = [
  {
    id: 2628,
    created_at: "2022-07-29T12:16:01.000000Z",
    name: "Dolor quo quae est iste.",
    title: null,
    subtitle: null,
    date_start: "2023-12-30T17:13:57.000000Z",
    date_end: "2024-07-22T00:29:36.000000Z",
    description: "Enim qui veritatis quo. Impedit voluptatum fugit tenetur.",
    address: "rue Lebreton",
    city: "Barre-sur-Garcia",
    postal_code: 34412,
  },
  {
    id: 2629,
    created_at: "2022-07-29T12:16:01.000000Z",
    name: "Nulla aut autem quo totam eos nobis.",
    title: null,
    subtitle: null,
    date_start: "2024-04-27T09:47:48.000000Z",
    date_end: "2024-06-08T08:20:14.000000Z",
    description:
      "Unde suscipit vitae id qui aut delectus eligendi sed. Sint dolor voluptatem unde ea.",
    address: "chemin Adrienne Coste",
    city: "Moreau",
    postal_code: 90420,
  },
  {
    id: 2628,
    created_at: "2022-07-29T12:16:01.000000Z",
    name: "Dolor quo quae est iste.",
    title: null,
    subtitle: null,
    date_start: "2023-12-30T17:13:57.000000Z",
    date_end: "2024-07-22T00:29:36.000000Z",
    description: "Enim qui veritatis quo. Impedit voluptatum fugit tenetur.",
    address: "rue Lebreton",
    city: "Barre-sur-Garcia",
    postal_code: 34412,
  },
  {
    id: 2629,
    created_at: "2022-07-29T12:16:01.000000Z",
    name: "Nulla aut autem quo totam eos nobis.",
    title: null,
    subtitle: null,
    date_start: "2024-04-27T09:47:48.000000Z",
    date_end: "2024-06-08T08:20:14.000000Z",
    description:
      "Unde suscipit vitae id qui aut delectus eligendi sed. Sint dolor voluptatem unde ea.",
    address: "chemin Adrienne Coste",
    city: "Moreau",
    postal_code: 90420,
  },
];
