import React, { useEffect, useState } from "react";
import { AiFillFileExcel, AiFillVideoCamera } from "react-icons/ai";
import {
  HiDesktopComputer,
  HiOutlineArrowNarrowLeft,
  HiOutlineArrowNarrowRight,
} from "react-icons/hi";
import { BsCalendarEvent, BsFillFileEarmarkPdfFill } from "react-icons/bs";
import { FaFilePowerpoint, FaMicrophone } from "react-icons/fa";
import { RiVoiceprintFill } from "react-icons/ri";
import { Col, Row } from "reactstrap";
import moment from "moment";
//@ts-ignore
import EllipsisText from "react-ellipsis-text";
import ReactTooltip from "react-tooltip";

import { useAppSelector } from "../../../../redux/hooks";
import {
  retrieveTrainingCatalog,
  selectTrainingCatalog,
  selectTrainingCatalogCount,
} from "../../../../redux/slices/trainingSlice";
import CatalogTable from "./CatalogTable";
import { ReactComponent as CameraIcon } from "../../../../assets/img/icons/Image.svg";
import Filters from "./Filters";

function AllCatalog(props: any) {
  const { scrollable, dispatch, setNavStep } = props;
  const trainings = useAppSelector(selectTrainingCatalog);
  const totalCount = useAppSelector(selectTrainingCatalogCount);
  const [pageNumber, setPage] = useState(1);
  const filtersData = {
    nom: "",
    sujets: [],
    createurs: [],
    datePub: {
      start: "",
      end: "",
    },
    dateTenu: {
      start: "",
      end: "",
    },
    contenu: {
      type: [],
      typeContenu: [],
    },
    entreprise: [],
  };
  const [filters, setFilters] = useState(filtersData);
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      dispatch(retrieveTrainingCatalog({ page: pageNumber, filters }));
    }, 100);
    return () => clearTimeout(delayDebounceFn);
  }, [dispatch, pageNumber, filters]);

  const columns = React.useMemo(
    () => [
      {
        Header: "",
        isSorted: false,
        width: 50,
        accessor: "publishedTrainingsIcon",
        Cell: (props: any) => {
          return props.row.original.type === "async" ? (
            <RiVoiceprintFill
              className="iconOutlined"
              size={43}
              color={"#0043c7"}
            />
          ) : props.row.original.type === "dist" ? (
            <HiDesktopComputer
              className="iconOutlined"
              size={43}
              color={"#0043c7"}
            />
          ) : (
            <BsCalendarEvent
              className="iconOutlined"
              size={43}
              color={"#0043c7"}
            />
          );
        },
      },
      {
        Header: "Nom de formation",
        isSorted: false,
        width: 200,
        accessor: "nom",
      },
      {
        Header: "Sujets",
        isSorted: false,
        width: 100,
        accessor: "sujets",
        Cell: (props: any) => {
          let text = "";
          props.row.original.sujets.map(
            (subject: any) =>
              (text = text !== "" ? text + ", " + subject.label : subject.label)
          );

          return (
            <>
              <EllipsisText text={text} length={"20"} />
              <ReactTooltip>{text}</ReactTooltip>
            </>
          );
        },
      },
      {
        Header: "Contenu",
        isSorted: false,
        width: 130,
        accessor: "contenu",
        Cell: (props: any) => {
          return (
            Array.isArray(props.row.original.contenu) &&
            props.row.original.contenu?.map((content: string) => (
              <>
                {content === "pdf" ? (
                  <BsFillFileEarmarkPdfFill
                    className="me-1"
                    color="#BDBDBD"
                    size={20}
                  />
                ) : content === "audio" ? (
                  <FaMicrophone className="me-1" color="#BDBDBD" size={20} />
                ) : content === "video" ? (
                  <AiFillVideoCamera
                    className="me-1"
                    color="#BDBDBD"
                    size={20}
                  />
                ) : content === "img" ? (
                  <CameraIcon className="me-1" />
                ) : content === "ppt" ? (
                  <FaFilePowerpoint
                    className="me-1"
                    color="#BDBDBD"
                    size={20}
                  />
                ) : content === "xl" ? (
                  <AiFillFileExcel className="me-1" color="#BDBDBD" size={21} />
                ) : null}
              </>
            ))
          );
        },
      },
      {
        Header: "Date de la formation",
        isSorted: false,
        width: 170,
        accessor: "dateFormation",
        Cell: (props: any) => {
          return props.row.original.dateFormation !== "--"
            ? moment(props.row.original.dateFormation).format("DD/MM/YYYY")
            : "-";
        },
      },
      {
        Header: "Créateur",
        isSorted: false,
        width: 100,
        Cell: (props: any) => {
          return (
            props.row.original.createur.nom +
            " " +
            props.row.original.createur.prenom
          );
        },
      },
      {
        Header: "Publié le",
        isSorted: true,
        width: 150,
        Cell: (props: any) => {
          return props.row.original.datePublication
            ? moment(props.row.original.datePublication).format("DD/MM/YYYY")
            : "-";
        },
        accessor: "datePublication",
      },
    ],
    []
  );
  return (
    <>
      <Filters setFilters={setFilters} setPage={setPage} filtersData={filtersData}></Filters>
      <Row style={{ width: "100%", marginLeft: 3 }}>
        <Col className={scrollable ? "overflow-scroll" : ""}>
          <Row>
            <CatalogTable
              data={trainings}
              columns={columns}
              setNavStep={setNavStep}
            />
          </Row>
          <Row>
            <Col md="12">
              <div className="d-flex d-column justify-content-center">
                <HiOutlineArrowNarrowLeft
                  onClick={() => pageNumber !== 1 && setPage(pageNumber - 1)}
                  className={
                    pageNumber !== 1 ? "mt-3 me-2 pointer" : "mt-3 me-2 "
                  }
                  color={pageNumber !== 1 ? "#3367CD" : "#BDBDBD"}
                  size={20}
                />
                <p className="label-grey-normal text-center pt-3 pointer opacity-50">
                  page
                </p>
                <div
                  className="ms-2 mt-3 me-2"
                  style={{
                    width: 20,
                    height: 20,
                    backgroundColor: "#ECF0FB",
                    color: "#3367CD",
                  }}
                >
                  <label className="text-center ms-1">{pageNumber}</label>
                </div>
                <p className="label-grey-normal text-center pt-3 pointer opacity-50">
                  sur {totalCount ? totalCount : 1}
                </p>
                <HiOutlineArrowNarrowRight
                  onClick={() =>
                    pageNumber !== totalCount &&
                    totalCount !== 0 &&
                    setPage(pageNumber + 1)
                  }
                  className={
                    pageNumber !== totalCount && totalCount !== 0
                      ? "mt-3 ms-2 pointer"
                      : "mt-3 ms-2 "
                  }
                  size={20}
                  color={
                    pageNumber !== totalCount && totalCount !== 0
                      ? "#3367CD"
                      : "#BDBDBD"
                  }
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default AllCatalog;
