import { IoMdTrash } from "react-icons/io";
const PostLinkPreview = (props: any) => {
  const { setLinkContent, linkContent, readOnly } = props;
  return (
    <div className="d-flex flex-row download">
      <div className="d-flex flex-column">
        <a href={linkContent} type="text/css" target="_blank">{linkContent}</a>
      </div>
      <div
        className="d-flex flex-row justify-content-center"
        style={{ alignSelf: "center" }}
      >
        {!readOnly && <IoMdTrash
          size={18}
          color="#3367cd"
          className="right pointer ms-5 mt-2"
          onClick={() => {
            setLinkContent("");
          }}
        />}
      </div>
    </div>
  );
};

export default PostLinkPreview;
