import { Card, CardBody, Col, Row } from "reactstrap";
import { useAppSelector } from "../../../redux/hooks";
import { selectTrainingsAwaitingProofreading } from "../../../redux/slices/trainingSlice";
import RowIcon from "../../training/trainingCatalog/components/RowIcon";
import TrainingElement from "./TrainingElement";
import moment from "moment";
import { useHistory } from "react-router";

const TrainingsAwaitingReview = (props: any) => {
  const { isActiveProofreader, isProofreader } = props;
  const history = useHistory();
  const training = useAppSelector(selectTrainingsAwaitingProofreading);

  return (
    <Col lg="6" md="12">
      {isActiveProofreader && isProofreader && (
        <Card body color="light" outline className="card p-0">
          <CardBody>
            <Row className="pb-3 w-100">
              <Col md="9" sm="9" xs="9">
                <div className="label-black-nqt-xmd">
                  {" "}
                  Formations en attente de ma relecture{" "}
                </div>
              </Col>
              <Col md="3" sm="3" xs="3">
                <div
                  className="pull-right label-primary-nqt-sm show-more"
                  onClick={() => history.push('/trainingAwaitingProofreader')}
                >
                  Tout voir
                </div>
              </Col>
            </Row>
            {training.map((val, index) => {
              return (
                <Row
                  className={
                    index === training.length - 1
                      ? "w-100 pb-2 mt-2 pointer"
                      : "w-100 border-bottom pb-3 mt-2 pointer"
                  }
                >
                  <TrainingElement
                    trainingId={val.id}
                    name={val.nom}
                    date={moment(val.dateAssign).local().format("DD/MM/YYYY")}
                    isAwaitingReview={true}
                    row={index !== training.length}
                  >
                    <RowIcon state={"async"}></RowIcon>
                  </TrainingElement>
                </Row>
              );
            })}
          </CardBody>
        </Card>
      )}
    </Col>
  );
};

export default TrainingsAwaitingReview;
