import { ReactComponent as EmptyStarIcon } from "../../../../assets/img/icons/emptyStar.svg";
import { ReactComponent as FilledStarIcon } from "../../../../assets/img/icons/filledStar.svg";
function FeedbackStars(props: any) {
    const { feedback } = props;
    const stars = [0,1,2,3,4].map(i => {
        if (feedback === "--") {
            return <>{'--'}</>;
        }else if (i < feedback){
            return <FilledStarIcon />;
        }else{
            return <EmptyStarIcon />;
        }
    });

    return (
        <div>
            {stars}
        </div>
    );
}

export default FeedbackStars;
