import { useEffect, useState } from "react";
import { useMedia } from "react-media";
import { useAppSelector } from "../redux/hooks";
import { selectUser } from "../redux/slices/userSlice";
import { Nav } from "react-bootstrap";
import { homeRoutes, collaborateurRoutes } from "../config/routes";
import { Card, CardBody, Col, Row } from "reactstrap";
import { NavLink, useParams } from "react-router-dom";
import LayoutHeader from "./LayoutHeader";
import LayoutSideBar from "./LayoutSideBar";
import "../styles/home.scss";
import LayoutMobileMenu from "./LayoutMobileMenu";

const GLOBAL_MEDIA_QUERIES = {
  small: "(max-width: 991px)",
  large: "(min-width: 992px)",
};

const getIcon = (name: string, active: boolean) => {
  if (name === "Dashboard") {
    return (
      <svg
        width="16"
        height="18"
        viewBox="0 0 16 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.5559 17.0303H10.7797C10.2003 17.0303 9.73077 16.5608 9.73077 15.9814V12.2051C9.73077 11.7415 9.35525 11.366 8.89161 11.366H7.21329C6.74965 11.366 6.37413 11.7415 6.37413 12.2051V15.9814C6.37413 16.5608 5.90462 17.0303 5.32518 17.0303H1.54895C0.969511 17.0303 0.5 16.5608 0.5 15.9814V7.97284C0.5 7.00823 0.942238 6.0969 1.69958 5.50025L7.66266 0.801372C7.89133 0.621792 8.21357 0.621792 8.44182 0.801372L14.4057 5.50025C15.1631 6.0969 15.6049 7.00781 15.6049 7.972V15.9814C15.6049 16.5608 15.1354 17.0303 14.5559 17.0303Z"
          fill={active ? "#3367CD" : "#BDBDBD"}
        />
      </svg>
    );
  } else if (name === "Catalogue") {
    return (
      <svg
        width="19"
        height="22"
        viewBox="0 0 19 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.5696 0.200012C1.93178 0.200012 0.599609 1.53219 0.599609 3.17V18.0199V18.2899V18.8299C0.599609 20.4606 1.93893 21.7999 3.5696 21.7999H17.6096C17.7169 21.8014 17.8235 21.7816 17.9231 21.7416C18.0227 21.7016 18.1133 21.6421 18.1898 21.5668C18.2662 21.4914 18.3269 21.4016 18.3683 21.3026C18.4097 21.2036 18.4311 21.0973 18.4311 20.9899C18.4311 20.8826 18.4097 20.7763 18.3683 20.6773C18.3269 20.5783 18.2662 20.4885 18.1898 20.4131C18.1133 20.3377 18.0227 20.2783 17.9231 20.2383C17.8235 20.1982 17.7169 20.1784 17.6096 20.1799H3.5696C2.90715 20.1799 2.36931 19.7222 2.24703 19.0999H17.6096C18.0572 19.0999 18.4195 18.7376 18.4195 18.2899V3.17C18.4195 1.53219 17.0874 0.200012 15.4496 0.200012H3.5696ZM5.18959 6.67999H13.8296C14.2772 6.67999 14.6396 7.04233 14.6396 7.48998C14.6396 7.93764 14.2772 8.29998 13.8296 8.29998H5.18959C4.74193 8.29998 4.3796 7.93764 4.3796 7.48998C4.3796 7.04233 4.74193 6.67999 5.18959 6.67999ZM5.18959 10.46H12.2096C12.6572 10.46 13.0196 10.8223 13.0196 11.27C13.0196 11.7176 12.6572 12.08 12.2096 12.08H5.18959C4.74193 12.08 4.3796 11.7176 4.3796 11.27C4.3796 10.8223 4.74193 10.46 5.18959 10.46Z"
          fill={active ? "#3367CD" : "#BDBDBD"}
        />
      </svg>
    );
  } else if (name === "Clubs NQT") {
    return (
      <svg
        width="20"
        height="24"
        viewBox="0 0 20 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17 0H3C2.45 0 2 0.45 2 1C2 1.55 2.45 2 3 2H17C17.55 2 18 1.55 18 1C18 0.45 17.55 0 17 0ZM3 24H17C17.55 24 18 23.55 18 23C18 22.45 17.55 22 17 22H3C2.45 22 2 22.45 2 23C2 23.55 2.45 24 3 24ZM18 4H2C0.9 4 0 4.9 0 6V18C0 19.1 0.9 20 2 20H18C19.1 20 20 19.1 20 18V6C20 4.9 19.1 4 18 4ZM10 6.75C11.24 6.75 12.25 7.76 12.25 9C12.25 10.24 11.24 11.25 10 11.25C8.76 11.25 7.75 10.24 7.75 9C7.75 7.76 8.76 6.75 10 6.75ZM15 17H5V15.5C5 13.83 8.33 13 10 13C11.67 13 15 13.83 15 15.5V17Z"
          fill={active ? "#3367CD" : "#BDBDBD"}
        />
      </svg>
    );
  } else {
    return (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16 0H2C0.89 0 0 0.9 0 2V16C0 17.1 0.89 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0ZM13 10H10V13C10 13.55 9.55 14 9 14C8.45 14 8 13.55 8 13V10H5C4.45 10 4 9.55 4 9C4 8.45 4.45 8 5 8H8V5C8 4.45 8.45 4 9 4C9.55 4 10 4.45 10 5V8H13C13.55 8 14 8.45 14 9C14 9.55 13.55 10 13 10Z"
          fill={active ? "#3367CD" : "#BDBDBD"}
        />
      </svg>
    );
  }
};

const MenuNavLink = ({ route, title, lg, trainingId = undefined }: any) => (
  <Col lg={lg} className="p-2 mt-2 mb-3" key={route.name}>
    <Card style={{ maxHeight: 64 }}>
      <CardBody>
        <NavLink to={route.path} className="navLink d-flex flex-row pb-3">
          {getIcon(route.name, title === route.name)}
          <p
            className={title === route.name ? "active" : ""}
            style={{ paddingLeft: 10 }}
          >
            {route.name}
          </p>
        </NavLink>
      </CardBody>
    </Card>
  </Col>
);

const Layout = (props: any) => {
  const { title, isProofreader, isActiveProofreader, setIsActiveProofreader } =
    props;
  const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
  const user = useAppSelector(selectUser);
  const userRole = useAppSelector(selectUser).role;

  const [isEditor, setIsEditor] = useState(false);
  const [isNqtCollaborator, setIsNqtCollaborator] = useState(false);
  const [isAlumuni, setIsAlumuni] = useState(false);
  const { trainingId } = useParams<any>()

  useEffect(() => {
    if (userRole.includes("EDITOR")) setIsEditor(true);
    else if (userRole.includes("NQT_COLLABORATOR")) setIsNqtCollaborator(true);
    else if (userRole.includes("ALUMNI")) setIsAlumuni(true);
  }, [userRole]);

  const alumniRoutes = homeRoutes
    .filter((route: any) => route.name !== "Créer une formation")
    .map((route: any) => <MenuNavLink route={route} title={title} trainingId={trainingId} lg="4" />);

  const mentorRoutes = homeRoutes.map((route: any) => (
    <MenuNavLink route={route} title={title} trainingId={trainingId} lg="3" />
  ));

  const collaboratorRoutes = collaborateurRoutes.map((route: any) => (
    <MenuNavLink route={route} title={title} trainingId={trainingId} lg="3" />
  ));

  return (
    <div className="main-panel">
      <LayoutHeader
        title={title}
        isProofreader={isProofreader}
        isActiveProofreader={isActiveProofreader}
        setIsActiveProofreader={setIsActiveProofreader}
        user={user}
        isEditor={isEditor}
        isNqtCollaborator={isNqtCollaborator}
      />

      <div className="content mt-5 pt-4 pb-5">
        {matches.large ? (
          <>
            <LayoutSideBar
              title={title}
              user={user}
              isEditor={isEditor}
              isNqtCollaborator={isNqtCollaborator}
              isAlumuni={isAlumuni}
            />
            {!isEditor && (
              <Row>
                <Nav className="flex-row pt-3">
                  {isAlumuni
                    ? alumniRoutes
                    : isNqtCollaborator
                    ? collaboratorRoutes
                    : mentorRoutes}
                </Nav>
              </Row>
            )}
          </>
        ) : (
          <LayoutMobileMenu
            title={title}
            user={user}
            isEditor={isEditor}
            isNqtCollaborator={isNqtCollaborator}
            isAlumuni={isAlumuni}
          />
        )}
        {props.children}
      </div>
    </div>
  );
};

export default Layout;
