import { SpecialZoomLevel, Viewer } from "@react-pdf-viewer/core";
import {
  pageNavigationPlugin,
  RenderGoToPageProps,
  RenderCurrentPageLabelProps,
} from "@react-pdf-viewer/page-navigation";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import {
  fullScreenPlugin,
  RenderEnterFullScreenProps,
} from "@react-pdf-viewer/full-screen";
import { Col } from "reactstrap";

import disableScrollPlugin from "./disableScrollPlugin";
import { useMedia } from "react-media";

interface SlidePresentationExampleProps {
  fileUrl: string;
}

const SlidePresentationExample: React.FC<SlidePresentationExampleProps> = ({
  fileUrl,
}) => {
  const disableScrollPluginInstance = disableScrollPlugin();
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const fullScreenPluginInstance = fullScreenPlugin();
  const { CurrentPageLabel } = pageNavigationPluginInstance;
  const GLOBAL_MEDIA_QUERIES = {
    small: "(max-width: 991px)",
    large: "(min-width: 992px)",
  };
  const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });

  const { GoToNextPage, GoToPreviousPage } = pageNavigationPluginInstance;

  const { EnterFullScreen } = fullScreenPluginInstance;

  return (
    <div
      style={{
        border: "1px solid rgba(0, 0, 0, 0.3)",
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <div className="pdf-toolbar">
        <Col md="2" className="fullscreen">
          <EnterFullScreen>
            {(props: RenderEnterFullScreenProps) => (
              <button
                style={{
                  background: "transparent",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                  padding: "8px",
                }}
                onClick={props.onClick}
              >
                <svg
                  width="22"
                  height="18"
                  viewBox="0 0 22 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H20C21.1 18 22 17.1 22 16V2C22 0.9 21.1 0 20 0ZM20 16H2V2H20V16ZM3 3H12V10H3V3Z"
                    fill="#BDBDBD"
                  />
                </svg>
                <span>Full screen</span>
              </button>
            )}
          </EnterFullScreen>
        </Col>

        {matches.large ? (
          <>
            <Col md="8" className="navigation-btn">
              <div style={{ padding: "0 2px" }}>
                <GoToPreviousPage>
                  {(props: RenderGoToPageProps) => (
                    <button
                      style={{
                        border: "none",
                        borderRadius: "4px",
                        cursor: props.isDisabled ? "not-allowed" : "pointer",
                        padding: "8px",
                        background: "transparent",
                      }}
                      disabled={props.isDisabled}
                      onClick={props.onClick}
                    >
                      <svg
                        width="20"
                        height="14"
                        viewBox="0 0 20 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M20 6.5V7.5C20 7.77615 19.7761 8 19.5 8H3.33L7.78 12.44C7.87466 12.5339 7.9279 12.6617 7.9279 12.795C7.9279 12.9283 7.87466 13.0561 7.78 13.15L7.07 13.85C6.97612 13.9447 6.84832 13.9979 6.715 13.9979C6.58168 13.9979 6.45388 13.9447 6.36 13.85L0.22 7.72001C0.0793075 7.57949 0.000175052 7.38885 0 7.19V6.81C0.00230401 6.61159 0.081116 6.42173 0.22 6.28L6.36 0.150005C6.45388 0.0553486 6.58168 0.00210571 6.715 0.00210571C6.84832 0.00210571 6.97612 0.0553486 7.07 0.150005L7.78 0.860005C7.87406 0.952168 7.92707 1.07832 7.92707 1.21C7.92707 1.34169 7.87406 1.46784 7.78 1.56L3.33 6H19.5C19.7761 6 20 6.22386 20 6.5Z"
                          fill={props.isDisabled ? "#BDBDBD" : "#3367CD"}
                        />
                      </svg>
                    </button>
                  )}
                </GoToPreviousPage>
              </div>
              <div style={{ padding: "0 2px" }}>
                <GoToNextPage>
                  {(props: RenderGoToPageProps) => (
                    <button
                      style={{
                        border: "none",
                        borderRadius: "4px",
                        cursor: props.isDisabled ? "not-allowed" : "pointer",
                        padding: "8px",
                        background: "transparent",
                      }}
                      disabled={props.isDisabled}
                      onClick={props.onClick}
                    >
                      <svg
                        width="20"
                        height="14"
                        viewBox="0 0 20 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M-5.68064e-07 6.50002L-6.55487e-07 7.50002C-6.79628e-07 7.77616 0.223857 8.00002 0.499999 8.00002L16.67 8.00002L12.22 12.44C12.1253 12.5339 12.0721 12.6617 12.0721 12.795C12.0721 12.9283 12.1253 13.0561 12.22 13.15L12.93 13.85C13.0239 13.9447 13.1517 13.9979 13.285 13.9979C13.4183 13.9979 13.5461 13.9447 13.64 13.85L19.78 7.72002C19.9207 7.5795 19.9998 7.38887 20 7.19002L20 6.81002C19.9977 6.61161 19.9189 6.42174 19.78 6.28002L13.64 0.15002C13.5461 0.0553632 13.4183 0.0021204 13.285 0.00212038C13.1517 0.00212037 13.0239 0.0553632 12.93 0.15002L12.22 0.860019C12.1259 0.952183 12.0729 1.07833 12.0729 1.21002C12.0729 1.34171 12.1259 1.46786 12.22 1.56002L16.67 6.00002L0.499999 6.00002C0.223857 6.00002 -5.43923e-07 6.22388 -5.68064e-07 6.50002Z"
                          fill={props.isDisabled ? "#BDBDBD" : "#3367CD"}
                        />
                      </svg>
                    </button>
                  )}
                </GoToNextPage>
              </div>
            </Col>
            <Col md="2" className="page-label">
              <CurrentPageLabel>
                {(props: RenderCurrentPageLabelProps) => (
                  <div className="d-flex" style={{ justifyContent: "center" }}>
                    <span> page </span>
                    <div className="answer-number" style={{ color: "#3367CD" }}>
                      {props.currentPage + 1}
                    </div>
                    <span>sur</span>
                    <div
                      className="answer-number"
                      style={{ background: "#F9F9F9", color: "#BDBDBD" }}
                    >
                      {props.numberOfPages}
                    </div>
                  </div>
                )}
              </CurrentPageLabel>
            </Col>
          </>
        ) : (
          <Col className="page-label d-flex">
            <div style={{ padding: "0 2px" }}>
                <GoToPreviousPage>
                  {(props: RenderGoToPageProps) => (
                    <button
                      style={{
                        border: "none",
                        borderRadius: "4px",
                        cursor: props.isDisabled ? "not-allowed" : "pointer",
                        padding: "8px",
                        background: "transparent",
                      }}
                      disabled={props.isDisabled}
                      onClick={props.onClick}
                    >
                      <svg
                        width="20"
                        height="14"
                        viewBox="0 0 20 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M20 6.5V7.5C20 7.77615 19.7761 8 19.5 8H3.33L7.78 12.44C7.87466 12.5339 7.9279 12.6617 7.9279 12.795C7.9279 12.9283 7.87466 13.0561 7.78 13.15L7.07 13.85C6.97612 13.9447 6.84832 13.9979 6.715 13.9979C6.58168 13.9979 6.45388 13.9447 6.36 13.85L0.22 7.72001C0.0793075 7.57949 0.000175052 7.38885 0 7.19V6.81C0.00230401 6.61159 0.081116 6.42173 0.22 6.28L6.36 0.150005C6.45388 0.0553486 6.58168 0.00210571 6.715 0.00210571C6.84832 0.00210571 6.97612 0.0553486 7.07 0.150005L7.78 0.860005C7.87406 0.952168 7.92707 1.07832 7.92707 1.21C7.92707 1.34169 7.87406 1.46784 7.78 1.56L3.33 6H19.5C19.7761 6 20 6.22386 20 6.5Z"
                          fill={props.isDisabled ? "#BDBDBD" : "#3367CD"}
                        />
                      </svg>
                    </button>
                  )}
                </GoToPreviousPage>
              </div>
              <CurrentPageLabel>
                {(props: RenderCurrentPageLabelProps) => (
                  <div className="d-flex" style={{ justifyContent: "center" }}>
                    <div className="answer-number" style={{ color: "#3367CD" }}>
                      {props.currentPage + 1}
                    </div>
                    <span>sur</span>
                    <div
                      className="answer-number"
                      style={{ background: "#F9F9F9", color: "#BDBDBD" }}
                    >
                      {props.numberOfPages}
                    </div>
                  </div>
                )}
              </CurrentPageLabel>
              <div style={{ padding: "0 2px" }}>
                <GoToNextPage>
                  {(props: RenderGoToPageProps) => (
                    <button
                      style={{
                        border: "none",
                        borderRadius: "4px",
                        cursor: props.isDisabled ? "not-allowed" : "pointer",
                        padding: "8px",
                        background: "transparent",
                      }}
                      disabled={props.isDisabled}
                      onClick={props.onClick}
                    >
                      <svg
                        width="20"
                        height="14"
                        viewBox="0 0 20 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M-5.68064e-07 6.50002L-6.55487e-07 7.50002C-6.79628e-07 7.77616 0.223857 8.00002 0.499999 8.00002L16.67 8.00002L12.22 12.44C12.1253 12.5339 12.0721 12.6617 12.0721 12.795C12.0721 12.9283 12.1253 13.0561 12.22 13.15L12.93 13.85C13.0239 13.9447 13.1517 13.9979 13.285 13.9979C13.4183 13.9979 13.5461 13.9447 13.64 13.85L19.78 7.72002C19.9207 7.5795 19.9998 7.38887 20 7.19002L20 6.81002C19.9977 6.61161 19.9189 6.42174 19.78 6.28002L13.64 0.15002C13.5461 0.0553632 13.4183 0.0021204 13.285 0.00212038C13.1517 0.00212037 13.0239 0.0553632 12.93 0.15002L12.22 0.860019C12.1259 0.952183 12.0729 1.07833 12.0729 1.21002C12.0729 1.34171 12.1259 1.46786 12.22 1.56002L16.67 6.00002L0.499999 6.00002C0.223857 6.00002 -5.43923e-07 6.22388 -5.68064e-07 6.50002Z"
                          fill={props.isDisabled ? "#BDBDBD" : "#3367CD"}
                        />
                      </svg>
                    </button>
                  )}
                </GoToNextPage>
              </div>
          </Col>
        )}
      </div>
      <div
        style={{
          flex: 1,
          overflow: "hidden",
        }}
      >
        <Viewer
          fileUrl={fileUrl}
          plugins={[
            disableScrollPluginInstance,
            pageNavigationPluginInstance,
            fullScreenPluginInstance,
          ]}
          defaultScale={SpecialZoomLevel.PageFit}
        />
      </div>
    </div>
  );
};

export default SlidePresentationExample;
