import { BsFillCalendar2Fill } from "react-icons/bs";
import { FaThList } from "react-icons/fa";
import { Card, Col, Progress, Row } from "reactstrap";
import moment from "moment";

import { DatePicker, Input } from "../../../../components/forms";
import { useEffect, useState } from "react";
import AsyncUpload from "./AsyncUpload";
import { sectionContentUpload } from "../../../../utils/constant";
import { IoMdAttach, IoMdClose } from "react-icons/io";
import SyncCodeValidation from "./SyncCodeValidation";

const SyncEvent = (props: any) => {
  //Init props
  const { setValues, setFieldValue, values, tag } = props;
  const [open, setOpen] = useState(false);
  const [startHour, setStartHour] = useState(values.eventDetails.startTimeHour);
  const [startMinute, setStartMinute] = useState(values.eventDetails.startTimeMinute);
  const [endHour, setEndHour] = useState(values.eventDetails.endTimeHour);
  const [endMinute, setEndMinute] = useState(values.eventDetails.endTimeMinute);

  const addOrRemoveFile = (tag: string, index: number = 0) => {
    let trainingValues: any = { ...values };

    if (tag === "add") {
      trainingValues.eventDetails.section.files.push({
        ...sectionContentUpload,
      });
    } else {
      trainingValues.eventDetails.section.files.splice(index, 1);
    }
    setValues(trainingValues);
  };

  useEffect(() => {
    if (+endHour <= +startHour) {
      setEndHour(startHour);
      if (+endMinute < +startMinute) setEndMinute(startMinute);
    }

    setFieldValue("eventDetails.startTimeHour", startHour);
    setFieldValue("eventDetails.startTimeMinute", startMinute);
    setFieldValue("eventDetails.endTimeHour", endHour);
    setFieldValue("eventDetails.endTimeMinute", endMinute);
  }, [setFieldValue, startHour, startMinute, endHour, endMinute]);

  return (
    <Row className="center-container">
      <Col md="9">
        <Card body color="light" outline className="cardIntro mt-5 p-4">
          <Row className="d-flex flex-row align-items-center">
            <Col md="11" className="d-flex flex-row">
              <FaThList size={42} className="iconOutlined" />
              <div className="d-flex flex-column">
                <p className="mb-0 ms-3 label-primary-nqt-md">
                  Détails de la formation évènement
                </p>
                <p className="pt-1 ms-3 label-grey-normal">
                  Précisez à la communauté comment rejoindre la formation.
                </p>
              </div>
            </Col>
            <Col md="1">
              <Progress multi style={{ height: 4, width: 24 }}>
                <Progress
                  bar
                  value="50"
                  className={
                    values.eventDetails.location &&
                      values.eventDetails.date &&
                      values.eventDetails.startTimeHour &&
                      values.eventDetails.startTimeMinute &&
                      values.eventDetails.endTimeHour &&
                      values.eventDetails.endTimeMinute
                      ? "bg-primary"
                      : "bg-pink"
                  }
                />
              </Progress>
            </Col>
          </Row>

          <Row>
            <hr className="hr-text-grey p-0 mt-3"></hr>
          </Row>
          <Row>
            <Col md="6">
              <p className="label-black mt-3">Lieu de l’évènement</p>
              <div
                className="my-3 d-flex flex-row"
                onChange={(ev: any) =>
                  setFieldValue("eventDetails.type", ev.target.value)
                }
              >
                <input
                  type="radio"
                  value="dig"
                  name="gender"
                  className="me-1"
                  checked={values.eventDetails.type === "dig"}
                />
                <p
                  className={
                    values.eventDetails.type === "dig"
                      ? "label-primary-normal me-3 mt-1"
                      : "label-grey-normal me-3"
                  }
                >
                  Digital
                </p>
                <input
                  type="radio"
                  value="pres"
                  name="gender"
                  className="me-1"
                  checked={values.eventDetails.type === "pres"}
                />
                <p
                  className={
                    values.eventDetails.type === "pres"
                      ? "label-primary-normal me-3 mt-1"
                      : "label-grey-normal me-3"
                  }
                >
                  Présentiel
                </p>
              </div>

              <Input
                name={"eventDetails.location"}
                placeholder={
                  values.eventDetails.type === "pres"
                    ? "Adresse de l’évènement"
                    : "Lien de la visioconférence ( Zoom, Teams, Google meets, ...)"
                }
                icon={values.eventDetails.type === "pres" ? false : true}
                error={""}
              />
            </Col>
          </Row>
          <Row className="justify-content-between">
            <Col md="6" sm="6">
              <p className="label-black mt-3">Date de l’évènement</p>
              <div className="pointer" onClick={() => setOpen(!open)}>
                <DatePicker
                  error=""
                  open={open}
                  value={new Date(values?.eventDetails.date)}
                  calendarIcon={
                    <BsFillCalendar2Fill
                      className="float-right ms-5 ms-5 pointer"
                      color="transparent"
                    />
                  }
                  onChange={(ev: any) =>
                    setFieldValue(
                      "eventDetails.date",
                      moment(ev).format("YYYY-MM-DD")
                    )
                  }
                  minDate={moment().toDate()}
                />
              </div>
            </Col>
            <Col md="2" sm="3" xs="4">
              <p className="label-black mt-3">Heure de début</p>
              <div className="d-flex flex-row justify-content-around">
                <Input
                  // name={"eventDetails.startTimeHour"}
                  type="number"
                  min="00"
                  max="23"
                  placeholder="16"
                  style={{ padding: 5, textAlign: "center", width: 42 }}
                  error={""}
                  onChange={(e: any) => {
                    let value = (+e.target.value < 10 && e.target.value.length === 1 ? "0" : "") + e.target.value;
                    +value < 24 && value.length === 2 && setStartHour(value);
                  }}
                  value={startHour}
                  className="flex-grow-0 formControl label-black"
                />
                <p
                  className="label-grey-normal mt-3 me-3"
                  style={{ color: "#BDBDBD" }}
                >
                  h
                </p>
                <Input
                  // name={"eventDetails.startTimeMinute"}
                  type="number"
                  placeholder="00"
                  max="59"
                  min="00"
                  style={{ padding: 5, textAlign: "center", width: 42 }}
                  error={""}
                  onChange={(e: any) => {
                    let value = (+e.target.value < 10 && e.target.value.length === 1 ? "0" : "") + e.target.value;
                    +value < 60 && value.length === 2 && setStartMinute(value);
                  }}
                  value={startMinute}
                  className="flex-grow-0 formControl label-black"
                />
              </div>
            </Col>
            <Col md="2" sm="3" xs="4">
              <p className="label-black mt-3">Heure de fin</p>
              <div className="d-flex flex-row justify-content-between">
                <Input
                  // name={"eventDetails.endTimeHour"}
                  type="number"
                  min={startHour}
                  max="23"
                  placeholder="18"
                  style={{ padding: 5, textAlign: "center", width: 42 }}
                  error={""}
                  onChange={(e: any) => {
                    let value = (+e.target.value < 10 && e.target.value.length === 1 ? "0" : "") + e.target.value;
                    +value < 24 && value.length === 2 && setEndHour(value);
                  }}
                  value={endHour}
                  className="flex-grow-0 formControl label-black"
                />
                <p
                  className="label-grey-normal mt-3 me-3"
                  style={{ color: "#BDBDBD" }}
                >
                  h
                </p>
                <Input
                  // name={"eventDetails.endTimeMinute"}
                  type="number"
                  placeholder="00"
                  min={endHour <= startHour ? startMinute : 0}
                  max="59"
                  style={{ padding: 5, textAlign: "center", width: 42 }}
                  error={""}
                  onChange={(e: any) => {
                    let value = (+e.target.value < 10 && e.target.value.length === 1 ? "0" : "") + e.target.value;
                    +value < 60 && value.length === 2 && setEndMinute(value);
                  }}
                  value={endMinute}
                  className="flex-grow-0 formControl label-black"
                />
              </div>
            </Col>
          </Row>
        </Card>
        <SyncCodeValidation setFieldValue={setFieldValue} tag={tag} values={values} />
        <Card body color="light" outline className="cardIntro mt-5 p-4">
          <div className="d-flex flex-row align-items-center">
            <FaThList size={42} className="iconOutlined" />
            <div className="d-flex flex-column  w-100 mt-3">
              <p className={"mb-0 ms-3 label-primary-nqt-md"}>
                Contenu à télécharger
              </p>
              <p className="pt-1 ms-3 label-grey-normal">
                Vous pouvez télécharger un contenu qui sera accessible aux appprenants en amont de la formation.
              </p>
            </div>
            <>
              <Progress multi style={{ height: 4, width: 24 }} className="me-2">
                <Progress bar value="50" className={"bg-primary"} />
              </Progress>
            </>
          </div>
        </Card>
        {values.eventDetails.section &&
          values.eventDetails.section.files.map(
            (file: any, fileKey: number) => (
              <>
                {fileKey > 0 && (
                  <IoMdClose
                    size={10}
                    color="#8D8D8D"
                    onClick={() => addOrRemoveFile("remove", fileKey)}
                    className="mt-5 ms-2 float-end closeIcon pointer"
                  />
                )}

                <AsyncUpload
                  values={values}
                  fileKey={fileKey}
                  setFieldValue={setFieldValue}
                  addOrRemoveFile={addOrRemoveFile}
                  tag={tag}
                />
              </>
            )
          )}
      </Col>
      <Row className="mt-3 center-container me-2">
        <Col md="9">
          <div
            className="dottedCard d-flex flex-row pointer h-auto mt-1"
            onClick={() => addOrRemoveFile("add", 0)}
          >
            <p className="label-grey-md-normal m-2 mt-5 w-100 ">
              Importer <br /> une pièce jointe
            </p>
            <IoMdAttach size={30} color="#8D8D8D" className="m-2" />
          </div>
        </Col>
      </Row>
    </Row>
  );
};
export default SyncEvent;
