import { useState } from "react";
import { useAppDispatch } from "../../../redux/hooks";
import TrainingEval from "./components/TrainingEval";
import TrainingLaunchHeader from "./components/TrainingLaunchHeader";
import TrainingQuizz from "./components/TrainingQuizz";
import TrainingSynchrone from "./components/TrainingSynchrone";

const TrainingLaunchContainer = () => {
  const dispatch = useAppDispatch();
  const [contentType, setContentType] = useState("content");
  return  (
    <div>
        {contentType === "content" && (<TrainingLaunchHeader dispatch={dispatch} />
        )}
        {contentType === "content" && (
        <TrainingSynchrone contentType={contentType} setContentType={setContentType}></TrainingSynchrone>
      )}
      {contentType === "quizz" && (
        <TrainingQuizz setContentType={setContentType}></TrainingQuizz>
      )}
      {contentType === "eval" && (
        <TrainingEval setContentType={setContentType}></TrainingEval>
      )}
    </div>
  );
};

export default TrainingLaunchContainer;

