import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { useMedia } from "react-media";
import { Col, Card, CardBody, CardTitle, Row } from "reactstrap";
import Box from "../../../components/widget/Box";
import { useAppSelector } from "../../../redux/hooks";
import { selectUser } from "../../../redux/slices/userSlice";
import { useMemo, useState } from "react";
import EditSectorsFamilies from "./EditSectorsFamilies";

const SectorFamilies = () => {
  const GLOBAL_MEDIA_QUERIES = {
    small: "(max-width: 991px)",
    large: "(min-width: 992px)",
  };
  const [show, setShow] = useState(false);
  const handleShowParentToChild = (index: boolean) => {
    setShow(index);
  };
  const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
  const profile = useAppSelector(selectUser);
  const sectors = useMemo(
    () => (profile.sectors ? profile.sectors : []),
    [profile.sectors]
  );

  const stepPercentage = useMemo(() => 100 / sectors.length, [sectors]);
  let percentage = 0;

  const selectedSectors = sectors.filter((item) => item.isSelected);
  if (selectedSectors.length > 0)
    selectedSectors.map(() => (percentage += stepPercentage));

  const mColor = percentage < 50 ? "#FF66A9" : "#3367CD";
  const iconColor = percentage > 49 && percentage < 100 ? "#ED984F" : mColor;

  return (
    <Col md="6" sm="6" xs="12">
      <Card
        color="light"
        outline
        body
        className="cardDetails"
        style={{ minHeight: 240, marginRight: 10, marginBottom: 15 }}
      >
        <CardBody>
          <Row className="mb-4">
            <Col style={{ minWidth: 70, maxWidth: 100 }}>
              <CircularProgressbarWithChildren
                value={Math.round(percentage)}
                background={true}
                styles={{
                  root: {},
                  path: {
                    stroke: iconColor,
                    strokeLinecap: "butt",
                    transition: "stroke-dashoffset 0.5s ease 0s",
                    strokeWidth: "3px",
                  },
                  trail: {
                    stroke: "rgb(255,255, 255)",
                    strokeLinecap: "butt",
                    strokeWidth: "10px",
                  },
                  background: {
                    fill: iconColor,
                  },
                }}
              >
                <svg
                  width="14"
                  height="16"
                  viewBox="0 0 14 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.625 10.125C9.625 11.625 7.75 12.75 7.75 13.875H6.25C6.25 12.75 4.375 11.625 4.375 10.125C4.375 8.6775 5.5525 7.5 7 7.5C8.4475 7.5 9.625 8.6775 9.625 10.125ZM7.75 14.625H6.25V15.75H7.75V14.625ZM12.25 9.75C12.25 11.01 11.8075 12.1575 11.065 13.065L12.13 14.13C13.135 12.9525 13.75 11.4225 13.75 9.75C13.75 7.695 12.8275 5.8575 11.38 4.62L10.315 5.685C11.4925 6.645 12.25 8.115 12.25 9.75ZM10 3.75L7 0.75V3C3.2725 3 0.25 6.0225 0.25 9.75C0.25 11.4225 0.865 12.9525 1.87 14.13L2.935 13.065C2.1925 12.1575 1.75 11.01 1.75 9.75C1.75 6.855 4.105 4.5 7 4.5V6.75L10 3.75Z"
                    fill="white"
                  />
                </svg>
              </CircularProgressbarWithChildren>
            </Col>
            <Col>
              <CardTitle className="d-flex mt-2">
                <Col lg="11" md="11" sx="11" xs="11">
                  <div className="label-black-md">
                    Mes clubs mentors métiers
                  </div>
                  <p className="label-grey-normal opacity-75 mt-1">
                    Les Clubs sélectionnés ici me permettront d’accéder aux contenus sur les métiers qui m’intéressent.
                  </p>
                </Col>
                <Col>
                  <div className="pull-right mt-1">
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => {
                        setShow(true);
                      }}
                    >
                      <path
                        d="M9.91406 0C9.65819 0 9.40228 0.0977188 9.20703 0.292969L8 1.5L10.5 4L11.707 2.79297C12.0975 2.40247 12.0975 1.76941 11.707 1.37891L10.6211 0.292969C10.4258 0.0977188 10.1699 0 9.91406 0ZM7 2.5L1.12988 8.37012C1.12988 8.37012 1.58877 8.329 1.75977 8.5C1.93077 8.671 1.79 9.79 2 10C2.21 10.21 3.32195 10.0622 3.48145 10.2217C3.64095 10.3812 3.62988 10.8701 3.62988 10.8701L9.5 5L7 2.5ZM0.5 10L0.0283203 11.3359C0.00977771 11.3887 0.000204918 11.4441 0 11.5C0 11.6326 0.0526784 11.7598 0.146447 11.8536C0.240215 11.9473 0.367392 12 0.5 12C0.555886 11.9998 0.611342 11.9902 0.664063 11.9717C0.665694 11.971 0.667321 11.9704 0.668945 11.9697L0.681641 11.9658L0.68457 11.9639L2 11.5L1.25 10.75L0.5 10Z"
                        fill="#8D8D8D"
                      />
                    </svg>
                  </div>
                  <EditSectorsFamilies
                    show={show}
                    setShow={handleShowParentToChild}
                  />
                </Col>
              </CardTitle>
            </Col>
          </Row>
          <Row>
            <hr style={{ border: "1px solid #ecf0fb" }} />
          </Row>
          <Row>
            {matches.large ? (
              <div className="expertiseList">
                {selectedSectors
                  .map((item) => (
                    <Box key={item.id} content={item.libelle}></Box>
                  ))
                  .flat()}
              </div>
            ) : (
              <div className="expertiseList justify-content-center">
                <Box
                  content={`${
                    selectedSectors.flat().length
                  } famille(s) de secteur renseignées`}
                ></Box>
              </div>
            )}
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default SectorFamilies;
