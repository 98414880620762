import { FaQuoteRight } from "react-icons/fa";
import { RiVoiceprintFill } from "react-icons/ri";
import { BsCalendarEvent } from "react-icons/bs";
import { Card, Col, Row, Progress } from "reactstrap";
import "../../../../styles/training.scss";
import { Input, Select, Switch, TextArea } from "../../../../components/forms";
import { training } from "../../../../utils/constant";
import {FormHelperText} from '@material-ui/core';

const Introduction = (props: any) => {
  // Init props
  const {
    errors,
    setFieldValue,
    handleChange,
    values,
    user,
    setIsSubjectValid,
    setSyncTypology
  } = props;

  const isNqtCollaborator = user.role.includes("NQT_COLLABORATOR");
  const isEditor: any = user.role.includes("EDITOR");

  const getChoices = () => {
    let choices: any = [];
    user.interetApprentissage.map((category: any) => {
      let subjects: any = [];
      let hasSelected = false;
      category.sujets.map((subject: any) => {
          subjects.push({
            value: subject.libelle,
            title: subject.libelle,
            id: subject.id,
          });
          hasSelected = true;
        return null;
      });
      hasSelected &&
        choices.push({
          value: category.libelle,
          id: category.id,
          title: category.libelle,
          subjects,
        });
      return null;
    });
    return choices;
  };

  const getDuration = () => {
    let minutes: number = 0;
    values.sections.map(
        (section: any) => {
          if(section.sectionDuration){
            minutes = minutes + parseInt(section.sectionDuration);
          }
        }
    );
    return minutes > 59
        ? Math.floor(minutes / 60) +
        " h " +
        (minutes % 60 > 0 ? (minutes % 60) + " min" : "")
        : minutes + " min";
  };

  return (
    <Row className="center-container">
      <Col md="9">
        <Card body color="light" outline className="cardIntro mt-2 p-4">
          <div className="d-flex flex-row align-items-center">
            <FaQuoteRight size={42} className="iconOutlined" />
            <div className="d-flex flex-column  w-100 mt-3">
              <p className="mb-0 ms-3 label-primary-nqt-md">
                Introduction de la formation
              </p>
              <p className="pt-1 ms-3 label-grey-normal">
                Ces informations seront présentes sur la fiche de la formation
                visible par les mentors NQT.
              </p>
            </div>
            <Progress multi style={{ height: 4, width: 24 }} className="me-2">
              <Progress
                bar
                value="50"
                className={
                  values.title && values.description ? "bg-primary" : "bg-pink"
                }
              />
            </Progress>
          </div>

          <Row>
            <hr className="hr-text-grey p-0 mt-3"></hr>
            <p className="label-black mt-2">Typologie de formation</p>
          </Row>
          <Row className="center-container">
            <Col style={{ pointerEvents: (isNqtCollaborator && !isEditor) ? "none" : "auto", opacity: (isNqtCollaborator && !isEditor) ? 0.4 : 1 }} md="6">
              <div
                className={
                  values.typology === "async"
                    ? "d-flex flex-row buttonChoiceFocus py-3"
                    : "d-flex flex-row buttonChoice py-3"
                }
                onClick={() => {
                  setSyncTypology(false);
                  setFieldValue("eventDetails", training.eventDetails);
                  setFieldValue("typology", "async");
                }}
              >
                <RiVoiceprintFill
                  size={20}
                  color={values.typology === "async" ? "#3367cd" : "#8d8d8d"}
                />
                <div className="d-flex flex-column">
                  <p
                    className={
                      values.typology === "async"
                        ? "mb-0 ms-3 mt-15 label-primary-normal"
                        : "mb-0 ms-3 label-black-normal"
                    }
                  >
                    Autonome
                  </p>
                  <p className="pt-3 ms-3 label-grey-normal">
                    Une formation postée sur la plateforme, que les mentors
                    peuvent consulter, à tout moment.
                  </p>
                </div>
              </div>
            </Col>
            <Col md="6">
              <div
                className={
                  values.typology === "sync"
                    ? "d-flex flex-row buttonChoiceFocus py-3"
                    : "d-flex flex-row buttonChoice py-3"
                }
                onClick={() => {
                  setFieldValue("sections", training.sections);
                  setFieldValue("typology", "sync");
                  setSyncTypology(true);
                }}
              >
                <BsCalendarEvent
                  size={20}
                  color={values.typology === "sync" ? "#3367cd" : "#8d8d8d"}
                />
                <div className="d-flex flex-column">
                  <p
                    className={
                      values.typology === "sync"
                        ? "mb-0 ms-3 mt-15 label-primary-normal"
                        : "mb-0 ms-3 label-black-normal"
                    }
                  >
                    Synchrone
                  </p>
                  <p className="pt-3 ms-3 label-grey-normal">
                    Une formation digitale ou présentielle, qui se tient à une
                    date précise.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          {values.typology && (
            <>
              <Row>
                <Col md="6">
                  <p className="label-black mt-3">Nom de la formation</p>
                  <Input
                    name="title"
                    placeholder="Exemple : Premier contact avec son filleul"
                    error={errors.title}
                  />
                </Col>
                <Col md="6">
                  <p className="label-black mt-3">Entreprise du créateur</p>
                  <Input
                    value={values.mentor_id ? values.company_name : user.expertisesMetier.entreprise}
                    disabled={true}
                    readOnly={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <p className="label-black mt-3">Description</p>
                  <TextArea
                    name="description"
                    placeholder="Exemple : Dans cette formation, nous verrons comment organiser le 1er contact avec son filleul"
                    error={errors.description}
                  />
                </Col>
                <Col md="6">
                  <p className="label-black mt-3">Sujets abordés</p>
                  <Select
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    name="subject"
                    value={values.subject}
                    choices={getChoices}
                    label="Ajouter un sujet"
                    setIsSubjectValid={setIsSubjectValid}
                  />
                  <FormHelperText id="style-error" error={errors.subject}>{errors.subject}</FormHelperText>
                </Col>
              </Row>
              {values.typology === "async" && (
                <Row>
                  <Col md="6">
                    <p className="label-black mt-3">
                      Formation accessible aux Alumni
                    </p>
                    <Switch
                      name={"alumniAccessible"}
                      value={values.alumniAccessible}
                      onChange={(ev: boolean) =>
                        setFieldValue("alumniAccessible", ev)
                      }
                      offColor={"#8D8D8D"}
                    />
                  </Col>
                  <Col md="6">
                    <p className="label-black mt-3">
                      Durée estimée de la formation
                      <span className="optional ms-1">calculée automatiquement</span>
                    </p>
                    <Input value={getDuration()} disabled={true} />
                  </Col>
                </Row>
              )}
            </>
          )}
        </Card>
      </Col>
    </Row>
  );
};
export default Introduction;
