import { Card, CardBody, Col, Row } from 'reactstrap'
const CreatorCard = (props: any) => {
  const {
    creatorData,
    edit,
    handleCreatorData,
    oldValues,
    handleUpdatedValues,
    updatedValues,
  } = props
  return (
    <Card body color="light" outline className="card p-0 mt-1 mb-1">
      <CardBody className="pl-1 pb-0 pt-1 mt-1">
        <Row className="pb-2 w-100">
          <Col md="3" sm="3" xs="3">
            <p className="label-black-nqt-gamification mb-0">Créateur</p>
          </Col>
          <Col md="9" sm="9" xs="9">
            <Row>
              {creatorData.map((val: any, key: any) => {
                return (
                  <>
                    <Col md="4" sm="4" xs="4">
                      <Row>
                        {val.reward_type === 'Trophée Bronze' ? (
                          <Col md="3" sm="3" xs="3">
                            <img
                              alt="..."
                              style={{ paddingLeft: '0px' }}
                              src={
                                require('../../../../assets/img/Bronze.png')
                                  .default
                              }
                            />
                          </Col>
                        ) : val.reward_type === 'Trophée Argent' ? (
                          <Col md="3" sm="3" xs="3">
                            <img
                              alt="..."
                              style={{ paddingLeft: '0px' }}
                              src={
                                require('../../../../assets/img/Argent.png')
                                  .default
                              }
                            />
                          </Col>
                        ) : val.reward_type === 'Trophée or' ? (
                          <Col md="3" sm="3" xs="3">
                            <img
                              alt="..."
                              style={{ paddingLeft: '0px' }}
                              src={
                                require('../../../../assets/img/Or.png').default
                              }
                            />
                          </Col>
                        ) : null}
                        <Col
                          md="9"
                          sm="9"
                          xs="9"
                          className={
                            val.reward_type === 'Trophée Bronze' ||
                            val.reward_type === 'Trophée Argent' ||
                            val.reward_type === 'Trophée or'
                              ? 'ps-0'
                              : ''
                          }
                        >
                          <p className="label-black-nqt-gamification mb-0">
                            {val.reward_type}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col md="4" sm="4" xs="4">
                      {edit ? (
                        <input
                          className="style-nombre-point"
                          type="number"
                          value={val.nb_points}
                          onChange={(e: any) => {
                            const target = oldValues.Createur.filter(
                              (section: any) => section.id === val.id,
                            )

                            if (target.nb_points !== val.nb_points) {
                              const filteredValues = updatedValues.filter(
                                (section: any) => section.id !== val.id,
                              )
                              handleUpdatedValues([
                                ...filteredValues,
                                {
                                  id: val.id,
                                  function: val.function,
                                  equivalence: val.equivalence,
                                  reward_type: val.reward_type,
                                  nb_points: e.target.value,
                                },
                              ])
                            }
                            const newList: any = []
                            creatorData.forEach(
                              (section: any, keyy: any) =>
                                (newList[keyy] = JSON.parse(
                                  JSON.stringify(section),
                                )),
                            )
                            newList[key] = {
                              id: val.id,
                              nb_points: parseInt(e.target.value),
                              function: val.function,
                              equivalence: val.equivalence,
                              reward_type: val.reward_type,
                            }
                            handleCreatorData(newList)
                          }}
                        />
                      ) : (
                        <p className="label-black-nqt-gamification mb-0">
                          {val.nb_points} pts
                        </p>
                      )}
                    </Col>
                    <Col md="4" sm="4" xs="4">
                      <p className="label-black-nqt-gamification mb-0">
                        {val.equivalence} relectures{' '}
                      </p>
                    </Col>
                  </>
                )
              })}
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default CreatorCard
