import {useEffect, useState} from "react";
import {Field} from "formik";
import {AiOutlineCaretDown, AiFillPlusCircle} from "react-icons/ai";
import {useMedia} from "react-media";
import {BiError} from "react-icons/bi";
import {MdOutlineDone} from "react-icons/md";

import Box from "../widget/Box";

const Selects = (props: any) => {
    //Init props
    const {value, choices, name, label, setFieldValue, setIsSubjectValid} =
        props;

    const GLOBAL_MEDIA_QUERIES = {
        small: "(max-width: 991px)",
        large: "(min-width: 992px)",
    };
    const matches = useMedia({queries: GLOBAL_MEDIA_QUERIES});

    const [results, setResults] = useState(value.length > 0 ? value : []);

    const [categoryId, setCategoryId] = useState(0);

    //Init states
    const [open, setOpen] = useState(false);

    //Effects
    useEffect(() => {
        if (results.length > 0) {
            setIsSubjectValid(true);
        } else {
            setIsSubjectValid(false);
        }
    }, [results, setIsSubjectValid]);

    /**
     * Add Field
     * @param choice
     */
    const addOrRemoveField = (choice: any) => {
        let isExisted = false;
        let array: any = [...results];

        results.map((result: any, index: number) => {
            if (result.id === choice.id) {
                array.splice(index, 1);
                setResults(array);
                isExisted = true;
            }
        });

        if (!isExisted) {
            let array: any = [...results];
            array.push({id: choice.id, label: choice.title});
            setResults(array);
            setFieldValue("subject", array);
        }
        else {
            let array: any = [...results];
            let newArray = array.filter((sujet:any)=>sujet.id !== choice.id);
            setResults(newArray);
            setFieldValue("subject", newArray);
        }
        setOpen(false);
    };
    const subjectList = results.map((select: any) => (
        <Box content={select.label}></Box>
    ));
    const categories = choices().map((category: any, index: number) => {
        return (
            <li
                data-id={category.id}
                className={
                    index === +categoryId ? `list active pointer` : `list pointer`
                }
                key={index}
                onClick={() => setCategoryId(index)}
            >
                {category.title}
            </li>
        );
    });
    const CustomSelectOption = () => {
        return (
            <div className="formSelect">
                <ul className="lists">{categories}</ul>

                {choices()[categoryId].subjects.map((subject: any) => {
                    return (
                        <>
                            <div
                                className="d-flex flex-row"
                                onClick={() => {
                                    addOrRemoveField(subject);
                                }}
                            >
                                {results.some((result: any) => result.id === subject.id) ? (
                                    <MdOutlineDone color="#3367cd" size={18} className="me-1"/>
                                ) : (
                                    <AiFillPlusCircle
                                        color="#ecf0ff"
                                        size={20}
                                        className="me-1"
                                    />
                                )}

                                <p className="formSelectChoice">{subject.title}</p>
                            </div>

                            <hr className="hr-text-grey mt-0"/>
                        </>
                    );
                })}
            </div>
        );
    };

    return (
        <>
            <label
                htmlFor="firstName"
                className={
                    !choices().length
                        ? "label-grey-normal formControl"
                        : "label-black-normal formControl"
                }
                color={"#3367CD"}
                onClick={() => (choices().length > 0 ? setOpen(!open) : setOpen(false))}
            >
                {!choices().length ? "Aucun sujet n'est disponible" : label}
                {!choices().length ? (
                    <BiError className="float-end" size={17} color="#8d8d8d"/>
                ) : (
                    <AiOutlineCaretDown color="#BDBDBD" className="float-end"/>
                )}
            </label>

            {open ? (
                <Field
                    component={() => CustomSelectOption()}
                    as="select"
                    id={name}
                    name={name}
                    options={choices()[categoryId].subjects}
                    onClick={() => setOpen(false)}
                    className="formSelect"
                    multiple={true}
                    isMulti={true}
                    {...props}
                ></Field>
            ) : (
                <div className="row">
                    {matches.large ? (
                        <div className="expertiseList mt-2">{subjectList.flat()}</div>
                    ) : (
                        <div className="expertiseList justify-content-center mt-2">
                            <Box
                                content={`${subjectList.flat().length} sujets renseignés`}
                            ></Box>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};
export default Selects;
