import DatePicker from "react-date-picker";

import "../../styles/forms.scss";

const Calendar = (props: any) => {
  return (
    <DatePicker
      className="formControl"
      clearIcon={null}
      isOpen={props.open}
      calendarClassName="calendar"
      {...props}
    />
  );
};
export default Calendar;
