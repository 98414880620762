import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import GamificationService from "../../services/gamification.service";
import { GamificationAdmin, GamificationAdminData, GamificationData } from "../../config/gamification";

export interface GamificationState {
    gamificationData: any[];
    gamificationAdminData: GamificationAdmin[];
    error:string | null;
    success:string,
    loading:string;
}

const initialState: GamificationState = {
    gamificationData: GamificationData,
    gamificationAdminData: GamificationAdminData,
    error:"",
    success:"",
    loading:"inactive",
};

// Retrieve Gamification Data
export const retrieveGamification = createAsyncThunk(
    "gamification",
    async (props: any) => {
        let { userId, type } = props;
        const res = await GamificationService.getGamificationData(userId, type);
        return res;
    }
);

// Retrieve Gamification Admin Data
export const retrieveGamificationAdmin = createAsyncThunk(
    "gamification/admin",
    async () => {
        const res = await GamificationService.getGamificationAdminData();
        return res;
    }
);

// Edit Gamification Admin Data
export const editGamificationAdminData = createAsyncThunk(
    "gamificationEdit/admin",
    async (props: any) => {
        const res = await GamificationService.editGamificationAdminData(props);
        return res;
    }
);

export const gamificationSlice = createSlice({
    name: "gamification",
    initialState,
    reducers: {
        setStatus (state) {
            state.loading = "inactive"
    },
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                retrieveGamification.fulfilled,
                (state: GamificationState, action: any) => {
                    state.gamificationData = action.payload.data;
                }
            )
            .addCase(
                retrieveGamificationAdmin.fulfilled,
                (state: GamificationState, action: any) => {
                    state.gamificationAdminData = action.payload;
                }
            )
            .addCase(
                editGamificationAdminData.pending,(state:GamificationState) => {
                    state.error = null;
                    state.loading = "true";
                }
            )
            .addCase(
                editGamificationAdminData.fulfilled, (state:GamificationState) => {
                    state.loading = "false";
                    state.success = "Vos données ont bien été mis à jour";
                }
            )
            .addCase(
                editGamificationAdminData.rejected, (state:GamificationState) => {
                    state.loading = "false";
                    state.error = "La mise à jour de vos données a été échouée";
                }
            )
    },
});

export const selectGamificationData = (state: RootState) =>
    state.gamification.gamificationData
export const selectGamificationAdminData = (state: RootState) =>
    state.gamification.gamificationAdminData
export const selectError = (state: RootState) => state.gamification.error;
export const selectLoading = (state: RootState) => state.gamification.loading;
export const selectSuccess = (state: RootState) => state.gamification.success;
export const gamificationActions = gamificationSlice.actions;
export default gamificationSlice.reducer;
