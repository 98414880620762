import { Col, Card, CardBody, Row, Spinner } from "reactstrap";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";
import TrophiesSlide from "./components/TrophiesSlide";
import BadgeSlide from "./components/BadgeSlide";
import { useMedia } from "react-media";

SwiperCore.use([Pagination, Navigation]);

const GamificationContainer = (props: any) => {
  const { isAlumini, gamificationData, status } = props;
  const GLOBAL_MEDIA_QUERIES = {
    small: "(max-width: 855px)",
    medium: "(min-width: 856px) and (max-width: 1530px)",
    large: "(min-width: 1531px)",
  };
  const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
  return (
    <Row className="center-container mb-4 mt-4">
      <Col md="12" lg="12" sm="12" xs="12">
        <Card
          body
          color="light"
          outline
          className="card p-0"
          style={{ zIndex: 0 }}
        >
          <CardBody className="d-flex pt-0 pb-0">
            {status === "pending" ? (
              <div className="d-flex justify-content-center align-items-center w-100">
                <Spinner animation="border" style={{ color: "#3367cd" }} />
              </div>
            ) : (
              <Col
                md="12"
                sm="12"
                xs="12"
                style={{ maxHeight: 190, minHeight: 190 }}
              >
                <Row className="mt-20 center-container">
                  <Col
                    md="12"
                    lg="12"
                    sm="12"
                    xs="12"
                    className="center-col mb-4"
                  >
                    <Swiper
                      className="mySwiper"
                      style={{
                        minHeight: 160,
                      }}
                      slidesPerView={
                        matches.medium ? 2 : matches.small ? 1 : 3.2
                      }
                      modules={[Navigation, Pagination]}
                      navigation
                      spaceBetween={10}
                      initialSlide={isAlumini ? 4 : 0}
                      pagination={{
                        clickable: true,
                        bulletClass: `swiper-pagination-bullet`,
                        renderBullet: (index, className) => {
                          return `<span class="${className} feature-pagination"></span>`;
                        },
                      }}
                    >
                      {gamificationData &&
                        gamificationData.map((gamificationObject: any) => {
                          return (
                            <SwiperSlide
                              className="swiper-slide"
                              key={
                                gamificationObject.role === "Relecteur"
                                  ? 0
                                  : gamificationObject.role === "Contributeur"
                                  ? 1
                                  : gamificationObject.role === "Animateur"
                                  ? 2
                                  : gamificationObject.role === "Learner"
                                  ? 4
                                  : 3
                              }
                            >
                              {gamificationObject.trophyType === "badge" ? (
                                <BadgeSlide
                                  gamificationObject={gamificationObject}
                                  oneSlideDisplay={matches.small}
                                />
                              ) : (
                                <TrophiesSlide
                                  gamificationObject={gamificationObject}
                                  oneSlideDisplay={matches.small}
                                />
                              )}
                            </SwiperSlide>
                          );
                        })}
                    </Swiper>
                  </Col>
                </Row>
              </Col>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default GamificationContainer;
