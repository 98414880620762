import { Button, Card, CardBody, Col, Row, Spinner } from "reactstrap";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  editGamificationAdminData,
  gamificationActions,
  retrieveGamificationAdmin,
  selectSuccess,
} from "../../../../redux/slices/gamificationSlice";
import { useEffect, useState, useRef } from "react";
import {
  selectError,
  selectLoading,
} from "../../../../redux/slices/gamificationSlice";
import NotificationAlert from "react-notification-alert";

const ActionsByRole = (props: any) => {
  const { scrollable } = props;
  const dispatch = useAppDispatch();
  const [filtredGamificationData, setfiltredGamificationData] = useState<any>({
    Animateur: [{ function_description: "", nb_points: "" }],
    Contributeur: [{ function_description: "", nb_points: "" }],
    Createur: [{ function_description: "", nb_points: "" }],
    Learner: [{ function_description: "", nb_points: "" }],
    Relecteur: [{ function_description: "", nb_points: "" }],
  });
  const [oldValues,setOldValues] = useState<any>({
    Animateur: [{ function_description: "", nb_points: "" }],
    Contributeur: [{ function_description: "", nb_points: "" }],
    Createur: [{ function_description: "", nb_points: "" }],
    Learner: [{ function_description: "", nb_points: "" }],
    Relecteur: [{ function_description: "", nb_points: "" }],
  });
  const [relecteur,setRelecteur]=useState([{id:'',nb_points:''}]);
  const [animateur,setAnimateur]=useState([{id:'',nb_points:''}]);
  const [contributeur,setContributeur]=useState([{id:'',nb_points:''}]);
  const [learner,setLearner]=useState([{id:'',nb_points:''}]);
  const [createur,setCreateur]=useState([{id:'',nb_points:''}]);
  const notificationAlert = useRef(null);
  const error = useAppSelector(selectError);
  const success = useAppSelector(selectSuccess);
  const status = useAppSelector(selectLoading);
  const [edit, setEdit] = useState(false);

  //Effect change listener
  useEffect(() => {
    if (status === "false") {
      //@ts-ignore
      notificationAlert.current.notificationAlert({
        place: "tr",
        message: (
          <div>
            <div>{error ? error : success ? success : null}</div>
          </div>
        ),
        type: error ? "danger" : "success",
        icon: "nc-icon nc-bell-55",
        closeButton: false,
        autoDismiss: 7,
      });
      dispatch(gamificationActions.setStatus());
    }
  }, [error, status]);

  useEffect(() => {
    dispatch(retrieveGamificationAdmin()).then((result: any) => {
      setfiltredGamificationData(
        result.payload.actions.reduce((rolesSoFar: any, role: any) => {
          if (!rolesSoFar[role.function]) rolesSoFar[role.function] = [];
          rolesSoFar[role.function].push(role);
          return rolesSoFar;
        }, {})
      );
      setOldValues(
        result.payload.actions.reduce((rolesSoFar: any, role: any) => {
          if (!rolesSoFar[role.function]) rolesSoFar[role.function] = [];
          rolesSoFar[role.function].push(role);
          return rolesSoFar;
        }, {})
      );
    });
  }, [dispatch]);

  return (
    <div className="center-container">
      <NotificationAlert ref={notificationAlert} />
      <Row className="p-2 ml-2">
        <Col md="12">
          <Row className="p-2 ml-2">
            <Col>
              <div className="label-black-md pt-3 ml-4 mb-2">
                Liste d’actions par rôle{" "}
              </div>
            </Col>
            <Col>
              <Button
                style={{ marginTop: 55 }}
                className="btn-round-sm float-end mb-0 pointer"
                color="primary"
                type="submit"
                onClick={() => {
                  setEdit(!edit);
                  if (edit) {
                    const values = [...relecteur,...contributeur,...learner,...animateur,...createur];
                    const updatedValues = values.filter((value:any)=>value.id !== '')
                    dispatch(
                      editGamificationAdminData({
                        actions: updatedValues
                      })
                    );
                    setRelecteur([{id:'',nb_points:''}]);
                    setContributeur([{id:'',nb_points:''}]);
                    setLearner([{id:'',nb_points:''}]);
                    setAnimateur([{id:'',nb_points:''}]);
                    setCreateur([{id:'',nb_points:''}]);
                  }
                }}
              >
                {status === "true" && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="me-1"
                  />
                )}

                {edit ? "Valider" : "Editer"}
              </Button>
            </Col>
          </Row>
        </Col>
        <Row style={{ width: "100%", marginLeft: 3 }}>
          <Col className={scrollable ? "overflow-scroll" : ""}>
            <Row className="center-container mb-4">
              <Col md="12">
                <Card
                  body
                  color="light"
                  outline
                  className="card p-0 mt-1 mb-1"
                  style={{ background: "#F0F0F0" }}
                >
                  <CardBody className="pl-1 pb-0 pt-1 mt-1">
                    <Row className="pb-2 w-100">
                      <Col md="4" sm="4" xs="4">
                        <input
                          className="label-black-nqt mb-0 remove-style"
                          value={"Rôle"}
                          disabled
                        />
                      </Col>
                      <Col md="4" sm="4" xs="4">
                        <input
                          className="label-black-nqt mb-0 remove-style"
                          value={"Actions"}
                          disabled
                        />
                      </Col>
                      <Col md="4" sm="4" xs="4">
                        <input
                          className="label-black-nqt mb-0 remove-style"
                          value={"Nombre de points"}
                          disabled
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card body color="light" outline className="card p-0 mt-1 mb-1">
                  <CardBody className="pl-1 pb-0 pt-1 mt-1">
                    <Row className="pb-2 w-100">
                      <Col md="4" sm="4" xs="4">
                        <>
                          <p className="label-black-nqt-gamification mb-0 remove-style">
                            {filtredGamificationData.Relecteur[0].function}
                          </p>
                        </>
                      </Col>

                      <Col md="4" sm="4" xs="4">
                        {filtredGamificationData.Relecteur.map((row: any) => {
                          return (
                            <div className="align-style">
                              <p className="label-black-nqt-gamification mb-0 remove-style">
                                {row.function_description}
                              </p>
                            </div>
                          );
                        })}
                      </Col>

                      <Col md="4" sm="4" xs="4">
                        {filtredGamificationData.Relecteur.map(
                          (row: any, key: any) => {
                            return edit ? (
                              <div className="container-align-items">
                                <input
                                  type="number"
                                  className="style-nombre-point label-black-nqt-gamification mb-0"
                                  value={row.nb_points}
                                  onChange={(e) => {
                                    const target = oldValues.Relecteur.filter((section:any)=>section.id === row.id);
                                    if(target.nb_points !== row.nb_points)
                                    {
                                      const filterRelecteur = relecteur.filter((value:any)=>value.id !== row.id);
                                      setRelecteur([...filterRelecteur,{id:row.id,nb_points:e.target.value}]);
                                    }
                                    setfiltredGamificationData({
                                      ...filtredGamificationData,
                                      ...(filtredGamificationData.Relecteur[
                                        key
                                      ] = {
                                        ...row,
                                        nb_points: e.target.value,
                                      }),
                                    });
                                  }}
                                />
                              </div>
                            ) : (
                              <div className="align-style">
                                <p className="label-black-nqt-gamification mb-0 remove-style">
                                  {row.nb_points} pts
                                </p>
                              </div>
                            );
                          }
                        )}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <Card body color="light" outline className="card p-0 mt-1 mb-1">
                  <CardBody className="pl-1 pb-0 pt-1 mt-1">
                    <Row className="pb-2 w-100">
                      <Col md="4" sm="4" xs="4">
                        <p className="label-black-nqt-gamification mb-0 remove-style">
                          {filtredGamificationData.Animateur[0].function}
                        </p>
                      </Col>

                      <Col md="4" sm="4" xs="4">
                        {filtredGamificationData.Animateur.map((row: any) => {
                          return (
                            <div className="align-style">
                              <p className="label-black-nqt-gamification mb-0 remove-style">
                                {row.function_description}
                              </p>
                            </div>
                          );
                        })}
                      </Col>

                      <Col md="4" sm="4" xs="4">
                        {filtredGamificationData.Animateur.map(
                          (row: any, key: any) => {
                            return edit ? (
                              <input
                                type="number"
                                className="style-nombre-point label-black-nqt-gamification mb-0"
                                value={row.nb_points}
                                onChange={(e) => {
                                  const target = oldValues.Animateur.filter((section:any)=>section.id === row.id);
                                  if(target.nb_points !== row.nb_points)
                                  {
                                    const filterAnimateur = animateur.filter((value:any)=>value.id !== row.id);
                                    setAnimateur([...filterAnimateur,{id:row.id,nb_points:e.target.value}]);
                                  }
                                  setfiltredGamificationData({
                                    ...filtredGamificationData,
                                    ...(filtredGamificationData.Animateur[key] =
                                      { ...row, nb_points: e.target.value }),
                                  });
                                }}
                              />
                            ) : (
                              <div className="align-style">
                                <p className="label-black-nqt-gamification mb-0 remove-style">
                                  {row.nb_points} pts
                                </p>
                              </div>
                            );
                          }
                        )}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <Card body color="light" outline className="card p-0 mt-1 mb-1">
                  <CardBody className="pl-1 pb-0 pt-1 mt-1">
                    <Row className="pb-2 w-100">
                      <Col md="4" sm="4" xs="4">
                        <p className="label-black-nqt-gamification mb-0 remove-style">
                          {filtredGamificationData.Contributeur[0].function}
                        </p>
                      </Col>

                      <Col md="4" sm="4" xs="4">
                        {filtredGamificationData.Contributeur.map(
                          (row: any) => {
                            return (
                              <div className="align-style">
                                <p className="label-black-nqt-gamification mb-0 remove-style">
                                  {row.function_description}
                                </p>
                              </div>
                            );
                          }
                        )}
                      </Col>

                      <Col md="4" sm="4" xs="4">
                        {filtredGamificationData.Contributeur.map(
                          (row: any, key: any) => {
                            return edit ? (
                              <div className="container-align-items">
                                <input
                                  type="number"
                                  className="style-nombre-point label-black-nqt-gamification mb-0"
                                  value={row.nb_points}
                                  onChange={(e) => {
                                    const target = oldValues.Contributeur.filter((section:any)=>section.id === row.id);
                                    if(target.nb_points !== row.nb_points)
                                    {
                                      const filterContributeur = contributeur.filter((value:any)=>value.id !== row.id);
                                      setContributeur([...filterContributeur,{id:row.id,nb_points:e.target.value}]);
                                    }
                                    setfiltredGamificationData({
                                      ...filtredGamificationData,
                                      ...(filtredGamificationData.Contributeur[
                                        key
                                      ] = {
                                        ...row,
                                        nb_points: e.target.value,
                                      }),
                                    });
                                  }}
                                />
                              </div>
                            ) : (
                              <div className="align-style">
                                <p className="label-black-nqt-gamification mb-0 remove-style">
                                  {row.nb_points} pts
                                </p>
                              </div>
                            );
                          }
                        )}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <Card body color="light" outline className="card p-0 mt-1 mb-1">
                  <CardBody className="pl-1 pb-0 pt-1 mt-1">
                    <Row className="pb-2 w-100">
                      <Col md="4" sm="4" xs="4">
                        <p className="label-black-nqt-gamification mb-0 remove-style">
                          {filtredGamificationData.Createur[0].function}
                        </p>
                      </Col>

                      <Col md="4" sm="4" xs="4">
                        {filtredGamificationData.Createur.map((row: any) => {
                          return (
                            <div className="align-style">
                              <p className="label-black-nqt-gamification mb-0 remove-style">
                                {row.function_description}
                              </p>
                            </div>
                          );
                        })}
                      </Col>

                      <Col md="4" sm="4" xs="4">
                        {filtredGamificationData.Createur.map(
                          (row: any, key: any) => {
                            return edit ? (
                              <div className="container-align-items">
                                <input
                                  type="number"
                                  className="style-nombre-point label-black-nqt-gamification mb-0"
                                  value={row.nb_points}
                                  onChange={(e: any) => {
                                    const target = oldValues.Createur.filter((section:any)=>section.id === row.id);
                                    if(target.nb_points !== row.nb_points)
                                    {
                                      const filterCreateur = createur.filter((value:any)=>value.id !== row.id);
                                      setCreateur([...filterCreateur,{id:row.id,nb_points:e.target.value}]);
                                    }
                                    setfiltredGamificationData({
                                      ...filtredGamificationData,
                                      ...(filtredGamificationData.Createur[
                                        key
                                      ] = {
                                        ...row,
                                        nb_points: e.target.value,
                                      }),
                                    });
                                  }}
                                />
                              </div>
                            ) : (
                              <div className="align-style">
                                <p className="label-black-nqt-gamification mb-0 remove-style">
                                  {row.nb_points} pts
                                </p>
                              </div>
                            );
                          }
                        )}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <Card body color="light" outline className="card p-0 mt-1 mb-1">
                  <CardBody className="pl-1 pb-0 pt-1 mt-1">
                    <Row className="pb-2 w-100">
                      <Col md="4" sm="4" xs="4">
                        <p className="label-black-nqt-gamification mb-0 remove-style">
                          {filtredGamificationData.Learner[0].function}
                        </p>
                      </Col>

                      <Col md="4" sm="4" xs="4">
                        {filtredGamificationData.Learner.map((row: any) => {
                          return (
                            <div className="align-style">
                              <p className="label-black-nqt-gamification mb-0 remove-style">
                                {row.function_description}
                              </p>
                            </div>
                          );
                        })}
                      </Col>

                      <Col md="4" sm="4" xs="4">
                        {filtredGamificationData.Learner.map(
                          (row: any, key: any) => {
                            return edit ? (
                              <div className="container-align-items">
                                <input
                                  type="number"
                                  className="style-nombre-point label-black-nqt-gamification mb-0"
                                  value={row.nb_points}
                                  onChange={(e) => {
                                    const target = oldValues.Learner.filter((section:any)=>section.id === row.id);
                                    if(target.nb_points !== row.nb_points)
                                    {
                                      const filterLearner = learner.filter((value:any)=>value.id !== row.id);
                                      setLearner([...filterLearner,{id:row.id,nb_points:e.target.value}]);
                                    }
                                    setfiltredGamificationData({
                                      ...filtredGamificationData,
                                      ...(filtredGamificationData.Learner[key] =
                                        { ...row, nb_points: e.target.value }),
                                    });
                                  }}
                                />
                              </div>
                            ) : (
                              <div className="align-style">
                                <p className="label-black-nqt-gamification mb-0 remove-style">
                                  {row.nb_points} pts
                                </p>
                              </div>
                            );
                          }
                        )}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Row>
    </div>
  );
};

export default ActionsByRole;
