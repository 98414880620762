/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { useEffect, useRef, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { useMedia } from "react-media";
import { useHistory } from "react-router";
import { ReactComponent as SendIcon } from "../../../../assets/img/icons/send.svg";
import {
  Button,
  Col,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
} from "reactstrap";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  deleteReviewComment,
  postReviewComment,
  retrieveAllTrainingsReviewComments,
  retrieveReviewComments,
  selectReviewComments,
  selectTargetRoute,
  trainingActions,
} from "../../../../redux/slices/trainingSlice";
import "../../../../styles/preview.scss";
import { selectUser } from "../../../../redux/slices/userSlice";
import {
  assignTrainingToUser,
  selectActiveLink,
  selectValidatedTraining,
} from "../../../../redux/slices/editorSlice";
import EditorCommentModal from "./EditorCommentModal";
import useHttp from "../../../../hooks/use-http";
import EditorService from "../../../../services/editor.service";
import NotificationAlert from "react-notification-alert";
import { Modal } from "react-bootstrap";
import { Calendar } from "react-date-range";
import {training} from "../../../../utils/constant";
import { ReactComponent as AvatarIcon } from '../../../../assets/img/icons/avatar-person.svg'
import { FaEdit, FaTrash } from "react-icons/fa";
import { MdUnpublished } from "react-icons/md";
import ReactTooltip from "react-tooltip";
import ModalConfirmation from "../../../../components/forms/ModalConfirmation";
import TrainingService from "../../../../services/training.services";

const TrainingPreviewHeader = (props: any) => {
  const { trainingId, data, isEmpty } = props;
  const dispatch = useAppDispatch();
  const GLOBAL_MEDIA_QUERIES = {
    small: "(max-width: 991px)",
    large: "(min-width: 992px)",
  };

  const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
  const history = useHistory();
  const comments = useAppSelector(selectReviewComments).flat();
  const [motif, setMotif] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedHour, setSelectedHour] = useState("16");
  const [selectedMinutes, setSelectedMinutes] = useState("00");
  const [isConfirmationUnpublishModalVisible, setConfirmationUnpublishModalVisible]= useState(false);
  const [isConfirmationDeleteModalVisible, setConfirmationDeleteModalVisible]= useState(false);
  const userId = useAppSelector(selectUser).id;
  const isAlumni = useAppSelector(selectActiveLink) === 4;
  const trainingRedux = useAppSelector(selectValidatedTraining).filter(
    (selectedTaining) => selectedTaining.id === trainingId
  )[0];
  const [rowData, setRowData] = useState(trainingRedux);
  const profile = useAppSelector(selectUser);
  const userRole: any = profile.role;
  const isEditor: any = userRole.includes("EDITOR");
  const isEditorAlumni: any = userRole.includes("EDITEUR-ALUMNI");
  const isNqtCollaborator = userRole.includes("NQT_COLLABORATOR");
  const path = isEditor ? "/editor" : (isNqtCollaborator ? "/collaborateur" : "");
  const targetRoute = useAppSelector(selectTargetRoute);

  const checkUrl = window.location.href.indexOf("previewTraining") > -1;
  useEffect(() => {
    if(!isEmpty)
    {
      dispatch(
          retrieveReviewComments({
            formationId: trainingId,
          })
      );
    }
  }, [dispatch, trainingId]);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const [commentValue, setCommentValue] = useState("");
  const getCommentValue = (event: any) => {
    setCommentValue(event.target.value);
  };
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalCancelShow, setModalCancelShow] = useState(false);
  const [modalProtractShow, setModalProtractShow] = useState(false);
  const [modalPublishShow, setModalPublishShow] = useState(false);
  const [verify, setVerify] = useState(false);
  const notificationAlert = useRef();

  /**
   * Open Right Side Modal
   */
  const openModal = () => {
    setIsOpen(true);
  };

  /**
   * close Right Side Modal
   */
  const closeModal = () => {
    setIsOpen(false);
  };

  const {
    sendRequest,
    error: publishingError,
    status: publishingStatus,
  } = useHttp(EditorService.publishTraining);

  const {
    sendRequest: reject,
    error: rejectError,
    status: rejectStatus,
  } = useHttp(EditorService.rejectTraining);

  const {
    sendRequest: reset,
    error: resetError,
    status: resetStatus,
  } = useHttp(EditorService.resetTraining);

  const {
    sendRequest: schedule,
    error: scheduleError,
    status: scheduleStatus,
  } = useHttp(EditorService.scheduleTraining);

  const {
    sendRequest: alumniSchedule,
    error: AlumniScheduleError,
    status: AlumniScheduleStatus,
  } = useHttp(EditorService.scheduleAlumniTraining);

  const {
    sendRequest: publishAlumniTraining,
    error: alumniPublishingError,
    status: alumniPublishingStatus,
  } = useHttp(EditorService.publishAlumniTraining);

  const {
    sendRequest: unpublishTraining,
    status: unpublishTrainingStatus,
    error: unpublishTrainingError
  } = useHttp(TrainingService.unpublishTraining);

  const {
    sendRequest: deleteTraining,
    status: deleteTrainingStatus,
    error: deleteTrainingError,
  } = useHttp(TrainingService.deleteTraining);

  useEffect(() => {
    if (
      publishingStatus === "completed" ||
      alumniPublishingStatus === "completed"
    ) {
      //@ts-ignore
      notificationAlert.current.notificationAlert({
        place: "tr",
        message:
          publishingError === null && alumniPublishingError === null ? (
            <div>
              {" "}
              <p>Formation publiée avec succès</p>
              <p
                onClick={() => reset(trainingId)}
                className="label-blue-md pointer"
              >
                annuler
              </p>
            </div>
          ) : (
            <div>{publishingError ?? alumniPublishingError}</div>
          ),
        type:
          publishingError === null && alumniPublishingError === null
            ? "success"
            : "danger",
        icon: "nc-icon nc-bell-55",
        closeButton: false,
        autoDismiss: 7,
      });
    }
  }, [
    publishingStatus,
    publishingError,
    alumniPublishingStatus,
    alumniPublishingError,
  ]);

  useEffect(() => {
    if (rejectStatus === "completed") {
      //@ts-ignore
      notificationAlert.current.notificationAlert({
        place: "tr",
        message:
          rejectError === null ? (
            <div> {"Formation refusée avec succès"}</div>
          ) : (
            <div>{rejectError}</div>
          ),
        type: rejectError === null ? "success" : "danger",
        icon: "nc-icon nc-bell-55",
        closeButton: false,
        autoDismiss: 7,
      });
    }
  }, [rejectStatus, rejectError]);

  useEffect(() => {
    if (resetStatus === "completed") {
      //@ts-ignore
      notificationAlert.current.notificationAlert({
        place: "tr",
        message:
          resetError === null ? (
            <div> {"Formation non publiée"}</div>
          ) : (
            <div>{resetError}</div>
          ),
        type: resetError === null ? "success" : "danger",
        icon: "nc-icon nc-bell-55",
        closeButton: false,
        autoDismiss: 7,
      });
    }
  }, [resetStatus, resetError]);

  useEffect(() => {
    if (
      scheduleStatus === "completed" ||
      AlumniScheduleStatus === "completed"
    ) {
      //@ts-ignore
      notificationAlert.current.notificationAlert({
        place: "tr",
        message:
          scheduleError === null && AlumniScheduleError === null ? (
            <div> {"Formation programmée avec succès"}</div>
          ) : (
            <div>{scheduleError ?? AlumniScheduleError}</div>
          ),
        type:
          scheduleError === null && AlumniScheduleError === null
            ? "success"
            : "danger",
        icon: "nc-icon nc-bell-55",
        closeButton: false,
        autoDismiss: 7,
      });
    }
  }, [
    scheduleStatus,
    scheduleError,
    AlumniScheduleStatus,
    AlumniScheduleError,
  ]);

  useEffect(() => {
    if (unpublishTrainingStatus === "completed") {
      //@ts-ignore
      notificationAlert.current.notificationAlert({
        place: "tr",
        message:
          unpublishTrainingError === null ? (
            <div>
              {" "}
              <p>Formation dépubliée avec succèes</p>
            </div>
          ) : (
            <div>{unpublishTrainingError}</div>
          ),
        type: unpublishTrainingError === null ? "success" : "danger",
        icon: "nc-icon nc-bell-55",
        closeButton: false,
        autoDismiss: 7,
      });
    }
  }, [unpublishTrainingStatus, unpublishTrainingError]);

  useEffect(() => {
    if (deleteTrainingStatus === "completed" && deleteTrainingError) {
      //@ts-ignore
      notificationAlert.current.notificationAlert({
        place: "tr",
        message: <div>{deleteTrainingError}</div>,
        type: "danger",
        icon: "nc-icon nc-bell-55",
        closeButton: false,
        autoDismiss: 7,
      });
    }
  }, [deleteTrainingStatus, deleteTrainingError]);

   useEffect(() => {
    if(deleteTrainingError || deleteTrainingStatus !== 'completed') return
 
    if (!verify) {
      history.push("/" + targetRoute, {
        fromDeleteTraining: true
      });
    } else if (verify) {
      if(isEditor || isNqtCollaborator) {
        history.push(path, {
          fromDeleteTraining: true
        });
      } else {
        history.push("/home", {
          fromDeleteTraining: true
        });
      }
    }
  }, [verify, deleteTrainingStatus, deleteTrainingError])

  return matches.large ? (
    <>
      {/**
      //@ts-ignore */}
      <NotificationAlert ref={notificationAlert} />
      <Row className="headerPreview px-4" style={{ marginRight: 0 }}>
        <Col className="p-3 d-flex pt-4 pe-0">
          <svg
            width="22"
            height="15"
            viewBox="0 0 22 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11 0C6 0 1.73 3.11 0 7.5C1.73 11.89 6 15 11 15C16 15 20.27 11.89 22 7.5C20.27 3.11 16 0 11 0ZM11 12.5C8.24 12.5 6 10.26 6 7.5C6 4.74 8.24 2.5 11 2.5C13.76 2.5 16 4.74 16 7.5C16 10.26 13.76 12.5 11 12.5ZM11 4.5C9.34 4.5 8 5.84 8 7.5C8 9.16 9.34 10.5 11 10.5C12.66 10.5 14 9.16 14 7.5C14 5.84 12.66 4.5 11 4.5Z"
              fill="#3367CD"
            />
          </svg>
          {(data.status === "draft" || data.status === "previewDetail") && (
            <div className="label-black-md pl-10">Aperçu de formation</div>
          )}
          {data.status === "pending" &&
            (data.validation_status === "attente_notation" ||
              // eslint-disable-next-line no-mixed-operators
              data.validation_status === "attente_relecture") &&
            // eslint-disable-next-line no-mixed-operators
            data.is_relecteur === true || data.status==="rejected" && data && data.validation_status === "validated" && (
              <div className="d-flex">
                <div className="label-blue-md pl-10">Relecture</div>
                <div className="label-black-md pl-2"> — {data.title}</div>
              </div>
            )}
          {data.status === "pending" &&
            data.validation_status === "attente_relecture" && (
              <div className="d-flex">
                <div className="label-blue-md pl-10">Relecture</div>
                <div className="label-black-md pl-2"> — {data.title}</div>
              </div>
            )}
          {data.status === "pending" && data.validation_status === "validated" && (
            <div className="d-flex">
              <div className="label-blue-md pl-10">
                Validation de publication
              </div>
              <div className="label-black-md pl-2"> — {data.title}</div>
            </div>
          )}
          {data.status === "published" && data.validation_status === "validated" && (
              <div className="d-flex">
                <div className="label-blue-md pl-10">
                 Aperçu
                </div>
                <div className="label-black-md pl-2"> — {data.title}</div>
              </div>
          )}
        </Col>
          
        {(isEditor || isEditorAlumni) && 
          <Col className="d-flex justify-content-start align-items-center ps-1">
            {data.status !== 'unpublished' && (
              <>
                <Button
                  className="btn btn-sm btn-round-sm"
                  onClick={() => history.push(`/training/${data.id}`)}
                  data-tip="Modifier la formation"
                  data-for="editTooltip"
                >
                  <FaEdit size="20" />
                </Button>
                <ReactTooltip id="editTooltip" place="top" effect="solid" />
              </>
            )}

            {data.status === 'published' && (
              <>
                <Button
                  className="btn btn-sm btn-round-sm"
                  color="warning"
                  data-tip="Dépublié la formation"
                  data-for="unpublishedTooltip"
                  onClick={() => setConfirmationUnpublishModalVisible(true)}
                  disabled={(unpublishTrainingStatus === 'completed' && !unpublishTrainingError) || unpublishTrainingStatus === 'pending'}
                >
                  <MdUnpublished size="20" />
                </Button>
                <ReactTooltip id="unpublishedTooltip" place="top" effect="solid" />
                <ModalConfirmation
                  confirm={() => {
                    unpublishTraining(data.id)
                    setConfirmationUnpublishModalVisible(false);
                  }}
                  cancel={() => setConfirmationUnpublishModalVisible(false)}
                  title={"Voulez vous vraiment dépublier cette formation ?"}
                  isVisible={isConfirmationUnpublishModalVisible}
                />
              </>
            )}

            <Button
              className="btn btn-sm btn-round-sm"
              color="danger"
              data-tip="Supprimer la formation"
              data-for="deleteTooltip"
              onClick={() => {
                setVerify(true)
                setConfirmationDeleteModalVisible(true)
              }}
              disabled={(deleteTrainingStatus === 'completed' && !deleteTrainingError) || deleteTrainingStatus === 'pending'}
            >
              <FaTrash size="20" />
            </Button>
            <ReactTooltip id="deleteTooltip" place="top" effect="solid" />
            <ModalConfirmation
              confirm={() => {
                deleteTraining(data.id)
                setConfirmationDeleteModalVisible(false);
              }}
              cancel={() => setConfirmationDeleteModalVisible(false)}
              title={"Voulez vous vraiment supprimer cette formation ?"}
              isVisible={isConfirmationDeleteModalVisible}
            />
          </Col>
        }

        {(data.status === "draft" || data.status === "previewDetail") && (
          <Col className="p-3 d-flex justify-content-end">
            {data.status === "draft" ?
                <div
                    className="label-blue-md pr-10 pointer"
                    onClick={() =>
                        history.push("/training/"+data.id)
                    }
                >
                  Retourner à la création de formation
                </div> :
                <div
                    className="label-blue-md pr-10 pointer"
                    onClick={() => {
                      dispatch(trainingActions.setTraining(data))
                      history.push("/training")
                    }
                    }
                >
                  Retourner à la création de formation
                </div>
            }
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.85 10.4399C11.9447 10.5337 11.9979 10.6615 11.9979 10.7949C11.9979 10.9282 11.9447 11.056 11.85 11.1499L11.15 11.8499C11.0561 11.9445 10.9283 11.9978 10.795 11.9978C10.6617 11.9978 10.5339 11.9445 10.44 11.8499L6 7.40985L1.56 11.8499C1.46612 11.9445 1.33832 11.9978 1.205 11.9978C1.07169 11.9978 0.943888 11.9445 0.850005 11.8499L0.150005 11.1499C0.0553486 11.056 0.00210571 10.9282 0.00210571 10.7949C0.00210571 10.6615 0.0553486 10.5337 0.150005 10.4399L4.59 5.99985L0.150005 1.55985C0.0553486 1.46597 0.00210571 1.33817 0.00210571 1.20485C0.00210571 1.07153 0.0553486 0.943736 0.150005 0.849852L0.850005 0.149852C0.943888 0.055196 1.07169 0.00195313 1.205 0.00195313C1.33832 0.00195313 1.46612 0.055196 1.56 0.149852L6 4.58985L10.44 0.149852C10.5339 0.055196 10.6617 0.00195312 10.795 0.00195312C10.9283 0.00195312 11.0561 0.055196 11.15 0.149852L11.85 0.849852C11.9447 0.943736 11.9979 1.07153 11.9979 1.20485C11.9979 1.33817 11.9447 1.46597 11.85 1.55985L7.41001 5.99985L11.85 10.4399Z"
                fill="#9A9CB1"
              />
            </svg>
          </Col>
        )}

        {/**/}
        {(checkUrl && ((data.status === "pending" && data && data.validation_status === "attente_relecture") || (data.status==="rejected" && data && data.validation_status === "validated"))) && (
            <Col className="p-3 d-flex justify-content-end">
                  <div
                      className="label-blue-md pr-10 pointer"
                      onClick={() => {
                        history.goBack();
                        dispatch(trainingActions.setTraining(training))
                      }
                      }
                  >
                    Retourner
                  </div>
              <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    d="M11.85 10.4399C11.9447 10.5337 11.9979 10.6615 11.9979 10.7949C11.9979 10.9282 11.9447 11.056 11.85 11.1499L11.15 11.8499C11.0561 11.9445 10.9283 11.9978 10.795 11.9978C10.6617 11.9978 10.5339 11.9445 10.44 11.8499L6 7.40985L1.56 11.8499C1.46612 11.9445 1.33832 11.9978 1.205 11.9978C1.07169 11.9978 0.943888 11.9445 0.850005 11.8499L0.150005 11.1499C0.0553486 11.056 0.00210571 10.9282 0.00210571 10.7949C0.00210571 10.6615 0.0553486 10.5337 0.150005 10.4399L4.59 5.99985L0.150005 1.55985C0.0553486 1.46597 0.00210571 1.33817 0.00210571 1.20485C0.00210571 1.07153 0.0553486 0.943736 0.150005 0.849852L0.850005 0.149852C0.943888 0.055196 1.07169 0.00195313 1.205 0.00195313C1.33832 0.00195313 1.46612 0.055196 1.56 0.149852L6 4.58985L10.44 0.149852C10.5339 0.055196 10.6617 0.00195312 10.795 0.00195312C10.9283 0.00195312 11.0561 0.055196 11.15 0.149852L11.85 0.849852C11.9447 0.943736 11.9979 1.07153 11.9979 1.20485C11.9979 1.33817 11.9447 1.46597 11.85 1.55985L7.41001 5.99985L11.85 10.4399Z"
                    fill="#9A9CB1"
                />
              </svg>
            </Col>
        )}
        {/*//*/}
        {data.status === "pending" && data.validation_status === "validated" ? (
            <Col className="p-3 d-flex justify-content-end">
              <div
                  id="cancel"
                  className="action m-0 pt-3"
                  onClick={() => {
                    setModalCancelShow(true);
                  }}
              >
                {" "}
                <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM2 10C2 5.58 5.58 2 10 2C11.85 2 13.55 2.63 14.9 3.69L3.69 14.9C2.63 13.55 2 11.85 2 10ZM10 18C8.15 18 6.45 17.37 5.1 16.31L16.31 5.1C17.37 6.45 18 8.15 18 10C18 14.42 14.42 18 10 18Z"
                      fill="#BDBDBD"
                  />
                </svg>
              </div>
              <div
                  style={{borderLeft: "1px solid #F0F0F0", height: "50px"}}
                  className="me-3 ms-3"
              ></div>
              <div
                  id="protract"
                  className="action m-0 pt-3"
                  onClick={() => {
                    setModalProtractShow(true);
                  }}
              >
                {" "}
                <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      d="M9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18ZM9.78 5H9.72C9.32 5 9 5.32 9 5.72V10.44C9 10.79 9.18 11.12 9.49 11.3L13.64 13.79C13.98 13.99 14.42 13.89 14.62 13.55C14.83 13.21 14.72 12.76 14.37 12.56L10.5 10.26V5.72C10.5 5.32 10.18 5 9.78 5Z"
                      fill="#BDBDBD"
                  />
                </svg>
              </div>
              <div
                  style={{borderLeft: "1px solid #F0F0F0", height: "50px"}}
                  className="me-3 ms-3"
              ></div>
              <div
                  className="label-blue-md pr-10 pointer pt-3"
                  onClick={() => {
                    isAlumni ? setModalPublishShow(true) : sendRequest(trainingId);
                  }}
              >
                Accepter la publication
              </div>
              <div
                  style={{borderLeft: "1px solid #F0F0F0", height: "50px"}}
                  className="me-3 ms-3"
              ></div>
              <div
                  className="label-blue-md pr-10 pointer pt-3"
                  onClick={() => {
                    openModal();
                    dispatch(
                        retrieveAllTrainingsReviewComments({
                          formationId: trainingId,
                        })
                    );
                  }}
              >
                Commentaires
              </div>

              <div className="pt-2 mt-1 ms-1 pointer">
                <svg
                    onClick={() =>
                        history.push({
                          pathname: "/editor",
                        })
                    }
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      d="M11.85 10.4399C11.9447 10.5337 11.9979 10.6615 11.9979 10.7949C11.9979 10.9282 11.9447 11.056 11.85 11.1499L11.15 11.8499C11.0561 11.9445 10.9283 11.9978 10.795 11.9978C10.6617 11.9978 10.5339 11.9445 10.44 11.8499L6 7.40985L1.56 11.8499C1.46612 11.9445 1.33832 11.9978 1.205 11.9978C1.07169 11.9978 0.943888 11.9445 0.850005 11.8499L0.150005 11.1499C0.0553486 11.056 0.00210571 10.9282 0.00210571 10.7949C0.00210571 10.6615 0.0553486 10.5337 0.150005 10.4399L4.59 5.99985L0.150005 1.55985C0.0553486 1.46597 0.00210571 1.33817 0.00210571 1.20485C0.00210571 1.07153 0.0553486 0.943736 0.150005 0.849852L0.850005 0.149852C0.943888 0.055196 1.07169 0.00195313 1.205 0.00195313C1.33832 0.00195313 1.46612 0.055196 1.56 0.149852L6 4.58985L10.44 0.149852C10.5339 0.055196 10.6617 0.00195312 10.795 0.00195312C10.9283 0.00195312 11.0561 0.055196 11.15 0.149852L11.85 0.849852C11.9447 0.943736 11.9979 1.07153 11.9979 1.20485C11.9979 1.33817 11.9447 1.46597 11.85 1.55985L7.41001 5.99985L11.85 10.4399Z"
                      fill="#9A9CB1"
                  />
                </svg>
              </div>
              <EditorCommentModal
                  trainingId={trainingId}
                  modalIsOpen={modalIsOpen}
                  closeModal={closeModal}
              />
            </Col>
        )
          : data.status === 'published' ?
          (   <Col className="p-3 d-flex justify-content-end">
                <div
                    className="label-blue-md pr-10 pointer pt-3"
                    onClick={() => {
                      openModal();
                      dispatch(
                          retrieveAllTrainingsReviewComments({
                            formationId: trainingId,
                          })
                      );
                    }}
                >
                  Commentaires
                </div>

                <div className="pt-2 mt-1 ms-1 pointer">
                  <svg
                      onClick={() =>
                          history.push({
                            pathname: "/editor",
                          })
                      }
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                        d="M11.85 10.4399C11.9447 10.5337 11.9979 10.6615 11.9979 10.7949C11.9979 10.9282 11.9447 11.056 11.85 11.1499L11.15 11.8499C11.0561 11.9445 10.9283 11.9978 10.795 11.9978C10.6617 11.9978 10.5339 11.9445 10.44 11.8499L6 7.40985L1.56 11.8499C1.46612 11.9445 1.33832 11.9978 1.205 11.9978C1.07169 11.9978 0.943888 11.9445 0.850005 11.8499L0.150005 11.1499C0.0553486 11.056 0.00210571 10.9282 0.00210571 10.7949C0.00210571 10.6615 0.0553486 10.5337 0.150005 10.4399L4.59 5.99985L0.150005 1.55985C0.0553486 1.46597 0.00210571 1.33817 0.00210571 1.20485C0.00210571 1.07153 0.0553486 0.943736 0.150005 0.849852L0.850005 0.149852C0.943888 0.055196 1.07169 0.00195313 1.205 0.00195313C1.33832 0.00195313 1.46612 0.055196 1.56 0.149852L6 4.58985L10.44 0.149852C10.5339 0.055196 10.6617 0.00195312 10.795 0.00195312C10.9283 0.00195312 11.0561 0.055196 11.15 0.149852L11.85 0.849852C11.9447 0.943736 11.9979 1.07153 11.9979 1.20485C11.9979 1.33817 11.9447 1.46597 11.85 1.55985L7.41001 5.99985L11.85 10.4399Z"
                        fill="#9A9CB1"
                    />
                  </svg>
                </div>
                <EditorCommentModal
                    trainingId={trainingId}
                    modalIsOpen={modalIsOpen}
                    closeModal={closeModal}
                />
              </Col>
          ) : null
        }

        {!checkUrl && data.status === "pending" &&
          data.validation_status === "attente_relecture" && (
            <Col className="p-3 d-flex justify-content-end">
              <div
                className="label-blue-md pr-10 pointer"
                onClick={() => {
                  dispatch(
                    assignTrainingToUser({
                      formationId: [trainingId],
                      userId: [userId],
                    })
                  );
                  dispatch(trainingActions.setSelfAssign());
                }}
              >
                Accepter l’assignation
              </div>
              <svg
                onClick={() =>
                  history.push({
                    pathname: path ? path : "/home",
                  })
                }
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.85 10.4399C11.9447 10.5337 11.9979 10.6615 11.9979 10.7949C11.9979 10.9282 11.9447 11.056 11.85 11.1499L11.15 11.8499C11.0561 11.9445 10.9283 11.9978 10.795 11.9978C10.6617 11.9978 10.5339 11.9445 10.44 11.8499L6 7.40985L1.56 11.8499C1.46612 11.9445 1.33832 11.9978 1.205 11.9978C1.07169 11.9978 0.943888 11.9445 0.850005 11.8499L0.150005 11.1499C0.0553486 11.056 0.00210571 10.9282 0.00210571 10.7949C0.00210571 10.6615 0.0553486 10.5337 0.150005 10.4399L4.59 5.99985L0.150005 1.55985C0.0553486 1.46597 0.00210571 1.33817 0.00210571 1.20485C0.00210571 1.07153 0.0553486 0.943736 0.150005 0.849852L0.850005 0.149852C0.943888 0.055196 1.07169 0.00195313 1.205 0.00195313C1.33832 0.00195313 1.46612 0.055196 1.56 0.149852L6 4.58985L10.44 0.149852C10.5339 0.055196 10.6617 0.00195312 10.795 0.00195312C10.9283 0.00195312 11.0561 0.055196 11.15 0.149852L11.85 0.849852C11.9447 0.943736 11.9979 1.07153 11.9979 1.20485C11.9979 1.33817 11.9447 1.46597 11.85 1.55985L7.41001 5.99985L11.85 10.4399Z"
                  fill="#9A9CB1"
                />
              </svg>
            </Col>
          )}

        {data.status === "pending" &&
          data.validation_status === "attente_notation" &&
          data.is_relecteur === true && (
            <Col className="p-3 d-flex justify-content-end">
              <Dropdown isOpen={dropdownOpen} toggle={dropdownToggle}>
                <DropdownToggle caret nav className="d-flex">
                  <div className="label-blue-md pr-10 pointer">
                    Ajouter un commentaire
                  </div>
                </DropdownToggle>
                <DropdownMenu right className="comments-dropdown-menu">
                  <Scrollbars
                    style={{ width: 500, height: 400 }}
                    renderThumbVertical={(prs) => (
                      <div
                        {...prs}
                        className="thumb-vertical-comments-dropdown"
                      />
                    )}
                  >
                    <div className="comments-header p-3">
                      Commentaires ({comments.length})
                    </div>
                    <div className="p-1">
                      {comments.map((value: any, key) => {
                        return (
                          <div
                            className="pt-1 pb-0"
                            style={{ backgroundColor: "#ffff" }}
                          >
                            <div className="d-flex p-2">
                              <Col className="pb-0 pt-3 ps-0">
                                <p className="icon-right comment-label m-0">
                                  {value.comment}
                                </p>
                                <p className="icon-right comment-date-label m-0">
                                  {moment(value.date).calendar()}
                                </p>
                              </Col>
                              <Col
                                xs={2}
                                className="pt-3 pb-0 pe-0 d-flex justify-content-end pointer"
                                onClick={() => {
                                  dispatch(
                                    deleteReviewComment({
                                      formationId: trainingId,
                                      commentId: value.id,
                                    })
                                  );
                                }}
                              >
                                <svg
                                  width="11"
                                  height="14"
                                  viewBox="0 0 11 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M10.6667 2.33333V1.66667C10.6667 1.48257 10.5174 1.33333 10.3333 1.33333H7.33333V0.666667C7.33333 0.298477 7.03486 0 6.66667 0H4C3.63181 0 3.33333 0.298477 3.33333 0.666667V1.33333H0.333333C0.149238 1.33333 0 1.48257 0 1.66667V2.33333C0 2.51743 0.149238 2.66667 0.333333 2.66667H10.3333C10.5174 2.66667 10.6667 2.51743 10.6667 2.33333ZM2.58033 13.3333C1.87891 13.3351 1.29609 12.793 1.24699 12.0933L0.666992 4H10.0003L9.43366 12.0933C9.38456 12.793 8.80174 13.3351 8.10033 13.3333H2.58033Z"
                                    fill="#3367CD"
                                  />
                                </svg>
                              </Col>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </Scrollbars>
                  <hr className="m-0 mt-2 hr-style" />
                  <Row>
                    <Col className="pb-0" md="10">
                      <input
                        className="formControComment label-black-nqt-sm"
                        type="text"
                        style={{ height: "60px" }}
                        placeholder="Bravo pour cette formation, ..."
                        value={commentValue}
                        onChange={getCommentValue}
                      />
                    </Col>
                    <Col>
                      <SendIcon
                        className="right m-3 pointer"
                        onClick={() => {
                          dispatch(
                            postReviewComment({
                              formationId: trainingId,
                              comment: commentValue,
                            })
                          );
                          setCommentValue("");
                        }}
                      />
                    </Col>
                  </Row>
                </DropdownMenu>
              </Dropdown>
              <div className="p-2 pointer" onClick={history.goBack}>
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.85 10.4399C11.9447 10.5337 11.9979 10.6615 11.9979 10.7949C11.9979 10.9282 11.9447 11.056 11.85 11.1499L11.15 11.8499C11.0561 11.9445 10.9283 11.9978 10.795 11.9978C10.6617 11.9978 10.5339 11.9445 10.44 11.8499L6 7.40985L1.56 11.8499C1.46612 11.9445 1.33832 11.9978 1.205 11.9978C1.07169 11.9978 0.943888 11.9445 0.850005 11.8499L0.150005 11.1499C0.0553486 11.056 0.00210571 10.9282 0.00210571 10.7949C0.00210571 10.6615 0.0553486 10.5337 0.150005 10.4399L4.59 5.99985L0.150005 1.55985C0.0553486 1.46597 0.00210571 1.33817 0.00210571 1.20485C0.00210571 1.07153 0.0553486 0.943736 0.150005 0.849852L0.850005 0.149852C0.943888 0.055196 1.07169 0.00195313 1.205 0.00195313C1.33832 0.00195313 1.46612 0.055196 1.56 0.149852L6 4.58985L10.44 0.149852C10.5339 0.055196 10.6617 0.00195312 10.795 0.00195312C10.9283 0.00195312 11.0561 0.055196 11.15 0.149852L11.85 0.849852C11.9447 0.943736 11.9979 1.07153 11.9979 1.20485C11.9979 1.33817 11.9447 1.46597 11.85 1.55985L7.41001 5.99985L11.85 10.4399Z"
                    fill="#9A9CB1"
                  />
                </svg>
              </div>
            </Col>
          )}
      </Row>
      <Modal
        show={modalProtractShow}
        onHide={() => setModalProtractShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton style={{ borderBottom: 0 }}>
          <Row>
            <div className="label-black-xmd">Programmer la publication</div>
            {trainingId ?
            <div className="d-flex">
              {data?.creator?.image ? (
                  <img
                    alt="profile"
                    className="avatar-profile"
                    style={{ maxWidth: 32 }}
                    src={data.creator.image}
                  />
                ) : (
                  <AvatarIcon
                    style={{ width: 32, height: 32, borderRadius: '50%' }}
                  />
                )}
              <div className="label-grey text-center px-2">
                {data?.creator?.firstName} {data?.creator?.lastName}
              </div>
            </div>
                :
                <div className="d-flex">
                  {profile.image ? (
                  <img
                    alt="profile"
                    className="avatar-profile"
                    style={{ maxWidth: 32 }}
                    src={profile.image}
                  />
                ) : (
                  <AvatarIcon
                    style={{ width: 32, height: 32, borderRadius: '50%' }}
                  />
                )}
                  <div className="label-grey text-center px-2">
                    {profile.nom} {profile?.prenom}
                  </div>
                </div>
            }
          </Row>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column ">
          {isAlumni && (
            <>
              <Row>
                <Col className="d-flex">
                  <>
                    <div
                      className="d-flex"
                      style={{ width: 200, cursor: "pointer" }}
                    >
                      <div className="filter-label-date">Publier Pour</div>
                    </div>
                    <div>
                      {" "}
                      <li
                        className="d-flex subjectList"
                        key="all"
                        data-id="all"
                        onClick={() =>
                          setRowData({
                            ...rowData,
                            diffusion_for: "Mentors & Alumni",
                          })
                        }
                      >
                        {rowData?.diffusion_for === "Mentors & Alumni" ? (
                          <div className="checked">
                            <svg
                              width="9"
                              height="7"
                              viewBox="0 0 9 7"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2.75015 4.38408L1.481 3.11494C1.22087 2.85481 0.800078 2.85481 0.539947 3.11494C0.279816 3.37507 0.279816 3.79586 0.539947 4.05599L2.28161 5.79766C2.54175 6.05779 2.96254 6.05779 3.22267 5.79766L7.631 1.38933C7.89113 1.12919 7.89113 0.708403 7.631 0.448272C7.3709 0.188171 6.95018 0.188141 6.69004 0.448183C6.69001 0.448212 6.68998 0.448242 6.68995 0.448272L2.75015 4.38408Z"
                                fill="#3367CD"
                                stroke="#3367CD"
                              />
                            </svg>
                          </div>
                        ) : (
                          <div className="unchecked">
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM14 11H11V14C11 14.55 10.55 15 10 15C9.45 15 9 14.55 9 14V11H6C5.45 11 5 10.55 5 10C5 9.45 5.45 9 6 9H9V6C9 5.45 9.45 5 10 5C10.55 5 11 5.45 11 6V9H14C14.55 9 15 9.45 15 10C15 10.55 14.55 11 14 11Z"
                                fill="#ECF0FB"
                              />
                            </svg>
                          </div>
                        )}

                        <span className="checkBox">Mentors & Alumni</span>
                      </li>
                      <li
                        className="d-flex subjectList"
                        key="Alumni"
                        data-id="Alumni"
                        onClick={() =>
                          setRowData({ ...rowData, diffusion_for: "Alumni" })
                        }
                      >
                        {rowData?.diffusion_for === "Alumni" ? (
                          <div className="checked">
                            <svg
                              width="9"
                              height="7"
                              viewBox="0 0 9 7"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2.75015 4.38408L1.481 3.11494C1.22087 2.85481 0.800078 2.85481 0.539947 3.11494C0.279816 3.37507 0.279816 3.79586 0.539947 4.05599L2.28161 5.79766C2.54175 6.05779 2.96254 6.05779 3.22267 5.79766L7.631 1.38933C7.89113 1.12919 7.89113 0.708403 7.631 0.448272C7.3709 0.188171 6.95018 0.188141 6.69004 0.448183C6.69001 0.448212 6.68998 0.448242 6.68995 0.448272L2.75015 4.38408Z"
                                fill="#3367CD"
                                stroke="#3367CD"
                              />
                            </svg>
                          </div>
                        ) : (
                          <div className="unchecked">
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM14 11H11V14C11 14.55 10.55 15 10 15C9.45 15 9 14.55 9 14V11H6C5.45 11 5 10.55 5 10C5 9.45 5.45 9 6 9H9V6C9 5.45 9.45 5 10 5C10.55 5 11 5.45 11 6V9H14C14.55 9 15 9.45 15 10C15 10.55 14.55 11 14 11Z"
                                fill="#ECF0FB"
                              />
                            </svg>
                          </div>
                        )}

                        <span className="checkBox">Alumnis</span>
                      </li>
                      <li
                        className="d-flex subjectList"
                        key="Mentors"
                        data-id="Mentors"
                        onClick={() =>
                          setRowData({ ...rowData, diffusion_for: "Mentors" })
                        }
                      >
                        {rowData?.diffusion_for === "Mentors" ? (
                          <div className="checked">
                            <svg
                              width="9"
                              height="7"
                              viewBox="0 0 9 7"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2.75015 4.38408L1.481 3.11494C1.22087 2.85481 0.800078 2.85481 0.539947 3.11494C0.279816 3.37507 0.279816 3.79586 0.539947 4.05599L2.28161 5.79766C2.54175 6.05779 2.96254 6.05779 3.22267 5.79766L7.631 1.38933C7.89113 1.12919 7.89113 0.708403 7.631 0.448272C7.3709 0.188171 6.95018 0.188141 6.69004 0.448183C6.69001 0.448212 6.68998 0.448242 6.68995 0.448272L2.75015 4.38408Z"
                                fill="#3367CD"
                                stroke="#3367CD"
                              />
                            </svg>
                          </div>
                        ) : (
                          <div className="unchecked">
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM14 11H11V14C11 14.55 10.55 15 10 15C9.45 15 9 14.55 9 14V11H6C5.45 11 5 10.55 5 10C5 9.45 5.45 9 6 9H9V6C9 5.45 9.45 5 10 5C10.55 5 11 5.45 11 6V9H14C14.55 9 15 9.45 15 10C15 10.55 14.55 11 14 11Z"
                                fill="#ECF0FB"
                              />
                            </svg>
                          </div>
                        )}

                        <span className="checkBox">Mentors</span>
                      </li>
                    </div>
                  </>
                </Col>
              </Row>
              <hr className="line"></hr>
            </>
          )}
          <Row className="d-flex flex-column align-items-center">
            <Col md="9" className="calendar-box">
              <Calendar
                date={selectedDate}
                onChange={(date) => setSelectedDate(date)}
              />
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <p className="label-black mt-3">Heure de publication</p>
              <div className="d-flex">
                <Input
                  name="startTimeHour"
                  onChange={(event) => setSelectedHour(event.target.value)}
                  type="number"
                  min="00"
                  max="23"
                  placeholder="16"
                  style={{
                    padding: 5,
                    textAlign: "center",
                    width: 45,
                    color: "#bdbdbd",
                  }}
                />
                <p
                  className="label-grey-normal mt-3 mx-1"
                  style={{ color: "#BDBDBD" }}
                >
                  h
                </p>
                <Input
                  name="startTimeMinute"
                  onChange={(event) => setSelectedMinutes(event.target.value)}
                  type="number"
                  placeholder="00"
                  max="59"
                  min="00"
                  style={{
                    padding: 5,
                    textAlign: "center",
                    width: 45,
                    color: "#bdbdbd",
                  }}
                />
              </div>
            </Col>
          </Row>
          <hr className="line"></hr>
          <Row>
            <Col className="d-flex">
              <div className="label-grey-md px-2">Le </div>
              <div className="label-blue-md">
                {format(selectedDate, "dd/MM/yyyy", {
                  locale: fr,
                })}
              </div>
              <div className="label-grey-md px-2">à </div>
              <div className="label-blue-md">
                {format(
                  new Date(
                    `${format(selectedDate, "MM/dd/yyyy", {
                      locale: fr,
                    })} ${selectedHour}:${selectedMinutes}`
                  ),
                  "HH:mm",
                  {
                    locale: fr,
                  }
                )}
              </div>
            </Col>
          </Row>
          <Row className="d-flex flex-column align-items-center">
            <Col className="d-flex justify-content-end">
              <Button
                className="btn-round-sm-secondary mt-3 me-2"
                onClick={() => setModalProtractShow(false)}
                color="secondary"
                type="submit"
              >
                Annuler
              </Button>
              <Button
                className="btn-round-sm mt-3"
                disabled={!rowData?.diffusion_for && isAlumni}
                onClick={() => {
                  isAlumni
                    ? alumniSchedule({
                        formationId: rowData?.id,
                        dateHour: format(
                          new Date(
                            `${format(selectedDate, "MM/dd/yyyy", {
                              locale: fr,
                            })} ${selectedHour}:${selectedMinutes}`
                          ),
                          "yyyy-MM-dd HH:mm",
                          {
                            locale: fr,
                          }
                        ),
                        diffusion_for: rowData?.diffusion_for,
                      })
                    : schedule({
                        formationId: rowData?.id,
                        dateHour: format(
                          new Date(
                            `${format(selectedDate, "MM/dd/yyyy", {
                              locale: fr,
                            })} ${selectedHour}:${selectedMinutes}`
                          ),
                          "yyyy-MM-dd HH:mm",
                          {
                            locale: fr,
                          }
                        ),
                      });
                  setModalProtractShow(false);
                }}
                color="primary"
                type="submit"
              >
                Envoyer
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <Modal
        show={modalCancelShow}
        onHide={() => setModalCancelShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton style={{ borderBottom: 0 }}>
          <Row>
            <div className="label-black-xmd">
              Refuser la publication de cette formation
            </div>
            <div className="label-grey">
              Expliquez pourquoi cette formation a été refusée.{" "}
            </div>
          </Row>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column ">
          <Row className="d-flex flex-column ">
            <Col className="d-flex flex-column ">
              <div>
                <textarea
                  onChange={(event) => setMotif(event.target.value)}
                  style={{ width: "100%", height: "84px" }}
                  placeholder="Ex : La formation ne respecte pas ..."
                ></textarea>
              </div>
            </Col>
          </Row>
          <Row className="d-flex flex-column align-items-center">
            <Col className="d-flex justify-content-end">
              <Button
                className="btn-round-sm-secondary mt-3 me-2"
                onClick={() => setModalCancelShow(false)}
                color="secondary"
                type="submit"
              >
                Annuler
              </Button>
              <Button
                className="btn-round-sm mt-3"
                disabled={motif === ""}
                onClick={() => {
                  reject({ formationId: rowData?.id, comment: motif });
                  setModalCancelShow(false);
                }}
                color="primary"
                type="submit"
              >
                Envoyer
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <Modal
        show={modalPublishShow}
        onHide={() => setModalPublishShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton style={{ borderBottom: 0 }}>
          <Row>
            <div className="label-black-xmd">Publication de la formation</div>
            {trainingId ?
                <div className="d-flex">
                  {data?.creator?.image ? (
                  <img
                    alt="profile"
                    className="avatar-profile"
                    style={{ maxWidth: 32 }}
                    src={data.creator.image}
                  />
                ) : (
                  <AvatarIcon
                    style={{ width: 32, height: 32, borderRadius: '50%' }}
                  />
                )}
                  <div className="label-grey text-center px-2">
                    {data?.creator?.firstName} {data?.creator?.lastName}
                  </div>
                </div>
                :
                <div className="d-flex">
                    {profile.image ? (
                  <img
                    alt="profile"
                    className="avatar-profile"
                    style={{ maxWidth: 32 }}
                    src={profile.image}
                  />
                ) : (
                  <AvatarIcon
                    style={{ width: 32, height: 32, borderRadius: '50%' }}
                  />
                )}
                  <div className="label-grey text-center px-2">
                    {profile.nom} {profile.prenom}
                  </div>
                </div>
            }
          </Row>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column ">
          <>
            <Row>
              <Col className="d-flex">
                <>
                  <div
                    className="d-flex"
                    style={{ width: 200, cursor: "pointer" }}
                  >
                    <div className="filter-label-date">Publier Pour</div>
                  </div>
                  <div>
                    {" "}
                    <li
                      className="d-flex subjectList"
                      key="all"
                      data-id="all"
                      onClick={() =>
                        setRowData({
                          ...rowData,
                          diffusion_for: "Mentors & Alumni",
                        })
                      }
                    >
                      {rowData?.diffusion_for === "Mentors & Alumni" ? (
                        <div className="checked">
                          <svg
                            width="9"
                            height="7"
                            viewBox="0 0 9 7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2.75015 4.38408L1.481 3.11494C1.22087 2.85481 0.800078 2.85481 0.539947 3.11494C0.279816 3.37507 0.279816 3.79586 0.539947 4.05599L2.28161 5.79766C2.54175 6.05779 2.96254 6.05779 3.22267 5.79766L7.631 1.38933C7.89113 1.12919 7.89113 0.708403 7.631 0.448272C7.3709 0.188171 6.95018 0.188141 6.69004 0.448183C6.69001 0.448212 6.68998 0.448242 6.68995 0.448272L2.75015 4.38408Z"
                              fill="#3367CD"
                              stroke="#3367CD"
                            />
                          </svg>
                        </div>
                      ) : (
                        <div className="unchecked">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM14 11H11V14C11 14.55 10.55 15 10 15C9.45 15 9 14.55 9 14V11H6C5.45 11 5 10.55 5 10C5 9.45 5.45 9 6 9H9V6C9 5.45 9.45 5 10 5C10.55 5 11 5.45 11 6V9H14C14.55 9 15 9.45 15 10C15 10.55 14.55 11 14 11Z"
                              fill="#ECF0FB"
                            />
                          </svg>
                        </div>
                      )}

                      <span className="checkBox">Mentors & Alumni</span>
                    </li>
                    <li
                      className="d-flex subjectList"
                      key="Alumni"
                      data-id="Alumni"
                      onClick={() =>
                        setRowData({ ...rowData, diffusion_for: "Alumni" })
                      }
                    >
                      {rowData?.diffusion_for === "Alumni" ? (
                        <div className="checked">
                          <svg
                            width="9"
                            height="7"
                            viewBox="0 0 9 7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2.75015 4.38408L1.481 3.11494C1.22087 2.85481 0.800078 2.85481 0.539947 3.11494C0.279816 3.37507 0.279816 3.79586 0.539947 4.05599L2.28161 5.79766C2.54175 6.05779 2.96254 6.05779 3.22267 5.79766L7.631 1.38933C7.89113 1.12919 7.89113 0.708403 7.631 0.448272C7.3709 0.188171 6.95018 0.188141 6.69004 0.448183C6.69001 0.448212 6.68998 0.448242 6.68995 0.448272L2.75015 4.38408Z"
                              fill="#3367CD"
                              stroke="#3367CD"
                            />
                          </svg>
                        </div>
                      ) : (
                        <div className="unchecked">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM14 11H11V14C11 14.55 10.55 15 10 15C9.45 15 9 14.55 9 14V11H6C5.45 11 5 10.55 5 10C5 9.45 5.45 9 6 9H9V6C9 5.45 9.45 5 10 5C10.55 5 11 5.45 11 6V9H14C14.55 9 15 9.45 15 10C15 10.55 14.55 11 14 11Z"
                              fill="#ECF0FB"
                            />
                          </svg>
                        </div>
                      )}

                      <span className="checkBox">Alumnis</span>
                    </li>
                    <li
                      className="d-flex subjectList"
                      key="Mentors"
                      data-id="Mentors"
                      onClick={() =>
                        setRowData({ ...rowData, diffusion_for: "Mentors" })
                      }
                    >
                      {rowData?.diffusion_for === "Mentors" ? (
                        <div className="checked">
                          <svg
                            width="9"
                            height="7"
                            viewBox="0 0 9 7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2.75015 4.38408L1.481 3.11494C1.22087 2.85481 0.800078 2.85481 0.539947 3.11494C0.279816 3.37507 0.279816 3.79586 0.539947 4.05599L2.28161 5.79766C2.54175 6.05779 2.96254 6.05779 3.22267 5.79766L7.631 1.38933C7.89113 1.12919 7.89113 0.708403 7.631 0.448272C7.3709 0.188171 6.95018 0.188141 6.69004 0.448183C6.69001 0.448212 6.68998 0.448242 6.68995 0.448272L2.75015 4.38408Z"
                              fill="#3367CD"
                              stroke="#3367CD"
                            />
                          </svg>
                        </div>
                      ) : (
                        <div className="unchecked">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM14 11H11V14C11 14.55 10.55 15 10 15C9.45 15 9 14.55 9 14V11H6C5.45 11 5 10.55 5 10C5 9.45 5.45 9 6 9H9V6C9 5.45 9.45 5 10 5C10.55 5 11 5.45 11 6V9H14C14.55 9 15 9.45 15 10C15 10.55 14.55 11 14 11Z"
                              fill="#ECF0FB"
                            />
                          </svg>
                        </div>
                      )}

                      <span className="checkBox">Mentors</span>
                    </li>
                  </div>
                </>
              </Col>
            </Row>
            <hr className="line"></hr>
          </>
          <Row className="d-flex flex-column align-items-center">
            <Col className="d-flex justify-content-end">
              <Button
                className="btn-round-sm-secondary mt-3 me-2"
                onClick={() => setModalPublishShow(false)}
                color="secondary"
                type="submit"
              >
                Annuler
              </Button>
              <Button
                className="btn-round-sm mt-3"
                disabled={!rowData?.diffusion_for && isAlumni}
                onClick={() => {
                  isAlumni &&
                    publishAlumniTraining({
                      formationId: trainingId,
                      diffusion_for: rowData.diffusion_for,
                    });

                  setModalPublishShow(false);
                }}
                color="primary"
                type="submit"
              >
                Envoyer
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  ) : (
    <Row className="headerPreview px-4" style={{ marginRight: 0 }}>
      <Col className="m-auto d-flex">
        <svg
          width="20"
          height="14"
          viewBox="0 0 20 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={()=> history.goBack()}
        >
          <path
            d="M20 6.49997V7.49997C20 7.77612 19.7761 7.99997 19.5 7.99997H3.33L7.78 12.44C7.87466 12.5339 7.9279 12.6617 7.9279 12.795C7.9279 12.9283 7.87466 13.0561 7.78 13.15L7.07 13.85C6.97612 13.9446 6.84832 13.9979 6.715 13.9979C6.58168 13.9979 6.45388 13.9446 6.36 13.85L0.22 7.71997C0.0793075 7.57946 0.000175052 7.38882 0 7.18997V6.80997C0.00230401 6.61156 0.081116 6.4217 0.22 6.27997L6.36 0.149974C6.45388 0.055318 6.58168 0.0020752 6.715 0.0020752C6.84832 0.0020752 6.97612 0.055318 7.07 0.149974L7.78 0.859974C7.87406 0.952138 7.92707 1.07828 7.92707 1.20997C7.92707 1.34166 7.87406 1.46781 7.78 1.55997L3.33 5.99997H19.5C19.7761 5.99997 20 6.22383 20 6.49997Z"
            fill="#3367CD"
          />
        </svg>
        {data.status === "pending" &&
          (data.validation_status === "attente_notation" ||
            data.validation_status === "attente_relecture") &&
          data.is_relecteur === true && (
            <div className="d-flex">
              <div className="label-blue-md pl-10">Relecture</div>
              <div className="label-black-md pl-2"> — {data.title}</div>
            </div>
          )}
        {data.status === "pending" &&
          data.validation_status === "attente_relecture" && (
            <div className="d-flex">
              ()
              <div className="label-blue-md pl-10">Relecture</div>
              <div className="label-black-md pl-2"> — {data.title}</div>
            </div>
          )}
        {data.status === "pending" && data.validation_status === "validated" && (
          <div className="d-flex">
            <div className="label-blue-md pl-10">Validation de publication</div>
            <div className="label-black-md pl-2"> — {data.title}</div>
          </div>)
        }
        {data.status === "published" && data.validation_status === "validated" &&  (
            <div className="d-flex">
              <div className="label-blue-md pl-10">Aperçu</div>
              <div className="label-black-md pl-2"> — {data.title}</div>
            </div>)
        }
      </Col>
    </Row>
  );
 };

export default TrainingPreviewHeader;
