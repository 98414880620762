import { useEffect, useState } from "react";
import { AiOutlineCaretDown, AiOutlineCaretUp } from "react-icons/ai";
import { Col, PopoverBody, UncontrolledPopover } from "reactstrap";
import { DateRange } from "react-date-range";
import {
  format,
  endOfWeek,
  startOfWeek,
  addWeeks,
  endOfMonth,
  startOfMonth,
} from "date-fns";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import React from "react";

interface TrainingDate {
  start: string;
  end: string;
}

interface RangeDate {
  startDate?: Date;
  endDate?: Date;
  key?: string;
}

const DateFilters = (props: any) => {
  const { handleTenuChange, handlePubChange, reset, setReset } = props;
  const [open, setOpen] = useState(false);
  const [clearAll, setClearAll] = useState(false);

  const initialState = {
    date: { start: "", end: "" },
    range: [{ startDate: new Date(), endDate: new Date(), key: "selection" }],
  };
  const [pubDate, setPubDate] = useState<TrainingDate>(initialState.date);
  const [tenuDate, setTenuDate] = useState<TrainingDate>(initialState.date);
  const [typeDate, setTypeDate] = useState("");
  const [shortcut, setShortcut] = useState("");
  const [rangeState, setRangeState] = useState<RangeDate[]>(initialState.range);
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleTenuChange(tenuDate);
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [tenuDate, handleTenuChange]);
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handlePubChange(pubDate);
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [pubDate, handlePubChange]);

  useEffect(() => {
    if (reset || clearAll) {
      setPubDate(initialState.date);
      setTenuDate(initialState.date);
      setTypeDate("");
      setShortcut("");
      setRangeState(initialState.range);
    }
    setReset(false);
    setClearAll(false);
  }, [reset, clearAll]);

  const DateElements = (
    <>
      <li
        className="d-flex subjectList"
        key={0}
        data-id={0}
        onClick={() => setTypeDate("pub")}
        style={{ width: 300 }}
      >
        <span className="checkBox">Date de publication</span>
        <div style={{ marginLeft: "auto", paddingRight: 10 }}>
          <svg
            width="7"
            height="12"
            viewBox="0 0 7 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.78016 5.27985C6.92086 5.42037 6.99999 5.61101 7.00016 5.80985V6.18985C6.99786 6.38827 6.91905 6.57813 6.78016 6.71985L1.64016 11.8499C1.54628 11.9445 1.41848 11.9978 1.28516 11.9978C1.15185 11.9978 1.02405 11.9445 0.930165 11.8499L0.220165 11.1399C0.126101 11.0477 0.0730934 10.9215 0.0730934 10.7899C0.0730934 10.6582 0.126101 10.532 0.220165 10.4399L4.67016 5.99985L0.220165 1.55985C0.125508 1.46597 0.0722656 1.33817 0.0722656 1.20485C0.0722656 1.07153 0.125508 0.943736 0.220165 0.849852L0.930165 0.149852C1.02405 0.055196 1.15185 0.00195312 1.28516 0.00195312C1.41848 0.00195312 1.54628 0.055196 1.64016 0.149852L6.78016 5.27985Z"
              fill="#BDBDBD"
            />
          </svg>
        </div>
      </li>
      <li
        className="d-flex subjectList"
        key={1}
        data-id={1}
        onClick={() => setTypeDate("tenu")}
        style={{ width: 300 }}
      >
        <span className="checkBox">Date de tenue de formation</span>
        <div style={{ marginLeft: "auto", paddingRight: 10 }}>
          <svg
            width="7"
            height="12"
            viewBox="0 0 7 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.78016 5.27985C6.92086 5.42037 6.99999 5.61101 7.00016 5.80985V6.18985C6.99786 6.38827 6.91905 6.57813 6.78016 6.71985L1.64016 11.8499C1.54628 11.9445 1.41848 11.9978 1.28516 11.9978C1.15185 11.9978 1.02405 11.9445 0.930165 11.8499L0.220165 11.1399C0.126101 11.0477 0.0730934 10.9215 0.0730934 10.7899C0.0730934 10.6582 0.126101 10.532 0.220165 10.4399L4.67016 5.99985L0.220165 1.55985C0.125508 1.46597 0.0722656 1.33817 0.0722656 1.20485C0.0722656 1.07153 0.125508 0.943736 0.220165 0.849852L0.930165 0.149852C1.02405 0.055196 1.15185 0.00195312 1.28516 0.00195312C1.41848 0.00195312 1.54628 0.055196 1.64016 0.149852L6.78016 5.27985Z"
              fill="#BDBDBD"
            />
          </svg>
        </div>
      </li>
    </>
  );

  const DateOptions = (
    <>
      <li
        className="d-flex subjectList"
        key={0}
        data-id={0}
        onClick={() => {
          setShortcut("current-week");
          const start = format(
            startOfWeek(new Date(), { weekStartsOn: 1 }),
            "yyyy-MM-dd"
          );
          const end = format(
            endOfWeek(new Date(), { weekStartsOn: 1 }),
            "yyyy-MM-dd"
          );
          if (typeDate === "pub") {
            setPubDate({ start, end });
          }
          if (typeDate === "tenu") {
            setTenuDate({ start, end });
          }
        }}
        style={{ width: 300 }}
      >
        {shortcut === "current-week" ? (
          <div className="checked">
            <svg
              width="9"
              height="7"
              viewBox="0 0 9 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.75015 4.38408L1.481 3.11494C1.22087 2.85481 0.800078 2.85481 0.539947 3.11494C0.279816 3.37507 0.279816 3.79586 0.539947 4.05599L2.28161 5.79766C2.54175 6.05779 2.96254 6.05779 3.22267 5.79766L7.631 1.38933C7.89113 1.12919 7.89113 0.708403 7.631 0.448272C7.3709 0.188171 6.95018 0.188141 6.69004 0.448183C6.69001 0.448212 6.68998 0.448242 6.68995 0.448272L2.75015 4.38408Z"
                fill="#3367CD"
                stroke="#3367CD"
              />
            </svg>
          </div>
        ) : (
          <div className="unchecked">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM14 11H11V14C11 14.55 10.55 15 10 15C9.45 15 9 14.55 9 14V11H6C5.45 11 5 10.55 5 10C5 9.45 5.45 9 6 9H9V6C9 5.45 9.45 5 10 5C10.55 5 11 5.45 11 6V9H14C14.55 9 15 9.45 15 10C15 10.55 14.55 11 14 11Z"
                fill="#ECF0FB"
              />
            </svg>
          </div>
        )}
        <span className="checkBox">Cette semaine</span>
      </li>
      <li
        className="d-flex subjectList"
        key={1}
        data-id={1}
        onClick={() => {
          setShortcut("next-week");
          const nextWeek = addWeeks(new Date(), -1);
          const start = format(
            startOfWeek(nextWeek, { weekStartsOn: 1 }),
            "yyyy-MM-dd"
          );
          const end = format(
            endOfWeek(nextWeek, { weekStartsOn: 1 }),
            "yyyy-MM-dd"
          );
          if (typeDate === "pub") {
            setPubDate({ start, end });
          }
          if (typeDate === "tenu") {
            setTenuDate({ start, end });
          }
        }}
        style={{ width: 300 }}
      >
        {shortcut === "next-week" ? (
          <div className="checked">
            <svg
              width="9"
              height="7"
              viewBox="0 0 9 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.75015 4.38408L1.481 3.11494C1.22087 2.85481 0.800078 2.85481 0.539947 3.11494C0.279816 3.37507 0.279816 3.79586 0.539947 4.05599L2.28161 5.79766C2.54175 6.05779 2.96254 6.05779 3.22267 5.79766L7.631 1.38933C7.89113 1.12919 7.89113 0.708403 7.631 0.448272C7.3709 0.188171 6.95018 0.188141 6.69004 0.448183C6.69001 0.448212 6.68998 0.448242 6.68995 0.448272L2.75015 4.38408Z"
                fill="#3367CD"
                stroke="#3367CD"
              />
            </svg>
          </div>
        ) : (
          <div className="unchecked">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM14 11H11V14C11 14.55 10.55 15 10 15C9.45 15 9 14.55 9 14V11H6C5.45 11 5 10.55 5 10C5 9.45 5.45 9 6 9H9V6C9 5.45 9.45 5 10 5C10.55 5 11 5.45 11 6V9H14C14.55 9 15 9.45 15 10C15 10.55 14.55 11 14 11Z"
                fill="#ECF0FB"
              />
            </svg>
          </div>
        )}
        <span className="checkBox">La semaine dernière</span>
      </li>
      <li
        className="d-flex subjectList"
        key={2}
        data-id={2}
        onClick={() => {
          setShortcut("current-month");
          const start = format(startOfMonth(new Date()), "yyyy-MM-dd");
          const end = format(endOfMonth(new Date()), "yyyy-MM-dd");
          if (typeDate === "pub") {
            setPubDate({ start, end });
          }
          if (typeDate === "tenu") {
            setTenuDate({ start, end });
          }
        }}
        style={{ width: 300 }}
      >
        {shortcut === "current-month" ? (
          <div className="checked">
            <svg
              width="9"
              height="7"
              viewBox="0 0 9 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.75015 4.38408L1.481 3.11494C1.22087 2.85481 0.800078 2.85481 0.539947 3.11494C0.279816 3.37507 0.279816 3.79586 0.539947 4.05599L2.28161 5.79766C2.54175 6.05779 2.96254 6.05779 3.22267 5.79766L7.631 1.38933C7.89113 1.12919 7.89113 0.708403 7.631 0.448272C7.3709 0.188171 6.95018 0.188141 6.69004 0.448183C6.69001 0.448212 6.68998 0.448242 6.68995 0.448272L2.75015 4.38408Z"
                fill="#3367CD"
                stroke="#3367CD"
              />
            </svg>
          </div>
        ) : (
          <div className="unchecked">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM14 11H11V14C11 14.55 10.55 15 10 15C9.45 15 9 14.55 9 14V11H6C5.45 11 5 10.55 5 10C5 9.45 5.45 9 6 9H9V6C9 5.45 9.45 5 10 5C10.55 5 11 5.45 11 6V9H14C14.55 9 15 9.45 15 10C15 10.55 14.55 11 14 11Z"
                fill="#ECF0FB"
              />
            </svg>
          </div>
        )}
        <span className="checkBox">Ce mois-ci</span>
      </li>
      <li
        className="d-flex subjectList"
        key={3}
        data-id={3}
        onClick={() => setShortcut("range")}
        style={{ width: 300, paddingTop: 6 }}
      >
        <div>
          <svg
            width="18"
            height="20"
            viewBox="0 0 18 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16 2H15V0H13V2H5V0H3V2H2C0.89 2 0.00999999 2.9 0.00999999 4L0 18C0 19.1 0.89 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM16 18H2V7H16V18ZM4 9H9V14H4V9Z"
              fill="#3367CD"
            />
          </svg>
        </div>
        <div>
          <span className="checkBox">Choisir une période précise</span>

          {typeDate === "pub" && pubDate.start !== "" && pubDate.end !== "" && (
            <div className="range-date d-flex">
              <span className="range">
                Du <span style={{ color: "#3367CD" }}>{pubDate.start}</span>{" "}
              </span>
              <span className="range">
                Au <span style={{ color: "#3367CD" }}>{pubDate.end}</span>{" "}
              </span>
            </div>
          )}
          {typeDate === "tenu" && tenuDate.start !== "" && tenuDate.end !== "" && (
            <div className="range-date d-flex">
              <span className="range">
                Du <span style={{ color: "#3367CD" }}>{tenuDate.start}</span>{" "}
              </span>
              <span className="range">
                Au <span style={{ color: "#3367CD" }}>{tenuDate.end}</span>{" "}
              </span>
            </div>
          )}
          {((typeDate === "tenu" &&
            tenuDate.start === "" &&
            tenuDate.end === "") ||
            (typeDate === "pub" &&
              pubDate.start === "" &&
              pubDate.end === "")) && (
            <div className="range-date d-flex">
              <span className="range">
                Du <span>--/--/----</span>{" "}
              </span>
              <span className="range">
                Au <span>--/--/----</span>{" "}
              </span>
            </div>
          )}
        </div>
      </li>
    </>
  );

  return (
    <>
      <div
        className="label-black-normal formControl d-flex"
        color={"#3367CD"}
        onClick={() => setOpen(!open)}
        onBlur={() => setOpen(false)}
        id="PopoverDate"
        tabIndex={0}
      >
        <div className="d-flex filter-label">
          <div className="name"> Dates</div>
          {pubDate.start !== "" && tenuDate.start !== "" ? (
            <div className="number"> 2 </div>
          ) : pubDate.start !== "" || tenuDate.start !== "" ? (
            <div className="number"> 1 </div>
          ) : (
            <div></div>
          )}
        </div>
        {open ? (
          <AiOutlineCaretUp color="#3367CD" className="float-end" />
        ) : (
          <AiOutlineCaretDown color="#BDBDBD" className="float-end" />
        )}
      </div>
      <UncontrolledPopover
        placement="bottom"
        target="PopoverDate"
        trigger="legacy"
      >
        <PopoverBody>
          <Col onFocus={() => setOpen(true)} onBlur={() => setOpen(false)}>
            {typeDate === "" && DateElements}
            {typeDate === "pub" && shortcut !== "range" && (
              <div
                className="d-flex"
                style={{ width: 300, cursor: "pointer" }}
                onClick={() => setTypeDate("")}
              >
                <div>
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.362413 5.27985C0.221721 5.42037 0.142588 5.61101 0.142413 5.80985V6.18985C0.144717 6.38827 0.223529 6.57813 0.362413 6.71985L5.50241 11.8499C5.5963 11.9445 5.72409 11.9978 5.85741 11.9978C5.99073 11.9978 6.11853 11.9445 6.21241 11.8499L6.92241 11.1399C7.01648 11.0477 7.06948 10.9215 7.06948 10.7899C7.06948 10.6582 7.01648 10.532 6.92241 10.4399L2.47241 5.99985L6.92241 1.55985C7.01707 1.46597 7.07031 1.33817 7.07031 1.20485C7.07031 1.07153 7.01707 0.943736 6.92241 0.849852L6.21241 0.149852C6.11853 0.055196 5.99073 0.00195312 5.85741 0.00195312C5.72409 0.00195312 5.5963 0.055196 5.50241 0.149852L0.362413 5.27985Z"
                      fill="#BDBDBD"
                    />
                  </svg>
                </div>
                <div className="filter-label-date">Date de publication</div>
              </div>
            )}
            {typeDate === "tenu" && shortcut !== "range" && (
              <div
                className="d-flex"
                style={{ width: 300, cursor: "pointer" }}
                onClick={() => setTypeDate("")}
              >
                <div>
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.362413 5.27985C0.221721 5.42037 0.142588 5.61101 0.142413 5.80985V6.18985C0.144717 6.38827 0.223529 6.57813 0.362413 6.71985L5.50241 11.8499C5.5963 11.9445 5.72409 11.9978 5.85741 11.9978C5.99073 11.9978 6.11853 11.9445 6.21241 11.8499L6.92241 11.1399C7.01648 11.0477 7.06948 10.9215 7.06948 10.7899C7.06948 10.6582 7.01648 10.532 6.92241 10.4399L2.47241 5.99985L6.92241 1.55985C7.01707 1.46597 7.07031 1.33817 7.07031 1.20485C7.07031 1.07153 7.01707 0.943736 6.92241 0.849852L6.21241 0.149852C6.11853 0.055196 5.99073 0.00195312 5.85741 0.00195312C5.72409 0.00195312 5.5963 0.055196 5.50241 0.149852L0.362413 5.27985Z"
                      fill="#BDBDBD"
                    />
                  </svg>
                </div>

                <div className="filter-label-date">
                  Date de tenu de formation
                </div>
              </div>
            )}

            {typeDate !== "" && shortcut !== "range" && DateOptions}
            {shortcut === "range" && (
              <>
                <div
                  className="d-flex"
                  style={{ width: 300, cursor: "pointer" }}
                  onClick={() => {
                    setShortcut("");
                  }}
                >
                  <div>
                    <svg
                      width="8"
                      height="12"
                      viewBox="0 0 8 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.362413 5.27985C0.221721 5.42037 0.142588 5.61101 0.142413 5.80985V6.18985C0.144717 6.38827 0.223529 6.57813 0.362413 6.71985L5.50241 11.8499C5.5963 11.9445 5.72409 11.9978 5.85741 11.9978C5.99073 11.9978 6.11853 11.9445 6.21241 11.8499L6.92241 11.1399C7.01648 11.0477 7.06948 10.9215 7.06948 10.7899C7.06948 10.6582 7.01648 10.532 6.92241 10.4399L2.47241 5.99985L6.92241 1.55985C7.01707 1.46597 7.07031 1.33817 7.07031 1.20485C7.07031 1.07153 7.01707 0.943736 6.92241 0.849852L6.21241 0.149852C6.11853 0.055196 5.99073 0.00195312 5.85741 0.00195312C5.72409 0.00195312 5.5963 0.055196 5.50241 0.149852L0.362413 5.27985Z"
                        fill="#BDBDBD"
                      />
                    </svg>
                  </div>
                  <div className="filter-label-date">
                    Choisir une période précise
                  </div>
                </div>
                <div>
                  <DateRange
                    editableDateInputs={true}
                    onChange={(item) => {
                      setRangeState([item.selection]);
                      const newRangeState = [item.selection];

                      if (newRangeState[0].startDate && newRangeState[0].endDate) {
                        const start = format(
                          newRangeState[0].startDate,
                          "yyyy-MM-dd"
                        );
                        const end = format(newRangeState[0].endDate, "yyyy-MM-dd");
                        if (typeDate === "pub") {
                          setPubDate({ start, end });
                        }
                        if (typeDate === "tenu") {
                          setTenuDate({ start, end });
                        }
                      }
                      setShortcut("");
                    }}
                    moveRangeOnFirstSelection={false}
                    ranges={rangeState}
                  />
                </div>
              </>
            )}
            <div className="clearAll" onClick={() => setClearAll(true)}>
                réinitialiser
            </div>
          </Col>
        </PopoverBody>
      </UncontrolledPopover>
    </>
  );
};

export default React.memo(DateFilters);
