const Actions = (props: any) => {
  const {
    onFileChange,
    onVideoChange,
    onAudioChange,
    onImgChange,
    setShowLink,
  } = props;

  const importFile = () => {
    document.getElementById("fileInput")?.click();
  };
  const importVideo = () => {
    document.getElementById("videoInput")?.click();
  };
  const importAudio = () => {
    document.getElementById("audioInput")?.click();
  };
  const importImg = () => {
    document.getElementById("imgInput")?.click();
  };
  return (
    <div className="pt-3">
      <span className="action-post" onClick={importFile}>
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="16" cy="16" r="16" fill="#F9F9F9" />
          <path
            d="M18.5145 13.1355V18.9056C18.5145 20.0454 17.68 21.0598 16.5456 21.1689C15.2422 21.2943 14.1514 20.2745 14.1514 18.9983V12.2574C14.1514 11.543 14.6641 10.894 15.3731 10.8231C16.1912 10.7413 16.8783 11.3794 16.8783 12.1811V17.9075C16.8783 18.2075 16.6329 18.4529 16.333 18.4529C16.033 18.4529 15.7876 18.2075 15.7876 17.9075V13.1355C15.7876 12.9119 15.6021 12.7264 15.3785 12.7264C15.1549 12.7264 14.9695 12.9119 14.9695 13.1355V17.8312C14.9695 18.5456 15.4822 19.1946 16.1912 19.2655C17.0092 19.3473 17.6964 18.7092 17.6964 17.9075V12.2738C17.6964 11.1339 16.862 10.1195 15.7276 10.0105C14.4296 9.88502 13.3334 10.9049 13.3334 12.1811V18.8728C13.3334 20.4381 14.4787 21.8397 16.0384 21.9869C17.8327 22.1506 19.3325 20.7544 19.3325 18.9983V13.1355C19.3325 12.9119 19.1471 12.7264 18.9235 12.7264C18.6999 12.7264 18.5145 12.9119 18.5145 13.1355Z"
            fill="#8D8D8D"
          />
        </svg>
      </span>
      <span className="action-post" onClick={importVideo}>
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="16" cy="16" r="16" fill="#F9F9F9" />
          <path
            d="M20.5 10H11.5C10.675 10 10 10.675 10 11.5V20.5C10 21.325 10.675 22 11.5 22H20.5C21.325 22 22 21.325 22 20.5V11.5C22 10.675 21.325 10 20.5 10ZM14.5 19.375V12.625L18.6025 15.7C18.805 15.85 18.805 16.15 18.6025 16.3L14.5 19.375Z"
            fill="#8D8D8D"
          />
        </svg>
      </span>
      <span className="action-post" onClick={importImg}>
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="16" cy="16" r="16" fill="#F9F9F9" />
          <path
            d="M22 20.6667V11.3333C22 10.6 21.4 10 20.6667 10H11.3333C10.6 10 10 10.6 10 11.3333V20.6667C10 21.4 10.6 22 11.3333 22H20.6667C21.4 22 22 21.4 22 20.6667ZM13.9333 17.32L15.3333 19.0067L17.4 16.3467C17.5333 16.1733 17.8 16.1733 17.9333 16.3533L20.2733 19.4733C20.44 19.6933 20.28 20.0067 20.0067 20.0067H12.0133C11.7333 20.0067 11.58 19.6867 11.7533 19.4667L13.4133 17.3333C13.54 17.16 13.7933 17.1533 13.9333 17.32V17.32Z"
            fill="#8D8D8D"
          />
        </svg>
      </span>
      <span className="action-post" onClick={importAudio} >
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="16" cy="16" r="16" fill="#F9F9F9" />
          <path
            d="M16 17.3333C17.1067 17.3333 18 16.44 18 15.3333V11.3333C18 10.2267 17.1067 9.33334 16 9.33334C14.8934 9.33334 14 10.2267 14 11.3333V15.3333C14 16.44 14.8934 17.3333 16 17.3333ZM19.94 15.3333C19.6134 15.3333 19.34 15.5733 19.2867 15.9C19.0134 17.4667 17.6467 18.6667 16 18.6667C14.3534 18.6667 12.9867 17.4667 12.7134 15.9C12.66 15.5733 12.3867 15.3333 12.06 15.3333C11.6534 15.3333 11.3334 15.6933 11.3934 16.0933C11.72 18.0933 13.32 19.66 15.3334 19.9467V21.3333C15.3334 21.7 15.6334 22 16 22C16.3667 22 16.6667 21.7 16.6667 21.3333V19.9467C18.68 19.66 20.28 18.0933 20.6067 16.0933C20.6734 15.6933 20.3467 15.3333 19.94 15.3333Z"
            fill="#8D8D8D"
          />
        </svg>
      </span>
      <span className="action-post" onClick={()=> setShowLink(true)}>
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="16" cy="16" r="16" fill="#F9F9F9" />
          <path
            d="M19.3334 12.6667H17.3334C16.9667 12.6667 16.6667 12.9667 16.6667 13.3333C16.6667 13.7 16.9667 14 17.3334 14H19.3334C20.4334 14 21.3334 14.9 21.3334 16C21.3334 17.1 20.4334 18 19.3334 18H17.3334C16.9667 18 16.6667 18.3 16.6667 18.6667C16.6667 19.0333 16.9667 19.3333 17.3334 19.3333H19.3334C21.1734 19.3333 22.6667 17.84 22.6667 16C22.6667 14.16 21.1734 12.6667 19.3334 12.6667ZM13.3334 16C13.3334 16.3667 13.6334 16.6667 14 16.6667H18C18.3667 16.6667 18.6667 16.3667 18.6667 16C18.6667 15.6333 18.3667 15.3333 18 15.3333H14C13.6334 15.3333 13.3334 15.6333 13.3334 16ZM14.6667 18H12.6667C11.5667 18 10.6667 17.1 10.6667 16C10.6667 14.9 11.5667 14 12.6667 14H14.6667C15.0334 14 15.3334 13.7 15.3334 13.3333C15.3334 12.9667 15.0334 12.6667 14.6667 12.6667H12.6667C10.8267 12.6667 9.33337 14.16 9.33337 16C9.33337 17.84 10.8267 19.3333 12.6667 19.3333H14.6667C15.0334 19.3333 15.3334 19.0333 15.3334 18.6667C15.3334 18.3 15.0334 18 14.6667 18Z"
            fill="#8D8D8D"
          />
        </svg>
      </span>
      <input
        type="file"
        id="fileInput"
        className="d-none"
        accept=".pdf, .pptx, .ppt ,.xls, .xlsx"
        onChange={(event) => onFileChange(event)}
      />
      <input
        type="file"
        id="videoInput"
        className="d-none"
        accept=".mp4"
        onChange={(event) => onVideoChange(event)}
      />
      <input
        type="file"
        id="audioInput"
        className="d-none"
        accept=".mp3"
        onChange={(event) => onAudioChange(event)}
      />
      <input
        type="file"
        id="imgInput"
        className="d-none"
        accept=".png, .jpg, .jpeg"
        onChange={(event) => onImgChange(event)}
      />
    </div>
  );
};

export default Actions;
