import classes from './Box.module.css';

const Box = (props: any) => {

    const { type } = props;

    return (
        <li className={type === "noBorder" ? classes.descNoBorder : classes.desc} >
            <div className={type === "noBorder" ? classes.descNoBorderDiv : classes.descDiv}> {props.content} </div>
        </li>
    );
}

export default Box;
