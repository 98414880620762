
import React, {useEffect, useRef, useState} from "react";
import { HiOutlineArrowNarrowLeft, HiOutlineArrowNarrowRight } from "react-icons/hi";
import ReactTooltip from "react-tooltip";
import {Button, Col, Input, Row} from "reactstrap";
import { useAppSelector} from "../../../../redux/hooks";
import {
    retrieveAllNonPublishedSyncTrainings,
    selectAllNonPublishedSyncTrainings, selectAllNonPublishedSyncTrainingsCount,
} from "../../../../redux/slices/trainingSlice";
//@ts-ignore
import EllipsisText from "react-ellipsis-text";
import EditorTable from "./EditorTable";
import moment from "moment";
import useHttp from "../../../../hooks/use-http";
import EditorService from "../../../../services/editor.service";
import {Modal} from "react-bootstrap";
import NotificationAlert from "react-notification-alert";
import {Calendar} from "react-date-range";
import {format} from "date-fns";
import {fr} from "date-fns/locale";
import EditorNonPublishedSyncTrainingsFilters from "./EditorNonPublishedSyncTrainingsFilters";
import { ReactComponent as AvatarIcon } from '../../../../assets/img/icons/avatar-person.svg'

const EditorSyncTrainings = (props: any) => {

    const {dispatch, scrollable } = props;
   // const trainings = useAppSelector(selectAllNonPublishedTrainings);
    const totalCount = useAppSelector(selectAllNonPublishedSyncTrainingsCount);
    const trainings = useAppSelector(selectAllNonPublishedSyncTrainings);
    const [pageNumber, setPage] = useState(1);
    const filtersData = {
        nom: "",
        sujets: [],
        createurs: [],
        datePub: {
            start: "",
            end: "",
        },
        contenu: {
            type: [],
            typeContenu: [],
        },
        dateTenu: {
            start: "",
            end: "",
        },
    };
    const [filters, setFilters] = useState(filtersData);
    const {
        sendRequest,
        error: publishingError,
        status: publishingStatus,
    } = useHttp(EditorService.publishTraining);
    const [modalCancelShow, setModalCancelShow] = useState(false);
    const [motif, setMotif] = useState("");
    const [rowData, setRowData] = useState(trainings[0]);
    const notificationAlert = useRef(null);
    const [modalProtractShow, setModalProtractShow] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedHour, setSelectedHour] = useState("16");
    const [selectedMinutes, setSelectedMinutes] = useState("00");
    const {
        sendRequest: reject,
        error: rejectError,
        status: rejectStatus,
    } = useHttp(EditorService.rejectTraining);
    const {
        sendRequest: schedule,
        error: scheduleError,
        status: scheduleStatus,
    } = useHttp(EditorService.scheduleTraining);
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            dispatch(retrieveAllNonPublishedSyncTrainings({ page: pageNumber, filters }));
        }, 100);
        return () => clearTimeout(delayDebounceFn);
    }, [dispatch, pageNumber, filters]);
    useEffect(() => {
        if (rejectStatus === "completed") {
            //@ts-ignore
            notificationAlert.current.notificationAlert({
                place: "tr",
                message:
                    rejectError === null ? (
                        <div> {"Formation refusée avec succès"}</div>
                    ) : (
                        <div>{rejectError}</div>
                    ),
                type: rejectError === null ? "success" : "danger",
                icon: "nc-icon nc-bell-55",
                closeButton: false,
                autoDismiss: 7,
            });
            dispatch(retrieveAllNonPublishedSyncTrainings({ page: pageNumber, filters }));
        }},[rejectStatus, rejectError]);
    useEffect(() => {
        if (publishingStatus === "completed") {
            //@ts-ignore
            notificationAlert.current.notificationAlert({
                place: "tr",
                message:
                    publishingError === null ? (
                        <div>
                            {" "}
                            <p>Formation publiée avec succès</p>
                        </div>
                    ) : (
                        <div>{publishingError}</div>
                    ),
                type: publishingError === null ? "success" : "danger",
                icon: "nc-icon nc-bell-55",
                closeButton: false,
                autoDismiss: 7,
            });
            dispatch(retrieveAllNonPublishedSyncTrainings({ page: pageNumber, filters }));
        }
    }, [publishingStatus, publishingError]);
    useEffect(() => {
        if (scheduleStatus === "completed") {
            //@ts-ignore
            notificationAlert.current.notificationAlert({
                place: "tr",
                message:
                    scheduleError === null ? (
                        <div> {"Formation programmée avec succès"}</div>
                    ) : (
                        <div>{scheduleError}</div>
                    ),
                type: scheduleError === null ? "success" : "danger",
                icon: "nc-icon nc-bell-55",
                closeButton: false,
                autoDismiss: 7,
            });dispatch(retrieveAllNonPublishedSyncTrainings({ page: pageNumber, filters }));
        }
    }, [scheduleStatus, scheduleError]);
                const columns = React.useMemo(
        () => [
            {
                Header: "",
                isSorted: false,
                width: 50,
                accessor: "creatorImage",
                Cell: (props: any) => {
                    return props.row.original.createur.image ? (
                        <img
                          alt="profile"
                          className="avatar-profile"
                          style={{ maxWidth: 32, marginTop: '10%' }}
                          src={props.row.original.createur.image}
                        />
                      ) : (
                        <AvatarIcon
                          style={{ width: 32, height: 32, borderRadius: '50%' }}
                        />
                      )
                },
            },
            {
                Header: "Nom de formation",
                isSorted: false,
                width: 120,
                accessor: "nom",
            },
            {
                Header: "Sujets",
                isSorted: false,
                width: 100,
                accessor: "sujets",
                Cell: (props: any) => {
                    let text = "";
                    props.row.original.sujets.map(
                        (subject: any) =>
                            (text = text !== "" ? text + ", " + subject.label : subject.label)
                    );
                    return (
                        <>
                            <EllipsisText text={text} length={"20"} />
                            <ReactTooltip>{text}</ReactTooltip>
                        </>
                    );
                },
            },

            {
                Header: "Actions",
                isSorted: false,
                width: 100,
                accessor: "actions",
                Cell: (props: any) => {
                    return (
                        <div className="d-flex">
                            <div
                                id="cancel"
                                className="action"
                                onClick={() => {
                                 //   EditorService.rejectTraining({ formationId: props.row.original.id, comment: motif });
                                   setRowData(props.row.original);
                                    setModalCancelShow(true);
                                }}
                            >
                                {" "}
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM2 10C2 5.58 5.58 2 10 2C11.85 2 13.55 2.63 14.9 3.69L3.69 14.9C2.63 13.55 2 11.85 2 10ZM10 18C8.15 18 6.45 17.37 5.1 16.31L16.31 5.1C17.37 6.45 18 8.15 18 10C18 14.42 14.42 18 10 18Z"
                                        fill="#BDBDBD"
                                    />
                                </svg>
                            </div>
                            <div
                                id="protract"
                                className="action"
                                onClick={() => {
                                    setRowData(props.row.original);
                                    setModalProtractShow(true);
                                }}
                            >
                                {" "}
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18ZM9.78 5H9.72C9.32 5 9 5.32 9 5.72V10.44C9 10.79 9.18 11.12 9.49 11.3L13.64 13.79C13.98 13.99 14.42 13.89 14.62 13.55C14.83 13.21 14.72 12.76 14.37 12.56L10.5 10.26V5.72C10.5 5.32 10.18 5 9.78 5Z"
                                        fill="#BDBDBD"
                                    />
                                </svg>
                            </div>
                            <div
                                id="accept"
                                className="action"
                                onClick={() => {
                                    sendRequest(props.row.original.id);
                                //    EditorService.publishTraining(props.row.original.id);

                                }}
                            >
                                {" "}
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM14.59 5.58L8 12.17L5.41 9.59L4 11L8 15L16 7L14.59 5.58Z"
                                        fill="#BDBDBD"
                                    />
                                </svg>
                            </div>
                        </div>
                    );
                },
            },
            {
                Header: "Date de soumission",
                isSorted: false,
                width: 100,
                accessor: "dateSubmit",
                Cell: (props: any) => {
                    return props.row.original.dateSubmit
                        ? moment(props.row.original.dateSubmit).format("DD/MM/YYYY")
                        : "-";
                },
            },
        ],
        []
    );
    return (
        <>
            <NotificationAlert ref={notificationAlert} />
            <EditorNonPublishedSyncTrainingsFilters setFilters={setFilters} filtersData={filtersData} />
            <Row style={{ width: '100%', marginLeft: 3 }}>
                <Col className={scrollable ? "overflow-scroll" : ""}>
                    <Row>
                        <EditorTable
                            data={trainings}
                            columns={columns}
                        />
                    </Row>
                    <Row>
                        <Col md="12">
                            <div className="d-flex d-column justify-content-center">
                                <HiOutlineArrowNarrowLeft
                                    onClick={() => pageNumber !== 1 && setPage(pageNumber - 1)}
                                    className={
                                        pageNumber !== 1 ? "mt-3 me-2 pointer" : "mt-3 me-2 "
                                    }
                                    color={pageNumber !== 1 ? "#3367CD" : "#BDBDBD"}
                                    size={20}
                                />
                                <p className="label-grey-normal text-center pt-3 pointer opacity-50">
                                    page
                                </p>
                                <div
                                    className="ms-2 mt-3 me-2"
                                    style={{
                                        width: 20,
                                        height: 20,
                                        backgroundColor: "#ECF0FB",
                                        color: "#3367CD",
                                    }}
                                >
                                    <label className="text-center ms-1">{pageNumber}</label>
                                </div>
                                <p className="label-grey-normal text-center pt-3 pointer opacity-50">
                                    sur {totalCount}
                                </p>
                                <HiOutlineArrowNarrowRight
                                    onClick={() =>
                                        pageNumber !== totalCount && totalCount !==0 &&  setPage(pageNumber + 1)
                                    }
                                    className={
                                        pageNumber !== totalCount && totalCount !==0
                                            ? "mt-3 ms-2 pointer"
                                            : "mt-3 ms-2"
                                    }
                                    size={20}
                                    color={pageNumber !== totalCount && totalCount !==0 ? "#3367CD" : "#BDBDBD"}
                                />
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Modal
                show={modalProtractShow}
                onHide={() => setModalProtractShow(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton style={{ borderBottom: 0 }}>
                    <Row>
                        <div className="label-black-xmd">Programmer la publication</div>
                        <div className="d-flex">
                        {rowData?.createur.image ? (
                <img
                  alt="profile"
                  className="avatar-profile"
                  style={{ maxWidth: 32 }}
                  src={rowData.createur.image}
                />
              ) : (
                <AvatarIcon
                  style={{ width: 32, height: 32, borderRadius: '50%' }}
                />
              )}
                            <div className="label-grey text-center px-2">{rowData?.nom}</div>
                        </div>
                    </Row>
                </Modal.Header>
                <Modal.Body className="d-flex flex-column ">
                    <Row className="d-flex flex-column align-items-center">
                        <Col md="9" className="calendar-box">
                            <Calendar
                                date={selectedDate}
                                onChange={(date) => setSelectedDate(date)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md="4">
                            <p className="label-black mt-3">Heure de publication</p>
                            <div className="d-flex">
                                <Input
                                    name="startTimeHour"
                                    onChange={(event) => setSelectedHour(event.target.value)}
                                    type="number"
                                    min="00"
                                    max="23"
                                    placeholder="16"
                                    style={{
                                        padding: 5,
                                        textAlign: "center",
                                        width: 45,
                                        color: "#bdbdbd",
                                    }}
                                />
                                <p
                                    className="label-grey-normal mt-3 mx-1"
                                    style={{ color: "#BDBDBD" }}
                                >
                                    h
                                </p>
                                <Input
                                    name="startTimeMinute"
                                    onChange={(event) => setSelectedMinutes(event.target.value)}
                                    type="number"
                                    placeholder="00"
                                    max="59"
                                    min="00"
                                    style={{
                                        padding: 5,
                                        textAlign: "center",
                                        width: 45,
                                        color: "#bdbdbd",
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                    <hr className="line"></hr>
                    <Row>
                        <Col className="d-flex">
                            <div className="label-grey-md px-2">Le </div>
                            <div className="label-blue-md">
                                {format(selectedDate, "dd/MM/yyyy", {
                                    locale: fr,
                                })}
                            </div>
                            <div className="label-grey-md px-2">à </div>
                            <div className="label-blue-md">
                                {format(
                                    new Date(
                                        `${format(selectedDate, "MM/dd/yyyy", {
                                            locale: fr,
                                        })} ${selectedHour}:${selectedMinutes}`
                                    ),
                                    "HH:mm",
                                    {
                                        locale: fr,
                                    }
                                )}
                            </div>
                        </Col>
                    </Row>
                    <Row className="d-flex flex-column align-items-center">
                        <Col className="d-flex justify-content-end">
                            <Button
                                className="btn-round-sm-secondary mt-3 me-2"
                                onClick={() => setModalProtractShow(false)}
                                color="secondary"
                                type="submit"
                            >
                                Annuler
                            </Button>
                            <Button
                                className="btn-round-sm mt-3"
                                onClick={() => {
                                    schedule({
                                        formationId: rowData?.id,
                                        dateHour: format(
                                            new Date(
                                                `${format(selectedDate, "MM/dd/yyyy", {
                                                    locale: fr,
                                                })} ${selectedHour}:${selectedMinutes}`
                                            ),
                                            "yyyy-MM-dd HH:mm",
                                            {
                                                locale: fr,
                                            }
                                        ),
                                    });
                                    setModalProtractShow(false);
                                }}
                                color="primary"
                                type="submit"
                            >
                                Envoyer
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            <Modal
                show={modalCancelShow}
                onHide={() => setModalCancelShow(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton style={{ borderBottom: 0 }}>
                    <Row>
                        <div className="label-black-xmd">
                            Refuser la publication de cette formation
                        </div>
                        <div className="label-grey">
                            Expliquez pourquoi cette formation a été refusée.{" "}
                        </div>
                    </Row>
                </Modal.Header>
                <Modal.Body className="d-flex flex-column ">
                    <Row className="d-flex flex-column ">
                        <Col className="d-flex flex-column ">
                            <div>
                <textarea
                    onChange={(event) => setMotif(event.target.value)}
                    style={{ width: "100%", height: "84px" }}
                    placeholder="Ex : La formation ne respecte pas ..."
                ></textarea>
                            </div>
                        </Col>
                    </Row>
                    <Row className="d-flex flex-column align-items-center">
                        <Col className="d-flex justify-content-end">
                            <Button
                                className="btn-round-sm-secondary mt-3 me-2"
                                onClick={() => setModalCancelShow(false)}
                                color="secondary"
                                type="submit"
                            >
                                Annuler
                            </Button>
                            <Button
                                className="btn-round-sm mt-3"
                                disabled={motif === ''}
                                onClick={() => {
                                    reject({ formationId: rowData?.id, comment: motif });
                                    setModalCancelShow(false);
                                }}
                                color="primary"
                                type="submit"
                            >
                                Envoyer
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default EditorSyncTrainings;
