import {Fragment, useEffect, useRef} from "react";
import NotificationAlert from "react-notification-alert";
import {Row, Button} from "reactstrap";
import {useMedia} from "react-media";
import useHttp from "../../../../../hooks/use-http";
import {useAppSelector} from "../../../../../redux/hooks";
import {selectUser} from "../../../../../redux/slices/userSlice";
import UserService from "../../../../../services/user.service";
import Expertise from "./expertise";
import Interest from "./interest";
import ProfileHeader from "./profile-header";
import Skills from "./skills";
import ProfileHeaderMobile from "./profile-header-mobile";
import InterestMobile from "./interest-mobile";
import ExpertiseMobile from "./expertise-mobile";
import SkillsMobile from "./skills-mobile";
import TradeSectorFamilies from "./TradeSectorFamilies";
import TradeSectorFamiliesMobile from "./TradeSectorFamilies-mobile";

const SecondStep = (props: any) => {
    const {handlePagination} = props;
    const profile = useAppSelector(selectUser);
    const {sendRequest, status,error} = useHttp(UserService.setInteretApprentissage);
    const {
      sendRequest: sendSectorsRequest,
      status: sectorsStatus,
      error: sectorsError,
    } = useHttp(UserService.setSectors);

    const GLOBAL_MEDIA_QUERIES = {
        small: "(max-width: 414px)",
        medium: "(min-width: 414px) and (max-width: 991px)",
        large: "(min-width: 991px) and (max-width: 1199px)",
        xLarge: "(min-width: 1200px)",
    };
    const matches = useMedia({queries: GLOBAL_MEDIA_QUERIES});
    const notificationAlert = useRef(null);
    const handleSubmit = (withPagination: boolean) => {
        const data = profile.interetApprentissage.map((cat) => {
            const selected = cat.sujets.filter((suj) => suj.selected);
            return selected.map((select) => select.id);
        });
        sendRequest(data.flat());
        withPagination && handlePagination(3);
    };

    const handleSectorsSubmit = (selectedSectors: any) => {
        const data = selectedSectors.map((item: any) => item.id);
        sendSectorsRequest(data.flat());
    };

    useEffect(() => {
        if (status === "completed") {
          //@ts-ignore
          notificationAlert.current.notificationAlert({
            place: "tr",
            message:
              error === null ? (
                <div>
                  {" "}
                  <p>Vos intérêts ont bien été sauvegardés </p>
                </div>
              ) : (
                <div>{error}</div>
              ),
            type: error === null ? "success" : "danger",
            icon: "nc-icon nc-bell-55",
            closeButton: false,
            autoDismiss: 7,
          });
        }
      }, [status, error]);

    useEffect(() => {
      if (sectorsStatus === "completed") {
        //@ts-ignore
        notificationAlert.current.notificationAlert({
          place: "tr",
          message:
          sectorsError === null ? (
              <div>
                {" "}
                <p>Vos familles de secteur ont bien été sauvegardées</p>
              </div>
            ) : (
              <div>{sectorsError}</div>
            ),
          type: sectorsError === null ? "success" : "danger",
          icon: "nc-icon nc-bell-55",
          closeButton: false,
          autoDismiss: 7,
        });
      }
    }, [sectorsStatus, sectorsError]);

    return (
        <Fragment>
            {/**
             //@ts-ignore */}
            <NotificationAlert ref={notificationAlert}/>
            <div className="center-container row">
                {!matches.xLarge ? <ProfileHeaderMobile/> : <ProfileHeader/>}
            </div>
            {matches.small || matches.medium ? (
              <>
                <InterestMobile handleSubmit={handleSubmit} />
                <TradeSectorFamiliesMobile handleSectorsSubmit={handleSectorsSubmit} />
              </>
            ) : (
              <>
                <Interest handleSubmit={handleSubmit}/>
                <TradeSectorFamilies handleSectorsSubmit={handleSectorsSubmit} />
              </>
            )}
            {matches.small || matches.medium ? <ExpertiseMobile/> : <Expertise/>}
            {matches.small || matches.medium ? <SkillsMobile/> : <Skills/>}
            <Row className="mt-20 center-container">
                    <Button
                        className="btn-round-sm"
                        style={{marginLeft: 'auto', width: 'auto'}}
                        onClick={() => handleSubmit(true)}
                        color="primary"
                        type="submit"
                    >
                       Suivant
                    </Button>
            </Row>
        </Fragment>
    );
};

export default SecondStep;
