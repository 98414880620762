import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { useAppSelector } from "../../../../redux/hooks";
import { getTrainingCourses, selectRegistredTrainingCourses } from "../../../../redux/slices/trainingSlice";
import SavedTrainingIcon from "./SavedTrainingIcon";
import TrainingTable from "./TrainingTable";
//@ts-ignore
import EllipsisText from "react-ellipsis-text";
import { ReactComponent as ShowMoreIcon } from "../../../../assets/img/icons/showMoreIcon.svg";


function SavedTrainings(props: any) {
    const { scrollable, dispatch } = props;
    const trainings = useAppSelector(selectRegistredTrainingCourses);
    const [pageNumber, setPage] = useState(1);

    useEffect(() => {
        dispatch(getTrainingCourses({ page: pageNumber, formationStatus: "registred", tag: "registredTrainingCourses" }))
    }, [dispatch, pageNumber]);

    const columns = React.useMemo(
        () => [
            {
                Header: '',
                width: 80,
                id: "pourcentage",
                Cell: (props: any) => {
                    return (
                        <SavedTrainingIcon type={props.row.original.type} />
                    )
                }
            },
            {
                Header: 'Nom de formation',
                maximal: 200,
                accessor: 'nom',
            },
            {
                Header: 'Sujets',
                maximal: 185,
                accessor: 'sujets',
                Cell: (props: any) => {
                    return (
                        <EllipsisText text={props.row.original.sujets} length={"20"} />
                    )
                },
            },
            {
                Header: 'Créateur',
                maximal: 185,
                accessor: 'createur',
            },
            {
                Header: 'Type',
                maximal: 185,
                accessor: 'type',
            },
            {
                Header: 'Enreegistré le',
                maximal: 180,
                accessor: (d: any) => {
                    return moment(d.date)
                        .local()
                        .format("DD/MM/YYYY")
                },
            },
        ],
        []
    );

    return (
        <Row style={{ marginLeft: "15px", paddingRight: "35px" }}>
            <Col className={scrollable ? "overflow-scroll" : ""}>
                <Row>
                    <TrainingTable data={trainings} columns={columns} />
                </Row>
                {(
                    <Row>
                        <Col md="12">
                            <p className="label-grey text-center pt-3 pointer"
                                onClick={() => {
                                    let page = pageNumber + 1
                                    dispatch(getTrainingCourses({ page, formationStatus: "registred", tag: "registredTrainingCourses" }));
                                    setPage(page);
                                }}>
                                Afficher plus
                                <ShowMoreIcon style={{ marginLeft: "10px" }} />
                            </p>
                        </Col>
                    </Row>
                )}
            </Col>
        </Row>
    );
}

export default SavedTrainings;
