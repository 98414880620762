import http from "../config/httpCommon";

const EditorService = {
  getEditorProofreaders(data: any) {
    return new Promise(async (resolve, reject) => {
      (await http())
        .post("/review/reviewers", data)
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },
  postAssignTrainingsToProofreader(data: any) {
    return new Promise(async (resolve, reject) => {
      (await http())
        .post("/review/assign", data)
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },
  assignTraining(formations: number[], users: number[]) {
    return new Promise(async (resolve, reject) => {
      (await http())
        .post("/review/assign", {
          formations,
          users,
        })
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },
  publishTraining(formationId: number) {
    return new Promise(async (resolve, reject) => {
      (await http())
        .post("/formation/publish/" + formationId)
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },
  resetTraining(formationId: number) {
    return new Promise(async (resolve, reject) => {
      (await http())
        .post("/formation/reset/" + formationId)
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },
  rejectTraining(data:{formationId: number, comment: string}) {
    return new Promise(async (resolve, reject) => {
      (await http())
        .post("/formation/reject/" + data.formationId, { comment: data.comment })
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },
  scheduleTraining(data : {formationId: number, dateHour: string}) {
    return new Promise(async (resolve, reject) => {
      (await http())
        .post("/formation/schedule/" + data.formationId, { dateHour: data.dateHour })
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },
  getValidatedTraining(data: any) {
    return new Promise(async (resolve, reject) => {
      (await http())
        .post("/editor/list/validated", data)
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },
  getAlumniTraining(data: any) {
    return new Promise(async (resolve, reject) => {
      (await http())
        .post("/editor/list/alumni", data)
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },
  getTrainingNeedsProofReading(data: any) {
    return new Promise(async (resolve, reject) => {
      (await http())
        .post("/editor/list/missing", data)
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },
  scheduleAlumniTraining(data : {formationId: number, dateHour: string, diffusion_for:string}) {
    return new Promise(async (resolve, reject) => {
      (await http())
        .post("/formation/schedule/" + data.formationId, { dateHour: data.dateHour, diffusion_for:data.diffusion_for})
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },
  publishAlumniTraining(data: {formationId: number, diffusion_for:string}) {
    return new Promise(async (resolve, reject) => {
      (await http())
        .post("/formation/publish/" + data.formationId,{diffusion_for:data.diffusion_for})
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },
};

export default EditorService;
