import { Col, Row } from "reactstrap";
//@ts-ignore
import EllipsisText from "react-ellipsis-text";
import { ReactComponent as ShowMoreIcon } from "../../../../assets/img/icons/ShowMoreIconCatalog.svg";
import { useAppDispatch } from "../../../../redux/hooks";
import { retrieveTrainingDetail, retrieveTrainingDetailSuggestions } from "../../../../redux/slices/trainingSlice";
import { useHistory } from "react-router";

const TrainingRow = (props: any) => {
  const { id = 0, name = "", subjects = [] } = props;

  let text = "";

  subjects.map((val: any) => {
    text = text !== "" ? text + ", " + val.label : val.label;
    return null;
  });

  const history = useHistory();
  const dispatch = useAppDispatch();
  const navigateToDetails = (trainingId: number) => {
    dispatch(retrieveTrainingDetail({ id: trainingId }));
    dispatch(retrieveTrainingDetailSuggestions({ id: trainingId }));
    history.push("/trainingDetail");
  };
  return (
    <Row>
      <Col style={{ minWidth: 50, maxWidth: 80 }}>{props.children}</Col>
      <Col className="pt-3">
        <Row className="d-block">
          <Col>
            <div
              className="label-black-md-trainings pointer"
              onClick={() => navigateToDetails(id)}
            >
              {" "}
              {name}{" "}
            </div>
          </Col>
          <Col>
            <div
              data-tip
              data-for="global"
              data-type="light"
              data-background-color="#F5F5F5"
              className="label-grey-trainings-sm"
            >
              <EllipsisText text={text} length={"65"} />
            </div>
          </Col>
        </Row>
      </Col>
      <Col style={{ minWidth: 50, maxWidth: 80 }} className="pt-3">
        <div className="pull-right pointer">
          <ShowMoreIcon onClick={() => navigateToDetails(id)} />
        </div>
      </Col>
    </Row>
  );
};

export default TrainingRow;
