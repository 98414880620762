import { Field } from "formik";
import { BiError } from "react-icons/bi";
import { BsFillPlusCircleFill, BsFillTrashFill } from "react-icons/bs";
import { Col, Row } from "reactstrap";

import { Input } from "../../../../components/forms";

const QuizEval = (props: any) => {
  //Init props
  const {
    sectionKey,
    sectionContentKey,
    values,
    setFieldValue,
    setValues,
    matches,
  } = props;

  /**
   * Handle Answer change (Add or remove answer)
   * @param value
   * @param questionKey
   * @param answerKey
   */
  const handleAnswerChange = async (
    value: string,
    questionKey: number,
    answerKey: number
  ) => {
    let trainingValues: any = JSON.parse(JSON.stringify(values));

    trainingValues.sections[sectionKey].sectionContent[
      sectionContentKey
    ].questions[questionKey].answers[answerKey].title = value;

    setFieldValue(
      `[sections[${sectionKey}].sectionContent[${sectionContentKey}].questions[${questionKey}].answers[${answerKey}].title]`,
      value
    );
    if (
      answerKey ===
        values.sections[sectionKey].sectionContent[sectionContentKey].questions[
          questionKey
        ].answers.length -
          1 &&
      value !== ""
    ) {
      trainingValues.sections[sectionKey].sectionContent[
        sectionContentKey
      ].questions[questionKey].answers.push({ title: "", isCorrect: false });
      setValues(trainingValues);
    }
    if (value === "") {
      trainingValues.sections[sectionKey].sectionContent[
        sectionContentKey
      ].questions[questionKey].answers.splice(answerKey, 1);
      setValues(trainingValues);
    }
  };

  /**
   * Add question
   */
  const addQuestion = () => {
    let trainingValues=JSON.parse(JSON.stringify(values));
    trainingValues.sections[sectionKey].sectionContent[
      sectionContentKey
    ].questions.push({
      title: "",
      choice: "unique",
      answers: [{ title: "", isCorrect: false }],
    });
    setValues(trainingValues);
  };

  /**
   * remove question
   */
  const RemoveQuestion = (questionKey: number) => {
    let trainingValues=JSON.parse(JSON.stringify(values));
    trainingValues.sections[sectionKey].sectionContent[0].questions.splice(
      questionKey,
      1
    );
    setValues(trainingValues);
  };
  /**
   * Handle choice change
   * @param question
   * @param answer
   * @param {number} questionKey
   * @param {number} answerKey
   */
  const handleChoiceChange = (
    question: any,
    answer: any,
    questionKey: number,
    answerKey: number
  ) => {
    if (question.choice === "unique" && answer.title !== "") {
      question.answers.map(
        (answer: any, index: number) =>
          index !== answerKey &&
          setFieldValue(
            `[sections[${sectionKey}].sectionContent[${sectionContentKey}].questions[${questionKey}].answers[${index}].isCorrect]`,
            false
          )
      );
    }
    setFieldValue(
      `[sections[${sectionKey}].sectionContent[${sectionContentKey}].questions[${questionKey}].answers[${answerKey}].isCorrect]`,
      answer.title !== "" ? !answer.isCorrect : false
    );
  };

  const handleSelectChange = (
    value: string,
    question: any,
    questionKey: number
  ) => {
    setFieldValue(
      `[sections[${sectionKey}].sectionContent[${sectionContentKey}].questions[${questionKey}].choice]`,
      value
    );
    question.answers.map((answer: any, index: number) =>
      setFieldValue(
        `[sections[${sectionKey}].sectionContent[${sectionContentKey}].questions[${questionKey}].answers[${index}].isCorrect]`,
        false
      )
    );
  };

  const checkValidation = (question: any) => {
    let validate = false;
    let nbCorrectAnswer = 0;
    question.answers.map((answer: any) => {
      if (answer.isCorrect) {
        nbCorrectAnswer++;
      }
      return null;
    });
    if (
      (question.choice === "unique" && nbCorrectAnswer === 1) ||
      (question.choice === "multiple" && nbCorrectAnswer > 1)
    ) {
      validate = true;
    }
    return !validate ? (
      <div className="d-flex flex-row  ms-5 mt-2">
        <BiError size={15} color="#ff66a9" />
        <label className="label-pink ms-1">
          Vous devez choisir
          {question.choice === "multiple"
            ? " au moins deux réponses correctes"
            : " une réponse correcte"}
        </label>
      </div>
    ) : null;
  };

  return (
    <>
      {values.sections[sectionKey].sectionContent[
        sectionContentKey
      ].questions.map((question: any, questionKey: number) => (
        <>
          <Row className="my-4">
            <Col md="12">
              <Row>
                <div
                  className={
                    matches.large ? "d-flex flex-row" : "d-flex flex-column"
                  }
                >
                  <div className={"d-flex flex-row w-75"}>
                    <p className="label-primary-md ms-4 me-2">
                      {questionKey + 1}
                    </p>

                    <Input
                      name={`[sections[${sectionKey}].sectionContent[${sectionContentKey}].questions[${questionKey}].title]`}
                      placeholder="Question"
                    />
                  </div>
                  {questionKey !== 0 ? (
                    <BsFillTrashFill
                      style={{ marginLeft: 20, marginTop: 10 }}
                      color="#3367cd"
                      size={20}
                      className="pointer"
                      onClick={() => RemoveQuestion(questionKey)}
                    />
                  ) : null}

                  <div className={matches.large ? "w-25" : "w-100 float-end"}>
                    <Field
                      as="select"
                      name={`[sections[${sectionKey}].sectionContent[${sectionContentKey}].questions[${questionKey}].choice]`}
                      className="float-end select px-3"
                      onChange={(ev: any) =>
                        handleSelectChange(
                          ev.target.value,
                          question,
                          questionKey
                        )
                      }
                    >
                      <option value={"unique"}>Choix unique</option>
                      <option value={"multiple"}>Choix multiple</option>
                    </Field>
                  </div>
                </div>
              </Row>
              <Row>{checkValidation(question)}</Row>
            </Col>
          </Row>

          {question.answers.map((answer: any, answerKey: number) => {
            return (
              <Row>
                <Col md="12">
                  <Row>
                    <div className="d-flex flex-row">
                      <Field
                        type={
                          question.choice === "unique" ? "radio" : "checkbox"
                        }
                        style={{ color: "red" }}
                        className="ms-3 me-1 mt-3 evalChoice"
                        checked={answer.isCorrect}
                        onChange={() =>
                          handleChoiceChange(
                            question,
                            answer,
                            questionKey,
                            answerKey
                          )
                        }
                      />
                      <Input
                        name={`[sections[${sectionKey}].sectionContent[${sectionContentKey}].questions[${questionKey}].answers[${answerKey}].title]`}
                        placeholder="Ajouter une réponse"
                        onChange={(ev: any) =>
                          handleAnswerChange(
                            ev.target.value,
                            questionKey,
                            answerKey
                          )
                        }
                      />
                      <p className="optional mt-3 ms-1 mb-0 float-start w-25">
                        {answer.isCorrect && " Bonne réponse"}
                      </p>
                    </div>
                  </Row>
                </Col>
              </Row>
            );
          })}
          <Row>
            {questionKey !==
              values.sections[sectionKey].sectionContent[sectionContentKey]
                .questions.length -
                1 && <hr className="hr-text-grey p-0 mt-3"></hr>}
          </Row>
        </>
      ))}
      <Row>
        <hr className="hr-text-grey p-0 mt-3" />
      </Row>
      <Row className="center-container">
        <BsFillPlusCircleFill
          color="#3367cd"
          size={20}
          className="pointer"
          onClick={addQuestion}
        />
      </Row>
    </>
  );
};
export default QuizEval;
