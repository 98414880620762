import { Card, CardBody, Col, Row } from "reactstrap";
import ProgressBar from "@ramonak/react-progress-bar";
import { ReactComponent as InfoIcon } from "../../../../../assets/img/icons/info.svg";
import TrophyIcon from "./TrophyIcon";
import ReactTooltip from "react-tooltip";

const TrophiesSlide = (props: any) => {
  const { gamificationObject } = props;
  return (
    <Card body outline className="card p-0">
      <CardBody
        className="pt-2 pe-0"
        style={{ maxHeight: 110, minHeight: 110 }}
      >
        <Row>
          <Col xs={8} md="8" lg="8" sm="8">
            <Row>
              <Col className="pt-1 ps-0 pe-0 ms-2 d-flex justify-content-start">
                <p
                  className={
                    gamificationObject.nbActions === 0
                      ? "label-grey-gamification pt-1"
                      : gamificationObject.role === "Relecteur"
                      ? "label-green-proofreader pt-1"
                      : gamificationObject.role === "Contributeur"
                      ? "label-pink-contributor pt-1"
                      : gamificationObject.role === "Animateur"
                      ? "label-orange-animator pt-1"
                      : gamificationObject.role === "Createur"
                      ? "label-blue-creator pt-1"
                      : "label-light-blue-learner pt-1"
                  }
                >
                  {gamificationObject.role === "Relecteur"
                    ? "Relecteur"
                    : gamificationObject.role === "Contributeur"
                    ? "Contributeur"
                    : gamificationObject.role === "Animateur"
                    ? "Animateur"
                    : gamificationObject.role === "Createur"
                    ? "Créateur"
                    : "Learner"}
                </p>
              </Col>
              <Col xs={2} className="pt-0 ps-0" style={{ zIndex: "100" }}>
                <InfoIcon data-tip={gamificationObject.role === "Relecteur"
                  ? "Relecture et correction de contenus"
                  : gamificationObject.role === "Contributeur"
                    ? "Contribution à l’animation des clubs"
                    : gamificationObject.role === "Createur"
                      ? "Création de contenus de formation"
                      : gamificationObject.role === "Animateur"
                        ? "Animation de formations en présentiels"
                        : "Suivre des formations pour monter en compétences"
                } />
                <ReactTooltip
                  className="tooltip"
                  place="left"
                  type="light" />
              </Col>
            </Row>
          </Col>
          <Col md="3" lg="3" sm="3">
            <TrophyIcon trophyType={gamificationObject.trophyType} />
          </Col>
        </Row>
        <div className="pt-2">
          {gamificationObject.nbActions !== 0 ? (
            <ProgressBar
              completed={gamificationObject.pourcentage}
              bgColor={
                gamificationObject.role === "Relecteur"
                  ? "linear-gradient(to right, rgba(80, 189, 137, 0.12) , rgba(80, 189, 137, 1))"
                  : gamificationObject.role === "Contributeur"
                  ? "linear-gradient(to right, rgba(255, 102, 169, 0.12), rgba(255, 102, 169, 1))"
                  : gamificationObject.role === "Animateur"
                  ? "linear-gradient(to right, rgba(237, 152, 79, 0.12) , rgba(237, 152, 79, 1))"
                  : gamificationObject.role === "Createur"
                  ? "linear-gradient(to right, rgba(51, 103, 205, 0.12) , rgba(51, 103, 205, 1))"
                  : "linear-gradient(to right, rgba(131, 166, 255, 0.12) , rgba(131, 166, 255, 1))"
              }
              height="6px"
              className="wrapper-progress-bar"
              barContainerClassName="container-progress-bar"
              isLabelVisible={false}
            />
          ) : (
            <ProgressBar
              completed={0}
              bgColor="#BDBDBD"
              height="6px"
              className="wrapper-progress-bar"
              barContainerClassName="container-progress-bar"
              isLabelVisible={false}
            />
          )}
        </div>
        <Row>
          <Col xs={6} className="ps-0 pe-0 pt-2">
            <p
              style={{ textAlign: "left", marginLeft: 10 }}
              className={
                gamificationObject.nbActions === 0
                  ? "label-grey-gamification-sm mb-0"
                  : gamificationObject.role === "Relecteur"
                  ? "label-green-proofreader-sm mb-0"
                  : gamificationObject.role === "Contributeur"
                  ? "label-pink-contributor-sm mb-0"
                  : gamificationObject.role === "Animateur"
                  ? "label-orange-animator-sm mb-0"
                  : gamificationObject.role === "Createur"
                  ? "label-blue-creator-sm mb-0"
                  : "label-light-blue-learner-sm mb-0"
              }
            >
              {gamificationObject.nbActions}
              {gamificationObject.role === "Relecteur"
                ? (gamificationObject.nbActions >1 ? " formations relues": " formation relue")
                : gamificationObject.role === "Contributeur"
                ? (gamificationObject.nbActions >1 ? " actions sur des posts": " action sur des posts")
                : gamificationObject.role === "Animateur"
                ? (gamificationObject.nbActions >1 ? " formations animées" : " formation animée")
                : gamificationObject.role === "Createur"
                ? (gamificationObject.nbActions >1 ? " formations publiées": " formation publiée")
                : (gamificationObject.nbActions >1 ? " formations terminées": " formation terminée")}
            </p>
          </Col>
          <Col xs={6} className="ps-0 pt-2">
            <p
              style={{ textAlign: "right", marginRight: 10 }}
              className={
                gamificationObject.nbActions === 0
                  ? "label-grey-gamification-sm mb-0"
                  : "label-black-gamification mb-0"
              }
            >
              {gamificationObject.nbMissingActions} {gamificationObject.nbMissingActions <= 1 ? "manquante" : "manquantes"}
            </p>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
export default TrophiesSlide;
