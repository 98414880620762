import {
  TrainingsCount,
  CreatedTrainingsCountData,
  TrainingCoursesCountData,
  Notifications,
  Subjects,
  TrainingPreview,
  TrainingPreviewData,
  TrainingLaunchData,
  TrainingLaunch,
  PublishedTraining,
  NonPublishedTraining,
  ValidatedTrainings,
  TrainingNeedsProofReading,
  NonPublishedSyncTraining,
} from "./../../config/training";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import TrainingService from "../../services/training.services";
import { training } from "../../utils/constant";

export interface TrainingState {
  ongoingTrainingCourses: any[];
  ongoingTrainingsLoading: boolean;
  registredTrainingCourses: any[];
  registredTrainingsLoading: boolean;
  finishedTrainingCourses: any[];
  finishedTrainingsLoading: boolean;
  trainingCourses: any[];
  publishedTrainings: any[];
  publishedTrainingsLoading: boolean;
  notPublishedTrainings: any[];
  notPublishedTrainingsLoading: boolean;
  createdTrainings: any[];
  trainingsAwaitingNotation: any[];
  trainingsAwaitingProofreading: any[];
  notifications: Notifications[];
  subjects: Subjects[];
  trainingCoursesCount: TrainingsCount[];
  trainingCoursesCountLoading: boolean;
  createdTrainingsCount: TrainingsCount[];
  trainingId: number | null;
  error: string | null;
  loading: string;
  trainingPreview: TrainingPreview;
  trainingLaunch: TrainingLaunch;
  trainingCatalog: any[];
  trainingCatalogCount: number;
  trainingDataCount: number;
  trainingLoading: boolean;
  trainingsAccordingToInterests: any[];
  pleasingTrainings: any[];
  trainingComments: any[];
  reviewComments: any[];
  formationId: number | null;
  trainingSuggestions: any[];
  trainingSuggestionsLoading: boolean;
  validatedTraining: ValidatedTrainings[];
  validatedTrainingLoading: boolean;
  validatedTrainingCount: number;
  trainingNeedsProofReading: TrainingNeedsProofReading[];
  trainingNeedsProofReadingLoading: boolean;
  trainingNeedsProofReadingCount: number;
  allPublishedTrainings: PublishedTraining[];
  allPublishedTrainingsLoading: boolean;
  allPublishedTrainingsCount: number;
  allNonPublishedTrainings: NonPublishedTraining[];
  allNonPublishedTrainingsLoading: boolean;
  allNonPublishedTrainingsDataCount: number;
  allNonPublishedTrainingsCount: number;
  allPublishedTrainingsDataCount: number;
  allUnpublishedTrainingsDataCount: number;
  allUnpublishedTrainings: PublishedTraining[];
  allUnpublishedTrainingsLoading: boolean;
  allUnpublishedTrainingsCount: number;
  trainingFeedback: any;
  allTrainingcomments: any[];
  allNonPublishedSyncTrainings: NonPublishedSyncTraining[];
  allNonPublishedSyncTrainingsLoading: boolean;
  allNonPublishedSyncTrainingsCount: number;
  allNonPublishedSyncTrainingsNbre: number;
  trainingPreviewDetail: any;
  show: boolean;
  targetRoute: string;
  events: any[];
  eventsLoading: boolean;
}

const initialState: TrainingState = {
  trainingCourses: [],
  ongoingTrainingCourses: [],
  ongoingTrainingsLoading: false,
  registredTrainingCourses: [],
  registredTrainingsLoading: false,
  finishedTrainingCourses: [],
  finishedTrainingsLoading: false,
  publishedTrainings: [],
  publishedTrainingsLoading: false,
  notPublishedTrainings: [],
  notPublishedTrainingsLoading: false,
  createdTrainings: [],
  trainingsAwaitingNotation: [],
  trainingsAwaitingProofreading: [],
  notifications: [],
  subjects: [],
  trainingCoursesCount: TrainingCoursesCountData,
  trainingCoursesCountLoading: false,
  createdTrainingsCount: CreatedTrainingsCountData,
  trainingId: null,
  error: null,
  loading: "inactive",
  trainingPreview: TrainingPreviewData,
  trainingLaunch: TrainingLaunchData,
  trainingCatalog: [],
  trainingCatalogCount: 1,
  trainingDataCount: 0,
  allNonPublishedTrainingsDataCount: 0,
  allPublishedTrainingsDataCount: 0,
  allUnpublishedTrainingsDataCount: 0,
  allUnpublishedTrainings: [],
  allUnpublishedTrainingsLoading: false,
  allUnpublishedTrainingsCount: 0,
  trainingLoading: false,
  trainingsAccordingToInterests: [],
  pleasingTrainings: [],
  trainingComments: [],
  reviewComments: [],
  formationId: null,
  trainingSuggestions: [],
  trainingSuggestionsLoading: false,
  allPublishedTrainings: [],
  allPublishedTrainingsLoading: false,
  allPublishedTrainingsCount: 0,
  allNonPublishedTrainings: [],
  allNonPublishedTrainingsLoading: false,
  allNonPublishedTrainingsCount: 0,
  trainingFeedback: {},
  validatedTraining: [],
  validatedTrainingLoading: false,
  validatedTrainingCount: 0,
  trainingNeedsProofReading: [],
  trainingNeedsProofReadingLoading: false,
  trainingNeedsProofReadingCount: 0,
  allTrainingcomments: [],
  allNonPublishedSyncTrainings: [],
  allNonPublishedSyncTrainingsLoading: false,
  allNonPublishedSyncTrainingsCount: 1,
  allNonPublishedSyncTrainingsNbre: 0,
  trainingPreviewDetail: training,
  show: false,
  targetRoute: "",
  events: [],
  eventsLoading: false,
};

// Retrieve Training Courses actions creator
export const getTrainingCourses = createAsyncThunk(
  "training/courses",
  async (props: any, { getState }: any) => {
    const { page, formationStatus, tag } = props;
    const trainingState = getState().training;
    const trainings = [...trainingState[tag]];
    const res = await TrainingService.getTrainingCourses(page, formationStatus);
    return { data: page === 1 ? res : trainings.concat(res), tag };
  }
);

// Retrieve Created Training actions creator
export const getCreatedTrainings = createAsyncThunk(
  "training/created",
  async (props: any, { getState }: any) => {
    const { page, formationStatus, tag } = props;
    const trainingState = getState().training;
    const trainings = [...trainingState[tag]];
    const res = await TrainingService.getCreatedTraining(page, formationStatus);
    return { data: page === 1 ? res : trainings.concat(res), tag };
  }
);
// Retrieve Trainings Awaiting Notation
export const retrieveTrainingsAwaitingNotation = createAsyncThunk(
  "training/notes",
  async (props: any) => {
    const { page, filters } = props;
    const res = await TrainingService.getTrainingsAwaitingNotation({
      page,
      filters,
      limit: 2,
    });
    return res;
  }
);

// Retrieve Trainings Awaiting Proofreading
export const retrieveTrainingsAwaitingProofreading = createAsyncThunk(
  "training/proofreading",
  async (props: any) => {
    const { page, filters } = props;
    const res = await TrainingService.getTrainingsAwaitingProofreading({
      page,
      filters,
      limit: 2,
    });
    return res;
  }
);

// Retrieve Awaiting Trainings
export const getAwaitingTrainings = createAsyncThunk(
  "training/awaiting",
  async (props: any, { getState }: any) => {
    const { page, formationStatus, tag } = props;
    const trainingState = getState().training;
    const trainings = [...trainingState[tag]];
    const res = await TrainingService.getTrainingCourses(page, formationStatus);
    return { data: page === 1 ? res : trainings.concat(res), tag };
  }
);

// Retrieve Notification actions creator
export const getNotifications = createAsyncThunk(
  "training/notifications",
  async () => {
    const res = await TrainingService.getNotifications();
    return res;
  }
);

// Retrieve Notification actions creator
export const getSubjects = createAsyncThunk("training/subjects", async () => {
  const res = await TrainingService.getSubjects();
  return res;
});

// Create new training action creator
export const postTraining = createAsyncThunk(
  "training/create",
  async (props: any) => {
    const { values, history, tag } = props;
    const res: any =
      tag === "create"
        ? await TrainingService.postTraining(values)
        : await TrainingService.putTraining(values);
    let trainings: any = {};
    if (res.id) {
      trainings = await TrainingService.getTraining(Number(res.id));
      values.status === "preview" && history.push("/trainingPreview/" + res.id);
    }

    return { id: res.id, trainings };
  }
);

// Retrieve Training Courses count
export const getTrainingCoursesCount = createAsyncThunk(
  "training/courses/count",
  async () => {
    const res = await TrainingService.getTrainingCoursesCount();
    return res;
  }
);

// Retrieve Created trainings count
export const getCreatedTrainingsCount = createAsyncThunk(
  "training/created/count",
  async () => {
    const res = await TrainingService.getCreatedTrainingsCount();
    return res;
  }
);

// Retrieve training catalog
export const retrieveTrainingCatalog = createAsyncThunk(
  "training/catalog",
  async (props: any) => {
    const { page, filters } = props;
    const res = await TrainingService.getTrainingCatalog({ page, filters });
    return res;
  }
);

// dispathPreview en attente de relecure
export const previewRelecture = createAsyncThunk(
  "training/relecture",
  async (props: any) => {
    const { id, history } = props;
    const trainings = await TrainingService.getTraining(id);
    history.push("/trainingPreview/" + id);
    return { id, trainings };
  }
);

// dispathPreview en attente de notation
export const previewNotation = createAsyncThunk(
  "training/notation",
  async (props: any) => {
    const { id, history } = props;
    const trainings = await TrainingService.getTraining(id);
    history.push("/trainingPreview/" + id);
    return { id, trainings };
  }
);

// dispathPreview Editor
export const previewEditor = createAsyncThunk(
  "training/previewEditor",
  async (props: any) => {
    const { id, history } = props;
    const trainings = await TrainingService.getTraining(id);
    history.push("/trainingPreview/" + id);
    return { id, trainings };
  }
);

// Retrieve all Published Training
export const retrieveAllPublishedTrainings = createAsyncThunk(
  "training/published",
  async (props: any) => {
    const { page, filters } = props;
    const res = await TrainingService.getAllPublishedTrainings({
      page,
      filters,
    });
    return res;
  }
);

// Retrieve all Unpublished Training
export const retrieveAllUnpublishedTrainings = createAsyncThunk(
  "training/unpublished",
  async (props: any) => {
    const { page, filters } = props;
    const res = await TrainingService.getAllUnpublishedTrainings({
      page,
      filters,
    });
    return res;
  }
);

// Retrieve all Non Published Training
export const retrieveAllNonPublishedTrainings = createAsyncThunk(
  "training/NonPublished",
  async (props: any) => {
    const { page, filters } = props;
    const res = await TrainingService.getAllNonPublishedTrainings({
      page,
      filters,
    });
    return res;
  }
);
// Retrieve all Non Published Sync Training
export const retrieveAllNonPublishedSyncTrainings = createAsyncThunk(
  "training/NonPublishedSync",
  async (props: any) => {
    const { page, filters } = props;
    const res = await TrainingService.getAllNonPublishedSyncTrainings({
      page,
      filters,
    });
    return res;
  }
);
// Retrieve training catalog count
export const retrieveTrainingCatalogCount = createAsyncThunk(
  "training/catalog/count",
  async () => {
    const res = await TrainingService.getTrainingCatalogCount();
    return res;
  }
);

// Retrieve trainings according to interests
export const retrieveTrainingsAccordingToInterests = createAsyncThunk(
  "training/catalog/interest",
  async () => {
    const res = await TrainingService.getTrainingsAccordingToInterests();
    return res;
  }
);

// Retrieve trainings according to interests
export const retrievePleasingTrainings = createAsyncThunk(
  "training/catalog/like",
  async () => {
    const res = await TrainingService.getPleasingTrainings();
    return res;
  }
);

// Retrieve training comments
export const retrieveTrainingComments = createAsyncThunk(
  "training/comments",
  async (props: any) => {
    const { formationId } = props;
    const res = await TrainingService.getTrainingComments(formationId);
    return res;
  }
);

// Add comment on training
export const postComment = createAsyncThunk(
  "training/addComment",
  async (props: any, { dispatch }) => {
    const { formationId, comment } = props;
    const res = await TrainingService.postComment(formationId, comment);
    dispatch(
      retrieveTrainingComments({
        formationId: formationId,
      })
    );
    return res;
  }
);

// Retrieve All Training Review Comments
export const retrieveAllTrainingsReviewComments = createAsyncThunk(
  "training/review/all",
  async (props: any) => {
    const { formationId } = props;
    const res = await TrainingService.getTrainingReview(formationId);
    return res;
  }
);

// Retrieve review comments
export const retrieveReviewComments = createAsyncThunk(
  "training/review/comments",
  async (props: any) => {
    const { formationId } = props;
    const res = await TrainingService.getReviewComments(formationId);
    return res;
  }
);

// Add comment on review
export const postReviewComment = createAsyncThunk(
  "training/review/addComment",
  async (props: any, { dispatch }) => {
    const { formationId, comment } = props;
    const res = await TrainingService.postReviewComment(formationId, comment);
    dispatch(
      retrieveReviewComments({
        formationId,
      })
    );
    return res;
  }
);

// delete review comment
export const deleteReviewComment = createAsyncThunk(
  "training/review/deleteComment",
  async (props: any, { dispatch }) => {
    const { formationId, commentId } = props;
    const res = await TrainingService.deleteReviewComment(commentId);
    dispatch(
      retrieveReviewComments({
        formationId,
      })
    );
    return res;
  }
);

// Retrieve training
export const retrieveTrainingDetail: any = createAsyncThunk(
  "training/detail",
  async (props: any) => {
    const { id } = props;
    const res = await TrainingService.getTraining(+id);
    return res;
  }
);

// Retrieve training details to launch
export const retrieveTrainingLaunchDetail = createAsyncThunk(
  "training/launch/detail",
  async (props: any) => {
    const { id, history } = props;
    const res: any = await TrainingService.getTraining(+id);
    if (res) {
      history.push("/trainingLaunch/" + res.id);
    }
    return { id, trainings: res };
  }
);

// Retrieve training suggestions
export const retrieveTrainingDetailSuggestions = createAsyncThunk(
  "training/detail/suggest",
  async (props: any) => {
    const { id } = props;
    const res = await TrainingService.getTrainingsuggestions(id);
    return res;
  }
);

// startTraining
export const StartTraining = createAsyncThunk(
  "training/start",
  async (props: any) => {
    const { id } = props;
    const res = await TrainingService.postTrainingStart(id);
    return res;
  }
);

// FinishContent
export const FinishContent = createAsyncThunk(
  "training/finishContent",
  async (props: any) => {
    const { id, formationId } = props;
    if (formationId) {
      const res = await TrainingService.finishContent(id, formationId);
      return res;
    } else {
      const res = await TrainingService.finishContent(id);
      return res;
    }
  }
);

// FinishContent
export const FinishSection = createAsyncThunk(
  "training/finishSection",
  async (props: any) => {
    const { id } = props;
    const res = await TrainingService.finishSection(id);
    return res;
  }
);

// AnswerQuestion
export const AnswerQuestion = createAsyncThunk(
  "training/answerQuestion",
  async (props: any) => {
    const { id, answers } = props;
    const res = await TrainingService.postAnswer(id, answers);
    return res;
  }
);

// Evaluate
export const Evaluate = createAsyncThunk(
  "training/evaluate",
  async (props: any) => {
    const { id, score, total } = props;
    const res = await TrainingService.postEvaluate(id, score, total);
    return res;
  }
);

// Get Feedback
export const retrieveFeedback: any = createAsyncThunk(
  "training/feedback",
  async (props: any) => {
    const { formationId, userId } = props;
    const res = await TrainingService.getFeedback(formationId, userId);
    return res;
  }
);

// Assign Proofreader To Training
export const assignProofreaderToTraining = createAsyncThunk(
  "training/assign",
  async (props: any) => {
    const { formationId, userId } = props;
    const res = await TrainingService.assignProofreaderToTraining(
      formationId,
      userId
    );
    return res;
  }
);
export const getAllEvents = createAsyncThunk("events/get", async () => {
  const res = await TrainingService.getAllEvents();
  return res;
});

export const trainingSlice = createSlice({
  name: "training",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setTraining(state, action) {
      state.trainingPreviewDetail = action.payload;
      if (!action.payload.trainingId) {
        state.trainingPreviewDetail = {
          ...state.trainingPreviewDetail,
          status: "previewDetail",
        };
      }
    },
    setModal(state, action) {
      state.show = action.payload.show;
      state.targetRoute = action.payload.targetRoute;
    },
    saveTrainingPreview(state, action) {
      state.trainingPreview = action.payload;
    },
    saveTrainingLaunch(state, action) {
      state.trainingLaunch = action.payload;
    },
    setProgression(state, action) {
      state.trainingLaunch.progression.progressionStatus = action.payload;
    },
    updateCurrentContent(state, action) {
      TrainingService.finishContent(action.payload.contentId).then(
        (result: any) => {
          state.trainingLaunch.progression.currentStep = result.sectionId;
          state.trainingLaunch.progression.currentContent = result.contentId;
        }
      );
    },
    updateQuizzResult(state, action) {
      const indexSection = state.trainingLaunch.progression.currentStep
        ? state.trainingLaunch.sections.findIndex(
            (section) =>
              section.id === state.trainingLaunch.progression.currentStep
          )
        : 0;
      state.trainingLaunch.sections[indexSection].sectionStatus =
        action.payload.statut;
      const index = state.trainingLaunch.sections[
        indexSection
      ].sectionContent.findIndex(
        (content) => content.type === "eval" && content.autoEval === false
      );
      state.trainingLaunch.sections[indexSection].sectionContent[
        index
      ].resultsQuizz = {
        correct: action.payload.correct,
        total: action.payload.total,
      };
      TrainingService.postEvaluate(
        action.payload.id,
        action.payload.correct,
        action.payload.total
      );
    },
    updateEvalResult(state, action) {
      const indexSection = state.trainingLaunch.progression.currentStep
        ? state.trainingLaunch.sections.findIndex(
            (section) =>
              section.id === state.trainingLaunch.progression.currentStep
          )
        : 0;
      state.trainingLaunch.sections[indexSection].sectionStatus =
        action.payload.statut;
      const index = state.trainingLaunch.sections[
        indexSection
      ].sectionContent.findIndex(
        (content) => content.type === "eval" && content.autoEval === true
      );
      state.trainingLaunch.sections[indexSection].sectionContent[
        index
      ].resultsEval = {
        correct: action.payload.correct,
        total: action.payload.total,
      };
      TrainingService.postEvaluate(
        action.payload.id,
        action.payload.correct,
        action.payload.total
      );
    },
    nextStep(state, action) {
      TrainingService.finishSection(action.payload.stepId).then(
        (result: any) => {
          state.trainingLaunch.progression.currentStep = result.sectionId;
          state.trainingLaunch.progression.currentContent = result.contentId;
        }
      );
    },
    startLaunch(state, action) {
      TrainingService.postTrainingStart(state.trainingLaunch.id).then(
        (result: any) => {
          state.trainingLaunch.progression.currentStep = result.sectionId;
          state.trainingLaunch.progression.currentContent = result.contentId;
        }
      );
    },
    answerQuestion(state, action) {
      TrainingService.postAnswer(action.payload.id, action.payload.title).then(
        (result: any) => {
          state.trainingLaunch.progression.currentStep = result.sectionId;
          state.trainingLaunch.progression.currentContent = result.contentId;
        }
      );
    },
    evaluate(state, action) {
      TrainingService.postEvaluate(
        action.payload.id,
        action.payload.score,
        action.payload.total
      ).then((result: any) => {
        state.trainingLaunch.progression.currentStep = result.sectionId;
        state.trainingLaunch.progression.currentContent = result.contentId;
      });
    },
    setSelfAssign(state) {
      state.trainingPreview.validation_status = "attente_notation";
      state.trainingPreview.is_relecteur = true;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  extraReducers: (builder) => {
    builder
      .addCase(getTrainingCourses.pending, (state: TrainingState) => {
        state.ongoingTrainingsLoading = true;
        state.registredTrainingsLoading = true;
        state.finishedTrainingsLoading = true;
      })
      .addCase(
        getTrainingCourses.fulfilled,
        (state: TrainingState, action: any) => {
          //@ts-ignore
          state[action.payload.tag] = action.payload.data;
          state.ongoingTrainingsLoading = false;
          state.registredTrainingsLoading = false;
          state.finishedTrainingsLoading = false;
        }
      )
      .addCase(getTrainingCourses.rejected, (state: TrainingState) => {
        state.ongoingTrainingsLoading = false;
        state.registredTrainingsLoading = false;
        state.finishedTrainingsLoading = false;
      })
      .addCase(getCreatedTrainings.pending, (state: TrainingState) => {
        state.publishedTrainingsLoading = true;
        state.notPublishedTrainingsLoading = true;
      })
      .addCase(
        getCreatedTrainings.fulfilled,
        (state: TrainingState, action: any) => {
          //@ts-ignore
          state[action.payload.tag] = action.payload.data;
          state.publishedTrainingsLoading = false;
          state.notPublishedTrainingsLoading = false;
        }
      )
      .addCase(getCreatedTrainings.rejected, (state: TrainingState) => {
        state.publishedTrainingsLoading = false;
        state.notPublishedTrainingsLoading = false;
      })
      .addCase(
        retrieveTrainingsAwaitingNotation.fulfilled,
        (state: TrainingState, action: any) => {
          state.trainingsAwaitingNotation = action.payload.data;
        }
      )
      .addCase(
        retrieveTrainingsAwaitingProofreading.fulfilled,
        (state: TrainingState, action: any) => {
          state.trainingsAwaitingProofreading = action.payload.data;
        }
      )
      .addCase(getSubjects.fulfilled, (state: TrainingState, action: any) => {
        state.subjects = action.payload;
      })
      .addCase(
        getNotifications.fulfilled,
        (state: TrainingState, action: any) => {
          state.notifications = action.payload;
        }
      )
      .addCase(getTrainingCoursesCount.pending, (state: TrainingState) => {
        state.trainingCoursesCountLoading = true;
      })
      .addCase(
        getTrainingCoursesCount.fulfilled,
        (state: TrainingState, action: any) => {
          state.trainingCoursesCount = action.payload;
          state.trainingCoursesCountLoading = false;
        }
      )
      .addCase(getTrainingCoursesCount.rejected, (state: TrainingState) => {
        state.trainingCoursesCountLoading = false;
      })
      .addCase(getAllEvents.pending, (state: TrainingState) => {
        state.eventsLoading = true;
      })
      .addCase(getAllEvents.fulfilled, (state: TrainingState, action: any) => {
        state.events = action.payload.data;
        state.eventsLoading = false;
      })
      .addCase(getAllEvents.rejected, (state: TrainingState) => {
        state.eventsLoading = false;
      })
      .addCase(
        getCreatedTrainingsCount.fulfilled,
        (state: TrainingState, action: any) => {
          state.createdTrainingsCount = action.payload;
        }
      )
      .addCase(postTraining.pending, (state: TrainingState) => {
        state.error = null;
        state.loading = "true";
      })
      .addCase(postTraining.fulfilled, (state: TrainingState, action: any) => {
        state.trainingId = action.payload.id;
        state.loading = "false";
        state.trainingPreview = action.payload.trainings;
      })
      .addCase(postTraining.rejected, (state: TrainingState) => {
        state.loading = "false";
        state.error = "Vérifier les données";
      })
      .addCase(previewRelecture.pending, (state: TrainingState) => {
        state.error = null;
        state.loading = "true";
      })
      .addCase(previewNotation.pending, (state: TrainingState) => {
        state.error = null;
        state.loading = "true";
      })
      .addCase(
        previewRelecture.fulfilled,
        (state: TrainingState, action: any) => {
          state.trainingId = action.payload.id;
          state.loading = "false";
          state.trainingPreview = action.payload.trainings;
          state.trainingPreview.status = "pending";
          state.trainingPreview.validation_status = "attente_relecture";
        }
      )
      .addCase(
        previewNotation.fulfilled,
        (state: TrainingState, action: any) => {
          state.trainingId = action.payload.id;
          state.loading = "false";
          state.trainingPreview = action.payload.trainings;
          state.trainingPreview.status = "pending";
          state.trainingPreview.validation_status = "attente_notation";
          state.trainingPreview.is_relecteur = true;
        }
      )
      .addCase(previewEditor.fulfilled, (state: TrainingState, action: any) => {
        state.trainingId = action.payload.id;
        state.loading = "false";
        state.trainingPreview = action.payload.trainings;
        state.trainingPreview.is_relecteur = true;
      })
      .addCase(retrieveTrainingCatalog.pending, (state: TrainingState) => {
        state.trainingLoading = true;
      })
      .addCase(
        retrieveTrainingCatalog.fulfilled,
        (state: TrainingState, action: any) => {
          state.trainingCatalog = action.payload.data;
          state.trainingCatalogCount = action.payload.totalPages;
          state.trainingLoading = false;
        }
      )
      .addCase(retrieveTrainingCatalog.rejected, (state: TrainingState) => {
        state.trainingLoading = false;
      })
      .addCase(
        retrieveTrainingCatalogCount.fulfilled,
        (state: TrainingState, action: any) => {
          state.trainingCatalogCount = Math.trunc(action.payload.nb / 10) + 1;
        }
      )
      .addCase(
        retrieveTrainingsAccordingToInterests.fulfilled,
        (state: TrainingState, action: any) => {
          state.trainingsAccordingToInterests = action.payload;
        }
      )
      .addCase(
        retrievePleasingTrainings.fulfilled,
        (state: TrainingState, action: any) => {
          state.pleasingTrainings = action.payload;
        }
      )
      .addCase(retrieveTrainingDetail.pending, (state: TrainingState) => {
        state.trainingLoading = true;
      })
      .addCase(
        retrieveTrainingDetail.fulfilled,
        (state: TrainingState, action: any) => {
          state.trainingPreview = action.payload;
          state.trainingLoading = false;
        }
      )
      .addCase(retrieveTrainingLaunchDetail.pending, (state: TrainingState) => {
        state.trainingLoading = true;
      })
      .addCase(
        retrieveTrainingLaunchDetail.fulfilled,
        (state: TrainingState, action: any) => {
          state.trainingId = action.payload.id;
          state.trainingLaunch = action.payload.trainings;
          if (action.payload.trainings.progression) {
            state.trainingLaunch = action.payload.trainings;
          }
          state.trainingLoading = false;
        }
      )
      .addCase(
        retrieveTrainingLaunchDetail.rejected,
        (state: TrainingState) => {
          state.loading = "false";
          state.error = "Something Went Wrong";
        }
      )
      .addCase(
        retrieveTrainingDetailSuggestions.pending,
        (state: TrainingState, action: any) => {
          state.trainingSuggestionsLoading = true;
        }
      )
      .addCase(
        retrieveTrainingDetailSuggestions.fulfilled,
        (state: TrainingState, action: any) => {
          state.trainingSuggestions = action.payload;
          state.trainingSuggestionsLoading = false;
        }
      )
      .addCase(
        retrieveTrainingComments.fulfilled,
        (state: TrainingState, action: any) => {
          state.trainingComments = action.payload;
        }
      )
      .addCase(
        retrieveReviewComments.fulfilled,
        (state: TrainingState, action: any) => {
          state.reviewComments = action.payload;
        }
      )
      .addCase(
        retrieveAllTrainingsReviewComments.fulfilled,
        (state: TrainingState, action: any) => {
          state.allTrainingcomments = action.payload.proofreaders;
        }
      )
      .addCase(StartTraining.fulfilled, (state: TrainingState, action: any) => {
        state.trainingLaunch.progression.currentStep = action.payload.sectionId;
        state.trainingLaunch.progression.currentContent =
          action.payload.contentId;
      })
      .addCase(FinishContent.fulfilled, (state: TrainingState, action: any) => {
        state.trainingLaunch.progression.currentStep = action.payload.sectionId;
        state.trainingLaunch.progression.currentContent =
          action.payload.contentId;
      })
      .addCase(FinishSection.fulfilled, (state: TrainingState, action: any) => {
        state.trainingLaunch.progression.currentStep = action.payload.sectionId;
        state.trainingLaunch.progression.currentContent =
          action.payload.contentId;
      })
      .addCase(
        retrieveAllPublishedTrainings.pending,
        (state: TrainingState) => {
          state.allPublishedTrainingsLoading = true;
        }
      )
      .addCase(
        retrieveAllPublishedTrainings.fulfilled,
        (state: TrainingState, action: any) => {
          state.allPublishedTrainings = action.payload.data;
          state.allPublishedTrainingsCount = action.payload.totalPages;
          state.allPublishedTrainingsDataCount = action.payload.totalCount;
          state.allPublishedTrainingsLoading = false;
        }
      )
      .addCase(
        retrieveAllUnpublishedTrainings.pending,
        (state: TrainingState) => {
          state.allUnpublishedTrainingsLoading = true;
        }
      )
      .addCase(
        retrieveAllUnpublishedTrainings.fulfilled,
        (state: TrainingState, action: any) => {
          state.allUnpublishedTrainings = action.payload.data;
          state.allUnpublishedTrainingsCount = action.payload.totalPages;
          state.allUnpublishedTrainingsDataCount = action.payload.totalCount;
          state.allUnpublishedTrainingsLoading = false;
        }
      )
      .addCase(
        retrieveAllNonPublishedTrainings.pending,
        (state: TrainingState) => {
          state.allNonPublishedTrainingsLoading = true;
        }
      )
      .addCase(
        retrieveAllNonPublishedTrainings.fulfilled,
        (state: TrainingState, action: any) => {
          state.allNonPublishedTrainings = action.payload.data;
          state.allNonPublishedTrainingsCount = action.payload.totalPages;
          state.allNonPublishedTrainingsDataCount = action.payload.totalCount;
          state.allNonPublishedTrainingsLoading = false;
        }
      )
      .addCase(
        retrieveAllNonPublishedSyncTrainings.pending,
        (state: TrainingState) => {
          state.allNonPublishedSyncTrainingsLoading = true;
        }
      )
      .addCase(
        retrieveAllNonPublishedSyncTrainings.fulfilled,
        (state: TrainingState, action: any) => {
          state.allNonPublishedSyncTrainings = action.payload.data;
          state.allNonPublishedSyncTrainingsCount = action.payload.totalPages;
          state.allNonPublishedSyncTrainingsNbre = action.payload.totalCount;
          state.allNonPublishedSyncTrainingsLoading = false;
        }
      )
      .addCase(retrieveFeedback.pending, (state: TrainingState) => {
        state.trainingLoading = true;
      })
      .addCase(
        retrieveFeedback.fulfilled,
        (state: TrainingState, action: any) => {
          const commentsCount = action.payload.filter(
            (el: any) => el.feedback.comment
          ).length;
          const noteMoy =
            action.payload.reduce(
              (total: any, current: any) => total + current.feedback.note,
              0
            ) / action.payload.length || 0;
          state.trainingLoading = false;
          state.trainingFeedback = {
            commentsCount,
            noteMoy,
            feedBacks: action.payload,
          };
        }
      )
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectTrainingCourses = (state: RootState) =>
  state.training.trainingCourses;
export const selectOngoingTrainingCourses = (state: RootState) =>
  state.training.ongoingTrainingCourses;
export const selectOngoingTrainingsLoading = (state: RootState) =>
  state.training.ongoingTrainingsLoading;
export const selectRegistredTrainingCourses = (state: RootState) =>
  state.training.registredTrainingCourses;
export const selectRegistredTrainingsLoading = (state: RootState) =>
  state.training.registredTrainingsLoading;
export const selectfinishedTrainingCourses = (state: RootState) =>
  state.training.finishedTrainingCourses;
export const selectFinishedTrainingsLoading = (state: RootState) =>
  state.training.finishedTrainingsLoading;
export const selectPublishedTrainings = (state: RootState) =>
  state.training.publishedTrainings;
export const selectPublishedTrainingsLoading = (state: RootState) =>
  state.training.publishedTrainingsLoading;
export const selectNotPublishedTrainings = (state: RootState) =>
  state.training.notPublishedTrainings;
export const selectNotPublishedTrainingsLoading = (state: RootState) =>
  state.training.notPublishedTrainingsLoading;
export const selectCreatedTrainings = (state: RootState) =>
  state.training.createdTrainings;
export const selectTrainingsAwaitingNotation = (state: RootState) =>
  state.training.trainingsAwaitingNotation;
export const selectTrainingsAwaitingProofreading = (state: RootState) =>
  state.training.trainingsAwaitingProofreading;
export const selectNotifications = (state: RootState) =>
  state.training.notifications;
export const selectSubjects = (state: RootState) => state.training.subjects;
export const selectError = (state: RootState) => state.training.error;
export const selectLoading = (state: RootState) => state.training.loading;
export const selectTrainingCoursesCount = (state: RootState) =>
  state.training.trainingCoursesCount;
export const selectTrainingCoursesCountLoading = (state: RootState) =>
  state.training.trainingCoursesCountLoading;
export const selectCreatedTrainingsCount = (state: RootState) =>
  state.training.createdTrainingsCount;
export const selectTrainingPreview = (state: RootState) =>
  state.training.trainingPreview;
export const selectTrainingCatalog = (state: RootState) =>
  state.training.trainingCatalog;
export const selectTrainingCatalogCount = (state: RootState) =>
  state.training.trainingCatalogCount;
export const selectTrainingDataCount = (state: RootState) =>
  state.training.trainingDataCount;
export const selectTrainingLoading = (state: RootState) =>
  state.training.trainingLoading;
export const selectTrainingsAccordingToInterests = (state: RootState) =>
  state.training.trainingsAccordingToInterests;
export const selectPleasingTrainings = (state: RootState) =>
  state.training.pleasingTrainings;
export const selectTrainingLaunch = (state: RootState) =>
  state.training.trainingLaunch;
export const selectTrainingComments = (state: RootState) =>
  state.training.trainingComments;
export const selectAllTrainingComments = (state: RootState) =>
  state.training.allTrainingcomments;
export const selectReviewComments = (state: RootState) =>
  state.training.reviewComments;
export const selectTrainingSuggestions = (state: RootState) =>
  state.training.trainingSuggestions;
export const selectTrainingSuggestionsLoading = (state: RootState) =>
  state.training.trainingSuggestionsLoading;
export const selectAllPublishedTrainings = (state: RootState) =>
  state.training.allPublishedTrainings;
export const selectAllPublishedTrainingsLoading = (state: RootState) =>
  state.training.allPublishedTrainingsLoading;
export const selectAllPublishedTrainingsCount = (state: RootState) =>
  state.training.allPublishedTrainingsCount;
export const selectAllPublishedTrainingsDataCount = (state: RootState) =>
  state.training.allPublishedTrainingsDataCount;
export const selectAllUnpublishedTrainings = (state: RootState) =>
  state.training.allUnpublishedTrainings;
export const selectAllUnpublishedTrainingsLoading = (state: RootState) =>
  state.training.allUnpublishedTrainingsLoading;
export const selectAllUnpublishedTrainingsCount = (state: RootState) =>
  state.training.allUnpublishedTrainingsCount;
export const selectAllUnpublishedTrainingsDataCount = (state: RootState) =>
  state.training.allUnpublishedTrainingsDataCount;
export const selectAllNonPublishedTrainings = (state: RootState) =>
  state.training.allNonPublishedTrainings;
export const selectAllNonPublishedTrainingsLoading = (state: RootState) =>
  state.training.allNonPublishedTrainingsLoading;
export const selectAllNonPublishedTrainingsCount = (state: RootState) =>
  state.training.allNonPublishedTrainingsCount;
export const selectAllNonPublishedTrainingsDataCount = (state: RootState) =>
  state.training.allNonPublishedTrainingsDataCount;
export const selectAllNonPublishedSyncTrainings = (state: RootState) =>
  state.training.allNonPublishedSyncTrainings;
export const selectAllNonPublishedSyncTrainingsCount = (state: RootState) =>
  state.training.allNonPublishedSyncTrainingsCount;
export const selectAllNonPublishedSyncTrainingsNbre = (state: RootState) =>
  state.training.allNonPublishedSyncTrainingsNbre;
export const selectTrainingFeedback = (state: RootState) =>
  state.training.trainingFeedback;
export const selectTrainingPreviewDetail = (state: RootState) =>
  state.training.trainingPreviewDetail;
export const selectModal = (state: RootState) => state.training.show;
export const selectTargetRoute = (state: RootState) =>
  state.training.targetRoute;
export const selectAllEvents = (state: RootState) => state.training.events;

export const trainingActions = trainingSlice.actions;
export default trainingSlice.reducer;
