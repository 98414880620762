import { Grid } from "@mui/material";
import { useMemo } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import AgendaElement from "./AgendaElement";

const Agenda = ({ events, userRole }: any) => {
  const isNqtCollaborator = userRole.includes("NQT_COLLABORATOR");
  const isEditor = userRole.includes("EDITOR");
  const link =
  isNqtCollaborator || isEditor
    ? "api.nqt.fr/dashboard/evenements"
    : "app.nqt.fr/events";

  const baseUrl = useMemo(
    () =>
      (window.location.href.includes("clubmentors")
        ? "https://"
        : "https://preprod.") + link,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Row className="center-container mb-4">
      <Col md="12">
        <Card body color="light" outline className="card p-0">
          <CardBody>
            <Row className="pb-3 w-100">
              <Col md="9" sm="9" xs="9">
                <div className="label-black-nqt-xmd"> Agenda de vos clubs </div>
              </Col>
              <Col md="3" sm="3" xs="3">
                {events?.length !== 0 && (
                  <div className="pull-right label-primary-nqt-sm"
                  onClick={() => {
                    window.onbeforeunload = null;
                    window.location.href = baseUrl
                  }}
                  >
                    {" "}
                    Tout voir
                  </div>
                )}
              </Col>
            </Row>
            <Row className="w-100">
              {events?.length !== 0 ? (
                <Grid container spacing={2}>
                  {events?.map((event: any) => {
                    return (
                      <Grid item lg={6} md={6} sm={6} xs={6}>
                        <AgendaElement agendaEvent={event} link={baseUrl} isNqtCollaborator={isNqtCollaborator}/>
                      </Grid>
                    );
                  })}
                </Grid>
              ) : (
                <div style={{ padding: 25, textAlign: "center", fontSize: 22 }}>
                  Des évènements apparaîtront prochainement ici...
                </div>
              )}
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default Agenda;
