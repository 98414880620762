import { useState } from "react";
import { BiPause } from "react-icons/bi";
import classes from "./Audio.module.css";
const Audio = (props: any) => {
  const audio = document.querySelector("audio");
  const str_pad_left = (time: number, pad: string, length: number) => {
    time = +time.toString().split(".")[0];
    const minutes = Math.floor(time / 60);
    const seconds = time - minutes * 60;
    return (
      (new Array(length + 1).join(pad) + minutes).slice(-length) +
      ":" +
      (new Array(length + 1).join(pad) + seconds).slice(-length)
    );
  };
  const playIcon = (
    <svg
      width="11"
      height="14"
      viewBox="0 0 11 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 1.82001V12.18C0 12.97 0.87 13.45 1.54 13.02L9.68 7.84001C10.3 7.45001 10.3 6.55001 9.68 6.15001L1.54 0.980008C0.87 0.550008 0 1.03001 0 1.82001Z"
        fill="white"
      />
    </svg>
  );
  const pauseIcon = (
    <BiPause
      size={25}
      style={{ color: "#FFFFFF", position: "relative", left: "-7", top: "-2" }}
    ></BiPause>
  );
  const duration = audio?.duration || 0;
  const [backgroundSize, setBackgroundSize] = useState("0% 100%");
  const [timeLineValue, setTimeLineValue] = useState(0);
  const [currenTime, setCurrenTime] = useState(0);
  const [audioIcon, setaudioIcon] = useState(playIcon);
  const toggleAudio = () => {
    if (audio) {
      if (audio.paused) {
        audio.play();
        setaudioIcon(pauseIcon);
      } else {
        audio.pause();
        setaudioIcon(playIcon);
      }
    }
  };
  const changeTimelinePosition = () => {
    if (audio) {
      const percentagePosition = (100 * audio.currentTime) / audio.duration;
      setBackgroundSize(`${percentagePosition}% 100%`);
      setTimeLineValue(percentagePosition);
      setCurrenTime(audio.currentTime);
    }
  };
  const changeSeek = () => {
    const time = (timeLineValue * duration) / 100;
    if (audio) {
      audio.currentTime = time;
    }
  };
  const audioEnded = () => {
    setaudioIcon(playIcon);
  };

  if (audio) {
    audio.ontimeupdate = changeTimelinePosition;
    audio.onended = audioEnded;
  }
  return (
    <div
      style={{ height: props.height ? props.height : "auto" }}
      className={classes["audio-player"]}
    >
      <div className={classes["icon-container"]}>
        {props.children}
        <div className={classes.controls}>
          <button onClick={toggleAudio} className={classes["player-button"]}>
            {audioIcon}
          </button>
          <div style={{ height: 24 }} className={classes["current-time"]}>
            {str_pad_left(currenTime, "0", 2)}
          </div>
          <input
            type="range"
            className={classes.timeline}
            style={{ backgroundSize }}
            max="100"
            value={timeLineValue}
            onChange={changeSeek}
          ></input>
          <div className={classes.duration}>
            {str_pad_left(duration, "0", 2)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Audio;
