import { Col, Card, Spinner } from "reactstrap";
//@ts-ignore
import EllipsisText from "react-ellipsis-text";

import { ReactComponent as PopularTrainingIcon } from "../../../assets/img/icons/popularTraining.svg";
import { ReactComponent as PopularPostIcon } from "../../../assets/img/icons/popularPost.svg";
import { ReactComponent as ArrowRightIcon } from "../../../assets/img/icons/arrowRight.svg";
import { ReactComponent as AvatarIcon } from "../../../assets/img/icons/avatar-person.svg";
import { ReactComponent as SupportSmallBlueIcon } from "../../../assets/img/icons/supportSmall.svg";
import { ReactComponent as BravoSmallBlueIcon } from "../../../assets/img/icons/bravoSmall.svg";
import { ReactComponent as InterestedSmallBlueIcon } from "../../../assets/img/icons/interestingSmall.svg";
import { ReactComponent as AsyncIcon } from "../../../assets/img/icons/asyncIcon.svg";
import { ReactComponent as DigIcon } from "../../../assets/img/icons/Digital.svg";
import { ReactComponent as PresIcon } from "../../../assets/img/icons/presIcon.svg";
import { ReactComponent as HeartIcon } from "../../../assets/img/icons/heart.svg";
import { FaRegSmile } from "react-icons/fa";
import "../../../styles/clubnqt.scss";
import { useHistory } from "react-router";

const Highlights = ({ data, status, navigateToDetails, md, sm }: any) => {
  let text = "";
  const history = useHistory();
  data &&
    data.formation &&
    data.formation.subjects.map(
      (subject: any) => (text = text !== "" ? text + ", " + subject : subject)
    );
  return (
    <Col md="12" sm="12" xs="12">
      <Card
        color="light"
        outline
        body
        className="cardDetailsHighlight mt-0 p-3"
      >
        {status === "pending" ? (
          <div className="d-flex justify-content-center align-items-center">
            <Spinner animation="border" style={{ color: "#3367cd" }} />
          </div>
        ) : (
          data && (
            <>
              <p className="label-black-md">Formation la plus consultée</p>
              <div className="d-flex flex-row align-items-center">
                <PopularTrainingIcon />
                {data.formation ? (
                  <Card
                    color="light"
                    outline
                    body
                    className="cardDetailsHighlight mt-0 p-2 pb-0 ms-2 pe-3 pointer"
                    onClick={() => navigateToDetails(data.formation.id)}
                  >
                    <div className="d-flex flex-row align-items-center">
                      {data.formation.typologie === "async" ? (
                        <AsyncIcon />
                      ) : data.formation.typologie === "pres" ? (
                        <PresIcon />
                      ) : (
                        <DigIcon />
                      )}
                      <div className="d-flex flex-column w-100">
                        <p className="mb-0 ms-3 label-black fw-bold mt-2">
                          {data.formation.nom}
                        </p>
                        <div className="d-flex flex-row">
                          <p className="mt-0 ms-3 label-primary-normal mt-1 me-0">
                              {data.formation.count <=1 ?  data.formation.count + " Vue"
                                  : data.formation.count + " Vues"}
                          </p>
                          <p className="mt-0 ms-3 label-grey-normal ms-0 opacity-75">
                            <EllipsisText text={text} length={40} />
                          </p>
                        </div>
                      </div>
                      <ArrowRightIcon />
                    </div>
                  </Card>
                ) : (
                  <p className="mb-0 ms-3 label-black fw-bold mt-2">
                    Aucune formation créee
                  </p>
                )}
              </div>
              <p className="label-black-md mt-2">Post le plus populaire</p>
              <div className="d-flex flex-row  align-items-center">
                <PopularPostIcon />
                {data.post ? (
                  <Card
                    color="light"
                    outline
                    body
                    className="cardDetailsHighlight mt-0 p-2 pb-0 ms-2 pe-3 pointer"
                    onClick={() => history.push("/postView/" + data.post.id)}
                  >
                    <div className="d-flex flex-row align-items-center">
                      <div className="d-flex flex-column w-100">
                        <div className="d-flex flex-row align-items-center w-75">
                          {data.post.user_image ? (
                            <img
                              alt="profile"
                              style={{
                                width: 30,
                                height: 30,
                                borderRadius: "50%",
                              }}
                              src={data.post.user_image}
                            />
                          ) : (
                            <AvatarIcon
                              className=" me-2"
                              style={{
                                width: 30,
                                height: 30,
                                borderRadius: "50%",
                              }}
                            />
                          )}
                          <p className="label-black fw-bold m-0">
                            {data.post.user_name}
                          </p>
                        </div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: data.post.description,
                          }}
                          className="post-text pt-2 mb-2"
                        />
                        <div className="d-flex flex-row">
                          <div className="d-flex flex-row w-75">
                            {data.post.likeCount > 0 && (
                              <>
                                <div className="circle-icon">
                                  <HeartIcon />
                                </div>
                                <p className="label-primary-normal ms-1 me-2 mt-1 p-0">
                                  {data.post.likeCount}
                                </p>
                              </>
                            )}
                            {data.post.adoreCount > 0 && (
                              <>
                                <div className="circle-icon">
                                  <FaRegSmile size={10} color="#3367CD" />
                                </div>
                                <p className="label-primary-normal ms-1 me-2 mt-1 p-0">
                                  {data.post.adoreCount}
                                </p>
                              </>
                            )}
                            {data.post.supportCount > 0 && (
                              <>
                                <div className="circle-icon">
                                  <SupportSmallBlueIcon />
                                </div>
                                <p className="label-primary-normal ms-1 me-2 mt-1 p-0">
                                  {data.post.supportCount}
                                </p>
                              </>
                            )}
                            {data.post.interestingCount > 0 && (
                              <>
                                <div className="circle-icon">
                                  <InterestedSmallBlueIcon />
                                </div>
                                <p className="label-primary-normal ms-1 me-2 mt-1 p-0">
                                  {data.post.interestingCount}
                                </p>
                              </>
                            )}
                            {data.post.bravoCount > 0 && (
                              <>
                                <div className="circle-icon">
                                  <BravoSmallBlueIcon />
                                </div>
                                <p className="label-primary-normal ms-1 me-2 mt-1 p-0">
                                  {data.post.bravoCount}
                                </p>
                              </>
                            )}
                          </div>
                          <div className="w-25 ">
                            <p className="label-primary-normal mt-1 float-end pointer">
                              {data.post.commentspost_count + (data.post.commentspost_count > 1 ? " commentaires": " commentaire")}
                            </p>
                          </div>
                        </div>
                      </div>
                      <ArrowRightIcon />
                    </div>
                  </Card>
                ) : (
                  <p className="mb-0 ms-3 label-black fw-bold mt-2">
                    Aucun post crée
                  </p>
                )}
              </div>
            </>
          )
        )}
      </Card>
    </Col>
  );
};

export default Highlights;
