import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
//@ts-ignore
import { AsyncStorage } from "AsyncStorage";

import { RootState } from "../store";
import AuthService from "../../services/auth.service";
import { PUBLIC_URL } from "../../utils/base";

export interface AuthState {
  status: "idle" | "loading" | "failed";
}

const initialState: AuthState = {
  status: "idle",
};
// Retrieve users actions creator
export const callback = createAsyncThunk("auth/callback", async (data: any) => {
  const { code } = data;
  return AuthService.callback(code)
    .then(async (res: any) => {
      await AsyncStorage.setItem("token", res.token);
      await AsyncStorage.setItem("refreshToken", res.refresh_token);
      window.location.href = PUBLIC_URL + "/";
      return res;
    })
    .catch((error: string) => error);
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  extraReducers: (builder) => {
    builder
      .addCase(callback.pending, (state) => {
        state.status = "loading";
      })
      .addCase(callback.fulfilled, (state: AuthState) => {
        state.status = "idle";
      })
      .addCase(callback.rejected, (state) => {
        state.status = "failed";
      });
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectStatus = (state: RootState) => state.auth.status;

export default authSlice.reducer;
