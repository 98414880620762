import http from "../config/httpCommon";

const AuthService = {
  callback(code: string) {
    return new Promise(async (resolve, reject) => {
      (await http())
        .get("/auth/callback", {
          params: { code },
        })
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },
};

export default AuthService;
