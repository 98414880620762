import { useMedia } from "react-media";
import { Col, Row } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import { useHistory, useLocation } from "react-router";
import { useEffect, useRef, useState } from "react";
import { MdDone } from "react-icons/md";

import Layout from "../../../components/Layout";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import AllCatalog from "./components/AllCatalog";
import PleasingTrainings from "./components/PleasingTrainings";
import TrainingsAccordingToInterrests from "./components/TrainingsAccordingToInterrests";
import {
  selectTrainingPreviewDetail,
  trainingActions,
} from "../../../redux/slices/trainingSlice";

const ConditionalWrapper = ({ condition, wrapper, children }: any) =>
  condition ? children : wrapper(children);

const TrainingsCatalogContainer = (props: any) => {
  const {layoutDisplayed} = props;

  const dispatch = useAppDispatch();

  const trainingEditWithoutId = useAppSelector(selectTrainingPreviewDetail);
  const history = useHistory();
  useEffect(() => {
    // @ts-ignore
    if (
      trainingEditWithoutId.typology !== "" &&
      !trainingEditWithoutId.trainingId
    ) {
      history.push("/training");
      dispatch(
        trainingActions.setModal({ show: true, targetRoute: "catalogue" })
      );
    } else {
      dispatch(trainingActions.setModal({ targetRoute: "catalogue" }));
    }
  }, [trainingEditWithoutId]);
  const location: any = useLocation();
  const notificationAlert = useRef();
  const [fromTraining, setFromTraining] = useState(
    (location.state && location.state.fromTraining) || false
  );

  useEffect(() => {
    fromTraining && location.state?.fromTraining &&
      //@ts-ignore
      notificationAlert.current.notificationAlert({
        place: "tr",
        message: (
          <div>
            <p className="label-black fw-bold">
              <MdDone
                size={30}
                color="#50BD89"
                style={{
                  width: 40,
                  height: 40,
                  backgroundColor: "#19875421",
                  padding: 10,
                  borderRadius: "50%",
                }}
                className="me-2"
              />
              {location.state.edit
                ? "Votre formation a été modifiée"
                : location.state.draft
                ? "Votre formation a été enregistrée"
                : location.state.published
                ? "Votre formation a été publiée"
                : "Votre formation a été envoyée pour validation"}
            </p>
          </div>
        ),
        type: "light",
        closeButton: false,
        autoDismiss: 7,
      }) &&
      setFromTraining(false);
  }, [fromTraining, setFromTraining, location]);
  const GLOBAL_MEDIA_QUERIES = {
    small: "(max-width: 1477px)",
    large: "(min-width: 1478px)",
  };
  const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
  return (
    <ConditionalWrapper
      condition={layoutDisplayed}
      wrapper={(children: any) => <Layout title="Consulter le catalogue">{children}</Layout>}
    >
      {/* @ts-ignore */}
      <NotificationAlert ref={notificationAlert} />
      <div className="center-container">
        <Row className="pb-4">
          <TrainingsAccordingToInterrests dispatch={dispatch} />
          <PleasingTrainings dispatch={dispatch} />
        </Row>
        <Row className="p-2 ml-2">
          <Col md="12">
            <Row>
              <p className="label-black-md mt-4 ml-4">Tout le catalogue </p>
              <AllCatalog dispatch={dispatch} scrollable={matches.small} />
            </Row>
          </Col>
        </Row>
      </div>
    </ConditionalWrapper>
  );
};

export default TrainingsCatalogContainer;
