import { useEffect, useState } from "react";
import Dashboard from "../views/dashboard/DashboardContainer";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import { selectUser } from "../redux/slices/userSlice";
import Layout from "./Layout";
import {selectTrainingPreviewDetail, trainingActions} from "../redux/slices/trainingSlice";
import { useHistory } from "react-router";

const Home = () => {
  const user = useAppSelector(selectUser);
  const [isProofreader] = useState(user.isReviewer);
  const [isActiveProofreader, setIsActiveProofreader] = useState(
    user.activeReviewer
  );
  const trainingEditWithoutId = useAppSelector(selectTrainingPreviewDetail)
  const dispatch = useAppDispatch()
  const history = useHistory();

    useEffect(()=>{
        // @ts-ignore
        if(trainingEditWithoutId.typology !== '' && !trainingEditWithoutId.trainingId )
        {
            history.push("/training");
           dispatch(trainingActions.setModal({show:true,targetRoute:"home"}));
        }
        else{
            dispatch(trainingActions.setModal({targetRoute: "home"}));
        }
    },[trainingEditWithoutId])

  return (
    <Layout
      title="Mon tableau de bord"
      isActiveProofreader={isActiveProofreader}
      setIsActiveProofreader={setIsActiveProofreader}
      isProofreader={isProofreader}
    >
      <Dashboard
        isActiveProofreader={isActiveProofreader}
        isProofreader={isProofreader}
      />
    </Layout>
  );
};

export default Home;
