import { useMedia } from "react-media";
import { Col, Row } from "reactstrap";
import ActionsByRole from "./components/ActionsByRole";
//@ts-ignore
import BadgesAndAwards from "./components/BadgesAndAwards";

const GamificationScaleContainer = (props: any) => {
    const GLOBAL_MEDIA_QUERIES = {
        small: "(max-width: 1477px)",
        large: "(min-width: 1478px)",
    };
    const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
    return (
        <div className="center-container">
            <Row className="p-2 ml-2">
                <Col md="12">
                    <Row>
                        <ActionsByRole scrollable={matches.small} />
                    </Row>
                    <Row>
                        <BadgesAndAwards scrollable={matches.small} />
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default GamificationScaleContainer;
