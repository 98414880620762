import {
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import { ReactComponent as DraftIcon } from "../../../../assets/img/icons/draft.svg";
import { ReactComponent as PendingIcon } from "../../../../assets/img/icons/pending.svg";
import { ReactComponent as PublishedIcon } from "../../../../assets/img/icons/published.svg";
import { ReactComponent as TrainingToCorrectIcon } from "../../../../assets/img/icons/toCorrect.svg";

const TrainingIcon = (props: any) => {
  const { status } = props;
  let icon;
  let bColor;
  if (status === "draft") {
    icon = (<DraftIcon />);
    bColor = "#BDBDBD"
  }
  if (status === "pending" || status === "ongoing_review") {
    icon = (<PendingIcon />);
    bColor = "#3367CD"
  }
  if (status === "Published") {
    icon = (<PublishedIcon />);
    bColor = "#50BD89"
  }
  if (status === "to_correct") {
    icon = (<TrainingToCorrectIcon />);
    bColor = "#FF66A9"
  }

  return (
    <div style={{ width: 49, height: 49 }}>
      <CircularProgressbarWithChildren
        value={0}
        background={true}
        styles={{
          root: {},
          path: {
            stroke: `rgb(80, 189, 137)`,
            strokeLinecap: "butt",
            transition: "stroke-dashoffset 0.5s ease 0s",
            strokeWidth: "3px",
          },
          trail: {
            stroke: "rgb(255,255, 255)",
            strokeLinecap: "butt",
            strokeWidth: "10px",
          },
          background: {
            fill: bColor,
            opacity: 0.2
          },
        }}
      >
        <div style={{ fontSize: 12, marginTop: -1 }}>
          {icon}
        </div>
      </CircularProgressbarWithChildren>
    </div >
  );
};

export default TrainingIcon;
