import { Col, Card, CardBody, Row } from "reactstrap";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { retrieveTrainingLaunchDetail, selectTrainingCourses } from "../../../redux/slices/trainingSlice";
import TrainingElement from "./TrainingElement";
import TrainingIcon from "./TrainingIcon";
import { useHistory } from "react-router";

const TrainingCourses = (props: any) => {
  const { isAlumuni } = props;
  const history = useHistory();
  const training = useAppSelector(selectTrainingCourses);
  const dispatch = useAppDispatch();
  return (
    <Col lg={isAlumuni ? 12: 6} md="12">
      <Card body color="light" outline className="card p-0">
        <CardBody>
          <Row className="pb-3 w-100">
            <Col md="9" sm="9" xs="9">
              <div className="label-black-nqt-xmd"> Mon parcours de formation </div>
            </Col>
            <Col md="3" sm="3" xs="3">
              <div
                onClick={() => history.push("CreatedCourses")}
                className="pull-right label-primary-nqt-sm show-more"
              >
                {" "}
                Tout voir
              </div>
            </Col>
          </Row>
          {training.map((val) => {
            return (
              <Row className="w-100 border-bottom pb-3 mt-2 pointer" key={val.id} onClick={()=>{
                dispatch(
                  retrieveTrainingLaunchDetail({ id: val.id, history })
                );
              }}>
                <TrainingElement
                  name={val.nom}
                  subject={val.sujets}
                >
                  <TrainingIcon type={"Courses"} percentage={val.pourcentage}></TrainingIcon>
                </TrainingElement>
              </Row>
            );
          })}
        </CardBody>
      </Card>
    </Col>
  );
};

export default TrainingCourses;
