import axios from "axios";
//@ts-ignore
import { AsyncStorage } from "AsyncStorage";
import { BASE_URL } from "../utils/base";

const axiosInstance = async () => {
  const instance = axios.create({
    baseURL: BASE_URL,
    headers: {
      "Content-type": "application/json",
      Authorization: "Bearer " + (await AsyncStorage.getItem("token")),
    },
  });
  instance.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {      
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error      
      if (error.response.status === 401) {
        window.onbeforeunload = null; // to prevent confirmation dialog from appearing
        window.location.href = BASE_URL + "/auth/redirect";
      }
      return Promise.reject(error);
    }
  );

  return instance;
};
export default axiosInstance;
