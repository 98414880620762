import { Field } from "formik";
import { AiOutlineSearch } from "react-icons/ai";
import { BiError } from "react-icons/bi";
import { IoMdLock } from "react-icons/io";

import "../../styles/forms.scss";

const Input = (props: any) => {
  //Init props
  const { error, readOnly, isSearch, icon } = props;

  return (
    <>
      <div className="col">
        <div className="f-flex flex-column mt-1">
          {isSearch && (
            <AiOutlineSearch
              className="formSearchIcon"
              size={17}
              color="#bdbdbd"
            ></AiOutlineSearch>
          )}
          <Field className={isSearch ? "formControl label-search" : "formControl label-black"}   {...props} />
          {(readOnly || icon) && (
            <IoMdLock className="formIcon" size={17} color="#bdbdbd" />
          )}
          {error && <BiError className="formIcon" size={17} color="#ff66a9" />}
        </div>
        {error && <label className="label-pink">{error}</label>}
      </div>
    </>
  );
};
export default Input;
