import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import {
  TrainingNeedsProofReading,
  ValidatedTrainings,
} from "../../config/training";
import EditorService from "../../services/editor.service";

import { RootState } from "../store";

export interface EditorState {
  status: "idle" | "loading" | "failed";
  proofreader: any;
  proofreaders: any;
  proofreadersDetails: any;
  training: any;
  origTraining:any;
  validatedTraining: ValidatedTrainings[];
  trainingLoading: boolean;
  validatedTrainingCount: number;
  validatedTrainingNbre: number;
  trainingNeedsProofReading: TrainingNeedsProofReading[];
  trainingNeedsProofReadingCount: number;
  trainingNeedsProofReadingNbre: number;
  alumniTrainingId: number;
  alumniTraining: ValidatedTrainings[];
  alumniTrainingCount: number;
  alumniTrainingNbre: number;
  activeLink:number;
}

const initialState: EditorState = {
  status: "idle",
  proofreader: {},
  proofreaders: [],
  proofreadersDetails: {},
  training: {},
  origTraining: {},
  validatedTraining: [],
  trainingLoading: false,
  validatedTrainingCount: 1,
  trainingNeedsProofReading: [],
  trainingNeedsProofReadingCount: 1,
  validatedTrainingNbre: 0,
  trainingNeedsProofReadingNbre: 0,
  alumniTrainingId: 1,
  alumniTraining: [],
  alumniTrainingCount: 1,
  alumniTrainingNbre: 0,
  activeLink:1,
};

// Retrieve users actions creator
export const saveProofreader = createAsyncThunk(
  "save/proofreader",
  async (proofreader: any) => {
    return proofreader;
  }
);

// Retrieve users actions creator
export const retrieveEditorProofreaders = createAsyncThunk(
  "retrieve/proofreaders",
  async (props: any) => {
    const { page, filters, formationId } = props;
    const res = formationId
      ? await EditorService.getEditorProofreaders({
          page,
          filters,
          formationId,
        })
      : await EditorService.getEditorProofreaders({ page, filters });
    return res;
  }
);
export const saveTraining = createAsyncThunk(
  "save/training",
  async (training: TrainingNeedsProofReading) => {
    const trainingData = { ...training };
    if (trainingData.reviewers.length > 0) {
      const reviewers = [...training.reviewers];
      trainingData.reviewers = reviewers.map((val) => {
        const reviewer = { ...val, isRelecteur: true };
        return reviewer;
      });
    }
    return {orig:training, trainingData};
  }
);

//assign training To User
export const assignTrainingToUser = createAsyncThunk(
  "training/assign",
  async (props: any) => {
    const { formationId, userId } = props;
    const res = await EditorService.assignTraining(formationId, userId);
    return res;
  }
);

// Retrieve Validated Training
export const retrieveValidatedTraining = createAsyncThunk(
  "training/validated",
  async (props: any) => {
    const { page, filters } = props;
    const res = await EditorService.getValidatedTraining({ page, filters });
    return res;
  }
);

// Retrieve Alumni Training
export const retrieveAlumniTraining = createAsyncThunk(
  "training/alumni",
  async (props: any) => {
    const { page, filters } = props;
    const res = await EditorService.getAlumniTraining({ page, filters });
    return res;
  }
);

// Retrieve Training Needs Proofreader
export const retrieveTrainingNeedsProofReading = createAsyncThunk(
  "training/needsProofReader",
  async (props: any) => {
    const { page, filters, userId } = props;
    const res = await EditorService.getTrainingNeedsProofReading({
      page,
      filters,
      userId,
    });
    return res;
  }
);
export const editorSlice = createSlice({
  name: "editor",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    updateTrainingReviewers(state, action) {
      const reviewers = [...current(state.training.reviewers)];
      const idx = reviewers.findIndex(
        (val: any) => val.id === action.payload.id
      );
      if (idx !== -1) {
        reviewers.splice(idx, 1);
        state.training.reviewers = reviewers;
      }
      if (idx === -1 && reviewers.length < 5) {
        reviewers.push(action.payload);
        state.training.reviewers = reviewers;
      }
    },
    resetTrainingReviewers(state) {
      const reviewers = [...current(state.training.reviewers)].filter(
        (val) => val.isRelecteur !== undefined
      );
      state.training.reviewers = reviewers;
    },
    updateDiffusion(state, action) {
      const alumniTrainings = [...current(state.alumniTraining)];
      const index = alumniTrainings.findIndex(
        (training) => training.id === state.alumniTrainingId
      );
      alumniTrainings[index] = {
        ...alumniTrainings[index],
        diffusion_for: action.payload.diffusion_for,
      };
      state.alumniTraining = alumniTrainings;
    },
    updateId(state, action) {
      state.alumniTrainingId = action.payload.id;
    },
    updateActiveLink(state, action) {
      state.activeLink = action.payload.activeLink;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  extraReducers: (builder) => {
    builder
      .addCase(
        retrieveEditorProofreaders.fulfilled,
        (state: EditorState, action: any) => {
          state.proofreaders = action.payload.data;
          state.proofreadersDetails = {
            totalCount: action.payload.totalCount,
            currentPage: action.payload.currentPage,
            totalPages: action.payload.totalPages,
            counts: action.payload.counts,
          };
        }
      )
      .addCase(saveProofreader.fulfilled, (state: EditorState, action) => {
        state.proofreader = action.payload;
      })
      .addCase(saveTraining.fulfilled, (state: EditorState, action) => {
        state.training = action.payload.trainingData;
        state.origTraining = action.payload.orig;
      })
      .addCase(retrieveValidatedTraining.pending, (state: EditorState) => {
        state.trainingLoading = true;
      })
      .addCase(
        retrieveValidatedTraining.fulfilled,
        (state: EditorState, action: any) => {
          state.validatedTraining = action.payload.data;
          state.validatedTrainingCount = action.payload.totalPages;
          state.validatedTrainingNbre = action.payload.totalCount;
          state.trainingLoading = false;
        }
      )
      .addCase(retrieveValidatedTraining.rejected, (state: EditorState) => {
        state.trainingLoading = false;
      })
      .addCase(retrieveAlumniTraining.pending, (state: EditorState) => {
        state.trainingLoading = true;
      })
      .addCase(
        retrieveAlumniTraining.fulfilled,
        (state: EditorState, action: any) => {
          state.alumniTraining = action.payload.data;
          state.alumniTrainingCount = action.payload.totalPages;
          state.alumniTrainingNbre = action.payload.totalCount;
          state.trainingLoading = false;
        }
      )
      .addCase(retrieveAlumniTraining.rejected, (state: EditorState) => {
        state.trainingLoading = false;
      })
      .addCase(
        retrieveTrainingNeedsProofReading.pending,
        (state: EditorState) => {
          state.trainingLoading = true;
        }
      )
      .addCase(
        retrieveTrainingNeedsProofReading.fulfilled,
        (state: EditorState, action: any) => {
          state.trainingNeedsProofReading = action.payload.data;
          state.trainingNeedsProofReadingCount = action.payload.totalPages;
          state.trainingNeedsProofReadingNbre = action.payload.totalCount;
          state.trainingLoading = false;
        }
      )
      .addCase(
        retrieveTrainingNeedsProofReading.rejected,
        (state: EditorState) => {
          state.trainingLoading = false;
        }
      );
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
export const selectProofreader = (state: RootState) => state.editor.proofreader;
export const selectProofreaders = (state: RootState) =>
  state.editor.proofreaders;
export const selectProofreadersDetails = (state: RootState) =>
  state.editor.proofreadersDetails;
export const selectTraining = (state: RootState) => state.editor.training;
export const selectTrainingLoading = (state: RootState) =>
  state.editor.trainingLoading;
export const selectValidatedTraining = (state: RootState) =>
  state.editor.validatedTraining;
export const selectValidatedTrainingNbre = (state: RootState) =>
  state.editor.validatedTrainingNbre;
export const selectValidatedTrainingCount = (state: RootState) =>
  state.editor.validatedTrainingCount;
export const selectTrainingNeedsProofReading = (state: RootState) =>
  state.editor.trainingNeedsProofReading;
export const selectTrainingNeedsProofReadingCount = (state: RootState) =>
  state.editor.trainingNeedsProofReadingCount;
export const selectTrainingNeedsProofReadingNbre = (state: RootState) =>
  state.editor.trainingNeedsProofReadingNbre;
export const selectAlumniTraining = (state: RootState) =>
  state.editor.alumniTraining;
export const selectAlumniTrainingNbre = (state: RootState) =>
  state.editor.alumniTrainingNbre;
export const selectAlumniTrainingCount = (state: RootState) =>
  state.editor.alumniTrainingCount;
export const selectAlumniTrainingId = (state: RootState) =>
  state.editor.alumniTrainingId;
export const selectOrigTraining = (state: RootState) => state.editor.origTraining;
export const selectActiveLink = (state: RootState) => state.editor.activeLink;
export const editorActions = editorSlice.actions;
export default editorSlice.reducer;
