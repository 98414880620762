import { Card, Col, Row } from "reactstrap";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {useState, useEffect, Fragment} from "react";
import { CircularProgress } from "@mui/material";
import useHttp from "../../../../hooks/use-http";
import TrainingService from "../../../../services/training.services";
interface MentorOptionType {
  user: {
    firstname: string;
    lastname: string;
    id: number;
    company: {
      id: string;
      name: string;
    };
  };
}

const AssignToMentor = (props: any) => {
  const { user, values, setValues, setFieldValue } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [enteredText, setEnteredText] = useState("");
  const [selectedMentorName, setSelectedMentorName] =
    useState<MentorOptionType | null>(values.mentor ? {user: values.mentor} : null);

  const [mentors, setMentors] = useState<any[]>([]);

  const {
    sendRequest: retrieveAllMentors,
    data: mentorsData,
    status,
  } = useHttp(TrainingService.getMentorsData);

  useEffect(() => {
    retrieveAllMentors();
  }, [retrieveAllMentors]);

  useEffect(() => {
    if (mentorsData?.data) {
      if (mentorsData.meta.current_page > 1)
        setMentors((prev) => [...prev, ...mentorsData.data]);
      else setMentors(mentorsData.data);
    }
  }, [mentorsData]);

  useEffect(() => {
    setFieldValue(
      "mentor_id",
      selectedMentorName?.user?.id ? selectedMentorName.user.id : null
    );
    setFieldValue(
      "company_name",
      selectedMentorName?.user?.company?.name
        ? selectedMentorName?.user.company.name
        : ""
    );
  }, [selectedMentorName, setFieldValue]);

  useEffect(() => {
    setIsLoading(status === "pending");
  }, [status]);

  const defaultProps = {
    options: mentors,
    getOptionLabel: (option: MentorOptionType) =>
      `${option.user.firstname} ${option.user.lastname}`,
  };

  return (
    <Row className="center-container">
      <Col md="9">
        <Card body color="light" outline className="cardIntro mt-5 p-3 mb-2">
          <div className="d-flex flex-row align-items-center">
            <div className="d-flex flex-column w-100 ">
              <p className="label-black m-2">Assigner à un mentor</p>
              <Autocomplete
                loading={isLoading}
                {...defaultProps}
                noOptionsText="Liste vide"
                loadingText="Chargement..."
                value={selectedMentorName}
                filterOptions={(options, state) => {
                  if (state.inputValue.length > 2) {
                    return options.filter((item) =>
                      (item.user.firstname + " " + item.user.lastname)
                        .toLowerCase()
                        .includes(state.inputValue.toLowerCase())
                    );
                  }
                  return options;
                }}
                onChange={(event: any, newValue: MentorOptionType | null) => {
                  setSelectedMentorName(newValue);

                  let trainingValues: any = { ...values };
                  trainingValues.mentor = newValue;
                  setValues(trainingValues);
                }}
                size="small"
                getOptionLabel={(option) => `${option.user.firstname} ${option.user.lastname}`}

                    // renderOption={(props, option) => (
                    //     <li key={String(option.user.id)} {...props}>
                    //     <p className="label-black">{`${option.user.firstname} ${option.user.lastname}`}</p>
                    //   </li>
                    // )}
                    // renderOption={(props, option) => {
                    //   const sanitizedHTML = DOMPurify.sanitize(
                    //       `${option.user.firstname} ${option.user.lastname}`);
                    //   const innerHTML = { __html: sanitizedHTML };
                    //   return (
                    //       <li key={option.user.id.toString()} {...props} >
                    //         <p className="label-black" dangerouslySetInnerHTML={{__html: innerHTML.__html }} ></p>
                    //       </li>
                    //   );
                    // }}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    label="Choisir un mentor"
                    variant="standard"
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                      className: "formControl",
                      style: {
                        paddingTop: 0,
                        fontSize: 12,
                        fontFamily: "CircularStdBook",
                        color: "#2c2c2c",
                        fontWeight: "normal !important",
                      },
                      endAdornment: (
                        <>
                          {isLoading ? (
                            <CircularProgress
                              color="inherit"
                              size={20}
                              style={{ color: "#3367cd", marginRight: 15 }}
                            />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                    InputLabelProps={{
                      ...params.InputLabelProps,
                      style: {
                        color: "#28282a",
                        zIndex: 10,
                        fontSize: 12,
                        margin: 12,
                        fontFamily: "CircularStdBook",
                      },
                    }}
                    onChange={(e) => {
                      setEnteredText(e.target.value);
                      e.target.value.length > 2 &&
                        retrieveAllMentors({
                          page: 1,
                          search: e.target.value,
                        });
                    }}
                  />
                )}
                ListboxProps={{
                  onScroll: (event: React.SyntheticEvent) => {
                    const listboxNode = event.currentTarget;
                    if (
                      listboxNode.scrollTop + listboxNode.clientHeight >=
                        listboxNode.scrollHeight - 200 &&
                        mentorsData?.meta?.last_page >
                        mentorsData?.meta?.current_page &&
                      !isLoading
                    )
                      retrieveAllMentors({
                        page: mentorsData?.meta.current_page + 1,
                        search: enteredText,
                      });
                  },
                }}
              />

              <p
                className="label-primary-nqt-sm pointer mt-3"
                onClick={() => {
                  setSelectedMentorName({
                    user: {
                      id: user.appNqtId,
                      firstname: `${user.nom}`,
                      lastname: `${user.prenom}`,
                      company: {
                        id: `${user?.expertisesMetier.entrepriseId}`,
                        name: `${user?.expertisesMetier.entreprise}`,
                      },
                    },
                  });

                  let trainingValues: any = { ...values };
                  trainingValues.mentor = {
                    user: {
                      id: user.appNqtId,
                      firstname: `${user.nom}`,
                      lastname: `${user.prenom}`,
                      company: {
                        id: `${user?.expertisesMetier.entreprise}`,
                        name: `${user?.expertisesMetier.entrepriseId}`,
                      },
                    },
                  };
                  setValues(trainingValues);
                }}
              >
                <u>Me l'assigner</u>
              </p>
            </div>
          </div>
        </Card>
      </Col>
    </Row>
  );
};
export default AssignToMentor;
