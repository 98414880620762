import { ReactComponent as AsyncIcon } from "../../../../assets/img/icons/asyncIcon.svg";
import { ReactComponent as DistIcon } from "../../../../assets/img/icons/distIcon.svg";
import { ReactComponent as PresIcon } from "../../../../assets/img/icons/presIcon.svg";


const SuggestionIcon = (props: any) => {
    const { state = "" } = props;
    let icon = (<AsyncIcon className="center-svg" />);
    if (state === "pres") {
        icon = (<PresIcon className="center-svg" />);
    }
    if (state === "dist") {
        icon = (<DistIcon className="center-svg" />);
    }
    if (state === "async") {
        icon = (<AsyncIcon className="center-svg" />);
    }
    return (
        <div>
            {icon}
        </div>
    )
};

export default SuggestionIcon;
