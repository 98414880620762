export interface Gamification {
  id: number;
  role: string;
  nbActions: number;
  nbMissingActions: number;
  nbNeededActions: number;
  accumulatedPoints: number;
  percentage: number;
  trophyType: string;
  badgeLevel: number;
  color: string;
}

export interface GamificationAdmin {
    actions: GamificationActions[];
    rewards: {
        0: GamificationRewards
        5: GamificationRewards
        10: GamificationRewards
        15: GamificationRewards
        20: GamificationRewards
        1: GamificationRewards
        6: GamificationRewards
        11: GamificationRewards
        16: GamificationRewards
        21: GamificationRewards
        2: GamificationRewards
        7: GamificationRewards
        12: GamificationRewards
        17: GamificationRewards
        22: GamificationRewards
        3: GamificationRewards
        8: GamificationRewards
        13: GamificationRewards
        18: GamificationRewards
        23: GamificationRewards
        4: GamificationRewards
        9: GamificationRewards
        14: GamificationRewards
        19: GamificationRewards
        24: GamificationRewards
    }
}

export interface GamificationRewards {
    id: number;
    function: string;
    reward_type: string;
    nb_points: number;
    equivalence: string;
}

export interface GamificationActions {
    id: number;
    function: string;
    function_description: string;
    nb_points: number;
}

export const GamificationData: Gamification[] = [
    {
        id: 1,
        role: "proofreader",
        nbActions: 0,
        nbMissingActions: 5,
        nbNeededActions: 10,
        accumulatedPoints: 2365,
        percentage: 0,
        trophyType: "",
        badgeLevel: 0,
        color: "#50BD89"
    },
    {
        id: 1,
        role: "contributor",
        nbActions: 6,
        nbMissingActions: 3,
        nbNeededActions: 10,
        accumulatedPoints: 3000,
        percentage: 60,
        trophyType: "silver",
        badgeLevel: 0,
        color: "#FF66A9"
    },
    {
        id: 1,
        role: "animator",
        nbActions: 1,
        nbMissingActions: 11,
        nbNeededActions: 10,
        accumulatedPoints: 3000,
        percentage: 30,
        trophyType: "badge",
        badgeLevel: 1,
        color: "#3367CD"
    },
    {
        id: 1,
        role: "creator",
        nbActions: 1,
        nbMissingActions: 11,
        nbNeededActions: 10,
        accumulatedPoints: 3000,
        percentage: 70,
        trophyType: "badge",
        badgeLevel: 2,
        color: "#ED984F"
    },
    {
        id: 1,
        role: "learner",
        nbActions: 2,
        nbMissingActions: 11,
        nbNeededActions: 10,
        accumulatedPoints: 3000,
        percentage: 90,
        trophyType: "badge",
        badgeLevel: 3,
        color: "#83A6FF"
    }
]

export const GamificationAdminData: GamificationAdmin[] = [
    {
        "actions": [
            {
                "id": 1,
                "function": "Relecteur",
                "function_description": "relire une formation",
                "nb_points": 100
            },
            {
                "id": 2,
                "function": "Contributeur",
                "function_description": "publier un post",
                "nb_points": 50
            },
            {
                "id": 3,
                "function": "Contributeur",
                "function_description": "republier du contenu sur les RS",
                "nb_points": 50
            },
            {
                "id": 4,
                "function": "Createur",
                "function_description": "publier une formation",
                "nb_points": 500
            },
            {
                "id": 5,
                "function": "Animateur",
                "function_description": "animer une formation synchrone",
                "nb_points": 500
            },
            {
                "id": 6,
                "function": "Learner",
                "function_description": "terminer une formation asynchrone avec succ\u00e8s",
                "nb_points": 500
            },
            {
                "id": 7,
                "function": "Learner",
                "function_description": "valider ma pr\u00e9sence pour une formation synchrone",
                "nb_points": 500
            }
        ],
        "rewards": {
            "0": {
                "id": 1,
                "function": "Relecteur",
                "reward_type": "Troph\u00e9e Bronze",
                "nb_points": 400,
                "equivalence": "1"
            },
            "5": {
                "id": 6,
                "function": "Relecteur",
                "reward_type": "Troph\u00e9e Argent",
                "nb_points": 2000,
                "equivalence": "5"
            },
            "10": {
                "id": 11,
                "function": "Relecteur",
                "reward_type": "Troph\u00e9e or",
                "nb_points": 4000,
                "equivalence": "10"
            },
            "15": {
                "id": 16,
                "function": "Relecteur",
                "reward_type": "Badge NQT",
                "nb_points": 8000,
                "equivalence": "20"
            },
            "20": {
                "id": 21,
                "function": "Relecteur",
                "reward_type": "Badge NQT Niv1",
                "nb_points": 16000,
                "equivalence": "40"
            },
            "1": {
                "id": 2,
                "function": "Contributeur",
                "reward_type": "Troph\u00e9e Bronze",
                "nb_points": 500,
                "equivalence": "10"
            },
            "6": {
                "id": 7,
                "function": "Contributeur",
                "reward_type": "Troph\u00e9e Argent",
                "nb_points": 2500,
                "equivalence": "50"
            },
            "11": {
                "id": 12,
                "function": "Contributeur",
                "reward_type": "Troph\u00e9e or",
                "nb_points": 5000,
                "equivalence": "100"
            },
            "16": {
                "id": 17,
                "function": "Contributeur",
                "reward_type": "Badge NQT",
                "nb_points": 10000,
                "equivalence": "200"
            },
            "21": {
                "id": 22,
                "function": "Contributeur",
                "reward_type": "Badge NQT Niv1",
                "nb_points": 20000,
                "equivalence": "400"
            },
            "2": {
                "id": 3,
                "function": "Createur",
                "reward_type": "Troph\u00e9e Bronze",
                "nb_points": 500,
                "equivalence": "1"
            },
            "7": {
                "id": 8,
                "function": "Createur",
                "reward_type": "Troph\u00e9e Argent",
                "nb_points": 2500,
                "equivalence": "5"
            },
            "12": {
                "id": 13,
                "function": "Createur",
                "reward_type": "Troph\u00e9e or",
                "nb_points": 5000,
                "equivalence": "10"
            },
            "17": {
                "id": 18,
                "function": "Createur",
                "reward_type": "Badge NQT",
                "nb_points": 10000,
                "equivalence": "20"
            },
            "22": {
                "id": 23,
                "function": "Createur",
                "reward_type": "Badge NQT Niv1",
                "nb_points": 20000,
                "equivalence": "40"
            },
            "3": {
                "id": 4,
                "function": "Animateur",
                "reward_type": "Troph\u00e9e Bronze",
                "nb_points": 500,
                "equivalence": "1"
            },
            "8": {
                "id": 9,
                "function": "Animateur",
                "reward_type": "Troph\u00e9e Argent",
                "nb_points": 2500,
                "equivalence": "5"
            },
            "13": {
                "id": 14,
                "function": "Animateur",
                "reward_type": "Troph\u00e9e or",
                "nb_points": 5000,
                "equivalence": "10"
            },
            "18": {
                "id": 19,
                "function": "Animateur",
                "reward_type": "Badge NQT",
                "nb_points": 10000,
                "equivalence": "20"
            },
            "23": {
                "id": 24,
                "function": "Animateur",
                "reward_type": "Badge NQT Niv1",
                "nb_points": 20000,
                "equivalence": "40"
            },
            "4": {
                "id": 5,
                "function": "Learner",
                "reward_type": "Troph\u00e9e Bronze",
                "nb_points": 1000,
                "equivalence": "2"
            },
            "9": {
                "id": 10,
                "function": "Learner",
                "reward_type": "Troph\u00e9e Argent",
                "nb_points": 5000,
                "equivalence": "10"
            },
            "14": {
                "id": 15,
                "function": "Learner",
                "reward_type": "Troph\u00e9e or",
                "nb_points": 10000,
                "equivalence": "20"
            },
            "19": {
                "id": 20,
                "function": "Learner",
                "reward_type": "Badge NQT",
                "nb_points": 20000,
                "equivalence": "40"
            },
            "24": {
                "id": 25,
                "function": "Learner",
                "reward_type": "Badge NQT Niv1",
                "nb_points": 40000,
                "equivalence": "80"
            }
        }
    }
]
