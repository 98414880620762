import { Row, Col, Card, CardBody, Form, Button } from "reactstrap";
import { useState } from "react";
import { Checkbox } from "../../../../components/forms";
import { Formik } from "formik";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { Scrollbars } from "react-custom-scrollbars-2";
import { BASE_URI } from "../../../../utils/base";

function ThirdStep(props: any) {
  const { handlePagination } = props;
  var cardStyle = {
    //height: "30vw",

    height: "400px",
  };

  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  };

  return (
    <Formik
      initialValues={{
        check: false,
      }}
      onSubmit={(values, actions) => {
        actions.setSubmitting(false);
      }}
    >
      {({ values, errors, setValues }) => (
        <Form>
          <Row className="center-container">
            <Col md="9">
              <p className="first-step-title">
                Signature de{" "}
                <span className="title-primary p-0">la charte NQT.</span>
              </p>
            </Col>
          </Row>
          <Row className="center-container">
            <Col md="9">
              <Card
                style={cardStyle}
                body
                color="light"
                outline
                className="card p-0"
              >
                <CardBody className="p-0">
                  <Scrollbars
                    renderThumbVertical={(props) => (
                      <div {...props} className="thumb-vertical" />
                    )}
                    renderTrackVertical={(props) => (
                      <div {...props} className="track-vertical" />
                    )}
                  >
                    <Document
                        file={"/charteEditoriale.pdf"}
                      onLoadSuccess={onDocumentLoadSuccess}
                    >
                      {Array.from(new Array(numPages), (el, index) => (
                        <Page
                          width={800}
                          key={`page_${index + 1}`}
                          pageNumber={index + 1}
                        />
                      ))}
                    </Document>
                  </Scrollbars>
                </CardBody>
                <Row className="mt-3 p-2">
                  <Checkbox
                    label="J'ai lu et j'accepte les conditions de la charte du Club NQT."
                    name="check"
                  ></Checkbox>
                </Row>
              </Card>
              <Row style={{ textAlign: "right" }}>
                <Col className="pl-0" md="12">
                  <Button
                    disabled={!values.check}
                    className="btn-round-finish mt-5 width-submit"
                    uppercase={false}
                    onClick={() => handlePagination(4)}
                    color="primary"
                    type="submit"
                  >
                    Terminer
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
}

export default ThirdStep;
