import { useEffect, useState } from "react";
import { useMedia } from "react-media";
import Sidebar from "./components/Sidebar";
import FirstStep from "./components/FirstStep";
import ThirdStep from "./components/ThirdStep";
import SecondStep from "./components/secondStep/SecondStep";
import {
  selectUser,
  updateRegisterStep,
} from "../../../redux/slices/userSlice";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import Stepbar from "./components/StepBar";
import Welcome from "../../../components/welcome";
import { useLocation } from "react-router";

const RegisterContainer = () => {
  //Init states from selector
  const user: any = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const GLOBAL_MEDIA_QUERIES = {
    small: "(max-width: 991px)",
    large: "(min-width: 992px)",
  };
  const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
  //Init states
  const [navStep, setNavStep] = useState(user && user.step);
  const location: any = useLocation();

  const handlePagination = (step: number) => {
    if (
      user.step !== step &&
      step > user.step &&
      step !== 4 && (user.step === 1 || user.inscriptionStatus !== "NotSubscribed")
    ) {
      dispatch(updateRegisterStep({ step }));
    }
    if (
      user.inscriptionStatus === "SubscriptionInProgress" ||
      (step === 4 && user.inscriptionStatus !== "NotSubscribed")
    ) {
      setNavStep(step);
    }
  };
  useEffect(() => {
    setNavStep(user.step);
  }, [user.step]);
  useEffect(() => {
    location &&
      location.state &&
      location.state.tag &&
      location.state.tag === "reset" &&
      setNavStep(1);
  }, [location]);

  return navStep === 4 ? <Welcome /> :
   (
    <div className="wrapper">
      {matches.large ? (
        <Sidebar
          bgColor={"white"}
          activeColor={"black"}
          handlePagination={handlePagination}
          navStep={navStep}
        />
      ) : (
        <Stepbar handlePagination={handlePagination} navStep={navStep} />
      )}
      <div className="main-panel">
        <div className="content">
          {navStep === 1 && <FirstStep handlePagination={handlePagination} />}
          {navStep === 2 && <SecondStep handlePagination={handlePagination} />}
          {navStep === 3 && <ThirdStep handlePagination={handlePagination} />}
        </div>
      </div>
    </div>
  );
};

export default RegisterContainer;
