import ProgressBar from "@ramonak/react-progress-bar";
import { ReactComponent as DoneIcon } from "../assets/img/icons/donePopup.svg";
import { ReactComponent as DoneIconPrimary } from "../assets/img/icons/donePopupPrimary.svg";
import { ReactComponent as HeadBandIcon } from "../assets/img/icons/badge.svg";

import "../styles/popup.scss";

const Gamification = (props: any) => {
  const { data } = props;
  if (!data) {
    return <div className="d-flex flex-row"></div>;
  }
  return (
    <div className="d-flex flex-row">
      <div className="w-25 action-icon d-flex align-items-center justify-content-center">
        {data.role === "Createur" ? (
          <img
            alt="..."
            src={
              require("../assets/img/modalImages/publishTraining.png").default
            }
          />
        ) : data.role === "Relecteur" ? (
          <img
            alt="..."
            src={require("../assets/img/modalImages/rateTraining.png").default}
          />
        ) : data.role === "Contributeur" ? (
          <img
            alt="..."
            src={require("../assets/img/modalImages/publishPost.png").default}
          />
        ) : data.role === "Animateur" ? (
          <img
            alt="..."
            src={require("../assets/img/modalImages/animTraining.png").default}
          />
        ) : (
          <img
            alt="..."
            src={require("../assets/img/modalImages/learner.png").default}
          />
        )}
      </div>
      <div className="ms-4 w-75 d-flex flex-column justify-content-center">
        {data.trophyType === "Badge" && (
          <div className="d-flex align-self-end">
            <HeadBandIcon
              style={{
                marginTop: -70,
                marginRight: data.badgeLevel > 0 ? -80 : -25,
                zIndex: 1,
              }}
            />
            <p
              className="label-white-sm fw-normal"
              style={{
                marginTop: -43,
                zIndex: 2,
                transform: "rotate(-17deg)",
              }}
            >
              {data.badgeLevel > 0 && "niveau " + data.badgeLevel}
            </p>
          </div>
        )}

        {Number(data.pourcentage) < 100 ? (
          <div className="outlined-green d-flex flex-row px-2 py-1">
            <DoneIcon />
            <p className="label-green ms-1 mt-1">
              {"+1" +
                (data.role === "Createur"
                  ? " formation publiée"
                  : data.role === "Relecteur"
                  ? " formation relue"
                  : data.role === "Learner"
                  ? " formation validé"
                  : " post publié")}
            </p>
          </div>
        ) : data.trophyType === "Badge" ? (
          <div className="outlined-primary-popup d-flex flex-row px-2 py-1">
            <DoneIconPrimary />
            <p className="label-primary-nqt-sm ms-1 mt-1">
              +1 formation terminée
            </p>
          </div>
        ) : (
          <div className="outlined-primary-popup d-flex flex-row px-2 py-1">
            <div className="outlined-trophy-md" style={{ marginTop: -7 }}>
              <img
                alt="..."
                src={
                  require("../assets/img/modalImages/" +
                    (data.trophyType
                      ? data.trophyType.toLowerCase()
                      : "bronze") +
                    ".png").default
                }
              />
            </div>
            <p className="label-primary-nqt-sm ms-1 mt-1">
              +1 Trophée
              {data.trophyType === "Bronze"
                ? " bronze "
                : data.trophyType === "Silver"
                ? " argent "
                : " doré "}
              débloqué
            </p>
          </div>
        )}
        <div className="w-100 d-flex flex-column justify-content-center mt-4">
          {data.role === "Createur" ? (
            <p className="label-black-nqt-md mb-0 pb-0">
              <span className="label-primary-nqt-lg">Bravo ! </span>Votre
              formation a été validée pour publication ! 🎉
            </p>
          ) : data.role === "Relecteur" ? (
            <p className="label-black-nqt-md mb-0 pb-0">
              <span className="label-primary-nqt-lg">Bravo ! </span>Vous venez
              de noter une nouvelle formation 🎉
            </p>
          ) : data.role === "Contributeur" ? (
            <p className="label-black-nqt-md mb-0 pb-0">
              <span className="label-primary-nqt-lg">Bravo ! </span>
              Nouveau post publié sur le réseau NQT 🎉
            </p>
          ) : data.role === "Animateur" ? (
            <p className="label-black-nqt-md mb-0 pb-0">
              <span className="label-primary-nqt-lg">Bravo ! </span>Vous venez
              d’animer une nouvelle formation 🎉
            </p>
          ) : (
            <p className="label-black-nqt-md mb-0 pb-0">
              <span className="label-primary-nqt-lg">Bravo ! </span>Vous avez
              validé votre formation 🎉
            </p>
          )}
          {data.trophyType !== "Badge" ? (
            <>
              <div className="d-flex flex-row align-items-center">
                <ProgressBar
                  completed={data.pourcentage}
                  bgColor={
                    Number(data.pourcentage) < 100
                      ? data.role === "Createur"
                        ? "linear-gradient(to right, rgba(51, 103, 205, 0.12) , rgba(51, 103, 205, 1))"
                        : data.role === "Animateur"
                        ? "linear-gradient(to right, rgba(237, 152, 79, 0.12) , rgba(237, 152, 79, 1))"
                        : data.role === "Relecteur"
                        ? "linear-gradient(to right, rgba(80, 189, 137, 0.12) , rgba(80, 189, 137, 1))"
                        : data.role === "Contributeur"
                        ? "linear-gradient(to right, rgba(255, 102, 169, 0.12), rgba(255, 102, 169, 1))"
                        : "linear-gradient(to right, rgba(131, 166, 255, 0.12) , rgba(131, 166, 255, 1))"
                      : "linear-gradient(90deg, rgba(242, 201, 76, 0.12) -17.4%, #F2C94C 99.9%)"
                  }
                  height="6px"
                  className="wrapper-progress-bar p-0"
                  barContainerClassName="container-progress-bar p-0"
                  isLabelVisible={false}
                />
                <div className="outlined-trophy">
                  <img
                    alt="..."
                    src={
                      require("../assets/img/modalImages/" +
                        (Number(data.pourcentage) < 100
                          ? data.trophyType === ""
                            ? "bronze"
                            : data.trophyType === "Bronze"
                            ? "silver"
                            : "gold"
                          : data.trophyType.toLowerCase()) +
                        ".png").default
                    }
                    style={{
                      opacity: Number(data.pourcentage) < 100 ? 0.4 : 1,
                    }}
                  />
                </div>
              </div>
              <div className="d-flex flex-row w-75">
                <p className="label-primary-md w-75 m-0 p-0">
                  {data.nbActions}{" "}
                  {data.role === "Relecteur"
                    ? " formations relues "
                    : data.role === "Contributeur"
                    ? " actions sur des posts "
                    : data.role === "Animateur"
                    ? " formations animées "
                    : data.role === "Createur"
                    ? " formations publiées "
                    : " formations terminées "}
                </p>
                <div className="w-25">
                  <p className="label-black">
                    {data.nbMissingActions} manquantes
                  </p>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="d-flex flex-row">
                <div className="pe-0">
                  <p
                    className={
                      data.role === "Relecteur"
                        ? "label-green-proofreader-lg mb-0 mt-4 ml-1 pe-0"
                        : data.role === "Contributeur"
                        ? "label-pink-contributor-lg mb-0 mt-4 ml-1 pe-0"
                        : data.role === "Animateur"
                        ? "label-orange-animator-lg mb-0 mt-4 ml-1 pe-0"
                        : data.role === "Createur"
                        ? "label-blue-creator-lg mb-0 mt-4 ml-1 pe-0"
                        : "label-light-blue-learner-lg mb-0 mt-4 ml-1 pe-0"
                    }
                  >
                    {data.accumulatedPoints}
                  </p>
                </div>
                <div className="ms-2">
                  <p
                    style={{ textAlign: "left", marginLeft: 0 }}
                    className={
                      data.role === "Relecteur"
                        ? "label-green-proofreader-sm mt-4 mb-0"
                        : data.role === "Contributeur"
                        ? "label-pink-contributor-sm mt-4 mb-0"
                        : data.role === "Animateur"
                        ? "label-orange-animator-sm mt-4 mb-0"
                        : data.role === "Createur"
                        ? "label-blue-creator-sm mt-4 mb-0"
                        : "label-light-blue-learner-sm mt-4 mb-0"
                    }
                  >
                    Points <br />
                    {data.role === "Relecteur"
                      ? " relecteur"
                      : data.role === "Contributeur"
                      ? " contributeur"
                      : data.role === "Animateur"
                      ? " animateur"
                      : data.role === "Createur"
                      ? " créateur"
                      : "learner"}
                    .
                  </p>
                </div>
              </div>
              <div className="d-flex flex-row">
                <div
                  style={{ textAlign: "left" }}
                  className={
                    data.role === "Relecteur"
                      ? "label-green-proofreader-sm mt-2 ml-0 pl-0"
                      : data.role === "Contributeur"
                      ? "label-pink-contributor-sm mt-0 ml-0 pl-0"
                      : data.role === "Animateur"
                      ? "label-orange-animator-sm mt-0 ml-0 pl-0"
                      : data.role === "Createur"
                      ? "label-blue-creator-sm mt-0 ml-0 pl-0"
                      : "label-light-blue-learner-sm mt-0 ml-0 pl-0"
                  }
                >
                  {data.nbActions}
                  {data.role === "Relecteur"
                    ? " formations relues "
                    : data.role === "Contributeur"
                    ? " actions sur des posts "
                    : data.role === "Animateur"
                    ? " formations animées "
                    : data.role === "Createur"
                    ? " formations publiées "
                    : " formations terminées "}
                  / {data.nbMissingActions}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Gamification;
