import moment from "moment";
import { Col, Row } from "reactstrap";
import Nav from "react-bootstrap/Nav";
import { useMedia } from "react-media";
import { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
//@ts-ignore
import EllipsisText from "react-ellipsis-text";
import { ReactComponent as AvatarIcon } from "../../../assets/img/icons/avatar-person.svg";
import ValidatedTraining from "./components/ValidatedTraining";
import "../../../styles/editor.scss";
import { ReactComponent as MemberIcon } from "../../../assets/img/icons/member.svg";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  editorActions,
  retrieveAlumniTraining,
  retrieveTrainingNeedsProofReading,
  retrieveValidatedTraining,
  selectAlumniTrainingNbre,
  selectProofreader,
  selectTraining,
  selectTrainingNeedsProofReadingNbre,
  selectValidatedTrainingNbre,
} from "../../../redux/slices/editorSlice";
import EditorTrainingsContainer from "../editorTrainings/EditorTrainingsContainer";
import { TrainingNeedsProofReading } from "../../../config/training";
import AllProofreaders from "../proofreadersListing/components/AllProofreaders";
import TrainingNeedsProofreader from "./components/TrainingNeedsProofreader";
import {
  retrieveAllNonPublishedSyncTrainings,
  retrieveAllNonPublishedTrainings,
  retrieveAllPublishedTrainings,
  retrieveAllUnpublishedTrainings,
  selectAllNonPublishedSyncTrainingsNbre,
  selectAllNonPublishedTrainingsDataCount,
  selectAllPublishedTrainingsDataCount,
  selectAllUnpublishedTrainingsDataCount,
} from "../../../redux/slices/trainingSlice";

interface Links {
  id: number;
  name: string;
}

const DashboardEditor = (props: any) => {
  const { tag, route } = props;
  const GLOBAL_MEDIA_QUERIES = {
    small: "(max-width: 991px)",
    large: "(min-width: 992px)",
  };
  const dispatch = useAppDispatch();
  const proofreader = useAppSelector(selectProofreader);
  const allNonPublishedTrainingsDataCount = useAppSelector(
    selectAllNonPublishedTrainingsDataCount
  );
  const allPublishedTrainingsDataCount = useAppSelector(
    selectAllPublishedTrainingsDataCount
  );
  const allUnublishedTrainingsDataCount = useAppSelector(
    selectAllUnpublishedTrainingsDataCount
  );
  const trainingNeedsProofReadingCount = useAppSelector(
    selectTrainingNeedsProofReadingNbre
  );
  const validatedTrainingCount = useAppSelector(selectValidatedTrainingNbre);
  const alumniTrainingCount = useAppSelector(selectAlumniTrainingNbre);
  const nonPublishedSyncTrainingsCount = useAppSelector(
    selectAllNonPublishedSyncTrainingsNbre
  );
  useEffect(() => {
    if (tag === "list") {
      setActiveLink(0);
    }
  }, [tag]);

  //Effects
  useEffect(() => {
    dispatch(
      retrieveTrainingNeedsProofReading({
        page: 1,
        filters: {
          nom: "",
          sujets: [],
          createurs: [],
          types: [],
        },
        userId: tag === "list" ? null : proofreader.id,
      })
    );
  }, [tag, proofreader, dispatch]);

  useEffect(() => {
    dispatch(
      retrieveValidatedTraining({
        page: 1,
        filters: {
          nom: "",
          sujets: [],
          createurs: [],
          types: [],
        },
      })
    );
    dispatch(
      retrieveAlumniTraining({
        page: 1,
        filters: {
          nom: "",
          sujets: [],
          createurs: [],
          types: [],
        },
      })
    );
  }, [dispatch]);
  useEffect(() => {
    dispatch(
      retrieveAllPublishedTrainings({
        page: 1,
        filters: {
          sujets: [],
          createurs: [],
          contenu: { type: [], typeContenu: [] },
          datePub: { start: "", end: "" },
          dateTenu: { start: "", end: "" },
        },
      })
    );
    dispatch(
      retrieveAllUnpublishedTrainings({
        page: 1,
        filters: {
          sujets: [],
          createurs: [],
          contenu: { type: [], typeContenu: [] },
          datePub: { start: "", end: "" },
          dateTenu: { start: "", end: "" },
        },
      })
    );
    dispatch(
      retrieveAllNonPublishedTrainings({
        page: 1,
        filters: {
          sujets: [],
          createurs: [],
          contenu: { type: [], typeContenu: [] },
          datePub: { start: "", end: "" },
          dateTenu: { start: "", end: "" },
        },
      })
    );
    dispatch(
      retrieveAllNonPublishedSyncTrainings({
        page: 1,
        filters: {
          sujets: [],
          createurs: [],
          datePub: { start: "", end: "" },
          dateTenu: { start: "", end: "" },
        },
      })
    );
  }, [dispatch]);
  let text = "";
  proofreader &&
    proofreader.subjects &&
    proofreader.subjects.map(
      (subject: any) =>
        (text = text !== "" ? text + ", " + subject.label : subject.label)
    );

  const training: TrainingNeedsProofReading = useAppSelector(selectTraining);

  const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });

  const NavLinks: Links[] = [
    {
      id: 0,
      name: "Validées par les relecteurs",
    },
    {
      id: 1,
      name: "Relecteurs manquants",
    },
    {
      id: 2,
      name: "Formations publiées",
    },
    {
      id: 3,
      name: "Formations non publiées",
    },
    {
      id: 4,
      name: "Formation alumni",
    },
    {
      id: 5,
      name: "Formation synchrones à publier",
    },
    {
      id: 6,
      name: "Formations dé-publiées",
    },
  ];
  const [activeLink, setActiveLink] = useState(tag === "list" ? 0 : 1);
  const navElement = NavLinks.map((val: Links) => {
    return (
      <Nav.Link
        key={val.name}
        eventKey={val.id.toString()}
        className="navLink d-flex flex-row pb-4"
      >
        <div className="mt-1">{val.name}</div>
        <div
          className="linkCount"
          style={activeLink === val.id ? { background: "#ECF0FB" } : {}}
        >
          {val.id === 0 && validatedTrainingCount}
          {val.id === 1 && trainingNeedsProofReadingCount}
          {val.id === 2 && allPublishedTrainingsDataCount}
          {val.id === 3 && allNonPublishedTrainingsDataCount}
          {val.id === 4 && alumniTrainingCount}
          {val.id === 5 && nonPublishedSyncTrainingsCount}
          {val.id === 6 && allUnublishedTrainingsDataCount}
        </div>
      </Nav.Link>
    );
  });

  const getNotes = (notes: any) => {
    let text = "";
    notes.map(
      (note: string) => (text = text !== "" ? text + ", " + note : note)
    );
    return text;
  };
  const getRelectures = (relectures: any) => {
    const elements = relectures.map((relecteur: any, idx: number) =>
      relecteur.image ? (
        <img
          key={idx}
          alt="profile"
          className="avatar-profile"
          style={
            idx === 0
              ? { maxWidth: 32 }
              : {
                  maxWidth: 32,
                  position: "relative",
                  right: `${idx * 3}%`,
                }
          }
          src={relecteur.image}
        />
      ) : (
        <AvatarIcon
          key={idx}
          style={
            idx === 0
              ? { maxWidth: 33, marginTop: -15 }
              : {
                  maxWidth: 33,
                  marginTop: -15,
                  position: "relative",
                  right: `${idx * 3}%`,
                }
          }
        />
      )
    );
    while (elements.length < 5) {
      elements.push(
        <svg
          key={elements.length + 1}
          style={
            elements.length === 0
              ? { maxWidth: 33, marginTop: -15 }
              : {
                  maxWidth: 33,
                  marginTop: -15,
                  position: "relative",
                  right: `${elements.length * 3}%`,
                }
          }
          width="39"
          height="39"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="16"
            cy="16"
            r="15"
            fill="#ECF0FB"
            stroke="white"
            strokeWidth="2"
          />
          <path
            d="M13 15.73V17.06H15.632V19.818H17.018V17.06H19.65V15.73H17.018V13H15.632V15.73H13Z"
            fill="#3367CD"
          />
        </svg>
      );
    }
    return elements;
  };

  return (
    <Row className="p-2 ml-2">
      <Col md="12">
        {tag === "list" ? (
          <>
            <Row>
              <div className="label-black-md ml-4">Liste des formations </div>
              <div className="label-grey-sm  ml-4" style={{ fontSize: 12 }}>
                32 entreprises enregistrées{" "}
              </div>
            </Row>
            <Row>
              <Col md="12">
                <Nav
                  activeKey={activeLink}
                  onSelect={(selectedKey) => {
                    if (selectedKey) {
                      setActiveLink(+selectedKey);
                      dispatch(
                        editorActions.updateActiveLink({
                          activeLink: +selectedKey,
                        })
                      );
                    }
                  }}
                  className="d-flex pt-3"
                >
                  {navElement}
                </Nav>
              </Col>
            </Row>
          </>
        ) : (
          ((training.createur && route === "Ajouter un relecteur") ||
            route === "Assigner à une formation") && (
            <>
              <p className="label-black-md ml-4">
                {route === "Ajouter un relecteur"
                  ? "Formation concernée"
                  : "Relecteur concerné"}
              </p>
              {route === "Ajouter un relecteur" ? (
                <Row>
                  <Col md="12">
                    <div className="d-flex flex-row  px-4 mb-3 outlinedBox">
                      <Col className="d-flex flex-row align-items-center">
                        {training.createur.image ? (
                          <img
                            alt="profile"
                            className="avatar-profile mt-3"
                            style={{ maxWidth: 32 }}
                            src={training.createur.image}
                          />
                        ) : (
                          <AvatarIcon
                            style={{
                              width: 32,
                              height: 32,
                              borderRadius: "50%",
                            }}
                          />
                        )}
                        <p className="mt-3 label-black fw-800 p-0 ms-2">
                          {training.nom}
                        </p>
                      </Col>
                      <Col>
                        <p className="mt-3 label-black fw-800 p-0 ms-2">
                          {training.sujets[0]?.label}
                        </p>
                      </Col>
                      <Col className="mt-3 fw-800 p-0 ms-2">
                        <svg
                          width="16"
                          height="18"
                          viewBox="0 0 16 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.8333 2.49999H13V1.66666C13 1.20833 12.625 0.833328 12.1667 0.833328C11.7083 0.833328 11.3333 1.20833 11.3333 1.66666V2.49999H4.66667V1.66666C4.66667 1.20833 4.29167 0.833328 3.83333 0.833328C3.375 0.833328 3 1.20833 3 1.66666V2.49999H2.16667C1.24167 2.49999 0.508333 3.24999 0.508333 4.16666L0.5 15.8333C0.5 16.75 1.24167 17.5 2.16667 17.5H13.8333C14.75 17.5 15.5 16.75 15.5 15.8333V4.16666C15.5 3.24999 14.75 2.49999 13.8333 2.49999ZM13 15.8333H3C2.54167 15.8333 2.16667 15.4583 2.16667 15V6.66666H13.8333V15C13.8333 15.4583 13.4583 15.8333 13 15.8333ZM4.66667 8.33333H7.16667C7.625 8.33333 8 8.70833 8 9.16666V11.6667C8 12.125 7.625 12.5 7.16667 12.5H4.66667C4.20833 12.5 3.83333 12.125 3.83333 11.6667V9.16666C3.83333 8.70833 4.20833 8.33333 4.66667 8.33333Z"
                            fill="#BDBDBD"
                          />
                        </svg>
                      </Col>
                      <Col
                        className="mt-3 fw-800 p-0 ms-2"
                        style={{ minWidth: 170 }}
                      >
                        {getRelectures(training.reviewers)}
                      </Col>
                      <Col>
                        <p className="mt-3 label-black fw-800 p-0 ms-2">
                          {training.submitDate
                            ? moment(training.submitDate).format("DD/MM/YYYY")
                            : "-"}
                        </p>
                      </Col>
                      <Col>
                        <p className="mt-3 label-black fw-800 p-0 ms-2">
                          {getNotes(training.notes)}
                        </p>
                      </Col>
                    </div>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col md="12">
                    <div className="d-flex flex-row  px-4 mb-3 outlinedBox">
                      <Col
                        md="3"
                        className="d-flex flex-row align-items-center"
                      >
                        <MemberIcon />
                        <p className="mt-3 label-black fw-800 p-0 ms-2">
                          {proofreader.firstName + " " + proofreader.lastName}{" "}
                        </p>
                      </Col>
                      <Col md="3">
                        <p className="mt-3 label-black fw-800 p-0 ms-2">
                          {proofreader.pendingFormations} en attente
                        </p>
                      </Col>
                      <Col md="3" className="mt-3">
                        <EllipsisText
                          className="label-black"
                          text={text}
                          length={20}
                        />
                        <ReactTooltip>{text}</ReactTooltip>
                      </Col>
                      <Col md="3">
                        {proofreader.isActive ? (
                          <p className="actif-training p-1 mt-3 fw-normal">
                            Actif
                          </p>
                        ) : (
                          <p className="inactif-training p-1 mt-3 fw-normal">
                            Inactif
                          </p>
                        )}
                      </Col>
                    </div>
                  </Col>
                </Row>
              )}
            </>
          )
        )}
        <Row>
          {activeLink === 0 && (
            <ValidatedTraining tag={tag} matches={matches} />
          )}
          {activeLink === 1 && route !== "Ajouter un relecteur" && (
            <>
              <TrainingNeedsProofreader
                tag={tag}
                dispatch={dispatch}
                scrollable={matches.small}
                matches={matches}
                route={route}
                proofreader={proofreader}
              />
            </>
          )}
          {activeLink === 1 && route === "Ajouter un relecteur" && (
            <div className="center-container">
              <Row className="p-2 ml-2">
                <Col md="12">
                  <Row>
                    <AllProofreaders
                      tag={tag}
                      dispatch={dispatch}
                      scrollable={matches.small}
                      matches={matches}
                      route={route}
                    />
                  </Row>
                </Col>
              </Row>
            </div>
          )}
          {activeLink === 2 && (
            <EditorTrainingsContainer activeLink={activeLink} />
          )}
          {activeLink === 3 && (
            <EditorTrainingsContainer activeLink={activeLink} />
          )}
          {activeLink === 4 && (
            <ValidatedTraining tag={tag} matches={matches} isAlumni />
          )}
          {activeLink === 5 && (
            <EditorTrainingsContainer activeLink={activeLink} />
          )}
          {activeLink === 6 && (
            <EditorTrainingsContainer activeLink={activeLink} />
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default DashboardEditor;
