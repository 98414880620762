import { useState } from "react";
import moment from "moment";
//@ts-ignore
import { LinkedinShareButton, TwitterShareButton } from "react-share";

import { AiFillFileExcel, AiFillHeart } from "react-icons/ai";
import { Card, Col, PopoverBody, Row, UncontrolledPopover } from "reactstrap";
import { ReactComponent as AvatarIcon } from "../../../../assets/img/icons/avatar-person.svg";
import { ReactComponent as HeartIcon } from "../../../../assets/img/icons/heart.svg";
import { ReactComponent as HeartOutlinedIcon } from "../../../../assets/img/icons/heartOutlined.svg";
import { ReactComponent as CommentIcon } from "../../../../assets/img/icons/comment.svg";
import { ReactComponent as ShareIcon } from "../../../../assets/img/icons/share.svg";
import { ReactComponent as AdoreIcon } from "../../../../assets/img/icons/adore.svg";
import { ReactComponent as InterestedIcon } from "../../../../assets/img/icons/interested.svg";
import { ReactComponent as BravoIcon } from "../../../../assets/img/icons/bravo.svg";
import { ReactComponent as SupportIcon } from "../../../../assets/img/icons/support.svg";
import { ReactComponent as AdoreBlueIcon } from "../../../../assets/img/icons/adoreBleu.svg";
import { ReactComponent as SupportBlueIcon } from "../../../../assets/img/icons/supportBlue.svg";
import { ReactComponent as SupportSmallBlueIcon } from "../../../../assets/img/icons/supportSmall.svg";
import { ReactComponent as BravoBlueIcon } from "../../../../assets/img/icons/bravoBlue.svg";
import { ReactComponent as BravoSmallBlueIcon } from "../../../../assets/img/icons/bravoSmall.svg";
import { ReactComponent as InterestedBlueIcon } from "../../../../assets/img/icons/interestedBlue.svg";
import { ReactComponent as InterestedSmallBlueIcon } from "../../../../assets/img/icons/interestingSmall.svg";
import { ReactComponent as TweetIcon } from "../../../../assets/img/icons/tweet.svg";
import { ReactComponent as TweetWhiteIcon } from "../../../../assets/img/icons/tweetWhite.svg";
import { ReactComponent as SmileIcon } from "../../../../assets/img/icons/smileGrey.svg";
import { ReactComponent as ShareInIcon } from "../../../../assets/img/icons/shareText.svg";
import { ReactComponent as SyncIcon } from "../../../../assets/img/icons/sync.svg";
import { ReactComponent as StarIcon } from "../../../../assets/img/icons/star.svg";
import { FaFilePowerpoint, FaHeart, FaRegSmile } from "react-icons/fa";
import { Popup, Grid } from "semantic-ui-react";
import { Formik } from "formik";
import { Picker } from "emoji-mart";
import { RiLinkedinFill, RiSendPlaneFill } from "react-icons/ri";
import Audio from "../../../../components/widget/Audio";
import { MdDownload } from "react-icons/md";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import { useAppSelector } from "../../../../redux/hooks";
import { selectUser } from "../../../../redux/slices/userSlice";
import { Mention, MentionsInput } from "react-mentions";

import { TiEye } from "react-icons/ti";
import PostLinkPreview from "../../community/components/LinkPreview";
import { fetchMentions } from "../../community/components/CreatePost";
import { BASE_URI } from "../../../../utils/base";

const News = (props: any) => {
  const host = window.location.origin
  const baseUrl = window.location.href.includes("clubmentors") ? host : window.location.href.includes("levelup-ui") ? host + "/levelup-ui" : host + BASE_URI
  const {
    post,
    reactToPost,
    sendComment,
    retrieveCommentByPost,
    commentsByPost,
    setCommentsData,
    setType,
  } = props;
  const user = useAppSelector(selectUser);
  const [openCommentPostId, setOpenCommentPostId] = useState("");
  const [open, setOpen] = useState(false);
  const [commentPage, setCommentPage] = useState(1);
  const [commentContent, setCommentContent] = useState("");
  const addEmoji = (emoji: any) => {
    setCommentContent((prev: string) => {
      const content = prev + emoji.native;
      return content;
    });
  };
  const handleContentChange = (event: any) => {
    setCommentContent(event.target.value);
  };

  const findHashtag = (text: string) => {};

  const handleSendComment = (ev: any, id: number) => {
    if (!ev || (ev && ev.key === "Enter" && !ev.shiftKey)) {
      let newContent = commentContent;
      const mentions = newContent
        .split(" ")
        .filter((content: string) => content.includes("@@@__"))
        .map((str: string) => {
          const splitted = str.split("@@@__");
          const popedstr = splitted.pop();
          const popedlast = popedstr?.split("^^^__").pop();
          return popedlast !== undefined ? popedlast.split("@@@^^^")[0] : "";
        });
      newContent = newContent
        .split("@@@__")
        .join('<label className="mentionText" id="mention');
      newContent = newContent.split("^^^__").join(`">@`);
      newContent = newContent.split("@@@^^^").join("</label>");
      newContent = newContent.split("\n").join("<br />");

      sendComment(newContent, id, mentions);
      setCommentContent("");
      setCommentPage(1);
    }
    if (ev && ev.key === "Enter" && ev.shiftKey) {
      setCommentContent((prev) => prev + "\n");
    }
  };
  return (
    <>
      {post && (
        <Row className="center-container px-4">
          <Col md="8" className="px-5">
            <Col md="12" className="mt-4 d-flex flex-column ms-1">
              {
                <Card body className="cardDetailsDesc mt-0 pb-2 p-3 mb-3">
                  {findHashtag(post.text)}
                  <div className="d-flex flex-row align-items-center">
                    {post.creator.image ? (
                      <img
                        alt="profile"
                        className={"avatarHome"}
                        src={post.creator.image}
                      />
                    ) : (
                      <AvatarIcon
                        style={{
                          width: 30,
                          height: 30,
                          borderRadius: "50%",
                        }}
                      />
                    )}
                    <div className="d-flex flex-column ms-2">
                      <p className="label-black fw-bold m-0">
                        {post.creator.firstName + " " + post.creator.lastName}
                      </p>
                      <p className="label-grey-normal opacity-75 m-0">
                        {post.creator.entreprise}
                      </p>
                      <p className="label-grey-normal opacity-75 m-0">
                        {moment(post.date).local().format("DD/MM/YYYY") +
                          " à " +
                          moment(post.date).local().format("HH:mm")}
                      </p>
                    </div>
                  </div>
                  <div
                    dangerouslySetInnerHTML={{ __html: post.text }}
                    className="post-text pt-2 mb-2"
                  />
                  {post.attachType === "image" ? (
                    <img
                      width="400"
                      style={{ borderRadius: 8 }}
                      src={post.file}
                      alt="img-preview"
                    />
                  ) : post.attachType === "audio" ? (
                    <Audio height={64}>
                      <audio style={{ height: 64 }} src={post.file}></audio>
                    </Audio>
                  ) : post.attachType === "video" ? (
                    <video width="400" controls>
                      <source style={{ borderRadius: 8 }} src={post.file} />
                    </video>
                  ) : post.attachType === "link" ? (
                    <PostLinkPreview
                     linkContent={post.url}
                      setLinkContent={() => {}}
                      readOnly={true}
                    />
                  ) : post.attachType === "rating" || post.rating ? (
                    <>
                      <div className="post-text pt-2 mb-2">
                        Je viens de terminer une nouvelle formation !
                      </div>
                      <Card
                        body
                        className="cardDetailsDesc mt-0 pb-2 p-3 mb-3 px-0 w-50"
                      >
                        <div className="d-flex flex-row ms-3">
                          <SyncIcon className="icon-outlined mt-1" />
                          <p className="mb-0 ms-3 label-black fw-bold">
                            {post.rating.trainingTitle}
                          </p>
                        </div>
                        <hr className="hr-text-grey" />
                        <div className="d-flex flex-row">
                          <div className="d-flex flex-column ms-3 me-2">
                            <p className="label-grey-normal m-0 p-0">
                              Note finale
                            </p>
                            <p className="label-primary-nqt-lg fw-normal m-0 p-0">
                              {post.rating.note}
                            </p>
                          </div>
                          <div className="d-flex flex-column ms-3 me-2">
                            <p className="label-grey-normal m-0 p-0">
                              Badge débloqué
                            </p>
                            <p className="label-primary-nqt-lg fw-normal m-0 p-0">
                              {[...Array(post.rating.badge)].map(() => (
                                <StarIcon />
                              ))}
                            </p>
                          </div>
                          <div className="d-flex flex-column ms-3 me-2">
                            <p className="label-grey-normal m-0 p-0">
                              Points récoltés
                            </p>
                            <p className="label-primary-nqt-lg fw-normal m-0 p-0">
                              +{post.rating.points}
                            </p>
                          </div>
                        </div>
                      </Card>
                    </>
                  ) : (
                    post.attachType && (
                      <div
                        className="d-flex flex-row upload-icon pb-0"
                        style={{ borderRadius: 8 }}
                      >
                        {post.file.split(".").pop() === "pdf" ? (
                          <BsFillFileEarmarkPdfFill
                            size={20}
                            className="mt-3 ms-1"
                          />
                        ) : post.file.split(".").pop() === "xlsx" ||
                          post.file.split(".").pop() === "xls" ? (
                          <AiFillFileExcel size={20} className="mt-3 ms-1" />
                        ) : (
                          <FaFilePowerpoint size={40} className="upload-icon" />
                        )}
                        <div className="d-flex flex-column w-100 justify-content-center">
                          <p className="mb-0 ms-3 label-black fw-bold pt-2 ">
                            Télécharger le fichier{" "}
                            {post.file.split(".").pop() === "pdf"
                              ? "Pdf"
                              : post.file.split(".").pop() === "xlsx" ||
                                post.file.split(".").pop() === "xls"
                              ? "Excel"
                              : "Power Point"}
                          </p>
                          <p className="mt-0 ms-3 label-grey-normal">
                            {post.file.split("\\").pop().split("/").pop()}
                          </p>
                        </div>
                        <TiEye
                          size={16}
                          color="#3367cd"
                          className="align-middle mt-3 pointer"
                          onClick={() => window.open(post.file, "_blanc")}
                        />
                        <a href={post.file} download>
                          <MdDownload
                            className="align-middle mt-3 pointer"
                            color="#3367cd"
                            size={17}
                          />
                        </a>
                      </div>
                    )
                  )}
                  <div className="d-flex flex-row mt-3">
                    <div className="d-flex flex-row w-75">
                      {post.likeCount > 0 && (
                        <>
                          <div className="circle-icon">
                            <HeartIcon />
                          </div>
                          <p className="label-primary-normal ms-1 me-2 mt-1 p-0">
                            {post.likeCount}
                          </p>
                        </>
                      )}
                      {post.reactions.adoreCount > 0 && (
                        <>
                          <div className="circle-icon ms-1">
                            <FaRegSmile size={10} color="#3367CD" />
                          </div>
                          <p className="label-primary-normal ms-1  me-2 mt-1 p-0">
                            {post.reactions.adoreCount}
                          </p>
                        </>
                      )}
                      {post.reactions.supportCount > 0 && (
                        <>
                          <div className="circle-icon ms-1">
                            <SupportSmallBlueIcon />
                          </div>
                          <p className="label-primary-normal ms-1 me-2 mt-1 p-0">
                            {post.reactions.supportCount}
                          </p>
                        </>
                      )}
                      {post.reactions.interestingCount > 0 && (
                        <>
                          <div className="circle-icon ms-1">
                            <InterestedSmallBlueIcon />
                          </div>
                          <p className="label-primary-normal ms-1 me-2 mt-1 p-0">
                            {post.reactions.interestingCount}
                          </p>
                        </>
                      )}
                      {post.reactions.bravoCount > 0 && (
                        <>
                          <div className="circle-icon ms-1">
                            <BravoSmallBlueIcon />
                          </div>
                          <p className="label-primary-normal ms-1 me-2 mt-1 p-0">
                            {post.reactions.bravoCount}
                          </p>
                        </>
                      )}
                    </div>
                    <div className="w-25 ">
                      <p
                        className="label-primary-normal mt-1 float-end pointer"
                        onClick={() => {
                          setOpenCommentPostId(
                            post.id !== openCommentPostId ? post.id : ""
                          );
                          setCommentsData([]);
                          post.id !== openCommentPostId &&
                            retrieveCommentByPost({
                              post_id: post.id,
                              page: 1,
                            }) &&
                            setCommentPage(1);
                        }}
                      >
                        {post.commentsCount + (post.commentsCount > 1 ? " commentaires": " commentaire")}
                      </p>
                    </div>
                  </div>
                  <hr className="hr-text-grey mt-1" />
                  <div className="d-flex flex-row">
                    <div className="d-flex flex-row w-75">
                      {post.userReaction === "like" && (
                        <AiFillHeart
                          className="m-0 p-0 ms-2"
                          color="#3367cd"
                          size={15}
                        />
                      )}
                      <p
                        className={
                          post.userReaction === "aimer"
                            ? "label-primary-normal me-3 me-2 p-0 pointer mt-1"
                            : "label-grey-normal me-3 mx-2 p-0 pointer"
                        }
                        onClick={() => reactToPost("aimer", post.id)}
                      >
                        {post.userReaction !== "aimer" ? (
                          <HeartOutlinedIcon />
                        ) : (
                          <FaHeart size={12} color="#3367cd" />
                        )}
                        Aimer
                      </p>

                      <Popup
                        style={{ borderRadius: 90 }}
                        className="reactions p-1 px-2 pointer"
                        trigger={
                          <p
                            className={
                              post.userReaction === "adore" ||
                              post.userReaction === "support" ||
                              post.userReaction === "interesting" ||
                              post.userReaction === "bravo"
                                ? "label-primary-normal me-3 mx-2 p-0 pointer mt-1"
                                : "label-grey-normal me-3 mx-2 p-0 pointer"
                            }
                          >
                            <FaRegSmile
                              size={15}
                              color={
                                post.userReaction === "adore" ||
                                post.userReaction === "support" ||
                                post.userReaction === "interesting" ||
                                post.userReaction === "bravo"
                                  ? "#3367cd"
                                  : "#8D8D8D"
                              }
                            />{" "}
                            Réagir
                          </p>
                        }
                        flowing
                        hoverable
                      >
                        <Grid
                          className="d-flex flex-row align-items-center"
                          centered
                          divided
                          columns={3}
                        >
                          {post.userReaction === "adore" ? (
                            <div
                              className="d-flex flex-row reaction-selected pe-2 me-2"
                              onClick={() => reactToPost("adore", post.id)}
                            >
                              <AdoreBlueIcon />
                              <p className="ms-1 mt-1 label-primary-normal">
                                J'adore
                              </p>
                            </div>
                          ) : (
                            <AdoreIcon
                              className="me-3"
                              onClick={() => reactToPost("adore", post.id)}
                            />
                          )}
                          {post.userReaction === "support" ? (
                            <div
                              className="d-flex flex-row reaction-selected pe-2 me-2"
                              onClick={() => reactToPost("support", post.id)}
                            >
                              <SupportBlueIcon />
                              <p className="ms-1 mt-1 label-primary-normal">
                                Soutient
                              </p>
                            </div>
                          ) : (
                            <SupportIcon
                              className="me-3"
                              onClick={() => reactToPost("support", post.id)}
                            />
                          )}
                          {post.userReaction === "interesting" ? (
                            <div
                              className="d-flex flex-row reaction-selected pe-2 me-2"
                              onClick={() =>
                                reactToPost("interesting", post.id)
                              }
                            >
                              <InterestedBlueIcon />
                              <p className="ms-1 mt-1 label-primary-normal">
                                Intéressant
                              </p>
                            </div>
                          ) : (
                            <InterestedIcon
                              className="me-3"
                              onClick={() =>
                                reactToPost("interesting", post.id)
                              }
                            />
                          )}
                          {post.userReaction === "bravo" ? (
                            <div
                              className="d-flex flex-row reaction-selected pe-2 me-2"
                              onClick={() => reactToPost("bravo", post.id)}
                            >
                              <BravoBlueIcon />
                              <p className="ms-1 mt-1 label-primary-normal">
                                Bravo !
                              </p>
                            </div>
                          ) : (
                            <BravoIcon
                              className="me-3"
                              onClick={() => reactToPost("bravo", post.id)}
                            />
                          )}
                        </Grid>
                      </Popup>

                      <p
                        className="label-grey-normal me-3 mx-2 p-0 pointer"
                        onClick={() => {
                          setOpenCommentPostId(post.id);
                          setCommentsData([]);
                          retrieveCommentByPost({
                            post_id: post.id,
                            page: 1,
                          });
                          setCommentPage(1);
                        }}
                      >
                        <CommentIcon /> Commenter
                      </p>
                    </div>
                    <div className="w-25 ">
                      <Popup
                        on="click"
                        pinned
                        trigger={
                          <p className="label-grey-normal mt-1 float-end pointer">
                            <ShareIcon /> Partager
                          </p>
                        }
                      >
                        <Grid
                          className="cardDetailsDesc d-flex flex-row align-items-center p-2 ps-3"
                          centered
                          divided
                          columns={3}
                        >
                          <div className="d-flex flex-column">
                            <p className="label-black fw-bold">Partager</p>
                            <TwitterShareButton
                              url={`${baseUrl}/postView/${post.id}`}
                              title={"Twitter share test"}
                            >
                              <div className="d-flex flex-row pointer">
                                <p className="label-black me-5">
                                  Publier sur twitter
                                </p>
                                <div className="px-2 tweet">
                                  <TweetWhiteIcon className="me-1" />
                                  <TweetIcon />
                                </div>
                              </div>
                            </TwitterShareButton>

                            <hr className="hr-text-grey mt-1" />
                            <LinkedinShareButton url={`${baseUrl}/postView/${post.id}`}>
                              <div className="d-flex flex-row pointer">
                                <p className="label-black me-5">
                                  Publier sur Linkedin
                                </p>
                                <div className="px-2 linkedin">
                                  <RiLinkedinFill
                                    color="white"
                                    size={11}
                                    className="me-1"
                                  />
                                  <ShareInIcon />
                                </div>
                              </div>
                            </LinkedinShareButton>
                          </div>
                        </Grid>
                      </Popup>
                    </div>
                  </div>
                  {openCommentPostId === post.id && (
                    <div>
                      <div className="d-flex flex-row">
                      {user.image ? (
                          <img
                            alt="profile"
                            className={'avatarHome'}
                            src={user.image}
                          />
                        ) : (
                          <AvatarIcon
                            style={{
                              width: 40,
                              height: 40,
                              borderRadius: '50%',
                            }}
                          />
                        )}
                        <Formik
                          initialValues={{
                            comment: "",
                          }}
                          onSubmit={() => {}}
                        >
                          {({ values, setFieldValue }) => {
                            return (
                              <div className="body__container w-100 ms-1">
                                <MentionsInput
                                  className="post-comment"
                                  onKeyPress={(e: any) =>
                                    handleSendComment(e, post.id)
                                  }
                                  placeholder="Rédiger un commentaire..."
                                  onChange={handleContentChange}
                                  value={commentContent}
                                  a11ySuggestionsListLabel={
                                    "Suggested Regions for mention"
                                  }
                                >
                                  <Mention
                                    trigger="@"
                                    markup="@@@__^^^____display__@@@^^^"
                                    data={fetchMentions}
                                    appendSpaceOnAdd
                                    style={{ background: "#ECF0FB" }}
                                    displayTransform={(display) => {
                                      return `@${display} `;
                                    }}
                                  />
                                </MentionsInput>
                                <RiSendPlaneFill
                                  size={20}
                                  className={
                                    commentContent !== ""
                                      ? "comment-send-active pointer"
                                      : "comment-send pointer"
                                  }
                                  color={
                                    values.comment === ""
                                      ? "#8D8D8D"
                                      : "#3367cd"
                                  }
                                  type="submit"
                                  onClick={() =>
                                    handleSendComment(null, post.id)
                                  }
                                />
                                <SmileIcon
                                  className="comment-emoji pointer"
                                  onClick={() => setOpen(!open)}
                                  onBlur={() => setOpen(false)}
                                  id={"PopoverEmojiComment" + post.id}
                                />
                                <UncontrolledPopover
                                  placement="bottom"
                                  target={"PopoverEmojiComment" + post.id}
                                  trigger="legacy"
                                  className="popover-body "
                                >
                                  <PopoverBody className="popover-body ">
                                    <Col
                                      onFocus={() => setOpen(true)}
                                      onBlur={() => setOpen(false)}
                                    >
                                      <Picker
                                        set="twitter"
                                        onSelect={(emoji) => addEmoji(emoji)}
                                        i18n={{
                                          search: "Recherche",
                                          categories: {
                                            search: "Résultats de recherche",
                                            recent: "Récents",
                                          },
                                        }}
                                      />
                                    </Col>
                                  </PopoverBody>
                                </UncontrolledPopover>
                              </div>
                            );
                          }}
                        </Formik>
                      </div>
                      {post.commentsCount > 0 && (
                        <>
                          {commentsByPost &&
                            commentsByPost.map((comment: any) => (
                              <div className="d-flex flex-row mt-3">
                                {comment.user.image ? (
                                  <img
                                    alt="profile"
                                    style={{
                                      width: 30,
                                      height: 30,
                                      borderRadius: "50%",
                                    }}
                                    src={comment.user.image}
                                  />
                                ) : (
                                  <AvatarIcon
                                    className=" me-2"
                                    style={{
                                      width: 30,
                                      height: 30,
                                      borderRadius: "50%",
                                    }}
                                  />
                                )}
                                <div className="d-flex flex-column ms-2 card-comment p-2 ps-3">
                                  <p className="label-black fw-bold m-0">
                                    {comment.user.firstName}
                                  </p>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: comment.text,
                                    }}
                                    className="post-text m-0"
                                  />
                                  <p className="label-grey-normal opacity-75 m-0">
                                    {moment(comment.updated_at)
                                      .local()
                                      .format("DD/MM/YYYY") +
                                      " à " +
                                      moment(comment.updated_at).format(
                                        "HH:mm"
                                      )}
                                  </p>
                                </div>
                              </div>
                            ))}
                          {post.commentsCount > commentPage * 3 && (
                            <p
                              className="label-primary-normal mt-3 pointer text-center"
                              onClick={() => {
                                setOpenCommentPostId(post.id);
                                retrieveCommentByPost({
                                  post_id: post.id,
                                  page: commentPage + 1,
                                });
                                setType("pagination");
                                setCommentPage((prev) => prev + 1);
                              }}
                            >
                              afficher plus de commentaires
                            </p>
                          )}
                        </>
                      )}
                    </div>
                  )}
                </Card>
              }
            </Col>
          </Col>
        </Row>
      )}
    </>
  );
};

export default News;
