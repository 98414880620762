import { useMedia } from "react-media";
import { useLocation } from "react-router";
import { Col, Row } from "reactstrap";
import Layout from "../../../components/Layout";
import { useAppDispatch } from "../../../redux/hooks";
import AllPending from "./components/AllPending";

const PendingTrainingsContainer = (props: any) => {
  const dispatch = useAppDispatch();
  const {pathname} = useLocation();
  const title = pathname === '/trainingAwaitingProofreader' ? "Formations En Attente De Relecteurs" : "Formations En Attente De Notation";
  const route = pathname === '/trainingAwaitingProofreader' ? "TrainingAwaiting" : "TrainingRating";
  const GLOBAL_MEDIA_QUERIES = {
    small: "(max-width: 1477px)",
    large: "(min-width: 1478px)",
  };
  const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
  return (
    <Layout title={title}>
    <div className="center-container">
      <Row className="p-2 ml-2">
        <Col md="12">
          <Row>
            <AllPending
              dispatch={dispatch}
              scrollable={matches.small}
              route={route}
            />
          </Row>
        </Col>
      </Row>
    </div>
    </Layout>
  );
};

export default PendingTrainingsContainer;
