import { Button, Col, Row, Card, Spinner } from "reactstrap";
import moment from "moment";
import { ReactComponent as CommaIcon } from "../../../../assets/img/icons/comma.svg";
import { ReactComponent as ProfileIcon } from "../../../../assets/img/icons/profile.svg";
import { ReactComponent as DetailsIcon } from "../../../../assets/img/icons/details.svg";
import { ReactComponent as StepIcon } from "../../../../assets/img/icons/step.svg";
import { ReactComponent as AvatarCardedIcon } from "../../../../assets/img/icons/avatarCarded.svg";
import { ReactComponent as PositionIcon } from "../../../../assets/img/icons/position.svg";
import { ReactComponent as JobIcon } from "../../../../assets/img/icons/job.svg";
import { retrieveTrainingLaunchDetail } from "../../../../redux/slices/trainingSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { useHistory } from "react-router";
import company from "../../../../assets/img/icons/comany.png";
import { selectUser } from "../../../../redux/slices/userSlice";
import { ReactComponent as AvatarIcon } from '../../../../assets/img/icons/avatar-person.svg'

const DetailsContent = (props: any) => {
  const { training, isSaved, saveLoading, sendSaveRequest, isSubscribed } =
    props;
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const getSeniority = () => {
    const years = Math.floor(
      moment(new Date()).diff(
        moment(training.creator.registred_at),
        "years",
        true
      )
    );
    const months = Math.floor(
      moment(new Date()).diff(
        moment(training.creator.registred_at),
        "months",
        true
      )
    );
    return years !== 0 ? years + " ans" : months + " mois";
  };
  const isAsync = () => (training.typology === "async" ? true : false);
  const isPres = () => (training.eventDetails.type === "pres" ? true : false);

  /**
   * Get Title
   */
  const getTitle = (type: string) => {
    return type === "pdf"
      ? "PDF"
      : type === "ppt" || type === "pptx"
      ? "Power Point"
      : type === "jpg" || type === "jpeg" || type === "png"
      ? "Image"
      : type === "mp3"
      ? "Podcast"
      : type === "mp4"
      ? "Vidéo"
      : type === "xlsx" || type === "xls"
      ? "Excel"
      : "";
  };

  const getDuration = () => {
    return training.duration > 59
      ? Math.floor(training.duration / 60) +
          " h " +
          (training.duration % 60 > 0 ? (training.duration % 60) + " min" : "")
      : training.duration + " min";
  };

  const getMeetingTool = () => {
    const url = training.eventDetails.location.toLowerCase();
    return url.includes("meet.google")
      ? "Google Meet"
      : url.includes("skype")
      ? "Skype"
      : url.includes("teams")
      ? "Teams"
      : url.includes("zoom")
      ? "Zoom"
      : url.includes("slack")
      ? "Slack"
      : url.includes("whereby")
      ? "Whereby"
      : "-";
  };

  const getContentTypes = () => {
    let contentType: string = "";
    isAsync()
      ? training.sections &&
        training.sections.map((section: any) =>
          section.sectionContent.map((content: any) => {
            if (content.type === "text") {
              contentType =
                contentType === "" ? "Texte" : contentType + ", Texte";
            }
            if (content.type === "upload") {
              contentType =
              contentType === ""
              ? getTitle(content.fileType)
              : contentType + ", " + getTitle(content.fileType);
            }
            if(content.type === "video" && !contentType.includes("Vidéo")) {
              contentType = (contentType === "" ? "" : `${contentType}, Vidéo`)
            }
            return null;
          })
        )
      : training.eventDetails.section.files.map(
          (file: any) =>
            (contentType =
              contentType === ""
                ? getTitle(file.fileType)
                : contentType + ", " + getTitle(file.fileType))
        );
    return contentType || "-";
  };
  const getTiming = () => {
    const minuteStart =
      training.eventDetails.startTimeMinute == null
        ? "00"
        : training.eventDetails.startTimeMinute;
    const minuteEnd =
      training.eventDetails.endTimeMinute == null
        ? "00"
        : training.eventDetails.endTimeMinute;
    const hourStart =
      training.eventDetails.startTimeHour == null
        ? "00"
        : training.eventDetails.startTimeHour;
    const hourEnd =
      training.eventDetails.endTimeHour == null
        ? "00"
        : training.eventDetails.endTimeHour;

    return (
      "De " + hourStart + ":" + minuteStart + " à " + hourEnd + ":" + minuteEnd
    );
  };
  return (
    <Row>
      <Col md="10">
        <div className="d-flex flex-row mt-3">
          <CommaIcon className="me-2" />
          <p className="label-black-nqt-sm fw-bolder mb-2 pb-0">
            Description de la formation
          </p>
        </div>
        <Card body className="cardDetailsDesc mt-0 pb-2 p-4 ">
          {training.description.replace(/<br\s*\/?>/gm, "\r\n").split(/[\n]+/).map((item: any, idx: any) => (
            <p key={idx} className="label-grey-normal">
              {item}
              <br />
            </p>
          ))}
        </Card>

        <div className="d-flex flex-row mt-4">
          <DetailsIcon className="me-2" />
          <p className="label-black-nqt-sm fw-bolder mb-2 pb-0">
            Détails de la formation
          </p>
        </div>
        <Card color="light" outline body className="cardDetails mt-0">
          <div className="d-flex flex-row">
            <p className="label-black pt-3 px-4 w-75">
              {isAsync() ? "Types de contenu" : "Date de la formation"}
            </p>
            <p className="label-grey-normal pt-3 px-4 w-25 text-end">
              {isAsync()
                ? getContentTypes()
                : "Le " +
                  moment(training.eventDetails.date).format("DD/MM/YYYY")}
            </p>
          </div>
          <hr className="hr-text-grey p-0 m-0" />
          <div className="d-flex flex-row">
            <p className="label-black pt-3 px-4 w-50">
              {isAsync()
                ? training.sections.length > 1
                  ? "Nombre d’étapes"
                  : "Nombre d’étape"
                : isPres()
                ? "Lieu de la formation"
                : "Outil pour suivre la formation"}
            </p>
            <p className="label-grey-normal pt-3 px-4 w-50 text-end">
              {isAsync()
                ? training.sections.length
                : isPres()
                ? training.eventDetails.location
                : getMeetingTool()}
            </p>
          </div>
          <hr className="hr-text-grey p-0 m-0" />
          <div className="d-flex flex-row">
            <p className="label-black pt-3 px-4 w-50">
              {isAsync() ? "Durée estimée de la formation" : "Heure"}
            </p>
            <p className="label-grey-normal pt-3 px-4 w-50 text-end">
              {isAsync() ? getDuration() : getTiming()}
            </p>
          </div>
          <hr className="hr-text-grey p-0 m-0" />
          <div className="d-flex flex-row">
            <p className="label-black pt-3 px-4 w-50">
              {isAsync()
                  ? "Nombre de participants"
                  : "Nombre d'inscrits"
              }
            </p>
            <p className="label-grey-normal pt-3 px-4 w-50 text-end">
              {training.subscribers <=1 ? training.subscribers + " mentor" : training.subscribers + " mentors"}
            </p>
          </div>
          <hr className="hr-text-grey p-0 m-0" />
          <div className="d-flex flex-row">
            <p className="label-black pt-3 px-4 w-50">
              {isAsync() ? "Formation publiée le" : "Contenu accessible"}
            </p>
            <p className="label-grey-normal pt-3 px-4 w-50 text-end">
              {isAsync()
                ? training.datePub
                  ? moment(training.datePub).format("DD/MM/YYYY")
                  : ""
                : getContentTypes()}
            </p>
          </div>
        </Card>

        {isAsync() && training.sections.length > 1 && (
          <>
            <div className="d-flex flex-row mt-4">
              <StepIcon className="me-2" />
              <p className="label-black-nqt-sm fw-bolder mb-2 pb-0">
                Étapes de la formation
              </p>
            </div>
            <Card color="light" outline body className="cardDetails mt-0">
              {training.sections.map((section: any, key: number) => (
                <>
                  <div className="d-flex flex-row p-3 pb-0 align-items-center">
                    <p className="label-primary-md ms-4 me-2 upload-icon py-2 px-3">
                      {key + 1}
                    </p>
                    <div className="d-flex flex-column w-100">
                      <p className="mb-0 ms-3 label-black fw-bold">
                        {section.sectionTitle}
                      </p>
                      {section.sectionDescription !== null && section.sectionDescription.replace(/<br\s*\/?>/gm, "\r\n").split(/[\n]+/).map((item: any, idx: any) => (
                        <p key={idx} className="mt-0 ms-3 mt-1 label-grey-normal">
                          {item}
                          <br />
                        </p>
                      ))}
                    </div>
                  </div>
                  <hr className="hr-text-grey p-0 m-0" />
                </>
              ))}
            </Card>
          </>
        )}
      </Col>
      <Col md="2">
        <div className="d-flex flex-row mt-3">
          <ProfileIcon className="me-2" />
          <p className="label-black-nqt-sm fw-bolder mb-2 pb-0">Créateur</p>
        </div>
        <Card body className="cardDetails mt-0 pt-3">
          <Row className="center-container">
          {training.creator.image ? (
              <img
                alt="profile"
                style={{ width: 65, height: 40, borderRadius: '50%' }}
                src={training.creator.image}
              />
            ) : (
              <AvatarIcon
                  style={{ width: 65, height: 40, borderRadius: '50%', marginTop: "1rem" }}
              />
            )}
            <p className="label-black-nqt-sm fw-bolder mt-2 text-center">
              {training.creator.firstName + " " + training.creator.lastName}
            </p>
            <p className="label-primary-normal fw-bolder text-center">
              {training.creator.count_formations <=1 ?  training.creator.count_formations + " formation créée"
                  : training.creator.count_formations + " formations créées"}
            </p>
          </Row>

          <hr className="hr-text-grey p-0 m-0" />
          <div className="d-flex flex-row p-3 pb-0">
            <AvatarCardedIcon className="mt-2" />
            <div className="d-flex flex-column w-100">
              <p className="mb-0 ms-3 label-black fw-bold">Ancienneté</p>
              <p className="mt-0 ms-3 label-grey-normal">
                {"Mentor depuis " + getSeniority()}
              </p>
            </div>
          </div>
          <hr className="hr-text-grey p-0 m-0" />
          <div className="d-flex flex-row p-3 pb-0">
            <PositionIcon className="mt-2" />
            <div className="d-flex flex-column w-100">
              <p className="mb-0 ms-3 label-black fw-bold">Région</p>
              <p className="mt-0 ms-3 label-grey-normal">
                {training.creator.city}, France
              </p>
            </div>
          </div>
          <hr className="hr-text-grey p-0 m-0" />
          <div className="d-flex flex-row p-3 pb-0">
            <img
              src={company}
              alt={"logo"}
              style={{ marginTop: 6, width: 18, height: 18 }}
            />
            <div className="d-flex flex-column w-100">
              <p className="mb-0 ms-3 label-black fw-bold">Entreprise</p>
              <p className="mt-0 ms-3 label-grey-normal">
                {training.creator.entreprise}
              </p>
            </div>
          </div>
          <hr className="hr-text-grey p-0 m-0" />
          <div className="d-flex flex-row p-3 pb-0">
            <JobIcon className="mt-2" />
            <div className="d-flex flex-column w-100">
              <p className="mb-0 ms-3 label-black fw-bold">
                Secteur d’activité
              </p>
              <p className="mt-0 ms-3 label-grey-normal">
                {training.creator.sector}
              </p>
            </div>
          </div>
        </Card>
      </Col>
      <Row>
        <Col md="7">
          {training.creator.id !== user.id && isSubscribed && (
            <Button
              className="btn-round-sm validate mt-3"
              color="primary"
              onClick={() => {
                dispatch(
                  retrieveTrainingLaunchDetail({ id: training.id, history })
                );
              }}
            >
              Lancer la formation
            </Button>
          )}
          {isAsync() && !isSaved && (
            <Button
              className="btn-round-sm-secondary ms-2 validate mt-3"
              color="primary"
              onClick={() => sendSaveRequest(training.id)}
            >
              {saveLoading === "pending" && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-1"
                />
              )}
              Enregistrer la formation
            </Button>
          )}
        </Col>
      </Row>
    </Row>
  );
};
export default DetailsContent;
