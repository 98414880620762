export const tutorialData = [
  {
    title: "Inscription au Club",
    videoUrl: "https://clubmentors.nqt.fr/videos/NQT+1_Inscription+Club.webm",
  },
  {
    title: "Tableau de bord",
    videoUrl: "https://clubmentors.nqt.fr/videos/NQT+2_TdB.webm",
  },
  {
    title: "Création de formation",
    videoUrl:
      "https://clubmentors.nqt.fr/videos/NQT+3_Cr%C3%A9ation+formation.webm",
  },
  {
    title: "Relecture",
    videoUrl: "https://clubmentors.nqt.fr/videos/NQT+4_Relecture.webm",
  },
  {
    title: "Réseau social",
    videoUrl: "https://clubmentors.nqt.fr/videos/NQT+5_R%C3%A9seau+Social.webm",
  },
];
