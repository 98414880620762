import {Button, Modal} from "react-bootstrap";
import {
    selectError,
    selectLoading, selectModal, selectTargetRoute,
    trainingActions
} from "../../../../redux/slices/trainingSlice";
import {useEffect, useRef} from "react";
import NotificationAlert from "react-notification-alert";
import {useAppDispatch, useAppSelector} from "../../../../redux/hooks";
import {useHistory} from "react-router-dom";
import {training} from "../../../../utils/constant";


const TrainingModal = (props: any) => {
    const { onSubmit, values, isValid } = props;
    const show = useAppSelector(selectModal);
    const targetRoute = useAppSelector(selectTargetRoute);
    const notificationAlert = useRef(null);
    const dispatch = useAppDispatch();
    const history = useHistory();

    const handleClose = () => {
        dispatch(trainingActions.setModal(false));
    };


    const closeModal = () => {
        dispatch(trainingActions.setTraining(training));
        history.push("/"+targetRoute);
    }
    const error = useAppSelector(selectError);
    const status = useAppSelector(selectLoading);

    //Effect change listener
    useEffect(() => {
        if (status === "false") {
            error &&
            //@ts-ignore
            notificationAlert.current.notificationAlert({
                place: "tr",
                message: (
                    <div>
                        <div>
                            {error}
                        </div>
                    </div>
                ),
                type: "danger",
                icon: "nc-icon nc-bell-55",
                closeButton: false,
                autoDismiss: 7,
            });
        }
    }, [error, status]);

    return (
        <>
            <NotificationAlert ref={notificationAlert}/>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>Êtes-vous sûr de vouloir quitter cette page</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>{
                        handleClose();
                        closeModal();
                    }}>
                        Quitter
                    </Button>
                    <Button  disabled={isValid} variant="primary" onClick={()=>{
                        onSubmit({...values,"status":"draft"});
                        handleClose();
                    }}>
                        Sauvegarder
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default TrainingModal;
