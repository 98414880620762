import { Col, Row } from "reactstrap";
import OngoingTrainings from "./components/OngoingTrainings";
import SavedTrainings from "./components/SavedTrainings";
import CompletedTrainings from "./components/CompletedTrainings";
import TrainingCount from "./components/TrainingCount";
import { useMedia } from "react-media";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectUser } from "../../../redux/slices/userSlice";
import { useEffect } from "react";
import { getTrainingCoursesCount } from "../../../redux/slices/trainingSlice";
import Layout from "../../../components/Layout";

const TrainingCoursesContainer = () => {
  const userRole = useAppSelector(selectUser).role;
  const isEditor = userRole.includes("EDITOR");

  const GLOBAL_MEDIA_QUERIES = {
    small: "(max-width: 1150px)",
    large: "(min-width: 1151px)",
  };
  const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getTrainingCoursesCount());
  }, [dispatch]);

  return (
    <Layout title="Mon parcours de formation">
      <Row className={`center-container ${isEditor ? "mt-5" : ""}`}>
        <Col md="12">
          <Row>
            <Col md="12">
              <Row className="center-container">
                <TrainingCount />
              </Row>
            </Col>
          </Row>
          <Row className="p-2 ml-2">
            <Col md="12">
              <Row>
                <p className="label-black-md mt-4 ml-4">
                  Mes formations en cours
                </p>
                <OngoingTrainings
                  dispatch={dispatch}
                  scrollable={matches.small}
                />
              </Row>
            </Col>
          </Row>
          <Row className="p-2 ml-2">
            <Col md="12">
              <Row>
                <p className="label-black-md p-2 mt-3 ml-5">
                  Mes formations enregistrées
                </p>
                <SavedTrainings
                  dispatch={dispatch}
                  scrollable={matches.small}
                />
              </Row>
            </Col>
          </Row>
          <Row className="p-2 ml-2">
            <Col md="12">
              <Row>
                <p className="label-black-md p-2 ml-5">
                  Mes formations terminées
                </p>
                <CompletedTrainings
                  dispatch={dispatch}
                  scrollable={matches.small}
                />
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  );
};

export default TrainingCoursesContainer;
