import http from "../config/httpCommon";

const GamificationService = {
  getGamificationData(id: number, type: string) {
    const params = {
      type: type,
    };
    return new Promise(async (resolve, reject) => {
      (await http())
        .get(`/gamification/${id}`, { params })
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },
  getGamificationAdminData() {
    return new Promise(async (resolve, reject) => {
      (await http())
        .get("config/gamification")
        .then((response: any) => {
          resolve(response.data)
        })
        .catch((error: any) => reject(error.message));
    });
  },

  editGamificationAdminData(values: any) {
    return new Promise(async (resolve, reject) => {
      (await http())
        .put("/config/gamification", values)
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },
  getHighlights() {
    return new Promise(async (resolve, reject) => {
      (await http())
        .get("/highlight")
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },
  getGamifications() {
    return new Promise(async (resolve, reject) => {
      (await http())
        .get("/gamification/trophies")
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },
};
export default GamificationService;
