import { useState, useEffect } from "react";
import { useParams } from "react-router";
import TrainingPreviewSynchrone from "./components/TrainingPreviewSynchrone";
import TrainingPreviewHeader from "./components/TrainingPreviewHeader";
import TrainingPreviewQuizz from "./components/TrainingPreviewQuizz";
import TrainingPreviewSelfAssessment from "./components/TrainingPreviewSelfAssessment";
import { selectTrainingPreviewDetail, selectTrainingPreview} from "../../../redux/slices/trainingSlice";
import { useAppSelector } from "../../../redux/hooks";

const TrainingPreviewContainer = () => {
  type TrainingPreviewContainerParams = {
    trainingId: string;
  };
  const params = useParams<TrainingPreviewContainerParams>();
  const [step, setStep] = useState(1);
  const [activeSection, setActiveSection] = useState(0);
  const { trainingId } = params;
  const data = useAppSelector(selectTrainingPreviewDetail);
  const dataPreview = useAppSelector(selectTrainingPreview);
  const [result,setResult]=useState(data);
  const isEmpty = Object.keys(params).length === 0;

  useEffect(()=>{
    if(trainingId){
      setResult(dataPreview);
    }
  },[trainingId,dataPreview]);

  return (
    <div>
      <TrainingPreviewHeader trainingId={+trainingId} isEmpty={isEmpty} data={result}/>
      {step === 1 && (
        <TrainingPreviewSynchrone
          setStep={setStep}
          activeSection={activeSection}
          setActiveSection={setActiveSection}
          data={result}
        ></TrainingPreviewSynchrone>
      )}
      {step === 2 && (
        <TrainingPreviewQuizz
          setStepG={setStep}
          activeSection={activeSection}
          data={result}
        ></TrainingPreviewQuizz>
      )}
      {step === 3 && (
        <TrainingPreviewSelfAssessment
          setStepG={setStep}
          activeSection={activeSection}
          data={result}
        ></TrainingPreviewSelfAssessment>
      )}
    </div>
  );
};

export default TrainingPreviewContainer;


