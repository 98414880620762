import { IoMdTrash } from "react-icons/io";

const VideoPreview = (props: any) => {
  const { selectedVideo, setSelectedVideo } = props;
  return selectedVideo.map((file:any, idx:number) => {
    return (
      <div key={idx} className="d-flex flex-row download mt-4">
        <div className="d-flex flex-column">
          <video width="400" controls>
            <source src={file.preview} />
          </video>
        </div>
        <div
          className="d-flex flex-row justify-content-center"
          style={{ alignSelf: "center" }}
        >
          <IoMdTrash
            size={18}
            color="#3367cd"
            className="right pointer ms-5 mt-2"
            onClick={() => {
              setSelectedVideo(null);
            }}
          />
        </div>
      </div>
    );
  });
};

export default VideoPreview;
