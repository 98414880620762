import {
  BsFillPlusCircleFill,
  BsFillTrashFill,
  BsPlusLg,
} from "react-icons/bs";
import { Col, Row } from "reactstrap";
import { Input } from "../../../../components/forms";

const AutoEval = (props: any) => {
  //Init props
  const { sectionKey, sectionContentKey, values, setFieldValue, setValues } =
    props;

  /**
   * Handle topic change (Add or remove topic)
   * @param value
   * @param thematicKey
   * @param topicKey
   */
  const handleTopicChange = (
    value: string,
    thematicKey: number,
    topicKey: number
  ) => {
    let trainingValues: any = JSON.parse(JSON.stringify(values));

    trainingValues.sections[sectionKey].sectionContent[
      sectionContentKey
    ].themes[thematicKey].topics[topicKey].title = value;

    setFieldValue(
      `[sections[${sectionKey}].sectionContent[${sectionContentKey}].themes[${thematicKey}].topics[${topicKey}].title]`,
      value
    );
    if (
      topicKey ===
        values.sections[sectionKey].sectionContent[sectionContentKey].themes[
          thematicKey
        ].topics.length -
          1 &&
      value !== ""
    ) {
      trainingValues.sections[sectionKey].sectionContent[
        sectionContentKey
      ].themes[thematicKey].topics.push({ title: "" });
      setValues(trainingValues);
    }
    if (value === "") {
      trainingValues.sections[sectionKey].sectionContent[
        sectionContentKey
      ].themes[thematicKey].topics.splice(topicKey, 1);
      setValues(trainingValues);
    }
  };

  /**
   * Add thematic
   */
  const addThematic = () => {
    let trainingValues = JSON.parse(JSON.stringify(values));
    trainingValues.sections[sectionKey].sectionContent[
      sectionContentKey
    ].themes.push({
      title: "",
      choice: "unique",
      topics: [{ title: "" }],
    });
    setValues(trainingValues);
  };

  const deleteThematic = (variable: number) => {
    let trainingValues = JSON.parse(JSON.stringify(values));
    var array =
      trainingValues.sections[sectionKey].sectionContent[sectionContentKey].themes;
    array.splice(variable, 1);
    setValues(trainingValues);
  };

  return (
    <>
      {values.sections[sectionKey].sectionContent[sectionContentKey].themes.map(
        (thematic: any, thematicKey: number) => (
          <>
            <Row className="my-4">
              <Col md="12">
                <Row>
                  <p className="label-grey-normal">
                    L’auto-évaluation donne l’opportunité à l'apprenant de noter
                    par sujet son niveau de compréhension de 1 à 5.
                  </p>
                  <p className="label-black-normal">
                    Renseignez les sujets sur lesquels l'apprenant doit
                    s’évaluer.
                  </p>
                </Row>
                <Row>
                  <div className="d-flex flex-row w-75">
                    <p className="label-primary-md ms-4 me-2">
                      {thematicKey + 1}
                    </p>
                    <Input
                      name={`[sections[${sectionKey}].sectionContent[${sectionContentKey}].themes[${thematicKey}].title]`}
                      placeholder="Thématique de l’auto-évaluation.
                      Exemple : Premier contact avec son filleul"
                    />
                    {thematicKey !== 0 ? (
                      <button
                        style={{ backgroundColor: "#ffffff", border: "none" }}
                        onClick={() => {
                          deleteThematic(thematicKey);
                        }}
                      >
                        <BsFillTrashFill color="#3367cd" size={20} />{" "}
                      </button>
                    ) : null}
                  </div>
                </Row>
              </Col>
            </Row>

            {thematic.topics.map((topic: any, topicKey: number) => {
              return (
                <Row>
                  <Col md="12">
                    <Row>
                      <div className="d-flex flex-row w-75">
                        <BsPlusLg
                          color="#3367cd"
                          className="ms-4 me-2 mt-3"
                          size={12}
                        />
                        <Input
                          name={`[sections[${sectionKey}].sectionContent[${sectionContentKey}].themes[${thematicKey}].topics[${topicKey}].title]`}
                          placeholder={
                            thematicKey
                              ? "Renseigner un nouveau sujet. Exemple: choisir un mode de communication"
                              : "Renseigner un nouveau sujet. Exemple: définir un lieu"
                          }
                          onChange={(ev: any) =>
                            handleTopicChange(
                              ev.target.value,
                              thematicKey,
                              topicKey
                            )
                          }
                        />
                      </div>
                    </Row>
                  </Col>
                </Row>
              );
            })}
            <Row>
              {thematicKey !==
                values.sections[sectionKey].sectionContent[sectionContentKey]
                  .themes.length -
                  1 && <hr className="hr-text-grey p-0 mt-3"></hr>}
            </Row>
          </>
        )
      )}
      <Row>
        <hr className="hr-text-grey p-0 mt-3" />
      </Row>
      <Row className="center-container">
        <BsFillPlusCircleFill
          color="#3367cd"
          size={20}
          className="pointer"
          onClick={addThematic}
        />
      </Row>
    </>
  );
};
export default AutoEval;
