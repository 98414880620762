import { Card, Col, Row } from "reactstrap";
import { ReactComponent as ThumbsUpDownIcon } from "../../../../assets/img/icons/thumbsUpDown.svg";
import { ReactComponent as ThumbsUpDownDisabledIcon } from "../../../../assets/img/icons/thumbsUpDownDisabled.svg";
import { Switch } from "../../../../components/forms";

const TrainingFeedback = (props: any) => {
  const { values, setValues } = props;
  const handleFeedbackChange = (ev: boolean) => {
    const feedbackEnabled = ev
      ? { ...values, feedback: { ...values.feedback, enable: true } }
      : { ...values, feedback: { ...values.feedback, enable: false } };
    setValues(feedbackEnabled);
  };
  const handleStarsFeedbackChange = (ev: boolean) => {
    const starsFeedbackEnabled = ev
      ? { ...values, feedback: { ...values.feedback, stars: true } }
      : { ...values, feedback: { ...values.feedback, stars: false } };
    setValues(starsFeedbackEnabled);
  };
  const handleCommentsFeedbackChange = (ev: boolean) => {
    const commentsFeedbackEnabled = ev
      ? { ...values, feedback: { ...values.feedback, comments: true } }
      : { ...values, feedback: { ...values.feedback, comments: false } };
    setValues(commentsFeedbackEnabled);
  };

  return (
    <Row className="center-container">
      <Col md="9">
        <Card body color="light" outline className="cardIntro mt-5 p-4 mb-2">
          <div className="d-flex flex-row align-items-center">
            {values.feedback.enable ? (
              <ThumbsUpDownIcon className="iconOutlined" />
            ) : (
              <ThumbsUpDownDisabledIcon className="iconOutlined" />
            )}
            <div className="d-flex flex-column  w-100 mt-3">
              <p
                className={
                  values.feedback.enable
                    ? "mb-0 ms-3 label-primary-nqt-md"
                    : "mb-0 ms-3 label-grey-nqt-md"
                }
              >
                Module de feedbacks
              </p>
              <p className="pt-1 ms-3 label-grey-normal">
                Les apprenants qui ont suivi votre formation peuvent laisser
                leurs feedbacks via une notation en étoiles ou en laissant des
                commentaires.
              </p>
            </div>
            <Switch
              name={values.feedback.enable}
              value={values.feedback.enable}
              onChange={(ev: boolean) => handleFeedbackChange(ev)}
              offColor={"#8D8D8D"}
            />
          </div>
          {values.feedback.enable && (
            <Row>
              <hr className="hr-text-grey p-0 mt-3"></hr>
              <Switch
                name={values.feedback.stars}
                value={values.feedback.stars}
                onChange={(ev: boolean) => handleStarsFeedbackChange(ev)}
                secondLabel="Notation en étoiles"
                secondLabelClassName={
                  values.feedback.stars
                    ? "label-primary-nqt-sm ms-2"
                    : "label-grey-normal ms-2"
                }
                offColor={"#8D8D8D"}
              />
              <Switch
                name={values.feedback.comments}
                value={values.feedback.comments}
                onChange={(ev: boolean) => handleCommentsFeedbackChange(ev)}
                secondLabel="Commentaires"
                secondLabelClassName={
                  values.feedback.comments
                    ? "label-primary-nqt-sm right ms-2"
                    : "label-grey-normal right ms-2"
                }
                offColor={"#8D8D8D"}
              />
            </Row>
          )}
        </Card>
      </Col>
    </Row>
  );
};
export default TrainingFeedback;
