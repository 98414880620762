import { Card, Col, Progress, Row } from "reactstrap";
import { FaThList } from "react-icons/fa";
import { IoMdAttach, IoMdClose, IoMdLink } from "react-icons/io";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { ReactComponent as VectorIcon } from "../../../../assets/img/icons/Vector.svg";
import { ReactComponent as DoneIcon } from "../../../../assets/img/icons/Done.svg";
import { Input, TextArea } from "../../../../components/forms";
import {
  sectionContentText,
  sectionContentUpload,
  sectionContentVideo
} from "../../../../utils/constant";
import AsyncText from "./AsyncText";
import AsyncUpload from "./AsyncUpload";
import AsyncEval from "./AsyncEval";
import VideoSection from "./VideoSection";

const AsyncSection = (props: any) => {
  //Init states
  const { values, setValues, errors, setFieldValue, matches, tag, status } = props;

  /**
   * Add Or Remove section
   * @param {string} tag
   * @param {number} sectionKey
   */
  const addOrRemoveSection = (tag: string, sectionKey: number = 0) => {
    let trainingValues = JSON.parse(JSON.stringify(values));
    if (tag === "add") {
      trainingValues.sections.push({
        section: 1,
        sectionTitle: "",
        sectionDuration: "",
        sectionDescription: "",
        sectionContent: [],
      });
      setValues(trainingValues);
    } else {
      trainingValues.sections.splice(sectionKey, 1);
      setValues(trainingValues);
    }
  };
  /**
   * Add Or Remove section content
   * @param {string} tag
   * @param {string} type
   * @param {number} index
   * @param {number} sectionKey
   */
  const addOrRemoveSectionContent = (
    tag: string,
    type: string,
    index: number = 0,
    sectionKey: number
  ) => {
    let trainingValues = JSON.parse(JSON.stringify(values));

    if (tag === "add") {
      trainingValues.sections[sectionKey].sectionContent.push(
        type === "text"
          ? { ...sectionContentText }
          : type === "upload"
            ? { ...sectionContentUpload }
            : type === "video"
              ? { ...sectionContentVideo }
              : {
                type: "eval",
                autoEval: false,
                questions: [
                  {
                    title: "",
                    choice: "unique",
                    answers: [{ title: "", isCorrect: false }],
                  },
                ],
              }
      );
      setValues(trainingValues);
    } else {
      trainingValues.sections[sectionKey].sectionContent.splice(index, 1);
      setValues(trainingValues);
    }
  };

  /**
   * Show section content
   * @param {string} type
   */
  const showSectionContent = (
    type: string,
    sectionKey: number,
    sectionContentKey: number
  ) => {
    switch (type) {
      case "upload":
        return (
          <AsyncUpload
            sectionKey={sectionKey}
            values={values}
            sectionContentKey={sectionContentKey}
            setFieldValue={setFieldValue}
            addOrRemoveSectionContent={addOrRemoveSectionContent}
            tag={tag}
          />
        );
      case "text":
        return (
          <AsyncText
            sectionKey={sectionKey}
            setFieldValue={setFieldValue}
            sectionContentKey={sectionContentKey}
            values={values}
            addOrRemoveSectionContent={addOrRemoveSectionContent}
          />
        );
      case "eval":
        return (
          <AsyncEval
            matches={matches}
            sectionKey={sectionKey}
            values={values}
            setFieldValue={setFieldValue}
            sectionContentKey={sectionContentKey}
            setValues={setValues}
            addOrRemoveSectionContent={addOrRemoveSectionContent}
            tag={tag}
            status={status}
          />
        );
      case "video":
        return (
          <VideoSection
            setFieldValue={setFieldValue}
            sectionKey={sectionKey}
            sectionContentKey={sectionContentKey}
            values={values}
            addOrRemoveSectionContent={addOrRemoveSectionContent}
          />
        );
      default:
        break;
    }
  };
  return (
    <>
      {values.sections &&
        values.sections.map((section: any, sectionKey: number) => (
          <>
            <Row className="center-container">
              <Col md="9">
                <>
                  {sectionKey > 0 && (
                    <IoMdClose
                      size={10}
                      color="#8D8D8D"
                      onClick={() => addOrRemoveSection("remove", sectionKey)}
                      className="mt-5 ms-2 float-end closeIcon pointer"
                    />
                  )}
                  <Card
                    body
                    color="light"
                    outline
                    className="cardIntro mt-5 p-4"
                    key={sectionKey}
                  >
                    <div className="d-flex flex-row align-items-center">
                      <FaThList size={42} className="iconOutlined" />
                      <div className="d-flex flex-column  w-100 mt-3">
                        <p
                          className={
                            values.sections[sectionKey].sectionTitle ===
                              "Nom de section"
                              ? "mb-0 ms-3 label-grey-nqt-md"
                              : "mb-0 ms-3 label-primary-nqt-md"
                          }
                        >
                          {values.sections[sectionKey].sectionTitle === ""
                            ? "Nom de la section"
                            : values.sections[sectionKey].sectionTitle}
                        </p>
                        <p className="pt-1 ms-3 label-grey-normal">
                          Les étapes permettent de séquencer votre formation en
                          plusieurs chapitres.
                        </p>
                      </div>
                      <>
                        <Progress
                          multi
                          style={{ height: 4, width: 24 }}
                          className="me-2"
                        >
                          <Progress
                            bar
                            value="50"
                            className={
                              section.sectionTitle ? "bg-primary" : "bg-pink"
                            }
                          />
                        </Progress>
                        {sectionKey > 0 && (
                          <IoMdClose
                            size={15}
                            color="#3367cd"
                            onClick={() =>
                              addOrRemoveSection("remove", sectionKey)
                            }
                            className="ms-2 float-end pointer closeIconPrimary"
                          />
                        )}
                      </>
                    </div>

                    <Row>
                      <hr className="hr-text-grey p-0 mt-3"></hr>
                    </Row>
                    <Row>
                      <Col md="6">
                        <p className="label-black mt-3">Nom de la section</p>
                        <Input
                          name={`[sections[${sectionKey}].sectionTitle]`}
                          placeholder="Exemple : Définir un lieu de rencontre avec son filleul"
                          onChange={(ev: any) => {
                            setFieldValue(
                              `[sections[${sectionKey}].sectionTitle]`,
                              !errors[
                                `[sections[${sectionKey}].sectionTitle]`
                              ] && ev.target.value
                                ? ev.target.value
                                : ""
                            );
                          }}
                          error={
                            errors.sections &&
                            errors.sections[sectionKey]?.sectionTitle
                          }
                        />
                      </Col>
                      <Col md="6">
                        <p className="label-black mt-3">
                          Durée estimée de l’étape
                          {/*<span className="optional ms-1">Facultatif</span>*/}
                        </p>
                        <Input
                          name={`[sections[${sectionKey}].sectionDuration]`}
                          type="number"
                          min="1"
                          onChange={(event: any) => {
                            (+event.target.value > 0 || event.target.value === "") &&
                              setFieldValue(`[sections[${sectionKey}].sectionDuration]`, event.target.value);
                          }}
                          placeholder="Exemple : 10 minutes"
                          error={errors.sections &&
                            errors.sections[sectionKey]?.sectionDuration}
                        />

                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <p className="label-black mt-3">
                          Description
                          <span className="optional ms-1">Facultatif</span>
                        </p>
                        <TextArea
                          name={`[sections[${sectionKey}].sectionDescription]`}
                          placeholder="Exemple : Dans cette section, vous trouverez les éléments pour bien définir un lieu propice à l'échange avec votre filleul"
                        />
                      </Col>
                    </Row>
                  </Card>
                  {section.sectionContent.length > 0 &&
                    section.sectionContent.map(
                      (content: any, sectionContentKey: number) => (
                        <>
                          <IoMdClose
                            size={10}
                            color="#8D8D8D"
                            onClick={() =>
                              addOrRemoveSectionContent(
                                "remove",
                                content.type,
                                sectionContentKey,
                                sectionKey
                              )
                            }
                            className="mt-5 ms-2 float-end closeIcon pointer"
                          />

                          {showSectionContent(
                            content.type,
                            sectionKey,
                            sectionContentKey
                          )}
                        </>
                      )
                    )}

                  <Row className="mt-3" style={{ width: "99%" }}>
                    <Col md="3">
                      <div
                        className="dottedCard d-flex flex-row pointer h-auto mt-1"
                        onClick={() =>
                          addOrRemoveSectionContent(
                            "add",
                            "upload",
                            0,
                            sectionKey
                          )
                        }
                      >
                        <p className="label-grey-md-normal m-2 mt-5 w-100 ">
                          Importer <br /> une pièce jointe
                        </p>
                        <IoMdAttach size={30} color="#8D8D8D" className="m-2" />
                      </div>
                    </Col>
                    <Col md="3">
                      <div
                        className="dottedCard d-flex flex-row pointer h-auto mt-1"
                        onClick={() =>
                          addOrRemoveSectionContent(
                            "add",
                            "text",
                            0,
                            sectionKey
                          )
                        }
                      >
                        <p className="label-grey-md-normal m-2 mt-5 w-100 ">
                          Rédiger <br /> un texte
                        </p>
                        <VectorIcon color="#8D8D8D" className="m-2" />
                      </div>
                    </Col>
                    <Col md="3">
                      <div
                        className="dottedCard d-flex flex-row pointer h-auto mt-1"
                        onClick={() =>
                          addOrRemoveSectionContent(
                            "add",
                            "eval",
                            0,
                            sectionKey
                          )
                        }
                      >
                        <p
                          className={
                            "label-grey-md-normal ms-2 mb-2 mt-5 w-100 "
                          }
                        >
                          Ajouter <br /> un module d’évaluation
                        </p>
                        <DoneIcon color="#8D8D8D" className="m-2" />
                      </div>
                    </Col>
                    <Col md="3">
                      <div
                        className="dottedCard d-flex flex-row pointer h-auto mt-1"
                        onClick={() =>
                          addOrRemoveSectionContent(
                            "add",
                            "video",
                            0,
                            sectionKey
                          )
                        }
                      >
                        <p className="label-grey-md-normal m-2 mt-5 w-100 ">
                          Ajouter <br /> un lien vers une vidéo
                        </p>
                        <IoMdLink size={30} color="#8D8D8D" className="m-2" />
                      </div>
                    </Col>
                  </Row>
                </>
              </Col>
            </Row>
          </>
        ))}
      <Row className="center-container mt-3">
        <Col md="9">
          <Card
            body
            color="light"
            outline
            className="cardIntro mt-4 p-4 center-container"
          >
            <p
              className="m-0 p-0 label-black-md pointer"
              onClick={() => addOrRemoveSection("add")}
            >
              <BsFillPlusCircleFill
                color="#3367cd"
                size={20}
                className="me-3"
              />
              Ajouter une section
            </p>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default AsyncSection;
