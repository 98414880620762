import { Col, Row } from "reactstrap";

import { useAppSelector } from "../../../../redux/hooks";
import TrainingCountCard from "../../trainingCourses/components/TrainingCountCard";
import { selectCreatedTrainingsCount } from "../../../../redux/slices/trainingSlice";

const CreatedTrainingsCount = (props: any) => {
  const { showToBeValidatedTrainingsBox } = props;
  const count = useAppSelector(selectCreatedTrainingsCount);

  return (
    <Row>
      <Col md="12">
        <Row className="center-container">
          <Col md="4">
            <TrainingCountCard
              trainingLabel={"Mes brouillons"}
              trainingStatus={"de formation"}
              state={"draft"}
              count={count}
            />
          </Col>
          {showToBeValidatedTrainingsBox && (
            <Col md="4">
              <TrainingCountCard
                trainingLabel={"En attente de"}
                trainingStatus={"validation"}
                state={"pending"}
                count={count}
              />
            </Col>
          )}
          <Col md="4">
            <TrainingCountCard
              trainingLabel={"Formations"}
              trainingStatus={"publiées"}
              state={"published"}
              count={count}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default CreatedTrainingsCount;
