import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Rate from "../../../../../components/widget/Rate";
import ReactTooltip from "react-tooltip";
import { useAppSelector } from "../../../../../redux/hooks";
import Box from "../../../../../components/widget/Box";
import { selectUser } from "../../../../../redux/slices/userSlice";
const SkillsMobile = () => {
  const profile = useAppSelector(selectUser);
  const competenceList = profile.competences?.competence?.map((competence) => {
    return <Box key={competence.id} content={competence.libelle}></Box>;
  });
  return (
    <Row className="center-container mb-4">
      <Col md="10">
        <Card body color="light" outline className="card p-0">
          <CardBody>
            <Row className="center-container mb-4">
              <Col sm="2" xs="3">
                <CircularProgressbarWithChildren
                  value={100}
                  background={true}
                  styles={{
                    // Customize the root svg element
                    root: {},
                    // Customize the path, i.e. the "completed progress"
                    path: {
                      // Path color
                      stroke: `rgba(0, 0, 255)`,
                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: "butt",
                      // Customize transition animation
                      transition: "stroke-dashoffset 0.5s ease 0s",
                      strokeWidth: "3px",
                    },
                    // Customize the circle behind the path, i.e. the "total progress"
                    trail: {
                      // Trail color
                      stroke: "rgb(255,255, 255)",
                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: "butt",
                      strokeWidth: "10px",
                    },
                    // Customize background - only used when the `background` prop is true
                    background: {
                      fill: "#3367CD",
                    },
                  }}
                >
                  <svg
                    width="16"
                    height="12"
                    viewBox="0 0 16 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.375 6C12.41 6 13.2425 5.16 13.2425 4.125C13.2425 3.09 12.41 2.25 11.375 2.25C10.34 2.25 9.5 3.09 9.5 4.125C9.5 5.16 10.34 6 11.375 6ZM5.75 5.25C6.995 5.25 7.9925 4.245 7.9925 3C7.9925 1.755 6.995 0.75 5.75 0.75C4.505 0.75 3.5 1.755 3.5 3C3.5 4.245 4.505 5.25 5.75 5.25ZM11.375 7.5C10.0025 7.5 7.25 8.19 7.25 9.5625V11.25H15.5V9.5625C15.5 8.19 12.7475 7.5 11.375 7.5ZM5.75 6.75C4.0025 6.75 0.5 7.6275 0.5 9.375V11.25H5.75V9.5625C5.75 8.925 5.9975 7.8075 7.5275 6.96C6.875 6.825 6.245 6.75 5.75 6.75Z"
                      fill="white"
                    />
                  </svg>
                </CircularProgressbarWithChildren>
              </Col>
              <Col xs="9" sm="9">
                <CardTitle className="d-flex">
                  <Col xs="9" sm="9">
                    <div className="label-black-md">
                      Compétences de mentorat
                    </div>
                  </Col>
                  <Col xs="2" sm="2">
                    <div data-tip="Compétences de mentorat">
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.99996 17.3333C4.39996 17.3333 0.666626 13.5999 0.666626 8.99992C0.666626 4.39992 4.39996 0.666584 8.99996 0.666584C13.6 0.666584 17.3333 4.39992 17.3333 8.99992C17.3333 13.5999 13.6 17.3333 8.99996 17.3333ZM9.83329 4.83325H8.16663V6.49992H9.83329V4.83325ZM9.83329 8.16658H8.16663V13.1666H9.83329V8.16658Z"
                          fill="#BDBDBD"
                        />
                      </svg>
                    </div>
                    <ReactTooltip />
                  </Col>
                </CardTitle>
                <Rate percentage={100}></Rate>
              </Col>
            </Row>
            <Row>
              <hr className="line" />
            </Row>
            <Row>
              <Col md="4" sm="6" xs="10">
                <div className="expertiseTitle">Ancienneté</div>
                <div className="expertiseInfo">
                  {profile.competences?.anciennete}
                </div>
              </Col>
              <Col md="4" sm="6" xs="10">
                <div className="expertiseTitle">Nombre de filleul</div>
                <div className="expertiseInfo">
                  {profile.competences?.nbreFilleul}
                </div>
              </Col>
              {/* <Col md="4" sm="6" xs="10">
                <div className="expertiseTitle">Niveau de mentorat</div>
                <div className="expertiseInfo">
                  {profile.competences?.niveau}
                </div>
              </Col> */}
            </Row>
            <Row>
              <div className="expertiseTitle">Compétences</div>
              <div className="expertiseList">{competenceList}</div>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default SkillsMobile;
