import {  Card, CardBody, Col, Row } from "reactstrap";
import { Fragment, useEffect, useState } from "react";
//@ts-ignore
import Modal from "react-modal";
import { ReactComponent as AIcon } from "../../../../assets/img/icons/A.svg";
import { ReactComponent as BIcon } from "../../../../assets/img/icons/B.svg";
import { ReactComponent as CIcon } from "../../../../assets/img/icons/C.svg";
import { ReactComponent as ChevronIcon } from "../../../../assets/img/icons/chevron.svg";
import { ReactComponent as ChevronBackIcon } from "../../../../assets/img/icons/chevronBack.svg";
import { ReactComponent as ChevronBottom } from "../../../../assets/img/icons/chevronBottom.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/img/icons/close.svg";
import { ReactComponent as CloseModalIcon } from "../../../../assets/img/icons/closeModal.svg";
import { ReactComponent as CircleIcon } from "../../../../assets/img/icons/circle.svg";
import { ReactComponent as DoneSmallIcon } from "../../../../assets/img/icons/doneSmall.svg";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { retrieveAllTrainingsReviewComments, selectAllTrainingComments } from "../../../../redux/slices/trainingSlice";
import moment from "moment";
import 'moment/locale/fr';

const EditorCommentModal = (props: any) => {
  //Init props
  const { modalIsOpen, closeModal, trainingId } = props;
  const dispatch = useAppDispatch();
  const proofreaders = useAppSelector(selectAllTrainingComments);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [openProcessedComments, setOpenProcessedComments] = useState(true);
  const [proofreader, setProofreader] = useState(
    proofreaders[0]
  );

  useEffect(() => {
    dispatch(
      retrieveAllTrainingsReviewComments({
        formationId: trainingId,
      })
    );
  }, [dispatch, trainingId]);
  const handleDetails = (proofreader: any) => {
    setIsDetailsOpen(true);
    setProofreader(proofreader);
  };
  const getCommentCount = (proofreaderCurrent: any) => {
    return proofreaderCurrent.comments.length;
  };
  const getDoneCommentsCount = (proofreaderCurrent: any) => {
    return proofreaderCurrent.comments.filter((comment:any) => !comment.done).length;
  };
  const getComments = () => (
    <>
      <div className="d-flex flex-row">
        <div className="w-100  mt-4">
          <p className="label-black-trainings fw-bold">
            Commentaires non traités
          </p>
        </div>
        <p className="align-middle mt-4 ps-1 outlined-circle">
          {getDoneCommentsCount(proofreader)}
        </p>
      </div>
      {proofreader.comments.map((comment: any, commentKey: number) => {
        return (
          !comment.done && (
            <Fragment key={commentKey}>
              <div className="d-flex flex-row">
                <CircleIcon
                  className="me-2 pointer"
                />
                <p className="label-black mb-1">{comment.title}</p>
              </div>
              <p className="pt-0 ms-4 label-grey-trainings-sm">{moment(comment.date).calendar()}</p>
            </Fragment>
          )
        );
      })}
      <div className="d-flex flex-row">
        <div className="w-100 mt-2">
          <p className="label-black-trainings fw-bold">Commentaires traités</p>
        </div>
        {openProcessedComments ? (
          <ChevronBottom
            className="mt-3 pointer"
            onClick={() => setOpenProcessedComments(!openProcessedComments)}
          />
        ) : (
          <ChevronIcon
            className="mt-3 pointer"
            onClick={() => setOpenProcessedComments(!openProcessedComments)}
          />
        )}
      </div>
      {openProcessedComments &&
        proofreader.comments.map(
          (comment: any,index:number) =>
            comment.done && (
              <Fragment key={index}>
                <div className="d-flex flex-row">
                  <DoneSmallIcon className="me-2 mt-1" />
                  <p className="label-grey-normal mb-1">{comment.title}</p>
                </div>
                <p className="pt-0 ms-4 label-grey-trainings-sm">{moment(comment.date).calendar()}</p>
              </Fragment>
            )
        )}
    </>
  );

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      className="Modal"
      overlayClassName="Overlay"
      contentLabel="Example Modal"
    >
      {!isDetailsOpen ? (
        <>
          <Row>
            <div className="d-flex justify-content-end pointer"
              onClick={closeModal}>
              <CloseModalIcon />
            </div>
            <Col md="12" className="mt-4">
              <p className="label-black-nqt-md">Commentaires</p>
            </Col>
          </Row>
          <Row className="center-container">
            <Col md="12">
              {proofreaders.map(
                (proofreader: any, index: number) => (
                  <Fragment key={index}>
                    <div
                      className="d-flex flex-row align-items-center my-0 py-0 pointer"
                      onClick={() =>
                        getCommentCount(proofreader) > 0 &&
                        handleDetails(proofreader)
                      }
                    >
                      <div className="iconOutlined px-3">{proofreader.note}
                      </div>
                      <div className="d-flex flex-column mt-3 w-100">
                        <p className="mb-0 ms-3 label-black-trainings fw-bold">
                          {`${proofreader.relecteur.firstName} ${proofreader.relecteur.lastName}`}
                        </p>
                        <p className="pt-1 ms-3 label-grey-normal opacity-75">
                          {getCommentCount(proofreader) === 1 ? getCommentCount(proofreader) + " commentaire" : getCommentCount(proofreader) + " commentaires"}
                        </p>
                      </div>
                      <p className="align-middle mt-4 ps-1 outlined-circle">
                        {getCommentCount(proofreader)}
                      </p>
                      {getCommentCount(proofreader) ? (
                        <ChevronIcon className="mt-2 ms-3" />
                      ) : null}
                    </div>
                    <hr className="hr-text-grey p-0 m-0" />
                  </Fragment>
                )
              )}
            </Col>
          </Row>{" "}
        </>
      ) : (
        <>
          <Row>
            <Col md="12" className="mt-5">
              <CloseIcon
                className="float-end pointer"
                onClick={() => {
                  closeModal();
                  setIsDetailsOpen(false);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md="12" className="mt-1">
              <div className="d-flex flex-row">
                <ChevronBackIcon
                  className="me-3 pointer mt-2"
                  onClick={() => setIsDetailsOpen(false)}
                />
                <p className="label-black-nqt-md">
                  {proofreader.relecteur.firstName + " " + proofreader.relecteur.lastName}
                </p>
              </div>
            </Col>
          </Row>
          <Row className="center-container">
            <Col md="12">
              <Card
                className="p-0 m-0"
                style={{
                  backgroundColor: "rgba(249, 249, 249, 1)",
                  borderColor: "#ECF0FB",
                  borderRadius: 10,
                }}
              >
                <CardBody>
                  <div className="d-flex flex-row">
                    <div
                      className="iconOutlined px-3"
                      style={{
                        width: 45,
                        height: 43,
                        backgroundColor: "white",
                      }}
                    >
                      {proofreader.note === "A" ? (
                        <AIcon />
                      ) : proofreader.note === "B" ? (
                        <BIcon />
                      ) : (
                        <CIcon />
                      )}
                    </div>
                    <div className="align-items-center">
                      <p className="label-black ms-2 mb-0 mt-2">
                        {proofreader.noteComment ? proofreader.noteComment : "Pas de commentaire saisi"}
                      </p>
                    </div>
                  </div>
                </CardBody>
              </Card>
              {getComments()}
            </Col>
          </Row>
        </>
      )}
    </Modal>
  );
};
export default EditorCommentModal;
