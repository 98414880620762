import { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { useMedia } from "react-media";
import ReactTooltip from "react-tooltip";
import {Col, Row, Button } from "reactstrap";
const TrainingPreviewSelfAssessment = (props: any) => {
  const { setStepG, activeSection, data } = props;
  const sectionData = data.sections[activeSection];
  const indexcontent = sectionData.sectionContent.findIndex(
    (content:any) => content.type === "eval" && content.autoEval === true
  );
  const themes = sectionData.sectionContent[indexcontent].themes?.filter(
    (theme:any) => theme.title !== null
  );
  type SelectedType = {
    [id: number]: number[];
  };
  const [activeKey, setActiveKey] = useState(0);
  const [choiceIndex, setChoiceIndex] = useState(0);
  const [selectedElement, setSelectedElement] = useState<SelectedType>({
    0: [1000],
  });
  const [isComplete, setisComplete] = useState(false);
  const [validateClicked, setValidateClicked] = useState(false);
  const [totalAnswers, setTotalAnswers] = useState(0);
  const GLOBAL_MEDIA_QUERIES = {
    small: "(max-width: 991px)",
    large: "(min-width: 992px)",
  };
  const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
  const elementClicked = (index: number, isLast: boolean) => {
    if (themes) {
      setSelectedElement((prev) => {
        const selected = { ...prev };
        const selectedTheme = [...selected[activeKey]];
        selectedTheme.splice(selectedTheme.length - 1, 0, index);
        selected[activeKey] = selectedTheme;
        return selected;
      });
      setChoiceIndex((prev) => prev + 1);
      if (isLast) {
        if (activeKey < themes.length - 1) {
          setSelectedElement((prev) => {
            const selected = { ...prev };
            selected[activeKey + 1] = [1000];
            return selected;
          });
          setChoiceIndex(0);
          setActiveKey((prev) => prev + 1);
        } else {
          setisComplete(true);
        }
      }
    }
  };
  const stepProgress =  themes ? ((100 / (themes[activeKey].topics.length)) * (choiceIndex + 1)).toString() + "%" : "0%";
  const colors = ["#FF66A9", "#ED984F", "#3367CD", "#3367CD", "#3367CD"];
  const tooltips = [
    "Pas du tout",
    "Un peu",
    "Bien",
    "Très bien",
    "Parfaitement",
  ];
  const getIcons = (
    index: number,
    indexThemes: number,
    topicLength: number
  ) => {
    const indexTheme = indexThemes > activeKey ? activeKey : indexThemes;
    return ["Pas du tout", "Un peu", "Bien", "Très bien", "Parfaitement"].map(
      (val, idx) => {
        return (
          <div
            data-tip={tooltips[idx]}
            onClick={() => {
              elementClicked(idx, index === topicLength - 1);
            }}
          >
            <svg
              width="22"
              height="21"
              viewBox="0 0 22 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.9999 17.5875L16.1874 20.725C17.1374 21.3 18.2999 20.45 18.0499 19.375L16.6749 13.475L21.2624 9.5C22.0999 8.775 21.6499 7.4 20.5499 7.3125L14.5124 6.8L12.1499 1.225C11.7249 0.212501 10.2749 0.212501 9.84991 1.225L7.48741 6.7875L1.44991 7.3C0.349908 7.3875 -0.100092 8.7625 0.737408 9.4875L5.32491 13.4625L3.94991 19.3625C3.69991 20.4375 4.86241 21.2875 5.81241 20.7125L10.9999 17.5875Z"
                fill={
                  idx <= selectedElement[indexTheme][index] &&
                  selectedElement[indexTheme][index] !== 1000
                    ? colors[selectedElement[indexTheme][index]]
                    : "#ECF0FB"
                }
              />
            </svg>
            <ReactTooltip />
          </div>
        );
      }
    );
  };
  const validateAnswer = () => {
    if (themes) {
      setValidateClicked(true);
      const reducer = (previousValue: number, currentValue: number) =>
        previousValue + currentValue;
      const total = themes.map(
        (value:any, index: number) =>
          +(
            (selectedElement[index].reduce(reducer) -
              1000 +
              (selectedElement[index].length - 1)) /
            (selectedElement[index].length - 1)
          ).toFixed(1)
      );
      setTotalAnswers(+(total.reduce(reducer) / themes.length).toFixed(1));
    }
  };
  return !validateClicked ? (
    <div className="center-container assessment">
      <Accordion defaultActiveKey={activeKey.toString()}>
        {themes &&
          themes.map((theme:any, idx: number) => {
            return (
              <Card key={idx}>
                {matches.large ? (
                  <Card.Header
                    className={
                      idx === activeKey && !isComplete
                        ? "accordion-header-active"
                        : idx < activeKey || isComplete
                        ? "accordion-header-completed"
                        : "accordion-header-inactive"
                    }
                  >
                    <div
                      className={
                        idx === activeKey && !isComplete
                          ? "eval-number"
                          : idx < activeKey || isComplete
                          ? "eval-number-completed"
                          : "eval-number-inactive"
                      }
                    >
                      {idx + 1}
                    </div>
                    <div
                      className={
                        idx === activeKey && !isComplete
                          ? "eval-title"
                          : idx < activeKey || isComplete
                          ? "eval-title-completed"
                          : "eval-title-inactive"
                      }
                    >
                      {theme.title}
                    </div>
                    {idx === activeKey && !isComplete && (
                      <div className="eval-progress">
                        <svg
                          width="265"
                          height="4"
                          viewBox="0 0 265 4"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            opacity="0.3"
                            width={"100%"}
                            height="4"
                            rx="2"
                            fill="#ECF0FB"
                          />
                          <rect
                            width={stepProgress}
                            height="4"
                            rx="2"
                            fill="white"
                          />
                        </svg>
                      </div>
                    )}
                    {(idx < activeKey || isComplete) && (
                      <div className="eval-progress">
                        <svg
                          width="15"
                          height="11"
                          viewBox="0 0 15 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.49994 8.49998L1.58327 5.58331C1.25827 5.25831 0.741602 5.25831 0.416602 5.58331C0.0916016 5.90831 0.0916016 6.42498 0.416602 6.74998L3.90827 10.2416C4.23327 10.5666 4.75827 10.5666 5.08327 10.2416L13.9166 1.41664C14.2416 1.09164 14.2416 0.574976 13.9166 0.249976C13.5916 -0.0750244 13.0749 -0.0750244 12.7499 0.249976L4.49994 8.49998Z"
                            fill="#3367CD"
                          />
                        </svg>

                        <div className="eval-completed">Complété</div>
                      </div>
                    )}
                  </Card.Header>
                ) : (
                  <Card.Header
                    className={
                      idx === activeKey && !isComplete
                        ? "accordion-header-active"
                        : idx < activeKey || isComplete
                        ? "accordion-header-completed"
                        : "accordion-header-inactive"
                    }
                    style={{
                      display: "block",
                      height: "100%",
                      textAlign: "start",
                    }}
                  >
                    <div className="d-flex" style={{ textAlign: "center" }}>
                      <div
                        className={
                          idx === activeKey && !isComplete
                            ? "eval-number"
                            : idx < activeKey || isComplete
                            ? "eval-number-completed"
                            : "eval-number-inactive"
                        }
                      >
                        {idx + 1}
                      </div>
                      {idx === activeKey && !isComplete && (
                        <div
                          className="eval-progress"
                          style={{ width: "80%", alignSelf: "center" }}
                        >
                          <svg
                            width="265"
                            height="4"
                            viewBox="0 0 265 4"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              opacity="0.3"
                              width={"100%"}
                              height="4"
                              rx="2"
                              fill="#ECF0FB"
                            />
                            <rect
                              width={stepProgress}
                              height="4"
                              rx="2"
                              fill="white"
                            />
                          </svg>
                        </div>
                      )}
                      {(idx < activeKey || isComplete) && (
                        <div
                          className="eval-progress"
                          style={{
                            alignSelf: "center",
                          }}
                        >
                          <svg
                            width="15"
                            height="11"
                            viewBox="0 0 15 11"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4.49994 8.49998L1.58327 5.58331C1.25827 5.25831 0.741602 5.25831 0.416602 5.58331C0.0916016 5.90831 0.0916016 6.42498 0.416602 6.74998L3.90827 10.2416C4.23327 10.5666 4.75827 10.5666 5.08327 10.2416L13.9166 1.41664C14.2416 1.09164 14.2416 0.574976 13.9166 0.249976C13.5916 -0.0750244 13.0749 -0.0750244 12.7499 0.249976L4.49994 8.49998Z"
                              fill="#3367CD"
                            />
                          </svg>

                          <div className="eval-completed">Complété</div>
                        </div>
                      )}
                    </div>
                    <div>
                      <div
                        className={
                          idx === activeKey && !isComplete
                            ? "eval-title"
                            : idx < activeKey || isComplete
                            ? "eval-title-completed"
                            : "eval-title-inactive"
                        }
                      >
                        {theme.title}
                      </div>
                    </div>
                  </Card.Header>
                )}
                <Accordion.Collapse
                  eventKey={idx.toString()}
                  className={idx <= activeKey ? "show" : ""}
                >
                  <Card.Body>
                    <div
                      className="info"
                      style={matches.large ? {} : { marginBottom: 15 }}
                    >
                      {" "}
                      De 1 à 5, estimez votre niveau de compréhension et de
                      maîtrise de chacun des sujets ci-dessous{" "}
                    </div>
                    {matches.large ? (
                      <div className="choice">
                        {theme.topics
                          .filter((topic:any) => topic.title !== null)
                          .map((choice:any, index: number) => {
                            return (
                              (choiceIndex >= index || idx < activeKey) && (
                                <div key={index} className="choice-element">
                                  {getIcons(
                                    index,
                                    idx,
                                    theme.topics.filter(
                                      (topic:any) => topic.title !== null
                                    ).length
                                  )}
                                  <div className="choice-title">
                                    {choice.title}
                                  </div>
                                </div>
                              )
                            );
                          })}
                      </div>
                    ) : (
                      <div className="choice">
                        {theme.topics
                          .filter((topic:any) => topic.title !== null)
                          .map((choice:any, index: number) => {
                            return (
                              (choiceIndex >= index || idx < activeKey) && (
                                <div
                                  style={{
                                    border: "1px solid #EBF0FA",
                                    padding: 12,
                                  }}
                                >
                                  <div className="choice-title">
                                    {choice.title}
                                  </div>
                                  <div key={index} className="choice-element">
                                    {getIcons(
                                      index,
                                      idx,
                                      theme.topics.filter(
                                        (topic:any) => topic.title !== null
                                      ).length
                                    )}
                                  </div>
                                </div>
                              )
                            );
                          })}
                      </div>
                    )}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            );
          })}
      </Accordion>

      {isComplete && (
        <div className="btn-valider" onClick={validateAnswer}>
          <div className="px-1">Valider mes réponses</div>
        </div>
      )}
    </div>
  ) : (
    <div className="container error-container">
      <Col md="12">
        <Row className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
          <Col md="8">
            <Card className="error-card">
              <div className="m-5 d-flex">
                {totalAnswers * 2 > 5 ? (
                  <div className="score-success">{totalAnswers}</div>
                ) : (
                  <div className="score-echec">{totalAnswers}</div>
                )}
                <div className="totale">/{5}</div>
              </div>
              {totalAnswers * 2 > 5 ? (
                <h5 className="score-msg" style={{ color: "#28282A" }}>
                  Vous semblez être plutôt à l’aise sur le sujet de formation !
                  Bravo !
                </h5>
              ) : (
                <h5 className="score-msg" style={{ color: "#28282A" }}>
                  Oups, vous semblez ne pas suffisament maîtriser le sujet. Nous
                  vous conseillons de reconsulter les documents.
                </h5>
              )}
              <Row className="d-flex flex-column align-items-center">
                <Button
                  className="btn-round-sm"
                  onClick={() => setStepG(1)}
                  color="primary"
                  type="submit"
                >
                  Revenir à l’éditeur
                </Button>
              </Row>
            </Card>
          </Col>
        </Row>
      </Col>
    </div>
  );
};

export default TrainingPreviewSelfAssessment;
