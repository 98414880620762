import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  FinishContent, FinishSection,
  selectTrainingLaunch,
  StartTraining,
} from "../../../../redux/slices/trainingSlice";
import { Button, Col, Row } from "reactstrap";
import { useMedia } from "react-media";
import {useHistory} from "react-router";
const TrainingNav = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const data = useAppSelector(selectTrainingLaunch);
  const indexSection = data.progression.currentStep
    ? data.sections.findIndex(section => section.id === data.progression.currentStep)
    : 0;
  const sectionData = data.sections[indexSection];
  const GLOBAL_MEDIA_QUERIES = {
    small: "(max-width: 991px)",
    large: "(min-width: 992px)",
  };
  const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
  const navElement = data.sections.map((val: any, idx: number) => {
    return (
      <div key={idx} className="d-flex flex-row nav-element">
        <div
          style={
            idx  === indexSection &&
            data.sections[idx].sectionStatus !== "invalid"
              ? { color: "#3367CD" }
              : idx > indexSection
              ? { color: "#BDBDBD" }
              : { color: "#28282A" }
          }
        >
          Etape {idx + 1}
        </div>
        <div className="icon">
          {idx  === indexSection && data.sections[idx].sectionStatus !== "invalid" && (
            <svg
              width="24"
              height="4"
              viewBox="0 0 24 4"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="24" height="4" rx="2" fill="#EBF0FA" />
              <rect width="12" height="4" rx="2" fill="#3367CD" />
            </svg>
          )}
          {idx  === indexSection && data.sections[idx].sectionStatus === "invalid" &&(
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle opacity="0.2" cx="8" cy="8" r="8" fill="#FF66A9" />
            <path
              d="M10.926 10.2208C10.9734 10.2677 11 10.3317 11 10.3983C11 10.465 10.9734 10.5289 10.926 10.5759L10.5759 10.926C10.5289 10.9734 10.465 11 10.3983 11C10.3317 11 10.2677 10.9734 10.2208 10.926L8 8.70525L5.77922 10.926C5.73226 10.9734 5.66834 11 5.60166 11C5.53498 11 5.47106 10.9734 5.4241 10.926L5.07398 10.5759C5.02663 10.5289 5 10.465 5 10.3983C5 10.3317 5.02663 10.2677 5.07398 10.2208L7.29475 8L5.07398 5.77922C5.02663 5.73226 5 5.66834 5 5.60166C5 5.53498 5.02663 5.47106 5.07398 5.4241L5.4241 5.07398C5.47106 5.02663 5.53498 5 5.60166 5C5.66834 5 5.73226 5.02663 5.77922 5.07398L8 7.29475L10.2208 5.07398C10.2677 5.02663 10.3317 5 10.3983 5C10.465 5 10.5289 5.02663 10.5759 5.07398L10.926 5.4241C10.9734 5.47106 11 5.53498 11 5.60166C11 5.66834 10.9734 5.73226 10.926 5.77922L8.70525 8L10.926 10.2208Z"
              fill="#FF66A9"
            />
          </svg>
          )}
          {
            idx  < indexSection &&  data.sections[idx].sectionStatus === "valid" &&(
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="8" cy="8" r="8" fill="#ECF0FB" />
                <path
                  d="M6.50055 10.0999L4.75055 8.34991C4.55555 8.15491 4.24555 8.15491 4.05055 8.34991C3.85555 8.54491 3.85555 8.85491 4.05055 9.04991L6.14555 11.1449C6.34055 11.3399 6.65555 11.3399 6.85055 11.1449L12.1505 5.84991C12.3455 5.65491 12.3455 5.34491 12.1505 5.14991C11.9555 4.95491 11.6455 4.95491 11.4505 5.14991L6.50055 10.0999Z"
                  fill="#3367CD"
                />
              </svg>
            )}
             {
            idx  < indexSection &&  data.sections[idx].sectionStatus === "invalid" &&(
                <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle opacity="0.2" cx="8" cy="8" r="8" fill="#FF66A9" />
                <path
                  d="M10.926 10.2208C10.9734 10.2677 11 10.3317 11 10.3983C11 10.465 10.9734 10.5289 10.926 10.5759L10.5759 10.926C10.5289 10.9734 10.465 11 10.3983 11C10.3317 11 10.2677 10.9734 10.2208 10.926L8 8.70525L5.77922 10.926C5.73226 10.9734 5.66834 11 5.60166 11C5.53498 11 5.47106 10.9734 5.4241 10.926L5.07398 10.5759C5.02663 10.5289 5 10.465 5 10.3983C5 10.3317 5.02663 10.2677 5.07398 10.2208L7.29475 8L5.07398 5.77922C5.02663 5.73226 5 5.66834 5 5.60166C5 5.53498 5.02663 5.47106 5.07398 5.4241L5.4241 5.07398C5.47106 5.02663 5.53498 5 5.60166 5C5.66834 5 5.73226 5.02663 5.77922 5.07398L8 7.29475L10.2208 5.07398C10.2677 5.02663 10.3317 5 10.3983 5C10.465 5 10.5289 5.02663 10.5759 5.07398L10.926 5.4241C10.9734 5.47106 11 5.53498 11 5.60166C11 5.66834 10.9734 5.73226 10.926 5.77922L8.70525 8L10.926 10.2208Z"
                  fill="#FF66A9"
                />
              </svg>
            )}
          {
            idx  > indexSection && (
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.0003 5.33342H11.3337V4.00008C11.3337 2.16008 9.84032 0.666748 8.00033 0.666748C6.16033 0.666748 4.66699 2.16008 4.66699 4.00008V5.33342H4.00033C3.26699 5.33342 2.66699 5.93341 2.66699 6.66675V13.3334C2.66699 14.0667 3.26699 14.6667 4.00033 14.6667H12.0003C12.7337 14.6667 13.3337 14.0667 13.3337 13.3334V6.66675C13.3337 5.93341 12.7337 5.33342 12.0003 5.33342ZM8.00033 11.3334C7.26699 11.3334 6.66699 10.7334 6.66699 10.0001C6.66699 9.26675 7.26699 8.66675 8.00033 8.66675C8.73366 8.66675 9.33366 9.26675 9.33366 10.0001C9.33366 10.7334 8.73366 11.3334 8.00033 11.3334ZM10.067 5.33342H5.93366V4.00008C5.93366 2.86008 6.86033 1.93341 8.00033 1.93341C9.14033 1.93341 10.067 2.86008 10.067 4.00008V5.33342Z"
                  fill="#BDBDBD"
                />
              </svg>
            )}
        </div>
      </div>
    );
  });
  const stringToDisplay = sectionData.sectionContent.length<=0  &&  data.sections[indexSection + 1] && data.sections.length>0 ? 'Etape suivante' : sectionData.sectionContent.length<=0 && !data.sections[indexSection + 1] ? 'Fermer et revenir à mon tableau de bord' :
      'Afficher le premier contenu';

  const handleClick = (condition:any) => {
    if ( stringToDisplay === 'Afficher le premier contenu') {
      dispatch(FinishContent({id:null, formationId: data.id}));
    } else  {
      dispatch(
          FinishSection({
            id: data.sections[indexSection].id,
          })
      );
      history.push( "/home");
    }
  };
  let style = {};
  let className = "";
  let svg;
  if ( stringToDisplay === 'Etape suivante' || stringToDisplay === 'Fermer et revenir à mon tableau de bord') {
    style = { paddingTop: 20 };
    className = 'd-flex justify-content-end';
    svg=     <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
      <path
          d="M2 11.5L2 12.5C2 12.7761 2.22386 13 2.5 13L18.67 13L14.22 17.44C14.1253 17.5339 14.0721 17.6617 14.0721 17.795C14.0721 17.9283 14.1253 18.0561 14.22 18.15L14.93 18.85C15.0239 18.9446 15.1517 18.9979 15.285 18.9979C15.4183 18.9979 15.5461 18.9446 15.64 18.85L21.78 12.72C21.9207 12.5795 21.9998 12.3888 22 12.19L22 11.81C21.9977 11.6116 21.9189 11.4217 21.78 11.28L15.64 5.14997C15.5461 5.05532 15.4183 5.00207 15.285 5.00207C15.1517 5.00207 15.0239 5.05532 14.93 5.14997L14.22 5.85997C14.1259 5.95214 14.0729 6.07828 14.0729 6.20997C14.0729 6.34166 14.1259 6.46781 14.22 6.55997L18.67 11L2.5 11C2.22386 11 2 11.2238 2 11.5Z"
          fill="#3367CD"
      />
    </svg>
  } else {
    style = { paddingTop: 20  };
    className = 'd-flex justify-content-center';
    svg=                  <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
      <path
          d="M12.72 15.78C12.5795 15.9207 12.3888 15.9999 12.19 16H11.81C11.6116 15.9977 11.4217 15.9189 11.28 15.78L6.14997 10.64C6.05532 10.5462 6.00208 10.4184 6.00208 10.285C6.00208 10.1517 6.05532 10.0239 6.14997 9.93004L6.85997 9.22004C6.95214 9.12598 7.07828 9.07297 7.20997 9.07297C7.34166 9.07297 7.46781 9.12598 7.55997 9.22004L12 13.67L16.44 9.22004C16.5339 9.12539 16.6617 9.07214 16.795 9.07214C16.9283 9.07214 17.0561 9.12539 17.15 9.22004L17.85 9.93004C17.9446 10.0239 17.9979 10.1517 17.9979 10.285C17.9979 10.4184 17.9446 10.5462 17.85 10.64L12.72 15.78Z"
          fill="#3367CD"
      />
    </svg>
  }
  const URL_REGEX = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;
  const words = sectionData?.sectionDescription?.split(' ');
  return (
    <div
      className="nav-wrapper"
      style={matches.small ? { paddingTop: "10%" } : {}}
    >
      <Col md="8" style={matches.small ? { marginLeft: 15 } : {}}>
        <Row className="d-flex preview-subject">
          <Col md={{ offset: 4 }}>
            <div className="d-flex pt-3">{navElement}</div>
          </Col>
        </Row>
        <Row className="d-flex preview-subject">
          <Col className="d-flex" md={{ offset: 4 }}>
            <div className="label-black-xxlg">{sectionData?.sectionTitle}</div>
          </Col>
        </Row>
        <Row className="d-flex preview-subject">
          <Col className="d-flex" md={{ offset: 4 }}>
            {sectionData?.duration && (
              <Col className="d-flex">
                <div className="label-grey-md">Durée estimée </div>
                <div className="label-black-nqt-sm">
                  : {sectionData?.duration} min
                </div>
              </Col>
            )}

            <Col className="d-flex">
              <div className="label-grey-md">Nombre de contenu </div>
              <div className="label-black-nqt-sm">
                : {sectionData?.sectionContent.length}
              </div>
            </Col>
          </Col>
        </Row>
        <Row>
          <hr className="line" />
        </Row>
        <Row className="d-flex preview-subject">
          <Col className="d-flex" md={{ offset: 4 }}>
            <svg
              width="18"
              height="15"
              viewBox="0 0 18 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 9V2C8 0.89543 7.10457 0 6 0H2C0.89543 0 0 0.89543 0 2V6C0 7.10457 0.89543 8 2 8H5V9C5.01856 9.96862 4.34023 10.8113 3.39 11C3.1683 11.0498 3.0081 11.2429 3 11.47V13.47C2.99257 13.6252 3.05492 13.7756 3.17 13.88C3.27637 13.9748 3.41874 14.0186 3.56 14C6.10071 13.7137 8.01608 11.5567 8 9ZM12 0H16C17.1046 0 18 0.89543 18 2V9C18.011 11.5529 16.0969 13.7041 13.56 13.99C13.4187 14.0086 13.2764 13.9648 13.17 13.87C13.0629 13.7725 13.0013 13.6348 13 13.49V11.49C12.9989 11.2554 13.1611 11.0516 13.39 11C14.3402 10.8113 15.0186 9.96862 15 9V8H12C10.8954 8 10 7.10457 10 6V2C10 0.89543 10.8954 0 12 0Z"
                fill="#3367CD"
              />
            </svg>
            <div className="label-black-md pl-10"> À propos de l’étape</div>
          </Col>
        </Row>
        <Row className="d-flex preview-subject">
          {sectionData?.sectionDescription && (
            <Col md={{ offset: 4 }}>
              {sectionData.sectionDescription.replace(/<br\s*\/?>/gm, "\r\n").split(/[\n]+/).map((item: any, idx: any) => (
                <div key={idx} className="label-grey-md pt-3">
                  {words.map((word) => {
                    return word.match(URL_REGEX) ? (
                        <>
                          <a href={word}>{word}</a>{' '}
                        </>
                    ) : (
                        word + ' '
                    );
                  })}
                  <br />
                </div>
              ))}
            </Col>
          )}
        </Row>
        {data.progression.currentStep === null && (
          <Row className="d-flex preview-subject">
            <Col className="d-flex" md={{ offset: 4 }}>
              <Button
                className="btn-round-sm validate mt-3"
                color="primary"
                onClick={() => {
                  dispatch(StartTraining({id:data.id}));
                }}
                type="submit"
              >
                Commencer
              </Button>
            </Col>
          </Row>
        )}
      </Col>
      {data.progression.currentStep !== null &&
      data.progression.currentContent === null && (
          <div
              className={className}
              style={style}
              onClick={() => {
                handleClick(stringToDisplay);
              }}
          >
            <div className="show-more"> {stringToDisplay}</div>
            <div>
              {svg}
            </div>
          </div>
      )}
    </div>
  );
};

export default TrainingNav;
