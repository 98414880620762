import { useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import Box from "../../../../components/widget/Box";
import { useMedia } from "react-media";
import TrainingPreviewContent from "./TrainingPreviewContent";
import { format, isToday } from "date-fns";
import { fr } from "date-fns/locale";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import NotificationAlert from "react-notification-alert";
import { FaFilePowerpoint, FaMicrophone } from "react-icons/fa";
import { AiFillVideoCamera } from "react-icons/ai";
import useHttp from "../../../../hooks/use-http";
import TrainingService from "../../../../services/training.services";
import { Modal } from "react-bootstrap";
import moment from "moment";

const TrainingPreviewSynchrone = (props: any) => {
  const { setStep, activeSection, setActiveSection, data } = props;
  const [modalShow, setModalShow] = useState(false);
  const [note, setNote] = useState("");
  const [motif, setMotif] = useState("");
  const notificationAlert = useRef();
  const { sendRequest, status } = useHttp(TrainingService.postNote);
  useEffect(() => {
    if (status === "completed") {
      //@ts-ignore
      notificationAlert.current.notificationAlert({
        place: "tr",
        message: <div>{"Votre note a bien été sauvegardée."}</div>,
        type: "success",
        icon: "nc-icon nc-bell-55",
        closeButton: false,
        autoDismiss: 7,
      });
    }
  }, [status]);
  const GLOBAL_MEDIA_QUERIES = {
    small: "(max-width: 991px)",
    large: "(min-width: 992px)",
  };
  const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
  if (data.typology !== "sync") {
    return (
      <TrainingPreviewContent
        setStep={setStep}
        activeSection={activeSection}
        setActiveSection={setActiveSection}
        data={data}
      ></TrainingPreviewContent>
    );
  }
  const sectionData = data?.eventDetails;
  let date = sectionData?.date;
  if(date && typeof(date) === "object")
    date = moment(sectionData.date).format("YYYY-MM-DD");

  const getIcons = (attachType: string) => {
    if (attachType === "pdf") {
      return (
        <BsFillFileEarmarkPdfFill
          size={50}
          color="#3367cd"
          className="ms-2 iconOutlined"
        />
      );
    }
    if (attachType === "mp3") {
      return (
        <FaMicrophone size={50} color="#3367cd" className="ms-2 iconOutlined" />
      );
    }
    if (attachType === "mp4") {
      return (
        <AiFillVideoCamera
          size={50}
          color="#3367cd"
          className="ms-2 iconOutlined"
        />
      );
    }
    if (attachType === "ppt" || attachType === "pptx") {
      return (
        <FaFilePowerpoint
          size={50}
          color="#3367cd"
          className="ms-2 iconOutlined"
        />
      );
    }
    return (
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.6001 36H10.4001C8.63276 36 7.20007 34.5673 7.20007 32.8V14.928C7.1994 14.0751 7.53921 13.2573 8.14408 12.656L15.8561 4.944C16.4574 4.33914 17.2752 3.99933 18.1281 4H29.6001C31.3674 4 32.8001 5.43269 32.8001 7.2V32.8C32.8001 34.5673 31.3674 36 29.6001 36ZM18.4001 13.6V6.912L10.1121 15.2H16.8001C17.6837 15.2 18.4001 14.4837 18.4001 13.6Z"
          fill="#3367CD"
        />
        <path
          d="M15.8083 25.55C15.8083 25.82 16.0243 26.036 16.2943 26.036C16.5643 26.036 16.7863 25.82 16.7863 25.55C16.7863 25.28 16.5643 25.058 16.2943 25.058C16.0243 25.058 15.8083 25.28 15.8083 25.55ZM17.2476 26H18.0456C18.0996 25.91 18.6456 25.112 18.7056 25.022L19.3836 26H20.1996L19.1376 24.512L20.1636 23.078H19.3716L18.7356 24.014C18.6816 23.93 18.1476 23.168 18.0936 23.078H17.2656L18.3096 24.524L17.2476 26ZM21.4038 26V21.656H20.7078V26H21.4038ZM22.0436 25.208C22.0796 25.538 22.4036 26.09 23.2496 26.09C23.9936 26.09 24.3536 25.598 24.3536 25.154C24.3536 24.722 24.0656 24.386 23.5136 24.266L23.0696 24.176C22.8896 24.14 22.7756 24.026 22.7756 23.87C22.7756 23.69 22.9496 23.534 23.1956 23.534C23.5856 23.534 23.7116 23.804 23.7356 23.972L24.3236 23.804C24.2756 23.516 24.0116 22.988 23.1956 22.988C22.5896 22.988 22.1216 23.42 22.1216 23.93C22.1216 24.332 22.3916 24.668 22.9076 24.782L23.3396 24.878C23.5736 24.926 23.6816 25.046 23.6816 25.202C23.6816 25.382 23.5316 25.538 23.2436 25.538C22.8716 25.538 22.6736 25.304 22.6496 25.04L22.0436 25.208Z"
          fill="white"
        />
      </svg>
    );
  };
  const filesPreview = sectionData.section.files.map(
    (content:any, index: number) => {
      if (content.type === "upload") {
        return (
          <div className="pb-3" key={index}>
            <Col className="preview-xls" md={{ offset: 2 }}>
              <div>{getIcons(content.fileType)}</div>
              <div className="d-grid">
                <span>Télécharger le fichier {content.fileType}</span>
                <span>{content.fileName}</span>
              </div>
              <a
                href={content.file}
                download={content.fileName}
                style={{ marginLeft: "auto" }}
              >
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15 20.0019C14.8343 20.0019 14.6759 19.9333 14.5625 19.8125L7.325 12.575C7.20668 12.4576 7.14013 12.2979 7.14013 12.1313C7.14013 11.9646 7.20668 11.8049 7.325 11.6875L7.575 11.4375C7.69259 11.3154 7.85552 11.2475 8.025 11.25H11.25V4.375C11.25 4.02982 11.5298 3.75 11.875 3.75H18.125C18.4702 3.75 18.75 4.02982 18.75 4.375V11.25H21.975C22.1445 11.2475 22.3074 11.3154 22.425 11.4375L22.675 11.6875C22.7933 11.8049 22.8599 11.9646 22.8599 12.1313C22.8599 12.2979 22.7933 12.4576 22.675 12.575L15.4375 19.8125C15.3241 19.9333 15.1657 20.0019 15 20.0019ZM23.75 24.375V23.125C23.75 22.7798 23.4702 22.5 23.125 22.5H6.875C6.52982 22.5 6.25 22.7798 6.25 23.125V24.375C6.25 24.7202 6.52982 25 6.875 25H23.125C23.4702 25 23.75 24.7202 23.75 24.375Z"
                    fill="#3367CD"
                  />
                </svg>
              </a>
            </Col>
          </div>
        );
      }
      return null;
    }
  );
  const getMeetingTool = () => {
    const url = sectionData.location.toLowerCase();
    return url.includes("meet.google")
      ? "Google Meet"
      : url.includes("skype")
      ? "Skype"
      : url.includes("teams")
      ? "Teams"
      : url.includes("zoom")
      ? "Zoom"
      : url.includes("slack")
      ? "Slack"
      : url.includes("whereby")
      ? "Whereby"
      : "";
  };
  return (
    <>
      {matches.large ? (
        <div className="preview-content-header d-flex">
          <Col md="8">
            <Row className="d-flex preview-title">
              <Col md={{ offset: 4 }}>
                <div className="label-white-xlg">{data?.title}</div>
              </Col>
            </Row>
            <Row className="d-flex preview-subject">
              <Col className="d-flex" md={{ offset: 4 }}>
                {data.subject.map((sub:any) => {
                  return (
                    <Box
                      key={sub.id}
                      content={sub.label}
                      background="#ECF0FB"
                      color="#3367CD"
                    ></Box>
                  );
                })}
              </Col>
            </Row>
            <Row className="d-flex preview-subject">
              <Col className="d-flex" md={{ offset: 4 }}>
                <svg
                  width="18"
                  height="15"
                  viewBox="0 0 18 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8 9V2C8 0.89543 7.10457 0 6 0H2C0.89543 0 0 0.89543 0 2V6C0 7.10457 0.89543 8 2 8H5V9C5.01856 9.96862 4.34023 10.8113 3.39 11C3.1683 11.0498 3.0081 11.2429 3 11.47V13.47C2.99257 13.6252 3.05492 13.7756 3.17 13.88C3.27637 13.9748 3.41874 14.0186 3.56 14C6.10071 13.7137 8.01608 11.5567 8 9ZM12 0H16C17.1046 0 18 0.89543 18 2V9C18.011 11.5529 16.0969 13.7041 13.56 13.99C13.4187 14.0086 13.2764 13.9648 13.17 13.87C13.0629 13.7725 13.0013 13.6348 13 13.49V11.49C12.9989 11.2554 13.1611 11.0516 13.39 11C14.3402 10.8113 15.0186 9.96862 15 9V8H12C10.8954 8 10 7.10457 10 6V2C10 0.89543 10.8954 0 12 0Z"
                    fill="#ECF0FB"
                  />
                </svg>
                <div className="label-white-lg pl-10">
                  {" "}
                  À propos de la formation
                </div>
              </Col>
            </Row>
            <Row className="d-flex preview-subject">
              <Col md={{ offset: 4 }}>
                {data?.description.replace(/<br\s*\/?>/gm, "\r\n").split(/[\n]+/).map((item: any, idx: any) => (
                  <p key={idx} className="label-white-md">
                    {item}
                    <br />
                  </p>
                ))}
              </Col>
            </Row>
          </Col>
          <Col md="4" className="d-flex">
            <Col>
              <div className="box">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0ZM10 3C11.6569 3 13 4.34315 13 6C13 7.65685 11.6569 9 10 9C8.34315 9 7 7.65685 7 6C7 4.34315 8.34315 3 10 3ZM10 16.9868C12.2151 16.9868 14.2994 15.9384 15.62 14.16C15.8246 13.8609 15.8514 13.4745 15.69 13.15L15.46 12.68C14.9568 11.6531 13.9136 11.0015 12.77 11H7.23C6.07012 11.0012 5.01488 11.671 4.52 12.72L4.31 13.16C4.15251 13.4818 4.17921 13.8633 4.38 14.16C5.70056 15.9384 7.78493 16.9868 10 16.9868Z"
                    fill="#3367CD"
                  />
                </svg>
                <div className="label-blue-md pt-3">Créateur</div>
                <div className="label-grey-md-normal">{`${data.creator?.firstName} ${data.creator?.lastName}`}</div>
              </div>
            </Col>
            <Col>
              <div className="box">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0ZM10 3C11.6569 3 13 4.34315 13 6C13 7.65685 11.6569 9 10 9C8.34315 9 7 7.65685 7 6C7 4.34315 8.34315 3 10 3ZM10 16.9868C12.2151 16.9868 14.2994 15.9384 15.62 14.16C15.8246 13.8609 15.8514 13.4745 15.69 13.15L15.46 12.68C14.9568 11.6531 13.9136 11.0015 12.77 11H7.23C6.07012 11.0012 5.01488 11.671 4.52 12.72L4.31 13.16C4.15251 13.4818 4.17921 13.8633 4.38 14.16C5.70056 15.9384 7.78493 16.9868 10 16.9868Z"
                    fill="#3367CD"
                  />
                </svg>
                <div className="label-blue-md pt-3">Entreprise</div>
                <div className="label-grey-md-normal">
                  {data.creator?.entreprise}
                </div>
              </div>
            </Col>
          </Col>
        </div>
      ) : (
        <div className="preview-content-header m-auto">
          <Row className="d-flex preview-title">
            <Col md={{ offset: 4 }}>
              <div className="label-white-xlg">{data?.title}</div>
            </Col>
          </Row>
          <Row className="d-flex preview-subject">
            <Col className="d-flex" md={{ offset: 4 }}>
              {data.subject.map((sub:any) => {
                return (
                  <Box
                    key={sub.id}
                    content={sub.label}
                    background="#ECF0FB"
                    color="#3367CD"
                  ></Box>
                );
              })}
            </Col>
          </Row>
          <Row className="d-flex preview-subject">
            <Col className="d-flex" md={{ offset: 4 }}>
              <svg
                width="18"
                height="15"
                viewBox="0 0 18 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8 9V2C8 0.89543 7.10457 0 6 0H2C0.89543 0 0 0.89543 0 2V6C0 7.10457 0.89543 8 2 8H5V9C5.01856 9.96862 4.34023 10.8113 3.39 11C3.1683 11.0498 3.0081 11.2429 3 11.47V13.47C2.99257 13.6252 3.05492 13.7756 3.17 13.88C3.27637 13.9748 3.41874 14.0186 3.56 14C6.10071 13.7137 8.01608 11.5567 8 9ZM12 0H16C17.1046 0 18 0.89543 18 2V9C18.011 11.5529 16.0969 13.7041 13.56 13.99C13.4187 14.0086 13.2764 13.9648 13.17 13.87C13.0629 13.7725 13.0013 13.6348 13 13.49V11.49C12.9989 11.2554 13.1611 11.0516 13.39 11C14.3402 10.8113 15.0186 9.96862 15 9V8H12C10.8954 8 10 7.10457 10 6V2C10 0.89543 10.8954 0 12 0Z"
                  fill="#ECF0FB"
                />
              </svg>
              <div className="label-white-lg pl-10">
                {" "}
                À propos de la formation
              </div>
            </Col>
          </Row>
          <Row className="d-flex preview-subject">
            <Col md={{ offset: 4 }}>
              {data?.description.replace(/<br\s*\/?>/gm, "\r\n").split(/[\n]+/).map((item: any, idx: any) => (
                <div key={idx} className="label-white-md">
                  {item}
                  <br />
                </div>
              ))}
            </Col>
          </Row>
          <Row className="d-flex preview-subject">
            <Col xs="6">
              <div className="box" style={{ top: "50%" }}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0ZM10 3C11.6569 3 13 4.34315 13 6C13 7.65685 11.6569 9 10 9C8.34315 9 7 7.65685 7 6C7 4.34315 8.34315 3 10 3ZM10 16.9868C12.2151 16.9868 14.2994 15.9384 15.62 14.16C15.8246 13.8609 15.8514 13.4745 15.69 13.15L15.46 12.68C14.9568 11.6531 13.9136 11.0015 12.77 11H7.23C6.07012 11.0012 5.01488 11.671 4.52 12.72L4.31 13.16C4.15251 13.4818 4.17921 13.8633 4.38 14.16C5.70056 15.9384 7.78493 16.9868 10 16.9868Z"
                    fill="#3367CD"
                  />
                </svg>
                <div className="label-blue-md pt-3">Créateur</div>
                <div className="label-grey-md-normal">{`${data.creator?.firstName} ${data.creator?.lastName}`}</div>
              </div>
            </Col>
            <Col xs="6">
              <div className="box" style={{ top: "50%" }}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0ZM10 3C11.6569 3 13 4.34315 13 6C13 7.65685 11.6569 9 10 9C8.34315 9 7 7.65685 7 6C7 4.34315 8.34315 3 10 3ZM10 16.9868C12.2151 16.9868 14.2994 15.9384 15.62 14.16C15.8246 13.8609 15.8514 13.4745 15.69 13.15L15.46 12.68C14.9568 11.6531 13.9136 11.0015 12.77 11H7.23C6.07012 11.0012 5.01488 11.671 4.52 12.72L4.31 13.16C4.15251 13.4818 4.17921 13.8633 4.38 14.16C5.70056 15.9384 7.78493 16.9868 10 16.9868Z"
                    fill="#3367CD"
                  />
                </svg>
                <div className="label-blue-md pt-3">Entreprise</div>
                <div className="label-grey-md-normal">
                  {data.creator?.entreprise}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      )}
      <div className="nav-wrapper" style={{ paddingTop: "5%" }}>
        <Col md="8" style={matches.small ? { marginLeft: 15 } : {}}>
          <Row className="d-flex preview-subject">
            <Col className="d-flex" md={{ offset: 4 }}>
              <Col className="d-flex">
                <div className="label-grey-md">Date : </div>
                <div className="label-blue-md">
                  {isToday(new Date(`${date.slice(0, 10)}`))
                    ? `Aujourd’hui de ${format(
                        new Date(
                          `${date.slice(0, 10)} ${
                            sectionData.startTimeHour
                          }:${sectionData.startTimeMinute}`
                        ),
                        "HH:mm",
                        {
                          locale: fr,
                        }
                      )} à ${format(
                        new Date(
                          `${date.slice(0, 10)} ${
                            sectionData.endTimeHour
                          }:${sectionData.endTimeMinute}`
                        ),
                        "HH:mm",
                        {
                          locale: fr,
                        }
                      )}`
                    : `Le ${format(
                        new Date(`${date.slice(0, 10)}`),
                        "dd/MM/yyyy",
                        {
                          locale: fr,
                        }
                      )} de ${format(
                        new Date(
                          `${date.slice(0, 10)} ${
                            sectionData.startTimeHour
                          }:${sectionData.startTimeMinute}`
                        ),
                        "HH:mm",
                        {
                          locale: fr,
                        }
                      )} à ${format(
                        new Date(
                          `${date.slice(0, 10)} ${
                            sectionData.endTimeHour
                          }:${sectionData.endTimeMinute}`
                        ),
                        "HH:mm",
                        {
                          locale: fr,
                        }
                      )}`}
                </div>
              </Col>
              <Col className="d-flex">
                <div className="label-grey-md">Nombre de participant </div>
                <div className="label-black-nqt-sm">: {data.subscribers}</div>
              </Col>
            </Col>
          </Row>
          <Row style={{ paddingTop: 30, paddingBottom: 30 }}>
            <hr className="line" />
          </Row>
          {sectionData.type === "dig" && (
            <>
              <Row className="d-flex preview-subject">
                <Col className="d-flex" md={{ offset: 4 }}>
                  <svg
                    width="18"
                    height="15"
                    viewBox="0 0 18 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8 9V2C8 0.89543 7.10457 0 6 0H2C0.89543 0 0 0.89543 0 2V6C0 7.10457 0.89543 8 2 8H5V9C5.01856 9.96862 4.34023 10.8113 3.39 11C3.1683 11.0498 3.0081 11.2429 3 11.47V13.47C2.99257 13.6252 3.05492 13.7756 3.17 13.88C3.27637 13.9748 3.41874 14.0186 3.56 14C6.10071 13.7137 8.01608 11.5567 8 9ZM12 0H16C17.1046 0 18 0.89543 18 2V9C18.011 11.5529 16.0969 13.7041 13.56 13.99C13.4187 14.0086 13.2764 13.9648 13.17 13.87C13.0629 13.7725 13.0013 13.6348 13 13.49V11.49C12.9989 11.2554 13.1611 11.0516 13.39 11C14.3402 10.8113 15.0186 9.96862 15 9V8H12C10.8954 8 10 7.10457 10 6V2C10 0.89543 10.8954 0 12 0Z"
                      fill="#3367CD"
                    />
                  </svg>
                  <div className="label-black-md pl-10">
                    {" "}
                    Lien pour lancer la visio
                  </div>
                </Col>
              </Row>
              <Row className="d-flex preview-subject">
                <Col className="d-grid" md={{ offset: 4 }}>
                  {sectionData.section?.sectionDescription.replace(/<br\s*\/?>/gm, "\r\n").split(/[\n]+/).map((item: any, idx: any) => (
                    <div key={idx} className="label-grey-md pt-3">
                      {item}
                      <br />
                    </div>
                  ))}
                  <Col className="zoom-container" md="4">
                    <div>
                      <div className="zoom-title">
                        Lancer le lien {getMeetingTool()}
                      </div>
                      <div className="zoom-url">{sectionData.location}</div>
                    </div>
                    <a
                      href={sectionData.location}
                      style={{ marginLeft: "auto" }}
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2 11.5L2 12.5C2 12.7761 2.22386 13 2.5 13L18.67 13L14.22 17.44C14.1253 17.5339 14.0721 17.6617 14.0721 17.795C14.0721 17.9283 14.1253 18.0561 14.22 18.15L14.93 18.85C15.0239 18.9446 15.1517 18.9979 15.285 18.9979C15.4183 18.9979 15.5461 18.9446 15.64 18.85L21.78 12.72C21.9207 12.5795 21.9998 12.3888 22 12.19L22 11.81C21.9977 11.6116 21.9189 11.4217 21.78 11.28L15.64 5.14997C15.5461 5.05532 15.4183 5.00207 15.285 5.00207C15.1517 5.00207 15.0239 5.05532 14.93 5.14997L14.22 5.85997C14.1259 5.95214 14.0729 6.07828 14.0729 6.20997C14.0729 6.34166 14.1259 6.46781 14.22 6.55997L18.67 11L2.5 11C2.22386 11 2 11.2238 2 11.5Z"
                          fill="#3367CD"
                        />
                      </svg>
                    </a>
                  </Col>
                </Col>
              </Row>
              <Row style={{ paddingTop: 30, paddingBottom: 30 }}>
                <hr className="line" />
              </Row>
            </>
          )}
          {sectionData.type === "pres" && (
            <>
              <Row className="d-flex preview-subject">
                <Col className="d-flex" md={{ offset: 4 }}>
                  <svg
                    width="16"
                    height="19"
                    viewBox="0 0 16 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 0C10.1217 0 12.1566 0.857906 13.6569 2.38499C15.1571 3.91207 16 5.98324 16 8.14286C16 11.514 13.4756 15.0462 8.53333 18.819C8.37947 18.9365 8.19233 19 8 19C7.80767 19 7.62053 18.9365 7.46667 18.819C2.52444 15.0462 0 11.514 0 8.14286C0 5.98324 0.842855 3.91207 2.34315 2.38499C3.84344 0.857906 5.87827 0 8 0ZM8 5.42857C7.29276 5.42857 6.61448 5.71454 6.11438 6.22357C5.61428 6.73259 5.33333 7.42298 5.33333 8.14286C5.33333 8.86273 5.61428 9.55312 6.11438 10.0621C6.61448 10.5712 7.29276 10.8571 8 10.8571C8.70724 10.8571 9.38552 10.5712 9.88562 10.0621C10.3857 9.55312 10.6667 8.86273 10.6667 8.14286C10.6667 7.42298 10.3857 6.73259 9.88562 6.22357C9.38552 5.71454 8.70724 5.42857 8 5.42857Z"
                      fill="#3367CD"
                    />
                  </svg>

                  <div className="label-black-md pl-10">
                    {" "}
                    Lieu de la formation
                  </div>
                </Col>
              </Row>
              <Row className="d-flex preview-subject">
                <Col className="d-grid" md={{ offset: 4 }}>
                  <div className="label-grey-md pt-3">
                    {sectionData.location}
                  </div>
                </Col>
              </Row>
              <Row style={{ paddingTop: 30, paddingBottom: 30 }}>
                <hr className="line" />
              </Row>
            </>
          )}
          <Row className="d-flex preview-subject">
            <Col className="d-flex" md={{ offset: 4 }}>
              <svg
                width="18"
                height="15"
                viewBox="0 0 18 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8 9V2C8 0.89543 7.10457 0 6 0H2C0.89543 0 0 0.89543 0 2V6C0 7.10457 0.89543 8 2 8H5V9C5.01856 9.96862 4.34023 10.8113 3.39 11C3.1683 11.0498 3.0081 11.2429 3 11.47V13.47C2.99257 13.6252 3.05492 13.7756 3.17 13.88C3.27637 13.9748 3.41874 14.0186 3.56 14C6.10071 13.7137 8.01608 11.5567 8 9ZM12 0H16C17.1046 0 18 0.89543 18 2V9C18.011 11.5529 16.0969 13.7041 13.56 13.99C13.4187 14.0086 13.2764 13.9648 13.17 13.87C13.0629 13.7725 13.0013 13.6348 13 13.49V11.49C12.9989 11.2554 13.1611 11.0516 13.39 11C14.3402 10.8113 15.0186 9.96862 15 9V8H12C10.8954 8 10 7.10457 10 6V2C10 0.89543 10.8954 0 12 0Z"
                  fill="#3367CD"
                />
              </svg>
              <div className="label-black-md pl-10">
                {" "}
                Contenus à télécharger
              </div>
            </Col>
          </Row>
        </Col>
      </div>
      <div className="preview-content">
        <Col md="8" style={matches.small ? { marginLeft: 15 } : {}}>
          {filesPreview}
        </Col>
      </div>
      {data.status === "pending" &&
        data.validation_status === "attente_notation" &&
        data.is_relecteur === true && (
          <div className="d-flex justify-content-center">
            {/** 
        //@ts-ignore */}
            <NotificationAlert ref={notificationAlert} />
            <Button
              className="btn-round-sm validate mt-3"
              color="primary"
              onClick={() => {
                setModalShow(true);
              }}
              type="submit"
            >
              Noter la formation
            </Button>
          </div>
        )}
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton style={{ borderBottom: 0 }}>
          <Row>
            <div className="label-black-xmd">Noter la formation</div>
            <div className="label-grey">
              Donnez une note à la formation que vous venez de relire.{" "}
            </div>
          </Row>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column ">
          <Row className="d-flex flex-column ">
            <Col className="d-flex ">
              <div
                className="note-box"
                onClick={() => setNote("A")}
                style={
                  note === "A"
                    ? {
                        color: "#3367CD",
                        border: "1px solid #3367CD",
                        background: "#ECF0FB",
                      }
                    : {}
                }
              >
                A
              </div>
              <div
                className="note-box"
                onClick={() => setNote("B")}
                style={
                  note === "B"
                    ? {
                        color: "#3367CD",
                        border: "1px solid #3367CD",
                        background: "#ECF0FB",
                      }
                    : {}
                }
              >
                B
              </div>
              <div
                className="note-box"
                onClick={() => setNote("C")}
                style={
                  note === "C"
                    ? {
                        color: "#3367CD",
                        border: "1px solid #3367CD",
                        background: "#ECF0FB",
                      }
                    : {}
                }
              >
                C
              </div>
              <div
                className="note-box"
                onClick={() => setNote("D")}
                style={
                  note === "D"
                    ? {
                        color: "#3367CD",
                        border: "1px solid #3367CD",
                        background: "#ECF0FB",
                      }
                    : {}
                }
              >
                D
              </div>
            </Col>
          </Row>
          <Row className="d-flex flex-column ">
            <Col className="d-flex flex-column ">
              {note === "A" && <p className="label-blue-md">“Validé.”</p>}
              {note === "B" && (
                <p className="label-blue-md">
                  “Publiable avec de légères modifications , et voici
                  lesquelles.”
                </p>
              )}
              {note === "C" && (
                <p className="label-blue-md">
                  “Publiable avec d’importantes modifications, et voici
                  lesquelles.”
                </p>
              )}
              {note === "D" && (
                <p className="label-blue-md">“Non publiable en l’état.”</p>
              )}
            </Col>
          </Row>
          {note !== "A" && (
            <Row className="d-flex flex-column ">
              <Col className="d-flex flex-column ">
                <div>
                  <textarea
                    onChange={(event) => setMotif(event.target.value)}
                    style={{ width: "100%", height: "84px" }}
                    placeholder="Pourquoi avez vous attribué cette note ?"
                  ></textarea>
                </div>
              </Col>
            </Row>
          )}
          <Row className="d-flex flex-column align-items-center">
            <Col className="d-flex justify-content-end">
              <Button
                className="btn-round-sm-secondary mt-3 me-2"
                onClick={() => setModalShow(false)}
                color="secondary"
                type="submit"
              >
                Revenir en arrière
              </Button>
              <Button
                disabled={
                  note === "" || (note !== "" && note !== "A" && motif === "")
                }
                className="btn-round-sm mt-3"
                onClick={() => {
                  sendRequest({ formationId: data.id, comment: motif, note });
                  setModalShow(false);
                }}
                color="primary"
                type="submit"
              >
                Envoyer
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TrainingPreviewSynchrone;
