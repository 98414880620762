import moment from "moment";
import { Col, Row } from "reactstrap";

const AgendaElement = ({ agendaEvent, link, isNqtCollaborator }: any) => {

  const hour = moment(agendaEvent.date_start).format("hh");
  const minute = moment(agendaEvent.date_start).format("mm");
  const day = moment(agendaEvent.date_start).format("DD");
  const month = moment(agendaEvent.date_start).format("MMM");

  return (
    <Row>
      <Col md="1" sm="1" xs="1">
        <div className="agenda">
          <Col md="12" className="calendar day">
            {day}
          </Col>
          <Col md="12" className="calendar month">
            {month.toLocaleUpperCase()}
          </Col>
          <Col md="12" className="calendar time">
            {hour + "h" + minute}
          </Col>
        </div>
      </Col>
      <Col md="9" sm="8" xs="7" className="px-5">
        <Row className="d-block">
          <Col>
            <div className="label-black-md"> {agendaEvent.name} </div>
          </Col>
          <Col>
            <div className="label-grey-trainings-sm"> {agendaEvent.name}</div>
          </Col>
        </Row>
      </Col>
      <Col md="2" sm="3" xs="4">
        <div className="pull-right label-primary-nqt-sm"
          onClick={() => {
            window.onbeforeunload = null;
            window.location.href = `${link}/${agendaEvent.id}${isNqtCollaborator ? "/details": ""}`
          }}>
            S’inscrire
            </div>
      </Col>
    </Row>
  );
};

export default AgendaElement;
