import { useEffect, useState } from "react";
import { Card, Col, Row } from "reactstrap";

import { ReactComponent as CodeIcon } from "../../../../assets/img/icons/code.svg";

const SyncCodeValidation = (props: any) => {
  const { setFieldValue, values } = props;

  const generateRandomString = () => {
    const list = "ABCDEFGHIJKLMNPQRSTUVWXYZ123456789";
    var res = "";
    for (var i = 0; i < 4; i++) {
      var rnd = Math.floor(Math.random() * list.length);
      res = res + list.charAt(rnd);
    }
    return res;
  };
  const checkCode = () =>
    values.code_validation
      ? values.code_validation.split("")
      : generateRandomString().split("");

  const [code] = useState(checkCode());

  useEffect(() => {
    setFieldValue("code_validation", code.join(""));
  }, [code, setFieldValue]);
  //Init props

  return (
    <Row className="center-container">
      <Col md="12">
        <Card body color="light" outline className="cardIntro mt-5 p-4">
          <Row className="d-flex flex-row align-items-center">
            <Col md="11" className="d-flex flex-row">
              <div className="iconOutlined mt-1">
                <CodeIcon />
              </div>
              <div className="d-flex flex-column">
                <p className="mb-0 ms-3 label-primary-nqt-md">
                  Code de validation
                </p>
                <p className="pt-1 ms-3 label-grey-normal">
                  Le code de validation permettra de valider la présence des
                  apprenants inscrits à la formation.
                </p>
              </div>
            </Col>
          </Row>

          <Row>
            <hr className="hr-text-grey p-0 mt-3"></hr>
          </Row>
          <Row>
            <Col md="6">
              <p className="label-black mt-3">Code de validation</p>
              <Row>
                <Col md="7" className="d-flex flex-row">
                  {code.map((item: string) => (
                    <p className="iconOutlined label-primary-nqt-md px-3 me-2">
                      {item}
                    </p>
                  ))}
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};
export default SyncCodeValidation;
