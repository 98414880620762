import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";

import TrainingIcon from "./TrainingIcon";
import { useAppSelector } from "../../../../redux/hooks";
import {
    getCreatedTrainings,
    selectNotPublishedTrainings,
} from "../../../../redux/slices/trainingSlice";
import moment from "moment";
//@ts-ignore
import EllipsisText from "react-ellipsis-text";
import { ReactComponent as ShowMoreIcon } from "../../../../assets/img/icons/showMoreIcon.svg";
import TrainingTable from "../../trainingCourses/components/TrainingTable";

function NonPublishedTrainings(props: any) {
  const { scrollable, dispatch } = props;
  const trainings = useAppSelector(selectNotPublishedTrainings);
  const [pageNumber, setPage] = useState(1);
  useEffect(() => {
    dispatch(
      getCreatedTrainings({
        page: pageNumber,
        formationStatus: "not published",
        tag: "notPublishedTrainings",
      })
    );
  }, [dispatch, pageNumber]);

    const columns = React.useMemo(
        () => [
            {
                Header: "",
                width: 80,
                accessor: "completedTrainingIcon",
                Cell: (props: any) => {
                    return props.row.original.status === "draft" ? (
                        <TrainingIcon
                            status={"draft"}
                            state={"NonPublished"}
                        ></TrainingIcon>
                    ) : props.row.original.status === "pending" ? (
                        <TrainingIcon
                            status={"pending"}
                            state={"NonPublished"}
                        ></TrainingIcon>
                    ) : props.row.original.status === "ongoing_review" ? (
                        <TrainingIcon
                            status={"ongoing_review"}
                            state={"NonPublished"}
                        ></TrainingIcon>
                    ): (
                        <TrainingIcon
                            status={"to_correct"}
                            state={"NonPublished"}
                        ></TrainingIcon>
                    );
                },
            },
            {
                Header: "Nom de formation",
                maximal: 200,
                accessor: "nom",
            },
            {
                Header: "Sujets",
                maximal: 185,
                accessor: 'sujets',
                Cell: (props: any) => {
                    return (
                        <EllipsisText text={props.row.original.sujets} length={"20"} />
                    )
                },
            },
            {
                Header: "Créateur",
                maximal: 185,
                accessor: "createur",
            },
            {
                Header: "Type",
                maximal: 185,
                accessor: (s: any) => {
                    const stat = s.status === "pending" ?  "En attente de validation" : s.status === "draft" ? "Brouillon" : s.status === "ongoing_review" ? "En attente de relecture" : "À corriger"
                    if (stat.length <= 20) {
                        return (stat);
                    } else {
                        return (stat.substring(0, 19) + "...")
                    }
                },
            },
            {
                Header: "Modifié le",
                maximal: 180,
                accessor: (d: any) => {
                    return moment(d.dateModif)
                        .local()
                        .format("DD/MM/YYYY")
                },
            },
        ],
        []
    );
    return (
        <Row style={{ marginLeft: "15px", paddingRight: "35px" }}>
            <Col className={scrollable ? "overflow-scroll" : ""}>
                <Row>
                    <TrainingTable data={trainings} columns={columns} />
                </Row>
                {(
                <Row>
                    <Col md="12">
                        <p
                            className="label-grey text-center pt-3 pointer"
                            onClick={() => {
                                let page = pageNumber + 1;
                                dispatch(
                                    getCreatedTrainings({
                                        page,
                                        formationStatus: "not published",
                                        tag: "notPublishedTrainings",
                                    })
                                );
                                setPage(page);
                            }}
                        >
                            Afficher plus
                            <ShowMoreIcon style={{ marginLeft: "10px" }} />
                        </p>
                    </Col>
                </Row>
                )}
            </Col>
        </Row>
  );
}

export default NonPublishedTrainings;
