import { Col } from "reactstrap";
import classes from "./BoxLaunch.module.css";
const BoxLaunch = (props: any) => {
  const { category, nbreCat } = props;
  return (
    <Col md={nbreCat > 1 && "5"} className={classes.box}>
      <Col className={classes.categories}>
        <div className={classes.content}>{category.label}</div>
      </Col>
      {category.subjects && category.subjects.map((subj: any, idx: number) => {
        return (
          <Col
            key={subj.id}
            className={
              idx === category.subjects.length - 1
                ? `${classes.subjects} ${classes.last}`
                : `${classes.subjects}`
            }
          >
            <div className={classes.content}>{subj.label}</div>
          </Col>
        );
      })}
    </Col>
  );
};

export default BoxLaunch;
