import { useHistory } from "react-router";
import { Col, Card, CardBody, Row } from "reactstrap";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  previewEditor,
  retrieveTrainingDetail,
  selectCreatedTrainings,
} from "../../../redux/slices/trainingSlice";
import TrainingElement from "./TrainingElement";
import TrainingIcon from "./TrainingIcon";

const CreatedTraining = () => {
  //Init hooks
  const training = useAppSelector(selectCreatedTrainings);
  const history = useHistory();
  const dispatch = useAppDispatch();

  /**
   * Navigate to details
   * @param {number} id
   */
  const navigateToDetails = (id: number) => {
    dispatch(retrieveTrainingDetail({ id }));
    history.push("/publishedTrainingDetail");
  };

  return (
    <Col lg="6" md="12">
      <Card body color="light" outline className="card p-0">
        <CardBody>
          <Row className="pb-3 w-100">
            <Col md="9" sm="9" xs="9">
              <div className="label-black-nqt-xmd"> Mes formations créées </div>
            </Col>
            <Col md="3" sm="3" xs="3">
              <div
                onClick={() => history.push("CreatedTrainings")}
                className="pull-right label-primary-nqt-sm show-more"
              >
                Tout voir
              </div>
            </Col>
          </Row>
          {training.map((val) => {
            return (
              <Row
                className="w-100 border-bottom pb-3 mt-2 pointer"
                key={val.id}
                onClick={() => {
                  if (val.status === "published") {
                    navigateToDetails(val.id);
                  } else {
                    dispatch(retrieveTrainingDetail({ id: val.id }));
                    history.push("/trainingPreview/" + val.id)
                  }
                }}
              >
                <TrainingElement
                  name={val.nom}
                  subject={val.sujets}
                  state={val.status}
                >
                  <TrainingIcon
                    type={"Created"}
                    percentage={0}
                    state={val.status}
                  ></TrainingIcon>
                </TrainingElement>
              </Row>
            );
          })}
        </CardBody>
      </Card>
    </Col>
  );
};

export default CreatedTraining;
