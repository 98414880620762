import { useCallback, useEffect, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { Button, Col, Form, Row } from "reactstrap";
import "../../../../styles/filters.scss";
import CreatorsFilters from "../../../training/trainingCatalog/components/CreatorsFilters";
import DateFilters from "../../../training/trainingCatalog/components/DateFilters";
import SubjectFilter from "../../../training/trainingCatalog/components/SubjectFilter";
import ContentsTypeFilter from "../../editorDashboard/components/ContentsTypeFilter";

const EditorPublishedTrainingsFilters = (props: any) => {
  const { setFilters, filtersData } = props;
  const [reset, setReset] = useState(false);

  const handleNameChange = useCallback((event: any) => {
    setFilters((prev: any) => {
      return { ...prev, nom: event };
    });
  }, []);
  const handleSujetsChange = useCallback((subjects: any) => {
    setFilters((prev: any) => {
      return { ...prev, sujets: subjects };
    });
  }, []);
  const handleCreatorsChange = useCallback((creators: any) => {
    setFilters((prev: any) => {
      return { ...prev, createurs: creators };
    });
  }, []);
  const handleDatePubChange = useCallback((datePub: any) => {
    setFilters((prev: any) => {
      return { ...prev, datePub };
    });
  }, []);
  const handleDateTenuChange = useCallback((dateTenu: any) => {
    setFilters((prev: any) => {
      return { ...prev, dateTenu };
    });
  }, []);
  const handleContentChange = useCallback((content: any) => {
    setFilters((prev: any) => {
      return { ...prev, contenu: content };
    });
  }, []);


  const [name, setName] = useState<string>("");

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleNameChange(name);
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [name, handleNameChange]);

  return (
    <Form>
      <Row>
        <Col lg="12">
          <AiOutlineSearch
            className="formSearchIcon"
            size={17}
            color="#bdbdbd"
          ></AiOutlineSearch>
          <input
            name="title"
            placeholder="Rechercher une formation"
            className="formControl label-search"
            onChange={(e) => setName(e.target.value.trim().toLowerCase())}
          />
        </Col>
      </Row>
      <Row className="my-3">
        <Col md="2">
          <SubjectFilter
            handleChange={handleSujetsChange}
            reset={reset}
          ></SubjectFilter>
        </Col>
        <Col md="2">
          <CreatorsFilters
            handleChange={handleCreatorsChange}
            reset={reset}
          ></CreatorsFilters>
        </Col>
        <Col md="2">
          <ContentsTypeFilter
            handleChange={handleContentChange}
            reset={reset}
          ></ContentsTypeFilter>
        </Col>
        <Col md="2">
          <DateFilters
            handlePubChange={handleDatePubChange}
            handleTenuChange={handleDateTenuChange}
            reset={reset}
            setReset={setReset}
          ></DateFilters>
        </Col>
        <Col md="2">
          <Button
            className="btn-round-sm-secondary ms-2 validate mt-0"
            color="primary"
            onClick={() => {
              setReset(true);
              setFilters(filtersData);
            }}
          >
            Réinitialiser
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default EditorPublishedTrainingsFilters;
