//@ts-ignore
import {
  useTable,
  useResizeColumns,
  useFlexLayout,
  useRowSelect,
  useSortBy,
} from "react-table";
import tw from "twin.macro";
import { Button, Col, Row, Spinner } from "reactstrap";

import { ReactComponent as DescIcon } from "../../../../assets/img/icons/Desc.svg";
import { ReactComponent as AscIcon } from "../../../../assets/img/icons/Asc.svg";
import "../../../../styles/catalog.scss";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { selectTrainingLoading } from "../../../../redux/slices/trainingSlice";
import "../../../../styles/catalog.scss";
import {
  editorActions,
  selectTraining,
} from "../../../../redux/slices/editorSlice";
import { TrainingNeedsProofReading } from "../../../../config/training";
import { ReactComponent as AvatarIcon } from '../../../../assets/img/icons/avatar-person.svg'

const Table = tw.table`
`;

const TableHead = tw.thead`
  p-4
`;

const TableRow = tw.tr`
p-2
`;

const TableHeader = tw.th`
`;

const TableBody = tw.tbody`
`;

const TableData = tw.td`
p-5
`;

const TrainingTable = (props: any) => {
  const { data = props.data, columns = props.columns } = { ...props };
  const { matches, tag, submitAssign , cancelAssign} = props;
  const loading = useAppSelector(selectTrainingLoading);
  const training: TrainingNeedsProofReading = useAppSelector(selectTraining);
  const tableInstance = useTable(
    { columns, data },
    useResizeColumns,
    useFlexLayout,
    useSortBy,
    useRowSelect
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  const dispatch = useAppDispatch();
  const getRelectures = () => {
    const elements = training.reviewers
      ? training.reviewers.map((relecteur: any, idx: number) =>
          relecteur && relecteur.isRelecteur === undefined ? (
            <>
              {relecteur.image ? (
                <img
                  alt="profile"
                  className="avatar-profile"
                  style={{ maxWidth: 32 }}
                  src={relecteur.image}
                />
              ) : (
                <AvatarIcon
                  style={{ width: 32, height: 32, borderRadius: '50%' }}
                />
              )}
              <svg
                onClick={() => dispatch(editorActions.updateTrainingReviewers(
                  relecteur
                ))}
                style={{ position: "relative", right: 12, top: -11 }}
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="8.5" cy="8.5" r="8.5" fill="#ECF0FB" />
                <path
                  d="M11.6686 10.9049C11.7199 10.9558 11.7487 11.025 11.7487 11.0972C11.7487 11.1694 11.7199 11.2386 11.6686 11.2895L11.2894 11.6687C11.2386 11.7199 11.1693 11.7488 11.0971 11.7488C11.0249 11.7488 10.9557 11.7199 10.9048 11.6687L8.49984 9.26365L6.09484 11.6687C6.04399 11.7199 5.97476 11.7488 5.90255 11.7488C5.83033 11.7488 5.76111 11.7199 5.71026 11.6687L5.33109 11.2895C5.27982 11.2386 5.25098 11.1694 5.25098 11.0972C5.25098 11.025 5.27982 10.9558 5.33109 10.9049L7.73609 8.4999L5.33109 6.0949C5.27982 6.04405 5.25098 5.97482 5.25098 5.90261C5.25098 5.83039 5.27982 5.76117 5.33109 5.71032L5.71026 5.33115C5.76111 5.27988 5.83033 5.25104 5.90255 5.25104C5.97476 5.25104 6.04399 5.27988 6.09484 5.33115L8.49984 7.73615L10.9048 5.33115C10.9557 5.27988 11.0249 5.25104 11.0971 5.25104C11.1693 5.25104 11.2386 5.27988 11.2894 5.33115L11.6686 5.71032C11.7199 5.76117 11.7487 5.83039 11.7487 5.90261C11.7487 5.97482 11.7199 6.04405 11.6686 6.0949L9.26359 8.4999L11.6686 10.9049Z"
                  fill="#3367CD"
                />
              </svg>
            </>
          ) :  relecteur.image ? (
            <img
              alt="profile"
              className="avatar-profile"
              style={{ maxWidth: 32 }}
              src={relecteur.image}
            />
          ) : (
            <AvatarIcon
              style={{ width: 32, height: 32, borderRadius: '50%' }}
            />
          ),
        )
      : [];
    while (elements.length < 5) {
      elements.push(
        <svg
        style={{position: "relative", top:-6}}
          width="32"
          height="33"
          viewBox="0 0 32 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <ellipse cx="16" cy="16.2941" rx="16" ry="15.9059" fill="#ECF0FB" />
          <path
            d="M17.31 20.22C17.292 20.112 17.292 20.022 17.292 19.986C17.292 19.284 17.598 18.69 18.192 18.276L18.966 17.736C20.01 16.998 20.766 15.99 20.766 14.568C20.766 12.732 19.326 10.968 16.662 10.968C13.944 10.968 12.594 12.93 12.594 14.73C12.594 15.018 12.63 15.306 12.702 15.558L14.736 15.702C14.664 15.504 14.628 15.162 14.628 14.892C14.628 13.866 15.33 12.84 16.662 12.84C17.976 12.84 18.606 13.722 18.606 14.658C18.606 15.27 18.354 15.792 17.76 16.224L16.932 16.818C15.888 17.574 15.474 18.456 15.474 19.536C15.474 19.788 15.492 19.986 15.51 20.22H17.31ZM15.114 22.812C15.114 23.532 15.69 24.126 16.428 24.126C17.148 24.126 17.742 23.532 17.742 22.812C17.742 22.092 17.148 21.48 16.428 21.48C15.69 21.48 15.114 22.092 15.114 22.812Z"
            fill="#3367CD"
          />
        </svg>
      );
    }
    return elements;
  };

  return (
    <Table style={{ borderRadius: 10 }} {...getTableProps()}>
      <TableHead>
        {headerGroups.map((headerGroup: any, index: number) => (
          <TableRow
            className="pb-0 mt-0"
            {...headerGroup.getHeaderGroupProps()}
          >
            {headerGroup.headers.map((column: any, key: number) => {
              return (
                <TableHeader
                  className={
                    column.isSorted
                      ? "label-black-nqt-normal"
                      : "label-grey-nqt-normal"
                  }
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  <div className={"d-flex flex-row"}>
                    <p
                      className={
                        column.render("Header") === "Nom du parrain"
                          ? "ps-5"
                          : "ps-2"
                      }
                    >
                      {column.render("Header")}
                    </p>
                    {key !== 4 &&
                      (column.isSorted ? (
                        column.isSortedDesc ? (
                          <div className="filter-icons-div">
                            <DescIcon fill="28282A" />
                          </div>
                        ) : (
                          <div className="filter-icons-div">
                            <AscIcon
                              fill="28282A"
                              style={{ marginBottom: "10px" }}
                            />
                          </div>
                        )
                      ) : (
                        <div className="filter-icons-div">
                          <AscIcon fill="grey" className="filter-icons" />
                          <DescIcon fill="grey" />
                        </div>
                      ))}
                  </div>
                </TableHeader>
              );
            })}
          </TableRow>
        ))}
      </TableHead>
      {tag === "assign" && (
        <Row>
          <Col ms="12" className="d-flex flex-row">
            <Col ms="4">{getRelectures()}</Col>
            <Col ms="8">
              <div
                className={matches.large ? "float-end" : "float-center mb-5"}
              >
                <Button
                  className="btn-round-sm-secondary me-2 p-2 px-3"
                  uppercase={false}
                  color="secondary"
                  type="submit"
                  onClick={()=>cancelAssign()}
                >
                  Tout déselectionner
                </Button>

                <Button
                  className="btn-round-sm validate p-2 px-3"
                  color="primary"
                  onClick={() => submitAssign()}
                  type="submit"
                >
                  Valider les assignations
                </Button>
              </div>
            </Col>
          </Col>
        </Row>
      )}
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: 600 }}
        >
          <Spinner animation="border" style={{ color: "#3367cd" }} />
        </div>
      ) : (
        <TableBody {...getTableBodyProps()}>
          {rows.map((row: any) => {
            prepareRow(row);
            return (
              <TableRow
                className="catalog-table-body table-row ps-4 mb-2 align-items-center pointer"
                {...row.getRowProps()}
               
              >
                {row.cells.map((cell: any, idx: any) => {
                  return (
                    <TableData
                      className="p-0 label-grey-normal"
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </TableData>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      )}
    </Table>
  );
};

export default TrainingTable;
