import {Col, Row} from "reactstrap";
import ModalConfirmation from "../../../../components/forms/ModalConfirmation";
import {useEffect, useState} from "react";

const Stepbar = (props: any) => {
    //Init props
    const {navStep, handlePagination} = props;
    const [isConfirmationModalVisible, setConfirmationModalVisible] = useState(false)
    useEffect(() => {
        window.onbeforeunload = function () {
            return "Leaving this page will reset the wizard";
        };
    }, [])
    return (
        <>
            <Row className="mb-3 fixed-top bg-white">
                <Col md="1" sm="2" xs="3" className="center-col" onClick={() => handlePagination(navStep - 1)}>
                    <svg
                        width="20"
                        height="14"
                        viewBox="0 0 20 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M20 6.5001V7.5001C20 7.77624 19.7761 8.0001 19.5 8.0001H3.33L7.78 12.4401C7.87466 12.534 7.9279 12.6618 7.9279 12.7951C7.9279 12.9284 7.87466 13.0562 7.78 13.1501L7.07 13.8501C6.97612 13.9448 6.84832 13.998 6.715 13.998C6.58168 13.998 6.45388 13.9448 6.36 13.8501L0.22 7.7201C0.0793075 7.57958 0.000175052 7.38894 0 7.1901V6.8101C0.00230401 6.61168 0.081116 6.42182 0.22 6.2801L6.36 0.150096C6.45388 0.0554401 6.58168 0.00219727 6.715 0.00219727C6.84832 0.00219727 6.97612 0.0554401 7.07 0.150096L7.78 0.860096C7.87406 0.95226 7.92707 1.07841 7.92707 1.2101C7.92707 1.34179 7.87406 1.46793 7.78 1.5601L3.33 6.0001H19.5C19.7761 6.0001 20 6.22395 20 6.5001Z"
                            fill="#3367CD"
                        />
                    </svg>
                </Col>
                <Col md="8" sm="7" xs="6" className="center-col" >
                    <svg onClick={() => handlePagination(1)}
                        width="32"
                        height="4"
                        viewBox="0 0 32 4"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <rect width="32" height="4" rx="2" fill={navStep >= 1 ? "#3367CD" : "#EBF0FA"}/>
                    </svg>
                    <svg onClick={() => handlePagination(2)}
                        width="32"
                        height="4"
                        viewBox="0 0 32 4"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <rect width="32" height="4" rx="2" fill={navStep >= 2 ? "#3367CD" : "#EBF0FA"}/>
                    </svg>
                    <svg onClick={() => handlePagination(3)}
                        width="32"
                        height="4"
                        viewBox="0 0 32 4"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <rect width="32" height="4" rx="2" fill={navStep >= 3 ? "#3367CD" : "#EBF0FA"}/>
                    </svg>
                </Col>
                <Col md="2" sm="2" xs="3" className="center-col">
                    <p
                        className="label-grey-nqt pt-2 pointer"
                        onClick={() => setConfirmationModalVisible(true)}
                    >
                        PLUS TARD
                    </p>
                </Col>
            </Row>
            <ModalConfirmation
                confirm={() => window.open("https://app.nqt.fr/signin", "_self")}
                cancel={() => setConfirmationModalVisible(false)}
                title={"Voulez vous vraiment quitter et revenir plus tard ?"}
                subTitle={"En quittant la page vous allez etre redirigé vers la page de login."}
                isVisible={isConfirmationModalVisible}/>
        </>
    );
};
export default Stepbar;
