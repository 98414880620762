import { Fragment, useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import { Modal } from "react-bootstrap";
import { CKEditor } from "ckeditor4-react";
import Box from "../../../../components/widget/Box";
import Nav from "react-bootstrap/Nav";
import Audio from "../../../../components/widget/Audio";
import { Worker } from "@phuocng/react-pdf-viewer";
import SlidePresentationExample from "./SlidePresentationExample";
import NotificationAlert from "react-notification-alert";
import { useMedia } from "react-media";
import TrainingService from "../../../../services/training.services";
import useHttp from "../../../../hooks/use-http";
import company from "../../../../assets/img/icons/comany.png";
import GamificationModal from "../../../../components/GamificationModal";
import ReactPlayer from "react-player";

const TrainingPreviewContent = (props: any) => {
  const { setStep, activeSection, setActiveSection, data } = props;
  const sectionData = data?.sections[activeSection];
  const [modalShow, setModalShow] = useState(false);
  const [popupShow, setPopupShow] = useState(false);
  const [note, setNote] = useState("");
  const [motif, setMotif] = useState("");
  const GLOBAL_MEDIA_QUERIES = {
    small: "(max-width: 991px)",
    large: "(min-width: 992px)",
  };
  const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
  const notificationAlert = useRef();
  const {
    sendRequest,
    status,
    data: popupData,
  } = useHttp(TrainingService.postNote);
  useEffect(() => {
    if (status === "completed") {
      //@ts-ignore
      notificationAlert.current.notificationAlert({
        place: "tr",
        message: <div>{"Votre note a bien été sauvegardée."}</div>,
        type: "success",
        icon: "nc-icon nc-bell-55",
        closeButton: false,
        autoDismiss: 7,
      });
      setPopupShow(true);
    }
  }, [status]);
  const navElement = data?.sections.map((val: any, idx: number) => {
    return (
      <Nav.Link
        key={idx}
        eventKey={idx.toString()}
        className="navLink d-flex flex-row pb-4"
      >
        <p className="mt-1">Etape {idx + 1}</p>
      </Nav.Link>
    );
  });
  const base64toBlob = (file: string) => {
    var byteString = atob(file.split(",")[1]);
    var mimeString = file.split(",")[0].split(":")[1].split(";")[0];

    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    var blob = new Blob([ab], { type: mimeString });

    return blob;

  };
  return (
    <Fragment>
      <GamificationModal
        show={popupShow}
        onHide={() => setPopupShow(false)}
        data={popupData && popupData.popUpInformation}
      />
      {matches.large ? (
        <div className="preview-content-header d-flex">
          <Col md="8">
            <Row className="d-flex preview-title">
              <Col md={{ offset: 4 }}>
                <div className="label-white-xlg">{data?.title}</div>
              </Col>
            </Row>
            <Row className="d-flex preview-subject">
              <Col className="d-flex" md={{ offset: 4 }}>
                {data.subject.map((sub:any) => {
                  return (
                    <Box
                      key={sub.id}
                      content={sub.label}
                      background="#ECF0FB"
                      color="#3367CD"
                    ></Box>
                  );
                })}
              </Col>
            </Row>
            <Row className="d-flex preview-subject">
              <Col className="d-flex" md={{ offset: 4 }}>
                <svg
                  width="18"
                  height="15"
                  viewBox="0 0 18 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8 9V2C8 0.89543 7.10457 0 6 0H2C0.89543 0 0 0.89543 0 2V6C0 7.10457 0.89543 8 2 8H5V9C5.01856 9.96862 4.34023 10.8113 3.39 11C3.1683 11.0498 3.0081 11.2429 3 11.47V13.47C2.99257 13.6252 3.05492 13.7756 3.17 13.88C3.27637 13.9748 3.41874 14.0186 3.56 14C6.10071 13.7137 8.01608 11.5567 8 9ZM12 0H16C17.1046 0 18 0.89543 18 2V9C18.011 11.5529 16.0969 13.7041 13.56 13.99C13.4187 14.0086 13.2764 13.9648 13.17 13.87C13.0629 13.7725 13.0013 13.6348 13 13.49V11.49C12.9989 11.2554 13.1611 11.0516 13.39 11C14.3402 10.8113 15.0186 9.96862 15 9V8H12C10.8954 8 10 7.10457 10 6V2C10 0.89543 10.8954 0 12 0Z"
                    fill="#ECF0FB"
                  />
                </svg>
                <div className="label-white-lg pl-10">
                  {" "}
                  À propos de la formation
                </div>
              </Col>
            </Row>
            <Row className="d-flex preview-subject">
              <Col md={{ offset: 4 }}>
                {data?.description.replace(/<br\s*\/?>/gm, "\r\n").split(/[\n]+/).map((item: any, idx: any) => (
                  <div key={idx} className="label-white-md">
                    {item}
                    <br />
                  </div>
                ))}
              </Col>
            </Row>
          </Col>
          {data.status === "draft" && (
            <Col md="4" className="d-flex">
              <Col>
                <div className="box">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0ZM10 3C11.6569 3 13 4.34315 13 6C13 7.65685 11.6569 9 10 9C8.34315 9 7 7.65685 7 6C7 4.34315 8.34315 3 10 3ZM10 16.9868C12.2151 16.9868 14.2994 15.9384 15.62 14.16C15.8246 13.8609 15.8514 13.4745 15.69 13.15L15.46 12.68C14.9568 11.6531 13.9136 11.0015 12.77 11H7.23C6.07012 11.0012 5.01488 11.671 4.52 12.72L4.31 13.16C4.15251 13.4818 4.17921 13.8633 4.38 14.16C5.70056 15.9384 7.78493 16.9868 10 16.9868Z"
                      fill="#3367CD"
                    />
                  </svg>
                  <div className="label-blue-md pt-3">Créateur</div>
                  <div className="label-grey-md-normal">{`${data.creator?.firstName} ${data.creator?.lastName}`}</div>
                </div>
              </Col>
              <Col>
                <div className="box">
                  <img
                    src={company}
                    alt={"logo"}
                    style={{ width: 20, height: 20 }}
                  />
                  <div className="label-blue-md pt-3">Entreprise</div>
                  <div className="label-grey-md-normal">
                    {data.creator?.entreprise}
                  </div>
                </div>
              </Col>
            </Col>
          )}
        </div>
      ) : (
        <div className="preview-content-header m-auto">
          <Row className="d-flex preview-title">
            <Col md={{ offset: 4 }}>
              <div className="label-white-xlg">{data?.title}</div>
            </Col>
          </Row>
          <Row className="d-flex preview-subject">
            <Col className="d-flex" md={{ offset: 4 }}>
              {data.subject.map((sub:any) => {
                return (
                  <Box
                    key={sub.id}
                    content={sub.label}
                    background="#ECF0FB"
                    color="#3367CD"
                  ></Box>
                );
              })}
            </Col>
          </Row>
          <Row className="d-flex preview-subject">
            <Col className="d-flex" md={{ offset: 4 }}>
              <svg
                width="18"
                height="15"
                viewBox="0 0 18 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8 9V2C8 0.89543 7.10457 0 6 0H2C0.89543 0 0 0.89543 0 2V6C0 7.10457 0.89543 8 2 8H5V9C5.01856 9.96862 4.34023 10.8113 3.39 11C3.1683 11.0498 3.0081 11.2429 3 11.47V13.47C2.99257 13.6252 3.05492 13.7756 3.17 13.88C3.27637 13.9748 3.41874 14.0186 3.56 14C6.10071 13.7137 8.01608 11.5567 8 9ZM12 0H16C17.1046 0 18 0.89543 18 2V9C18.011 11.5529 16.0969 13.7041 13.56 13.99C13.4187 14.0086 13.2764 13.9648 13.17 13.87C13.0629 13.7725 13.0013 13.6348 13 13.49V11.49C12.9989 11.2554 13.1611 11.0516 13.39 11C14.3402 10.8113 15.0186 9.96862 15 9V8H12C10.8954 8 10 7.10457 10 6V2C10 0.89543 10.8954 0 12 0Z"
                  fill="#ECF0FB"
                />
              </svg>
              <div className="label-white-lg pl-10">
                {" "}
                À propos de la formation
              </div>
            </Col>
          </Row>
          <Row className="d-flex preview-subject">
            <Col md={{ offset: 4 }}>
              {data?.description.replace(/<br\s*\/?>/gm, "\r\n").split(/[\n]+/).map((item: any, idx: any) => (
                <div key={idx} className="label-white-md">
                  {item}
                  <br />
                </div>
              ))}
            </Col>
          </Row>
          {data.status === "draft" && (
            <Row className="d-flex preview-subject">
              <Col xs="6">
                <div className="box" style={{ top: "50%" }}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0ZM10 3C11.6569 3 13 4.34315 13 6C13 7.65685 11.6569 9 10 9C8.34315 9 7 7.65685 7 6C7 4.34315 8.34315 3 10 3ZM10 16.9868C12.2151 16.9868 14.2994 15.9384 15.62 14.16C15.8246 13.8609 15.8514 13.4745 15.69 13.15L15.46 12.68C14.9568 11.6531 13.9136 11.0015 12.77 11H7.23C6.07012 11.0012 5.01488 11.671 4.52 12.72L4.31 13.16C4.15251 13.4818 4.17921 13.8633 4.38 14.16C5.70056 15.9384 7.78493 16.9868 10 16.9868Z"
                      fill="#3367CD"
                    />
                  </svg>
                  <div className="label-blue-md pt-3">Créateur</div>
                  <div className="label-grey-md-normal">{`${data.creator?.firstName} ${data.creator?.lastName}`}</div>
                </div>
              </Col>
              <Col xs="6">
                <div className="box" style={{ top: "50%" }}>
                  <img
                    src={company}
                    alt={"logo"}
                    style={{ width: 20, height: 20 }}
                  />
                  <div className="label-blue-md pt-3">Entreprise</div>
                  <div className="label-grey-md-normal">
                    {data.creator?.entreprise}
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </div>
      )}
      <div
        className="nav-wrapper"
        style={matches.small ? { paddingTop: "10%" } : {}}
      >
        <Col md="8" style={matches.small ? { marginLeft: 15 } : {}}>
          <Row className="d-flex preview-subject">
            <Col md={{ offset: 4 }}>
              <Nav
                activeKey={activeSection}
                onSelect={(selectedKey) =>
                  selectedKey && setActiveSection(+selectedKey)
                }
                className="d-flex pt-3"
              >
                {navElement}
              </Nav>
            </Col>
          </Row>
          <Row className="d-flex preview-subject">
            <Col className="d-flex" md={{ offset: 4 }}>
              <div className="label-black-xxlg">
                {sectionData?.sectionTitle}
              </div>
            </Col>
          </Row>
          <Row className="d-flex preview-subject">
            <Col className="d-flex" md={{ offset: 4 }}>
              {sectionData?.duration && (
                <Col className="d-flex">
                  <div className="label-grey-md">Durée estimée </div>
                  <div className="label-black-nqt-sm">
                    : {sectionData?.duration} min
                  </div>
                </Col>
              )}

              <Col className="d-flex">
                <div className="label-grey-md">Nombre de contenu </div>
                <div className="label-black-nqt-sm">
                  : {sectionData?.sectionContent.length}
                </div>
              </Col>
            </Col>
          </Row>
          <Row>
            <hr className="line" />
          </Row>
          <Row className="d-flex preview-subject">
            <Col className="d-flex" md={{ offset: 4 }}>
              <svg
                width="18"
                height="15"
                viewBox="0 0 18 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8 9V2C8 0.89543 7.10457 0 6 0H2C0.89543 0 0 0.89543 0 2V6C0 7.10457 0.89543 8 2 8H5V9C5.01856 9.96862 4.34023 10.8113 3.39 11C3.1683 11.0498 3.0081 11.2429 3 11.47V13.47C2.99257 13.6252 3.05492 13.7756 3.17 13.88C3.27637 13.9748 3.41874 14.0186 3.56 14C6.10071 13.7137 8.01608 11.5567 8 9ZM12 0H16C17.1046 0 18 0.89543 18 2V9C18.011 11.5529 16.0969 13.7041 13.56 13.99C13.4187 14.0086 13.2764 13.9648 13.17 13.87C13.0629 13.7725 13.0013 13.6348 13 13.49V11.49C12.9989 11.2554 13.1611 11.0516 13.39 11C14.3402 10.8113 15.0186 9.96862 15 9V8H12C10.8954 8 10 7.10457 10 6V2C10 0.89543 10.8954 0 12 0Z"
                  fill="#3367CD"
                />
              </svg>
              <div className="label-black-md pl-10"> À propos de l’étape</div>
            </Col>
          </Row>
          <Row className="d-flex preview-subject">
            {sectionData?.sectionDescription && (
              <Col md={{ offset: 4 }}>
                {sectionData?.sectionDescription.replace(/<br\s*\/?>/gm, "\r\n").split(/[\n]+/).map((item: any, idx: any) => (
                  <div key={idx} className="label-grey-md pt-3">
                    {item}
                    <br />
                  </div>
                ))}
              </Col>
            )}
          </Row>
        </Col>
      </div>
      <div className="preview-content">
        <div className="p-5">
          {sectionData?.sectionContent.map((content:any, index: number) => {
            if (content.type === "upload" && content.fileType === "pdf") {
              return (
                <Row className="pb-5" key={index}>
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.5.207/build/pdf.worker.min.js">
                    <div
                      id="pdfviewer"
                      style={
                        matches.small ? { width: "100%", height: "50vh" } : {}
                      }
                    >
                      {/* <Viewer defaultScale={SpecialZoomLevel.PageFit} fileUrl={URL.createObjectURL(base64toBlob(content.file))} /> */}
                      <SlidePresentationExample
                        fileUrl={URL.createObjectURL(
                          base64toBlob(content?.file || "")
                        )}
                      ></SlidePresentationExample>
                    </div>
                  </Worker>
                </Row>
              );
            } else if (
              content.type === "upload" &&
              (content.fileType === "ppt" || content.fileType === "pptx")
              && content.fileConverted
            ) {
              return (
                <Row className="pb-5" key={index}>
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.5.207/build/pdf.worker.min.js">
                    <div
                      id="pdfviewer"
                      style={
                        matches.small ? { width: "100%", height: "50vh" } : {}
                      }
                    >
                      {/* <Viewer defaultScale={SpecialZoomLevel.PageFit} fileUrl={URL.createObjectURL(base64toBlob(content.file))} /> */}
                      <SlidePresentationExample
                        fileUrl={URL.createObjectURL(
                          base64toBlob(content?.fileConverted || "")
                        )}
                      ></SlidePresentationExample>
                    </div>
                  </Worker>
                </Row>
              );
            } else if (
              content.type === "upload" &&
              (content.fileType === "xls" || content.fileType === "xlsx")
            ) {
              return (
                <Row className="pb-3" key={index}>
                  <div className="preview-xls">
                    <div>
                      <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M29.6001 36H10.4001C8.63276 36 7.20007 34.5673 7.20007 32.8V14.928C7.1994 14.0751 7.53921 13.2573 8.14408 12.656L15.8561 4.944C16.4574 4.33914 17.2752 3.99933 18.1281 4H29.6001C31.3674 4 32.8001 5.43269 32.8001 7.2V32.8C32.8001 34.5673 31.3674 36 29.6001 36ZM18.4001 13.6V6.912L10.1121 15.2H16.8001C17.6837 15.2 18.4001 14.4837 18.4001 13.6Z"
                          fill="#3367CD"
                        />
                        <path
                          d="M15.8083 25.55C15.8083 25.82 16.0243 26.036 16.2943 26.036C16.5643 26.036 16.7863 25.82 16.7863 25.55C16.7863 25.28 16.5643 25.058 16.2943 25.058C16.0243 25.058 15.8083 25.28 15.8083 25.55ZM17.2476 26H18.0456C18.0996 25.91 18.6456 25.112 18.7056 25.022L19.3836 26H20.1996L19.1376 24.512L20.1636 23.078H19.3716L18.7356 24.014C18.6816 23.93 18.1476 23.168 18.0936 23.078H17.2656L18.3096 24.524L17.2476 26ZM21.4038 26V21.656H20.7078V26H21.4038ZM22.0436 25.208C22.0796 25.538 22.4036 26.09 23.2496 26.09C23.9936 26.09 24.3536 25.598 24.3536 25.154C24.3536 24.722 24.0656 24.386 23.5136 24.266L23.0696 24.176C22.8896 24.14 22.7756 24.026 22.7756 23.87C22.7756 23.69 22.9496 23.534 23.1956 23.534C23.5856 23.534 23.7116 23.804 23.7356 23.972L24.3236 23.804C24.2756 23.516 24.0116 22.988 23.1956 22.988C22.5896 22.988 22.1216 23.42 22.1216 23.93C22.1216 24.332 22.3916 24.668 22.9076 24.782L23.3396 24.878C23.5736 24.926 23.6816 25.046 23.6816 25.202C23.6816 25.382 23.5316 25.538 23.2436 25.538C22.8716 25.538 22.6736 25.304 22.6496 25.04L22.0436 25.208Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <div className="d-grid">
                      <span>Télécharger le fichier Excel</span>
                      <span>{content.fileName}</span>
                    </div>
                    <a
                      href={content.file}
                      download={content.fileName}
                      style={{ marginLeft: "auto" }}
                    >
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M15 20.0019C14.8343 20.0019 14.6759 19.9333 14.5625 19.8125L7.325 12.575C7.20668 12.4576 7.14013 12.2979 7.14013 12.1313C7.14013 11.9646 7.20668 11.8049 7.325 11.6875L7.575 11.4375C7.69259 11.3154 7.85552 11.2475 8.025 11.25H11.25V4.375C11.25 4.02982 11.5298 3.75 11.875 3.75H18.125C18.4702 3.75 18.75 4.02982 18.75 4.375V11.25H21.975C22.1445 11.2475 22.3074 11.3154 22.425 11.4375L22.675 11.6875C22.7933 11.8049 22.8599 11.9646 22.8599 12.1313C22.8599 12.2979 22.7933 12.4576 22.675 12.575L15.4375 19.8125C15.3241 19.9333 15.1657 20.0019 15 20.0019ZM23.75 24.375V23.125C23.75 22.7798 23.4702 22.5 23.125 22.5H6.875C6.52982 22.5 6.25 22.7798 6.25 23.125V24.375C6.25 24.7202 6.52982 25 6.875 25H23.125C23.4702 25 23.75 24.7202 23.75 24.375Z"
                          fill="#3367CD"
                        />
                      </svg>
                    </a>
                  </div>
                </Row>
              );
            } else if (
              content.type === "upload" &&
              (content.fileType === "png" ||
                content.fileType === "jpg" ||
                content.fileType === "jpeg")
            ) {
              return (
                <Row
                  className="pb-3"
                  style={{ justifyContent: "center" }}
                  key={index}
                >
                  <img
                    style={{ width: "fit-content" }}
                    src={content.file}
                    alt="img-preview"
                  />
                </Row>
              );
            } else if (content.type === "text") {
              return (
                <Row className="pb-3 center-container" key={index}>
                  <Col md="10">
                    <CKEditor
                      initData={content?.html || ""}
                      config={{
                        height: "autogrow",
                        readOnly: true,
                        toolbar: [],
                        resize_enabled: false,
                        removePlugins: "toolbar",
                        // You may want to disable content filtering because if you use full page mode, you probably
                        // want to  freely enter any HTML content in source mode without any limitations.
                        allowedContent: true,
                        extraPlugins: "autogrow",
                        autoGrow_onStartup: true,
                        autoGrow_minHeight: 0,
                        autoGrow_maxHeight: 1000,
                      }}
                    />
                  </Col>
                </Row>
              );
            } else if (
              content.type === "upload" &&
              content.fileType === "mp3"
            ) {
              return (
                <Row className="pb-3" key={index}>
                  <Audio>
                    <audio src={content.file}></audio>
                  </Audio>
                </Row>
              );
            } else if (content.type === "video") {
                return (
                  <Row className="pb-3" key={index} style={{ justifyContent: 'center' }}>
                    <ReactPlayer url={content?.link}/>
                  </Row>
              );
            } else if (
              content.type === "eval" &&
              content.autoEval &&
              content.themes &&
              content.themes.length > 0 &&
              content.themes[0].topics?.length > 0
            ) {
              return (
                <Row className="quizz pb-3" key={index}>
                  <Col md="3">
                    <svg
                      width="286"
                      height="123"
                      viewBox="0 0 286 123"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="128.975"
                        cy="53.1188"
                        r="52.4257"
                        stroke="#ECF0FB"
                        strokeWidth="1.38614"
                      />
                      <circle
                        cx="128.975"
                        cy="53.1187"
                        r="38.6881"
                        stroke="#ECF0FB"
                        strokeWidth="1.38614"
                      />
                      <g filter="url(#filter0_d_695_8542)">
                        <rect
                          x="52.0439"
                          y="87.9207"
                          width="102.574"
                          height="23.0792"
                          rx="3.88119"
                          fill="white"
                        />
                      </g>
                      <g clipPath="url(#clip0_695_8542)">
                        <path
                          d="M74.9379 102.241L77.4057 103.733C77.8576 104.007 78.4107 103.602 78.2917 103.091L77.6376 100.284L79.82 98.3934C80.2184 98.0485 80.0043 97.3944 79.481 97.3528L76.6089 97.109L75.485 94.4569C75.2828 93.9752 74.593 93.9752 74.3909 94.4569L73.267 97.103L70.3949 97.3469C69.8716 97.3885 69.6575 98.0426 70.0559 98.3875L72.2383 100.278L71.5842 103.085C71.4652 103.597 72.0182 104.001 72.4702 103.727L74.9379 102.241Z"
                          fill="#3367CD"
                        />
                      </g>
                      <g clipPath="url(#clip1_695_8542)">
                        <path
                          d="M89.2094 102.241L91.6772 103.733C92.1291 104.007 92.6821 103.602 92.5632 103.091L91.9091 100.284L94.0914 98.3934C94.4899 98.0485 94.2758 97.3944 93.7525 97.3528L90.8804 97.109L89.7565 94.4569C89.5543 93.9752 88.8645 93.9752 88.6624 94.4569L87.5385 97.103L84.6664 97.3469C84.1431 97.3885 83.929 98.0426 84.3274 98.3875L86.5097 100.278L85.8556 103.085C85.7367 103.597 86.2897 104.001 86.7417 103.727L89.2094 102.241Z"
                          fill="#3367CD"
                        />
                      </g>
                      <g clipPath="url(#clip2_695_8542)">
                        <path
                          d="M103.481 102.241L105.949 103.733C106.401 104.007 106.954 103.602 106.835 103.091L106.181 100.284L108.363 98.3934C108.761 98.0485 108.547 97.3944 108.024 97.3528L105.152 97.109L104.028 94.4569C103.826 93.9752 103.136 93.9752 102.934 94.4569L101.81 97.103L98.9378 97.3469C98.4146 97.3885 98.2005 98.0426 98.5989 98.3875L100.781 100.278L100.127 103.085C100.008 103.597 100.561 104.001 101.013 103.727L103.481 102.241Z"
                          fill="#3367CD"
                        />
                      </g>
                      <g clipPath="url(#clip3_695_8542)">
                        <path
                          d="M117.752 102.241L120.22 103.733C120.672 104.007 121.225 103.602 121.106 103.091L120.452 100.284L122.634 98.3934C123.033 98.0485 122.819 97.3944 122.295 97.3528L119.423 97.109L118.299 94.4569C118.097 93.9752 117.408 93.9752 117.205 94.4569L116.081 97.103L113.209 97.3469C112.686 97.3885 112.472 98.0426 112.87 98.3875L115.053 100.278L114.399 103.085C114.28 103.597 114.833 104.001 115.285 103.727L117.752 102.241Z"
                          fill="#3367CD"
                        />
                      </g>
                      <g clipPath="url(#clip4_695_8542)">
                        <path
                          d="M132.024 102.241L134.492 103.733C134.944 104.007 135.497 103.602 135.378 103.091L134.724 100.284L136.906 98.3934C137.304 98.0485 137.09 97.3944 136.567 97.3528L133.695 97.109L132.571 94.4569C132.369 93.9752 131.679 93.9752 131.477 94.4569L130.353 97.103L127.481 97.3469C126.958 97.3885 126.743 98.0426 127.142 98.3875L129.324 100.278L128.67 103.085C128.551 103.597 129.104 104.001 129.556 103.727L132.024 102.241Z"
                          fill="#3367CD"
                        />
                      </g>
                      <g filter="url(#filter1_d_695_8542)">
                        <rect
                          x="9"
                          y="11.9061"
                          width="154.777"
                          height="34.8249"
                          rx="5.85644"
                          fill="white"
                        />
                      </g>
                      <g clipPath="url(#clip5_695_8542)">
                        <path
                          d="M28.1609 33.4239L31.5883 35.4969C32.216 35.8768 32.9841 35.3152 32.8189 34.6049L31.9104 30.7067L34.9415 28.0803C35.4948 27.6013 35.1975 26.6928 34.4707 26.635L30.4816 26.2964L28.9207 22.6129C28.6399 21.944 27.6819 21.944 27.4011 22.6129L25.8401 26.2882L21.8511 26.6268C21.1243 26.6846 20.827 27.5931 21.3803 28.0721L24.4113 30.6984L23.5028 34.5966C23.3377 35.3069 24.1057 35.8685 24.7334 35.4886L28.1609 33.4239Z"
                          fill="#3367CD"
                        />
                      </g>
                      <rect
                        x="45.2764"
                        y="21.5797"
                        width="78.8397"
                        height="5.80415"
                        rx="2.90207"
                        fill="#3367CD"
                      />
                      <rect
                        x="45.2764"
                        y="31.7369"
                        width="100.605"
                        height="5.80415"
                        rx="2.90207"
                        fill="#ECF0FB"
                      />
                      <g filter="url(#filter2_d_695_8542)">
                        <rect
                          x="121.648"
                          y="53.1188"
                          width="154.777"
                          height="34.8249"
                          rx="5.85644"
                          fill="white"
                        />
                      </g>
                      <g clipPath="url(#clip6_695_8542)">
                        <path
                          d="M141.143 74.3882L144.571 76.4612C145.198 76.8411 145.967 76.2795 145.801 75.5692L144.893 71.671L147.924 69.0446C148.477 68.5656 148.18 67.6571 147.453 67.5993L143.464 67.2607L141.903 63.5772C141.622 62.9083 140.664 62.9083 140.383 63.5772L138.823 67.2525L134.833 67.5911C134.107 67.6489 133.809 68.5574 134.363 69.0364L137.394 71.6627L136.485 75.5609C136.32 76.2712 137.088 76.8328 137.716 76.4529L141.143 74.3882Z"
                          fill="#ECF0FB"
                        />
                      </g>
                      <rect
                        x="157.924"
                        y="62.7924"
                        width="78.8397"
                        height="5.80415"
                        rx="2.90207"
                        fill="#3367CD"
                      />
                      <rect
                        x="157.924"
                        y="72.9496"
                        width="100.605"
                        height="5.80414"
                        rx="2.90207"
                        fill="#ECF0FB"
                      />
                      <defs>
                        <filter
                          id="filter0_d_695_8542"
                          x="46.2222"
                          y="87.9207"
                          width="114.218"
                          height="34.7228"
                          filterUnits="userSpaceOnUse"
                          colorInterpolationFilters="sRGB"
                        >
                          <feFlood
                            floodOpacity="0"
                            result="BackgroundImageFix"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feOffset dy="5.82178" />
                          <feGaussianBlur stdDeviation="2.91089" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_695_8542"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow_695_8542"
                            result="shape"
                          />
                        </filter>
                        <filter
                          id="filter1_d_695_8542"
                          x="0.215346"
                          y="11.9061"
                          width="172.347"
                          height="52.3942"
                          filterUnits="userSpaceOnUse"
                          colorInterpolationFilters="sRGB"
                        >
                          <feFlood
                            floodOpacity="0"
                            result="BackgroundImageFix"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feOffset dy="8.78465" />
                          <feGaussianBlur stdDeviation="4.39233" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_695_8542"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow_695_8542"
                            result="shape"
                          />
                        </filter>
                        <filter
                          id="filter2_d_695_8542"
                          x="112.864"
                          y="53.1188"
                          width="172.347"
                          height="52.3942"
                          filterUnits="userSpaceOnUse"
                          colorInterpolationFilters="sRGB"
                        >
                          <feFlood
                            floodOpacity="0"
                            result="BackgroundImageFix"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feOffset dy="8.78465" />
                          <feGaussianBlur stdDeviation="4.39233" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_695_8542"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow_695_8542"
                            result="shape"
                          />
                        </filter>
                        <clipPath id="clip0_695_8542">
                          <rect
                            width="14.2714"
                            height="14.2714"
                            fill="white"
                            transform="translate(67.8027 91.9712)"
                          />
                        </clipPath>
                        <clipPath id="clip1_695_8542">
                          <rect
                            width="14.2714"
                            height="14.2714"
                            fill="white"
                            transform="translate(82.0742 91.9712)"
                          />
                        </clipPath>
                        <clipPath id="clip2_695_8542">
                          <rect
                            width="14.2714"
                            height="14.2714"
                            fill="white"
                            transform="translate(96.3457 91.9712)"
                          />
                        </clipPath>
                        <clipPath id="clip3_695_8542">
                          <rect
                            width="14.2714"
                            height="14.2714"
                            fill="white"
                            transform="translate(110.617 91.9712)"
                          />
                        </clipPath>
                        <clipPath id="clip4_695_8542">
                          <rect
                            width="14.2714"
                            height="14.2714"
                            fill="white"
                            transform="translate(124.889 91.9712)"
                          />
                        </clipPath>
                        <clipPath id="clip5_695_8542">
                          <rect
                            width="19.8214"
                            height="19.8214"
                            fill="white"
                            transform="translate(18.25 19.1607)"
                          />
                        </clipPath>
                        <clipPath id="clip6_695_8542">
                          <rect
                            width="19.8214"
                            height="19.8214"
                            fill="white"
                            transform="translate(131.232 60.125)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </Col>
                  <Col md="6" style={{ alignSelf: "center" }}>
                    <div className="d-flex">
                      <div className="label-blue-md">
                        Faire l’auto-évaluation
                      </div>
                      <div className="label-grey-md-normal px-3">
                        Obligatoire
                      </div>
                    </div>
                    <Row>
                      <div>
                        Évaluez vos connaissance et votre niveau de
                        compréhension du sujet grâce à ce quizz. En fonction de
                        votre score, remporter des points ou des badges !
                      </div>
                    </Row>
                  </Col>
                  <Col md="3" className="d-flex">
                    <div className="quizz-btn" onClick={() => setStep(3)}>
                      <div className="label-black-sm">
                        Faire le quizz d’évaluation
                      </div>
                    </div>
                  </Col>
                </Row>
              );
            } else if (
              content.type === "eval" &&
              !content.autoEval &&
              content.questions &&
              content.questions?.length > 0 &&
              content.questions[0].answers?.length > 0
            ) {
              return (
                <Row className="quizz pb-3" key={index}>
                  <Col md="3">
                    <svg
                      width="277"
                      height="117"
                      viewBox="0 0 277 117"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="124.975"
                        cy="53.1189"
                        r="52.7679"
                        stroke="#ECF0FB"
                        strokeWidth="0.702055"
                      />
                      <circle
                        cx="124.976"
                        cy="53.1195"
                        r="39.0302"
                        stroke="#ECF0FB"
                        strokeWidth="0.702055"
                      />
                      <g filter="url(#filter0_d_695_8228)">
                        <rect
                          x="48.0446"
                          y="87.9219"
                          width="102.574"
                          height="23.0793"
                          rx="1.96575"
                          fill="white"
                        />
                      </g>
                      <rect
                        opacity="0.2"
                        x="55.9351"
                        y="92.1709"
                        width="13.9598"
                        height="13.9598"
                        rx="6.97992"
                        fill="#50BD89"
                      />
                      <g clipPath="url(#clip0_695_8228)">
                        <path
                          d="M62.0424 100.372L61.0245 99.3546C60.911 99.2412 60.7307 99.2412 60.6173 99.3546C60.5039 99.468 60.5039 99.6483 60.6173 99.7617L61.8359 100.98C61.9493 101.094 62.1325 101.094 62.2459 100.98L65.3287 97.9004C65.4422 97.787 65.4422 97.6067 65.3287 97.4933C65.2153 97.3798 65.035 97.3798 64.9216 97.4933L62.0424 100.372Z"
                          fill="#50BD89"
                        />
                      </g>
                      <rect
                        x="75.9641"
                        y="94.332"
                        width="52.2489"
                        height="3.84654"
                        rx="1.92327"
                        fill="#50BD89"
                      />
                      <rect
                        opacity="0.2"
                        x="75.9641"
                        y="101.063"
                        width="66.6734"
                        height="3.84654"
                        rx="1.92327"
                        fill="#50BD89"
                      />
                      <g filter="url(#filter1_d_695_8228)">
                        <rect
                          x="5"
                          y="11.9053"
                          width="154.778"
                          height="34.8249"
                          rx="2.96618"
                          fill="white"
                        />
                      </g>
                      <rect
                        x="14.6736"
                        y="19.1611"
                        width="19.3472"
                        height="19.3472"
                        rx="9.6736"
                        fill="#EBF0FA"
                      />
                      <path
                        d="M21.5009 28.6709C21.5009 29.8804 22.4041 30.5915 23.3696 30.5915C23.707 30.5915 24.0444 30.5033 24.3299 30.3372L24.7815 30.8303L25.2019 30.4721L24.7659 29.9842C25.0566 29.6624 25.2435 29.216 25.2435 28.6709C25.2435 27.4667 24.3351 26.7555 23.3696 26.7555C22.4041 26.7555 21.5009 27.4667 21.5009 28.6709ZM22.1394 28.6709C22.1394 27.7833 22.7519 27.3421 23.3696 27.3421C23.9925 27.3421 24.605 27.7833 24.605 28.6709C24.605 29.0291 24.5064 29.3094 24.3506 29.5222L23.7381 28.8422L23.3125 29.2056L23.9198 29.8804C23.7485 29.9634 23.5565 29.9998 23.3696 29.9998C22.7519 29.9998 22.1394 29.5586 22.1394 28.6709ZM27.0327 30.5137V27.15H26.4928C26.4202 27.503 26.0879 27.7729 25.6052 27.7833V28.2089H26.4202V30.5137H27.0327Z"
                        fill="#3367CD"
                      />
                      <rect
                        x="41.276"
                        y="21.5801"
                        width="78.8398"
                        height="5.80416"
                        rx="2.90208"
                        fill="#3367CD"
                      />
                      <rect
                        x="41.276"
                        y="31.7373"
                        width="100.605"
                        height="5.80416"
                        rx="2.90208"
                        fill="#ECF0FB"
                      />
                      <g filter="url(#filter2_d_695_8228)">
                        <rect
                          x="117.649"
                          y="53.1201"
                          width="154.778"
                          height="34.8249"
                          rx="2.96618"
                          fill="white"
                        />
                      </g>
                      <rect
                        x="127.322"
                        y="60.375"
                        width="19.3472"
                        height="19.3472"
                        rx="9.6736"
                        fill="#EBF0FA"
                      />
                      <path
                        d="M134.15 69.8858C134.15 71.0952 135.053 71.8064 136.018 71.8064C136.356 71.8064 136.693 71.7181 136.979 71.552L137.43 72.0452L137.851 71.687L137.415 71.1991C137.705 70.8772 137.892 70.4308 137.892 69.8858C137.892 68.6815 136.984 67.9704 136.018 67.9704C135.053 67.9704 134.15 68.6815 134.15 69.8858ZM134.788 69.8858C134.788 68.9981 135.401 68.5569 136.018 68.5569C136.641 68.5569 137.254 68.9981 137.254 69.8858C137.254 70.2439 137.155 70.5242 136.999 70.7371L136.387 70.0571L135.961 70.4204L136.568 71.0952C136.397 71.1783 136.205 71.2146 136.018 71.2146C135.401 71.2146 134.788 70.7734 134.788 69.8858ZM139.681 71.7285V68.3649H139.142C139.069 68.7178 138.737 68.9878 138.254 68.9981V69.4238H139.069V71.7285H139.681Z"
                        fill="#3367CD"
                      />
                      <rect
                        x="153.925"
                        y="62.793"
                        width="78.8398"
                        height="5.80416"
                        rx="2.90208"
                        fill="#3367CD"
                      />
                      <rect
                        x="153.925"
                        y="72.9512"
                        width="100.605"
                        height="5.80416"
                        rx="2.90208"
                        fill="#ECF0FB"
                      />
                      <defs>
                        <filter
                          id="filter0_d_695_8228"
                          x="45.0959"
                          y="87.9219"
                          width="108.472"
                          height="28.9766"
                          filterUnits="userSpaceOnUse"
                          colorInterpolationFilters="sRGB"
                        >
                          <feFlood
                            floodOpacity="0"
                            result="BackgroundImageFix"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feOffset dy="2.94863" />
                          <feGaussianBlur stdDeviation="1.47431" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_695_8228"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow_695_8228"
                            result="shape"
                          />
                        </filter>
                        <filter
                          id="filter1_d_695_8228"
                          x="0.550728"
                          y="11.9053"
                          width="163.676"
                          height="43.7235"
                          filterUnits="userSpaceOnUse"
                          colorInterpolationFilters="sRGB"
                        >
                          <feFlood
                            floodOpacity="0"
                            result="BackgroundImageFix"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feOffset dy="4.44927" />
                          <feGaussianBlur stdDeviation="2.22464" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_695_8228"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow_695_8228"
                            result="shape"
                          />
                        </filter>
                        <filter
                          id="filter2_d_695_8228"
                          x="113.2"
                          y="53.1201"
                          width="163.676"
                          height="43.7235"
                          filterUnits="userSpaceOnUse"
                          colorInterpolationFilters="sRGB"
                        >
                          <feFlood
                            floodOpacity="0"
                            result="BackgroundImageFix"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feOffset dy="4.44927" />
                          <feGaussianBlur stdDeviation="2.22464" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_695_8228"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow_695_8228"
                            result="shape"
                          />
                        </filter>
                        <clipPath id="clip0_695_8228">
                          <rect
                            width="6.97992"
                            height="6.97992"
                            fill="white"
                            transform="translate(59.4248 95.6611)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </Col>
                  <Col md="6" style={{ alignSelf: "center" }}>
                    <div className="d-flex">
                      <div className="label-blue-md">Faire le quizz</div>
                      <div className="label-grey-md-normal px-3">
                        Obligatoire
                      </div>
                    </div>
                    <Row>
                      <div>
                        Évaluez vos connaissance et votre niveau de
                        compréhension du sujet grâce à ce quizz. En fonction de
                        votre score, remporter des points ou des badges !
                      </div>
                    </Row>
                  </Col>
                  <Col md="3" className="d-flex">
                    <div className="quizz-btn" onClick={() => setStep(2)}>
                      <div className="label-black-sm">
                        Faire le quizz d’évaluation
                      </div>
                    </div>
                  </Col>
                </Row>
              );
            } else {
              return <Row key={index}></Row>;
            }
          })}
        </div>
      </div>
      {data.status === "pending" &&
        data.validation_status === "attente_notation" &&
        data.is_relecteur === true &&
        data.sections.length - 1 === activeSection && (
          <div className="d-flex justify-content-center">
            {/** 
        //@ts-ignore */}
            <NotificationAlert ref={notificationAlert} />
            <Button
              className="btn-round-sm validate mt-3"
              color="primary"
              onClick={() => {
                setModalShow(true);
              }}
              type="submit"
            >
              Noter la formation
            </Button>
          </div>
        )}
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton style={{ borderBottom: 0 }}>
          <Row>
            <div className="label-black-xmd">Noter la formation</div>
            <div className="label-grey">
              Donnez une note à la formation que vous venez de relire.{" "}
            </div>
          </Row>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column ">
          <Row className="d-flex flex-column ">
            <Col className="d-flex ">
              <div
                className="note-box"
                onClick={() => setNote("A")}
                style={
                  note === "A"
                    ? {
                        color: "#3367CD",
                        border: "1px solid #3367CD",
                        background: "#ECF0FB",
                      }
                    : {}
                }
              >
                A
              </div>
              <div
                className="note-box"
                onClick={() => setNote("B")}
                style={
                  note === "B"
                    ? {
                        color: "#3367CD",
                        border: "1px solid #3367CD",
                        background: "#ECF0FB",
                      }
                    : {}
                }
              >
                B
              </div>
              <div
                className="note-box"
                onClick={() => setNote("C")}
                style={
                  note === "C"
                    ? {
                        color: "#3367CD",
                        border: "1px solid #3367CD",
                        background: "#ECF0FB",
                      }
                    : {}
                }
              >
                C
              </div>
              <div
                className="note-box"
                onClick={() => setNote("D")}
                style={
                  note === "D"
                    ? {
                        color: "#3367CD",
                        border: "1px solid #3367CD",
                        background: "#ECF0FB",
                      }
                    : {}
                }
              >
                D
              </div>
            </Col>
          </Row>
          <Row className="d-flex flex-column ">
            <Col className="d-flex flex-column ">
              {note === "A" && <p className="label-blue-md">“Validé.”</p>}
              {note === "B" && (
                <p className="label-blue-md">
                  “Publiable avec de légères modifications , et voici
                  lesquelles.”
                </p>
              )}
              {note === "C" && (
                <p className="label-blue-md">
                  “Publiable avec d’importantes modifications, et voici
                  lesquelles.”
                </p>
              )}
              {note === "D" && (
                <p className="label-blue-md">“Non publiable en l’état.”</p>
              )}
            </Col>
          </Row>
          {note !== "A" && (
            <Row className="d-flex flex-column ">
              <Col className="d-flex flex-column ">
                <div>
                  <textarea
                    onChange={(event) => setMotif(event.target.value)}
                    style={{ width: "100%", height: "84px" }}
                    placeholder="Pourquoi avez vous attribué cette note ?"
                  ></textarea>
                </div>
              </Col>
            </Row>
          )}
          <Row className="d-flex flex-column align-items-center">
            <Col className="d-flex justify-content-end">
              <Button
                className="btn-round-sm-secondary mt-3 me-2"
                onClick={() => setModalShow(false)}
                color="secondary"
                type="submit"
              >
                Revenir en arrière
              </Button>
              <Button
                disabled={
                  note === "" || (note !== "" && note !== "A" && motif === "")
                }
                className="btn-round-sm mt-3"
                onClick={() => {
                  sendRequest({ formationId: data.id, comment: motif, note });
                  setModalShow(false);
                }}
                color="primary"
                type="submit"
              >
                Envoyer
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default TrainingPreviewContent;
