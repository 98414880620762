import React, { useState, useEffect } from "react";
import { AiOutlineCaretUp, AiOutlineCaretDown } from "react-icons/ai";
import { UncontrolledPopover, PopoverBody, Col } from "reactstrap";

const TrainingTypeFilter = (props: any) => {
  const { handleChange, reset } = props;
  const [open, setOpen] = useState(false);
  const [contentType, setContentType] = useState<string[]>([]);
  const [clearAll, setClearAll] = useState(false);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleChange(contentType);
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [contentType, handleChange]);

  useEffect(() => {
    if (reset || clearAll) {
      setContentType([]);
    }
  }, [reset, clearAll]);
  const setContentsSelected = (e: any) => {
    const id = e.currentTarget.dataset.id;
    if (contentType.includes(id)) {
      setContentType((prev) => {
        const sub = [...prev];
        sub.splice(
          contentType.findIndex((val) => val === id),
          1
        );
        return sub;
      });
    } else {
      setContentType((prev) => {
        const sub = [...prev];
        sub.push(id);
        return sub;
      });
    }
  };
  const ContentsOptions = (
    <>
      <li
        className="d-flex subjectList"
        key={2}
        data-id={"async"}
        onClick={setContentsSelected.bind(this)}
        style={{ width: 300 }}
      >
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.5"
            y="0.5"
            width="39"
            height="39"
            rx="3.5"
            fill="white"
            stroke="#F0F0F0"
          />
          <path
            d="M28.1667 13.167H13.1667C12.25 13.167 11.5 13.917 11.5 14.8337V24.8337C11.5 25.7503 12.25 26.5003 13.1667 26.5003H17.3333V27.3337C17.3333 27.792 17.7083 28.167 18.1667 28.167H23.1667C23.625 28.167 24 27.792 24 27.3337V26.5003H28.1667C29.0833 26.5003 29.8333 25.7503 29.8333 24.8337V14.8337C29.8333 13.917 29.0833 13.167 28.1667 13.167ZM27.3333 24.8337H14C13.5417 24.8337 13.1667 24.4587 13.1667 24.0003V15.667C13.1667 15.2087 13.5417 14.8337 14 14.8337H27.3333C27.7917 14.8337 28.1667 15.2087 28.1667 15.667V24.0003C28.1667 24.4587 27.7917 24.8337 27.3333 24.8337ZM25.6667 17.3337H18.1667C17.7083 17.3337 17.3333 17.7087 17.3333 18.167C17.3333 18.6253 17.7083 19.0003 18.1667 19.0003H25.6667C26.125 19.0003 26.5 18.6253 26.5 18.167C26.5 17.7087 26.125 17.3337 25.6667 17.3337ZM25.6667 20.667H18.1667C17.7083 20.667 17.3333 21.042 17.3333 21.5003C17.3333 21.9587 17.7083 22.3337 18.1667 22.3337H25.6667C26.125 22.3337 26.5 21.9587 26.5 21.5003C26.5 21.042 26.125 20.667 25.6667 20.667ZM16.5 17.3337H14.8333V19.0003H16.5V17.3337ZM16.5 20.667H14.8333V22.3337H16.5V20.667Z"
            fill="#3367CD"
          />
        </svg>

        <span className="checkBox">Autonome</span>
        {contentType.includes("async") ? (
          <div
            className="checked"
            style={{ marginLeft: "auto", paddingRight: 10 }}
          >
            <svg
              width="9"
              height="7"
              viewBox="0 0 9 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.75015 4.38408L1.481 3.11494C1.22087 2.85481 0.800078 2.85481 0.539947 3.11494C0.279816 3.37507 0.279816 3.79586 0.539947 4.05599L2.28161 5.79766C2.54175 6.05779 2.96254 6.05779 3.22267 5.79766L7.631 1.38933C7.89113 1.12919 7.89113 0.708403 7.631 0.448272C7.3709 0.188171 6.95018 0.188141 6.69004 0.448183C6.69001 0.448212 6.68998 0.448242 6.68995 0.448272L2.75015 4.38408Z"
                fill="#3367CD"
                stroke="#3367CD"
              />
            </svg>
          </div>
        ) : (
          <div
            className="unchecked"
            style={{ marginLeft: "auto", paddingRight: 10 }}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM14 11H11V14C11 14.55 10.55 15 10 15C9.45 15 9 14.55 9 14V11H6C5.45 11 5 10.55 5 10C5 9.45 5.45 9 6 9H9V6C9 5.45 9.45 5 10 5C10.55 5 11 5.45 11 6V9H14C14.55 9 15 9.45 15 10C15 10.55 14.55 11 14 11Z"
                fill="#ECF0FB"
              />
            </svg>
          </div>
        )}
      </li>
      <li
        className="d-flex subjectList"
        key={2}
        data-id={"sync"}
        onClick={setContentsSelected.bind(this)}
        style={{ width: 300 }}
      >
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.5"
            y="0.5"
            width="39"
            height="39"
            rx="3.5"
            fill="white"
            stroke="#F0F0F0"
          />
          <path
            d="M25.8333 12.5002H25V11.6668C25 11.2085 24.625 10.8335 24.1667 10.8335C23.7083 10.8335 23.3333 11.2085 23.3333 11.6668V12.5002H16.6667V11.6668C16.6667 11.2085 16.2917 10.8335 15.8333 10.8335C15.375 10.8335 15 11.2085 15 11.6668V12.5002H14.1667C13.2417 12.5002 12.5083 13.2502 12.5083 14.1668L12.5 25.8335C12.5 26.7502 13.2417 27.5002 14.1667 27.5002H25.8333C26.75 27.5002 27.5 26.7502 27.5 25.8335V14.1668C27.5 13.2502 26.75 12.5002 25.8333 12.5002ZM25 25.8335H15C14.5417 25.8335 14.1667 25.4585 14.1667 25.0002V16.6668H25.8333V25.0002C25.8333 25.4585 25.4583 25.8335 25 25.8335ZM16.6667 18.3335H19.1667C19.625 18.3335 20 18.7085 20 19.1668V21.6668C20 22.1252 19.625 22.5002 19.1667 22.5002H16.6667C16.2083 22.5002 15.8333 22.1252 15.8333 21.6668V19.1668C15.8333 18.7085 16.2083 18.3335 16.6667 18.3335Z"
            fill="#3367CD"
          />
        </svg>

        <span className="checkBox">Synchrone</span>
        {contentType.includes("sync") ? (
          <div
            className="checked"
            style={{ marginLeft: "auto", paddingRight: 10 }}
          >
            <svg
              width="9"
              height="7"
              viewBox="0 0 9 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.75015 4.38408L1.481 3.11494C1.22087 2.85481 0.800078 2.85481 0.539947 3.11494C0.279816 3.37507 0.279816 3.79586 0.539947 4.05599L2.28161 5.79766C2.54175 6.05779 2.96254 6.05779 3.22267 5.79766L7.631 1.38933C7.89113 1.12919 7.89113 0.708403 7.631 0.448272C7.3709 0.188171 6.95018 0.188141 6.69004 0.448183C6.69001 0.448212 6.68998 0.448242 6.68995 0.448272L2.75015 4.38408Z"
                fill="#3367CD"
                stroke="#3367CD"
              />
            </svg>
          </div>
        ) : (
          <div
            className="unchecked"
            style={{ marginLeft: "auto", paddingRight: 10 }}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM14 11H11V14C11 14.55 10.55 15 10 15C9.45 15 9 14.55 9 14V11H6C5.45 11 5 10.55 5 10C5 9.45 5.45 9 6 9H9V6C9 5.45 9.45 5 10 5C10.55 5 11 5.45 11 6V9H14C14.55 9 15 9.45 15 10C15 10.55 14.55 11 14 11Z"
                fill="#ECF0FB"
              />
            </svg>
          </div>
        )}
      </li>
      <div className="clearAll" onClick={() => setClearAll(true)}>
        réinitialiser
      </div>
    </>
  );

  return (
    <>
      <div
        className="label-black-normal formControl d-flex"
        color={"#3367CD"}
        onClick={() => setOpen(!open)}
        onBlur={() => setOpen(false)}
        id="PopoverContent"
        tabIndex={0}
      >
        <div className="d-flex filter-label">
          <div className="name"> Type</div>
          {contentType.length > 0 && (
            <div className="number"> {contentType.length} </div>
          )}
        </div>
        {open ? (
          <AiOutlineCaretUp color="#3367CD" className="float-end" />
        ) : (
          <AiOutlineCaretDown color="#BDBDBD" className="float-end" />
        )}
      </div>
      <UncontrolledPopover
        placement="bottom"
        target="PopoverContent"
        trigger="legacy"
      >
        <PopoverBody>
          <Col onFocus={() => setOpen(true)} onBlur={() => setOpen(false)}>
            <div>{ContentsOptions}</div>
          </Col>
        </PopoverBody>
      </UncontrolledPopover>
    </>
  );
};

export default React.memo(TrainingTypeFilter);
