import { useEffect, useRef, useState } from "react";
import {
  HiOutlineArrowNarrowLeft,
  HiOutlineArrowNarrowRight,
} from "react-icons/hi";
import { Col, Row } from "reactstrap";
//@ts-ignore
import EllipsisText from "react-ellipsis-text";
import ReactTooltip from "react-tooltip";
import Checkbox from "@mui/material/Checkbox";
import { useAppSelector } from "../../../../redux/hooks";
import NotificationAlert from "react-notification-alert";
import PendingTable from "./PendingTable";
import { ReactComponent as AvatarIcon } from "../../../../assets/img/icons/avatar-person.svg";
import Filters from "./Filters";
import { useHistory } from "react-router-dom";
import {
  editorActions,
  retrieveEditorProofreaders,
  saveProofreader,
  saveTraining,
  selectOrigTraining,
  selectProofreaders,
  selectProofreadersDetails,
  selectTraining,
} from "../../../../redux/slices/editorSlice";
import { TrainingNeedsProofReading } from "../../../../config/training";
import useHttp from "../../../../hooks/use-http";
import EditorService from "../../../../services/editor.service";
import { selectUser } from "../../../../redux/slices/userSlice";

function AllProofreaders(props: any) {
  const { matches, scrollable, dispatch, tag, route } = props;
  const history = useHistory();
  type ReviewersList = {
    [id: number]: number;
  };
  const training: TrainingNeedsProofReading = useAppSelector(selectTraining);
  const originalTraining: TrainingNeedsProofReading = useAppSelector(selectOrigTraining);
  const profile = useAppSelector(selectUser);
  const proofreaders = useAppSelector(selectProofreaders).filter(
    (proofreader: any) =>
      tag === "assign"
        ? training.createur && training.createur.id !== proofreader.id
        : true
  );

  const proofreadersDetails = useAppSelector(selectProofreadersDetails);
  const [pageNumber, setPage] = useState(1);
  const filtersData = {
    nom: "",
    sujets: [],
    etat: [],
    entreprise: [],
    exp: "",
  };
  const [filters, setFilters] = useState(filtersData);
  const [all, setAll] = useState(true);
  const notificationAlert = useRef();
  const [reviewersList, setReviewersList] = useState<ReviewersList>({});
  useEffect(() => {
    if (training.reviewers) {
      const reviewrList: ReviewersList = {};
      training.reviewers.forEach((val) => {
        setReviewersList((prev) => {
          reviewrList[val.id] = training.reviewers.findIndex(
            (rev) => rev.id === val.id
          );
          return reviewrList;
        });
      });
    }
  }, [training.reviewers]);
  const handleChange = async (data: any) => {
    dispatch(editorActions.updateTrainingReviewers(data));
  };
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      route === "Ajouter un relecteur"
        ? dispatch(
            retrieveEditorProofreaders({
              page: pageNumber,
              formationId: training.id,
              filters,
            })
          )
        : dispatch(retrieveEditorProofreaders({ page: pageNumber, filters }));
    }, 100);
    return () => clearTimeout(delayDebounceFn);
  }, [dispatch, pageNumber, filters, training.id, route]);
  const {
    sendRequest,
    status: statusAssign,
    error,
  } = useHttp(EditorService.postAssignTrainingsToProofreader);
  const cancelAssign = () => {
    dispatch(editorActions.resetTrainingReviewers());
  };
  const submitAssign = () => {
    const usersId = training.reviewers
      .filter((reviewer) => reviewer.isRelecteur === undefined)
      .map((val) => val.id);
    sendRequest({ users: usersId, formations: [training.id] });
  };

  const columns = [
    {
      Header: "Nom du parrain",
      isSorted: false,
      width: 100,
      Cell: (props: any) => {
        return (
          <div className="d-flex flex-row align-items-center">
            {tag === "assign" && (
              <Checkbox
                //@ts-ignore
                checked={reviewersList[props.row.original.id] !== undefined}
                onClick={(event) => handleChange(props.row.original)}
                inputProps={{ "aria-label": "controlled" }}
                sx={
                  {
                    color: "#E6E6E6",
                    borderWidth: 1,
                    "&.Mui-checked": { color: "#3367CD" },
                  }
                }
              />
            )}
            {props.row.original.image ? (
              <img
                alt="profile"
                style={{ width: 40, height: 40, borderRadius: "50%" }}
                src={props.row.original.image}
              />
            ) : (
              <AvatarIcon
                style={{ width: 40, height: 40, borderRadius: "50%" }}
              />
            )}
            <p className="label-black ms-2 mt-3">
              {props.row.original.firstName + " " + props.row.original.lastName}
            </p>
          </div>
        );
      },
      accessor: "firstName",
    },
    {
      Header: "Formations à valider",
      isSorted: false,
      width: 100,
      Cell: (props: any) => {
        return (
          <p className="label-grey-normal mt-3">
            {props.row.original.pendingFormations} en attente
          </p>
        );
      },
      accessor: "pendingFormations",
    },
    {
      Header: "Sujets",
      isSorted: false,
      width: 100,
      Cell: (props: any) => {
        let text = "";
        props.row.original.subjects.map(
          (subject: any) =>
            (text = text !== "" ? text + ", " + subject.label : subject.label)
        );

        return (
          <>
            <EllipsisText className="label-black" text={text} length={20} />
            <ReactTooltip>{text}</ReactTooltip>
          </>
        );
      },
      accessor: "sujets",
    },
    {
      Header: "etat",
      isSorted: false,
      width: 90,
      accessor: "isActive",
      Cell: (props: any) => {
        return props.row.original.isActive ? (
          <p className="actif-training p-1 mt-3">Actif</p>
        ) : (
          <p className="inactif-training p-1 mt-3">Inactif</p>
        );
      },
    },
    {
      Header: " ",
      isSorted: false,
      width: 100,
      Cell: (props: any) => {
        return (
          tag !== "assign" && (
            <p
              className="label-primary-nqt-sm pointer mt-3"
              onClick={() => {
                history.push("/editor", {route: "Assigner à une formation"});
                dispatch(saveProofreader(props.row.original));
              }}
            >
              Assigner à une formation
            </p>
          )
        );
      },
    },
  ];

  profile.role.includes("EDITOR-ALUMNI") && columns.pop();

  useEffect(() => {
    if (statusAssign === "completed") {
      //@ts-ignore
      notificationAlert.current.notificationAlert({
        place: "tr",
        message:
          error === null ? (
            <div>
              {" "}
              <p>Formations assignées avec succés</p>
            </div>
          ) : (
            <div>
              {" "}
              <p>Formations non assignées</p>
            </div>
          ),
        type: error === null ? "success" : "danger",
        icon: "nc-icon nc-bell-55",
        closeButton: false,
        autoDismiss: 7,
      });
      dispatch(
        retrieveEditorProofreaders({
          page: pageNumber,
          formationId: training.id,
          filters,
        })
      );
      dispatch(saveTraining(originalTraining));
    }
  }, [statusAssign, error, pageNumber, training.id, filters, dispatch,originalTraining]);

  return (
    <>
      {tag !== "assign" && (
        <>
          <p className="label-black-nqt-md ml-4 mb-0">
            Liste des relecteurs
          </p>
          <p className="label-grey-trainings-sm mt-0 opacity-md">
            {proofreadersDetails.counts && proofreadersDetails.counts.all}{" "}
            parrains enregistrés
          </p>
          <div className="d-flex flex-row mt-3 me-2">
            <p
              className={
                all
                  ? "label-primary-nqt-sm me-1 pointer"
                  : "label-grey-trainings-sm me-1 pointer"
              }
              onClick={() => {
                dispatch(
                  retrieveEditorProofreaders({
                    page: pageNumber,
                    filters: filtersData,
                  })
                );
                setAll(true);
              }}
            >
              Tous
            </p>
            <p
              className={
                !all
                  ? "align-middle ps-1 me-4 px-2 outlined-grey"
                  : "align-middle ps-1 me-4 px-2 outlined-primary"
              }
            >
              {proofreadersDetails.counts && proofreadersDetails.counts.all}
            </p>
            <p
              className={
                !all
                  ? "label-primary-nqt-sm me-1 pointer"
                  : "label-grey-trainings-sm me-1 pointer"
              }
              onClick={() => {
                dispatch(
                  retrieveEditorProofreaders({
                    page: pageNumber,
                    filters: { ...filtersData, etat: ["actif"] },
                  })
                );
                setAll(false);
              }}
            >
              On
            </p>
            <p
              className={
                all
                  ? "align-middle ps-1 me-4 px-2 outlined-grey"
                  : "align-middle ps-1 me-4 px-2 outlined-primary"
              }
            >
              {proofreadersDetails.counts && proofreadersDetails.counts.on}
            </p>
          </div>
        </>
      )}
      {/** 
        //@ts-ignore */}
      <NotificationAlert ref={notificationAlert} />
      <Filters setFilters={setFilters} filtersData={filtersData}></Filters>
      <Row style={{ width: "100%", marginLeft: 3 }}>
        <Col className={scrollable ? "overflow-scroll" : ""}>
          <Row>
            <PendingTable
              data={proofreaders}
              matches={matches}
              columns={columns}
              tag={tag}
              submitAssign={submitAssign}
              cancelAssign={cancelAssign}
              reviewersList={reviewersList}
            />
          </Row>
          <Row>
            <Col md="12">
              <div className="d-flex d-column justify-content-center">
                <HiOutlineArrowNarrowLeft
                  onClick={() => pageNumber !== 1 && setPage(pageNumber - 1)}
                  className={
                    pageNumber !== 1 ? "mt-3 me-2 pointer" : "mt-3 me-2 "
                  }
                  color={pageNumber !== 1 ? "#3367CD" : "#BDBDBD"}
                  size={20}
                />
                <p className="label-grey-normal text-center pt-3 pointer opacity-50">
                  page
                </p>
                <div
                  className="ms-2 mt-3 me-2"
                  style={{
                    width: 20,
                    height: 20,
                    backgroundColor: "#ECF0FB",
                    color: "#3367CD",
                  }}
                >
                  <label className="text-center ms-1">{pageNumber}</label>
                </div>
                <p className="label-grey-normal text-center pt-3 pointer opacity-50">
                  sur {proofreadersDetails.totalPages}
                </p>
                <HiOutlineArrowNarrowRight
                  onClick={() =>
                    pageNumber !== proofreadersDetails.totalPages &&
                    proofreadersDetails.totalPages !== 0 &&
                    setPage(pageNumber + 1)
                  }
                  className={
                    pageNumber !== proofreadersDetails.totalPages &&
                    proofreadersDetails.totalPages !== 0
                      ? "mt-3 ms-2 pointer"
                      : "mt-3 ms-2 "
                  }
                  size={20}
                  color={
                    pageNumber !== proofreadersDetails.totalPages &&
                    proofreadersDetails.totalPages !== 0
                      ? "#3367CD"
                      : "#BDBDBD"
                  }
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default AllProofreaders;
