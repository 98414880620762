import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import SuggestionIcon from "./SuggestionIcon";


const CardIcon = (props: any) => {
    const {state} = props;
    return (
        <div style={{ width: 80, height: 65 }}>
            <CircularProgressbarWithChildren
                value={0}
                background={true}
                styles={{
                    root: {},
                    path: {
                        stroke: `rgb(80, 189, 137)`,
                        strokeLinecap: "butt",
                        transition: "stroke-dashoffset 0.5s ease 0s",
                        strokeWidth: "3px",
                    },
                    trail: {
                        stroke: "rgb(255,255, 255)",
                        strokeLinecap: "butt",
                        strokeWidth: "10px",
                    },
                    background: {
                        fill: "#3367CD",
                        opacity: 0.2
                    },
                }}
            >
                <SuggestionIcon state={state} />
            </CircularProgressbarWithChildren>
        </div>
    )
};

export default CardIcon;
