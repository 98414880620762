import {useState} from "react";
import {Field} from "formik";
import {AiOutlineCaretDown} from "react-icons/ai";
import {BiError} from "react-icons/bi";
import {useAppSelector} from "../../redux/hooks";
import {
    selectUser,
} from "../../redux/slices/userSlice";

const RegisterSelect = (props: any) => {
    //Init props
    const {choices, name, label, handleId, handleTitle, id} =
        props;

    const [categoryId] = useState(0);

    //Init states
    const [open, setOpen] = useState(false);

    const profile = useAppSelector(selectUser);

    const categories = profile.interetApprentissage.map((categorie) => {

        return (
            <li
                data-id={categorie.id}
                className={
                    categorie.id === +id
                        ? `list active`
                        : `list`
                }
                key={categorie.id}
                onClick={() => {
                    handleId(categorie.id)
                    handleTitle(categorie.libelle)
                }}
            >
                {categorie.libelle}
            </li>
        );
    });
    const CustomSelectOption = () => {
        return (
            <div className="formSelect">
                <ul className="lists" style={{display: "flex", flexDirection: "column", gap: "10px"}}>{categories}</ul>
            </div>
        )
    };

    return (
        <>
            <label
                htmlFor="firstName"
                className={
                    !choices.length
                        ? "label-grey-normal formControl"
                        : "label-black-normal formControl"
                }
                color={"#3367CD"}
                onClick={() => (choices.length > 0 ? setOpen(!open) : setOpen(false))}
            >
                {!choices.length ? "Aucun sujet n'est disponible" : label}
                {!choices.length ? (
                    <BiError className="float-end" size={17} color="#8d8d8d"/>
                ) : (
                    <AiOutlineCaretDown color="#BDBDBD" className="float-end"/>
                )}
            </label>

            {open ? (
                <Field
                    component={() => CustomSelectOption()}
                    as="select"
                    id={name}
                    name={name}
                    options={choices[categoryId].subjects}
                    onClick={() => setOpen(false)}
                    className="formSelect"
                    multiple={true}
                    isMulti={true}
                    {...props}
                ></Field>
            ) : null
            }
        </>
    );
};
export default RegisterSelect;
