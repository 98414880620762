import { useMedia } from "react-media";
import { Col, Row } from "reactstrap";
import { useAppDispatch } from "../../../redux/hooks";
import AllProofreaders from "./components/AllProofreaders";

const ProofreadersListingContainer = (props: any) => {
  const dispatch = useAppDispatch();
  const GLOBAL_MEDIA_QUERIES = {
    small: "(max-width: 1477px)",
    large: "(min-width: 1478px)",
  };
  const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
  const { setNavStep, route } = props;
  return (
    <div className="center-container">
      <Row className="p-2 ml-2">
        <Col md="12">
          <Row>
            <AllProofreaders
              dispatch={dispatch}
              scrollable={matches.small}
              setNavStep={setNavStep}
              route={route}
            />
          </Row>
        </Col>
        </Row>
    </div>
  );
};

export default ProofreadersListingContainer;
