import { Col, Row, Spinner } from "reactstrap";

import DetailsHeader from "./components/DetailsHeader";
import DetailsContent from "./components/DetailsContent";
import {
  retrieveFeedback,
  selectTrainingFeedback,
  selectTrainingLoading,
  selectTrainingPreview,
} from "../../../redux/slices/trainingSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import Layout from "../../../components/Layout";
import { useEffect } from "react";

const PublishedTrainingDetailContainer = () => {
  const training: any = useAppSelector(selectTrainingPreview);
  const loading = useAppSelector(selectTrainingLoading);
  const feedback = useAppSelector(selectTrainingFeedback)
  const dispatch = useAppDispatch()
 
  useEffect(()=> {
    dispatch(retrieveFeedback({formationId: training.id}))
  }, [training])

  return (
    <Layout title="Détails de la formation">
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: 600 }}
        >
          <Spinner animation="border" style={{ color: "#3367cd" }} />
        </div>
      ) : (
        <div className="center-container mt-3">
          <Row className="pb-4">
            <Col md="12">
              <DetailsHeader
                training={training}
              />
              <hr className="hr-text-grey p-0 my-4"></hr>
              <DetailsContent
                training={training}
                feedback={feedback}
              />
            </Col>
          </Row>
        </div>
      )}
    </Layout>
  );
};

export default PublishedTrainingDetailContainer;
