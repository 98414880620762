import { IoMdTrash } from "react-icons/io";

const ImgPreview = (props: any) => {
  const { selectedImg, setSelectedImg } = props;
  return selectedImg.map((file: any, idx: number) => {
    return (
      <div key={idx} className="d-flex flex-row download mt-4">
        <div className="d-flex flex-column">
          <img width="400" src={file.preview} alt='img-preview'/>
        </div>
        <div
          className="d-flex flex-row justify-content-center"
          style={{ alignSelf: "center" }}
        >
          <IoMdTrash
            size={18}
            color="#3367cd"
            className="right pointer ms-5 mt-2"
            onClick={() => {
                setSelectedImg(null);
            }}
          />
        </div>
      </div>
    );
  });
};

export default ImgPreview;
