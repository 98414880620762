import { Card, CardBody, Col, Row } from "reactstrap";
import Box from "../../../../components/widget/Box";
import CardIcon from "./CardIcon";
//@ts-ignore
import EllipsisText from "react-ellipsis-text";
import { ReactComponent as QuotesIcon } from "../../../../assets/img/icons/quotes.svg";
import { useAppDispatch } from "../../../../redux/hooks";
import { retrieveTrainingDetail } from "../../../../redux/slices/trainingSlice";
import { useHistory } from "react-router";

const TrainingSuggestions = (props: any) => {
    const { trainingSuggestions } = props;
    const dispatch = useAppDispatch();
    const history = useHistory();
    const navigateToDetails = (id: number) => {
        dispatch(retrieveTrainingDetail({ id }));
        history.push('/trainingDetail');
    };
    return (
        <Row>
            {trainingSuggestions && trainingSuggestions.map((val: any, index: number) => (
                <Col lg="3" md="12" className="p-2 d-flex align-items-stretch" key={index}>
                    <Card body outline className="catalog-table-header">
                        <CardBody className="p-0 d-flex">
                            <Row className="center-container">
                                <CardIcon state={val.type} />
                                <p className="label-black-nqt-sm fw-bold text-center ps-0 pe-0">{val.nom}</p>
                                <div style={{ display: "inline-block" }}>
                                    <div className="categoriesList justify-content-center mb-0">
                                        <Box type={"noBorder"} content={val.theme && val.theme[0] ? val.theme[0] : "Développement personnel"}></Box>
                                    </div>
                                </div>
                                <hr className="hr-style" />
                                <Row className="ps-2 pe-2">
                                    <Col lg="2" className="p-0">
                                    <img
                                       alt="profile"
                                        className={'avatarHome'}
                                              src={
                                                  !val.createur.image
                                                   ? require('../../../../assets/img/faces/Creator.png')
                                                    .default
                                                      : val.createur.image
                                                  }
                                    />
                                    </Col>
                                    <Col lg="10" className="ps-3 pe-0">
                                        <p className="label-black-nqt-sm fw-bolder mb-0"> Créateur </p>
                                        <p className="label-grey-normal"> {val.createur.firstName + " " + val.createur.lastName} </p>
                                    </Col>
                                </Row>
                                <hr className="hr-style" />
                                <Row className="pb-3 ps-0 pe-0">
                                    <Col lg="2" className="text-center ps-1 pe-0">
                                        <QuotesIcon className="ml-0" />
                                    </Col>
                                    <Col lg="10" className="ps-3 pe-1">
                                        <p className="label-black-nqt-sm fw-bolder mb-0"> Description </p>
                                        <EllipsisText className="label-grey-normal suggestions-description" text={val.description} length={"80"} />
                                    </Col>
                                </Row>
                                <Row className="mt-auto">
                                    <hr className="hr-style" />
                                    <p className="label-primary-open-button text-center pt-2 mb-1 pointer" onClick={() => {
                                        navigateToDetails(val.id)
                                    }}>
                                        Ouvrir
                                    </p>
                                </Row>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            ))}
        </Row>
    );
};

export default TrainingSuggestions;
