import { Col, Row, Spinner } from "reactstrap";
import NotificationAlert from "react-notification-alert";

import TrainingSuggestions from "./components/TrainingSuggestions";
import DetailsHeader from "./components/DetailsHeader";
import DetailsContent from "./components/DetailsContent";
import {
  selectTrainingLoading,
  selectTrainingPreview,
  selectTrainingSuggestions,
  selectTrainingSuggestionsLoading,
} from "../../../redux/slices/trainingSlice";
import { useAppSelector } from "../../../redux/hooks";
import TrainingService from "../../../services/training.services";
import { useEffect, useRef, useState } from "react";
import useHttp from "../../../hooks/use-http";
import Layout from "../../../components/Layout";

const TrainingDetailContainer = ({ setNavStep }: any) => {
  const training: any = useAppSelector(selectTrainingPreview);
  const loading = useAppSelector(selectTrainingLoading);
  const trainingSuggestions = useAppSelector(selectTrainingSuggestions);
  const loadingSuggestions = useAppSelector(selectTrainingSuggestionsLoading);
  const [isSaved, setIsSaved] = useState(training.isSaved);
  const [isSubscribed, setIsSubscribed] = useState(training.isSubscribed);
  const notificationAlert = useRef();

  //subscribe training
  const {
    status: subscribeLoading,
    sendRequest: sendSubscribeRequest,
    data: subscribeStatus,
  } = useHttp(TrainingService.postTrainingSubscribe, false);

  const {
    status: saveLoading,
    sendRequest: sendSaveRequest,
    data: saveStatus,
  } = useHttp(TrainingService.postTrainingSave, false);

  useEffect(() => {
    if (subscribeStatus != null) {
      subscribeStatus.status && setIsSubscribed(subscribeStatus.status);
      //@ts-ignore
      notificationAlert.current.notificationAlert({
        place: "tr",
        message: (
          <div>
            <div>
              {subscribeStatus != null
                ? subscribeStatus.status
                  ? "inscription effectuée avec succès."
                  : "vous ne pouvez pas vous inscrire à cette formation."
                : "Erreur serveur"}
            </div>
          </div>
        ),
        type:
          subscribeStatus != null
            ? subscribeStatus.status
              ? "success"
              : "danger"
            : "danger",
        icon: "nc-icon nc-bell-55",
        closeButton: false,
        autoDismiss: 7,
      });
    }
  }, [subscribeStatus]);
  useEffect(() => {
    setIsSaved(training.isSaved);
    setIsSubscribed(training.isSubscribed);
 }, [training]);

  useEffect(() => {
    if (saveStatus != null) {
      saveStatus.status && setIsSaved(saveStatus.status);
      //@ts-ignore
      notificationAlert.current.notificationAlert({
        place: "tr",
        message: (
          <div>
            <div>
              {saveStatus != null
                ? saveStatus.status
                  ? "Formation enregistrée avec succès."
                  : "vous ne pouvez pas enregistrer cette formation."
                : "Erreur serveur"}
            </div>
          </div>
        ),
        type:
          saveStatus != null
            ? saveStatus.status
              ? "success"
              : "danger"
            : "danger",
        icon: "nc-icon nc-bell-55",
        closeButton: false,
        autoDismiss: 7,
      });
    }
  }, [saveStatus]);
  return (
    <Layout title="Détails de la formation">
      {loading || loadingSuggestions ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: 600 }}
        >
          <Spinner animation="border" style={{ color: "#3367cd" }} />
        </div>
      ) : (
        <div className="center-container mt-5">
          {/** 
        //@ts-ignore */}
          <NotificationAlert ref={notificationAlert} />
          <Row className="pb-4">
            <Col md="12">
              <DetailsHeader
                training={training}
                isSaved={isSaved}
                isSubscribed={isSubscribed}
                subscribeLoading={subscribeLoading}
                sendSubscribeRequest={sendSubscribeRequest}
              />
              <hr className="hr-text-grey p-0 my-4"></hr>
              <DetailsContent
                training={training}
                isSaved={isSaved}
                isSubscribed={isSubscribed}
                subscribeLoading={subscribeLoading}
                sendSubscribeRequest={sendSubscribeRequest}
                saveLoading={saveLoading}
                sendSaveRequest={sendSaveRequest}
              />
            </Col>
          </Row>
          <Row className="p-2 ml-2">
            <Col md="12">
              <Row>
                <p className="label-black-md mt-4 ml-4">
                  Ces formations peuvent aussi vous plaire{" "}
                </p>
                <TrainingSuggestions
                  trainingSuggestions={trainingSuggestions}
                  setNavStep={setNavStep}
                />
              </Row>
            </Col>
          </Row>
        </div>
      )}
    </Layout>
  );
};

export default TrainingDetailContainer;
