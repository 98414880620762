import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { ReactComponent as TickIcon } from "../../../../assets/img/icons/tick.svg";

const TrainingsProgressBar = (props: any) => {
  const {
    strokePathColor = props.strokePathColor,
    fillBackgroundColor = props.fillBackgroundColor,
    trainingType = props.trainingType,
    percentage = props.percentage,
  } = { ...props };
  return (
    <div style={{ width: 49, height: 49 }}>
      <CircularProgressbarWithChildren
        value={percentage}
        background={true}
        styles={{
          root: {},
          path: {
            stroke: strokePathColor,
            strokeLinecap: "butt",
            transition: "stroke-dashoffset 0.5s ease 0s",
            strokeWidth: "3px",
          },
          trail: {
            stroke: "rgb(255,255, 255)",
            strokeLinecap: "butt",
            strokeWidth: "9",
          },
          background: {
            fill: fillBackgroundColor,
          },
        }}
      >
        {trainingType === "CompletedTraining" ? (
          <div style={{ fontSize: 12, marginTop: -1 }}>
            <TickIcon />
          </div>
        ) : (
          <div style={{ fontSize: 12, marginTop: 16 }}>
            <p className="label-white-trainings">{percentage}%</p>
          </div>
        )}
      </CircularProgressbarWithChildren>
    </div>
  );
};

export default TrainingsProgressBar;
