import { useEffect, useRef, useState } from "react";
import {
  HiOutlineArrowNarrowLeft,
  HiOutlineArrowNarrowRight,
} from "react-icons/hi";
import { Col, Row, Spinner } from "reactstrap";
//@ts-ignore
import EllipsisText from "react-ellipsis-text";
import ReactTooltip from "react-tooltip";
import Checkbox from "@mui/material/Checkbox";
import { useAppSelector } from "../../../../redux/hooks";
import { ReactComponent as AvatarIcon } from "../../../../assets/img/icons/avatar-person.svg";
import NotificationAlert from "react-notification-alert";
import Filters from "./FiltersEditeur";
import moment from "moment";
import {
  retrieveTrainingNeedsProofReading,
  saveTraining,
  selectTrainingLoading,
  selectTrainingNeedsProofReading,
  selectTrainingNeedsProofReadingCount,
} from "../../../../redux/slices/editorSlice";
import useHttp from "../../../../hooks/use-http";
import EditorService from "../../../../services/editor.service";
import ValidatedTrainingTable from "./ValidatedTableTraining";
import { selectUser } from "../../../../redux/slices/userSlice";
import { useHistory } from "react-router";

function Test(props: any) {
  //Init props
  const { matches, scrollable, dispatch, tag, proofreader, route } =
    props;

  const history = useHistory();

  //Init states from selector
  const trainings = useAppSelector(selectTrainingNeedsProofReading).filter(
    (training: any) => tag ? training.createur.id !== proofreader.id : true
  );
  const loading = useAppSelector(selectTrainingLoading);
  const totalCount = useAppSelector(selectTrainingNeedsProofReadingCount);
  const profile = useAppSelector(selectUser);
  //Init component states
  const [pageNumber, setPage] = useState(1);
  const filtersData = {
    nom: "",
    sujets: [],
    createurs: [],
    types: [],
  };
  const [filters, setFilters] = useState(filtersData);
  const notificationAlert = useRef();
  const [assignedTrainings, setAssignedTrainings] = useState({});
  const [newAssignedTrainings, setNewAssignedTrainings] = useState({});

  /**
   * Get checkbox initial values
   */
  const getInitialValues = () => {
    let values: any = {};
    proofreader.trainings &&
      proofreader.trainings.forEach((val: any) => {
        values[val.id] = proofreader.trainings.findIndex(
          (rev: any) => rev.id === val.id
        );
        setAssignedTrainings(values);
        setNewAssignedTrainings(values);

        return values;
      });
  };

  const { sendRequest ,status: statusAssign,error} = useHttp(
    EditorService.postAssignTrainingsToProofreader
  );

  /**
   * Handle checkbox changes
   * @param event
   * @param data
   */
  const handleChange = async (event: any, data: any) => {
    let values: any = { ...newAssignedTrainings };

    if (event.target.checked) {
      values = {
        ...values,
        [data.id]: proofreader.trainings.findIndex(
          (rev: any) => rev.id === data.id
        ),
      };
      setNewAssignedTrainings(values);
    } else {
      delete values[data.id];
      setNewAssignedTrainings(values);
    }
  };

  /**
   * Cancel checked checkbox
   */
  const cancelAssign = () => {
    setNewAssignedTrainings(assignedTrainings);
  };

  /**
   * Assign submit
   */
  const submitAssign = () => {
    const trainingsIds = Object.keys(newAssignedTrainings).filter(
      (key) => !(key in assignedTrainings)
    );
    sendRequest({ users: [proofreader.id], formations: trainingsIds });
    setAssignedTrainings(newAssignedTrainings);
  };

  //Effects
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      dispatch(
        retrieveTrainingNeedsProofReading({
          page: pageNumber,
          filters,
          userId: tag === "list" ? null : proofreader.id,
        })
      );
    }, 100);
    return () => clearTimeout(delayDebounceFn);
  }, [dispatch, pageNumber, filters, proofreader, tag]);

  useEffect(() => {
    if (statusAssign === "completed") {
      //@ts-ignore
      notificationAlert.current.notificationAlert({
        place: "tr",
        message:
          error === null ? (
            <div>
              {" "}
              <p>Formations assignées avec succés</p>
            </div>
          ) : (
            <div> {" "}
            <p>Formations non assignées</p></div>
          ),
        type: error === null ? "success" : "danger",
        icon: "nc-icon nc-bell-55",
        closeButton: false,
        autoDismiss: 7,
      });
    }
  }, [statusAssign, error]);

  useEffect(() => {
    getInitialValues();
  }, [proofreader]);

  //Define table columns
  const columns = [
    {
      Header: "",
      isSorted: false,
      width: 100,
      accessor: "creatorImage",
      Cell: (props: any) => {
        return (
          <div className="d-flex flex-row align-items-center">
            {tag === "assign" && (
              <Checkbox
                checked={
                  //@ts-ignore
                  newAssignedTrainings[props.row.original.id] !== undefined
                }
                disabled={
                  //@ts-ignore
                  assignedTrainings[props.row.original.id] !== undefined
                }
                onClick={(event) => handleChange(event, props.row.original)}
                inputProps={{ "aria-label": "controlled" }}
                sx={
                  //@ts-ignore
                  assignedTrainings[props.row.original.id] !== undefined
                    ? {
                        color: "#E6E6E6",
                        borderWidth: 1,
                        "&.Mui-checked": { color: "#8D8D8D" },
                      }
                    : {
                        color: "#E6E6E6",
                        borderWidth: 1,
                        "&.Mui-checked": { color: "#3367CD" },
                      }
                }
              />
            )}
              {props.row.original.createur.image ? (
              <img
                alt="profile"
                className={
                  tag === 'assign'
                    ? 'avatar-profile mt-3'
                    : 'avatar-profile ms-4 mt-3'
                }
                style={{ maxWidth: 32, marginTop: '10%' }}
                src={props.row.original.createur.image}
              />
            ) : (
              <AvatarIcon
                style={{ width: 32, height: 32, borderRadius: '50%' }}
              />
            )}
          </div>
        );
      },
    },
    {
      Header: "Nom de formation",
      isSorted: false,
      width: 200,
      accessor: "nom",
    },
    {
      Header: "Sujets",
      isSorted: false,
      width: 100,
      accessor: "sujets",
      Cell: (props: any) => {
        let text = "";
        props.row.original.sujets.map(
          (subject: any) =>
            (text = text !== "" ? text + ", " + subject.label : subject.label)
        );

        return (
          <>
            <EllipsisText text={text} length={20} />
            <ReactTooltip>{text}</ReactTooltip>
          </>
        );
      },
    },
    {
      Header: "Type",
      isSorted: false,
      width: 70,
      accessor: "type",
      Cell: (props: any) => {
        return (
          <svg
            width="16"
            height="18"
            viewBox="0 0 16 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.8333 2.49999H13V1.66666C13 1.20833 12.625 0.833328 12.1667 0.833328C11.7083 0.833328 11.3333 1.20833 11.3333 1.66666V2.49999H4.66667V1.66666C4.66667 1.20833 4.29167 0.833328 3.83333 0.833328C3.375 0.833328 3 1.20833 3 1.66666V2.49999H2.16667C1.24167 2.49999 0.508333 3.24999 0.508333 4.16666L0.5 15.8333C0.5 16.75 1.24167 17.5 2.16667 17.5H13.8333C14.75 17.5 15.5 16.75 15.5 15.8333V4.16666C15.5 3.24999 14.75 2.49999 13.8333 2.49999ZM13 15.8333H3C2.54167 15.8333 2.16667 15.4583 2.16667 15V6.66666H13.8333V15C13.8333 15.4583 13.4583 15.8333 13 15.8333ZM4.66667 8.33333H7.16667C7.625 8.33333 8 8.70833 8 9.16666V11.6667C8 12.125 7.625 12.5 7.16667 12.5H4.66667C4.20833 12.5 3.83333 12.125 3.83333 11.6667V9.16666C3.83333 8.70833 4.20833 8.33333 4.66667 8.33333Z"
              fill="#BDBDBD"
            />
          </svg>
        );
      },
    },
    {
      Header: "Relecteurs",
      isSorted: false,
      width: 170,
      Cell: (props: any) => {
        const elements = props.row.original.reviewers.map(
          (relecteur: any, idx: number) =>
            relecteur.image ? (
              <img
              key={idx}
                alt="profile"
                className="avatar-profile"
                style={
                  idx === 0
                    ? { maxWidth: 32, marginTop: "10%" }
                    : {
                        maxWidth: 32,
                        marginTop: "10%",
                        position: "relative",
                        right: `${idx * 3}%`,
                      }
                }
                src={relecteur.image}
              />
            ) : (
              <AvatarIcon
              key={idx}
                style={
                  idx === 0
                    ? { maxWidth: 30, marginTop: "4%" }
                    : {
                        maxWidth: 30,
                        marginTop: "4%",
                        position: "relative",
                        right: `${idx * 3}%`,
                      }
                }
              />
            )
        );
        while (elements.length < 5) {
          elements.push(
            <svg
            key={elements.length}
              style={
                elements.length === 0
                  ? { maxWidth: 33, marginTop: "4%" }
                  : {
                      maxWidth: 33,
                      marginTop: "4%",
                      position: "relative",
                      right: `${elements.length * 3}%`,
                    }
              }
              width="39"
              height="39"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="16"
                cy="16"
                r="15"
                fill="#ECF0FB"
                stroke="white"
                strokeWidth="2"
              />
              <path
                d="M13 15.73V17.06H15.632V19.818H17.018V17.06H19.65V15.73H17.018V13H15.632V15.73H13Z"
                fill="#3367CD"
              />
            </svg>
          );
        }
        return elements;
      },
    },
    {
      Header: "soumis le",
      isSorted: true,
      width: 150,
      Cell: (props: any) => {
        return props.row.original.submitDate
          ? moment(props.row.original.submitDate).format("DD/MM/YYYY")
          : "-";
      },
      accessor: "submitDate",
    },
    {
      Header: "Notes",
      isSorted: false,
      width: 100,
      accessor: "notes",
      Cell: (props: any) => {
        let text = "";
        props.row.original.notes.map(
          (note: string) => (text = text !== "" ? text + ", " + note : note)
        );

        return (
          <>
            <EllipsisText text={text} length={20} />
            <ReactTooltip>{text}</ReactTooltip>
          </>
        );
      },
    },
    {
      Header: "",
      isSorted: false,
      width: 100,
      accessor: "actions",
      Cell: (props: any) => {
        return (
          <div className="d-flex">
            {tag !== "assign" && (
              <p
                className="label-primary-nqt-sm pointer mt-3"
                onClick={() => {
                  history.push("/editor", {route: "Ajouter un relecteur"})
                  dispatch(saveTraining(props.row.original));
                }}
              >
                Ajouter relecteur
              </p>
            )}
          </div>
        );
      },
    },
  ];
  profile.role.includes('EDITOR-ALUMNI') && columns.pop();

  return (
    <>
      {/** 
        //@ts-ignore */}
      <NotificationAlert ref={notificationAlert} />
      <Filters setFilters={setFilters} filtersData={filtersData}></Filters>
      <Row style={{ width: "100%", marginLeft: 3 }}>
        <Col className={scrollable ? "overflow-scroll" : ""}>
          <Row>
            {loading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: 600 }}
              >
                <Spinner animation="border" style={{ color: "#3367cd" }} />
              </div>
            ) : (
              <ValidatedTrainingTable
                data={trainings}
                matches={matches}
                columns={columns}
                tag={tag}
                route={route}
                submitAssign={submitAssign}
                cancelAssign={cancelAssign}
                assignCount={
                  Object.keys(newAssignedTrainings).filter(
                    (key) => !(key in assignedTrainings)
                  ).length
                }
                disabled={
                  Object.keys(newAssignedTrainings).filter(
                    (key) => !(key in assignedTrainings)
                  ).length === 0
                }
              />
            )}
          </Row>
          <Row>
            <Col md="12">
              <div className="d-flex d-column justify-content-center">
                <HiOutlineArrowNarrowLeft
                  onClick={() => pageNumber !== 1 && setPage(pageNumber - 1)}
                  className={
                    pageNumber !== 1 ? "mt-3 me-2 pointer" : "mt-3 me-2 "
                  }
                  color={pageNumber !== 1 ? "#3367CD" : "#BDBDBD"}
                  size={20}
                />
                <p className="label-grey-normal text-center pt-3 pointer opacity-50">
                  page
                </p>
                <div
                  className="ms-2 mt-3 me-2"
                  style={{
                    width: 20,
                    height: 20,
                    backgroundColor: "#ECF0FB",
                    color: "#3367CD",
                  }}
                >
                  <label className="text-center ms-1">{pageNumber}</label>
                </div>
                <p className="label-grey-normal text-center pt-3 pointer opacity-50">
                  sur {totalCount}
                </p>
                <HiOutlineArrowNarrowRight
                  onClick={() =>
                    pageNumber !== totalCount && totalCount !==0  && setPage(pageNumber + 1)
                  }
                  className={
                    pageNumber !== totalCount && totalCount !==0 
                      ? "mt-3 ms-2 pointer"
                      : "mt-3 ms-2 "
                  }
                  size={20}
                  color={pageNumber !== totalCount && totalCount !==0  ? "#3367CD" : "#BDBDBD"}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default Test;
