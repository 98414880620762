import React, { useEffect, useState } from "react";
import { AiOutlineCaretDown, AiOutlineCaretUp } from "react-icons/ai";
import { Col, PopoverBody, UncontrolledPopover } from "reactstrap";
import useHttp from "../../../../hooks/use-http";
import FiltersService from "../../../../services/filters.service";

interface Company {
  label: string;
}

const CompanyFilters = (props: any) => {
  let data: Company[] = [];
  const { handleChange, reset } = props;
  const [open, setOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<string[]>([]);
  const [text, setText] = useState<string>("");
  const {
    sendRequest,
    status,
    data: companyData,
  } = useHttp(FiltersService.getCompany);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (text.length > 0) {
        sendRequest(text);
      }
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [text, sendRequest]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleChange(selectedCompany);
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [selectedCompany, handleChange]);

  useEffect(() => {
    if (reset) {
      setSelectedCompany([]);
      setText("");
    }
  }, [reset]);
  if (companyData && status === "completed") {
    data = text.length === 0 ? [] : companyData;
  }

  const setCompanySelected = (e: any) => {
    const id = e.currentTarget.dataset.id;
    if (selectedCompany.includes(id)) {
      setSelectedCompany((prev) => {
        const sub = [...prev];
        sub.splice(
          selectedCompany.findIndex((val) => val === id),
          1
        );
        return sub;
      });
    } else {
      setSelectedCompany((prev) => {
        const sub = [...prev];
        sub.push(id);
        return sub;
      });
    }
  };

  const companyElement = data.map((company) => {
    return (
      <li
        className="d-flex subjectList"
        key={company.label}
        data-id={company.label}
        onClick={setCompanySelected.bind(this)}
      >
        <span className="checkBox">{company.label}</span>
        {selectedCompany.includes(company.label) ? (
          <div
            className="checked"
            style={{ marginLeft: "auto", paddingRight: 10 }}
          >
            <svg
              width="9"
              height="7"
              viewBox="0 0 9 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.75015 4.38408L1.481 3.11494C1.22087 2.85481 0.800078 2.85481 0.539947 3.11494C0.279816 3.37507 0.279816 3.79586 0.539947 4.05599L2.28161 5.79766C2.54175 6.05779 2.96254 6.05779 3.22267 5.79766L7.631 1.38933C7.89113 1.12919 7.89113 0.708403 7.631 0.448272C7.3709 0.188171 6.95018 0.188141 6.69004 0.448183C6.69001 0.448212 6.68998 0.448242 6.68995 0.448272L2.75015 4.38408Z"
                fill="#3367CD"
                stroke="#3367CD"
              />
            </svg>
          </div>
        ) : (
          <div
            className="unchecked"
            style={{ marginLeft: "auto", paddingRight: 10 }}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM14 11H11V14C11 14.55 10.55 15 10 15C9.45 15 9 14.55 9 14V11H6C5.45 11 5 10.55 5 10C5 9.45 5.45 9 6 9H9V6C9 5.45 9.45 5 10 5C10.55 5 11 5.45 11 6V9H14C14.55 9 15 9.45 15 10C15 10.55 14.55 11 14 11Z"
                fill="#ECF0FB"
              />
            </svg>
          </div>
        )}
      </li>
    );
  });

  return (
    <>
      <div
        className="label-black-normal formControl d-flex"
        color={"#3367CD"}
        onClick={() => setOpen(!open)}
        onBlur={() => setOpen(false)}
        id="PopoverCompany"
        tabIndex={0}
      >
        <div className="d-flex filter-label">
          <div className="name"> Entreprise</div>
          {selectedCompany.length > 0 && (
            <div className="number"> {selectedCompany.length} </div>
          )}
        </div>
        {open ? (
          <AiOutlineCaretUp color="#3367CD" className="float-end" />
        ) : (
          <AiOutlineCaretDown color="#BDBDBD" className="float-end" />
        )}
      </div>
      <UncontrolledPopover
        placement="bottom"
        target="PopoverCompany"
        trigger="legacy"
      >
        <PopoverBody>
          <Col>
            <div onFocus={() => setOpen(true)} onBlur={() => setOpen(false)}>
              <input
                name="entreprise"
                placeholder="Rechercher une Entreprise"
                className="formControl label-search"
                style={{ width: 300 }}
                onChange={(e) => setText(e.target.value.trim().toLowerCase())}
              />
            </div>
            {companyElement.length > 0 && <div>{companyElement}</div>}
          </Col>
        </PopoverBody>
      </UncontrolledPopover>
    </>
  );
};

export default React.memo(CompanyFilters);
