import { Card, CardBody, Col, Row } from "reactstrap";
import {ReactComponent as BadgeIcon} from '../../../../../assets/img/icons/badge.svg'
const BadgeSlide = (props: any) => {
  const { gamificationObject, oneSlideDisplay } = props;
  return (
    <Card
      body
      outline
      className="card p-0 card-badge swiper-slide-style"
      style={{ zIndex: 0 }}
    >
      <div className="d-flex flex-row position-absolute">
        <BadgeIcon style={{ zIndex: 1, marginTop: -20, marginLeft: -15 }} />
        <p
          className="label-white-sm fw-normal mt-2"
          style={{ zIndex: 2, marginLeft: -80, transform: "rotate(-17deg)" }}
        >
          niveau {gamificationObject.badgeLevel}
        </p>
      </div>
      <CardBody
        className="pt-2 pe-0"
        style={{ maxHeight: 110, minHeight: 110 }}
      >
        <Row>
          <Col xs={8} lg="8">
            <Row>
              <div style={{ float: "left" }} className="badge"></div>
              <Col xs={7} className="pe-0">
                <p
                  className={
                    gamificationObject.role === "Relecteur"
                      ? "label-green-proofreader-lg mb-0 mt-4 ml-1 pe-0"
                      : gamificationObject.role === "Contributeur"
                      ? "label-pink-contributor-lg mb-0 mt-4 ml-1 pe-0"
                      : gamificationObject.role === "Animateur"
                      ? "label-orange-animator-lg mb-0 mt-4 ml-1 pe-0"
                      : gamificationObject.role === "Createur"
                      ? "label-blue-creator-lg mb-0 mt-4 ml-1 pe-0"
                      : "label-light-blue-learner-lg mb-0 mt-4 ml-1 pe-0"
                  }
                >
                  {gamificationObject.accumulatedPoints}
                </p>
              </Col>
              <Col xs={5} className="ps-1">
                <p
                  style={{ textAlign: "left", marginLeft: 0 }}
                  className={
                    gamificationObject.role === "Relecteur"
                      ? "label-green-proofreader-sm mt-4 mb-0"
                      : gamificationObject.role === "Contributeur"
                      ? "label-pink-contributor-sm mt-4 mb-0"
                      : gamificationObject.role === "Animateur"
                      ? "label-orange-animator-sm mt-4 mb-0"
                      : gamificationObject.role === "Createur"
                      ? "label-blue-creator-sm mt-4 mb-0"
                      : "label-light-blue-learner-sm mt-4 mb-0"
                  }
                >
                  Points{" "}
                  {gamificationObject.role === "Relecteur"
                    ? "relecteur"
                    : gamificationObject.role === "Contributeur"
                    ? "contributeur"
                    : gamificationObject.role === "Animateur"
                    ? "animateur"
                    : gamificationObject.role === "Createur"
                    ? "créateur"
                    : "learner"}
                  .
                </p>
              </Col>
            </Row>
            <Row>
              <Col
                xs={12}
                style={{ textAlign: "left", marginLeft: 18 }}
                className={
                  gamificationObject.role === "Relecteur"
                    ? "label-green-proofreader-sm mt-0 ml-0 pl-0"
                    : gamificationObject.role === "Contributeur"
                    ? "label-pink-contributor-sm mt-0 ml-0 pl-0"
                    : gamificationObject.role === "Animateur"
                    ? "label-orange-animator-sm mt-0 ml-0 pl-0"
                    : gamificationObject.role === "Createur"
                    ? "label-blue-creator-sm mt-0 ml-0 pl-0"
                    : "label-light-blue-learner-sm mt-0 ml-0 pl-0"
                }
              >
                {gamificationObject.nbActions}
                {gamificationObject.role === "Relecteur"
                  ? " formations relues "
                  : gamificationObject.role === "Contributeur"
                  ? " actions sur des posts "
                  : gamificationObject.role === "Animateur"
                  ? " formations animées "
                  : gamificationObject.role === "Createur"
                  ? " formations publiées "
                  : " formations terminées "}
                / {gamificationObject.nbMissingActions}
              </Col>
            </Row>
          </Col>
          <Col xs={4} className="p-0 m-0">
            {gamificationObject.role === "Relecteur" ? (
              <img
                alt="..."
                style={
                  oneSlideDisplay
                    ? { paddingLeft: "90px" }
                    : { paddingLeft: "0px" }
                }
                src={
                  require("../../../../../assets/img/swiperImages/proofreaderSlide.png")
                    .default
                }
              />
            ) : gamificationObject.role === "Contributeur" ? (
              <img
                alt="..."
                style={
                  oneSlideDisplay
                    ? { paddingLeft: "90px" }
                    : { paddingLeft: "0px" }
                }
                src={
                  require("../../../../../assets/img/swiperImages/contributorSlide.png")
                    .default
                }
              />
            ) : gamificationObject.role === "Animateur" ? (
              <img
                alt="..."
                style={
                  oneSlideDisplay
                    ? { paddingLeft: "90px" }
                    : { paddingLeft: "0px" }
                }
                src={
                  require("../../../../../assets/img/swiperImages/animatorSlide.png")
                    .default
                }
              />
            ) : gamificationObject.role === "Createur" ? (
              <img
                alt="..."
                style={
                  oneSlideDisplay
                    ? { paddingLeft: "90px" }
                    : { paddingLeft: "0px" }
                }
                src={
                  require("../../../../../assets/img/swiperImages/creatorSlide.png")
                    .default
                }
              />
            ) : (
              <img
                alt="..."
                style={
                  oneSlideDisplay
                    ? { paddingLeft: "90px" }
                    : { paddingLeft: "0px" }
                }
                src={
                  require("../../../../../assets/img/swiperImages/learnerSlide.png")
                    .default
                }
              />
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
export default BadgeSlide;
