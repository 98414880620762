import { useEffect, useRef, useState } from "react";
import NotificationAlert from "react-notification-alert";
import { useHistory, useLocation } from "react-router";
import { MdDone } from "react-icons/md";

import CreatePost from "./components/CreatePost";
import News from "./components/News";
import "../../../styles/clubnqt.scss";
import useHttp from "../../../hooks/use-http";
import ClubNqtService from "../../../services/clubnqt.service";
import Layout from "../../../components/Layout";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  selectTrainingPreviewDetail,
  trainingActions,
} from "../../../redux/slices/trainingSlice";

const ConditionalWrapper = ({ condition, wrapper, children }: any) =>
  condition ? children : wrapper(children);

const ClubNqtContainer = (props: any) => {
  const {layoutDisplayed} = props;
  //Init states
  const [club, setClub] = useState("national");
  const [hashtagFilter, setHashtagFilter] = useState("");
  const [type, setType] = useState("");
  const [postId, setPostId] = useState(0);
  const [isMyPostsTab, setIsMyPostsTab] = useState(false);

  const trainingEditWithoutId = useAppSelector(selectTrainingPreviewDetail);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location: any = useLocation();
  const notificationAlert = useRef();
  const [fromTraining, setFromTraining] = useState(
    (location.state && location.state.fromTraining) || false
  );

  useEffect(() => {
    fromTraining && location.state?.fromTraining &&
      //@ts-ignore
      notificationAlert.current.notificationAlert({
        place: "tr",
        message: (
          <div>
            <p className="label-black fw-bold">
              <MdDone
                size={30}
                color="#50BD89"
                style={{
                  width: 40,
                  height: 40,
                  backgroundColor: "#19875421",
                  padding: 10,
                  borderRadius: "50%",
                }}
                className="me-2"
              />
              {location.state.edit
                ? "Votre formation a été modifiée"
                : location.state.draft
                ? "Votre formation a été enregistrée"
                : location.state.published
                ? "Votre formation a été publiée"
                : "Votre formation a été envoyée pour validation"}
            </p>
          </div>
        ),
        type: "light",
        closeButton: false,
        autoDismiss: 7,
      }) &&
      setFromTraining(false);
  }, [fromTraining, setFromTraining, location]);

  useEffect(() => {
    // @ts-ignore
    if (
      trainingEditWithoutId.typology !== "" &&
      !trainingEditWithoutId.trainingId
    ) {
      history.push("/training");
      dispatch(
        trainingActions.setModal({ show: true, targetRoute: "clubNqt" })
      );
    } else {
      dispatch(trainingActions.setModal({ targetRoute: "clubNqt" }));
    }
  }, [trainingEditWithoutId]);

  //Init requests callBack
  const {
    sendRequest: retrievePosts,
    data: postsByClub,
    status,
  } = useHttp(ClubNqtService.getPosts);

  const {
    sendRequest: getPostsByUser,
    data: postsByUser,
  } = useHttp(ClubNqtService.getPostsByUser);

  const {
    sendRequest: retrieveCommentByPost,
    data: commentsByPost,
    status: loading,
  } = useHttp(ClubNqtService.getCommentsByPost);

  const {
    sendRequest: sendReactionRequest,
    data: reaction,
    status: reactionLoading,
  } = useHttp(ClubNqtService.reactToPost);

  const { sendRequest: postComment, status: statusComment } = useHttp(
    ClubNqtService.postComment
  );

  const { sendRequest: retrievePost, data: post } = useHttp(ClubNqtService.getPost);
  const { sendRequest: deletePost, error: deleteError, status: deleteStatus } = useHttp(ClubNqtService.deletePost);

  const [postData, setPostData] = useState(postsByClub);
  const [commentsData, setCommentsData] = useState([]);
  const [postToUpdate, setPostToUpdate] = useState();

  const handleUpdatePostClick = (postId: any) => retrievePost(postId);

  useEffect(() => {
    window.scrollTo(0, 0);
    setPostToUpdate(post);
  }, [post]);

  /**
   * Post reactions callBack
   * @param reaction
   * @param id
   */
  const reactToPost = (reaction: any, id: number) => {
    let postReaction = postsByClub.data.filter((post: any) => post.id === id)[0]?.userReaction;
    sendReactionRequest({
      post_id: id,
      reaction_type: postReaction === reaction ? "" : reaction,
    });
  };
  /**
   * Send comment
   * @param text
   * @param id
   * @param setFieldValue
   */
  const sendComment = (text: any, id: number, mentions: string[]) => {
    postComment({ post_id: id, text, mentions });
    setPostId(id);
    setType("comment");
  };

  const updatePostReaction = () => {
    let array = { ...postData };
    array.data.map((post: any) => {
      if (post.id === reaction.post_id) {
        post.userReaction = reaction.reaction_type;
        post.likeCount = reaction.likeCount;
        post.reactions = reaction.reactions;
      }
      return null;
    });
    setPostData(array);
  };

  useEffect(() => {
    if(club !== "") setIsMyPostsTab(false);

    if (statusComment === "completed") {
      retrieveCommentByPost({ post_id: postId, page: 1 });
      (club !== "") && retrievePosts({ club, hashtag: hashtagFilter });
    }
  }, [
    statusComment,
    postId,
    club,
    hashtagFilter,
    retrieveCommentByPost,
    retrievePosts,
  ]);

  //Effects
  useEffect(() => {
    retrievePosts({ club, hashtag: hashtagFilter });
  }, []);

  const mergeCommentsData = () => {
    setCommentsData((prev) =>
      type === "pagination" ? prev.concat(commentsByPost) : commentsByPost
    );
  };

  useEffect(() => {
    loading === "completed" && mergeCommentsData();
  }, [commentsByPost]);

  useEffect(() => {
    reactionLoading === "completed" && updatePostReaction();
  }, [reaction]);

  useEffect(() => {
    if(postsByClub) {
      if (postsByClub.current_page > 1) {
        const newData = postData.data;
        const obj = { ...postsByClub };
        const { data, ...newPostData } = obj;
        newPostData.data = newData.concat(data);
        setPostData(newPostData);
      } else {
        setPostData(postsByClub);
      }
    }
  }, [postsByClub]);
  useEffect(() => {
    if(postsByUser) {
      if (postsByUser.current_page > 1) {
        const newData = postData.data;
        const { data, ...newPostData } = postsByUser;
        newPostData.data = newData.concat(data);
        setPostData(newPostData);
      } else {
        setPostData(postsByUser);
      }
    }
  }, [postsByUser]);

  useEffect(() => {
    if (deleteStatus === "completed") {
      deleteError === null && retrievePosts();
    }
  }, [deleteStatus, deleteError]);

  return (
    <ConditionalWrapper
      condition={layoutDisplayed}
      wrapper={(children: any) => <Layout title="Club NQT">{children}</Layout>}
    >
      {/* @ts-ignore */}
      <NotificationAlert ref={notificationAlert} />
      <div>
        <CreatePost
          retrievePosts={retrievePosts}
          postToUpdate={postToUpdate}
          setPostToUpdate={setPostToUpdate}
        />
        <News
          status={status}
          data={postData}
          retrievePosts={retrievePosts}
          reactToPost={reactToPost}
          handleUpdatePostClick={handleUpdatePostClick}
          deletePost={deletePost}
          club={club}
          setClub={setClub}
          hashtagFilter={hashtagFilter}
          setHashtagFilter={setHashtagFilter}
          sendComment={sendComment}
          retrieveCommentByPost={retrieveCommentByPost}
          commentsByPost={commentsData}
          loading={loading}
          setCommentsData={setCommentsData}
          setType={setType}
          isMyPostsTab={isMyPostsTab}
          setIsMyPostsTab={setIsMyPostsTab}
          getPostsByUser={getPostsByUser}
        />
      </div>
    </ConditionalWrapper>
  );
};

export default ClubNqtContainer;
