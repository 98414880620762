import Pusher from "pusher-js";

const getPusher = (baseUrl: any) => {
  const pusher = new Pusher(
    window.location.href.includes("clubmentors")
      ? "c323579f2de7a267ad43"
      : "8de02a6b9af82a706666",
    {
      cluster: "eu",
      authEndpoint: baseUrl + "/broadcasting/auth",

      auth: {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      },
    }
  );
  pusher.connection.bind("error", function (err: any) {
    console.error("err", err);
  });
  return pusher;
};

export default getPusher;
