import http from "../config/httpCommon";
import httpForm from "../config/httpCommonForm"
import { TrainingResult } from "../config/training";

const ClubNqtService = {
  getPosts(data: FormData) {
    return new Promise(async (resolve, reject) => {
      (await http())
        .get("/community/posts", { params: data })
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },
  getPostsByUser(data: FormData) {
    return new Promise(async (resolve, reject) => {
      (await http())
        .get(`/community/posts/my-posts`, { params: data })
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },
  getPost(id: number) {
    return new Promise(async (resolve, reject) => {
      (await http())
        .get("/postView/" + id)
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },
  savePost(data: any) {
    console.log("data to save",data)
    console.log("file to save",data.get("file"))
    return new Promise(async (resolve, reject) => {
      (await httpForm())
        .post("/community/posts", data)
        .then((response: any) => {return resolve(response.data)})
        .catch((error: any) => reject(error.message));
    });
  },
  getCities(query: string) {
    return new Promise(async (resolve, reject) => {
      (await http())
        .get(`/community/regions?q=${query}`)
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },
  shareTrainingResult(data: TrainingResult) {
    return new Promise(async (resolve, reject) => {
      (await http())
        .post("/community", data)
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },
  reactToPost(data: TrainingResult) {
    return new Promise(async (resolve, reject) => {
      (await http())
        .post("/community/reaction", data)
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },
  postComment(data: TrainingResult) {
    return new Promise(async (resolve, reject) => {
      (await http())
        .post("/community/comment", data)
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },
  getCommentsByPost(data: any) {
    const { page, post_id } = data;
    return new Promise(async (resolve, reject) => {
      (await http())
        .get("/community/Listcomment", { params: { limit: 3, page, post_id } })
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },
  editPost(data: any) {
    let id: any;
    data.forEach((value: any, key: any) => {
      if (key === "data") id = JSON.parse(value).id;
    });

    return new Promise(async (resolve, reject) => {
      (await http())
        .post(`/community/posts/${id}`, data)
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },
  deletePost(postId: number) {
    return new Promise(async (resolve, reject) => {
      (await http())
        .delete(`/community/posts/${postId}`)
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },
};

export default ClubNqtService;
