import { Col, Row } from "reactstrap";

import { ReactComponent as DoneOutlinedIcon } from "../../../../assets/img/icons/circleDone.svg";
import { ReactComponent as PreviewIcon } from "../../../../assets/img/icons/preview.svg";

import moment from "moment";
import { useHistory } from "react-router";

const DetailsHeader = (props: any) => {
  const { training } = props;
  const history = useHistory();
  const isAsync = () => (training.typology === "async" ? true : false);
  const isPres = () => (training.eventDetails.type === "pres" ? true : false);

  /**
   * Get Title
   */
  const getTitle = (type: string) => 
     type === "pdf"
      ? "PDF"
      : type === "ppt" || type === "pptx"
      ? "Power Point"
      : type === "jpg" || type === "jpeg" || type === "png"
      ? "Image"
      : type === "mp3"
      ? "Podcast"
      : type === "mp4"
      ? "Vidéo"
      : type === "xlsx" || type === "xls"
      ? "Excel"
      : "-";
  ;
  const getContentTypes = () => {
    let contentType: string = "";
    isAsync()
      ? training.sections &&
        training.sections.map((section: any) =>
          section.sectionContent.map((content: any) => {
            if (content.type === "text") {
              contentType =
                contentType === "" ? "Texte" : contentType + ", Texte";
            }
            if (content.type === "upload") {
              contentType =
                contentType === ""
                  ? getTitle(content.fileType)
                  : contentType + ", " + getTitle(content.fileType);
            }
            return null;
          })
        )
      : training.eventDetails.section.files.map(
          (file: any) =>
            (contentType =
              contentType === ""
                ? getTitle(file.fileType)
                : contentType + ", " + getTitle(file.fileType))
        );
    return contentType || "-";
  };

  const getMeetingTool = () => {
    const url = training.eventDetails.location.toLowerCase();
    return url.includes("meet.google")
      ? "Google Meet"
      : url.includes("skype")
      ? "Skype"
      : url.includes("teams")
      ? "Teams"
      : url.includes("zoom")
      ? "Zoom"
      : url.includes("slack")
      ? "Slack"
      : url.includes("whereby")
      ? "Whereby"
      : "-";
  };

  return (
    <Row>
      <Col md="12">
        <div className="d-flex flex-row">
          <div className="d-flex flex-row w-50">
            <DoneOutlinedIcon className="me-3" />
            <p className="label-black-nqt-sm mt-2 fw-bolder">
              Formation publiée
            </p>
          </div>
          <PreviewIcon
            className="pointer float-end"
            onClick={() => history.push("/trainingPreview/" + training.id)}
          />
        </div>
        <div className="d-flex flex-row mt-1">
          <div className="d-flex flex-column">
            <p className="label-grey-trainings-sm mt-2">TYPE DE FORMATION</p>
            <p className="label-black">{isAsync() ? "Autonome" : "Synchrone"}</p>
          </div>
          <div className="d-flex flex-column ms-3">
            <p className="label-grey-trainings-sm mt-2 ">
              {isAsync() ? "FORMATION PUBLIEE LE" : "DATE DE LA FORMATION"}
            </p>
            <p className="label-black mt-0">
              {isAsync()
                ? training.datePub
                  ? moment(training.datePub).format("DD/MM/YYYY")
                  : "-"
                : "Le " +
                  moment(training.eventDetails.date).format("DD/MM/YYYY")}
            </p>
          </div>
          <div className="d-flex flex-column ms-3">
            <p className="label-grey-trainings-sm mt-2 ">
              {isAsync()
                ? "NOMBRE D'ETAPES"
                : isPres()
                ? "LIEU DE LA FORMATION"
                : "OUTIL POUR SUIVRE LA FORMATION"}
            </p>
            <p className="label-black mt-0">
              {isAsync()
                ? training.sections.length
                : isPres()
                ? training.eventDetails.location
                : getMeetingTool()}
            </p>
          </div>
          <div className="d-flex flex-column ms-3">
            <p className="label-grey-trainings-sm mt-2 ">
              {isAsync() ? "NOMBRE DE LECTEURS" : "NOMBRE D'INSCRITS"}
            </p>
            <p className="label-black mt-0">
              {training.subscribers >1? training.subscribers + " mentors": training.subscribers + " mentor"}
            </p>
          </div>
        </div>
      </Col>
    </Row>
  );
};
export default DetailsHeader;
