import {Modal} from "react-bootstrap";
import {Button} from "reactstrap";

const ModalConfirmation = (props: any) => {
    const {title, subTitle, isVisible, cancel, confirm} = props;
    return (
        <Modal show={isVisible}
               aria-labelledby="contained-modal-title-vcenter"
               centered
               dialogClassName="modal-90w"
        >
            <div className={'center-modal'}>
                <div className={'modal-image-container'}>
                    <img
                        className={'modal-image'}
                        alt="..."
                        src={require("../../assets/img/error.png").default}
                    />
                </div>
                <p className={'modal-big-title'}>{title}</p>
                <p className={'modal-sub-title'}>{subTitle} </p>
                <div className={'modal-button-container'}>
                    <Button
                        onClick={cancel}
                        className="btn-modal-cancel"
                        uppercase={false}
                    ><p>Annuler</p></Button>
                    <Button
                        onClick={confirm}
                        className="btn-modal-save"
                        uppercase={false}
                    ><p>Confirmer</p></Button>
                </div>

            </div>
        </Modal>
    );
};
export default ModalConfirmation;
