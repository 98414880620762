import http from "../config/httpCommon";

const UserService = {
  getMe() {
    return new Promise(async (resolve, reject) => {
      (await http())
        .get("/users/profile")
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },
  postRegisterStep(step: number) {
    return new Promise(async(resolve, reject) => {
      (await http())
        .put("users/inscription-step", { step })
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },
  setInteretApprentissage(interetApprentissage: number []) {
    return new Promise(async (resolve, reject) => {
      (await http())
        .post("/users/learning-interest", {interetApprentissage})
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },
  setSectors(sectorsIds: number []) {
    return new Promise(async (resolve, reject) => {
      (await http())
        .post("/user/sectors", {sectorsIds})
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },

  changeProofreadingStatus() {
    return new Promise(async (resolve, reject) => {
      (await http())
        .put("/users/relecteur")
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },
  resetUser() {
    return new Promise(async (resolve, reject) => {
      (await http())
        .post("/user/reset")
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  }
};

export default UserService;
