
import { ReactComponent as PDFIcon } from "../../../../assets/img/icons/PDFIcon.svg";
import { ReactComponent as Steps } from "../../../../assets/img/icons/steps.svg";
import { ReactComponent as PowerPointIcon } from "../../../../assets/img/icons/ppt.svg";
import { ReactComponent as EventIcon } from "../../../../assets/img/icons/event.svg";
import { ReactComponent as MixedTrainingIcon } from "../../../../assets/img/icons/mixte.svg";
import { ReactComponent as PodcastIcon } from "../../../../assets/img/icons/podcast.svg";
import { ReactComponent as VisioIcon } from "../../../../assets/img/icons/visio.svg";

const SavedTrainingIcon = (props: any) => {
  const { trainingType = props.type } = { ...props };
  return trainingType === "PDF" ? (
    <div className="center-svg-rectangle">
      <PDFIcon className="center-svg" />
    </div>
  ) : trainingType === "Etapes" ? (
    <div className="center-svg-rectangle">
      <Steps className="center-svg" />
    </div>
  ) : trainingType === "PowerPoint" ? (
    <div className="center-svg-rectangle">
      <PowerPointIcon className="center-svg" />
    </div>
  ) : trainingType === "Évènement" ? (
    <div className="center-svg-rectangle">
      <EventIcon className="center-svg" />
    </div>
  ) : trainingType === "Mixtes" ? (
    <div className="center-svg-rectangle">
      <MixedTrainingIcon className="center-svg" />
    </div>
  ) : trainingType === "Podcast" ? (
    <div className="center-svg-rectangle">
      <PodcastIcon className="center-svg" />
    </div>
  ) : (
    <div className="center-svg-rectangle">
      <VisioIcon className="center-svg" />
    </div>
  );
};

export default SavedTrainingIcon;
