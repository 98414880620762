import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { useAppSelector } from "../../../../redux/hooks";
import {
    getCreatedTrainings,
    selectPublishedTrainings,
} from "../../../../redux/slices/trainingSlice";
import TrainingIcon from "./TrainingIcon";
//@ts-ignore
import EllipsisText from "react-ellipsis-text";
import { ReactComponent as ShowMoreIcon } from "../../../../assets/img/icons/showMoreIcon.svg";
import TrainingTable from "../../trainingCourses/components/TrainingTable";

function PublishedTrainings(props: any) {
    const { scrollable, dispatch } = props;
    const trainings = useAppSelector(selectPublishedTrainings);
    const [pageNumber, setPage] = useState(1);
    useEffect(() => {
        dispatch(
            getCreatedTrainings({
                page: pageNumber,
                formationStatus: "published",
                tag: "publishedTrainings",
            })
        );
    }, [dispatch, pageNumber]);

    const columns = React.useMemo(
        () => [
            {
                Header: "",
                width: 80,
                accessor: "publishedTrainingsIcon",
                Cell: () => {
                    return <TrainingIcon state={"Published"}></TrainingIcon>;
                },
            },
            {
                Header: "Nom de formation",
                maximal: 200,
                accessor: "nom",
            },
            {
                Header: "Sujets",
                maximal: 185,
                accessor: "sujets",
                Cell: (props: any) => {
                    return (
                        <EllipsisText text={props.row.original.sujets} length={"20"} />
                    );
                },
            },
            {
                Header: "Nombre d'inscrits",
                maximal: 185,
                accessor: "nbInscription",
                Cell: (props: any) => {
                    return props.row.original.nbInscription === 1 ? (
                        <p>Suivie par {props.row.original.nbInscription} mentor</p>
                    ) : (
                        <p>Suivie par {props.row.original.nbInscription} mentors</p>
                    );
                },
            },
            {
                Header: "Créateur",
                maximal: 185,
                accessor: "createur",
            },
            {
                Header: "Créé le",
                maximal: 180,
                accessor: (d: any) => {
                    return moment(d.dateCreat).local().format("DD/MM/YYYY");
                },
            },
        ],
        []
    );
    return (
        <Row style={{ marginLeft: "15px", paddingRight: "35px" }}>
            <Col className={scrollable ? "overflow-scroll" : ""}>
                <Row>
                    <TrainingTable data={trainings} columns={columns} />
                </Row>
                {(
                    <Row>
                        <Col md="12">
                            <p
                                className="label-grey text-center pt-3 pointer"
                                onClick={() => {
                                    let page = pageNumber + 1;
                                    dispatch(
                                        getCreatedTrainings({
                                            page,
                                            formationStatus: "published",
                                            tag: "publishedTrainings",
                                        })
                                    );
                                    setPage(page);
                                }}
                            >
                                Afficher plus
                                <ShowMoreIcon style={{ marginLeft: "10px" }} />
                            </p>
                        </Col>
                    </Row>
                )}
            </Col>
        </Row>
    );
}

export default PublishedTrainings;
