
import { useHistory } from "react-router";
import { Button, Card, Col, Row } from "reactstrap";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { selectUser, updateRegisterStep } from "../redux/slices/userSlice";

const Welcome = () => {
  const profile = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const history = useHistory();
  return (
    <div className="container error-container">
      <Col md="12">
        <Row className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
          <Col md="8">
            <Card className="error-card">
              <div className="m-5">
                <svg
                  width="164"
                  height="164"
                  viewBox="0 0 164 164"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="82" cy="82" r="82" fill="#ECF0FB" />
                </svg>
              </div>
              <h5 className="label-black-lg text-center">
                Bienvenue dans le club NQT
              </h5>
              <h5 className="label-black-lg text-center">{profile.nom} ! 🎉</h5>
              <Row className="d-flex flex-column align-items-center">
                <Col md="8" className="d-flex flex-column align-items-center">
                  <p className="label-black-nqt-sm text-center">
                    Vous pouvez dès maintenant accéder à votre espace personnel Club.
                  </p>
                </Col>
              </Row>
              <Row className="d-flex flex-column align-items-center">
                <Button
                  className="btn-round-sm"
                  onClick={() => {
                    dispatch(updateRegisterStep({ step:4 }));
                    history.push("/home");
                  }}
                  color="primary"
                  type="submit"
                >
                  Accéder à mon profil
                </Button>
              </Row>
            </Card>
          </Col>
        </Row>
      </Col>
    </div>
  );
};

export default Welcome;
