import { useState } from "react";
import { Col, PopoverBody, UncontrolledPopover } from "reactstrap";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";
const EmojiSelector = (props: any) => {
  const { setPostContent } = props;
  const [open, setOpen] = useState(false);

  const addEmoji = (emoji: any) => {
    setPostContent((prev: string) => {
      const content = prev + emoji.native;
      return content;
    });
  };
  return (
    <>
      <span
        className="emoji"
        tabIndex={0}
        onClick={() => setOpen(!open)}
        onBlur={() => setOpen(false)}
        id="PopoverEmoji"
      >
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.9935 0.333313C3.3135 0.333313 0.333496 3.31998 0.333496 6.99998C0.333496 10.68 3.3135 13.6666 6.9935 13.6666C10.6802 13.6666 13.6668 10.68 13.6668 6.99998C13.6668 3.31998 10.6802 0.333313 6.9935 0.333313ZM4.66683 4.33331C5.22016 4.33331 5.66683 4.77998 5.66683 5.33331C5.66683 5.88665 5.22016 6.33331 4.66683 6.33331C4.1135 6.33331 3.66683 5.88665 3.66683 5.33331C3.66683 4.77998 4.1135 4.33331 4.66683 4.33331ZM10.1402 8.81331C9.5335 10.1133 8.36016 11 7.00016 11C5.64016 11 4.46683 10.1133 3.86016 8.81331C3.7535 8.59331 3.9135 8.33331 4.16016 8.33331H9.84016C10.0868 8.33331 10.2468 8.59331 10.1402 8.81331ZM9.3335 6.33331C8.78016 6.33331 8.3335 5.88665 8.3335 5.33331C8.3335 4.77998 8.78016 4.33331 9.3335 4.33331C9.88683 4.33331 10.3335 4.77998 10.3335 5.33331C10.3335 5.88665 9.88683 6.33331 9.3335 6.33331Z"
            fill="#8D8D8D"
          />
        </svg>
      </span>
      <UncontrolledPopover
        placement="bottom"
        target="PopoverEmoji"
        trigger="legacy"
      >
        <PopoverBody>
          <Col onFocus={() => setOpen(true)} onBlur={() => setOpen(false)}>
            <Picker
              set="twitter"
              onSelect={(emoji) => addEmoji(emoji)}
              i18n={{
                search: "Recherche",
                categories: {
                  search: "Résultats de recherche",
                  recent: "Récents",
                },
              }}
            />
          </Col>
        </PopoverBody>
      </UncontrolledPopover>
    </>
  );
};

export default EmojiSelector;
