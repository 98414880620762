import classes from './Rate.module.css';

const Rate = (props: any) => {
    return (
        <div className={classes.taux}>
       {props.percentage !== '100' ? <div> Renseigné à {props.percentage} %</div>: <div> Renseigné sur App NQT</div>} 
      </div>
    );
}

export default Rate;
