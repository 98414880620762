import { Card, Spinner } from "reactstrap";
import { useAppSelector } from "../../../../redux/hooks";
import { selectTrainingCoursesCountLoading } from "../../../../redux/slices/trainingSlice";

const TrainingCountCard = (props: any) => {
  const {
    trainingLabel = props.trainingLabel,
    trainingStatus = props.trainingStatus,
    state = props.state,
    count = props.count,
  } = { ...props };
  let trainingsNumber: number = 0;
  const countLoading = useAppSelector(selectTrainingCoursesCountLoading);

  return (
    <Card body color="light" outline className="card rounded-borders">
      <div className="d-flex flex-row">
        <div className="d-flex flex-column">
          <p
            className={
              "mb-0 ms-3 " +
              (trainingLabel === "Mes brouillons"
                ? "label-primary-nqt-md"
                : "label-black-md")
            }
          >
            {trainingLabel}
          </p>
          <p
            className={
              "mb-0 ms-3 " +
              (trainingStatus === "de formation"
                ? "label-black-md"
                : "label-primary-nqt-md")
            }
          >
            {trainingStatus}
          </p>
        </div>
      </div>
      <div className="d-flex align-items-end flex-column">
        {count.map((val: any) => {
          if (val.status === state) {
            trainingsNumber = val.count;
          }
        })}
        <div className="mt-auto p-2">
          {countLoading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: 50 }}
            >
              <Spinner animation="border" style={{ color: "#3367cd" }} />
            </div>
          ) : (
            <p className="mb-0 ms-3 label-primary-nqt-xlg">{trainingsNumber}</p>
          )}
        </div>
      </div>
    </Card>
  );
};

export default TrainingCountCard;
