import { AiFillWarning } from "react-icons/ai";

const Alert = (props: any) => {
  //Init props
  const { label } = props;

  return (
    <div className="alert">
      <div className="row">
        <div className="col-md-10">
          <label className="label-black">{label}</label>
        </div>
        <div className="col-md-2">
          <AiFillWarning className="right" size={17} color="#ff66a9" />
        </div>
      </div>
    </div>
  );
};
export default Alert;
