import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { useState } from "react";
//@ts-ignore
import Modal from "react-modal";
import { ReactComponent as AIcon } from "../../../../assets/img/icons/A.svg";
import { ReactComponent as BIcon } from "../../../../assets/img/icons/B.svg";
import { ReactComponent as CIcon } from "../../../../assets/img/icons/C.svg";
import { ReactComponent as ChevronIcon } from "../../../../assets/img/icons/chevron.svg";
import { ReactComponent as ChevronBackIcon } from "../../../../assets/img/icons/chevronBack.svg";
import { ReactComponent as ChevronBottom } from "../../../../assets/img/icons/chevronBottom.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/img/icons/close.svg";
import { ReactComponent as CircleIcon } from "../../../../assets/img/icons/circle.svg";
import { ReactComponent as DIcon } from "../../../../assets/img/icons/D.svg";
import { ReactComponent as InterrogationIcon } from "../../../../assets/img/icons/_.svg";
import { ReactComponent as DoneSmallIcon } from "../../../../assets/img/icons/doneSmall.svg";
import useHttp from "../../../../hooks/use-http";
import TrainingService from "../../../../services/training.services";

const CommentModal = (props: any) => {
  //Init props
  const { modalIsOpen, closeModal, values, setFieldValue } = props;
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [proofreaderKey, setProofreaderKey] = useState(1);
  const [proofreader, setProofreader] = useState(
    values.process.proofreaders[0]
  );
  const [openProcessedComments, setOpenProcessedComments] = useState(true);
  const { sendRequest } = useHttp(TrainingService.putComment, true);
  const handleDetails = (proofreaderKey: number, proofreader: any) => {
    setIsDetailsOpen(true);
    setProofreader(proofreader);
    setProofreaderKey(proofreaderKey);
  };
  const getCommentCount = (proofreaderCurrent: any) => {
    let count = 0;
    proofreaderCurrent.comments.map((comment: any) => {
      !comment.done && ++count;
      return null
    });
    return count;
  };
  const getComments = () => (
    <>
      <div className="d-flex flex-row">
        <div className="w-100  mt-4">
          <p className="label-black-trainings fw-bold">
            Commentaires à traiter
          </p>
        </div>
        <p className="align-middle mt-4 ps-1 outlined-circle">
          {getCommentCount(proofreader)}
        </p>
      </div>
      {proofreader.comments.map((comment: any, commentKey: number) => {
        return (
          !comment.done && (
            <div className="d-flex flex-row">
              <CircleIcon
                className="me-2 pointer"
                onClick={async () => {
                  sendRequest(comment.id);
                  await setFieldValue(
                    `process.proofreaders[${
                      proofreaderKey - 1
                    }].comments[${commentKey}].done`,
                    true
                  );
                  const object = { ...proofreader };
                  object.comments[commentKey].done = true;
                  setProofreader(object);
                }}
              />
              <p className="label-black">{comment.title}</p>
            </div>
          )
        );
      })}
      <div className="d-flex flex-row">
        <div className="w-100 mt-2">
          <p className="label-black-trainings fw-bold">Commentaires traités</p>
        </div>
        {openProcessedComments ? (
          <ChevronBottom
            className="mt-3 pointer"
            onClick={() => setOpenProcessedComments(!openProcessedComments)}
          />
        ) : (
          <ChevronIcon
            className="mt-3 pointer"
            onClick={() => setOpenProcessedComments(!openProcessedComments)}
          />
        )}
      </div>
      {openProcessedComments &&
        proofreader.comments.map(
          (comment: any) =>
            comment.done && (
              <div className="d-flex flex-row">
                <DoneSmallIcon className="me-2" />
                <p className="label-grey-normal">{comment.title}</p>
              </div>
            )
        )}
    </>
  );

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      className="Modal"
      overlayClassName="Overlay"
      contentLabel="Example Modal"
    >
      {!isDetailsOpen ? (
        <>
          <Row>
            <Col md="12" className="mt-5">
              <p className="label-black-nqt-md">Commentaires</p>
            </Col>
          </Row>
          <Row className="center-container">
            <Col md="12">
              {values.process.proofreaders.map(
                (proofreader: any, key: number) => (
                  <>
                    <div
                      className="d-flex flex-row align-items-center my-0 py-0 pointer"
                      onClick={() =>
                        getCommentCount(proofreader) > 0 &&
                        handleDetails(key + 1, proofreader)
                      }
                    >
                      <div className="iconOutlined px-3">
                        {proofreader.note === "A" ? (
                          <AIcon />
                        ) : proofreader.note === "B" ? (
                          <BIcon />
                        ) : proofreader.note === "C" ? (
                          <CIcon />
                        ) : proofreader.note === "D" ? (
                          <DIcon />
                        ) : (
                          <InterrogationIcon />
                        )}
                      </div>
                      <div className="d-flex flex-column mt-3 w-100">
                        <p className="mb-0 ms-3 label-black-trainings fw-bold">
                          {"Relecteur " + (key + 1)}
                        </p>
                        <p className="pt-1 ms-3 label-grey-normal opacity-75">
                          {getCommentCount(proofreader) + (getCommentCount(proofreader) > 1 ? " commentaires": " commentaire")}
                        </p>
                      </div>
                      <p className="align-middle mt-4 ps-1 outlined-circle">
                        {getCommentCount(proofreader)}
                      </p>
                      {getCommentCount(proofreader) ? (
                        <ChevronIcon className="mt-2 ms-3" />
                      ) : null}
                    </div>
                    <hr className="hr-text-grey p-0 m-0" />
                  </>
                )
              )}

              <div className="d-flex flex-row  center-container end-position-bottom">
                <Button
                  className="btn-round-sm-secondary fw-bold px-5  "
                  uppercase={false}
                  onClick={closeModal}
                  color="secondary"
                  type="submit"
                >
                  Fermer
                </Button>
              </div>
            </Col>
          </Row>{" "}
        </>
      ) : (
        <>
          <Row>
            <Col md="12" className="mt-5">
              <CloseIcon
                className="float-end pointer"
                onClick={() => {
                  closeModal();
                  setIsDetailsOpen(false);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md="12" className="mt-1">
              <div className="d-flex flex-row">
                <ChevronBackIcon
                  className="me-3 pointer mt-2"
                  onClick={() => setIsDetailsOpen(false)}
                />
                <p className="label-black-nqt-md">
                  {"Relecteur " + proofreaderKey}
                </p>
              </div>
            </Col>
          </Row>
          <Row className="center-container">
            <Col md="12">
              <Card
                className="p-0 m-0"
                style={{
                  backgroundColor: "rgba(249, 249, 249, 1)",
                  borderColor: "#ECF0FB",
                  borderRadius: 10,
                }}
              >
                <CardBody>
                  <div className="d-flex flex-row">
                    <div
                      className="iconOutlined px-3"
                      style={{
                        width: 45,
                        height: 43,
                        backgroundColor: "white",
                      }}
                    >
                      {proofreader.note === "A" ? (
                        <AIcon />
                      ) : proofreader.note === "B" ? (
                        <BIcon />
                      ) : proofreader.note === "C" ? (
                        <CIcon />
                      ) : proofreader.note === "D" ? (
                        <DIcon />
                      ) : (
                        <InterrogationIcon />
                      )}
                    </div>
                    <div className="align-items-center">
                      <p className="label-black ms-2 mb-0">
                        {proofreader.noteComment}
                      </p>
                    </div>
                  </div>
                </CardBody>
              </Card>
              {getComments()}
            </Col>
          </Row>
        </>
      )}
    </Modal>
  );
};
export default CommentModal;
