import { ProfileInformations } from './../../config/profile';
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import UserService from "../../services/user.service";

export interface UserState {
  user: ProfileInformations;
  status: "idle" | "loading" | "failed";
}

const initialState: UserState = {
  user: {
    id: 0,
    image: '',
    prenom: '',
    nom: '',
    age: '0 ans',
    region: '',
    pays: '',
    depuis: '',
    interetApprentissage: [],
    sectors: [],
    expertisesMetier: {
      entrepriseId: 0,
      entreprise: '',
      metier: [],
      secteur: '',
      subdomain: '',
      expertises: [],
    },
    competences: {
      anciennete: '',
      nbreFilleul: 0,
      niveau: '',
      competence: [],
    },    
    isReviewer: false,
    activeReviewer: false,
    role: [],
  },
  status: "idle",
};

// Retrieve users actions creator
export const retrieveUserMe = createAsyncThunk("user/retrieve", async () => {
  const res = await UserService.getMe();
  return res;
});

// Update user register step users actions creator
export const updateRegisterStep = createAsyncThunk(
  "registerStep/update",
  async ({ step }: any, { dispatch }) => {
    const res = await UserService.postRegisterStep(step);
    dispatch(retrieveUserMe())
    return res;
  }
);

// Enable/Disable proofreading Status
export const changeProofreadingStatus = createAsyncThunk(
  "/status/relecteur",
  async (props, { dispatch }) => {
    const res = await UserService.changeProofreadingStatus();
    dispatch(retrieveUserMe())
    return res;
  }
);

export const counterSlice = createSlice({
  name: "counter",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    updateSubject(state, action) {
      const indexCat = state.user.interetApprentissage.findIndex(cat => cat.id === action.payload.catId);
      if (indexCat !== -1) {
        state.user.interetApprentissage.forEach(cat => cat.selectedCategorie = false);
        const category = state.user.interetApprentissage[indexCat];
        category.selectedCategorie = true;
        const subject = category.sujets.find(sub => sub.id === action.payload.subjectId);
        if (subject) {
          subject.selected = !subject.selected;
        }
      }
    },
    updateSectorsFamilies(state, action) {
      const sector = state.user.sectors.find(item => item.id === action.payload.sectorId);
      if (sector) {
        sector.isSelected = !sector.isSelected;
      }
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  extraReducers: (builder) => {
    builder
      .addCase(retrieveUserMe.pending, (state) => {
        state.status = "loading";
      })
      .addCase(retrieveUserMe.fulfilled, (state, action) => {
        state.status = "idle";
        //@ts-ignore
        state.user = action.payload;
      })
      .addCase(retrieveUserMe.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(updateRegisterStep.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateRegisterStep.fulfilled, (state) => {
        state.status = "idle";
      })
      .addCase(updateRegisterStep.rejected, (state) => {
        state.status = "failed";
      });
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectUser = (state: RootState) => state.user.user;
export const selectStatus = (state: RootState) => state.user.status;
export const usersActions = counterSlice.actions;
export default counterSlice.reducer;
