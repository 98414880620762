import { useCallback, useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
//@ts-ignore
import { AsyncStorage } from "AsyncStorage";
import { BASE_URI, BASE_URL } from "./utils/base";

import { BrowserRouter } from "react-router-dom";
import { useAppDispatch } from "./redux/hooks";
import { callback } from "./redux/slices/authSlice";
import { retrieveUserMe } from "./redux/slices/userSlice";
import Routes from "./components/Routes";

function App() {
  //Init Query Params
  const query = new URLSearchParams(window.location.search);
  const code = query.get("code");
  const [access, setAccess] = useState("");
  //Init Dispatch
  const dispatch = useAppDispatch();

  /**
   * CheckTokenExpiration
   */
  const checkTokenExpiration = useCallback(async () => {
    const token = await AsyncStorage.getItem("token");

    if (token) {
      const decodedToken: any = jwt_decode(token);
      let currentDate = new Date();

      // JWT exp is in seconds
      if (decodedToken.exp * 1000 < currentDate.getTime()) {
        AsyncStorage.setItem("token", "");
        setAccess("false");
        return (window.location.href = BASE_URL + "/auth/redirect");
      } else {

        setAccess("true");
        dispatch(retrieveUserMe());
      }
    } else {
      setAccess("false");
    }
  }, []);

  //Effect from first render
  useEffect(() => {

    if (code) {
      dispatch(callback({ code }));
    } else {
      checkTokenExpiration();
    }
  }, [checkTokenExpiration]);

  return (
    <BrowserRouter basename={BASE_URI}>
      <Routes access={access} code={code} />
    </BrowserRouter>
  );
}

export default App;
