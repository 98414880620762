import { useState, useEffect } from "react";
import DashboardEditeur from "../views/editor/editorDashboard/EditorDashboardContainer";
import SidebarEditeur from "./SideBarEditeur";
import Layout from "./Layout";
import ProofreadersListingContainer from "../views/editor/proofreadersListing/ProofreadersListingContainer";
import Dashboard from "../views/dashboard/DashboardContainer";
import ClubNqtContainer from "../views/club-nqt/community/ClubNqtContainer";
import TrainingsCatalogContainer from "../views/training/trainingCatalog/TrainingsCatalogContainer";
import TrainingContainer from "../views/training/trainingAdd/TrainingAddContainer";
import { useLocation } from "react-router";

//@ts-ignore
import GamificationScaleContainer from "../views/editor/gamification/GamificationScaleContainer";
import { useAppSelector } from "../redux/hooks";
import { selectUser } from "../redux/slices/userSlice";
const Editeur = (props: any) => {
  const userRole = useAppSelector(selectUser).role;
  
  const location: any = useLocation();
  const [route, setRoute] = useState(location?.state?.route ? location?.state?.route : "Mon tableau de bord");

  useEffect(() => {
    if (location?.state?.route)
      setRoute(location?.state?.route)
  }, [location.state]);

  if (userRole.length === 0)
    return null;

  return (
    <Layout title={route} >
      <div className="content mt-3 pt-5 pb-5">
        {(route === "Formations" ||
        route === "Assigner à une formation" ||
        route === "Ajouter un relecteur") && (
          <DashboardEditeur
            setRoute={setRoute}
            route={route}
            tag={
              route === "Assigner à une formation" ||
              route === "Ajouter un relecteur"
                ? "assign"
                : "list"
            }
          />
        )}
        { route === "Barème de gamification" && <GamificationScaleContainer setRoute={setRoute} /> } 
        { route === "Relecteurs" && <ProofreadersListingContainer setRoute={setRoute} /> }

        { route === "Mon tableau de bord" && <Dashboard /> }
        { route === "Créer une formation" && <TrainingContainer layoutDisplayed /> }
        { route === "Consulter le catalogue" && <TrainingsCatalogContainer layoutDisplayed /> }
        { route === "Réseau social" && <ClubNqtContainer layoutDisplayed /> }
      </div>
    </Layout>
  );
};

export default Editeur;
