import { useState } from "react";
import { Card, Col, Progress, Row } from "reactstrap";
import { IoMdAttach, IoMdClose } from "react-icons/io";
import { MdInfo } from "react-icons/md";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { BsFillFileEarmarkPdfFill, BsFillImageFill } from "react-icons/bs";
import { FaMicrophone, FaFilePowerpoint } from "react-icons/fa";

import TemplateModal from "./TemplateModal";
import FileUploader from "./FileUploader";
import { AiFillFileExcel, AiFillVideoCamera } from "react-icons/ai";

const AsyncUpload = (props: any) => {
  //Init props
  const {
    sectionKey,
    values,
    setFieldValue,
    sectionContentKey,
    addOrRemoveSectionContent,
    addOrRemoveFile,
    tag,
    fileKey
  } = props;

  //Init constant
  const type =
    values.typology === "async"
      ? values.sections[sectionKey].sectionContent[sectionContentKey].fileType
      : values.eventDetails.section.files[fileKey].fileType;

  /**
   * Get Card Title
   */
  const getTitle = (type: string) => {
    return type === "pdf"
      ? "PDF"
      : type === "ppt" || type === "pptx"
      ? "Power Point"
      : type === "jpg" || type === "jpeg" || type === "png"
      ? "Image"
      : type === "mp3"
      ? "Audio"
      : type === "mp4"
      ? "Vidéo"
      : type === "xlsx" || type === "xls"
      ? "Excel"
      : "Pièce jointe";
  };

  //Init states
  const [modalIsOpen, setIsOpen] = useState(false);
  const [attachType, setAttachType] = useState(
    type ? getTitle(type) : "Pièce jointe"
  );

  /**
   * Open Right Side Modal
   */
  const openModal = () => {
    setIsOpen(true);
  };

  /**
   * close Right Side Modal
   */
  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <Card body color="light" outline className="cardIntro mt-5 p-4">
      <div className="d-flex flex-row align-items-center">
        {attachType === "PDF" ? (
          <BsFillFileEarmarkPdfFill
            size={50}
            color="#3367cd"
            className="ms-2 iconOutlined"
          />
        ) : attachType === "Audio" ? (
          <FaMicrophone
            size={50}
            color="#3367cd"
            className="ms-2 iconOutlined"
          />
        ) : attachType === "Vidéo" ? (
          <AiFillVideoCamera
            size={50}
            color="#3367cd"
            className="ms-2 iconOutlined"
          />
        ) : attachType === "Image" ? (
          <BsFillImageFill
            size={50}
            color="#3367cd"
            className="ms-2 iconOutlined"
          />
        ) : attachType === "Power Point" ? (
          <FaFilePowerpoint
            size={50}
            color="#3367cd"
            className="ms-2 iconOutlined"
          />
        ) : attachType === "Excel" ? (
          <AiFillFileExcel
            size={50}
            color="#3367cd"
            className="ms-2 iconOutlined"
          />
        ) : (
          <IoMdAttach size={50} color="#8D8D8D" className="m-2 iconOutlined" />
        )}{" "}
        <div className="d-flex flex-column  w-100 mt-3">
          <p className={"mb-0 ms-3 label-primary-nqt-md"}>{attachType}</p>
          <p className="pt-1 ms-3 mt-0 label-grey-normal">
            Les fichiers joints peuvent prendre la forme d’un pdf, d’un audio, d’une image, ou d’un powerpoint.
          </p>
        </div>
        <>
          <Progress multi style={{ height: 4, width: 24 }} className="me-2">
            <Progress
              bar
              value="50"
              className={type ? "bg-primary" : "bg-pink"}
            />
          </Progress>

          <IoMdClose
            size={15}
            color="#3367cd"
            onClick={() =>
              values.typology === "async" ? addOrRemoveSectionContent(
                "remove",
                "upload",
                sectionContentKey,
                sectionKey
              ) : addOrRemoveFile('remove', fileKey)
            }
            className="ms-2 float-end pointer closeIconPrimary"
          />
        </>
      </div>

      <Row>
        <hr className="hr-text-grey p-0 mt-3"></hr>
      </Row>

  {/*    <Row>
        <Col md="12">
          <p className="label-black mt-3">Importer une pièce jointe.</p>
          <p className="label-grey-normal">
            Peut prendre la forme d’un pdf, d’un audio, d’une image, ou d’un
            powerpoint.
          </p>
        </Col>
      </Row>*/}
      <Row>
        <Col md="12">
          <FileUploader
            title="Ajouter une pièce jointe."
            setFieldValue={setFieldValue}
            values={values}
            sectionKey={sectionKey}
            sectionContentKey={sectionContentKey}
            setAttachType={setAttachType}
            getTitle={getTitle}
            tag={tag}
            fileKey={fileKey}
          />
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <div className="carded mt-4 d-flex flex-row">
            <MdInfo size={20} color="#3367cd" />
            <div className="d-flex flex-column ms-3">
              <p className="label-black fw-bold">
                Utilisez un modèle NQT (Ppt, Word, Excel)
              </p>
              <p
                className="label-primary-normal pointer"
                onClick={() => openModal()}
              >
                Voir les modèles
                <HiOutlineArrowNarrowRight
                  size={17}
                  color="#3367cd"
                  className="ms-2"
                />
              </p>
            </div>
          </div>
          <TemplateModal closeModal={closeModal} modalIsOpen={modalIsOpen} />
        </Col>
      </Row>
    </Card>
  );
};
export default AsyncUpload;
