import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { IoMdAttach, IoMdTrash } from "react-icons/io";
import { MdDone } from "react-icons/md";
import { TiEye } from "react-icons/ti";

import "../../../../styles/forms.scss";

const FileUploader = (props: any) => {
  //Init props
  const {
    values,
    title,
    setFieldValue,
    sectionKey,
    sectionContentKey,
    setAttachType,
    getTitle,
    fileKey,
    tag,
  } = props;

  const file: any =
      values.typology === "async"
          ? values.sections[sectionKey].sectionContent[sectionContentKey]
          : values.eventDetails.section.files[fileKey];

  //Init states
  const [files, setFiles]: any = useState(file.file !== "" ? [file] : []);

  /**
   *
   * @param {*} file
   */
  const onFileToBase64 = (file: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function (event: any) {
      setFieldValue(
          values.typology === 'async'
              ? `[sections[${sectionKey}].sectionContent[${sectionContentKey}].file]`
              : `[eventDetails.section.files[${fileKey}].file]`,
          event.target.result
      );
    };
  };

  //Init dropZone props
  const { getRootProps, getInputProps } = useDropzone({
    accept:
        values.typology === "async"
            ? ".png, .jpg, .jpeg, .pdf, .pptx, .ppt, .mp3, .xls, .xlsx"
            : ".pdf, .pptx, .ppt, .mp3, .xls, .xlsx",
    onDrop: (acceptedFiles: any) => {
      var file = acceptedFiles[0];
      onFileToBase64(file);

      setFiles(
          acceptedFiles.map((file: any) =>
              Object.assign(file, {
                preview: URL.createObjectURL(file),
              })
          )
      );
      setFieldValue(
          values.typology === "async"
              ? `[sections[${sectionKey}].sectionContent[${sectionContentKey}].fileType]`
              : `[eventDetails.section.files[${fileKey}].fileType]`,
          acceptedFiles[0].name.split(".").pop()
      );
      setFieldValue(
          values.typology === "async"
              ? `[sections[${sectionKey}].sectionContent[${sectionContentKey}].preview]`
              : `[eventDetails.section.files[${fileKey}].preview]`,
          acceptedFiles[0].preview
      );
      setFieldValue(
          values.typology === "async"
              ? `[sections[${sectionKey}].sectionContent[${sectionContentKey}].fileName]`
              : `[eventDetails.section.files[${fileKey}].fileName]`,
          acceptedFiles[0].name
      );
      setFieldValue(
          values.typology === "async"
              ? `[sections[${sectionKey}].sectionContent[${sectionContentKey}].fileContentType]`
              : `[eventDetails.section.files[${fileKey}].fileContentType]`,
          acceptedFiles[0].type
      );

      setAttachType(getTitle(acceptedFiles[0].name.split(".").pop()));
    },
  });

  function base64toBlob(file: any) {
    var byteString = atob(file.split(",")[1]);
    var mimeString = file.split(",")[0].split(":")[1].split(";")[0];

    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    var blob = new Blob([ab], { type: mimeString });

    return blob;
  }

  //Effect from first render
  useEffect(
      () => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach((file: any) => URL.revokeObjectURL(file.preview));
      },
      [files]
  );

  return (
      <div {...getRootProps({ className: "dropzone w-full" })}>
        {files.length ? (
            <div className="d-flex flex-row download mt-4">
              <MdDone size={40} className="done-icon" />
              <div className="d-flex flex-column">
                {files.map((file: any,index:number) => (
                    <p key={index} className="mb-0 ms-3 fw-bold">
                      {file.fileName ? file.fileName : file.name}{" "}
                    </p>
                ))}
                <p className="mt-0 ms-3 label-green">Importé avec succès</p>
              </div>
              <div className="d-flex flex-row justify-content-center">
                <IoMdTrash
                    size={18}
                    color="#3367cd"
                    className="right pointer ms-5 mt-2"
                    onClick={() => {
                      setFiles([]);
                      setFieldValue(
                          values.typology === "async"
                              ? `[sections[${sectionKey}].sectionContent[${sectionContentKey}].fileType]`
                              : `[eventDetails.section.files[${fileKey}].fileType]`,
                          ""
                      );
                      setFieldValue(
                          values.typology === "async"
                              ? `[sections[${sectionKey}].sectionContent[${sectionContentKey}].file]`
                              : `[eventDetails.section.files[${fileKey}].file]`,
                          ""
                      );

                      setFieldValue(
                          values.typology === "async"
                              ? `[sections[${sectionKey}].sectionContent[${sectionContentKey}].preview]`
                              : `[eventDetails.section.files[${fileKey}].preview]`,
                          ""
                      );
                      setFieldValue(
                          values.typology === "async"
                              ? `[sections[${sectionKey}].sectionContent[${sectionContentKey}].fileName]`
                              : `[eventDetails.section.files[${fileKey}].fileName]`,
                          ""
                      );
                      setFieldValue(
                          values.typology === "async"
                              ? `[sections[${sectionKey}].sectionContent[${sectionContentKey}].fileContentType]`
                              : `[eventDetails.section.files[${fileKey}].fileContentType]`,
                          ""
                      );
                      setAttachType("Pièce jointe");
                    }}
                />
                <TiEye
                    size={16}
                    color="#3367cd"
                    className="right pointer ms-1 mt-2"
                    onClick={() =>
                        window.open(
                            tag === "edit"
                                ? URL.createObjectURL(base64toBlob(file.file || ""))
                                : files[0].preview,
                            "_blanc"
                        )
                    }
                />
              </div>
            </div>
        ) : (
            <div className="placeholderPreview">
              <input {...getInputProps()} />
              <IoMdAttach
                  size={50}
                  color="#3367cd"
                  className="mt-2 m-0 iconOutlined"
              />
              <p className="label-primary-nqt-md">{title}</p>{" "}
              <p className="label-aqua pt-0">
                {values.typology === "async"
                    ? ".pdf .png .jpg .jpeg .ppt .mp3 , .xlsx"
                    : ".pdf .ppt .mp3, .xlsx"}
              </p>
            </div>
        )}
      </div>
  );
};

export default FileUploader;
