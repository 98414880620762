import React, { useState, useEffect } from "react";
import { AiOutlineCaretUp, AiOutlineCaretDown } from "react-icons/ai";
import { UncontrolledPopover, PopoverBody, Col } from "reactstrap";

const ContentsTypeFilter = (props: any) => {
    const { handleChange, reset } = props;
    const [open, setOpen] = useState(false);
    const [contentType, setContentType] = useState<string[]>([]);
    const [clearAll, setClearAll] = useState(false);
  
    useEffect(() => {
      const delayDebounceFn = setTimeout(() => {
        handleChange(contentType);
      }, 1000);
      return () => clearTimeout(delayDebounceFn);
    }, [contentType, handleChange]);
  
    useEffect(() => {
      if (reset || clearAll) {
        setContentType([]);
      }
    }, [reset, clearAll]);
    const setContentsSelected = (e: any) => {
        const id = e.currentTarget.dataset.id;
        if (contentType.includes(id)) {
          setContentType((prev) => {
            const sub = [...prev];
            sub.splice(
              contentType.findIndex((val) => val === id),
              1
            );
            return sub;
          });
        } else {
          setContentType((prev) => {
            const sub = [...prev];
            sub.push(id);
            return sub;
          });
        }
      };
    const ContentsOptions = (
      <>
        <li
          className="d-flex subjectList"
          key={0}
          data-id={"all"}
          onClick={setContentsSelected.bind(this)}
          style={{ width: 300 }}
        >
          <svg
            width="31"
            height="30"
            viewBox="0 0 31 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.517578"
              y="0.375"
              width="29.25"
              height="29.25"
              rx="5.625"
              fill="white"
              stroke="#F0F0F0"
              strokeWidth="0.75"
            />
            <path
              d="M10.5742 20.5H20.5742C21.2617 20.5 21.8242 19.9375 21.8242 19.25C21.8242 18.5625 21.2617 18 20.5742 18H10.5742C9.88672 18 9.32422 18.5625 9.32422 19.25C9.32422 19.9375 9.88672 20.5 10.5742 20.5ZM10.5742 18.625H11.8242V19.875H10.5742V18.625ZM9.32422 11.75C9.32422 12.4375 9.88672 13 10.5742 13H20.5742C21.2617 13 21.8242 12.4375 21.8242 11.75C21.8242 11.0625 21.2617 10.5 20.5742 10.5H10.5742C9.88672 10.5 9.32422 11.0625 9.32422 11.75ZM11.8242 12.375H10.5742V11.125H11.8242V12.375ZM10.5742 16.75H20.5742C21.2617 16.75 21.8242 16.1875 21.8242 15.5C21.8242 14.8125 21.2617 14.25 20.5742 14.25H10.5742C9.88672 14.25 9.32422 14.8125 9.32422 15.5C9.32422 16.1875 9.88672 16.75 10.5742 16.75ZM10.5742 14.875H11.8242V16.125H10.5742V14.875Z"
              fill="#3367CD"
            />
          </svg>
  
          <span className="checkBox">All</span>
          {contentType.includes("all") ? (
            <div
              className="checked"
              style={{ marginLeft: "auto", paddingRight: 10 }}
            >
              <svg
                width="9"
                height="7"
                viewBox="0 0 9 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.75015 4.38408L1.481 3.11494C1.22087 2.85481 0.800078 2.85481 0.539947 3.11494C0.279816 3.37507 0.279816 3.79586 0.539947 4.05599L2.28161 5.79766C2.54175 6.05779 2.96254 6.05779 3.22267 5.79766L7.631 1.38933C7.89113 1.12919 7.89113 0.708403 7.631 0.448272C7.3709 0.188171 6.95018 0.188141 6.69004 0.448183C6.69001 0.448212 6.68998 0.448242 6.68995 0.448272L2.75015 4.38408Z"
                  fill="#3367CD"
                  stroke="#3367CD"
                />
              </svg>
            </div>
          ) : (
            <div
              className="unchecked"
              style={{ marginLeft: "auto", paddingRight: 10 }}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM14 11H11V14C11 14.55 10.55 15 10 15C9.45 15 9 14.55 9 14V11H6C5.45 11 5 10.55 5 10C5 9.45 5.45 9 6 9H9V6C9 5.45 9.45 5 10 5C10.55 5 11 5.45 11 6V9H14C14.55 9 15 9.45 15 10C15 10.55 14.55 11 14 11Z"
                  fill="#ECF0FB"
                />
              </svg>
            </div>
          )}
        </li>
        <li
          className="d-flex subjectList"
          key={1}
          data-id={"pdf"}
          onClick={setContentsSelected.bind(this)}
          style={{ width: 300 }}
        >
          <svg
            width="31"
            height="30"
            viewBox="0 0 31 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.515625"
              y="0.375"
              width="29.25"
              height="29.25"
              rx="5.625"
              fill="white"
              stroke="#F0F0F0"
              strokeWidth="0.75"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M18.8916 21.25H11.3916C10.7012 21.25 10.1416 20.6904 10.1416 20V13.0188C10.1413 12.6856 10.2741 12.3661 10.5104 12.1313L13.5229 9.11875C13.7577 8.88248 14.0772 8.74974 14.4104 8.75H18.8916C19.582 8.75 20.1416 9.30964 20.1416 10V20C20.1416 20.6904 19.582 21.25 18.8916 21.25ZM14.5173 12.5V9.88745L11.2798 13.125H13.8923C14.2375 13.125 14.5173 12.8451 14.5173 12.5Z"
              fill="#3367CD"
            />
            <path
              d="M11.2783 17.6625C11.2783 17.865 11.4403 18.027 11.6428 18.027C11.8453 18.027 12.0118 17.865 12.0118 17.6625C12.0118 17.46 11.8453 17.2935 11.6428 17.2935C11.4403 17.2935 11.2783 17.46 11.2783 17.6625ZM13.1633 18.855V17.7525C13.2758 17.919 13.5143 18.054 13.8293 18.054C14.4593 18.054 14.8598 17.559 14.8598 16.902C14.8598 16.254 14.4953 15.759 13.8473 15.759C13.5098 15.759 13.2578 15.9165 13.1498 16.1055V15.8085H12.6458V18.855H13.1633ZM14.3378 16.902C14.3378 17.3205 14.0948 17.5905 13.7483 17.5905C13.4063 17.5905 13.1588 17.3205 13.1588 16.902C13.1588 16.4925 13.4063 16.2225 13.7483 16.2225C14.0993 16.2225 14.3378 16.4925 14.3378 16.902ZM15.7127 16.8975C15.7127 16.479 15.9647 16.2135 16.3067 16.2135C16.6487 16.2135 16.8827 16.4745 16.8827 16.893C16.8827 17.3115 16.6442 17.595 16.3022 17.595C15.9467 17.595 15.7127 17.316 15.7127 16.8975ZM17.3867 14.742H16.8782V16.065C16.8107 15.9255 16.6172 15.75 16.2347 15.75C15.6182 15.75 15.1907 16.2675 15.1907 16.8975C15.1907 17.559 15.6137 18.054 16.2437 18.054C16.5542 18.054 16.7837 17.901 16.8872 17.7165C16.8872 17.8065 16.9007 17.9505 16.9097 18H17.4092C17.4047 17.964 17.3867 17.7975 17.3867 17.5995V14.742ZM19.0407 15.1875C19.1397 15.1875 19.1982 15.201 19.2297 15.21V14.7645C19.1937 14.7465 19.0857 14.7195 18.9552 14.7195C18.5052 14.7195 18.1902 15.0165 18.1902 15.507V15.8085H17.8077V16.263H18.1902V18H18.7167V16.263H19.2252V15.8085H18.7167V15.5205C18.7167 15.2505 18.9012 15.1875 19.0407 15.1875Z"
              fill="white"
            />
          </svg>
  
          <span className="checkBox">Pdf</span>
          {contentType.includes("pdf") ? (
            <div
              className="checked"
              style={{ marginLeft: "auto", paddingRight: 10 }}
            >
              <svg
                width="9"
                height="7"
                viewBox="0 0 9 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.75015 4.38408L1.481 3.11494C1.22087 2.85481 0.800078 2.85481 0.539947 3.11494C0.279816 3.37507 0.279816 3.79586 0.539947 4.05599L2.28161 5.79766C2.54175 6.05779 2.96254 6.05779 3.22267 5.79766L7.631 1.38933C7.89113 1.12919 7.89113 0.708403 7.631 0.448272C7.3709 0.188171 6.95018 0.188141 6.69004 0.448183C6.69001 0.448212 6.68998 0.448242 6.68995 0.448272L2.75015 4.38408Z"
                  fill="#3367CD"
                  stroke="#3367CD"
                />
              </svg>
            </div>
          ) : (
            <div
              className="unchecked"
              style={{ marginLeft: "auto", paddingRight: 10 }}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM14 11H11V14C11 14.55 10.55 15 10 15C9.45 15 9 14.55 9 14V11H6C5.45 11 5 10.55 5 10C5 9.45 5.45 9 6 9H9V6C9 5.45 9.45 5 10 5C10.55 5 11 5.45 11 6V9H14C14.55 9 15 9.45 15 10C15 10.55 14.55 11 14 11Z"
                  fill="#ECF0FB"
                />
              </svg>
            </div>
          )}
        </li>
        <li
          className="d-flex subjectList"
          key={2}
          data-id={"ppt"}
          onClick={setContentsSelected.bind(this)}
          style={{ width: 300 }}
        >
          <svg
            width="31"
            height="30"
            viewBox="0 0 31 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.642578"
              y="0.5"
              width="29"
              height="29"
              rx="3.5"
              fill="white"
              stroke="#F0F0F0"
            />
            <g clipPath="url(#clip0_1482_35666)">
              <path
                d="M8.89258 10.625C9.23633 10.625 9.51758 10.9062 9.51758 11.25V18.75C9.51758 19.0938 9.23633 19.375 8.89258 19.375C8.54883 19.375 8.26758 19.0938 8.26758 18.75V11.25C8.26758 10.9062 8.54883 10.625 8.89258 10.625ZM11.3926 10.625C11.7363 10.625 12.0176 10.9062 12.0176 11.25V18.75C12.0176 19.0938 11.7363 19.375 11.3926 19.375C11.0488 19.375 10.7676 19.0938 10.7676 18.75V11.25C10.7676 10.9062 11.0488 10.625 11.3926 10.625ZM21.3926 10.625H13.8926C13.5488 10.625 13.2676 10.9062 13.2676 11.25V18.75C13.2676 19.0938 13.5488 19.375 13.8926 19.375H21.3926C21.7363 19.375 22.0176 19.0938 22.0176 18.75V11.25C22.0176 10.9062 21.7363 10.625 21.3926 10.625ZM14.9176 17.6188L15.8363 16.4562C15.9613 16.3 16.1926 16.3 16.3238 16.45L17.1988 17.5L18.5113 15.8062C18.6363 15.6437 18.8801 15.6437 19.0051 15.8062L20.3863 17.6188C20.5426 17.825 20.3988 18.1187 20.1363 18.1187H15.1613C14.9051 18.125 14.7551 17.825 14.9176 17.6188Z"
                fill="#3367CD"
              />
            </g>
            <defs>
              <clipPath id="clip0_1482_35666">
                <rect
                  width="15"
                  height="15"
                  fill="white"
                  transform="translate(7.64258 7.5)"
                />
              </clipPath>
            </defs>
          </svg>
  
          <span className="checkBox">Diaporama</span>
          {contentType.includes("ppt") ? (
            <div
              className="checked"
              style={{ marginLeft: "auto", paddingRight: 10 }}
            >
              <svg
                width="9"
                height="7"
                viewBox="0 0 9 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.75015 4.38408L1.481 3.11494C1.22087 2.85481 0.800078 2.85481 0.539947 3.11494C0.279816 3.37507 0.279816 3.79586 0.539947 4.05599L2.28161 5.79766C2.54175 6.05779 2.96254 6.05779 3.22267 5.79766L7.631 1.38933C7.89113 1.12919 7.89113 0.708403 7.631 0.448272C7.3709 0.188171 6.95018 0.188141 6.69004 0.448183C6.69001 0.448212 6.68998 0.448242 6.68995 0.448272L2.75015 4.38408Z"
                  fill="#3367CD"
                  stroke="#3367CD"
                />
              </svg>
            </div>
          ) : (
            <div
              className="unchecked"
              style={{ marginLeft: "auto", paddingRight: 10 }}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM14 11H11V14C11 14.55 10.55 15 10 15C9.45 15 9 14.55 9 14V11H6C5.45 11 5 10.55 5 10C5 9.45 5.45 9 6 9H9V6C9 5.45 9.45 5 10 5C10.55 5 11 5.45 11 6V9H14C14.55 9 15 9.45 15 10C15 10.55 14.55 11 14 11Z"
                  fill="#ECF0FB"
                />
              </svg>
            </div>
          )}
        </li>
        <li
          className="d-flex subjectList"
          key={4}
          data-id={"video"}
          onClick={setContentsSelected.bind(this)}
          style={{ width: 300 }}
        >
          <svg
            width="31"
            height="30"
            viewBox="0 0 31 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.642578"
              y="0.5"
              width="29"
              height="29"
              rx="3.5"
              fill="white"
              stroke="#F0F0F0"
            />
            <path
              d="M14.3418 13.65V17.35C14.3418 17.6125 14.6418 17.7563 14.848 17.5938L17.1605 15.7438C17.3168 15.6188 17.3168 15.3812 17.1605 15.2562L14.848 13.4062C14.6418 13.2437 14.3418 13.3875 14.3418 13.65V13.65ZM19.9668 9.875H11.2168C10.5293 9.875 9.9668 10.4375 9.9668 11.125V19.875C9.9668 20.5625 10.5293 21.125 11.2168 21.125H19.9668C20.6543 21.125 21.2168 20.5625 21.2168 19.875V11.125C21.2168 10.4375 20.6543 9.875 19.9668 9.875ZM19.3418 19.875H11.8418C11.498 19.875 11.2168 19.5938 11.2168 19.25V11.75C11.2168 11.4062 11.498 11.125 11.8418 11.125H19.3418C19.6855 11.125 19.9668 11.4062 19.9668 11.75V19.25C19.9668 19.5938 19.6855 19.875 19.3418 19.875Z"
              fill="#3367CD"
            />
          </svg>
  
          <span className="checkBox">Vidéo</span>
          {contentType.includes("video") ? (
            <div
              className="checked"
              style={{ marginLeft: "auto", paddingRight: 10 }}
            >
              <svg
                width="9"
                height="7"
                viewBox="0 0 9 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.75015 4.38408L1.481 3.11494C1.22087 2.85481 0.800078 2.85481 0.539947 3.11494C0.279816 3.37507 0.279816 3.79586 0.539947 4.05599L2.28161 5.79766C2.54175 6.05779 2.96254 6.05779 3.22267 5.79766L7.631 1.38933C7.89113 1.12919 7.89113 0.708403 7.631 0.448272C7.3709 0.188171 6.95018 0.188141 6.69004 0.448183C6.69001 0.448212 6.68998 0.448242 6.68995 0.448272L2.75015 4.38408Z"
                  fill="#3367CD"
                  stroke="#3367CD"
                />
              </svg>
            </div>
          ) : (
            <div
              className="unchecked"
              style={{ marginLeft: "auto", paddingRight: 10 }}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM14 11H11V14C11 14.55 10.55 15 10 15C9.45 15 9 14.55 9 14V11H6C5.45 11 5 10.55 5 10C5 9.45 5.45 9 6 9H9V6C9 5.45 9.45 5 10 5C10.55 5 11 5.45 11 6V9H14C14.55 9 15 9.45 15 10C15 10.55 14.55 11 14 11Z"
                  fill="#ECF0FB"
                />
              </svg>
            </div>
          )}
        </li>
        <li
          className="d-flex subjectList"
          key={5}
          data-id={"audio"}
          onClick={setContentsSelected.bind(this)}
          style={{ width: 300 }}
        >
          <svg
            width="31"
            height="30"
            viewBox="0 0 31 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.640625"
              y="0.5"
              width="29"
              height="29"
              rx="3.5"
              fill="white"
              stroke="#F0F0F0"
            />
            <path
              d="M15.141 16.25C16.1785 16.25 17.016 15.4125 17.016 14.375V10.625C17.016 9.5875 16.1785 8.75 15.141 8.75C14.1035 8.75 13.266 9.5875 13.266 10.625V14.375C13.266 15.4125 14.1035 16.25 15.141 16.25ZM18.8347 14.375C18.5285 14.375 18.2722 14.6 18.2222 14.9063C17.966 16.375 16.6847 17.5 15.141 17.5C13.5972 17.5 12.316 16.375 12.0597 14.9063C12.0097 14.6 11.7535 14.375 11.4472 14.375C11.066 14.375 10.766 14.7125 10.8222 15.0875C11.1285 16.9625 12.6285 18.4313 14.516 18.7V20C14.516 20.3438 14.7972 20.625 15.141 20.625C15.4847 20.625 15.766 20.3438 15.766 20V18.7C17.6535 18.4313 19.1535 16.9625 19.4597 15.0875C19.5222 14.7125 19.216 14.375 18.8347 14.375Z"
              fill="#3367CD"
            />
          </svg>
  
          <span className="checkBox">Audio, podcast</span>
          {contentType.includes("audio") ? (
            <div
              className="checked"
              style={{ marginLeft: "auto", paddingRight: 10 }}
            >
              <svg
                width="9"
                height="7"
                viewBox="0 0 9 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.75015 4.38408L1.481 3.11494C1.22087 2.85481 0.800078 2.85481 0.539947 3.11494C0.279816 3.37507 0.279816 3.79586 0.539947 4.05599L2.28161 5.79766C2.54175 6.05779 2.96254 6.05779 3.22267 5.79766L7.631 1.38933C7.89113 1.12919 7.89113 0.708403 7.631 0.448272C7.3709 0.188171 6.95018 0.188141 6.69004 0.448183C6.69001 0.448212 6.68998 0.448242 6.68995 0.448272L2.75015 4.38408Z"
                  fill="#3367CD"
                  stroke="#3367CD"
                />
              </svg>
            </div>
          ) : (
            <div
              className="unchecked"
              style={{ marginLeft: "auto", paddingRight: 10 }}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM14 11H11V14C11 14.55 10.55 15 10 15C9.45 15 9 14.55 9 14V11H6C5.45 11 5 10.55 5 10C5 9.45 5.45 9 6 9H9V6C9 5.45 9.45 5 10 5C10.55 5 11 5.45 11 6V9H14C14.55 9 15 9.45 15 10C15 10.55 14.55 11 14 11Z"
                  fill="#ECF0FB"
                />
              </svg>
            </div>
          )}
        </li>
        <li
          className="d-flex subjectList"
          key={6}
          data-id={"xl"}
          onClick={setContentsSelected.bind(this)}
          style={{ width: 300 }}
        >
          <svg
            width="31"
            height="30"
            viewBox="0 0 31 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.638672"
              y="0.5"
              width="29"
              height="29"
              rx="3.5"
              fill="white"
              stroke="#F0F0F0"
            />
            <path
              d="M20.4961 9.25H10.4961C9.80859 9.25 9.24609 9.8125 9.24609 10.5V20.5C9.24609 21.1875 9.80859 21.75 10.4961 21.75H20.4961C21.1836 21.75 21.7461 21.1875 21.7461 20.5V10.5C21.7461 9.8125 21.1836 9.25 20.4961 9.25ZM12.9961 20.5H11.1211C10.7773 20.5 10.4961 20.2188 10.4961 19.875V18H12.9961V20.5ZM12.9961 16.75H10.4961V14.25H12.9961V16.75ZM12.9961 13H10.4961V11.125C10.4961 10.7812 10.7773 10.5 11.1211 10.5H12.9961V13ZM16.7461 20.5H14.2461V18H16.7461V20.5ZM16.7461 16.75H14.2461V14.25H16.7461V16.75ZM16.7461 13H14.2461V10.5H16.7461V13ZM19.8711 20.5H17.9961V18H20.4961V19.875C20.4961 20.2188 20.2148 20.5 19.8711 20.5ZM20.4961 16.75H17.9961V14.25H20.4961V16.75ZM20.4961 13H17.9961V10.5H19.8711C20.2148 10.5 20.4961 10.7812 20.4961 11.125V13Z"
              fill="#3367CD"
            />
          </svg>
  
          <span className="checkBox">Tableurs</span>
          {contentType.includes("xl") ? (
            <div
              className="checked"
              style={{ marginLeft: "auto", paddingRight: 10 }}
            >
              <svg
                width="9"
                height="7"
                viewBox="0 0 9 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.75015 4.38408L1.481 3.11494C1.22087 2.85481 0.800078 2.85481 0.539947 3.11494C0.279816 3.37507 0.279816 3.79586 0.539947 4.05599L2.28161 5.79766C2.54175 6.05779 2.96254 6.05779 3.22267 5.79766L7.631 1.38933C7.89113 1.12919 7.89113 0.708403 7.631 0.448272C7.3709 0.188171 6.95018 0.188141 6.69004 0.448183C6.69001 0.448212 6.68998 0.448242 6.68995 0.448272L2.75015 4.38408Z"
                  fill="#3367CD"
                  stroke="#3367CD"
                />
              </svg>
            </div>
          ) : (
            <div
              className="unchecked"
              style={{ marginLeft: "auto", paddingRight: 10 }}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM14 11H11V14C11 14.55 10.55 15 10 15C9.45 15 9 14.55 9 14V11H6C5.45 11 5 10.55 5 10C5 9.45 5.45 9 6 9H9V6C9 5.45 9.45 5 10 5C10.55 5 11 5.45 11 6V9H14C14.55 9 15 9.45 15 10C15 10.55 14.55 11 14 11Z"
                  fill="#ECF0FB"
                />
              </svg>
            </div>
          )}
        </li>
        <div className="clearAll" onClick={() => setClearAll(true)}>
            réinitialiser
          </div>
      </>
    );
  
    return (
      <>
        <div
          className="label-black-normal formControl d-flex"
          color={"#3367CD"}
          onClick={() => setOpen(!open)}
          onBlur={() => setOpen(false)}
          id="PopoverContent"
          tabIndex={0}
        >
          <div className="d-flex filter-label">
            <div className="name"> Contenus</div>
            {contentType.length > 0 && (<div className="number"> {contentType.length} </div>)}
          </div>
          {open ? (
            <AiOutlineCaretUp color="#3367CD" className="float-end" />
          ) : (
            <AiOutlineCaretDown color="#BDBDBD" className="float-end" />
          )}
        </div>
        <UncontrolledPopover
          placement="bottom"
          target="PopoverContent"
          trigger="legacy"
        >
          <PopoverBody>
            <Col onFocus={() => setOpen(true)} onBlur={() => setOpen(false)}>
            <div>{ContentsOptions}</div>
            </Col>
          </PopoverBody>
        </UncontrolledPopover>
      </>
    );
  };
  
  export default React.memo(ContentsTypeFilter);
