import React, { useEffect, useState } from "react";
import { AiOutlineCaretDown, AiOutlineCaretUp } from "react-icons/ai";
import { Col, PopoverBody, UncontrolledPopover } from "reactstrap";


const SeniorityFilter = (props: any) => {
  const { handleChange, reset } = props;
  const [open, setOpen] = useState(false);
  const [text, setText] = useState<number>();

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleChange(text);
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [text, handleChange]);

  useEffect(() => {
    if (reset) {
      setText(Number(0));
    }
  }, [reset]);

  return (
    <>
      <div
        className="label-black-normal formControl d-flex"
        color={"#3367CD"}
        onClick={() => setOpen(!open)}
        onBlur={() => setOpen(false)}
        id="PopoverSeniority"
        tabIndex={0}
      >
        <div className="d-flex filter-label">
          <div className="label-grey opacity-75 fw-normal">Ancienneté</div>
        </div>
        {open ? (
          <AiOutlineCaretUp color="#3367CD" className="float-end" />
        ) : (
          <AiOutlineCaretDown color="#BDBDBD" className="float-end" />
        )}
      </div>
      <UncontrolledPopover
        placement="bottom"
        target="PopoverSeniority"
        trigger="legacy"
      >
        <PopoverBody>
          <Col>
            <div
              className="d-flex flex-row px-1 align-items-center"
              onFocus={() => setOpen(true)}
              onBlur={() => setOpen(false)}
              style={{ width: 200, height: 40 }}
            >
              <input
                placeholder="0"
                value={text}
                name="entreprise"
                type="number"
                min={0}
                className="formControl label-search  p-0 text-center"
                style={{
                  width: 40,
                  backgroundColor: "#F9F9F9",
                  color: "black",
                }}
                onChange={(e) => setText(Number(e.target.value))}
              />
              <p className="label-black ms-3 mt-3">an d’ancienneté</p>
            </div>
          </Col>
        </PopoverBody>
      </UncontrolledPopover>
    </>
  );
};

export default React.memo(SeniorityFilter);
