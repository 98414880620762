import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useMedia } from "react-media";
import { Row, Col, Button } from "reactstrap";

const TrainingEvalHeader = (props: any) => {
  const { setContentType, type, title } = props;
  const GLOBAL_MEDIA_QUERIES = {
    small: "(max-width: 991px)",
    large: "(min-width: 992px)",
  };
  const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
  const [modalShow, setModalShow] = useState(false);
  return (
    <>
      {matches.large ? (
        <Row className="headerPreview px-4" style={{ marginRight: 0 }}>
          <Col className="p-3 d-flex">
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22 11L19.56 8.21L19.9 4.52L16.29 3.7L14.4 0.5L11 1.96L7.6 0.5L5.71 3.69L2.1 4.5L2.44 8.2L0 11L2.44 13.79L2.1 17.49L5.71 18.31L7.6 21.5L11 20.03L14.4 21.49L16.29 18.3L19.9 17.48L19.56 13.79L22 11ZM8.38 15.01L6 12.61C5.61 12.22 5.61 11.59 6 11.2L6.07 11.13C6.46 10.74 7.1 10.74 7.49 11.13L9.1 12.75L14.25 7.59C14.64 7.2 15.28 7.2 15.67 7.59L15.74 7.66C16.13 8.05 16.13 8.68 15.74 9.07L9.82 15.01C9.41 15.4 8.78 15.4 8.38 15.01Z"
                fill="#3367CD"
              />
            </svg>
            {type === "eval" && (
              <div className="label-blue-md pl-10">Auto-évaluation</div>
            )}
            {type === "quizz" && (
              <div className="label-blue-md pl-10">Quiz</div>
            )}
            <div className="label-black-md pl-10">{title}</div>
          </Col>
          <Col className="p-3 d-flex justify-content-end">
            <div
              className="label-blue-md pr-10 pointer"
              onClick={() => {
                setModalShow(true);
              }}
            >
              {type === "quizz"
                ? "Fermer le Quiz "
                : "Fermer l'auto-évaluation"}
            </div>
          </Col>
        </Row>
      ) : (
        <Row className="headerPreview px-4" style={{ marginRight: 0 }}>
          <Col className="m-auto d-flex">
            <svg
              width="20"
              height="14"
              viewBox="0 0 20 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 6.49997V7.49997C20 7.77612 19.7761 7.99997 19.5 7.99997H3.33L7.78 12.44C7.87466 12.5339 7.9279 12.6617 7.9279 12.795C7.9279 12.9283 7.87466 13.0561 7.78 13.15L7.07 13.85C6.97612 13.9446 6.84832 13.9979 6.715 13.9979C6.58168 13.9979 6.45388 13.9446 6.36 13.85L0.22 7.71997C0.0793075 7.57946 0.000175052 7.38882 0 7.18997V6.80997C0.00230401 6.61156 0.081116 6.4217 0.22 6.27997L6.36 0.149974C6.45388 0.055318 6.58168 0.0020752 6.715 0.0020752C6.84832 0.0020752 6.97612 0.055318 7.07 0.149974L7.78 0.859974C7.87406 0.952138 7.92707 1.07828 7.92707 1.20997C7.92707 1.34166 7.87406 1.46781 7.78 1.55997L3.33 5.99997H19.5C19.7761 5.99997 20 6.22383 20 6.49997Z"
                fill="#3367CD"
              />
            </svg>
            <div className="label-black-md mx-4">{title}</div>
          </Col>
        </Row>
      )}
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="d-flex flex-column justify-content-center align-items-center">
          <div className="m-5 d-flex">
            <svg
              width="172"
              height="171"
              viewBox="0 0 172 171"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M171.5 85.5C171.5 132.441 133.223 170.5 86 170.5C38.7769 170.5 0.5 132.441 0.5 85.5C0.5 38.5585 38.7769 0.5 86 0.5C133.223 0.5 171.5 38.5585 171.5 85.5Z"
                stroke="#ECF0FB"
              />
              <circle cx="86" cy="87" r="55.5" stroke="#ECF0FB" />
              <circle
                r="7"
                transform="matrix(1 0 0 -1 81 143)"
                fill="#FF66A9"
              />
              <circle r="7" transform="matrix(1 0 0 -1 15 38)" fill="#FF66A9" />
              <ellipse
                rx="7.5"
                ry="7"
                transform="matrix(1 0 0 -1 125.5 49)"
                fill="#ED984F"
              />
              <circle opacity="0.2" cx="86" cy="87" r="22" fill="#ED984F" />
              <path
                d="M97 87L94.56 84.21L94.9 80.52L91.29 79.7L89.4 76.5L86 77.96L82.6 76.5L80.71 79.69L77.1 80.5L77.44 84.2L75 87L77.44 89.79L77.1 93.49L80.71 94.31L82.6 97.5L86 96.03L89.4 97.49L91.29 94.3L94.9 93.48L94.56 89.79L97 87ZM83.38 91.01L81 88.61C80.61 88.22 80.61 87.59 81 87.2L81.07 87.13C81.46 86.74 82.1 86.74 82.49 87.13L84.1 88.75L89.25 83.59C89.64 83.2 90.28 83.2 90.67 83.59L90.74 83.66C91.13 84.05 91.13 84.68 90.74 85.07L84.82 91.01C84.41 91.4 83.78 91.4 83.38 91.01Z"
                fill="#ED984F"
              />
            </svg>
          </div>
          <Row className="d-flex flex-column align-items-center">
            <Col md="8" className="d-flex flex-column align-items-center">
              <p className="label-black-md text-center">
                Êtes-vous sûre de vouloir quitter le quiz ?
              </p>
            </Col>
          </Row>
          <Row className="d-flex flex-column align-items-center">
            <Col md="8" className="d-flex flex-column align-items-center">
              <p className="label-black-nqt-sm text-center">
                Si vous quittez le quiz, vous devrez le recommencer en entier à
                la prochaine ouverture.
              </p>
            </Col>
          </Row>
          <Row className="d-flex flex-column align-items-center">
            <Col>
              <Button
                className="btn-round-sm-secondary mt-3 me-2"
                onClick={() => setModalShow(false)}
                color="secondary"
                type="submit"
              >
                Annuler
              </Button>
              <Button
                className="btn-round-sm mt-3"
                onClick={() => setContentType('content')}
                color="primary"
                type="submit"
              >
                Fermer le quiz
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TrainingEvalHeader;
