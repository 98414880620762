import TrainingProgressBar from "./TrainingProgressBar";

const TrainingProgress = (props: any) => {
    const { percentage } = props;

    return (
        percentage < 50 ? (
            <TrainingProgressBar
                strokePathColor={"#FF66A9"}
                fillBackgroundColor={"#FF66A9"}
                percentage={percentage}
            />
        ) : percentage >= 50 && percentage < 75 ? (
            <TrainingProgressBar
                strokePathColor={"#ED984F"}
                fillBackgroundColor={"#ED984F"}
                percentage={percentage}
            />
        ) : (
            <TrainingProgressBar
                strokePathColor={"#3367CD"}
                fillBackgroundColor={"#3367CD"}
                percentage={percentage}
            />
        )
    );
};

export default TrainingProgress;
