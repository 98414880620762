import { IoMdTrash } from "react-icons/io";
import Audio from "../../../../components/widget/Audio";

const AudioPreview = (props: any) => {
    const {selectedAudio, setSelectedAudio} = props
    return selectedAudio.map((file:any, idx:number) => {
        return (
          <div key={idx} className="d-flex flex-row download mt-4">
            <div className="d-flex flex-column">
              <Audio> <audio src={file.preview}></audio></Audio>
            </div>
            <div
              className="d-flex flex-row justify-content-center"
              style={{ alignSelf: "center" }}
            >
              <IoMdTrash
                size={18}
                color="#3367cd"
                className="right pointer ms-5 mt-2"
                onClick={() => {
                    setSelectedAudio(null);
                }}
              />
            </div>
          </div>
        );
      });
};

export default AudioPreview;
