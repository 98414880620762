import Dashboard from "../views/dashboard/DashboardContainer";
import Layout from "./Layout";

const Collaborator = () => {
  return (
    <Layout title="Mon tableau de bord">
      <Dashboard />
    </Layout>
  );
};

export default Collaborator;
