import { Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from "reactstrap";
import TrainingProgress from "./TrainingProgress";
import { ReactComponent as ChatIcon } from "../../../../assets/img/icons/chat.svg";
import { useEffect, useState } from "react";
import { ReactComponent as SendIcon } from "../../../../assets/img/icons/send.svg";
import { useAppSelector } from "../../../../redux/hooks";
import { postComment, retrieveTrainingComments, selectTrainingComments, selectTrainingLaunch } from "../../../../redux/slices/trainingSlice";
import moment from "moment";
import 'moment/locale/fr';
import Scrollbars from "react-custom-scrollbars-2";
import { useHistory } from "react-router";
import { ReactComponent as AvatarIcon } from '../../../../assets/img/icons/avatar-person.svg'

const TrainingLaunchHeader = (props: any) => {
    const { dispatch } = props;
    const data = useAppSelector(selectTrainingLaunch);
    const trainingId = data.id;

    const comments = useAppSelector(selectTrainingComments);
    useEffect(() => {
        dispatch(
            retrieveTrainingComments({
                formationId: trainingId
            }));
    }, [dispatch, trainingId]);

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownToggle = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const [commentValue, setCommentValue] = useState("");
    const getCommentValue = (event: any) => {
        setCommentValue(event.target.value);
    };
    const history = useHistory();

    return (
        <Row>
            <Col xs={1} className="mt-2 training-progress-icon">
                <TrainingProgress percentage={data.progression && data.progression.progression ? data.progression.progression : "0"} />
            </Col>
            <Col className="p-0">
                <p className="training-name">{data.title}</p>
            </Col>
            <Col className="p-3 d-flex justify-content-end">
                {data.typology === "async"?
                <Dropdown isOpen={dropdownOpen} toggle={dropdownToggle}>
                    <DropdownToggle caret nav>
                        <ChatIcon className="pointer" />
                    </DropdownToggle>
                    <DropdownMenu right className="comments-dropdown-menu">
                        <Scrollbars
                            style={{ width: 500, height: 400 }}
                            renderThumbVertical={(props) => (
                                <div {...props} className="thumb-vertical-comments-dropdown" />
                            )}>
                            <div>
                                {comments.map((value: any) => {
                                    return (
                                        <DropdownItem tag="a" className="pt-1 pb-0" style={{ backgroundColor: "#ffff" }} >
                                            <a href="#comment" style={{ textDecoration: "none" }}>
                                                <Row>
                                                    <Col xs={2} className="pt-3 pb-0 pe-0">
                                                    {value.user.picture ? (
                                <img
                                  alt="profile"
                                  className={'avatarHome'}
                                  src={value.user.picture}
                                />
                              ) : (
                                <AvatarIcon
                                  style={{
                                    width: 30,
                                    height: 30,
                                    borderRadius: '50%',
                                  }}
                                />
                              )}
                                                    </Col>
                                                    <Col className="pb-0 pt-3 ps-0">
                                                        <p className="icon-right comment-label m-0">{value.user.firstName}</p>
                                                        <p className="icon-right comment-label m-0">{value.comment.text}</p>
                                                        <p className="icon-right comment-date-label m-0">{
                                                            moment(value.comment.date)
                                                                .calendar()
                                                        }
                                                        </p>
                                                    </Col>
                                                </Row>
                                            </a>
                                        </DropdownItem>
                                    )
                                })}
                            </div>
                        </Scrollbars>
                        <hr className="m-0 mt-2 hr-style" />
                        <Row>
                            <Col className="pb-0" md="10">
                                <input
                                    className="formControComment label-black-nqt-sm"
                                    type="text"
                                    style={{ height: "60px" }}
                                    placeholder="Ecrire un commentaire"
                                    onChange={getCommentValue}
                                />
                            </Col>
                            <Col>
                                <SendIcon className="right m-3 pointer"
                                    onClick={() => {
                                        dispatch(postComment({ formationId: trainingId, comment: commentValue }));
                                        setCommentValue("")
                                    }} />
                            </Col>
                        </Row>
                    </DropdownMenu>
                </Dropdown>
                    : null }
                <div className="p-2 pointer" onClick={history.goBack}>
                    <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M11.85 10.4399C11.9447 10.5337 11.9979 10.6615 11.9979 10.7949C11.9979 10.9282 11.9447 11.056 11.85 11.1499L11.15 11.8499C11.0561 11.9445 10.9283 11.9978 10.795 11.9978C10.6617 11.9978 10.5339 11.9445 10.44 11.8499L6 7.40985L1.56 11.8499C1.46612 11.9445 1.33832 11.9978 1.205 11.9978C1.07169 11.9978 0.943888 11.9445 0.850005 11.8499L0.150005 11.1499C0.0553486 11.056 0.00210571 10.9282 0.00210571 10.7949C0.00210571 10.6615 0.0553486 10.5337 0.150005 10.4399L4.59 5.99985L0.150005 1.55985C0.0553486 1.46597 0.00210571 1.33817 0.00210571 1.20485C0.00210571 1.07153 0.0553486 0.943736 0.150005 0.849852L0.850005 0.149852C0.943888 0.055196 1.07169 0.00195313 1.205 0.00195313C1.33832 0.00195313 1.46612 0.055196 1.56 0.149852L6 4.58985L10.44 0.149852C10.5339 0.055196 10.6617 0.00195312 10.795 0.00195312C10.9283 0.00195312 11.0561 0.055196 11.15 0.149852L11.85 0.849852C11.9447 0.943736 11.9979 1.07153 11.9979 1.20485C11.9979 1.33817 11.9447 1.46597 11.85 1.55985L7.41001 5.99985L11.85 10.4399Z"
                            fill="#9A9CB1"
                        />
                    </svg>
                </div>
            </Col>
        </Row >
    )
};

export default TrainingLaunchHeader;
