import { Field } from "formik";

const Checkbox = (props: any) => {
  //Init props
  const { label } = props;

  return (
    <div className="row-auto" style={{ flexWrap: "initial"}}>
      <div className="col">
        <Field type="checkbox" {...props} />
      </div>
        <label className="label-black left pl-0">{label}</label>
    </div>
  );
};
export default Checkbox;
