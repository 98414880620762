import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../redux/hooks";
import { selectUser } from "../../../../redux/slices/userSlice";
import { Col, PopoverBody, UncontrolledPopover } from "reactstrap";
import { AiOutlineCaretDown, AiOutlineCaretUp } from "react-icons/ai";
import React from "react";

const SubjectFilter = (props: any) => {
  //Init props
  const { handleChange, reset } = props;

  //Init states
  const [open, setOpen] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState<number[]>([]);
  const profile = useAppSelector(selectUser);
  const [selectedCategorie, setSelectedCategorie] = useState(
    profile.interetApprentissage[0]?.id
  );

  useEffect(() => {
    const index = profile.interetApprentissage.findIndex(
      (cat) => cat.selectedCategorie
    );
    setSelectedCategorie(
      index !== -1
        ? profile.interetApprentissage[index]?.id
        : profile.interetApprentissage[0]?.id
    );
  }, [profile.interetApprentissage]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleChange(selectedSubject);
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [selectedSubject, handleChange]);

  useEffect(() => {
    if (reset) {
      setSelectedSubject([]);
    }
  }, [reset]);
  const selSelectedId = (e: any) => {
    setSelectedCategorie(+e.currentTarget.dataset.id);
  };

  const categories = profile.interetApprentissage.map((categorie) => {
    return (
      <li
        data-id={categorie.id}
        className={categorie.id === +selectedCategorie ? `list active pointer` : `list pointer`}
        key={categorie.id}
        onClick={selSelectedId.bind(this)}
      >
        {categorie.libelle}
      </li>
    );
  });

  const subject =
    profile.interetApprentissage.find(
      (interet) => interet.id === +selectedCategorie
    )?.sujets || [];

  const setSubjectSelected = (e: any) => {
    const id = +e.currentTarget.dataset.id;
    if (selectedSubject.includes(id)) {
      setSelectedSubject((prev) => {
        const sub = [...prev];
        sub.splice(
          selectedSubject.findIndex((val) => val === id),
          1
        );
        return sub;
      });
    } else {
      setSelectedSubject((prev) => {
        const sub = [...prev];
        sub.push(id);
        return sub;
      });
    }
  };
  const subjectElement = subject.map((subj) => {
    return (
      <li
        className="d-flex subjectList"
        key={subj.id}
        data-id={subj.id}
        onClick={setSubjectSelected.bind(this)}
      >
        {selectedSubject.includes(subj.id) ? (
          <div className="checked">
            <svg
              width="9"
              height="7"
              viewBox="0 0 9 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.75015 4.38408L1.481 3.11494C1.22087 2.85481 0.800078 2.85481 0.539947 3.11494C0.279816 3.37507 0.279816 3.79586 0.539947 4.05599L2.28161 5.79766C2.54175 6.05779 2.96254 6.05779 3.22267 5.79766L7.631 1.38933C7.89113 1.12919 7.89113 0.708403 7.631 0.448272C7.3709 0.188171 6.95018 0.188141 6.69004 0.448183C6.69001 0.448212 6.68998 0.448242 6.68995 0.448272L2.75015 4.38408Z"
                fill="#3367CD"
                stroke="#3367CD"
              />
            </svg>
          </div>
        ) : (
          <div className="unchecked">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM14 11H11V14C11 14.55 10.55 15 10 15C9.45 15 9 14.55 9 14V11H6C5.45 11 5 10.55 5 10C5 9.45 5.45 9 6 9H9V6C9 5.45 9.45 5 10 5C10.55 5 11 5.45 11 6V9H14C14.55 9 15 9.45 15 10C15 10.55 14.55 11 14 11Z"
                fill="#ECF0FB"
              />
            </svg>
          </div>
        )}

        <span className="checkBox">{subj.libelle}</span>
      </li>
    );
  });

  return (
    <>
      <div
        tabIndex={0}
        className={
          !categories.length
            ? "label-grey-normal formControl d-flex"
            : "label-black-normal formControl d-flex"
        }
        color={"#3367CD"}
        onClick={() => setOpen(!open)}
        onBlur={() => setOpen(false)}
        id="PopoverSubject"
      >
        <div className="d-flex filter-label"         style={{backgroundColor: 'white'}}
>
          <div className="name"> SUJETS</div>
          {selectedSubject.length > 0 && (
            <div className="number"> {selectedSubject.length} </div>
          )}
        </div>
        {open ? (
          <AiOutlineCaretUp color="#3367CD" className="float-end" />
        ) : (
          <AiOutlineCaretDown color="#BDBDBD" className="float-end" />
        )}
      </div>
      <UncontrolledPopover
        placement="bottom"
        target="PopoverSubject"
        trigger="legacy"
      >
        <PopoverBody>
          <Col onFocus={() => setOpen(true)} onBlur={() => setOpen(false)}>
            <div>
              <ul className="lists">{categories}</ul>
            </div>
            <div>{subjectElement}</div>
            <div className="clearAll" onClick={() => setSelectedSubject([])}>
              réinitialiser
            </div>
          </Col>
        </PopoverBody>
      </UncontrolledPopover>
    </>
  );
};

export default React.memo(SubjectFilter);
