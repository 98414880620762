import { Card, Col, Row } from "reactstrap";
import { ReactComponent as AIcon } from "../../../../assets/img/icons/A.svg";
import { ReactComponent as BIcon } from "../../../../assets/img/icons/B.svg";
import { ReactComponent as CIcon } from "../../../../assets/img/icons/C.svg";
import { ReactComponent as DIcon } from "../../../../assets/img/icons/D.svg";
import { ReactComponent as InterrogationIcon } from "../../../../assets/img/icons/_.svg";
import CommentModal from "./CommentModal";

import { MdOutlineDone } from "react-icons/md";
import { useState } from "react";

const ValidationProcess = (props: any) => {
  const { values, setFieldValue } = props;
  const [modalIsOpen, setIsOpen] = useState(false);

  /**
   * Open Right Side Modal
   */
  const openModal = () => {
    setIsOpen(true);
  };

  /**
   * close Right Side Modal
   */
  const closeModal = () => {
    setIsOpen(false);
  };
  const getCommentCount = (proofreader: any) => {
    return proofreader.comments.filter((comment:any) => !comment.done).length;
  };

  const getUnassignedProofreader = () => {
    const notAssignedProofreader: any = [];
    const diff = 5 - values.process.proofreaders.length;
    for (let index = 0; index < diff; index++) {
      notAssignedProofreader.push(5 - diff + index + 1);
    }
    return notAssignedProofreader.map((value: number, key: number) => (
      <>
        <div className="ms-3 d-flex flex-row align-items-center">
          <div className="iconOutlined px-3">
            <InterrogationIcon />
          </div>
          <div className="d-flex flex-column  w-100 mt-3">
            <p className="mb-0 ms-3 label-grey-trainings">
              {"Relecteur " + value + " en attente d’assignation"}
            </p>
            <p className="pt-1 ms-3 label-grey-normal opacity-75">
              Votre formation ne peut être publiée tant que 5 relecteur ne l’ont
              pas relue.
            </p>
          </div>
        </div>
        {key !== notAssignedProofreader.length - 1 && (
          <hr className="hr-text-grey p-0  m-0" style={{ width: "97%" }} />
        )}
      </>
    ));
  };
  return (
    <Row className="center-container">
      <Col md="9">
        <Card body color="light" outline className="cardIntro mt-2 p-3 mb-4">
          {values.process.status === "published" ? (
            <div className="d-flex flex-row align-items-center mt-0 pt-0">
              <MdOutlineDone color="#50bd89" className="outlined-success" />
              <div className="d-flex flex-column w-75 ">
                <p className="mb-0 ms-3 label-black fw-bold">
                  Formation publiée
                </p>
                <p className="pt-1 ms-3 label-grey-normal mb-0 pb-0">
                  Votre formation a été approuvée par les relecteurs et l’admin.
                </p>
              </div>
            </div>
          ) : (
            <>
              <div className="d-flex flex-row align-items-center mt-0 pt-0">
                <Col md="10">
                  <div className="d-flex flex-column w-75 mt-3">
                    <p className="mb-0 ms-3 label-black-md-nqt">
                      Processus de validation de la formation
                    </p>
                    <p className="pt-1 ms-3 label-grey-normal mb-0 pb-0">
                      Afin d’être validée et publiée votre formation doir
                      recevoir au moins trois A sur cinq relecteurs.
                    </p>
                  </div>
                </Col>
                <Col md="2">
                  <p
                    className={
                      values.process.status === "pending"
                        ? "label-pink bg-pink-light py-1 px-2  me-3"
                        : "label-primary-xsm bg-blue-light py-1 px-2 me-3 "
                    }
                  >
                    {values.process.status === "pending"
                      ? "En cours de relecture"
                      : values.process.status === "ongoing_review"
                      ? "En attente de relecture"
                      : "Soumise à la publication"}
                  </p>
                </Col>
              </div>
              {values.process.proofreaders.map(
                (proofreader: any, key: number) => (
                  <Row>
                    <Col ms="11">
                      <div className="ms-3 d-flex flex-row align-items-center my-0 py-0">
                        <Col
                          ms="10"
                          className=" d-flex flex-row align-items-center my-0 py-0 w-100"
                        >
                          <div className="iconOutlined px-3">
                            {proofreader.note === "A" ? (
                              <AIcon />
                            ) : proofreader.note === "B" ? (
                              <BIcon />
                            ) : proofreader.note === "C" ? (
                              <CIcon />
                            ) : proofreader.note === "D" ? (
                              <DIcon />
                            ) : (
                              <InterrogationIcon />
                            )}
                          </div>
                          <div className={"d-flex flex-column mt-3 w-100"}>
                            <p className="mb-0 ms-3 label-black-trainings fw-bold">
                              {"Relecteur " + (key + 1)}
                            </p>
                            <p className="pt-1 ms-3 label-grey-normal opacity-75">
                              {getCommentCount(proofreader) +
                                " commentaires non traités"}
                            </p>
                          </div>
                        </Col>
                        <Col ms="2">
                          <div className="justify-content-center align-items-center">
                            <p
                              className={
                                proofreader.note === "A"
                                  ? "mt-3 label-green right me-4"
                                  : "mt-3 me-0 label-primary-nqt-sm pointer right me-4"
                              }
                            >
                              {proofreader.note === "A" ? (
                                <div className="d-flex flex-row">
                                  <MdOutlineDone
                                    color="#50bd89"
                                    className="me-1"
                                    size={20}
                                  />{" "}
                                  Validé
                                </div>
                              ) : (
                                <p className="p-0 m-0" onClick={openModal}>
                                  Voir les commentaires
                                </p>
                              )}
                            </p>
                          </div>
                        </Col>
                      </div>
                      <hr
                        className="hr-text-grey p-0 ms-3 m-0"
                        style={{ width: "97%" }}
                      />
                    </Col>
                  </Row>
                )
              )}
              {getUnassignedProofreader()}
            </>
          )}
        </Card>
      </Col>
      <CommentModal
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        values={values}
        setFieldValue={setFieldValue}
      />
    </Row>
  );
};
export default ValidationProcess;
