import React, { useEffect } from "react";
import { useMedia } from "react-media";
import Layout from "../../components/Layout";
import { tutorialData } from "../../utils/tutorialConts";

export default function TutorialContainer() {
  const GLOBAL_MEDIA_QUERIES = {
    small: "(max-width: 414px)",
    medium: "(min-width: 414px) and (max-width: 991px)",
    large: "(min-width: 991px) and (max-width: 1199px)",
    xLarge: "(min-width: 1200px)",
  };
  const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });

  useEffect(() => {
    // this is used to re-render and apply new styles
  }, [matches]);

  return (
    <Layout title="Tutoriel">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        {tutorialData.map((el) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                margin: 20,
              }}
            >
              <div
                style={{
                  marginBottom: 25,
                  fontSize: matches.small ? 20 : 30,
                  fontFamily: "CircularStd",
                }}
              >
                {el.title}
              </div>
              <div>
                <video
                  controls
                  height={matches.small ? 150 : matches.medium ? 250 : 400}
                >
                  <source src={el.videoUrl} />
                </video>
              </div>
            </div>
          );
        })}
      </div>
    </Layout>
  );
}
