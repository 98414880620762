import http from "../config/httpCommon";

const TrainingService = {
  getTrainingCourses(page: number, formationStatus: string) {
    const params = formationStatus
      ? {
          limit: 5,
          page,
          formationStatus,
        }
      : {
          limit: 5,
          page,
        };
    return new Promise(async (resolve, reject) => {
      (await http())
        .get("/formations/related", { params })
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },

  getCreatedTraining(page: number, formationStatus: string) {
    const params = formationStatus
      ? {
          limit: 5,
          page,
          formationStatus,
        }
      : {
          limit: 5,
          page,
        };
    return new Promise(async (resolve, reject) => {
      (await http())
        .get("/formations/created", { params })
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },

  getNotifications() {
    return new Promise(async (resolve, reject) => {
      (await http())
        .get("/notifications")
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },

  getSubjects() {
    return new Promise(async (resolve, reject) => {
      (await http())
        .get("/me/subjects")
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },

  getTrainingCoursesCount() {
    return new Promise(async (resolve, reject) => {
      (await http())
        .get("/formations/related/count")
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },

  getAllEvents() {
    return new Promise(async (resolve, reject) => {
      (await http())
        .get("/events")
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },

  getCreatedTrainingsCount() {
    return new Promise(async (resolve, reject) => {
      (await http())
        .get("/formations/created/count")
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },

  postTraining(values: any) {
    return new Promise(async (resolve, reject) => {
      (await http())
        .post("/formation", values)
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },

  postTrainingSave(id: number) {
    return new Promise(async (resolve, reject) => {
      (await http())
        .post("/formation/save/" + id)
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },

  postTrainingSubscribe(id: number) {
    return new Promise(async (resolve, reject) => {
      (await http())
        .post("/formation/subscribe/" + id)
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },

  postTrainingStart(id: number) {
    return new Promise(async (resolve, reject) => {
      (await http())
        .post("/formation/start/" + id)
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },

  finishContent(id: number | null, formationId?: number) {
    if (formationId) {
      return new Promise(async (resolve, reject) => {
        (await http())
          .post("/content/finish/" + id, { formationId })
          .then((response: any) => resolve(response.data))
          .catch((error: any) => reject(error.message));
      });
    } else {
      return new Promise(async (resolve, reject) => {
        (await http())
          .post("/content/finish/" + id)
          .then((response: any) => resolve(response.data))
          .catch((error: any) => reject(error.message));
      });
    }
  },

  finishSection(id: number) {
    return new Promise(async (resolve, reject) => {
      (await http())
        .post("/section/finish/" + id)
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },
  postAnswer(id: number, answer: string[]) {
    return new Promise(async (resolve, reject) => {
      (await http())
        .post("/question/answer/" + id, { answer })
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },

  postEvaluate(id: number, score: number, total: number) {
    return new Promise(async (resolve, reject) => {
      (await http())
        .post("/section/evaluate/" + id, { score, total })
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },

  putTraining(values: any) {
    return new Promise(async (resolve, reject) => {
      (await http())
        .put("/formation/" + values.id, values)
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },

  getTraining(id: number) {
    return new Promise(async (resolve, reject) => {
      (await http())
        .get(`/formation/${id}`)
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },

  unpublishTraining(id: number) {
    return new Promise(async (resolve, reject) => {
      (await http())
        .put(`/formation/${id}/unpublish`)
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },

  deleteTraining(id: number) {
    return new Promise(async (resolve, reject) => {
      (await http())
        .delete(`/formation/${id}`)
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },

  getTrainingsuggestions(id: number) {
    return new Promise(async (resolve, reject) => {
      (await http())
        .get(`/formation/suggest/${id}`)
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },

  getTrainingCatalog(data: any) {
    return new Promise(async (resolve, reject) => {
      (await http())
        .post("/catalogue/all", data)
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },

  getTrainingNote(data: any) {
    return new Promise(async (resolve, reject) => {
      (await http())
        .post("/review/note/waiting", data)
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },
  getTrainingProofreaders(data: any) {
    return new Promise(async (resolve, reject) => {
      (await http())
        .post("/review/relecture/waiting", data)
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },
  getTrainingReview(id: number) {
    return new Promise(async (resolve, reject) => {
      (await http())
        .get("/review/" + id)
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },

  getTrainingCatalogCount() {
    return new Promise(async (resolve, reject) => {
      (await http())
        .get("/catalogue/count")
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },

  getTrainingsAccordingToInterests() {
    return new Promise(async (resolve, reject) => {
      (await http())
        .get("/catalogue/interest")
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },

  getPleasingTrainings() {
    return new Promise(async (resolve, reject) => {
      (await http())
        .get("/catalogue/like")
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },

  getTrainingComments(formationId: number) {
    const params = { formationId };
    return new Promise(async (resolve, reject) => {
      (await http())
        .get("/comment", { params })
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },

  postComment(formationId: number, comment: string) {
    return new Promise(async (resolve, reject) => {
      (await http())
        .post("/comment", {
          formationId,
          comment,
        })
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },

  putComment(id: Number) {
    return new Promise(async (resolve, reject) => {
      (await http())
        .put("/review/comment/" + id)
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },
  getReviewComments(formationId: number) {
    return new Promise(async (resolve, reject) => {
      (await http())
        .get("/review/comment/" + formationId)
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },

  postReviewComment(formationId: number, comment: string) {
    return new Promise(async (resolve, reject) => {
      (await http())
        .post("/review/comment", {
          formationId,
          comment,
        })
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },

  getFeedback(formationId: number, userId?: number) {
    const params = formationId
      ? {
          formationId: formationId,
        }
      : {
          userId: userId,
        };
    return new Promise(async (resolve, reject) => {
      (await http())
        .get("/feedback", { params })
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },

  deleteReviewComment(formationId: number) {
    return new Promise(async (resolve, reject) => {
      (await http())
        .delete("/review/comment/" + formationId)
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },

  postFeedback(data: {
    formationId: number;
    feedback: { note?: number; comment?: string };
  }) {
    return new Promise(async (resolve, reject) => {
      (await http())
        .post("/feedback", data)
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },
  postCodeValidationStatus({ formationId, status_validation }: any) {
    return new Promise(async (resolve, reject) => {
      (await http())
        .post("/formation/validate-presence/" + formationId, {
          status_validation,
        })
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },
  postCheckCodeValidation({ formationId, code_validation }: any) {
    return new Promise(async (resolve, reject) => {
      (await http())
        .post("/formation/check-code-validation/" + formationId, {
          code_validation,
        })
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },
  postNote(data: { formationId: number; note: number; comment: string }) {
    return new Promise(async (resolve, reject) => {
      (await http())
        .post("/review/note", data)
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },

  getAllPublishedTrainings(data: any) {
    return new Promise(async (resolve, reject) => {
      (await http())
        .post("/editor/list/published", data)
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },

  getAllUnpublishedTrainings(data: any) {
    return new Promise(async (resolve, reject) => {
      (await http())
        .post("/editor/list/unpublished", data)
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },

  getAllNonPublishedTrainings(data: any) {
    return new Promise(async (resolve, reject) => {
      (await http())
        .post("/editor/list/rejected", data)
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },
  getAllNonPublishedSyncTrainings(data: any) {
    return new Promise(async (resolve, reject) => {
      (await http())
        .post("/editor/list/sync", data)
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },
  getTrainingsAwaitingNotation(data: any) {
    return new Promise(async (resolve, reject) => {
      (await http())
        .post("/review/note/waiting", data)
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },

  getTrainingsAwaitingProofreading(data: any) {
    return new Promise(async (resolve, reject) => {
      (await http())
        .post("/review/relecture/waiting", data)
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },

  assignProofreaderToTraining(formationId: number, userId: number) {
    const params = {
      formationns: [formationId],
      users: [userId],
    };
    return new Promise(async (resolve, reject) => {
      (await http())
        .post("/reviewer/assign", { params })
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },

  getMentorsData(data: any) {
    return new Promise(async (resolve, reject) => {
      (await http())
        .get("/users/mentors", { params: data })
        .then((response: any) =>  resolve(response.data))
        .catch((error: any) => {
          reject(error.message);
        });
    });
  },
};

export default TrainingService;
