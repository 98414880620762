import { useEffect, useState } from "react";

import "../../../styles/clubnqt.scss";
import useHttp from "../../../hooks/use-http";
import ClubNqtService from "../../../services/clubnqt.service";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {selectTrainingPreviewDetail, trainingActions} from "../../../redux/slices/trainingSlice";
import { useHistory, useParams } from "react-router";
import Layout from "../../../components/Layout";
import News from "./components/News";


const PostViewContainer = () => {
  //Init states
  const [type, setType] = useState("");
  const [postId, setPostId] = useState(0);
  const { idPost }: any = useParams();
  const history = useHistory()

  const trainingEditWithoutId = useAppSelector(selectTrainingPreviewDetail)
  const dispatch = useAppDispatch()
  useEffect(()=>{
    // @ts-ignore
    if(trainingEditWithoutId.typology !== '' && !trainingEditWithoutId.trainingId)
    {
      history.push("/training")
      dispatch(trainingActions.setModal({show:true,targetRoute:"clubNqt"}))
    }
    else{
      dispatch(trainingActions.setModal({targetRoute:"clubNqt"}))
    }
  },[trainingEditWithoutId])

  //Init requests callBack
  const {
    sendRequest: retrievePost,
    data,
    status,
  } = useHttp(ClubNqtService.getPost);
  const [postData, setPostData] = useState(data);

  const {
    sendRequest: retrieveCommentByPost,
    data: commentsByPost,
    status: loading,
  } = useHttp(ClubNqtService.getCommentsByPost);

  const {
    sendRequest: sendReactionRequest,
    data: reaction,
    status: reactionLoading,
  } = useHttp(ClubNqtService.reactToPost);

  const { sendRequest: postComment, status: statusComment } = useHttp(
    ClubNqtService.postComment
  );
  const [commentsData, setCommentsData] = useState([]);
  /**
   * Post reactions callBack
   * @param reaction
   * @param id
   */
  const reactToPost = (reaction: any, id: number) => {
    const postReaction = postData.userReaction;

    sendReactionRequest({
      post_id: id,
      reaction_type: postReaction === reaction ? "" : reaction,
    });
  };
  const updatePostReaction = () => {
    let dataReaction = {
      ...postData,
      userReaction: reaction.reaction_type,
      likeCount: reaction.likeCount,
      reactions: reaction.reactions,
    };

    setPostData(dataReaction);
  };
  /**
   * Send comment
   * @param text
   * @param id
   * @param setFieldValue
   */
  const sendComment = (text: any, id: number, mentions: string[]) => {
    postComment({ post_id: id, text, mentions });
    setPostId(id);
    setType("comment");
  };

  useEffect(() => {
    if (statusComment === "completed") {
      retrieveCommentByPost({ post_id: postId, page: 1 });
      retrievePost(idPost);
    }
  }, [statusComment, postId, retrieveCommentByPost, retrievePost, idPost]);

  //Effects
  useEffect(() => {
    retrievePost(idPost);
  }, []);
  const mergeCommentsData = () => {
    setCommentsData((prev) =>
      type === "pagination" ? prev.concat(commentsByPost) : commentsByPost
    );
  };
  useEffect(() => {
    loading === "completed" && mergeCommentsData();
  }, [commentsByPost]);
  const updatePosts = async () => {
    await setPostData(data);
  };

  useEffect(() => {
    reactionLoading === "completed" && updatePostReaction();
  }, [reaction]);
  useEffect(() => {
    if (data) {
      updatePosts();
    }
  }, [data]);
  return (
    <Layout title="Club NQT">
      {
        <News
          status={status}
          post={postData}
          retrievePost={retrievePost}
          reactToPost={reactToPost}
          sendComment={sendComment}
          retrieveCommentByPost={retrieveCommentByPost}
          commentsByPost={commentsData}
          setCommentsData={setCommentsData}
          setType={setType}
        />
      }
    </Layout>
  );
};

export default PostViewContainer;
