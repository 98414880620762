import { Fragment } from "react";
import { NavLink } from "react-router-dom";
import { Row, Col, Card, CardBody } from "reactstrap";

const Todo = () => {
  return (
    <Fragment>
      <Row className="center-container mb-4" key={1}>
        <Col md="12">
          <Card body color="light" outline className="card p-0">
            <CardBody className="d-flex">
              <Col md="1" className="col-center">
                <div className="col-center label-orange-sm">{"à voir"}</div>
              </Col>
              <Col md="9">
                <div className="todo">
                  <div>
                    {"Visionner la formation"}
                    <NavLink
                      to={"/tutorial"}
                      style={{ textDecoration: "none" }}
                    >
                      <span className="name">
                        {" "}
                        “{"Premiers pas d'un mentor"}”
                      </span>
                    </NavLink>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="pull-right">
                  {" "}
                  <NavLink to={"/tutorial"}>
                    <svg
                      width="18"
                      height="12"
                      viewBox="0 0 18 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.0840964 5.58337L0.0840963 6.4167C0.0840963 6.64682 0.281764 6.83337 0.525598 6.83337L14.8037 6.83337L10.8744 10.5334C10.7908 10.6116 10.7438 10.7181 10.7438 10.8292C10.7438 10.9403 10.7908 11.0468 10.8744 11.125L11.5013 11.7084C11.5842 11.7873 11.6971 11.8316 11.8148 11.8316C11.9325 11.8316 12.0453 11.7873 12.1282 11.7084L17.5499 6.60004C17.6741 6.48294 17.744 6.32408 17.7441 6.15837L17.7441 5.84171C17.7421 5.67636 17.6725 5.51814 17.5499 5.40004L12.1282 0.291706C12.0453 0.212826 11.9325 0.168457 11.8148 0.168457C11.6971 0.168457 11.5842 0.212826 11.5013 0.291706L10.8744 0.883372C10.7913 0.960175 10.7445 1.0653 10.7445 1.17504C10.7445 1.28478 10.7913 1.3899 10.8744 1.46671L14.8037 5.16671L0.525598 5.1667C0.281765 5.1667 0.0840964 5.35325 0.0840964 5.58337Z"
                        fill="#ED984F"
                      />
                    </svg>
                  </NavLink>
                </div>
              </Col>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Todo;
