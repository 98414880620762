import { Field } from "formik";

const TextArea = (props: any) => {
  //Init props
  const { error } = props;

  return (
    <>
      <div className="col">
        <Field as="textarea" className="formTextarea label-black" {...props} />
        {error && <label className="label-pink right">{error}</label>}
      </div>
    </>
  );
};
export default TextArea;
