import { useEffect } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { useAppSelector } from "../../../../redux/hooks";
import { retrievePleasingTrainings, selectPleasingTrainings } from "../../../../redux/slices/trainingSlice";
import RowIcon from "./RowIcon";
import TrainingRow from "./TrainingRow";
import { ReactComponent as RefreshIcon } from "../../../../assets/img/icons/refreshIcon.svg";

const PleasingTrainings = (props: any) => {
    const { dispatch, setNavStep } = props;
    const trainings = useAppSelector(selectPleasingTrainings);
    useEffect(() => {
        dispatch(retrievePleasingTrainings())
    }, [dispatch]);
    return (
        <Col lg="6" md="12">
            <Card body color="light" outline className="catalog-table-header p-0">
                <CardBody>
                    <Row className="pb-3 w-100">
                        <Col md="9" sm="9" xs="9">
                            <div className="label-black-nqt-xmd"> Formations susceptibles de vous plaire </div>
                        </Col>
                        <Col md="3" sm="3" xs="3" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                            <div className="pull-right label-primary-nqt-sm show-more pointer"
                                onClick={() => {
                                    dispatch(
                                        retrievePleasingTrainings()
                                    );;
                                }}>
                                <RefreshIcon />
                            </div>
                        </Col>
                    </Row>
                    {trainings.map((val: any, key: number) => {
                        return (
                            <Row className={trainings.length - 1 === key ? "w-100 pb-3 mt-2" : "w-100 border-bottom pb-3 mt-2"}>
                                <TrainingRow
                                    id={val.id}
                                    name={val.nom}
                                    subjects={val.sujet}
                                    setNavStep={setNavStep}
                                    >
                                    <RowIcon state={val.type}></RowIcon>
                                </TrainingRow>
                            </Row>
                        )
                    })}
                </CardBody>
            </Card>
        </Col>
    );
};

export default PleasingTrainings;
