import { Col, Row, Card } from "reactstrap";
import Rating from "react-rating";
import moment from "moment";

import { ReactComponent as CommaIcon } from "../../../../assets/img/icons/comma.svg";
import { ReactComponent as FeedBackIcon } from "../../../../assets/img/icons/feedBackPrimary.svg";
import { ReactComponent as StarIcon } from "../../../../assets/img/icons/starPrimary.svg";
import { ReactComponent as EmptyStarIcon } from "../../../../assets/img/icons/emptyPrimaryStar.svg";
import { ReactComponent as AvatarIcon } from "../../../../assets/img/icons/avatar-person.svg";

const DetailsContent = (props: any) => {
  const { training, feedback } = props;
  const noteDescription = [
    "Pas du tout satisfait",
    "Pas très satisfait",
    "Bien",
    "Très bien",
    "Parfait",
  ];
  return (
    <Row>
      <Col md="10">
        <div className="d-flex flex-row mt-3">
          <CommaIcon className="me-2" />
          <p className="label-black-nqt-sm fw-bolder mb-2 pb-0">
            À propos de la formation
          </p>
        </div>
        <Card body className="cardDetailsDesc mt-0 pb-2 p-4 ">
          {training.description.replace(/<br\s*\/?>/gm, "\r\n").split(/[\n]+/).map((item: any, idx: any) => (
            <p key={idx} className="label-grey-normal">
              {item}
              <br />
            </p>
          ))}
        </Card>

        <div className="d-flex flex-row mt-4">
          <FeedBackIcon className="me-2" />
          <p className="label-black-nqt-sm fw-bolder mb-2 pb-0">
            Feedbacks obtenus
          </p>
        </div>
        <Card body className="cardDetails mt-0">
          <div className="d-flex flex-row w-100 align-items-center">
            <p className="label-primary-md  ps-4">{feedback.commentsCount}</p>
            <p className="label-black-nqt  ps-2 mt-3 ">Commentaires</p>
            <Rating
              className="ms-4"
              initialRating={feedback.noteMoy}
              readonly
              emptySymbol={<EmptyStarIcon />}
              fullSymbol={<StarIcon />}
            />
            <p className="label-black-nqt  ps-2 mt-3 ">{feedback.noteMoy % 1 !== 0 ? Number(feedback.noteMoy).toFixed(2) : feedback.noteMoy}</p>
          </div>
          <hr className="hr-text-grey p-0 m-0" />
          <div className="feedback p-3">
            {feedback &&
              feedback.feedBacks &&
              feedback.feedBacks.map((el: any, key: number) => (
                <>
                  <div className="d-flex flex-row">
                    {el.user.picture ? (
                      <img
                        alt="profile"
                        style={{ width: 30, height: 30, borderRadius: "50%" }}
                        src={el.user.picture}
                      />
                    ) : (
                      <AvatarIcon
                        className=" me-2"
                        style={{ width: 30, height: 30, borderRadius: "50%" }}
                      />
                    )}
                    <div className="d-flex flex-column w-75">
                      <p className="label-black fw-bold m-0">
                        {el.user.firstName + " " + el.user.lastName}
                        <span className="label-grey-trainings-sm ms-3">
                          {" Le " +
                            moment(el.feedback.date).format("DD/MM/YYYY")}
                        </span>
                      </p>
                      <p className="label-black  my-1">
                        {el.feedback.comment || ""}
                      </p>
                      <div className="d-flex flex-row">
                        <p className="label-primary-normal mt-2">
                          {noteDescription[el.feedback.note - 1]}
                        </p>

                        <Rating
                          className="ms-2"
                          initialRating={el.feedback.note}
                          readonly
                          emptySymbol={<EmptyStarIcon />}
                          fullSymbol={<StarIcon />}
                        />
                      </div>
                    </div>
                  </div>
                  {key !== feedback.feedBacks.length - 1 && (
                    <hr className="hr-text-grey p-0 m-0 my-3" />
                  )}
                </>
              ))}
          </div>
        </Card>
      </Col>
    </Row>
  );
};
export default DetailsContent;
