import { useMedia } from "react-media";
import { Col, Row } from "reactstrap";
import BoxLaunch from "../../../../components/widget/BoxLaunch";
import { useAppSelector } from "../../../../redux/hooks";
import { selectTrainingLaunch } from "../../../../redux/slices/trainingSlice";

const TraininhContentHeader = () => {
  const data = useAppSelector(selectTrainingLaunch);
  const GLOBAL_MEDIA_QUERIES = {
    small: "(max-width: 991px)",
    large: "(min-width: 992px)",
  };
  const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
  return (
    <>
      {matches.large ? (
        <div className="preview-content-header d-flex">
          <Col md="8">
            <Row className="d-flex preview-title">
              <Col md={{ offset: 4 }}>
                <div className="label-white-xlg">{data?.title}</div>
              </Col>
            </Row>
            <Row className="d-flex preview-subject">
              <Col className="d-flex" md={{ offset: 4 }}>
                <Row className="d-flex">
                  {data.subject.map((cat) => {
                    return <BoxLaunch category={cat} nbreCat={data.subject.length} key={cat.id}></BoxLaunch>;
                  })}
                </Row>
              </Col>
            </Row>
            <Row className="d-flex preview-subject">
              <Col className="d-flex" md={{ offset: 4 }}>
                <svg
                  width="18"
                  height="15"
                  viewBox="0 0 18 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8 9V2C8 0.89543 7.10457 0 6 0H2C0.89543 0 0 0.89543 0 2V6C0 7.10457 0.89543 8 2 8H5V9C5.01856 9.96862 4.34023 10.8113 3.39 11C3.1683 11.0498 3.0081 11.2429 3 11.47V13.47C2.99257 13.6252 3.05492 13.7756 3.17 13.88C3.27637 13.9748 3.41874 14.0186 3.56 14C6.10071 13.7137 8.01608 11.5567 8 9ZM12 0H16C17.1046 0 18 0.89543 18 2V9C18.011 11.5529 16.0969 13.7041 13.56 13.99C13.4187 14.0086 13.2764 13.9648 13.17 13.87C13.0629 13.7725 13.0013 13.6348 13 13.49V11.49C12.9989 11.2554 13.1611 11.0516 13.39 11C14.3402 10.8113 15.0186 9.96862 15 9V8H12C10.8954 8 10 7.10457 10 6V2C10 0.89543 10.8954 0 12 0Z"
                    fill="#ECF0FB"
                  />
                </svg>
                <div className="label-white-lg pl-10">
                  {" "}
                  À propos de la formation
                </div>
              </Col>
            </Row>
            <Row className="d-flex preview-subject">
              <Col md={{ offset: 4 }}>
                {data?.description.replace(/<br\s*\/?>/gm, "\r\n").split(/[\n]+/).map((item: any, idx: any) => (
                  <p key={idx} className="label-white-md">
                    {item}
                    <br />
                  </p>
                ))}
              </Col>
            </Row>
          </Col>
          <Col md="4" className="d-flex">
            <Col>
              <div className="box">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0ZM10 3C11.6569 3 13 4.34315 13 6C13 7.65685 11.6569 9 10 9C8.34315 9 7 7.65685 7 6C7 4.34315 8.34315 3 10 3ZM10 16.9868C12.2151 16.9868 14.2994 15.9384 15.62 14.16C15.8246 13.8609 15.8514 13.4745 15.69 13.15L15.46 12.68C14.9568 11.6531 13.9136 11.0015 12.77 11H7.23C6.07012 11.0012 5.01488 11.671 4.52 12.72L4.31 13.16C4.15251 13.4818 4.17921 13.8633 4.38 14.16C5.70056 15.9384 7.78493 16.9868 10 16.9868Z"
                    fill="#3367CD"
                  />
                </svg>
                <div className="label-blue-md pt-3">Créateur</div>
                <div className="label-grey-md-normal">{`${data.creator?.firstName} ${data.creator?.lastName}`}</div>
              </div>
            </Col>
            <Col>
              <div className="box">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0ZM10 3C11.6569 3 13 4.34315 13 6C13 7.65685 11.6569 9 10 9C8.34315 9 7 7.65685 7 6C7 4.34315 8.34315 3 10 3ZM10 16.9868C12.2151 16.9868 14.2994 15.9384 15.62 14.16C15.8246 13.8609 15.8514 13.4745 15.69 13.15L15.46 12.68C14.9568 11.6531 13.9136 11.0015 12.77 11H7.23C6.07012 11.0012 5.01488 11.671 4.52 12.72L4.31 13.16C4.15251 13.4818 4.17921 13.8633 4.38 14.16C5.70056 15.9384 7.78493 16.9868 10 16.9868Z"
                    fill="#3367CD"
                  />
                </svg>
                <div className="label-blue-md pt-3">Entreprise</div>
                <div className="label-grey-md-normal">
                  {data.creator?.entreprise}
                </div>
              </div>
            </Col>
          </Col>
        </div>
      ) : (
        <div className="preview-content-header m-auto">
          <Row className="d-flex preview-title">
            <Col md={{ offset: 4 }}>
              <div className="label-white-xlg">{data?.title}</div>
            </Col>
          </Row>
          <Row className="d-flex preview-subject">
              <Col className="d-flex" md={{ offset: 4 }}>
                <Row className="d-flex">
                  {data.subject.map((cat) => {
                    return <BoxLaunch category={cat} nbreCat={data.subject.length} key={cat.id}></BoxLaunch>;
                  })}
                </Row>
              </Col>
            </Row>
          <Row className="d-flex preview-subject">
            <Col className="d-flex" md={{ offset: 4 }}>
              <svg
                width="18"
                height="15"
                viewBox="0 0 18 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8 9V2C8 0.89543 7.10457 0 6 0H2C0.89543 0 0 0.89543 0 2V6C0 7.10457 0.89543 8 2 8H5V9C5.01856 9.96862 4.34023 10.8113 3.39 11C3.1683 11.0498 3.0081 11.2429 3 11.47V13.47C2.99257 13.6252 3.05492 13.7756 3.17 13.88C3.27637 13.9748 3.41874 14.0186 3.56 14C6.10071 13.7137 8.01608 11.5567 8 9ZM12 0H16C17.1046 0 18 0.89543 18 2V9C18.011 11.5529 16.0969 13.7041 13.56 13.99C13.4187 14.0086 13.2764 13.9648 13.17 13.87C13.0629 13.7725 13.0013 13.6348 13 13.49V11.49C12.9989 11.2554 13.1611 11.0516 13.39 11C14.3402 10.8113 15.0186 9.96862 15 9V8H12C10.8954 8 10 7.10457 10 6V2C10 0.89543 10.8954 0 12 0Z"
                  fill="#ECF0FB"
                />
              </svg>
              <div className="label-white-lg pl-10">
                {" "}
                À propos de la formation
              </div>
            </Col>
          </Row>
          <Row className="d-flex preview-subject">
            <Col md={{ offset: 4 }}>
              {data?.description.replace(/<br\s*\/?>/gm, "\r\n").split(/[\n]+/).map((item: any, idx: any) => (
                <div key={idx} className="label-white-md">
                  {item}
                  <br />
                </div>
              ))}
            </Col>
          </Row>
          <Row className="d-flex preview-subject">
            <Col xs="6">
              <div className="box" style={{ top: "50%" }}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0ZM10 3C11.6569 3 13 4.34315 13 6C13 7.65685 11.6569 9 10 9C8.34315 9 7 7.65685 7 6C7 4.34315 8.34315 3 10 3ZM10 16.9868C12.2151 16.9868 14.2994 15.9384 15.62 14.16C15.8246 13.8609 15.8514 13.4745 15.69 13.15L15.46 12.68C14.9568 11.6531 13.9136 11.0015 12.77 11H7.23C6.07012 11.0012 5.01488 11.671 4.52 12.72L4.31 13.16C4.15251 13.4818 4.17921 13.8633 4.38 14.16C5.70056 15.9384 7.78493 16.9868 10 16.9868Z"
                    fill="#3367CD"
                  />
                </svg>
                <div className="label-blue-md pt-3">Créateur</div>
                <div className="label-grey-md-normal">{`${data.creator?.firstName} ${data.creator?.lastName}`}</div>
              </div>
            </Col>
            <Col xs="6">
              <div className="box" style={{ top: "50%" }}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0ZM10 3C11.6569 3 13 4.34315 13 6C13 7.65685 11.6569 9 10 9C8.34315 9 7 7.65685 7 6C7 4.34315 8.34315 3 10 3ZM10 16.9868C12.2151 16.9868 14.2994 15.9384 15.62 14.16C15.8246 13.8609 15.8514 13.4745 15.69 13.15L15.46 12.68C14.9568 11.6531 13.9136 11.0015 12.77 11H7.23C6.07012 11.0012 5.01488 11.671 4.52 12.72L4.31 13.16C4.15251 13.4818 4.17921 13.8633 4.38 14.16C5.70056 15.9384 7.78493 16.9868 10 16.9868Z"
                    fill="#3367CD"
                  />
                </svg>
                <div className="label-blue-md pt-3">Entreprise</div>
                <div className="label-grey-md-normal">
                  {data.creator?.entreprise}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default TraininhContentHeader;
