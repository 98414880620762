import { useState, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import logo from "../assets/img/logo.png";
import { Nav } from "react-bootstrap";
import { IoMdBriefcase } from "react-icons/io";
import { BiNews } from "react-icons/bi";
import { MdBusiness } from "react-icons/md";
import { AiFillHome, AiFillPlusSquare, AiFillCalendar } from "react-icons/ai";
import {
  homeRoutes,
  EditeurRoutes,
  collaborateurRoutes,
} from "../config/routes";
import { NavLink } from "react-router-dom";
import "../styles/home.scss";

const SubMenuNavLink = ({ title, route, trainingId = undefined }: any) => {
  return (
    <NavLink
      key={route.name}
      to={route.path}
      className="navLink d-flex flex-row pb-2"
    >
      <AiFillPlusSquare size={28} className="navLinkIcon ms-5" />
      <p className={(title === route.name ? "active" : "") + " mt-1"}>
        {route.name}
      </p>
    </NavLink>
  );
};

const EditorSubMenuNavLink = ({ title, route, trainingId = undefined }: any) => {
  const history = useHistory();
  return (
    <Nav.Link
      key={route.name}
      eventKey={route.name}
      onClick={() => history.push("/editor", { route: route.name })}
      className="navLink d-flex flex-row ms-4 pt-0"
    >
      <AiFillPlusSquare size={28} className="navLinkIcon" />
      <p
        className={title === route.name ? "active" : ""}
        style={{ fontSize: 12, marginTop: 5 }}
      >
        {route.name}
      </p>
    </Nav.Link>
  );
};

const LayoutSideBar = (props: any) => {
  const { title, user, isEditor, isNqtCollaborator, isAlumuni } = props;
  const companyId = user.expertisesMetier.entrepriseId;

  const baseUrl = useMemo(
    () =>
      window.location.href.includes("clubmentors")
        ? "https://"
        : "https://preprod.",
    []
  );

  const [hover, setHover] = useState(false);
  const [indexOfHoveredItem, setIndexOfHoveredItem] = useState(-1);
  const { trainingId } = useParams<any>()

  const mentorSideBarRoute = homeRoutes.map((route: any) => (
    <SubMenuNavLink title={title} route={route} trainingId={trainingId} />
  ));

  const alumniSideBarRoute = homeRoutes
    .filter((route: any) => route.name !== "Créer une formation")
    .map((route: any) => <SubMenuNavLink title={title} route={route} trainingId={trainingId} />);

  const editorNqtClubSideBarRoute = homeRoutes.map((route: any) => (
    <EditorSubMenuNavLink title={title} route={route} />
  ));

  const editorSideBarRoute = EditeurRoutes.map((route: any) => (
    <EditorSubMenuNavLink title={title} route={route} />
  ));

  const collaboratorSideBarRoute = collaborateurRoutes.map((route: any) => (
    <SubMenuNavLink title={title} route={route} trainingId={trainingId} />
  ));

  const menuItems = [
    {
      id: 1,
      name: "Accueil",
      toBeShown: true,
      link: isEditor || isNqtCollaborator ? "api.nqt.fr/" : "app.nqt.fr/",
      icon: AiFillHome,
      iconSize: 32,
    },
    {
      id: 2,
      name: "Mon Entreprise",
      toBeShown: !isEditor && !isNqtCollaborator,
      link: `app.nqt.fr/company/${companyId}`,
      icon: MdBusiness,
      iconSize: 32,
    },
    {
      id: 3,
      name: "Évènements",
      toBeShown: !isEditor && !isNqtCollaborator,
      link: "app.nqt.fr/events",
      icon: AiFillCalendar,
      iconSize: 32,
    },
    {
      id: 4,
      name: "Offres d'emploi",
      toBeShown: !isEditor && !isNqtCollaborator,
      link: "app.nqt.fr/jobs",
      icon: IoMdBriefcase,
      iconSize: 32,
    },
    {
      id: 5,
      name: "Club NQT",
      toBeShown: true,
      link: "/",
      icon: BiNews,
      subMenu: isEditor
        ? editorNqtClubSideBarRoute
        : isAlumuni
        ? alumniSideBarRoute
        : isNqtCollaborator
        ? collaboratorSideBarRoute
        : mentorSideBarRoute,
      iconSize: 32,
    },
    {
      id: 6,
      name: "Editeur",
      toBeShown: isEditor,
      link: "/",
      icon: BiNews,
      subMenu: editorSideBarRoute,
      iconSize: 32,
    },
  ];

  const NQTMenu = (
    <>
      {menuItems.map((item) => {
        const TagName = item.icon;
        return (
          <div key={item.id}>
            {item.toBeShown && (
              <>
                <Nav.Link
                  onClick={(event) => {
                    if (item.name === "Club NQT" || item.name === "Editeur")
                      event.preventDefault();
                    else {
                      window.onbeforeunload = null;
                      window.location.href = `${baseUrl}${item.link}`;
                    }
                  }}
                  className="navLink d-flex flex-row pb-1 ms-2"
                  onMouseEnter={() => {
                    setIndexOfHoveredItem(item.id);
                    setHover(true);
                  }}
                  onMouseLeave={() => setHover(false)}
                  style={{
                    ...(hover && item.id === indexOfHoveredItem
                      ? { background: "#f9f9f9" }
                      : null),
                  }}
                >
                  <TagName
                    color={
                      hover && item.id === indexOfHoveredItem
                        ? "#3367CD"
                        : "#BDBDBD"
                    }
                    size={item.iconSize}
                    className="navLinkIcon"
                    style={{ marginRight: 5 }}
                  />
                  <p
                    className="pt-2"
                    style={{ fontSize: 15, color: "rgb(40, 40, 42)" }}
                  >
                    {item.name}
                  </p>
                </Nav.Link>
                {item.subMenu && item.subMenu}
              </>
            )}
          </div>
        );
      })}
    </>
  );

  return (
    <div className="sidebar pb-0" data-color={"white"}>
      <div className="logo ps-4" style={{ paddingBottom: "1.2rem" }}>
        <a href="https://www.nqt.fr/" className="simple-text">
          <img src={logo} alt="react-logo" />
        </a>
      </div>

      <div
        className="sidebar-wrapper pb-0"
        style={{ borderRight: "1px solid #F0F0F0" }}
      >
        <div
          className="flex-column"
          style={{ paddingTop: "2.5rem", maxHeight: "85%", overflowY: "auto" }}
        >
          {NQTMenu}
        </div>

        <div className="px-3 end-position text-center " style={{ zIndex: -1 }}>
          <hr className="hr-text"></hr>
          <p
            className="label-grey-nqt pt-2 pointer"
            onClick={() => window.open("https://app.nqt.fr/signin", "_self")}
          >
            QUITTER ET REVENIR PLUS TARD
          </p>
        </div>
      </div>
    </div>
  );
};

export default LayoutSideBar;
