import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";

import userReducer from "./slices/userSlice";
import authReducer from "./slices/authSlice";
import trainingReducer from "./slices/trainingSlice";
import editorReducer from "./slices/editorSlice";
import gamificationReducer from "./slices/gamificationSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    auth: authReducer,
    training: trainingReducer,
    editor: editorReducer,
    gamification: gamificationReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
