//@ts-ignore
import {
  useTable,
  useResizeColumns,
  useFlexLayout,
  useRowSelect,
  useSortBy,
} from "react-table";
import tw from "twin.macro";
import { Button, Col, Row } from "reactstrap";
import { motion, AnimatePresence } from "framer-motion";

import { ReactComponent as DescIcon } from "../../../../assets/img/icons/Desc.svg";
import { ReactComponent as AscIcon } from "../../../../assets/img/icons/Asc.svg";
import { ReactComponent as ChevronBottomGreyIcon } from "../../../../assets/img/icons/chevronBottomGrey.svg";
import "../../../../styles/catalog.scss";

import { useMemo } from "react";
import { previewEditor } from "../../../../redux/slices/trainingSlice";
import { useAppDispatch } from "../../../../redux/hooks";
import { useHistory } from "react-router";

const Table = tw.table`
  `;

const TableHead = tw.thead`
    p-4
  `;

const TableRow = tw.tr`
  p-2
  `;

const TableHeader = tw.th`
  `;

const TableBody = tw.tbody`
  `;

const TableData = tw.td`
  p-5
  `;

const ValidatedTrainingTable = (props: any) => {
  const {
    data,
    columns,
    matches,
    route,
    assignCount,
    submitAssign,
    cancelAssign,
    disabled,
  } = props;

  const tableInstance = useTable(
    { columns, data },
    useResizeColumns,
    useFlexLayout,
    useSortBy,
    useRowSelect
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  const spring = useMemo(
    () => ({
      type: "spring",
      damping: 50,
      stiffness: 100,
    }),
    []
  );

  const dispatch = useAppDispatch();
  const history = useHistory();

  return (
    <Table style={{ borderRadius: 10 }} {...getTableProps()}>
      <TableHead>
        {headerGroups.map((headerGroup: any, index: number) => (
          <TableRow
            className=" pb-0 mt-1"
            {...headerGroup.getHeaderGroupProps()}
          >
            {headerGroup.headers.map((column: any, key: number) => {
              return (
                <TableHeader
                  className={
                    column.isSorted
                      ? "label-black-nqt-normal"
                      : "label-grey-nqt-normal"
                  }
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  <div className={"d-flex flex-row"}>
                    <p
                      className={
                        column.render("Header") === "Publié le"
                          ? "fw-bold"
                          : "fw-normal"
                      }
                    >
                      {column.render("Header")}
                    </p>
                    {key !== 0 &&
                      (column.isSorted ? (
                        column.isSortedDesc ? (
                          <div className="filter-icons-div">
                            <DescIcon fill="28282A" />
                          </div>
                        ) : (
                          <div className="filter-icons-div">
                            <AscIcon
                              fill="28282A"
                              style={{ marginBottom: "10px" }}
                            />
                          </div>
                        )
                      ) : (
                        <div className="filter-icons-div">
                          <AscIcon fill="grey" className="filter-icons" />
                          <DescIcon fill="grey" />
                        </div>
                      ))}
                  </div>
                </TableHeader>
              );
            })}
          </TableRow>
        ))}
      </TableHead>
      {(route === "Ajouter un relecteur" ||
        route === "Assigner à une formation") && (
          <Row>
            <Col ms="12" className="d-flex flex-row">
              <Col ms="4">
                <p className="label-black fw-bold mt-1">
                  Vous avez sélectionné {assignCount} formations
                  <ChevronBottomGreyIcon className="ms-3" />
                </p>
              </Col>
              <Col ms="8">
                <div
                  className={matches.large ? "float-end" : "float-center mb-5"}
                >
                  <Button
                    className="btn-round-sm-secondary me-2 p-2 px-3"
                    uppercase={false}
                    color="secondary"
                    type="submit"
                    disabled={disabled}
                    onClick={() => cancelAssign()}
                  >
                    Tout déselectionner
                  </Button>

                  <Button
                    className="btn-round-sm validate p-2 px-3"
                    color="primary"
                    onClick={() => submitAssign()}
                    disabled={disabled}
                    type="submit"
                  >
                    Valider les assignations
                  </Button>
                </div>
              </Col>
            </Col>
          </Row>
        )}

      <TableBody {...getTableBodyProps()}>
        <AnimatePresence>
          {rows.map((row: any) => {
            prepareRow(row);
            return (
              <motion.tr
                className="catalog-table-body table-row mb-2 align-items-center pointer"
                {...row.getRowProps({
                  layoutTransition: spring,
                  exit: { opacity: 0, maxHeight: 0 },
                })}
              >
                {row.cells.map((cell: any, idx: any) => {
                  return cell.column.Header.toLowerCase() ===
                    "nom de formation" ? (
                    <TableData
                      className="p-0 label-black-trainings"
                      {...cell.getCellProps()}
                      onClick={() =>
                        dispatch(
                          previewEditor({
                            history,
                            id: row.original.id
                          }))}
                    >
                      {cell.render("Cell")}
                    </TableData>
                  ) : (
                    <TableData
                      className="p-0 label-grey-normal"
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </TableData>
                  );
                })}
              </motion.tr>
            );
          })}
        </AnimatePresence>
      </TableBody>
    </Table>
  );
};

export default ValidatedTrainingTable;
