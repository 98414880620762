import { useMedia } from "react-media";
import { Button, Col, Row, Spinner } from "reactstrap";

import Box from "../../../../components/widget/Box";
import { GLOBAL_MEDIA_QUERIES } from "../../../../utils/constant";
import { ReactComponent as AsyncIcon } from "../../../../assets/img/icons/asyncIcon.svg";
import { ReactComponent as DigIcon } from "../../../../assets/img/icons/Digital.svg";
import { ReactComponent as PresIcon } from "../../../../assets/img/icons/presIcon.svg";
import { ReactComponent as DoneIcon } from "../../../../assets/img/icons/simpleDone.svg";
import { retrieveTrainingLaunchDetail } from "../../../../redux/slices/trainingSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { useHistory } from "react-router";
import { selectUser } from "../../../../redux/slices/userSlice";

const DetailsHeader = (props: any) => {
  const { training, isSaved, isSubscribed, subscribeLoading, sendSubscribeRequest } = props;
  const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const history= useHistory();
  const subjectList = training.subject.map((select: any) => (
    <Box content={select.label}></Box>
  ));

  return (
    <Row>
      <Col md="7">
        {matches.large ? (
          <div className="expertiseList mt-2">{subjectList.flat()}</div>
        ) : (
          <div className="expertiseList justify-content-center mt-2">
            <Box
              content={`${subjectList.flat().length} sujets renseignés`}
            ></Box>
          </div>
        )}
        {training.typology === "async" && (
          <div className="d-flex fex-row">
            <AsyncIcon className="me-3" />
            <p className="label-black-nqt-sm fw-bolder">
              Formation en autonomie
            </p>
          </div>
        )}
        {training.typology === "sync" &&
          training.eventDetails &&
          training.eventDetails.type === "pres" && (
            <div className="d-flex fex-row">
              <PresIcon className="me-3" />
              <p className="label-black-nqt-sm fw-bolder">
                Formation présentielle
              </p>
            </div>
          )}
        {training.typology === "sync" &&
          training.eventDetails &&
          (training.eventDetails.type === "dig" ||
            training.eventDetails.type === "dist") && (
            <div className="d-flex fex-row">
              <DigIcon className="me-3" />
              <p className="label-black-nqt-sm fw-bolder">
                Formation distancielle
              </p>
            </div>
          )}

        <p className="label-primary-nqt-lg">{training.title}</p>
        <div className="d-flex flex-row">
          {!isSubscribed && (training.creator.id !== user.id)? (
            <Button
              className={"btn-round-sm validate mt-3"}
              color="primary"
              onClick={() => sendSubscribeRequest(training.id)}
            >
              {subscribeLoading === "pending" && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-1"
                />
              )}
              S’inscrire à la formation
            </Button>
          ) : null}
          {training.typology === "async" &&
             isSaved && (
              <>
                <Button
                  className={"btn-round-sm-success validate mt-3"}
                  color="primary"
                >
                  <DoneIcon className="me-2" />
                  Formation enregistrée
                </Button>
                {training.creator.id !== user.id &&<Button
                  className="btn-round-sm validate mt-3"
                  color="primary"
                  onClick={() => {
                    dispatch(retrieveTrainingLaunchDetail({ id: training.id, history }));
                  }}
                >
                  Lancer la formation
                </Button>}
              </>
            )}
        </div>
      </Col>
    </Row>
  );
};
export default DetailsHeader;
