import { Button, Col, Row } from "reactstrap";
import { MdDownload, MdInfo } from "react-icons/md";
import { TEMPLATE_URL } from "../../../../../src/utils/base";
//@ts-ignore
import Modal from "react-modal";
import { FaFilePowerpoint, FaFileWord } from "react-icons/fa";
import { AiFillFileExcel } from "react-icons/ai";
const TemplateModal = (props: any) => {
  //Init props
  const { modalIsOpen, closeModal } = props;

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      className="Modal"
      overlayClassName="Overlay"
      contentLabel="Example Modal"
    >
      <Row>
        <Col md="12" className="mt-5">
          <p className="label-black-nqt-md">Ressources de modèles</p>
        </Col>
      </Row>
      <Row>
        <Col md="12" className="mt-5">
          <div className="d-flex flex-row carded">
            <MdInfo size={25} color="#3367cd" className="w-auto me-2" />
            <div className="d-flex flex-column w-75">
              <p className="label-black fw-bold">Comment cela fonctionne ?</p>
              <p className="label-grey-normal">
                Vous pouvez télécharger le template qui vous intéresse pour votre formation.
                Vous pouvez le remplir avec votre contenu puis le télécharger dans votre formation.
              </p>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="center-container">
        <Col md="12" className="mt-5">
          <div className="d-flex flex-row">
            <FaFilePowerpoint size={40} className="upload-icon" />
            <div className="d-flex flex-column w-100">
              <p className="mb-0 ms-3 label-black fw-bold">
                Modèle PowerPoint
              </p>
              <p className="mt-0 ms-3 label-grey-normal">https://www.nqt.fr</p>
            </div>
            <a href= {"/TemplatePPT.pptx" } download>
              <MdDownload
                className="align-middle mt-2 pointer"
                color="#3367cd"
                size={17}
              />
            </a>
          </div>
          <div className="d-flex flex-row">
            <FaFileWord size={40} className="upload-icon" />
            <div className="d-flex flex-column w-100">
              <p className="mb-0 ms-3 label-black fw-bold">Modèle Word</p>
              <p className="mt-0 ms-3 label-grey-normal">https://www.nqt.fr</p>
            </div>
            <a href = {"/TemplateWord.docx"} download>
              <MdDownload
                className="align-middle mt-2 pointer"
                color="#3367cd"
                size={17}
              />
            </a>
          </div>
          <div className="d-flex flex-row">
            <AiFillFileExcel size={40} className="upload-icon" />
            <div className="d-flex flex-column w-100">
              <p className="mb-0 ms-3 label-black fw-bold">Modèle Excel</p>
              <p className="mt-0 ms-3 label-grey-normal">https://www.nqt.fr</p>
            </div>
            <a href={"/TemplateExcel.xlsx" } download>
              <MdDownload
                className="align-middle mt-2 pointer"
                color="#3367cd"
                size={17}
              />
            </a>
          </div>
          <div className="d-flex flex-row mt-5 center-container">
            <Button
              className="btn-round-sm-secondary mt-5 fw-bold px-5  "
              uppercase={false}
              onClick={closeModal}
              color="secondary"
              type="submit"
            >
              Fermer
            </Button>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};
export default TemplateModal;
