import { IoMdTrash } from "react-icons/io";
import { MdDone } from "react-icons/md";
import { TiEye } from "react-icons/ti";

const FilesPreview = (props: any) => {
    const {setSelectedFile,selectedFile} = props;
    return (
        selectedFile.map((file:any, idx:number) => {
            return (
              <div key={idx} className="d-flex flex-row download mt-4">
                <MdDone size={40} className="done-icon" />
                <div className="d-flex flex-column">
                  <p className="mb-0 ms-3 fw-bold">{file.fileName}</p>
                  <p className="mt-0 ms-3 label-green">
                    Importé avec succès
                  </p>
                </div>
                <div className="d-flex flex-row justify-content-center">
                  <IoMdTrash
                    size={18}
                    color="#3367cd"
                    className="right pointer ms-5 mt-2"
                    onClick={() => {
                      setSelectedFile(null);
                    }}
                  />
                  <TiEye
                    size={16}
                    color="#3367cd"
                    className="right pointer ms-1 mt-2"
                    onClick={() => window.open(file.preview, "_blanc")}
                  />
                </div>
              </div>
            );
          })
    )
};

export default FilesPreview;