import React, { useState, useMemo } from "react";
import Menu from "@mui/material/Menu";
import { Nav } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { IoMdBriefcase } from "react-icons/io";
import { BiNews } from "react-icons/bi";
import { MdBusiness } from "react-icons/md";
import { AiFillHome, AiFillPlusSquare, AiFillCalendar } from "react-icons/ai";

import {
  homeRoutes,
  EditeurRoutes,
  collaborateurRoutes,
} from "../config/routes";
import "../styles/home.scss";

const SubMenuNavLink = ({ title, route }: any) => {
  const history = useHistory();
  return (
    <Nav.Link
      key={route.name}
      eventKey={route.name}
      onClick={() => history.push(route.path)}
      className="navLink d-flex flex-row"
      style={{ paddingLeft: 7, paddingTop: 5, paddingBottom: 2 }}
    >
      <AiFillPlusSquare
        size={28}
        className="navLinkIcon"
        style={{ marginRight: 5 }}
      />
      <p
        className={
          title === route.name ||
          (title === "Club NQT" && route.name === "Réseau social")
            ? "active"
            : ""
        }
        style={{ fontSize: 12, marginBottom: 0, marginTop: 6 }}
      >
        {route.name}
      </p>
    </Nav.Link>
  );
};

const EditorSubMenuNavLink = ({ title, route }: any) => {
  const history = useHistory();
  return (
    <Nav.Link
      key={route.name}
      eventKey={route.name}
      onClick={() => history.push("/editor", { route: route.name })}
      className="navLink d-flex flex-row"
      style={{ paddingLeft: 7, paddingTop: 5, paddingBottom: 2 }}
    >
      <AiFillPlusSquare
        size={28}
        className="navLinkIcon"
        style={{ marginRight: 5 }}
      />
      <p
        className={title === route.name ? "active" : ""}
        style={{ fontSize: 12, marginBottom: 0, marginTop: 6 }}
      >
        {route.name}
      </p>
    </Nav.Link>
  );
};

const LayoutMobileMenu = (props: any) => {
  const { title, user, isEditor, isNqtCollaborator, isAlumuni } = props;
  const companyId = user.expertisesMetier.entrepriseId;
  const [menuItems, setMenuItems] = useState<any>(null);

  const baseUrl = useMemo(
    () =>
      window.location.href.includes("clubmentors")
        ? "https://"
        : "https://preprod.",
    []
  );
  const [hover, setHover] = useState(false);
  const [indexOfHoveredItem, setIndexOfHoveredItem] = useState(-1);

  const mentorRoutes = homeRoutes.map((route: any) => (
    <SubMenuNavLink title={title} route={route} />
  ));

  const alumniRoutes = homeRoutes
    .filter((route: any) => route.name !== "Créer une formation")
    .map((route: any) => <SubMenuNavLink title={title} route={route} />);

  const editorNqtClubRoutes = homeRoutes.map((route: any) => (
    <EditorSubMenuNavLink title={title} route={route} />
  ));

  const editorRoutes = EditeurRoutes.map((route: any) => (
    <EditorSubMenuNavLink title={title} route={route} />
  ));

  const collaboratorRoutes = collaborateurRoutes.map((route: any) => (
    <SubMenuNavLink title={title} route={route} />
  ));

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const bottomMenu = [
    {
      id: 1,
      name: "Accueil",
      toBeShown: true,
      link: isEditor || isNqtCollaborator ? "api.nqt.fr/" : "app.nqt.fr/",
      icon: AiFillHome,
      iconSize: 35,
    },
    {
      id: 2,
      name: "Entreprise",
      toBeShown: !isEditor && !isNqtCollaborator,
      link: `app.nqt.fr/company/${companyId}`,
      icon: MdBusiness,
      iconSize: 35,
    },
    {
      id: 3,
      name: "Évènements",
      toBeShown: !isEditor && !isNqtCollaborator,
      link: "app.nqt.fr/events",
      icon: AiFillCalendar,
      iconSize: 35,
    },
    {
      id: 4,
      name: "Offres",
      toBeShown: !isEditor && !isNqtCollaborator,
      link: "app.nqt.fr/jobs",
      icon: IoMdBriefcase,
      iconSize: 35,
    },
    {
      id: 5,
      name: "Club NQT",
      toBeShown: true,
      link: "/",
      icon: BiNews,
      subMenu: isEditor
        ? editorNqtClubRoutes
        : isAlumuni
        ? alumniRoutes
        : isNqtCollaborator
        ? collaboratorRoutes
        : mentorRoutes,
      iconSize: 35,
    },
    {
      id: 6,
      name: "Editeur",
      toBeShown: isEditor,
      link: "/",
      icon: BiNews,
      subMenu: editorRoutes,
      iconSize: 35,
    },
  ];

  return (
    <div className="d-flex flex-row fixed-bottom justify-content-around bg-white">
      <>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mb: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: 0,
                mr: 0,
              },
              // "&:before": {
              //   content: '""',
              //   display: "block",
              //   position: "absolute",
              //   bottom: 0,
              //   right: 30,
              //   width: 15,
              //   height: 15,
              //   bgcolor: "background.paper",
              //   transform: "translateY(50%) rotate(45deg)",
              //   zIndex: 0,
              // },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "bottom" }}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
          disableScrollLock={true}
        >
          {menuItems}
        </Menu>

        {bottomMenu.map((item) => {
          const IconTag = item.icon;
          return (
            <>
              {item.toBeShown && (
                <div key={item.id}>
                  <Nav.Link
                    onClick={(event) => {
                      if (item.name === "Club NQT" || item.name === "Editeur") {
                        event.preventDefault();
                        setMenuItems(item.subMenu);
                        handleClick(event);
                      } else {
                        window.onbeforeunload = null;
                        window.location.href = `${baseUrl}${item.link}`;
                      }
                    }}
                    className="d-flex flex-column"
                    onMouseEnter={() => {
                      setIndexOfHoveredItem(item.id);
                      setHover(true);
                    }}
                    onMouseLeave={() => setHover(false)}
                    style={{
                      ...(hover && item.id === indexOfHoveredItem
                        ? { background: "#f9f9f9" }
                        : null),
                    }}
                  >
                    <IconTag
                      color={
                        hover && item.id === indexOfHoveredItem
                          ? "#3367CD"
                          : "#BDBDBD"
                      }
                      size={item.iconSize}
                      className="navLinkIcon"
                      style={{ margin: "auto" }}
                    />
                    <p
                      className="mb-0 pb-0"
                      style={{ fontSize: 12, color: "rgb(40, 40, 42)" }}
                    >
                      {item.name}
                    </p>
                  </Nav.Link>
                </div>
              )}
            </>
          );
        })}
      </>
    </div>
  );
};

export default LayoutMobileMenu;
