import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useAppSelector } from "../../../../../redux/hooks";
import Rate from "../../../../../components/widget/Rate";
// import Box from "../../../../../components/widget/Box";
import ReactTooltip from "react-tooltip";
import { selectUser } from "../../../../../redux/slices/userSlice";

const ExpertiseMobile = () => {
  const profile = useAppSelector(selectUser);
  // const expertiseList = profile.expertisesMetier.expertises?.map(
  //   (expertise) => {
  //     return <Box key={expertise.id} content={expertise.libelle}></Box>;
  //   }
  // );
  return (
    <Row className="center-container mb-4">
      <Col md="10">
        <Card body color="light" outline className="card p-0">
          <CardBody>
            <Row className="center-container mb-4">
              <Col sm="2" xs="3">
                <CircularProgressbarWithChildren
                  value={100}
                  background={true}
                  styles={{
                    // Customize the root svg element
                    root: {},
                    // Customize the path, i.e. the "completed progress"
                    path: {
                      // Path color
                      stroke: `rgba(0, 0, 255)`,
                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: "butt",
                      // Customize transition animation
                      transition: "stroke-dashoffset 0.5s ease 0s",
                      strokeWidth: "3px",
                    },
                    // Customize the circle behind the path, i.e. the "total progress"
                    trail: {
                      // Trail color
                      stroke: "rgb(255,255, 255)",
                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: "butt",
                      strokeWidth: "10px",
                    },
                    // Customize background - only used when the `background` prop is true
                    background: {
                      fill: "#3367CD",
                    },
                  }}
                >
                  <svg
                    width="15"
                    height="17"
                    viewBox="0 0 15 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.96293 8.51844V11.4814C1.96293 12.0962 2.45922 12.5925 3.07404 12.5925C3.68885 12.5925 4.18515 12.0962 4.18515 11.4814V8.51844C4.18515 7.90362 3.68885 7.40732 3.07404 7.40732C2.45922 7.40732 1.96293 7.90362 1.96293 8.51844ZM6.40737 8.51844V11.4814C6.40737 12.0962 6.90367 12.5925 7.51848 12.5925C8.1333 12.5925 8.6296 12.0962 8.6296 11.4814V8.51844C8.6296 7.90362 8.1333 7.40732 7.51848 7.40732C6.90367 7.40732 6.40737 7.90362 6.40737 8.51844ZM1.59256 16.2962H13.4444C14.0592 16.2962 14.5555 15.7999 14.5555 15.1851C14.5555 14.5703 14.0592 14.074 13.4444 14.074H1.59256C0.977742 14.074 0.481445 14.5703 0.481445 15.1851C0.481445 15.7999 0.977742 16.2962 1.59256 16.2962ZM10.8518 8.51844V11.4814C10.8518 12.0962 11.3481 12.5925 11.9629 12.5925C12.5777 12.5925 13.074 12.0962 13.074 11.4814V8.51844C13.074 7.90362 12.5777 7.40732 11.9629 7.40732C11.3481 7.40732 10.8518 7.90362 10.8518 8.51844ZM6.8296 1.10362L0.977742 4.1851C0.674038 4.34066 0.481445 4.65918 0.481445 4.99992C0.481445 5.51103 0.89626 5.92584 1.40737 5.92584H13.637C14.1407 5.92584 14.5555 5.51103 14.5555 4.99992C14.5555 4.65918 14.3629 4.34066 14.0592 4.1851L8.20737 1.10362C7.77774 0.873989 7.25922 0.873989 6.8296 1.10362Z"
                      fill="white"
                    />
                  </svg>
                </CircularProgressbarWithChildren>
              </Col>
              <Col xs="9" sm="9">
                <CardTitle className="d-flex">
                  <Col xs="9" sm="9">
                    <div className="label-black-md">Expertises métier</div>
                  </Col>
                  <Col xs="2" sm="2">
                    <div data-tip="Expertises métier">
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.99996 17.3333C4.39996 17.3333 0.666626 13.5999 0.666626 8.99992C0.666626 4.39992 4.39996 0.666584 8.99996 0.666584C13.6 0.666584 17.3333 4.39992 17.3333 8.99992C17.3333 13.5999 13.6 17.3333 8.99996 17.3333ZM9.83329 4.83325H8.16663V6.49992H9.83329V4.83325ZM9.83329 8.16658H8.16663V13.1666H9.83329V8.16658Z"
                          fill="#BDBDBD"
                        />
                      </svg>
                    </div>
                    <ReactTooltip />
                  </Col>
                </CardTitle>
                <Rate percentage={100}></Rate>
              </Col>
            </Row>
            <Row>
              <hr className="line" />
            </Row>
            <Row>
              <Col md="3" sm="6" xs="10">
                <div className="expertiseTitle">Entreprise</div>
                <div className="expertiseInfo">
                  {profile.expertisesMetier.entreprise}
                </div>
              </Col>
              <Col md="3" sm="6" xs="10">
                <div className="expertiseTitle">Secteur d’activité</div>
                <div className="expertiseInfo">
                  {profile.expertisesMetier.secteur}
                </div>
              </Col>
              <Col md="3" sm="6" xs="10">
                <div className="expertiseTitle">Sous secteur d’activité</div>
                <div className="expertiseInfo">
                  {profile.expertisesMetier.subdomain}
                </div>
              </Col>
              <Col md="3" sm="6" xs="10">
                <div className="expertiseTitle">Métier</div>
                {profile.expertisesMetier?.metier?.map((metier) => {
                  return (
                    <li className="expertiseInfo" key={metier.id}>
                      {metier.libelle}
                    </li>
                  );
                })}
              </Col>
            </Row>
            {/* <Row>
              <div className="expertiseTitle">Expertises</div>
              <div className="expertiseList">{expertiseList}</div>
            </Row> */}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default ExpertiseMobile;
