import http from "../config/httpCommon";

const FiltersService = {
  getCompany(nom: string) {
    const params = {nom};
    return new Promise(async (resolve, reject) => {
      (await http())
        .get("/company/find", { params })
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },

  getCreator(nom: string) {
    const params = {nom};
    return new Promise(async (resolve, reject) => {
      (await http())
        .get("/users/find", { params })
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error.message));
    });
  },
};


export default FiltersService;
