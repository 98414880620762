import { Card, Col, Progress, Row } from "reactstrap";
import { IoMdLink } from "react-icons/io";
import { IoMdClose } from "react-icons/io";
import { Input } from "../../../../components/forms";
import { useState } from "react";
import ReactPlayer from 'react-player';

const VideoSection = (props: any) => {
  //Init props
  const {
    sectionKey,
    setFieldValue,
    sectionContentKey,
    values,
    addOrRemoveSectionContent,
  } = props;
  const [state, setLink] = useState(null);

  const handleChange = (event: any) => {
    setLink(event.target.value);
    setFieldValue(
      `sections[${sectionKey}].sectionContent[${sectionContentKey}].link`,
      event.target.value
    );
  };

  return (
    <Card body color="light" outline className="cardIntro mt-5 p-4">
      <div className="d-flex flex-row align-items-center">
        <div className="ms-2 iconOutlined">
          <IoMdLink size={25} color="#8D8D8D" />
        </div>
        <div className="d-flex flex-column  w-100 mt-3">
          <p className={"mb-0 ms-3 label-primary-nqt-md"}>Vidéo</p>
          <p className="pt-1 ms-3 label-grey-normal">
            Ajouter votre lien vers une vidéo içi.
          </p>
        </div>
        <>
          <Progress multi style={{ height: 4, width: 24 }} className="me-2">
            <Progress
              bar
              value="50"
              className={
                values.sections[sectionKey].sectionContent[sectionContentKey].link
                  ? "bg-primary"
                  : "bg-pink"
              }
            />
          </Progress>
          <IoMdClose
            size={15}
            color="#3367cd"
            onClick={() =>
              addOrRemoveSectionContent(
                "remove",
                "text",
                sectionContentKey,
                sectionKey
              )
            }
            className="ms-2 float-end pointer closeIconPrimary"
          />
        </>
      </div>

      <Row>
        <hr className="hr-text-grey p-0 mt-3"></hr>
      </Row>
      <Row>
      </Row>

      <Row>
        <Col md="6">
          <Input
            name="link"
            type="text"
            onChange={handleChange}
            value={state ?? values.sections[sectionKey].sectionContent[sectionContentKey].link}
          />

        </Col>
      </Row>
      <Row className="mt-3">

        {(state || values.sections[sectionKey].sectionContent[sectionContentKey].link) && (
          <ReactPlayer
            url={state || values.sections[sectionKey].sectionContent[sectionContentKey].link}
          />
        )}
      </Row>
    </Card>
  );
};
export default VideoSection;