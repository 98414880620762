import {useEffect, useState} from "react";
import Nav from "react-bootstrap/Nav";
import { MdOutlineDone } from "react-icons/md";
import logo from "../../../../assets/img/logo.png";
import ModalConfirmation from "../../../../components/forms/ModalConfirmation";

const Sidebar = (props: any) => {
  //Init props
  const { navStep, handlePagination } = props;
  const [isConfirmationModalVisible, setConfirmationModalVisible ]= useState(false)
  useEffect(() => {
    window.onbeforeunload = function() {
      return "Leaving this page will reset the wizard";
    };
  }, [])
  return (
    <div
      className="sidebar"
      data-color={props.bgColor}
      data-active-color={props.activeColor}
    >
      <div className="logo ps-4">
        <a href="https://www.nqt.fr/" className="simple-text">
          <img src={logo} alt="react-logo" />
        </a>
      </div>

      <div className="sidebar-wrapper">
        <h5 className="ps-4 label-black-lg" style={{ marginTop: 32 }}>
          Inscription <br /> au Club NQT
        </h5>

        <Nav activeKey={navStep} className="flex-column pt-3">
          <Nav.Link
            eventKey="1"
            onClick={() => handlePagination(1)}
            className={
              navStep === 1
                ? "nav-link-focus d-flex flex-row"
                : "nav-link d-flex flex-row"
            }
          >
            {navStep > 1 ? (
              <MdOutlineDone size={20} className="nav-link-icon" />
            ) : (
              <p className="nav-link-focus ms-2 me-3">1 </p>
            )}
            Premiers pas dans le Club
          </Nav.Link>
          <Nav.Link
            eventKey="2"
            onClick={() => handlePagination(2)}
            className={
              navStep < 2
                ? "nav-link-disabled mt-10 d-flex flex-row"
                : navStep > 2
                ? "nav-link mt-10 d-flex flex-row"
                : "nav-link-focus mt-10 d-flex flex-row"
            }
          >
            {navStep > 2 ? (
              <MdOutlineDone size={20} className="nav-link-icon" />
            ) : (
              <p
                className={
                  navStep < 2
                    ? "nav-link-disabled ms-2 me-3"
                    : "nav-link-focus ms-2 me-3"
                }
              >
                2
              </p>
            )}
            Compléter mon profil
          </Nav.Link>
          <Nav.Link
            eventKey="3"
            onClick={() => handlePagination(3)}
            className={
              navStep < 3
                ? "nav-link-disabled mt-10 d-flex flex-row"
                : "nav-link-focus mt-10 d-flex flex-row"
            }
          >
            <p
              className={
                navStep < 3
                  ? "nav-link-disabled ms-2 me-3"
                  : "nav-link-focus ms-2 me-3"
              }
            >
              3
            </p>
            Charte Club NQT
          </Nav.Link>
        </Nav>
        <div className="px-3 end-position text-center ">
          <hr className="hr-text"></hr>
          <p
            className="label-grey-nqt pt-2 pointer"
              onClick={()=> setConfirmationModalVisible(true)}
          >
            QUITTER ET REVENIR PLUS TARD
          </p>
        </div>
      </div>
      <ModalConfirmation
          confirm={() => window.open("https://app.nqt.fr/signin", "_self")}
          cancel={()=>setConfirmationModalVisible(false)}
          title={"Voulez vous vraiment quitter et revenir plus tard ?"}
          subTitle={"En quittant la page vous allez etre redirigé vers la page de login."}
          isVisible={isConfirmationModalVisible}/>
    </div>
  );
};
export default Sidebar;
