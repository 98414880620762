//@ts-ignore
import {
  useTable,
  useResizeColumns,
  useFlexLayout,
  useRowSelect,
  useSortBy,
} from "react-table";
import tw from "twin.macro";
import { Spinner } from "reactstrap";

import { ReactComponent as DescIcon } from "../../../../assets/img/icons/Desc.svg";
import { ReactComponent as AscIcon } from "../../../../assets/img/icons/Asc.svg";
import '../../../../styles/catalog.scss'
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  retrieveTrainingDetail,
  retrieveTrainingDetailSuggestions,
  selectTrainingLoading,
} from "../../../../redux/slices/trainingSlice";
import { useHistory } from "react-router";

const Table = tw.table`
`;

const TableHead = tw.thead`
  p-4
`;

const TableRow = tw.tr`
p-2
`;

const TableHeader = tw.th`
`;

const TableBody = tw.tbody`
`;

const TableData = tw.td`
p-5
`;

const TrainingTable = (props: any) => {
  const { data , columns  } = props ;
  const loading = useAppSelector(selectTrainingLoading);
  const tableInstance = useTable(
    { columns, data },
    useResizeColumns,
    useFlexLayout,
    useSortBy,
    useRowSelect
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;
  const history = useHistory();
  const dispatch = useAppDispatch();
  const navigateToDetails = (id: number) => {
    dispatch(retrieveTrainingDetail({ id }));
    dispatch(retrieveTrainingDetailSuggestions({ id }));
    history.push('/trainingDetail');
  };
  return (
    <Table style={{ borderRadius: 10 }} {...getTableProps()}>
      <TableHead>
        {headerGroups.map((headerGroup: any, index: number) => (
          <TableRow
            className=" pb-0 mt-1"
            {...headerGroup.getHeaderGroupProps()}
          >
            {headerGroup.headers.map((column: any, key: number) => {
              return (
                <TableHeader
                  className={
                    column.isSorted
                      ? "label-black-nqt-normal"
                      : "label-grey-nqt-normal"
                  }
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  <div className={"d-flex flex-row"}>
                    <p
                      className={
                        column.render("Header") === "Publié le"
                          ? "fw-bold"
                          : "fw-normal"
                      }
                    >
                      {column.render("Header")}
                    </p>
                    {key !== 0 &&
                      (column.isSorted ? (
                        column.isSortedDesc ? (
                          <div className="filter-icons-div">
                            <DescIcon fill="28282A" />
                          </div>
                        ) : (
                          <div className="filter-icons-div">
                            <AscIcon
                              fill="28282A"
                              style={{ marginBottom: "10px" }}
                            />
                          </div>
                        )
                      ) : (
                        <div className="filter-icons-div">
                          <AscIcon fill="grey" className="filter-icons" />
                          <DescIcon fill="grey" />
                        </div>
                      ))}
                  </div>
                </TableHeader>
              );
            })}
          </TableRow>
        ))}
      </TableHead>
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: 600 }}
        >
          <Spinner animation="border" style={{ color: "#3367cd" }} />
        </div>
      ) : (
        <TableBody {...getTableBodyProps()}>
          {rows.map((row: any) => {
            prepareRow(row);
            return (
              <TableRow
                className="catalog-table-body table-row mb-2 align-items-center pointer"
                {...row.getRowProps()}
                onClick={() => navigateToDetails(row.original.id)}
              >
                {row.cells.map((cell: any, idx: any) => {
                  return cell.column.Header.toLowerCase() ===
                    "nom de formation" ? (
                    <TableData
                      className="p-0 label-black-trainings"
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </TableData>
                  ) : (
                    <TableData
                      className="p-0 label-grey-normal opacity-75"
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </TableData>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      )}
    </Table>
  );
};

export default TrainingTable;
