import { useHistory } from "react-router";
import { Row, Col } from "reactstrap";
import { useAppDispatch } from "../../../redux/hooks";
import {previewNotation, previewRelecture, trainingActions} from "../../../redux/slices/trainingSlice";
const TrainingElement = (props: any) => {
  const { name = "", subject = "", state = "", date, isAwaitingRating, isAwaitingReview, trainingId } = props;
  const dispatch = useAppDispatch();

  const history = useHistory();
  let mappedState = "Brouillon";
  if (state === "pending") {
    mappedState = "En attente de validation";
  }
  if (state === "draft") {
    mappedState = "Brouillon";
  }
  if (state === "published") {
    mappedState = "Publiée";
  }
  if (state === "ongoing_review") {
    mappedState = "En attente de relecture";
  }
  if (state === "rejected") {
    mappedState = "À corriger";
  }
  return (
      <Row key={trainingId} onClick={() => {
        if (isAwaitingReview) {
          dispatch(
              previewRelecture({
                history,
                id: trainingId
              })
          );
        }
        if (isAwaitingRating) {
          dispatch(
              previewNotation({
                history,
                id: trainingId
              })
          );
        }
      }}>
        <Col style={{ minWidth: 50, maxWidth: 80 }}>
          {props.children}
        </Col>
        <Col>
          <Row className="d-block">
            <Col>
              <div className="label-black-md"> {name} </div>
            </Col>
            <Col>
              <div className="label-grey-trainings-sm">
                {" "}
                {state !== "" ? mappedState : date ? "Envoyée le " + date : subject}{" "}
              </div>
            </Col>
          </Row>
        </Col>
        <Col style={{ minWidth: 50, maxWidth: 80 }} className="pt-3">
          <div className="pull-right pointer">
            {isAwaitingRating ?
                <span className="pe-2 label-primary-nqt-mm pointer">Je note</span>
                : isAwaitingReview ?
                    <span className="pe-2 label-primary-nqt-mm pointer"
                    >Je relis</span>
                    : null
            }
            <svg
                width="7"
                height="12"
                viewBox="0 0 7 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
              <path
                  d="M6.78016 5.2801C6.92086 5.42061 6.99999 5.61125 7.00016 5.8101V6.1901C6.99786 6.38851 6.91905 6.57838 6.78016 6.7201L1.64016 11.8501C1.54628 11.9448 1.41848 11.998 1.28516 11.998C1.15185 11.998 1.02405 11.9448 0.930165 11.8501L0.220165 11.1401C0.126101 11.0479 0.0730934 10.9218 0.0730934 10.7901C0.0730934 10.6584 0.126101 10.5323 0.220165 10.4401L4.67016 6.0001L0.220165 1.5601C0.125508 1.46621 0.0722656 1.33842 0.0722656 1.2051C0.0722656 1.07178 0.125508 0.94398 0.220165 0.850096L0.930165 0.150096C1.02405 0.0554401 1.15185 0.00219727 1.28516 0.00219727C1.41848 0.00219727 1.54628 0.0554401 1.64016 0.150096L6.78016 5.2801Z"
                  fill="#3367CD"
              />
            </svg>
          </div>
        </Col>
      </Row>
  );
};

export default TrainingElement;
