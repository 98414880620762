import TrainingsProgressBar from "./TrainingsProgressBar";

const OnGoingTrainingsProgressBar = (props: any) => {
  const { percentage = props.percentage } = { ...props };

  return percentage < 50 ? (
    <TrainingsProgressBar
      strokePathColor={"#FF66A9"}
      fillBackgroundColor={"#FF66A9"}
      trainingType={"OnGoingTraining"}
      percentage={percentage}
    />
  ) : percentage >= 50 && percentage < 75 ? (
    <TrainingsProgressBar
      strokePathColor={"#ED984F"}
      fillBackgroundColor={"#ED984F"}
      trainingType={"OnGoingTraining"}
      percentage={percentage}
    />
  ) : (
    <TrainingsProgressBar
      strokePathColor={"#3367CD"}
      fillBackgroundColor={"#3367CD"}
      trainingType={"OnGoingTraining"}
      percentage={percentage}
    />
  );
};

export default OnGoingTrainingsProgressBar;
