//@ts-ignore
import { useHistory } from "react-router";
import {
  useTable,
  useResizeColumns,
  useFlexLayout,
  useRowSelect,
  useSortBy,
} from "react-table";
import tw from "twin.macro";
import { ReactComponent as DescIcon } from "../../../../assets/img/icons/Desc.svg";
import { ReactComponent as AscIcon } from "../../../../assets/img/icons/Asc.svg";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  retrieveTrainingDetail,
  selectFinishedTrainingsLoading,
  selectNotPublishedTrainingsLoading,
  selectOngoingTrainingsLoading,
  selectPublishedTrainingsLoading,
  selectRegistredTrainingsLoading,
} from "../../../../redux/slices/trainingSlice";
import { Spinner } from "reactstrap";
import { selectUser } from "../../../../redux/slices/userSlice";

const Table = tw.table`
`;

const TableHead = tw.thead`
  p-4
`;

const TableRow = tw.tr`
p-2
`;

const TableHeader = tw.th`
`;

const TableBody = tw.tbody`
`;

const TableData = tw.td`
p-5
`;

const TrainingTable = (props: any) => {
  const { data = props.data, columns = props.columns } = { ...props };
  const user: any = useAppSelector(selectUser);
  const userRole: any = user.role;
  const isEditor: any = userRole.includes("EDITOR");
  const isNqtCollaborator = userRole.includes("NQT_COLLABORATOR");
  const path = isEditor ? "/editor" : (isNqtCollaborator ? "/collaborateur" : "");

  const ongoingTrainingsLoading = useAppSelector(selectOngoingTrainingsLoading);
  const registredTrainingsLoading = useAppSelector(
    selectRegistredTrainingsLoading
  );
  const finishedTrainingsLoading = useAppSelector(
    selectFinishedTrainingsLoading
  );
  const publishedTrainingsLoading = useAppSelector(
    selectPublishedTrainingsLoading
  );
  const notPublishedTrainingsLoading = useAppSelector(
    selectNotPublishedTrainingsLoading
  );
  const dispatch = useAppDispatch();
  /**
   * Navigate to details
   * @param {number} id
   */
  const navigateToDetails = (id: number) => {
    dispatch(retrieveTrainingDetail({ id }));
    history.push("/publishedTrainingDetail");
  };
  const tableInstance = useTable(
    { columns, data },
    useResizeColumns,
    useFlexLayout,
    useSortBy,
    useRowSelect
  );
  const history = useHistory();
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <Table
      className="p-3"
      style={{ borderRadius: "10px" }}
      {...getTableProps()}
    >
      <TableHead>
        {headerGroups.map((headerGroup: any) => (
          <TableRow
            className="rounded-borders table-row mb-2"
            {...headerGroup.getHeaderGroupProps()}
          >
            {headerGroup.headers.map((column: any) => (
              <TableHeader
                className={
                  column.isSorted
                    ? "label-black-nqt-normal"
                    : "label-grey-nqt-normal"
                }
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                {column.render("Header")}
                {column.isSorted ? (
                  column.isSortedDesc ? (
                    <div className="filter-icons-div">
                      <DescIcon fill="28282A" />
                    </div>
                  ) : (
                    <div className="filter-icons-div">
                      <AscIcon fill="28282A" style={{ marginBottom: "10px" }} />
                    </div>
                  )
                ) : (
                  <div className="filter-icons-div">
                    <AscIcon fill="grey" className="filter-icons" />
                    <DescIcon fill="grey" />
                  </div>
                )}
              </TableHeader>
            ))}
          </TableRow>
        ))}
      </TableHead>
      {(ongoingTrainingsLoading &&
        registredTrainingsLoading &&
        finishedTrainingsLoading) ||
      (publishedTrainingsLoading && notPublishedTrainingsLoading) ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: 100 }}
        >
          <Spinner animation="border" style={{ color: "#3367cd" }} />
        </div>
      ) : (
        <TableBody {...getTableBodyProps()}>
          {rows.map((row: any) => {
            prepareRow(row);
            return (
              <TableRow
                className={"rounded-borders table-row mb-2 pointer"}
                {...row.getRowProps()}
                onClick={() => {
                  if (row.original.status !== "published" &&
                  row.original.status !== "finished"){
                    if(isEditor || isNqtCollaborator) {
                      history.push({
                        pathname: path,
                        state: { id: row.original.id },
                      });
                    } else {
                      history.push({
                        pathname: "/home",
                        state: { id: row.original.id },
                      });
                    }
                  }else if (row.original.status === "finished"){
                    return;
                  }else{
                    navigateToDetails(row.original.id);
                  }
                }}
              >
                {row.cells.map((cell: any, idx: any) =>
                  cell.column.Header.toLowerCase() === "nom de formation" ? (
                    <TableData
                      className="p-0 label-black-trainings me-1"
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </TableData>
                  ) : cell.column.Header === "%" ||
                    cell.column.Header === "" ? (
                    <TableData className="p-0" {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </TableData>
                  ) : (
                    <TableData
                      className="p-0 label-grey-trainings"
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </TableData>
                  )
                )}
              </TableRow>
            );
          })}
        </TableBody>
      )}
    </Table>
  );
};

export default TrainingTable;
