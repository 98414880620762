import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { Col, Row } from "reactstrap";

const TrophyIcon = (props: any) => {
  const { trophyType } = props;

  let bronzeIcon = (
    <img
      alt="..."
      src={require("../../../../../assets/img/swiperImages/bronze.png").default}
      style={{ opacity: 0.5 }}
    />
  );
  let silverIcon = (
    <img
      alt="..."
      style={{ opacity: 0.5 }}
      src={require("../../../../../assets/img/swiperImages/argent.png").default}
    />
  );
  let goldIcon = (
    <img
      alt="..."
      style={{ opacity: 0.5 }}
      src={
        require("../../../../../assets/img/swiperImages/orDisable.png").default
      }
    />
  );
  if (trophyType === "bronze") {
    bronzeIcon = (
      <img
        alt="..."
        src={
          require("../../../../../assets/img/swiperImages/bronze.png").default
        }
      />
    );
    silverIcon = (
      <img
        alt="..."
        style={{ opacity: 0.5 }}
        src={
          require("../../../../../assets/img/swiperImages/argent.png").default
        }
      />
    );
    goldIcon = (
      <img
        alt="..."
        style={{ opacity: 0.5 }}
        src={
          require("../../../../../assets/img/swiperImages/orDisable.png")
            .default
        }
      />
    );
  }
  if (trophyType === "silver") {
    bronzeIcon = (
      <img
        alt="..."
        src={
          require("../../../../../assets/img/swiperImages/bronze.png").default
        }
      />
    );
    silverIcon = (
      <img
        alt="..."
        src={
          require("../../../../../assets/img/swiperImages/argent.png").default
        }
      />
    );
    goldIcon = (
      <img
        alt="..."
        style={{ opacity: 0.5 }}
        src={
          require("../../../../../assets/img/swiperImages/orDisable.png")
            .default
        }
      />
    );
  }
  if (trophyType === "gold") {
    bronzeIcon = (
      <img
        alt="..."
        src={
          require("../../../../../assets/img/swiperImages/bronze.png").default
        }
      />
    );
    silverIcon = (
      <img
        alt="..."
        src={
          require("../../../../../assets/img/swiperImages/argent.png").default
        }
      />
    );
    goldIcon = (
      <img
        alt="..."
        src={
          require("../../../../../assets/img/swiperImages/orDisable.png")
            .default
        }
      />
    );
  }
  return (
    <Col xs={12}>
      <Row>
        <Col xs={4} className="pt-0 ps-0 pe-0">
          <div style={{ minWidth: 40, maxWidth: 40 }}>
            <CircularProgressbarWithChildren
              value={trophyType === "" ? 0 : 100}
              background={true}
              styles={{
                root: {},
                path: {
                  stroke: `#F7DE94`,
                  strokeLinecap: "butt",
                  transition: "stroke-dashoffset 0.5s ease 0s",
                  strokeWidth: "3px",
                },
                trail: {
                  stroke: "rgb(255,255, 255)",
                  strokeLinecap: "butt",
                  strokeWidth: "10px",
                },
                background: {
                  fill: "#8D8D8D",
                  opacity: 0.2,
                },
              }}
            >
              {bronzeIcon}
            </CircularProgressbarWithChildren>
          </div>
        </Col>
        <Col xs={4} className="pt-0 ps-0 pe-0">
          <div style={{ minWidth: 40, maxWidth: 40 }}>
            <CircularProgressbarWithChildren
              value={(trophyType === "bronze" || trophyType === "") ? 0 : 100}
              background={true}
              styles={{
                root: {},
                path: {
                  stroke: `#F7DE94`,
                  strokeLinecap: "butt",
                  transition: "stroke-dashoffset 0.5s ease 0s",
                  strokeWidth: "3px",
                },
                trail: {
                  stroke: "rgb(255,255, 255)",
                  strokeLinecap: "butt",
                  strokeWidth: "10px",
                },
                background: {
                  fill: "#8D8D8D",
                  opacity: 0.2,
                },
              }}
            >
              {silverIcon}
            </CircularProgressbarWithChildren>
          </div>
        </Col>
        <Col xs={4} className="pt-0 ps-0 pe-0">
          <div style={{ minWidth: 40, maxWidth: 40 }}>
            <CircularProgressbarWithChildren
              value={trophyType === "gold" ? 100 : 0}
              background={true}
              styles={{
                root: {},
                path: {
                  stroke: `#F7DE94`,
                  strokeLinecap: "butt",
                  transition: "stroke-dashoffset 0.5s ease 0s",
                  strokeWidth: "3px",
                },
                trail: {
                  stroke: "rgb(255,255, 255)",
                  strokeLinecap: "butt",
                  strokeWidth: "10px",
                },
                background: {
                  fill: "#8D8D8D",
                  opacity: 0.2,
                },
              }}
            >
              {goldIcon}
            </CircularProgressbarWithChildren>
          </div>
        </Col>
      </Row>
    </Col>
  );
};
export default TrophyIcon;
