import { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Row, Spinner } from 'reactstrap'
import Nav from 'react-bootstrap/Nav'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import {
  editGamificationAdminData,
  retrieveGamificationAdmin,
  selectLoading,
} from '../../../../redux/slices/gamificationSlice'
import AnimatorCard from './AnimatorCard'
import ContributorCard from './ContributorCard'
import CreatorCard from './CreatorCard'
import LearnerCard from './LearnerCard'
//@ts-ignore
import ProofreaderCard from './ProofreaderCard'
interface Links {
  id: number
  name: string
}
const BadgesAndAwards = (props: any) => {
  const { scrollable } = props

  const [proofreaderData, setproofreaderData] = useState<any>([
    { function: '', reward_type: '', nb_points: '', equivalence: '' },
  ])
  const [contributorData, setcontributorData] = useState<any>([
    { function: '', reward_type: '', nb_points: '', equivalence: '' },
  ])
  const [creatorData, setcreatorData] = useState<any>([
    { function: '', reward_type: '', nb_points: '', equivalence: '' },
  ])
  const [animatorData, setanimatorData] = useState<any>([
    { function: '', reward_type: '', nb_points: '', equivalence: '' },
  ])
  const [learnerData, setlearnerData] = useState<any>([
    { function: '', reward_type: '', nb_points: '', equivalence: '' },
  ])
  const [oldValues, setOldValues] = useState<any>([
    {
      Relecteur: [
        { function: '', reward_type: '', nb_points: '', equivalence: '' },
      ],
    },
    {
      Contributeur: [
        { function: '', reward_type: '', nb_points: '', equivalence: '' },
      ],
    },
    {
      Createur: [
        { function: '', reward_type: '', nb_points: '', equivalence: '' },
      ],
    },
    {
      Animateur: [
        { function: '', reward_type: '', nb_points: '', equivalence: '' },
      ],
    },
    {
      Learner: [
        { function: '', reward_type: '', nb_points: '', equivalence: '' },
      ],
    },
  ])
  const [updatedValues, setUpdatedValues] = useState<any>([
    { id: '', function: '', reward_type: '', nb_points: '', equivalence: '' },
  ])
  const status = useAppSelector(selectLoading)
  const dispatch = useAppDispatch()
  const [edit, setEdit] = useState(false)

  const handleProofreaderData = (index: any) => {
    setproofreaderData(index)
  }
  const handleContributorData = (index: any) => {
    setcontributorData(index)
  }
  const handleCreatorData = (index: any) => {
    setcreatorData(index)
  }
  const handleAnimatorData = (index: any) => {
    setanimatorData(index)
  }
  const handleLearnerData = (index: any) => {
    setlearnerData(index)
  }
  const handleUpdatedValues = (index: any) => {
    setUpdatedValues(index)
  }

  useEffect(() => {
    dispatch(retrieveGamificationAdmin()).then((result: any) => {
      setproofreaderData(
        Object.values(result.payload.rewards).filter(
          (val: any) => val.function === 'Relecteur',
        ),
      )
      setcontributorData(
        Object.values(result.payload.rewards).filter(
          (val: any) => val.function === 'Contributeur',
        ),
      )
      setcreatorData(
        Object.values(result.payload.rewards).filter(
          (val: any) => val.function === 'Createur',
        ),
      )
      setanimatorData(
        Object.values(result.payload.rewards).filter(
          (val: any) => val.function === 'Animateur',
        ),
      )
      setlearnerData(
        Object.values(result.payload.rewards).filter(
          (val: any) => val.function === 'Learner',
        ),
      )
      setOldValues({
        Relecteur: Object.values(result.payload.rewards).filter(
          (val: any) => val.function === 'Relecteur',
        ),
        Contributeur: Object.values(result.payload.rewards).filter(
          (val: any) => val.function === 'Contributeur',
        ),
        Createur: Object.values(result.payload.rewards).filter(
          (val: any) => val.function === 'Createur',
        ),
        Animateur: Object.values(result.payload.rewards).filter(
          (val: any) => val.function === 'Animateur',
        ),
        Learner: Object.values(result.payload.rewards).filter(
          (val: any) => val.function === 'Learner',
        ),
      })
    })
  }, [dispatch])

  const NavLinks: Links[] = [
    {
      id: 0,
      name: 'Tous',
    },
    {
      id: 1,
      name: 'Relecteurs',
    },
    {
      id: 2,
      name: 'Contributeurs',
    },
    {
      id: 3,
      name: 'Créateurs',
    },
    {
      id: 4,
      name: 'Animateurs',
    },
    {
      id: 5,
      name: 'Learners',
    },
  ]
  const [activeLink, setActiveLink] = useState(0)
  const navElement = NavLinks.map((val: Links) => {
    return (
      <Nav.Link
        key={val.name}
        eventKey={val.id.toString()}
        className="navLink d-flex flex-row pb-4"
      >
        <div className="mt-1">{val.name}</div>
      </Nav.Link>
    )
  })
  return (
    <div className="center-container">
      <Row className="p-2 ml-2">
        <Col md="12">
          <Row className="p-2 ml-2">
            <Col>
              <div className="label-black-md mt-4 pt-5 ml-4">
                Badges et récompenses{' '}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Nav
                activeKey={activeLink}
                onSelect={(selectedKey) =>
                  selectedKey && setActiveLink(+selectedKey)
                }
                className="d-flex pt-3"
              >
                {navElement}
              </Nav>
            </Col>
            <Col>
              <Button
                style={{ marginTop: 20, marginRight: 10 }}
                className="btn-round-sm float-end mb-0 pointer"
                color="primary"
                type="submit"
                onClick={() => {
                  setEdit(!edit)
                  if (edit) {
                    const filterEmptyValues = updatedValues.filter(
                      (value: any) => value.id !== '',
                    )
                    dispatch(
                      editGamificationAdminData({ rewards: filterEmptyValues }),
                    )
                  }
                }}
              >
                {status === 'true' && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="me-1"
                  />
                )}
                {edit ? 'Valider' : 'Editer'}
              </Button>
            </Col>
          </Row>
        </Col>
        <Row style={{ width: '100%', marginLeft: 3 }}>
          <Col className={scrollable ? 'overflow-scroll' : ''}>
            <Row className="center-container mb-4">
              <Col md="12">
                <Card
                  body
                  color="light"
                  outline
                  className="card p-0 mt-1 mb-1"
                  style={{ background: '#F0F0F0' }}
                >
                  <CardBody className="pl-1 pb-0 pt-1 mt-1">
                    <Row className="pb-2 w-100">
                      <Col md="3" sm="3" xs="3">
                        <p className="label-black-nqt mb-0">Rôle</p>
                      </Col>
                      <Col md="3" sm="3" xs="3">
                        <p className="label-black-nqt mb-0">Trophées</p>
                      </Col>
                      <Col md="3" sm="3" xs="3">
                        <p className="label-black-nqt mb-0">Nombre de points</p>
                      </Col>
                      <Col md="3" sm="3" xs="3">
                        <p className="label-black-nqt mb-0">ÉQUIVALENCE</p>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                {activeLink === 0 && (
                  <>
                    <ProofreaderCard
                      proofreaderData={proofreaderData}
                      edit={edit}
                      handleProofreaderData={handleProofreaderData}
                      oldValues={oldValues}
                      handleUpdatedValues={handleUpdatedValues}
                      updatedValues={updatedValues}
                    />
                    <ContributorCard
                      contributorData={contributorData}
                      edit={edit}
                      handleContributorData={handleContributorData}
                      oldValues={oldValues}
                      handleUpdatedValues={handleUpdatedValues}
                      updatedValues={updatedValues}
                    />
                    <CreatorCard
                      creatorData={creatorData}
                      edit={edit}
                      handleCreatorData={handleCreatorData}
                      oldValues={oldValues}
                      handleUpdatedValues={handleUpdatedValues}
                      updatedValues={updatedValues}
                    />
                    <AnimatorCard
                      animatorData={animatorData}
                      edit={edit}
                      handleAnimatorData={handleAnimatorData}
                      oldValues={oldValues}
                      handleUpdatedValues={handleUpdatedValues}
                      updatedValues={updatedValues}
                    />
                    <LearnerCard
                      learnerData={learnerData}
                      edit={edit}
                      handleLearnerData={handleLearnerData}
                      oldValues={oldValues}
                      handleUpdatedValues={handleUpdatedValues}
                      updatedValues={updatedValues}
                    />
                  </>
                )}

                {activeLink === 1 && (
                  <>
                    <ProofreaderCard
                      proofreaderData={proofreaderData}
                      edit={edit}
                      handleProofreaderData={handleProofreaderData}
                    />
                  </>
                )}
                {activeLink === 2 && (
                  <>
                    <ContributorCard
                      contributorData={contributorData}
                      edit={edit}
                      handleContributorData={handleContributorData}
                    />
                  </>
                )}
                {activeLink === 3 && (
                  <>
                    <CreatorCard
                      creatorData={creatorData}
                      edit={edit}
                      handleCreatorData={handleCreatorData}
                    />
                  </>
                )}
                {activeLink === 4 && (
                  <>
                    <AnimatorCard
                      animatorData={animatorData}
                      edit={edit}
                      handleAnimatorData={handleAnimatorData}
                    />
                  </>
                )}
                {activeLink === 5 && (
                  <>
                    <LearnerCard
                      learnerData={learnerData}
                      edit={edit}
                      handleLearnerData={handleLearnerData}
                    />
                  </>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Row>
    </div>
  )
}

export default BadgesAndAwards
