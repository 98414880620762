import { Field, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useMedia } from "react-media";
import { useHistory } from "react-router";
import ReactTooltip from "react-tooltip";
import { Button, Card, Col, Form, Row } from "reactstrap";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  AnswerQuestion,
  Evaluate,
  FinishContent,
  selectTrainingLaunch,
  trainingActions,
} from "../../../../redux/slices/trainingSlice";
import NotificationAlert from "react-notification-alert";
import TrainingEvalHeader from "./TrainingEvalHeader";
import useHttp from "../../../../hooks/use-http";
import TrainingService from "../../../../services/training.services";
import { Modal } from "react-bootstrap";
import { ReactComponent as TweetIcon } from "../../../../assets/img/icons/tweet.svg";
import { ReactComponent as TweetWhiteIcon } from "../../../../assets/img/icons/tweetWhite.svg";
import { ReactComponent as ShareInIcon } from "../../../../assets/img/icons/shareText.svg";
import { RiLinkedinFill } from "react-icons/ri";
import ClubNqtService from "../../../../services/clubnqt.service";
import { LinkedinShareButton, TwitterShareButton } from "react-share";
import { Grid } from "semantic-ui-react";
import GamificationModal from "../../../../components/GamificationModal";
import Gamification from "../../../../components/Gamifification";
import { selectUser } from "../../../../redux/slices/userSlice";

type CorrectAnswerType = {
  [id: number]: number;
};

const subjects = [
  {
    id: 0,
    libelle: "Tous mes clubs",
    name: "Tous les clubs",
  },
  {
    id: 1,
    libelle: "Club national",
    name: "national",
  },
  {
    id: 2,
    libelle: "Club régional",
    name: "regional",
  },  
  {
    id: 3,
    libelle: "Club entreprise",
    name: "entreprise",
  },
  {
    id: 4,
    libelle: "Club métier",
    name: "metier",
  },
];

interface Post {
  id: any;
  text: string;
  clubs: string[];
  mentions: string[];
  hashtags: string[];
  lien?: string;
}

const TrainingQuizz = (props: any) => {
  const { setContentType } = props;
  const GLOBAL_MEDIA_QUERIES = {
    small: "(max-width: 991px)",
    large: "(min-width: 992px)",
  };
  const dispatch = useAppDispatch();
  const history = useHistory();
  const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
  const data = useAppSelector(selectTrainingLaunch);
  const profile = useAppSelector(selectUser);
  const userRole: any = profile.role;
  const isEditor: any = userRole.includes("EDITOR");
  const isNqtCollaborator = userRole.includes("NQT_COLLABORATOR");
  const path = isEditor ? "/editor" : (isNqtCollaborator ? "/collaborateur" : "");
  
  const indexSection = data.progression.currentStep
    ? data.sections.findIndex(
        (section) => section.id === data.progression.currentStep
      )
    : 0;
  const sectionData = data.sections[indexSection];
  const indexcontent = sectionData.sectionContent.findIndex(
    (content) => content.type === "eval" && content.autoEval === false
  );
  const questions = sectionData.sectionContent[indexcontent].questions?.filter(
    (quest) => quest.title !== null
  );

  const sumValues = (obj: CorrectAnswerType) =>
    Object.values(obj).reduce((a, b) => a + b, 0);
  const questionNumber = questions?.length.toString() || "0";
  const [modalShareShow, setModalShareShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [postData, setPostData] = useState<{
    formation_id: number;
    note: string;
    clubs: string[];
  }>({ formation_id: 0, note: "", clubs: [] });
  const [step, setStep] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState([1000]);
  const [selectedElement, setSelectedElement] = useState(1000);
  const [validateClicked, setValidateClicked] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [correctAnswer, setCorrectAnswer] = useState(0);
  const [correctAnswersState, setCorrectAnswersState] =
    useState<CorrectAnswerType>({});
  const [totalCorrectAnswersState, setTotalCorrectAnswersState] =
    useState<CorrectAnswerType>({});
  const [isComplete, setisComplete] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState<number[]>([]);
  const [isExpanded, setIsExpanded] = useState<{
    id?: number;
    libelle?: string;
    name?: string;
  }>({});
  const [contentTypeShare, setContentTypeShare] = useState<string[]>([]);
  const colors = ["#FF66A9", "#ED984F", "#3367CD", "#3367CD", "#3367CD"];
  const {
    sendRequest: finish,
    status: finishStatut,
    data: finishData,
  } = useHttp(TrainingService.finishSection);
  const tooltips = [
    "Pas du tout",
    "Un peu",
    "Bien",
    "Très bien",
    "Parfaitement",
  ];
  const getFeedBackIcons = () => {
    return tooltips.map((val, idx) => {
      return (
        <div
          data-tip={val}
          onClick={() => {
            setSelectedElement(idx);
          }}
        >
          <svg
            width="22"
            height="21"
            viewBox="0 0 22 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.9999 17.5875L16.1874 20.725C17.1374 21.3 18.2999 20.45 18.0499 19.375L16.6749 13.475L21.2624 9.5C22.0999 8.775 21.6499 7.4 20.5499 7.3125L14.5124 6.8L12.1499 1.225C11.7249 0.212501 10.2749 0.212501 9.84991 1.225L7.48741 6.7875L1.44991 7.3C0.349908 7.3875 -0.100092 8.7625 0.737408 9.4875L5.32491 13.4625L3.94991 19.3625C3.69991 20.4375 4.86241 21.2875 5.81241 20.7125L10.9999 17.5875Z"
              fill={
                idx <= selectedElement && selectedElement !== 1000
                  ? colors[selectedElement]
                  : "#ECF0FB"
              }
            />
          </svg>
          <ReactTooltip />
        </div>
      );
    });
  };
  const question = questions && questions[step];
  const onSelectAnswer = (index: number) => {
    if (question?.choice === "unique") {
      if (selectedAnswer.includes(index)) {
        setSelectedAnswer([1000]);
      } else {
        setSelectedAnswer([index]);
      }
    } else {
      const answer = [...selectedAnswer];
      const idx = answer.findIndex((val) => val === index);
      if (idx !== -1) {
        answer.splice(idx, 1);
      } else {
        answer.push(index);
      }
      setSelectedAnswer(answer);
    }
  };
  const correctAnswers =
    question?.answers.filter((answer) => answer.isCorrect) || [];
  const validateAnswer = () => {
    setValidateClicked(true);
    if (question) {
      const answers: string[] = [];
      setIsCorrect(
        correctAnswers.every(
          (val) =>
            selectedAnswer.includes(
              question.answers?.findIndex((q) => q.title === val.title)
            ) &&
            ((question.choice === "multiple" &&
              selectedAnswer.length - 1 === correctAnswers.length) ||
              (question.choice === "unique" &&
                selectedAnswer.length === correctAnswers.length))
        )
      );
      selectedAnswer.forEach((element) => {
        if (element !== 1000) {
          answers.push(question.answers[element].title);
        }
        if (element !== 1000 && question.answers[element].isCorrect) {
          setCorrectAnswer((prev) => prev + 1);
        }
      });
      dispatch(AnswerQuestion({ id: question.id, answers }));
    }
  };

  const elementStyle = (idx: number) => {
    if (question) {
      if (!validateClicked && selectedAnswer.includes(idx)) {
        return { background: "#ECF0FB", border: "1px solid #3367CD" };
      } else if (
        validateClicked &&
        (isCorrect || question.answers[idx].isCorrect) &&
        selectedAnswer.includes(idx)
      ) {
        return { background: "#ECF0FB", border: "1px solid #3367CD" };
      } else if (
        question.choice === "unique" &&
        validateClicked &&
        !isCorrect &&
        selectedAnswer.includes(idx)
      ) {
        return {
          background: "rgba(80, 189, 137, 0.1)",
          border: "1px solid #50BD89",
        };
      } else if (
        question.choice === "multiple" &&
        validateClicked &&
        !question.answers[idx].isCorrect &&
        selectedAnswer.includes(idx)
      ) {
        return {
          background: "rgba(255, 102, 169, 0.1)",
          border: "1px solid #FF66A9",
        };
      } else if (
        question.choice === "multiple" &&
        validateClicked &&
        question.answers[idx].isCorrect &&
        !selectedAnswer.includes(idx)
      ) {
        return {
          background: "rgba(80, 189, 137, 0.1)",
          border: "1px dashed #50BD89",
        };
      } else {
        return { background: "#FFFFFF", border: "1px solid #ECF0FB" };
      }
    }
  };
  const numberStyle = (idx: number) => {
    if (!validateClicked && selectedAnswer.includes(idx)) {
      return { background: "#3367CD", color: "#FFFFFF" };
    } else if (validateClicked && isCorrect && selectedAnswer.includes(idx)) {
      return { background: "#3367CD", color: "#FFFFFF" };
    } else if (validateClicked && !isCorrect && selectedAnswer.includes(idx)) {
      return { background: "#50BD89", color: "#FFFFFF" };
    } else if (validateClicked && !isCorrect && !selectedAnswer.includes(idx)) {
      return { background: "#FF66A9", color: "#FFFFFF" };
    } else {
      return { background: "#ECF0FB", color: "#3367CD" };
    }
  };
  const resetState = (isSuiv: boolean) => {
    if (question && questions) {
      setCorrectAnswersState((prev) => {
        const cAnswer = { ...prev };
        cAnswer[step] = correctAnswer;
        return cAnswer;
      });
      setTotalCorrectAnswersState((prev) => {
        const cAnswer = { ...prev };
        cAnswer[step] = correctAnswers.length;
        return cAnswer;
      });
      if (step < questions.length - 1) {
        if (isSuiv) {
          setStep((prev) => prev + 1);
        } else {
          setStep((prev) => prev - 1);
        }
        setSelectedAnswer([1000]);
        setValidateClicked(false);
        setIsCorrect(false);
        setCorrectAnswer(0);
      } else {
        setisComplete(true);
      }
    }
  };
  const stepProgress = ((100 / +questionNumber) * (step + 1)).toString() + "%";
  const [comment, setComment] = useState("");
  const notificationAlert = useRef();
  const { sendRequest, status } = useHttp(TrainingService.postFeedback);
  const { sendRequest: share, status: shareStatus } = useHttp(
    ClubNqtService.shareTrainingResult
  );
  const { sendRequest: savePost } = useHttp(
    ClubNqtService.savePost
  );

  useEffect(() => {
    if (status === "completed") {
      //@ts-ignore
      notificationAlert.current.notificationAlert({
        place: "tr",
        message: <div>{"Vos feedbacks ont bien été sauvegardés."}</div>,
        type: "success",
        icon: "nc-icon nc-bell-55",
        closeButton: false,
        autoDismiss: 7,
      });
    }
  }, [status]);

  useEffect(() => {
    if (shareStatus === "completed") {
      //@ts-ignore
      notificationAlert.current.notificationAlert({
        place: "tr",
        message: <div>{"Vos accomplissement ont bien été partagé."}</div>,
        type: "success",
        icon: "nc-icon nc-bell-55",
        closeButton: false,
        autoDismiss: 7,
      });
    }
  }, [shareStatus]);

  useEffect(() => {
    if (finishStatut === "completed" && finishData.popUpInformation) {
      setModalShow(true);
    }
  }, [finishStatut, finishData]);

  useEffect(() => {
    if (
      isComplete &&
      sumValues(correctAnswersState) * 2 > sumValues(totalCorrectAnswersState)
    ) {
      finish(data.sections[indexSection].id);
    }
  }, [isComplete]);

  useEffect(() => {
    let clubs = selectedSubject.map((id: number) => subjects[id].name);
    if (clubs.length === 5) {
      clubs = ["regional", "national", "entreprise", "metier"];
    }
    clubs =[...clubs, ...contentTypeShare];
    setPostData((prev) => {
      return { ...prev, clubs };
    });
  }, [selectedSubject,contentTypeShare]);

  const sendFeedback = () => {
    const note = selectedElement !== 1000 && selectedElement + 1;
    const cmt = comment !== "" && comment;
    if (cmt && note) {
      const feedback = {
        note,
        comment: cmt,
      };
      sendRequest({ formationId: data.id, feedback });
    } else if (cmt) {
      const feedback = {
        comment: cmt,
      };
      sendRequest({ formationId: data.id, feedback });
    } else {
      const feedback = {
        note,
      };
      sendRequest({ formationId: data.id, feedback });
    }
  };

  const setSubjectSelected = (e: any) => {
    const id = e.currentTarget ? +e.currentTarget.dataset.id : e;
    if (selectedSubject.includes(id)) {
      if (id === 0 || selectedSubject.filter((sub) => sub !== 0).length === 1) {
        setSelectedSubject([]);
      } else {
        setSelectedSubject((prev) => {
          const sub = [...prev];
          sub.splice(
            selectedSubject.findIndex((val) => val === id),
            1
          );
          if (selectedSubject.findIndex((val) => val === 0) !== -1) {
            sub.splice(
              selectedSubject.findIndex((val) => val === 0),
              1
            );
          }
          return sub;
        });
      }
    } else {
      if (id === 0) {
        setSelectedSubject([0, 1, 2, 3, 4]);
      } else {
        selectedSubject.filter((subj: any) => subj.id !== 0).length === 3
          ? setSelectedSubject([0, 1, 2, 3, 4])
          : setSelectedSubject((prev) => {
              const sub = [...prev];
              sub.push(id);
              return sub;
            });
      }
    }
  };
  const setContentsSelected = (id: string) => {
    if (contentTypeShare.includes(id)) {
      setContentTypeShare((prev) => {
        const sub = [...prev];
        sub.splice(
          contentTypeShare.findIndex((val) => val === id),
          1
        );
        return sub;
      });
    } else {
      setContentTypeShare((prev) => {
        const sub = [...prev];
        if (
          contentTypeShare.includes(`${isExpanded.name}-all`) ||
          contentTypeShare.includes(`${isExpanded.name}`)
        ) {
          sub.splice(
            contentTypeShare.findIndex(
              (val) =>
                val === `${isExpanded.name}-all` || val === `${isExpanded.name}`
            ),
            1
          );
        }
        sub.push(id);
        return sub;
      });
    }
  };
  const filtredSubjects =
  profile.role.includes("ALUMNI")
    ? subjects.filter((subj: any) => subj.id !== 3 && subj.id !== 0)
    : subjects;
    const subjectElement = filtredSubjects.map((subj: any) => {
      return subj.id === 4 || subj.id === 0 || !profile.role.includes("ALUMNI") ? (
        <li
          className="d-flex subjectList"
          key={subj.id}
          data-id={subj.id}
          onClick={setSubjectSelected.bind(this)}
        >
          {selectedSubject.includes(subj.id) ? (
            <div className="checked">
              <svg
                width="9"
                height="7"
                viewBox="0 0 9 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.75015 4.38408L1.481 3.11494C1.22087 2.85481 0.800078 2.85481 0.539947 3.11494C0.279816 3.37507 0.279816 3.79586 0.539947 4.05599L2.28161 5.79766C2.54175 6.05779 2.96254 6.05779 3.22267 5.79766L7.631 1.38933C7.89113 1.12919 7.89113 0.708403 7.631 0.448272C7.3709 0.188171 6.95018 0.188141 6.69004 0.448183C6.69001 0.448212 6.68998 0.448242 6.68995 0.448272L2.75015 4.38408Z"
                  fill="#3367CD"
                  stroke="#3367CD"
                />
              </svg>
            </div>
          ) : (
            <div className="unchecked">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM14 11H11V14C11 14.55 10.55 15 10 15C9.45 15 9 14.55 9 14V11H6C5.45 11 5 10.55 5 10C5 9.45 5.45 9 6 9H9V6C9 5.45 9.45 5 10 5C10.55 5 11 5.45 11 6V9H14C14.55 9 15 9.45 15 10C15 10.55 14.55 11 14 11Z"
                  fill="#ECF0FB"
                />
              </svg>
            </div>
          )}
  
          <span className="checkBox">{subj.libelle}</span>
        </li>
      ) : (
        <li
          className="d-flex subjectList"
          key={subj.id}
          data-id={subj.id}
          onClick={() => {
            setIsExpanded(subj);
          }}
        >
          {(contentTypeShare.includes(`${subj.name}-all`) || contentTypeShare.includes(`${subj.name}`)) ? (
            <div className="checked">
              <svg
                width="9"
                height="7"
                viewBox="0 0 9 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.75015 4.38408L1.481 3.11494C1.22087 2.85481 0.800078 2.85481 0.539947 3.11494C0.279816 3.37507 0.279816 3.79586 0.539947 4.05599L2.28161 5.79766C2.54175 6.05779 2.96254 6.05779 3.22267 5.79766L7.631 1.38933C7.89113 1.12919 7.89113 0.708403 7.631 0.448272C7.3709 0.188171 6.95018 0.188141 6.69004 0.448183C6.69001 0.448212 6.68998 0.448242 6.68995 0.448272L2.75015 4.38408Z"
                  fill="#3367CD"
                  stroke="#3367CD"
                />
              </svg>
            </div>
          ) : (
            <div className="unchecked">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM14 11H11V14C11 14.55 10.55 15 10 15C9.45 15 9 14.55 9 14V11H6C5.45 11 5 10.55 5 10C5 9.45 5.45 9 6 9H9V6C9 5.45 9.45 5 10 5C10.55 5 11 5.45 11 6V9H14C14.55 9 15 9.45 15 10C15 10.55 14.55 11 14 11Z"
                  fill="#ECF0FB"
                />
              </svg>
            </div>
          )}
          <span className="checkBox">{subj.libelle}</span>
          <div style={{ marginLeft: "auto", paddingRight: 10 }}>
            <svg
              width="7"
              height="12"
              viewBox="0 0 7 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.78016 5.27985C6.92086 5.42037 6.99999 5.61101 7.00016 5.80985V6.18985C6.99786 6.38827 6.91905 6.57813 6.78016 6.71985L1.64016 11.8499C1.54628 11.9445 1.41848 11.9978 1.28516 11.9978C1.15185 11.9978 1.02405 11.9445 0.930165 11.8499L0.220165 11.1399C0.126101 11.0477 0.0730934 10.9215 0.0730934 10.7899C0.0730934 10.6582 0.126101 10.532 0.220165 10.4399L4.67016 5.99985L0.220165 1.55985C0.125508 1.46597 0.0722656 1.33817 0.0722656 1.20485C0.0722656 1.07153 0.125508 0.943736 0.220165 0.849852L0.930165 0.149852C1.02405 0.055196 1.15185 0.00195312 1.28516 0.00195312C1.41848 0.00195312 1.54628 0.055196 1.64016 0.149852L6.78016 5.27985Z"
                fill="#BDBDBD"
              />
            </svg>
          </div>
        </li>
      );
    });
  
    const contentsOptions = (
      <>
        <li
          className="d-flex subjectList"
          key="All"
          data-id="All"
          onClick={() => setContentsSelected(`${isExpanded.name}-all`)}
        >
          {contentTypeShare.includes(`${isExpanded.name}-all`) ? (
            <div className="checked">
              <svg
                width="9"
                height="7"
                viewBox="0 0 9 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.75015 4.38408L1.481 3.11494C1.22087 2.85481 0.800078 2.85481 0.539947 3.11494C0.279816 3.37507 0.279816 3.79586 0.539947 4.05599L2.28161 5.79766C2.54175 6.05779 2.96254 6.05779 3.22267 5.79766L7.631 1.38933C7.89113 1.12919 7.89113 0.708403 7.631 0.448272C7.3709 0.188171 6.95018 0.188141 6.69004 0.448183C6.69001 0.448212 6.68998 0.448242 6.68995 0.448272L2.75015 4.38408Z"
                  fill="#3367CD"
                  stroke="#3367CD"
                />
              </svg>
            </div>
          ) : (
            <div className="unchecked">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM14 11H11V14C11 14.55 10.55 15 10 15C9.45 15 9 14.55 9 14V11H6C5.45 11 5 10.55 5 10C5 9.45 5.45 9 6 9H9V6C9 5.45 9.45 5 10 5C10.55 5 11 5.45 11 6V9H14C14.55 9 15 9.45 15 10C15 10.55 14.55 11 14 11Z"
                  fill="#ECF0FB"
                />
              </svg>
            </div>
          )}
  
          <span className="checkBox">Mentors & Alumni</span>
        </li>
        <li
          className="d-flex subjectList"
          key="Alumni"
          data-id="Alumni"
          onClick={() => setContentsSelected(`${isExpanded.name}`)}
        >
          {contentTypeShare.includes(`${isExpanded.name}`) ? (
            <div className="checked">
              <svg
                width="9"
                height="7"
                viewBox="0 0 9 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.75015 4.38408L1.481 3.11494C1.22087 2.85481 0.800078 2.85481 0.539947 3.11494C0.279816 3.37507 0.279816 3.79586 0.539947 4.05599L2.28161 5.79766C2.54175 6.05779 2.96254 6.05779 3.22267 5.79766L7.631 1.38933C7.89113 1.12919 7.89113 0.708403 7.631 0.448272C7.3709 0.188171 6.95018 0.188141 6.69004 0.448183C6.69001 0.448212 6.68998 0.448242 6.68995 0.448272L2.75015 4.38408Z"
                  fill="#3367CD"
                  stroke="#3367CD"
                />
              </svg>
            </div>
          ) : (
            <div className="unchecked">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM14 11H11V14C11 14.55 10.55 15 10 15C9.45 15 9 14.55 9 14V11H6C5.45 11 5 10.55 5 10C5 9.45 5.45 9 6 9H9V6C9 5.45 9.45 5 10 5C10.55 5 11 5.45 11 6V9H14C14.55 9 15 9.45 15 10C15 10.55 14.55 11 14 11Z"
                  fill="#ECF0FB"
                />
              </svg>
            </div>
          )}
  
          <span className="checkBox">Alumni</span>
        </li>
      </>
    );

  return !isComplete ? (
    <>
      <TrainingEvalHeader
        setContentType={setContentType}
        title={sectionData.sectionTitle}
        type={"quizz"}
      ></TrainingEvalHeader>
      {matches.large ? (
        <Row className="questions-container">
          <Col
            md="12"
            lg="6"
            className="questions d-flex"
            style={{ padding: 70 }}
          >
            <div className="d-flex">
              <div className="question-number">
                {(step + 1).toString().padStart(2, "0")}
              </div>
              <div className="question-total">
                /{questionNumber.padStart(2, "0")}
              </div>
            </div>
            <div className="preview-center">
              <div className="question">{question?.title}</div>
              {question?.choice === "unique" && (
                <div className="type">Une seule bonne réponse est attendu</div>
              )}
              {question?.choice === "multiple" && (
                <div className="type">
                  Plusieurs bonnes réponses sont attendues.{" "}
                </div>
              )}
            </div>
            <div className="quizz-progress">
              <svg
                width="265"
                height="4"
                viewBox="0 0 265 4"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  opacity="0.3"
                  width={"100%"}
                  height="4"
                  rx="2"
                  fill="#ECF0FB"
                />
                <rect width={stepProgress} height="4" rx="2" fill="white" />
              </svg>
            </div>
          </Col>
          <Col md="12" lg="6" className="answers">
            <div className="answer">
              {validateClicked && (
                <div className="result">
                  <span className="label-grey-md-normal">Résultat</span>
                  {isCorrect && (
                    <span className="label-grey-md">
                      Bravo ! Vous avez trouvé la bonne réponse !
                    </span>
                  )}
                  {!isCorrect && correctAnswer === 0 && (
                    <span className="label-grey-md">
                      Oups, vous vous êtes trompée de réponse(s).
                    </span>
                  )}
                  {!isCorrect && correctAnswer > 0 && (
                    <span className="label-blue-md">
                      {correctAnswer} bonne réponse sur {correctAnswers.length}
                    </span>
                  )}
                </div>
              )}
              {question &&
                question.answers
                  .filter((val) => val.title !== null)
                  .map((val, idx: number) => {
                    return (
                      (!validateClicked ||
                        (validateClicked &&
                          (selectedAnswer.includes(idx) || val.isCorrect))) && (
                        <div
                          key={idx}
                          className="answer-element"
                          style={elementStyle(idx)}
                          onClick={onSelectAnswer.bind(this, idx)}
                        >
                          {question.choice === "unique" && (
                            <div
                              className="answer-number"
                              style={numberStyle(idx)}
                            >
                              {" "}
                              {idx + 1}{" "}
                            </div>
                          )}
                          {question.choice === "multiple" && (
                            <Formik
                              initialValues={{
                                checked: [],
                              }}
                              onSubmit={() => {}}
                            >
                              {({ values }) => (
                                <Form>
                                  <Field
                                    type="checkbox"
                                    value={idx}
                                    className="ms-3 me-1 mt-3 choice"
                                    checked={selectedAnswer.includes(idx)}
                                    disabled={validateClicked}
                                    onChange={() => onSelectAnswer(idx)}
                                  />
                                </Form>
                              )}
                            </Formik>
                          )}
                          <div className="answer-response">{val.title}</div>
                        </div>
                      )
                    );
                  })}
            </div>
            <div className="btn-answer">
              {validateClicked && step > 0 && (
                <div
                  className="btn-prec"
                  onClick={() => {
                    resetState(false);
                  }}
                >
                  <svg
                    width="20"
                    height="14"
                    viewBox="0 0 20 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20 6.49997V7.49997C20 7.77612 19.7761 7.99997 19.5 7.99997H3.33L7.78 12.44C7.87466 12.5339 7.9279 12.6617 7.9279 12.795C7.9279 12.9283 7.87466 13.0561 7.78 13.15L7.07 13.85C6.97612 13.9446 6.84832 13.9979 6.715 13.9979C6.58168 13.9979 6.45388 13.9446 6.36 13.85L0.22 7.71997C0.0793075 7.57946 0.000175052 7.38882 0 7.18997V6.80997C0.00230401 6.61156 0.081116 6.4217 0.22 6.27997L6.36 0.149974C6.45388 0.055318 6.58168 0.0020752 6.715 0.0020752C6.84832 0.0020752 6.97612 0.055318 7.07 0.149974L7.78 0.859974C7.87406 0.952138 7.92707 1.07828 7.92707 1.20997C7.92707 1.34166 7.87406 1.46781 7.78 1.55997L3.33 5.99997H19.5C19.7761 5.99997 20 6.22383 20 6.49997Z"
                      fill="white"
                    />
                  </svg>
                  <div className="px-1">Question précédente</div>
                </div>
              )}
              {validateClicked && (
                <div
                  className="btn-suiv"
                  onClick={() => {
                    resetState(true);
                  }}
                >
                  <div className="px-1">{`${(questions?.length && step < questions?.length - 1) ? "Question suivante" : "Afficher les résultats"}`}</div>
                  <svg
                    width="20"
                    height="14"
                    viewBox="0 0 20 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 6.5001V7.5001C0 7.77624 0.223858 8.0001 0.5 8.0001H16.67L12.22 12.4401C12.1253 12.534 12.0721 12.6618 12.0721 12.7951C12.0721 12.9284 12.1253 13.0562 12.22 13.1501L12.93 13.8501C13.0239 13.9448 13.1517 13.998 13.285 13.998C13.4183 13.998 13.5461 13.9448 13.64 13.8501L19.78 7.7201C19.9207 7.57958 19.9998 7.38894 20 7.1901V6.8101C19.9977 6.61168 19.9189 6.42182 19.78 6.2801L13.64 0.150096C13.5461 0.0554401 13.4183 0.00219727 13.285 0.00219727C13.1517 0.00219727 13.0239 0.0554401 12.93 0.150096L12.22 0.860096C12.1259 0.95226 12.0729 1.07841 12.0729 1.2101C12.0729 1.34179 12.1259 1.46793 12.22 1.5601L16.67 6.0001H0.5C0.223858 6.0001 0 6.22395 0 6.5001Z"
                      fill="white"
                    />
                  </svg>
                </div>
              )}
              {!validateClicked && (
                <div className="btn-suiv" onClick={validateAnswer}>
                  <div className="px-1">Valider mes réponses</div>
                </div>
              )}
            </div>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col
            md="12"
            lg="6"
            className="questions"
            style={{ padding: 70, height: "100%" }}
          >
            <div className="d-flex">
              <div className="question-number">
                {(step + 1).toString().padStart(2, "0")}
              </div>
              <div className="question-total">
                /{questionNumber.padStart(2, "0")}
              </div>
              <div style={{ paddingLeft: 10 }}>
                <svg
                  width="265"
                  height="4"
                  viewBox="0 0 265 4"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    opacity="0.3"
                    width={"100%"}
                    height="4"
                    rx="2"
                    fill="#ECF0FB"
                  />
                  <rect width={stepProgress} height="4" rx="2" fill="white" />
                </svg>
              </div>
            </div>
            <div style={{ marginTop: 40 }}>
              <div className="question">{question?.title}</div>
            </div>
          </Col>
          <Col className="answers-mobile">
            <div style={{ height: 100 }}>
              {question?.choice === "unique" && (
                <div
                  className="type"
                  style={{ color: "#8D8D8D", textAlign: "center" }}
                >
                  Une seule bonne réponse est attendu
                </div>
              )}
              {question?.choice === "multiple" && (
                <div
                  className="type"
                  style={{ color: "#8D8D8D", textAlign: "center" }}
                >
                  Plusieurs bonnes réponses sont attendues.{" "}
                </div>
              )}
            </div>
            <Col md="12" lg="6" className="answers-mobile">
              <div className="answer">
                {validateClicked && (
                  <div className="result">
                    <span className="label-grey-md-normal">Résultat</span>
                    {isCorrect && (
                      <span className="label-grey-md">
                        Bravo ! Vous avez trouvé la bonne réponse !
                      </span>
                    )}
                    {!isCorrect && correctAnswer === 0 && (
                      <span className="label-grey-md">
                        Oups, vous vous êtes trompée de réponse(s).
                      </span>
                    )}
                    {!isCorrect && correctAnswer > 0 && (
                      <span className="label-blue-md">
                        {correctAnswer} bonne réponse sur{" "}
                        {correctAnswers.length}
                      </span>
                    )}
                  </div>
                )}
                {question &&
                  question.answers
                    .filter((val) => val.title !== null)
                    .map((val, idx: number) => {
                      return (
                        (!validateClicked ||
                          (validateClicked &&
                            (selectedAnswer.includes(idx) ||
                              val.isCorrect))) && (
                          <div
                            key={idx}
                            className="answer-element"
                            style={elementStyle(idx)}
                            onClick={onSelectAnswer.bind(this, idx)}
                          >
                            {question.choice === "unique" && (
                              <div
                                className="answer-number"
                                style={numberStyle(idx)}
                              >
                                {" "}
                                {idx + 1}{" "}
                              </div>
                            )}
                            {question.choice === "multiple" && (
                              <Formik
                                initialValues={{
                                  checked: [],
                                }}
                                onSubmit={() => {}}
                              >
                                {({ values }) => (
                                  <Form>
                                    <Field
                                      type="checkbox"
                                      value={idx}
                                      className="ms-3 me-1 mt-3 choice"
                                      checked={selectedAnswer.includes(idx)}
                                      disabled={validateClicked}
                                      onChange={() => onSelectAnswer(idx)}
                                    />
                                  </Form>
                                )}
                              </Formik>
                            )}
                            <div className="answer-response">{val.title}</div>
                          </div>
                        )
                      );
                    })}
              </div>
              <div className="btn-answer-mobile">
                {validateClicked && step > 0 && (
                  <div
                    className="btn-prec"
                    onClick={() => {
                      resetState(false);
                    }}
                  >
                    <svg
                      width="20"
                      height="14"
                      viewBox="0 0 20 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20 6.49997V7.49997C20 7.77612 19.7761 7.99997 19.5 7.99997H3.33L7.78 12.44C7.87466 12.5339 7.9279 12.6617 7.9279 12.795C7.9279 12.9283 7.87466 13.0561 7.78 13.15L7.07 13.85C6.97612 13.9446 6.84832 13.9979 6.715 13.9979C6.58168 13.9979 6.45388 13.9446 6.36 13.85L0.22 7.71997C0.0793075 7.57946 0.000175052 7.38882 0 7.18997V6.80997C0.00230401 6.61156 0.081116 6.4217 0.22 6.27997L6.36 0.149974C6.45388 0.055318 6.58168 0.0020752 6.715 0.0020752C6.84832 0.0020752 6.97612 0.055318 7.07 0.149974L7.78 0.859974C7.87406 0.952138 7.92707 1.07828 7.92707 1.20997C7.92707 1.34166 7.87406 1.46781 7.78 1.55997L3.33 5.99997H19.5C19.7761 5.99997 20 6.22383 20 6.49997Z"
                        fill="white"
                      />
                    </svg>
                    <div className="px-1">Question précédente</div>
                  </div>
                )}
                {validateClicked && (
                  <div
                    className="btn-suiv"
                    onClick={() => {
                      resetState(true);
                    }}
                  >
                    <div className="px-1">{`${(questions?.length && step < questions?.length - 1) ? "Question suivante" : "Afficher les résultats"}`}</div>
                    <svg
                      width="20"
                      height="14"
                      viewBox="0 0 20 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0 6.5001V7.5001C0 7.77624 0.223858 8.0001 0.5 8.0001H16.67L12.22 12.4401C12.1253 12.534 12.0721 12.6618 12.0721 12.7951C12.0721 12.9284 12.1253 13.0562 12.22 13.1501L12.93 13.8501C13.0239 13.9448 13.1517 13.998 13.285 13.998C13.4183 13.998 13.5461 13.9448 13.64 13.8501L19.78 7.7201C19.9207 7.57958 19.9998 7.38894 20 7.1901V6.8101C19.9977 6.61168 19.9189 6.42182 19.78 6.2801L13.64 0.150096C13.5461 0.0554401 13.4183 0.00219727 13.285 0.00219727C13.1517 0.00219727 13.0239 0.0554401 12.93 0.150096L12.22 0.860096C12.1259 0.95226 12.0729 1.07841 12.0729 1.2101C12.0729 1.34179 12.1259 1.46793 12.22 1.5601L16.67 6.0001H0.5C0.223858 6.0001 0 6.22395 0 6.5001Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                )}
                {!validateClicked && (
                  <div className="btn-suiv" onClick={validateAnswer}>
                    <div className="px-1">Valider mes réponses</div>
                  </div>
                )}
              </div>
            </Col>
          </Col>
        </Row>
      )}
    </>
  ) : (
    <div
      className="container error-container"
      style={
        sumValues(correctAnswersState) * 2 >
          sumValues(totalCorrectAnswersState) &&
        data.sections.length <= indexSection + 1
          ? { position: "relative", padding: "2%" }
          : {}
      }
    >
      {/** 
        //@ts-ignore */}
      <NotificationAlert ref={notificationAlert} />
      <GamificationModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        data={finishData && finishData.popUpInformation}
      />
      <Col md="12">
        <Row className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
          <Col md="8">
            <Row>
              <Col>
                <Card
                  className="error-card"
                  style={
                    sumValues(correctAnswersState) * 2 >
                    sumValues(totalCorrectAnswersState)
                      ? { height: "48vh" }
                      : {}
                  }
                >
                  <div className="m-5 d-flex">
                    {sumValues(correctAnswersState) * 2 >
                    sumValues(totalCorrectAnswersState) ? (
                      <div className="score-success">
                        {sumValues(correctAnswersState)}
                      </div>
                    ) : (
                      <div className="score-echec">
                        {sumValues(correctAnswersState)}
                      </div>
                    )}
                    <div className="totale">
                      /{sumValues(totalCorrectAnswersState)}
                    </div>
                  </div>
                  {sumValues(correctAnswersState) * 2 >
                  sumValues(totalCorrectAnswersState) ? (
                    <h5 className="score-msg">
                      Félicitations ! Vous avez fait un super score ! 🎉
                    </h5>
                  ) : (
                    <h5 className="score-msg">
                      Oups, c’est un peu juste pour valider cette formation.
                    </h5>
                  )}
                  <Row className="d-flex flex-column align-items-center">
                    <Col
                      md="8"
                      className="d-flex flex-column align-items-center"
                    >
                      {sumValues(correctAnswersState) * 2 >
                      sumValues(totalCorrectAnswersState) ? (
                        <p className="label-grey-trainings-sm text-center">
                          Félicitations ! Vous validez une nouvelle formation.
                          Vous pouvez retrouver l’historique de vos formations
                          validées dans votre profil, dans la page “parcours de
                          formation”.
                        </p>
                      ) : (
                        <p className="label-grey-trainings-sm  text-center">
                          Vous n’avez pas obtenu la moyenne sur ce quizz. Vous
                          ne pouvez pas passé à l’étape suivante. Nous vous
                          conseillons de relire la formation et de repasser le
                          quizz plus tard.
                        </p>
                      )}
                    </Col>
                  </Row>
                  {(data.sections.length > indexSection + 1 ||
                    sumValues(correctAnswersState) * 2 <=
                      sumValues(totalCorrectAnswersState)) && (
                    <Row className="d-flex flex-column align-items-center">
                      <Col>
                        <Button
                          className="btn-round-sm-secondary mt-3 me-2"
                          onClick={() => history.push(path ? path : "/home")}
                          color="secondary"
                          type="submit"
                        >
                          {sumValues(correctAnswersState) * 2 >
                          sumValues(totalCorrectAnswersState)
                            ? "Continuer plus tard"
                            : "Quitter la formation"}
                        </Button>
                        <Button
                          className="btn-round-sm mt-3"
                          onClick={() => {
                            if (
                              sumValues(correctAnswersState) * 2 >
                              sumValues(totalCorrectAnswersState)
                            ) {
                              dispatch(
                                Evaluate({
                                  id: sectionData.sectionContent[indexcontent]
                                    .id,
                                  score: sumValues(correctAnswersState),
                                  total: sumValues(totalCorrectAnswersState),
                                })
                              );
                              dispatch(
                                trainingActions.updateQuizzResult({
                                  id: sectionData.sectionContent[indexcontent]
                                    .id,
                                  statut: "valid",
                                  correct: sumValues(correctAnswersState),
                                  total: sumValues(totalCorrectAnswersState),
                                })
                              );
                              if (
                                indexcontent + 1 <
                                  sectionData.sectionContent.length &&
                                data.progression.currentContent !== null
                              ) {
                                // const contentId = sectionData.sectionContent[sectionData.sectionContent.findIndex(cnt => cnt.id === data.progression.currentContent)].id;
                                dispatch(
                                  FinishContent({
                                    id: sectionData.sectionContent[indexcontent]
                                      .id,
                                  })
                                );
                              }
                            } else {
                              dispatch(
                                Evaluate({
                                  id: sectionData.sectionContent[indexcontent]
                                    .id,
                                  score: sumValues(correctAnswersState),
                                  total: sumValues(totalCorrectAnswersState),
                                })
                              );
                              dispatch(
                                trainingActions.updateQuizzResult({
                                  id: sectionData.sectionContent[indexcontent]
                                    .id,
                                  statut: "invalid",
                                  correct: sumValues(correctAnswersState),
                                  total: sumValues(totalCorrectAnswersState),
                                })
                              );
                            }

                            setContentType("content");
                          }}
                          color="primary"
                          type="submit"
                        >
                          {sumValues(correctAnswersState) * 2 >
                          sumValues(totalCorrectAnswersState)
                            ? "Continuer la formation"
                            : "Retourner à  la formation"}
                        </Button>
                      </Col>
                    </Row>
                  )}
                </Card>
              </Col>
            </Row>
            {sumValues(correctAnswersState) * 2 >
              sumValues(totalCorrectAnswersState) && (
              <>
                <Row className="pt-3">
                  {data.sections.length > indexSection + 1 && (
                    <>
                      <Col md="4">
                        <Card className="error-card">
                          <div className="m-5 d-flex">
                            <svg
                              width="50"
                              height="50"
                              viewBox="0 0 50 50"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle cx="25" cy="25" r="25" fill="#ECF0FB" />
                              <path
                                d="M25 15L27.2451 21.9098H34.5106L28.6327 26.1803L30.8779 33.0902L25 28.8197L19.1221 33.0902L21.3673 26.1803L15.4894 21.9098H22.7549L25 15Z"
                                fill="#3367CD"
                              />
                            </svg>
                          </div>
                          <Row className="d-flex flex-column align-items-center">
                            <Col
                              md="8"
                              className="d-flex flex-column align-items-center"
                            >
                              <p className="label-black-nqt-sm text-center">
                                Nouveau badge débloqué !
                              </p>
                            </Col>
                          </Row>
                          <Row className="d-flex flex-column align-items-center">
                            <Col className="d-flex flex-column align-items-center">
                              <p className="label-grey-trainings-sm text-center">
                                Total : 32 badges
                              </p>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                      <Col md="4">
                        <Card className="error-card">
                          <div className="m-5 d-flex">
                            <h5 className="score-msg">40</h5>
                          </div>
                          <Row className="d-flex flex-column align-items-center">
                            <Col
                              md="8"
                              className="d-flex flex-column align-items-center"
                            >
                              <p className="label-black-nqt-sm text-center">
                                Nouveaux points gagnés !
                              </p>
                            </Col>
                          </Row>
                          <Row className="d-flex flex-column align-items-center">
                            <Col className="d-flex flex-column align-items-center">
                              <p className="label-grey-trainings-sm text-center">
                                Total : 450pts
                              </p>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                      <Col md="4">
                        <Card className="error-card">
                          <div className="m-5 d-flex">
                            <svg
                              width="50"
                              height="50"
                              viewBox="0 0 50 50"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle cx="25" cy="25" r="25" fill="#ECF0FB" />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M24.9997 15.8333C22.6997 15.8333 20.833 17.7 20.833 20L21.4993 20L22.4163 20C22.4163 18.575 23.5747 17.4167 24.9997 17.4167C26.4247 17.4167 27.583 18.575 27.583 20V21.6667H19.9997C19.083 21.6667 18.333 22.4167 18.333 23.3333V31.6667C18.333 32.5833 19.083 33.3333 19.9997 33.3333H29.9997C30.9163 33.3333 31.6663 32.5833 31.6663 31.6667V23.3333C31.6663 22.4167 30.9163 21.6667 29.9997 21.6667H29.4998H29.1663V20C29.1663 17.7 27.2997 15.8333 24.9997 15.8333ZM23.333 27.5C23.333 28.4167 24.083 29.1667 24.9997 29.1667C25.9163 29.1667 26.6663 28.4167 26.6663 27.5C26.6663 26.5833 25.9163 25.8333 24.9997 25.8333C24.083 25.8333 23.333 26.5833 23.333 27.5Z"
                                fill="#3367CD"
                              />
                            </svg>
                          </div>
                          <Row className="d-flex flex-column align-items-center">
                            <Col
                              className="d-flex flex-column align-items-center"
                              md="8"
                            >
                              <p className="label-black-nqt-sm text-center">
                                Etape {indexSection + 1} débloqué !
                              </p>
                            </Col>
                          </Row>
                          <Row className="d-flex flex-column align-items-center">
                            <Col className="d-flex flex-column align-items-center">
                              <p className="label-grey-trainings-sm text-center">
                                Total : {data.sections.length} étapes
                              </p>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    </>
                  )}
                  {data.sections.length <= indexSection + 1 && (
                    <>
                      <Col md="8">
                      {finishData && finishData.popUpInformation &&<Card >
                         <Gamification
                            data={finishData.popUpInformation}
                          />
                        </Card>}
                      </Col>
                      <Col md="4">
                        <Card className="error-card">
                          <div className="m-5 d-flex">
                            <svg
                              width="50"
                              height="50"
                              viewBox="0 0 50 50"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle cx="25" cy="25" r="25" fill="#ECF0FB" />
                              <path
                                d="M20.8333 21.6665V19.1665L15 24.9998L20.8333 30.8332V28.3332L17.5 24.9998L20.8333 21.6665ZM25.8333 22.4998V19.1665L20 24.9998L25.8333 30.8332V27.4165C30 27.4165 32.9167 28.7498 35 31.6665C34.1667 27.4998 31.6667 23.3332 25.8333 22.4998Z"
                                fill="#3367CD"
                              />
                            </svg>
                          </div>
                          <Row className="d-flex flex-column align-items-center">
                            <Col
                              className="d-flex flex-column align-items-center"
                              md="8"
                            >
                              <p className="label-black-nqt-sm text-center">
                                Partagez votre résultat à votre communauté
                              </p>
                            </Col>
                          </Row>
                          <Row className="d-flex flex-column align-items-center">
                            <Col className="d-flex flex-column align-items-center">
                              <p
                                className="label-blue-md text-center pointer"
                                onClick={() => {
                                  setPostData({
                                    formation_id: data.id,
                                    note: `${sumValues(
                                      correctAnswersState
                                    )}/${sumValues(totalCorrectAnswersState)}`,
                                    clubs: [],
                                  });
                                  setModalShareShow(true);
                                }}
                              >
                                Partager
                              </p>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    </>
                  )}
                </Row>
                {data.sections.length <= indexSection + 1 && (
                  <>
                    {data.feedback?.enable && (
                      <Row className="pt-3">
                        <Col>
                          <Card className="error-card">
                            <Col
                              md="8"
                              className="d-flex flex-column align-items-center"
                            >
                              <div className="label-black-md text-center">
                                Faites savoir au créateur{" "}
                              </div>
                            </Col>

                            <Col
                              md="8"
                              className="d-flex flex-column align-items-center pt-2"
                            >
                              <div className="label-blue-md text-center">
                                ce que vous avez pensé de sa formation
                              </div>
                            </Col>

                            <Col
                              md="8"
                              className="d-flex flex-column align-items-center pt-3"
                            >
                              <div className="label-grey-trainings-sm  text-center">
                                Vous avez fait un super score ! Vous validez
                                donc cette formation.Partagez au créateur de
                                formation ce que vous avez pensé de sa
                                formation.
                              </div>
                            </Col>

                            {data.feedback.stars === true && (
                              <Col
                                md="8"
                                className="d-flex flex-column align-items-center pt-4"
                              >
                                <div className="d-flex">
                                  {getFeedBackIcons()}
                                </div>
                              </Col>
                            )}

                            {data.feedback.comments === true && (
                              <Col
                                md="8"
                                className="d-flex flex-column align-items-center pt-5"
                              >
                                <div>
                                  <textarea
                                    onChange={(event) =>
                                      setComment(event.target.value)
                                    }
                                    style={{ width: "100vh", height: "84px" }}
                                    placeholder="Laissez votre commentaire ici"
                                  ></textarea>
                                </div>
                              </Col>
                            )}

                            <Col
                              md="8"
                              className="d-flex flex-column align-items-center pt-5"
                            >
                              <div>
                                <Button
                                  className="btn-round-sm mt-3"
                                  color="primary"
                                  type="submit"
                                  onClick={sendFeedback}
                                >
                                  Envoyer les feedbacks
                                </Button>
                              </div>
                            </Col>
                          </Card>
                        </Col>
                      </Row>
                    )}
                    <Row className="pt-3">
                      <Col md="3" style={{ marginLeft: "auto" }}>
                        <Card
                          className="error-card"
                          onClick={() => {
                            dispatch(
                              Evaluate({
                                id: sectionData.sectionContent[indexcontent].id,
                                score: sumValues(correctAnswersState),
                                total: sumValues(totalCorrectAnswersState),
                              })
                            );
                            history.push(path ? path : "/home");
                          }}
                        >
                          <Col className="d-flex flex-column align-items-center">
                            <div className="label-blue-md text-center pointer">
                              Fermer et revenir à mon tableau de bord
                            </div>
                          </Col>
                        </Card>
                      </Col>
                    </Row>
                  </>
                )}
              </>
            )}
          </Col>
        </Row>
      </Col>
      <Modal
        show={modalShareShow}
        onHide={() => setModalShareShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton style={{ borderBottom: 0 }}>
          <Row>
            <div className="label-black-md">Partager Votre Accomplissement</div>
            <div className="label-grey">
              Vous pouver partager votre accomplissement sur les réseaux sociaux
              ou à vos communautés NQT
            </div>
          </Row>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column ">
          <Row>
            <div className="label-black-xmd">Partager à vos clubs</div>
            {!isExpanded.id ? (
              <div style={{ width: 200, cursor: "pointer" }}>
                {subjectElement}
              </div>
            ) : (
              <>
                <div
                  className="d-flex"
                  style={{ width: 200, cursor: "pointer" }}
                  onClick={() => setIsExpanded({})}
                >
                  <div>
                    <svg
                      width="8"
                      height="12"
                      viewBox="0 0 8 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.362413 5.27985C0.221721 5.42037 0.142588 5.61101 0.142413 5.80985V6.18985C0.144717 6.38827 0.223529 6.57813 0.362413 6.71985L5.50241 11.8499C5.5963 11.9445 5.72409 11.9978 5.85741 11.9978C5.99073 11.9978 6.11853 11.9445 6.21241 11.8499L6.92241 11.1399C7.01648 11.0477 7.06948 10.9215 7.06948 10.7899C7.06948 10.6582 7.01648 10.532 6.92241 10.4399L2.47241 5.99985L6.92241 1.55985C7.01707 1.46597 7.07031 1.33817 7.07031 1.20485C7.07031 1.07153 7.01707 0.943736 6.92241 0.849852L6.21241 0.149852C6.11853 0.055196 5.99073 0.00195312 5.85741 0.00195312C5.72409 0.00195312 5.5963 0.055196 5.50241 0.149852L0.362413 5.27985Z"
                        fill="#BDBDBD"
                      />
                    </svg>
                  </div>
                  <div className="filter-label-date">{isExpanded.libelle}</div>
                </div>
                <div>{contentsOptions}</div>
              </>
            )}
          </Row>
          <Row>
            <Grid
              className="cardDetailsDesc d-flex flex-row align-items-center p-2 ps-3"
              centered
              divided
              columns={3}
            >
              <div className="d-flex flex-column">
                <p className="label-black fw-bold">
                  Ou partager sur les réseaux sociaux
                </p>
                <div>
                  <TwitterShareButton
                    url={"https://nqt.fr/"}
                    title={"Twitter share test"}
                    className="mx-2 ms-0"
                  >
                    <div className="px-2 tweet">
                      <TweetWhiteIcon className="me-1" />
                      <TweetIcon />
                    </div>
                  </TwitterShareButton>
                  <LinkedinShareButton url={"https://nqt.fr/"}>
                    <div className="px-2 linkedin">
                      <RiLinkedinFill
                        color="white"
                        size={11}
                        className="me-1"
                      />
                      <ShareInIcon />
                    </div>
                  </LinkedinShareButton>
                </div>
              </div>
            </Grid>
          </Row>
          <Row className="d-flex flex-column align-items-center">
            <Col className="d-flex justify-content-end">
              <Button
                className="btn-round-sm mt-3"
                disabled={postData.clubs.length === 0}
                onClick={() => {
                  // share(postData);
                  setModalShareShow(false);

                  const jsonData: Post = {
                    id: null,
                    text: `Je viens de terminer une nouvelle formation ! 🎉<br>Nom de la formation: ${data.title}<br>Note finale: ${sumValues(
                        correctAnswersState)}/${sumValues(totalCorrectAnswersState)}`,
                    clubs: postData.clubs,
                    mentions: [],
                    hashtags: []
                  };
                  const formData = new FormData();
                  formData.append("data", JSON.stringify(jsonData));
                  
                  savePost(formData);
                }}
                color="primary"
                type="submit"
              >
                Poster
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default TrainingQuizz;
