import { useCallback, useEffect, useRef, useState } from "react";
import { Button, Col, Row, Spinner } from "reactstrap";
import { MentionsInput, Mention } from "react-mentions";
import NotificationAlert from "react-notification-alert";
import Actions from "./Actions";
import AudioPreview from "./AudioPreview";
import ClubList from "./ClubList";
import EmojiSelector from "./EmojiSelector";
import FilesPreview from "./FilesPreview";
import ImgPreview from "./ImgPreview";
import PostLinkPreview from "./LinkPreview";
import VideoPreview from "./VideoPreview";
import useHttp from "../../../../hooks/use-http";
import ClubNqtService from "../../../../services/clubnqt.service";
import { useAppSelector } from "../../../../redux/hooks";
import { selectUser } from "../../../../redux/slices/userSlice";
import GamificationModal from "../../../../components/GamificationModal";
import { ReactComponent as AvatarIcon } from "../../../../assets/img/icons/avatar-person.svg";
import ReactTooltip from "react-tooltip";

interface FileInfo {
  fileType: string;
  fileName: string;
  fileContentType: string;
  preview: string;
  file: any;
}
interface Post {
  id: string;
  text: string;
  mentions: string[];
  hashtags: string[];
  clubs: string[];
  lien?: string;
}
export function fetchMentions(query: string, callback: any) {
  if (!query) return;
  ClubNqtService.getCities(query)
    // Transform the users to what react-mentions expects
    .then((res: any) =>
      res.map((city: any) => {
        const transformedValue = city.name.includes(" ")
          ? city.name.split(" ").join("-")
          : city.name;
        return { display: transformedValue, id: city.name };
      })
    )
    .then(callback);
}
async function asyncTags(query: string, callback: any) {
  Promise.resolve([{ id: query, display: query }]).then(callback);
}

const subjects = [
  {
    id: 0,
    libelle: "Tous mes clubs",
    name: "Tous les clubs",
  },
  {
    id: 1,
    libelle: "Club national",
    name: "national",
  },
  {
    id: 2,
    libelle: "Club régional",
    name: "regional",
  },
  {
    id: 3,
    libelle: "Club entreprise",
    name: "entreprise",
  },
  {
    id: 4,
    libelle: "Club métier",
    name: "metier",
  },
];
const CreatePost = (props: any) => {
  const { retrievePosts, postToUpdate, setPostToUpdate } = props;
  const user = useAppSelector(selectUser);
  const [selectedFile, setSelectedFile] = useState<FileInfo[] | null>(null);
  const [selectedVideo, setSelectedVideo] = useState<FileInfo[] | null>(null);
  const [selectedAudio, setSelectedAudio] = useState<FileInfo[] | null>(null);
  const [selectedImg, setSelectedImg] = useState<FileInfo[] | null>(null);
  const [files, setFiles] = useState<FileInfo[] | null>(null);
  const [fileBlob, setFileBlob] = useState<File | null>(null);
  const [postContent, setPostContent] = useState("");
  const [linkContent, setLinkContent] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [clubList, setClubList] = useState<string[]>([]);
  const [showLink, setShowLink] = useState(false);
  const inputEl = useRef<HTMLInputElement>();
  const notificationAlert = useRef();
  const [count, setCount] = useState(0);

  const handleClubChange = useCallback((clubData: any) => {
    let clubs = clubData.clubs.map((id: number) => subjects[id].name);
    if (clubs.length === 5) {
      clubs = ["regional", "national", "entreprise", "metier"];
    }
    setClubList([...clubs, ...clubData.to]);
  }, []);

  const onFileChange = (event: any) => {
    const fileInfo = {
      fileType: event.target.files[0].name.split(".").pop(),
      fileName: event.target.files[0].name,
      fileContentType: event.target.files[0].type,
      preview: URL.createObjectURL(event.target.files[0]),
      file: event.target.files[0],
    };
    selectedFile
    ? setSelectedFile((prev) => {
        const files = prev ? [...prev] : [];
        files.push(fileInfo);
        return files;
      })
    : setSelectedFile([fileInfo]);
   setFileBlob(event.target.files[0]);
  };

  const onVideoChange = (event: any) => {
    const fileInfo = {
      fileType: event.target.files[0].name.split(".").pop(),
      fileName: event.target.files[0].name,
      fileContentType: event.target.files[0].type,
      preview: URL.createObjectURL(event.target.files[0]),
      file: event.target.files[0],
    };
    selectedVideo
    ? setSelectedVideo((prev) => {
        const files = prev ? [...prev] : [];
        files.push(fileInfo);
        return files;
      })
    : setSelectedVideo([fileInfo]);
    setFileBlob(event.target.files[0]);
  };
  const onAudioChange = (event: any) => {
    const fileInfo = {
      fileType: event.target.files[0].name.split(".").pop(),
      fileName: event.target.files[0].name,
      fileContentType: event.target.files[0].type,
      preview: URL.createObjectURL(event.target.files[0]),
      file: event.target.files[0],
    };
    selectedAudio
    ? setSelectedAudio((prev) => {
        const files = prev ? [...prev] : [];
        files.push(fileInfo);
        return files;
      })
    : setSelectedAudio([fileInfo]);
    setFileBlob(event.target.files[0]);
  };
  const onImgChange = (event: any) => {
    const fileInfo = {
      fileType: event.target.files[0].name.split(".").pop(),
      fileName: event.target.files[0].name,
      fileContentType: event.target.files[0].type,
      preview: URL.createObjectURL(event.target.files[0]),
      file: event.target.files[0],
    };
    selectedImg
    ? setSelectedImg((prev) => {
        const files = prev ? [...prev] : [];
        files.push(fileInfo);
        return files;
      })
    : setSelectedImg([fileInfo]);
    setFileBlob(event.target.files[0]);
  };

  useEffect(() => {
 
      const postImages =  selectedImg ? [...selectedImg] : []
      const postAudios =  selectedAudio ? [...selectedAudio] : [];
      const postFiles =  selectedFile ? [...selectedFile] : []; 
      const postVideos = selectedVideo ? [...selectedVideo] : [];
      setFiles((prev) => {
        const allFiles = prev ? [...prev] : [];
        return allFiles
          .concat(postImages)
          .concat(postAudios)
          .concat(postFiles)
          .concat(postVideos);
      });
    }, [selectedAudio, selectedFile, selectedImg, selectedVideo]);
  

  useEffect(() => {
    
  }, [files]);
  const handleContentChange = (event: any) => {
    setPostContent(event.target.value);
  };
  const handleLinkChange = (event: any) => {
    event.preventDefault();
    if (event.target.value.length > 10) {
      setLinkContent(event.target.value);
      setShowLink(false);
    }
  };
  const handleHashtag = () => {
    setPostContent((prev: string) => {
      const content = `${prev} #`;
      return content;
    });
    //@ts-ignore
    inputEl.current.focus();
  };
  const handleMention = () => {
    setPostContent((prev: string) => {
      const content = `${prev} @`;
      return content;
    });
    //@ts-ignore
    inputEl.current.focus();
  };

  const { sendRequest, error, data, status } = useHttp(ClubNqtService.savePost);
  const {
    sendRequest: updatePost,
    error: updateError,
    data: updateData,
    status: updateStatus,
  } = useHttp(ClubNqtService.editPost);

  async function savePost(e: any, toUpdate: boolean) {
    e.preventDefault();
    let newContent = postContent;
    const mentions = newContent
      .split(" ")
      .filter((content: string) => content.includes("@@@__"))
      .map((str: string) => {
        const splitted = str.split(".").join(" ").split("@@@__");
        const popedstr = splitted.pop();
        const popedlast = popedstr?.split("^^^__").pop();
        return popedlast !== undefined ? popedlast.split("@@@^^^")[0] : "";
      });
    const hashtags = newContent
      .split(" ")
      .filter((content: string) => content.includes("$$$__"))
      .map((str: string) => {
        const splitted = str.split("$$$__");
        const popedstr = splitted.pop();
        const popedlast = popedstr?.split("~~~__").pop();
        return popedlast !== undefined ? popedlast.split("$$$~~~")[0] : "";
      });
    newContent = newContent
      .split(" ")
      .map((val) => {
        if (val.includes("#") && !hashtags.includes(val)) {
          val.length > 0 && hashtags.push(val.split("#").join(""));
          return val
            .split("#")
            .join('<label className="hashtagText" id="hashtag">#')
            .concat("</label>");
        }
        return val;
      })
      .join(" ");
    newContent = newContent
      .split("@@@__")
      .join('<label className="mentionText" id="mention');
    newContent = newContent.split("^^^__").join(`">@`); // .split('.').join(' ');
    newContent = newContent.split("@@@^^^").join("</label>");

    newContent = newContent
      .split("$$$__")
      .join('<label className="hashtagText" id="hashtag');
    newContent = newContent.split("~~~__").join(`">#`);
    newContent = newContent.split("$$$~~~").join("</label>");
    if (newContent !== "") {
      const jsonData: Post = {
        id: postToUpdate?.id ? postToUpdate.id : null,
        text: newContent.trim().replace(/(?:\r\n|\r|\n)/g, "<br>"),
        clubs: clubList,
        mentions,
        hashtags,
      };
      if (linkContent) {
        jsonData.lien = linkContent;
      }
      const formData = new FormData();
      console.log("old variable files:",files);
      if (files !== null && files.length > 0) {
        // formData.append("file", files[0].file);
        formData.append("file", fileBlob as Blob,fileBlob?.name);
        formData.append("files[]",fileBlob as Blob,fileBlob?.name)
        console.log('formdata file:',formData.get("file"));
        console.log('formData files[]:',formData.get("files[]"));
        console.log("fileBlob:",fileBlob);
      }
      formData.append("data", JSON.stringify(jsonData));
      const promise=toUpdate ? updatePost(formData) : sendRequest(formData);
      // Reset the state of files
      promise.then(() => {
        // setFiles(null);
      })
      .catch((error:any) => {
        console.error(error)
      });
    }
  }
  useEffect(() => {
    if (status === "completed") {
      //@ts-ignore
      notificationAlert.current.notificationAlert({
        place: "tr",
        message:
          error === null ? (
            <div>
              {" "}
              <p>Post publié avec succès</p>
            </div>
          ) : (
            <div>{error}</div>
          ),
        type: error === null ? "success" : "danger",
        icon: "nc-icon nc-bell-55",
        closeButton: false,
        autoDismiss: 7,
      });
      error === null && setModalShow(true);
      setPostContent("");
      setSelectedAudio(null);
      setSelectedFile(null);
      setSelectedImg(null);
      setSelectedVideo(null);
      setLinkContent("");
      error === null && retrievePosts();
    }
  }, [status, error, retrievePosts]);

  useEffect(() => {
    if (updateStatus === "completed") {
      //@ts-ignore
      notificationAlert.current.notificationAlert({
        place: "tr",
        message:
          updateError === null ? (
            <div>
              {" "}
              <p>Post modifié avec succès</p>
            </div>
          ) : (
            <div>{updateError}</div>
          ),
        type: updateError === null ? "success" : "danger",
        icon: "nc-icon nc-bell-55",
        closeButton: false,
        autoDismiss: 7,
      });
      updateError === null && setModalShow(true);
      setPostContent("");
      setSelectedAudio(null);
      setSelectedFile(null);
      setSelectedImg(null);
      setSelectedVideo(null);
      setLinkContent("");
      updateError === null && retrievePosts() && setPostToUpdate(null);
    }
  }, [updateStatus, updateError, retrievePosts, setPostToUpdate]);

  useEffect(() => {
    if (postContent.length > 3100)
      setPostContent(postContent.substring(0, 3100));
    else setCount(postContent.length);
  }, [postContent]);

  useEffect(() => {
    if (postToUpdate?.text) {
      const str = postToUpdate?.text.replace(/<br\s*\/?>/gm, "\n");
      setPostContent(str.replace(/<\/?[^>]+(>|$)/g, ""));
    }
  }, [postToUpdate]);

  return (
    <>
      {/** 
        //@ts-ignore */}
      <NotificationAlert ref={notificationAlert} />
      <GamificationModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        data={data && data.popUpInformation}
      />
      <GamificationModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        data={updateData && updateData.popUpInformation}
      />
      <div className="post__container">
        <Row className="p-3 d-flex">
          <Col md="6" className="post__header">
            Rédiger un post
          </Col>
          <Col md="6">
            <div className="d-flex justify-content-end">
              <ClubList
                handleChange={handleClubChange}
                subjects={subjects}
                selectedClubs={postToUpdate?.clubs}
              />
            </div>
          </Col>
        </Row>
        <div className="p-3 d-flex">
          <Col md="1" className="pr-3">
            {user.image ? (
              <img alt="profile" className={"avatarHome"} src={user.image} />
            ) : (
              <AvatarIcon
                style={{ width: 30, height: 30, borderRadius: "50%" }}
              />
            )}
          </Col>
          <Col md="10" className="body__container">
            <MentionsInput
              className="post__body"
              placeholder="De quoi souhaitez-vous parler ?"
              onChange={handleContentChange}
              value={postContent}
              a11ySuggestionsListLabel={"Suggested Regions for mention"}
              //@ts-ignore
              inputRef={inputEl}
            >
              <Mention
                trigger="@"
                markup="@@@__^^^____display__@@@^^^"
                data={fetchMentions}
                appendSpaceOnAdd
                style={{ background: "#ECF0FB" }}
                displayTransform={(display) => {
                  return `@${display} `;
                }}
              />
              <Mention
                trigger="#"
                data={asyncTags}
                markup="$$$__~~~____display__$$$~~~"
                style={{
                  backgroundColor: "#daf4fa",
                }}
                displayTransform={(display) => `#${display} `}
                appendSpaceOnAdd
              />
            </MentionsInput>
            <p className="label-grey-normal opacity-75 m-0">{`${
              3100 - count
            } caractères restants`}</p>
            <span className="mention" onClick={handleMention}>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.48021 0.353358C3.42021 0.0666917 0.0668748 3.42002 0.353542 7.48003C0.593542 11.0067 3.67354 13.6667 7.20687 13.6667H9.66687C10.0335 13.6667 10.3335 13.3667 10.3335 13C10.3335 12.6334 10.0335 12.3334 9.66687 12.3334H7.22021C4.73354 12.3334 2.45354 10.72 1.83354 8.31336C0.840208 4.44669 4.44021 0.840025 8.30687 1.84003C10.7202 2.45336 12.3335 4.73336 12.3335 7.22003V7.95336C12.3335 8.48003 11.8602 9.00002 11.3335 9.00002C10.8069 9.00002 10.3335 8.48003 10.3335 7.95336V7.12003C10.3335 5.44669 9.14687 3.94003 7.49354 3.70669C5.22687 3.38003 3.31354 5.34002 3.72021 7.62003C3.94687 8.89336 4.94021 9.94669 6.20021 10.2467C7.42688 10.5334 8.59354 10.14 9.36021 9.36003C9.95354 10.1734 11.1402 10.6 12.2269 10.1667C13.1202 9.81336 13.6669 8.90003 13.6669 7.94002V7.21336C13.6669 3.67336 11.0069 0.593358 7.48021 0.353358ZM7.00021 9.00002C5.89354 9.00002 5.00021 8.10669 5.00021 7.00002C5.00021 5.89336 5.89354 5.00003 7.00021 5.00003C8.10687 5.00003 9.00021 5.89336 9.00021 7.00002C9.00021 8.10669 8.10687 9.00002 7.00021 9.00002Z"
                  fill="#8D8D8D"
                />
              </svg>
            </span>
            <EmojiSelector setPostContent={setPostContent} />
            <span className="hashtag" onClick={handleHashtag}>
              <svg
                width="12"
                height="11"
                viewBox="0 0 12 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.584 4.52H3.632L3.088 7.08H0.8V8.296H2.816L2.24 11H3.584L4.16 8.296H6.432L5.856 11H7.2L7.776 8.296H10.224V7.08H8.048L8.592 4.52H11.008V3.304H8.848L9.424 0.632H8.08L7.504 3.304H5.232L5.808 0.632H4.464L3.888 3.304H1.584V4.52ZM4.976 4.52H7.248L6.704 7.08H4.432L4.976 4.52Z"
                  fill="#8D8D8D"
                />
              </svg>
            </span>
            {selectedFile && selectedFile.length > 0 && (
              <FilesPreview
                selectedFile={selectedFile}
                setSelectedFile={setSelectedFile}
              />
            )}
            {selectedVideo && selectedVideo.length > 0 && (
              <VideoPreview
                selectedVideo={selectedVideo}
                setSelectedVideo={setSelectedVideo}
              />
            )}
            {selectedAudio && selectedAudio.length > 0 && (
              <AudioPreview
                selectedAudio={selectedAudio}
                setSelectedAudio={setSelectedAudio}
              />
            )}
            {selectedImg && selectedImg.length > 0 && (
              <ImgPreview
                selectedImg={selectedImg}
                setSelectedImg={setSelectedImg}
              />
            )}
            {showLink === true && linkContent === "" && (
              <div className="link-container d-flex">
                <input
                  placeholder="Coller le lien url"
                  onChange={handleLinkChange}
                  value={linkContent}
                ></input>
                <span>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.3334 4.66663H9.33337C8.96671 4.66663 8.66671 4.96663 8.66671 5.33329C8.66671 5.69996 8.96671 5.99996 9.33337 5.99996H11.3334C12.4334 5.99996 13.3334 6.89996 13.3334 7.99996C13.3334 9.09996 12.4334 9.99996 11.3334 9.99996H9.33337C8.96671 9.99996 8.66671 10.3 8.66671 10.6666C8.66671 11.0333 8.96671 11.3333 9.33337 11.3333H11.3334C13.1734 11.3333 14.6667 9.83996 14.6667 7.99996C14.6667 6.15996 13.1734 4.66663 11.3334 4.66663ZM5.33337 7.99996C5.33337 8.36663 5.63337 8.66663 6.00004 8.66663H10C10.3667 8.66663 10.6667 8.36663 10.6667 7.99996C10.6667 7.63329 10.3667 7.33329 10 7.33329H6.00004C5.63337 7.33329 5.33337 7.63329 5.33337 7.99996ZM6.66671 9.99996H4.66671C3.56671 9.99996 2.66671 9.09996 2.66671 7.99996C2.66671 6.89996 3.56671 5.99996 4.66671 5.99996H6.66671C7.03337 5.99996 7.33337 5.69996 7.33337 5.33329C7.33337 4.96663 7.03337 4.66663 6.66671 4.66663H4.66671C2.82671 4.66663 1.33337 6.15996 1.33337 7.99996C1.33337 9.83996 2.82671 11.3333 4.66671 11.3333H6.66671C7.03337 11.3333 7.33337 11.0333 7.33337 10.6666C7.33337 10.3 7.03337 9.99996 6.66671 9.99996Z"
                      fill="#8D8D8D"
                    />
                  </svg>
                </span>

                <div
                  data-tip="L'url doit être copiée collée"
                  className="info-link"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.99996 17.3333C4.39996 17.3333 0.666626 13.5999 0.666626 8.99992C0.666626 4.39992 4.39996 0.666584 8.99996 0.666584C13.6 0.666584 17.3333 4.39992 17.3333 8.99992C17.3333 13.5999 13.6 17.3333 8.99996 17.3333ZM9.83329 4.83325H8.16663V6.49992H9.83329V4.83325ZM9.83329 8.16658H8.16663V13.1666H9.83329V8.16658Z"
                      fill="#BDBDBD"
                    />
                  </svg>
                </div>
                <ReactTooltip />
              </div>
            )}
            {linkContent !== "" && (
              <PostLinkPreview
                linkContent={linkContent}
                setLinkContent={setLinkContent}
                readOnly={false}
              />
            )}
            <Row>
              <Col md="8">
                {linkContent === "" &&
                  (selectedAudio === null || selectedAudio?.length === 0) &&
                  (selectedVideo === null || selectedVideo?.length === 0) &&
                  (selectedFile === null || selectedFile?.length === 0) &&
                  (selectedImg === null || selectedImg?.length === 0) && (
                    <Actions
                      onFileChange={onFileChange}
                      onVideoChange={onVideoChange}
                      onAudioChange={onAudioChange}
                      onImgChange={onImgChange}
                      setShowLink={setShowLink}

                    />
                  )}
              </Col>
              <Col md="4" className="pt-2 justify-content-end">
                {postToUpdate ? (
                  <div className="d-flex flex-row justify-content-end">
                    <Button
                      className="btn-round-sm ms-2 validate mt-0"
                      style={{ display: "flex" }}
                      color="primary"
                      onClick={async (event) => savePost(event, true)}
                      disabled={postContent === "" || clubList.length === 0}
                    >
                      {updateStatus === "pending" && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="me-1"
                        />
                      )}
                      Modifier
                    </Button>
                    <Button
                      className="btn-round-sm ms-2 validate mt-0"
                      color="primary"
                      onClick={() => setPostToUpdate(null)}
                    >
                      Annuler
                    </Button>
                  </div>
                ) : (
                  <Button
                    className="btn-round-sm ms-2 validate mt-0"
                    style={{ float: "right" }}
                    color="primary"
                    onClick={async (event) => savePost(event, false)}
                    disabled={postContent === "" || clubList.length === 0}
                  >
                    {status === "pending" && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="me-1"
                      />
                    )}
                    Poster
                  </Button>
                )}
              </Col>
            </Row>
          </Col>
        </div>
      </div>
    </>
  );
};

export default CreatePost;
