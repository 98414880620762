import { useMedia } from "react-media";
import { Row } from "reactstrap";
import { useAppDispatch } from "../../../redux/hooks";
import EditorNonPublishedTrainings from "./components/EditorNonPublishedTrainings";
import EditorPublishedTrainings from "./components/EditorPublishedTrainings";
import EditorSyncTrainings from "./components/EditorSyncTrainings";
import EditorUnpublishedTrainings from "./components/EditorUnpublishedTraining";

const EditorTrainingsContainer = (props: any) => {
    const GLOBAL_MEDIA_QUERIES = {
        small: "(max-width: 1100px)",
        large: "(min-width: 1101px)",
    };

    const dispatch = useAppDispatch();
    const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
    const { activeLink } = props;
    return (
        <Row>
            {activeLink === 2 ?
                <EditorPublishedTrainings scrollable={matches.small} dispatch={dispatch} />
                : activeLink === 3 ?
                    <EditorNonPublishedTrainings scrollable={matches.small} dispatch={dispatch} />:
                    activeLink === 5 ?
                        <EditorSyncTrainings scrollable={matches.small} dispatch={dispatch} />:
                        activeLink === 6 ?
                            <EditorUnpublishedTrainings scrollable={matches.small} dispatch={dispatch} />
                                : null
            }
        </Row>
    );
};

export default EditorTrainingsContainer;
