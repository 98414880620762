import Switch from "react-switch";

const SwitchButton = (props: any) => {
  //Init props
  const {
    value,
    firstLabel,
    secondLabel,
    firstLabelClassName,
    secondLabelClassName,
  } = props;

  const switchAttribute = {...props};
  delete switchAttribute.firstLabelClassName;
  delete switchAttribute.secondLabelClassName;
  delete switchAttribute.firstLabel;
  delete switchAttribute.secondLabel;
  //Init states

  return (
    <div className="d-flex flex-row">
      <label className={firstLabelClassName}>{firstLabel}</label>
      <Switch
        checked={value}
        checkedIcon={false}
        uncheckedIcon={false}
        width={20}
        height={10}
        className="mt-1"
        handleDiameter={6}
        onColor="#3367cd"
        offColor="#3367cd"
        {...switchAttribute}
      />
      <label className={secondLabelClassName}>{secondLabel}</label>
    </div>
  );
};
export default SwitchButton;
