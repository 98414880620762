const ErrorContainer = () => {
  return (
    <div className="container error-container">
        <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center row">
          <div className="col-md-8">
            <div className="error-card card">
              <img
                src={require("../assets/img/error.png").default}
                alt="react-logo"
                className="mb-4 error-img"
              />
              <h5 className="label-black-lg text-center">Oups ! 👀</h5>
              <h5 className="label-black-lg text-center">
                Nous avons rencontré une erreur !
              </h5>
              <div className="d-flex flex-column align-items-center row">
                <div  className="d-flex flex-column align-items-center col-md-8">
                  <p className="label-black-nqt-sm text-center">
                    Nous rencontrons un problème pour retrouver la page
                    demandée. Réessayez plus tard!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
};
export default ErrorContainer;
