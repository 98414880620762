import { Row, Col, Button } from "reactstrap";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import { MdDownload } from "react-icons/md";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import SwiperCore, { Pagination } from "swiper";
import collaborer from "../../../../assets/img/collaborer.png";
import résauter from "../../../../assets/img/réseauter.png";
import enrichir from "../../../../assets/img/enrichir.png";
import "swiper/swiper.min.css";
// Pagination module
import "swiper/modules/pagination/pagination.scss";


SwiperCore.use([Pagination]);

const FirstStep = (props: any) => {
  //Init props
  const { handlePagination } = props;

  return (
    <Row>
      <Col md="12">
        <div className="feed">
          <Row className="center-container">
            <Col md="9">
              <p className="label-black-xlg">Premiers pas dans le Club NQT</p>
            </Col>
          </Row>
          <Row className="center-container pt-80">
            <Col md="3">
              <img src={collaborer} alt={"error collaborer"} className="first-step-image"/>
            </Col>
            <Col md="3">
              <img
                className="first-step-image"
                alt={"error réseauter"}
                src={résauter}
              />
            </Col>
            <Col md="3">
              <img
                  className="first-step-image"
                  alt={"error enrichir"}
                  src={enrichir}
              />
            </Col>
          </Row>
          <Row className="center-container mt-5">
            <Col md="3">
              <p className="first-step-title">
                Appartenir à une
                <p className="title-primary p-0 m-0">
                  communauté d'entraide <br />
                </p>
                entre mentors.
              </p>
              <p className="label-black-nqt-sm">
                Entrez dans un espace de partage dans lequel vous pourrez
                participer à différents clubs, consulter des ressources et en
                proposer afin d’améliorer l’efficience de vos mentorats.
              </p>
            </Col>
            <Col md="3">
              <p className="first-step-title">
                Être une
                <span className="title-primary p-0 m-0 px-2">
                  ressource de <br /> connaissance
                </span><br />
                pour les autres mentors.
              </p>
              <p className="label-black-nqt-sm">
                Partagez votre expertise et vos bonnes pratiques de mentorat
                avec l’ensemble de la communauté des mentors tout en développant
                votre réseau individuel ! 
              </p>
            </Col>
            <Col md="3">
              <p className="first-step-title">
                Se
                <span className="title-primary p-0 m-0 px-2">
                  former <br />
                </span>
                régulièrement pour <br /> améliorer son mentorat.
              </p>
              <p className="label-black-nqt-sm">
                Accédez à des contenus et outils méthodologiques créés par des
                mentors, pour des mentors et décuplez l’efficience de vos
                accompagnements ! 
              </p>
            </Col>
          </Row>
        </div>

        <div className="slider">
          <Row className="mt-20 center-container slider">
            <Col md="8">
              <Swiper
                slidesPerView={1}
                spaceBetween={30}
                keyboard={{
                  enabled: true,
                }}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
              >
                <SwiperSlide>
                  <Col md="12">
                    <Row>
                      <img src={collaborer} alt={"error collaborer"} className="first-step-image"/>
                    </Row>
                    <Row className="mt-40 pb-3">
                      <p className="first-step-title">
                        Appartenir à une
                        <p className="title-primary p-0 m-0">
                          communauté d'entraide <br />
                        </p>
                        entre mentors.
                      </p>
                      <p className="label-black-nqt-sm">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Magni et, suscipit illo maiores adipisci nostrum sunt
                        quis tenetur ipsa, amet temporibus praesentium quasi
                        ratione laudantium, magnam vel laboriosam! Soluta,
                        possimus?
                      </p>
                    </Row>
                  </Col>
                </SwiperSlide>
                <SwiperSlide>
                  <Col md="12">
                    <Row>
                      <img
                          className="first-step-image"
                          alt={"error réseauter"}
                          src={résauter}
                      />
                    </Row>
                    <Row className="mt-40 pb-3">
                      <p className="first-step-title">
                        Être une
                        <span className="title-primary p-0 m-0 px-2">
                          ressource de <br /> connaissance
                        </span>
                        pour les <br /> autres mentors.
                      </p>
                      <p className="label-black-nqt-sm">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Magni et, suscipit illo maiores adipisci nostrum sunt
                        quis tenetur ipsa, amet temporibus praesentium quasi
                        ratione laudantium, magnam vel laboriosam! Soluta,
                        possimus?
                      </p>
                    </Row>
                  </Col>
                </SwiperSlide>
                <SwiperSlide>
                  <Col md="12">
                    <Row>
                      <img
                          className="first-step-image"
                          alt={"error enrichir"}
                          src={enrichir}
                      />
                    </Row>
                    <Row className="mt-40 pb-3">
                      <p className="first-step-title">
                        Être
                        <span className="title-primary p-0 m-0 px-2">
                          motivé à se former <br />
                        </span>
                        régulièrement pour <br /> améliorer son mentorat.
                      </p>
                      <p className="label-black-nqt-sm">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Magni et, suscipit illo maiores adipisci nostrum sunt
                        quis tenetur ipsa, amet temporibus praesentium quasi
                        ratione laudantium, magnam vel laboriosam! Soluta,
                        possimus?
                      </p>
                    </Row>
                  </Col>
                </SwiperSlide>
              </Swiper>
            </Col>
          </Row>
        </div>
        <Row className="mtp-6 center-container">
          <Col md="9">
            <hr className="hr-text"></hr>
          </Col>
        </Row>

        <Row className="mt-20 center-container">
          <Col md="8">
            <div className="d-flex flex-row download">
              <BsFillFileEarmarkPdfFill size={40} className="upload-icon" />
              <div className="d-flex flex-column">
                <p className="mb-0 ms-3 label-black-nqt-sm">
                  Guide premiers pas dans le Club
                </p>
                <p className="mt-0 ms-3 label-grey-normal">
                  https://www.nqt.fr
                </p>
              </div>
              <div className="d-flex flex-row align-items-center">
                <a
                    href= {"/GuideMentor.pdf" }
                  download
                >
                  <MdDownload className="align-middle ms-5" size={17} />
                </a>
              </div>
            </div>
          </Col>
          <Col md="1">
            <Button
              className="btn-round-sm"
              onClick={() => handlePagination(2)}
              color="primary"
              type="submit"
            >
              Commencer
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default FirstStep;
