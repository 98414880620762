import { Fragment } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { useAppSelector } from "../../../../../redux/hooks";
import { selectUser } from "../../../../../redux/slices/userSlice";
import { ReactComponent as AvatarIcon } from '../../../../../assets/img/icons/avatar-person.svg'


const ProfileHeaderMobile = () => {
  const profile = useAppSelector(selectUser);
  return (
    <div className="center-container row mb-4">
      <Col md="10" xs="12">
          <Fragment>
            <Row className="mt-3">
              <Col xs="12">
                <div className="title-avatar">
                  <div>
                    <span className="name">Bonjour {profile.prenom}</span>,
                    afin de vous intégrer au Club NQT,nous aimerions en savoir
                    un peu plus sur vous.
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="center-container mb-4">
              <Col xs="12">
                <Card body color="light" outline className="card p-0">
                  <CardBody className="d-flex">
                    <Col xs="1" className="avatarContainer">
                    {profile.image ? (
                      <img
                        alt="profile"
                        className="avatar-profile"
                        src={profile.image}
                      />
                    ) : (
                      <AvatarIcon
                        style={{ width: 30, height: 30, borderRadius: '50%' }}
                      />
                    )}
                    </Col>
                    <Col xs="11">
                      <Row className="center-container">
                        <Col xs="10">
                        <p
                            className="label-black-md"
                            style={{ display: "contents" }}
                          >
                            {profile.prenom} {profile.nom}
                          </p>
                        </Col>
                      </Row>
                      <Row className="center-container">
                        <Col xs="1">
                          <svg
                            width="13"
                            height="14"
                            viewBox="0 0 13 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.875 1.875H8.2625C8 1.15 7.3125 0.625 6.5 0.625C5.6875 0.625 5 1.15 4.7375 1.875H2.125C1.4375 1.875 0.875 2.4375 0.875 3.125V11.875C0.875 12.5625 1.4375 13.125 2.125 13.125H10.875C11.5625 13.125 12.125 12.5625 12.125 11.875V3.125C12.125 2.4375 11.5625 1.875 10.875 1.875ZM6.5 1.875C6.84375 1.875 7.125 2.15625 7.125 2.5C7.125 2.84375 6.84375 3.125 6.5 3.125C6.15625 3.125 5.875 2.84375 5.875 2.5C5.875 2.15625 6.15625 1.875 6.5 1.875ZM6.5 4.375C7.5375 4.375 8.375 5.2125 8.375 6.25C8.375 7.2875 7.5375 8.125 6.5 8.125C5.4625 8.125 4.625 7.2875 4.625 6.25C4.625 5.2125 5.4625 4.375 6.5 4.375ZM10.25 11.875H2.75V11C2.75 9.75 5.25 9.0625 6.5 9.0625C7.75 9.0625 10.25 9.75 10.25 11V11.875Z"
                              fill="#3367CD"
                            />
                          </svg>
                        </Col>
                        <Col xs="9">
                          <p
                            className="label-black-nqt-sm"
                            style={{ display: "contents" }}
                          >
                            Mentor depuis {profile.depuis}
                          </p>
                        </Col>
                      </Row>
                      <Row className="center-container">
                        <Col xs="1">
                          <svg
                            width="9"
                            height="13"
                            viewBox="0 0 9 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4.5 0.25C2.08125 0.25 0.125 2.20625 0.125 4.625C0.125 7.23125 2.8875 10.825 4.025 12.1938C4.275 12.4938 4.73125 12.4938 4.98125 12.1938C6.1125 10.825 8.875 7.23125 8.875 4.625C8.875 2.20625 6.91875 0.25 4.5 0.25ZM4.5 6.1875C3.6375 6.1875 2.9375 5.4875 2.9375 4.625C2.9375 3.7625 3.6375 3.0625 4.5 3.0625C5.3625 3.0625 6.0625 3.7625 6.0625 4.625C6.0625 5.4875 5.3625 6.1875 4.5 6.1875Z"
                              fill="#3367CD"
                            />
                          </svg>
                        </Col>
                        <Col xs="9">
                          <p
                            className="label-black-nqt-sm"
                            style={{ display: "contents" }}
                          >
                            {profile.region}, {profile.pays}
                          </p>
                        </Col>
                      </Row>
                      <Row className="center-container">
                        <Col xs="1">
                          <svg
                            width="13"
                            height="15"
                            viewBox="0 0 13 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.6875 7.7501C10.5125 7.6501 10.3313 7.56885 10.15 7.5001C10.3313 7.43135 10.5125 7.3501 10.6875 7.2501C11.8875 6.55635 12.5563 5.3001 12.5625 4.00635C11.4438 3.3626 10.0188 3.3126 8.8125 4.00635C8.6375 4.10635 8.475 4.2251 8.325 4.34385C8.35625 4.1501 8.375 3.9501 8.375 3.7501C8.375 2.3626 7.61875 1.15635 6.5 0.506348C5.38125 1.15635 4.625 2.3626 4.625 3.7501C4.625 3.9501 4.64375 4.1501 4.675 4.34385C4.525 4.21885 4.3625 4.1001 4.1875 4.0001C2.9875 3.30635 1.5625 3.35635 0.4375 4.0001C0.4375 5.29385 1.10625 6.5501 2.3125 7.24385C2.4875 7.34385 2.66875 7.4251 2.85 7.49385C2.66875 7.5626 2.4875 7.64385 2.3125 7.74385C1.1125 8.4376 0.44375 9.69385 0.4375 10.9876C1.55625 11.6313 2.98125 11.6813 4.1875 10.9876C4.3625 10.8876 4.525 10.7688 4.675 10.6501C4.64375 10.8501 4.625 11.0501 4.625 11.2501C4.625 12.6376 5.38125 13.8438 6.5 14.4938C7.61875 13.8438 8.375 12.6376 8.375 11.2501C8.375 11.0501 8.35625 10.8501 8.325 10.6563C8.475 10.7813 8.6375 10.8938 8.8125 10.9938C10.0125 11.6876 11.4375 11.6376 12.5625 10.9938C12.5563 9.7001 11.8875 8.44385 10.6875 7.7501ZM6.5 10.0001C5.11875 10.0001 4 8.88135 4 7.5001C4 6.11885 5.11875 5.0001 6.5 5.0001C7.88125 5.0001 9 6.11885 9 7.5001C9 8.88135 7.88125 10.0001 6.5 10.0001Z"
                              fill="#3367CD"
                            />
                          </svg>
                        </Col>
                        <Col xs="9">
                          <p
                            className="label-black-nqt-sm"
                            style={{ display: "contents" }}
                          >
                            {profile.age}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Fragment>
      </Col>
    </div>
  );
};

export default ProfileHeaderMobile;
