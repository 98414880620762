import { Button, Col, Row, Spinner } from "reactstrap";
import {Form, Formik} from "formik";
import * as yup from "yup";
import { useMedia } from "react-media";
import NotificationAlert from "react-notification-alert";
import { useHistory, useParams } from "react-router";
import { useEffect, useRef, useState } from "react";
import Introduction from "./components/Introduction";
import AsyncSection from "./components/AsyncSection";
import SyncEvent from "./components/SyncEvent";
import { GLOBAL_MEDIA_QUERIES, training } from "../../../utils/constant";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectUser } from "../../../redux/slices/userSlice";
import {
  postTraining,
  selectLoading,
  selectTargetRoute,
  trainingActions,
  selectTrainingPreviewDetail,
} from "../../../redux/slices/trainingSlice";
import useHttp from "../../../hooks/use-http";
import TrainingService from "../../../services/training.services";
import ValidationProcess from "./components/ValidationProcess";
import TrainingFeedback from "./components/TrainingFeedback";
import Layout from "../../../components/Layout";
import TrainingModal from "./components/TrainingModal";
import AssignToMentor from "./components/AssignToMentor";

const ConditionalWrapper = ({ condition, wrapper, children }: any) =>
  condition ? children : wrapper(children);

const TrainingContainer = (props: any) => {
  const { layoutDisplayed } = props;
  //Init responsive width
  const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });

  //Init history route
  const history = useHistory();
  const { trainingId } = useParams<any>();
  //Init component states
  const [isSubjectValid, setIsSubjectValid] = useState(false);
  const valid = useRef(true);

  //Init states from selector
  const user: any = useAppSelector(selectUser);
  const userRole: any = user.role;
  const isEditor: any = userRole.includes("EDITOR");
  const isEditorAlumni: any = userRole.includes("EDITEUR-ALUMNI");
  const isNqtCollaborator = userRole.includes("NQT_COLLABORATOR");
  const path = isEditor ? "/editor" : (isNqtCollaborator ? "/collaborateur" : "");

  const status: any = useAppSelector(selectLoading);
  const [verify, setVerify] = useState(false);
  //Init dispatch
  const dispatch = useAppDispatch();
  const notificationAlert = useRef();

  const trainingEditWithoutId = useAppSelector(selectTrainingPreviewDetail);
  const targetRoute = useAppSelector(selectTargetRoute);
  const ref = useRef(null);
  const [val, setVal] = useState(training);
  const syncTypology = useRef(false);

  const handleSync = (index:any) => {
    syncTypology.current = index;
  }
  useEffect(() => {
    if (!trainingId) {
      dispatch(trainingActions.setTraining(val));
    }
  }, [val]);

 /* useEffect(() => {
    if(!isEditor && !isEditorAlumni && trainingId) {
      history.push('/home')
    }
  }, [history, isEditor, isEditorAlumni]);*/
  
  /**
   * Submit form
   * @param values
   */
  const onSubmit = (val: any) => {
    const values = JSON.parse(JSON.stringify(val));
    values.description = values.description.trim().replace(/(?:\r\n|\r|\n)/g, '<br>');
    values.sections.map((section: any) => {
      values.duration = (+values.duration + +section.sectionDuration).toString();
      section.sectionContent.map((content: any) =>
        content.type === "eval"
          ? content.autoEval
            ? content.themes.map((theme: any) =>
                theme.topics.map(
                  (topic: any, key: number) =>
                    topic.title === "" && theme.topics.splice(key, 1)
                )
              )
            : content.questions.map((question: any) =>
                question.answers.map(
                  (answer: any, key: number) =>
                    answer.title === "" && question.answers.splice(key, 1)
                )
              )
          : null
      );
      if(section.sectionDescription) {
        section.sectionDescription = section?.sectionDescription.trim().replace(/(?:\r\n|\r|\n)/g, '<br>');
      }
      return section;
    });
    dispatch(
      postTraining({
        values,
        history,
        notificationAlert,
        tag: trainingId ? "edit" : "create",
      })
    ).then((result) => {
      const payload:any = result.payload;
      if (result.type === "training/create/fulfilled" && !verify) {
        dispatch(trainingActions.setTraining(training));
        history.push("/" + targetRoute, {
          fromTraining: true,
          draft: values.status === "draft",
          edit: trainingId ? true : false,
        });
      } else if (result.type === "training/create/fulfilled" && verify) {
        dispatch(trainingActions.setTraining(training));
        if(isEditor || isNqtCollaborator) {
          history.push(path, {
            fromTraining: true,
            draft: values.status === "draft",
            edit: trainingId ? true : false,
            route: "Mon tableau de bord",
            published: payload.trainings.status === "published"
          });
        } else {
          history.push("/home", {
            fromTraining: true,
            draft: values.status === "draft",
            edit: trainingId ? true : false,
          });
        }
      }
    });
  };

  //Retrieve created training
  const {
    status: loading,
    sendRequest,
    data: trainingData,
  } = useHttp(TrainingService.getTraining, true);

  //Retrieve validation process
  const {
    status: reviewLoading,
    sendRequest: sendReviewRequest,
    data: reviewData,
  } = useHttp(TrainingService.getTrainingReview, true);

  //Init training edit data
  const trainingEdit = {
    ...trainingData,
    eventDetails:
      trainingData && trainingData.typology === "sync"
        ? trainingData.eventDetails
        : training.eventDetails,
    sections:
      trainingData && trainingData.typology === "async"
        ? trainingData.sections
        : training.sections,
    process: reviewData,
  };

  const verifySections = (index: any) => {
    for (let i = 0; i < index.sections.length; i++) {
      if (
        index.sections[i].sectionTitle == "" ||
        index.sections[i].sectionDuration == ""
      ) {
        return false;
      }
    }
    return true;
  };
  const validation = (values: any) => {
    values.typology == "async" &&
    values.title &&
    values.description &&
    isSubjectValid &&
    verifySections(values)
      ? (valid.current = false)
      : values.typology == "sync" &&
        values.title &&
        values.description &&
        isSubjectValid
      ? (valid.current = false)
      : (valid.current = true);
  };

  useEffect(() => {
    if (trainingId) {
      sendRequest(trainingId);
      sendReviewRequest(trainingId);
    }
    return () => {
      const state:any = history.location.state;
      if (state && state.fromTraining) {
        const stateCopy = { ...state };
        stateCopy.fromTraining = false;
        history.replace({ state: stateCopy });
      }
    }
  }, [trainingId, sendRequest, sendReviewRequest]);

  return (
    <ConditionalWrapper
      condition={layoutDisplayed}
      wrapper={(children: any) => <Layout title={`${trainingId ? "Modifier" : "Créer"}  une formation`}>{children}</Layout>}
    >
      {(loading === "pending" || reviewLoading === "pending") && trainingId ? (
        <div
          className="d-flex justify-content-center align-items-center mt-5"
          style={{ height: "100%" }}
        >
          <Spinner animation="border" style={{ color: "#3367cd" }} />
        </div>
      ) : (
        <Formik
          initialValues={
            trainingId
              ? trainingEdit
              : trainingEditWithoutId
              ? trainingEditWithoutId
              : training
          }
          innerRef={ref}
          onSubmit={onSubmit}
          validationSchema={yup.object().shape({
            title: yup
              .string()
              .required("Champ requis")
              .min(3, "Minimum trois caractères")
              .matches(
                /^[0-9aA-zZàâçéèêëîïôûùüÿñæœ .:'’,-?!]+[aA-zZàâçéèêëîïôûùüÿñæœ0-9 .:'’,-?!\s]+$/,
                "Ce champ n'autorise pas les caractères spéciaux"
              ),
            description: yup
              .string()
              .required("Champ requis")
              .min(3, "Minimum trois caractères")
              .matches(
                /^[0-9aA-zZàâçéèêëîïôûùüÿñæœ .:'’,-?!]+[aA-zZàâçéèêëîïôûùüÿñæœ0-9 .:'’,-?!\s]+$/,
                "Ce champ autorise uniquement les caractères "
              ),
            subject: yup.array().min(1, "Champ requis"),
              sections: yup
                  .array().of(yup.object().shape({
                      sectionTitle: yup.string().when([],{
                        is: ()=>(syncTypology.current==false),
                        then: yup.string().required('Champ requis'),
                      }),
                      sectionDuration: yup.number().when([],{
                        is: ()=>(syncTypology.current==false),
                        then: yup.number().typeError(
                      'Ce champ autorise uniquement les chiffres').required('Champ requis'),
          })
                  }))
          })}
        >
          {({ errors,
              setFieldValue,
              handleChange,
              values,
              setValues }) => {
            setVal(values);
            validation(values);
            return (
              <Form>
                {/**
                                 //@ts-ignore */}
                <NotificationAlert ref={notificationAlert} />
                <TrainingModal
                  isValid={valid.current}
                  onSubmit={onSubmit}
                  values={values}
                />
                <Row className="center-container">
                  <Col md="10">
                    <Button
                      disabled={valid.current}
                      className="btn-round-sm preview mt-3 float-end"
                      color="primary"
                      onClick={() => {
                        setFieldValue("status", "draft");
                        if (trainingId) {
                          history.push("/previewTraining");
                          dispatch(
                            trainingActions.setTraining({
                              ...values,
                              trainingId: true,
                            })
                          );
                        } else {
                          history.push("/previewTraining");
                          dispatch(
                            trainingActions.setTraining({
                              ...values,
                              trainingId: false,
                            })
                          );
                        }
                      }}
                      type="submit"
                    >
                      {status === "true" ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="me-1"
                        />
                      ) : null}
                      Aperçu de la formation
                    </Button>
                  </Col>
                </Row>
                {trainingId && trainingData.status !== "draft" && (
                  <ValidationProcess
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                )}

                <Introduction
                  user={user}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  values={values}
                  isValid={valid.current}
                  status={status}
                  setIsSubjectValid={setIsSubjectValid}
                  setSyncTypology={handleSync}
                />
                {values.typology === "async" && (
                  <AsyncSection
                    matches={matches}
                    setValues={setValues}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    values={values}
                    tag={trainingId ? "edit" : "create"}
                    status={status}
                  />
                )}
                {values.typology === "sync" && (
                  <SyncEvent
                    errors={errors}
                    setFieldValue={setFieldValue}
                    values={values}
                    setValues={setValues}
                    tag={trainingId ? "edit" : "create"}
                  />
                )}
                {isEditor && (values.typology === "sync" ||
                  values.typology === "async") && (
                  <AssignToMentor
                    user={user}
                    values={values}
                    setValues={setValues}
                    setFieldValue={setFieldValue}
                  />
                )}
                {(values.typology === "sync" ||
                  values.typology === "async") && (
                  <TrainingFeedback
                    errors={errors}
                    values={values}
                    setValues={setValues}
                  />
                )}
                {(!trainingId ||
                  (trainingId &&
                    values.process &&
                    values.process.status !== "published")) && (
                  <Row className="center-container">
                    <Col md="8">
                      <div
                        className={
                          matches.large ? "float-end" : "float-center mb-5"
                        }
                      >
                        <Button
                          className="btn-round-sm-secondary mt-3 me-2"
                          uppercase={false}
                          color="secondary"
                          disabled={
                            valid.current || values.status === "unpublished" || 
                            (values.typology === 'async' && isNqtCollaborator) || status === "true"
                          }
                          onClick={() => {
                            setFieldValue("status", "draft");
                            setVerify(true);
                          }}
                          type="submit"
                        >
                          {status === "true" && (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              className="me-1"
                            />
                          )}
                          Continuer plus tard
                        </Button>
                        <Button
                          disabled={
                            valid.current || values.status === "unpublished" ||
                            (!isEditor && !isEditorAlumni && trainingId) ||
                            status === "true"
                          }
                          className="btn-round-sm validate mt-3"
                          color="primary"
                          onClick={() => {
                            setFieldValue("status", "pending");
                            setVerify(true);
                          }}
                          type="submit"
                        >
                          {status === "true" && (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              className="me-1"
                            />
                          )}
                          {(values.typology === "sync" || isEditor || isNqtCollaborator)
                            ? "Envoyer pour publication"
                            : values.typology === "async" ||
                              (values.process &&
                                values.process.status === "pending")
                            ? "Envoyer pour validation"
                            : values.process &&
                              values.process.status === "submitted"
                            ? "Formation soumise à la publication"
                            : "Soumise à la publication"}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                )}
              </Form>
            );
          }}
        </Formik>
      )}
    </ConditionalWrapper>    
  );
};
export default TrainingContainer;
