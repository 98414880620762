import { Route, Switch, Redirect } from "react-router-dom";
//@ts-ignore
import { AsyncStorage } from "AsyncStorage";
import { useEffect, useState } from "react";

import routes from "../config/routes";
import { useAppSelector } from "../redux/hooks";
import { selectUser } from "../redux/slices/userSlice";
import { BASE_URL } from "../utils/base";

const Routes = (props: any) => {
  const { access, code } = props;
  //Init states from selector
  const user: any = useAppSelector(selectUser);

  /**
   * Get token from async storage
   */
  const getTokenFromAsyncStorage = async () => {
    const jwtToken = await AsyncStorage.getItem("token");
    return jwtToken;
  };

  const [token] = useState(async () => await getTokenFromAsyncStorage());
  const [role, setRole] = useState("MENTOR");
  const [pathname, setPathname] = useState("");

  const getPathname = async () => {
    if (access === "true" && await token) {
      if (role === "MENTOR" || role === "ALUMNI") {
        if (user.inscriptionStatus === "Subscribed") {
          setPathname("/home");
        } else {
          setPathname("/register");
        }
      } else if (role === "EDITEUR" || role === "EDITEUR-ALUMNI") {
        setPathname("/editor");
      } else if (role === "NQT_COLLABORATOR") {
        setPathname("/collaborateur");
      } else {
        setPathname("/error");
      }
    } else {
      if (access === "false" && !code) {
        window.location.href = BASE_URL + "/auth/redirect";
      }
    }
  };

  useEffect(() => {
    // user && user.role && user.role.includes('EDITOR') ? setRole('EDITEUR') : setRole('MENTOR');
    if (user && user.role) {
      user.role.includes("EDITOR")
        ? setRole("EDITEUR")
        : user.role.includes("EDITOR-ALUMNI")
          ? setRole("EDITEUR-ALUMNI")
          : user.role.includes("ALUMNI")
            ? setRole("ALUMNI")
            : user.role.includes("NQT_COLLABORATOR")
              ? setRole("NQT_COLLABORATOR")
              : setRole("MENTOR");
    }
  }, [user]);

  //Effect from first render
  useEffect(() => {
    getTokenFromAsyncStorage();
    getPathname();
  });

  return (
    <Switch>
      <Route path="/">
        {(window.location.pathname === "/" ||
          window.location.pathname === "/auth/callback" ||
          window.location.pathname === "/levelup-ui/" ||
          window.location.pathname === "/levelup-ui/home" ||
          window.location.pathname === "/levelup-dev-ui/editor" ||
          window.location.pathname === "/levelup-ui/collaborateur" ||
          window.location.pathname === "/levelup-ui/error" ||
          window.location.pathname === "/levelup-ui/register" ||
          window.location.pathname === "/levelup-dev-ui/" ||
          window.location.pathname === "/levelup-dev-ui/register" ||
          window.location.pathname === "/levelup-dev-ui/home" ||
          window.location.pathname === "/levelup-dev-ui/error" ||
          window.location.pathname === "/levelup-dev-ui/auth/callback" ||
          window.location.pathname === "/register" ||
          window.location.pathname === "/home" ||
          window.location.pathname === "/editor" ||
          window.location.pathname === "/collaborateur" ||
          window.location.pathname === "/error") && <Redirect to={pathname} />}
        {routes.map((prop, key) => {
          return (
            <Route
              path={prop.path}
              component={prop.component}
              key={key}
              exact
            />
          );
        })}
      </Route>
    </Switch>
  );
};

export default Routes;
