import { useEffect, useState, memo } from "react";
import { AiOutlineCaretDown, AiOutlineCaretUp } from "react-icons/ai";
import { Col, PopoverBody, UncontrolledPopover } from "reactstrap";
import { useAppSelector } from "../../../../redux/hooks";
import { selectUser } from "../../../../redux/slices/userSlice";

const ClubList = (props: any) => {
  //Init props
  const { handleChange, subjects, selectedClubs } = props;
  const CHOISIR_DESTINATION = "Où souhaitez-vous poster?";
  //Init states
  const profile = useAppSelector(selectUser);
  const [open, setOpen] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState<number[]>([]);
  const [clubLabel, setClubLabel] = useState(CHOISIR_DESTINATION);
  const [isExpanded, setIsExpanded] = useState<{
    id?: number;
    libelle?: string;
    name?: string;
  }>({});
  const [contentType, setContentType] = useState<string[]>([]);
  const filtredSubjects =
    profile.role.includes("ALUMNI")
      ? subjects.filter((subj: any) => subj.id !== 3 && subj.id !== 0)
      : subjects;

  useEffect(() => {
    if (selectedClubs) {
      const selected =
        selectedClubs.length < 4
          ? selectedClubs.reduce((acc: any, curr: any) => {
              subjects.map(
                (item: any) => item.name === curr && acc.push(item.id)
              );
              return acc;
            }, [])
          : [0, 1, 2, 3, 4];

      setSelectedSubject(selected);
    }
  }, [selectedClubs]);

  useEffect(() => {
    let label = selectedSubject
      .filter((subj) => subj !== 0)
      .reduce((prev, curr) => {
        return prev === "Club"
          ? `${prev} ${subjects[curr].name}`
          : `${prev}/${subjects[curr].name}`;
      }, "Club");
    label = contentType.reduce((prev, curr) => {
      return prev === "Club"
        ? `${prev} ${curr.split("-")[0]}`
        : `${prev}/${curr.split("-")[0]}`;
    }, label);
    if (selectedSubject.length === subjects.length) {
      label = "Tous les Clubs";
    }
    label === "Club" ? setClubLabel(CHOISIR_DESTINATION) : setClubLabel(label);
    const delayDebounceFn = setTimeout(() => {
      handleChange({ clubs: selectedSubject, to: contentType });
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [selectedSubject, contentType, handleChange, subjects]);

  const setSubjectSelected = (e: any) => {
    const id = e.currentTarget ? +e.currentTarget.dataset.id : e;
    if (selectedSubject.includes(id)) {
      if (id === 0 || selectedSubject.filter((sub) => sub !== 0).length === 1) {
        setSelectedSubject([]);
      } else {
        setSelectedSubject((prev) => {
          const sub = [...prev];
          sub.splice(
            selectedSubject.findIndex((val) => val === id),
            1
          );
          if (selectedSubject.findIndex((val) => val === 0) !== -1) {
            sub.splice(
              selectedSubject.findIndex((val) => val === 0),
              1
            );
          }
          return sub;
        });
      }
    } else {
      if (id === 0) {
        setSelectedSubject([0, 1, 2, 3, 4]);
      } else {
        selectedSubject.filter((subj: any) => subj.id !== 0).length === 3
          ? setSelectedSubject([0, 1, 2, 3, 4])
          : setSelectedSubject((prev) => {
              const sub = [...prev];
              sub.push(id);
              return sub;
            });
      }
    }
  };
  const setContentsSelected = (id: string) => {
    if (contentType.includes(id)) {
      setContentType((prev) => {
        const sub = [...prev];
        sub.splice(
          contentType.findIndex((val) => val === id),
          1
        );
        return sub;
      });
    } else {
      setContentType((prev) => {
        const sub = [...prev];
        if (
          contentType.includes(`${isExpanded.name}-all`) ||
          contentType.includes(`${isExpanded.name}`)
        ) {
          sub.splice(
            contentType.findIndex(
              (val) =>
                val === `${isExpanded.name}-all` || val === `${isExpanded.name}`
            ),
            1
          );
        }
        sub.push(id);
        return sub;
      });
    }
  };
  const subjectElement = filtredSubjects.map((subj: any) => {
    return subj.id === 4 || subj.id === 0 || !profile.role.includes("ALUMNI") ? (
      <li
        className="d-flex subjectList"
        key={subj.id}
        data-id={subj.id}
        onClick={setSubjectSelected.bind(this)}
      >
        {selectedSubject.includes(subj.id) ? (
          <div className="checked">
            <svg
              width="9"
              height="7"
              viewBox="0 0 9 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.75015 4.38408L1.481 3.11494C1.22087 2.85481 0.800078 2.85481 0.539947 3.11494C0.279816 3.37507 0.279816 3.79586 0.539947 4.05599L2.28161 5.79766C2.54175 6.05779 2.96254 6.05779 3.22267 5.79766L7.631 1.38933C7.89113 1.12919 7.89113 0.708403 7.631 0.448272C7.3709 0.188171 6.95018 0.188141 6.69004 0.448183C6.69001 0.448212 6.68998 0.448242 6.68995 0.448272L2.75015 4.38408Z"
                fill="#3367CD"
                stroke="#3367CD"
              />
            </svg>
          </div>
        ) : (
          <div className="unchecked">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM14 11H11V14C11 14.55 10.55 15 10 15C9.45 15 9 14.55 9 14V11H6C5.45 11 5 10.55 5 10C5 9.45 5.45 9 6 9H9V6C9 5.45 9.45 5 10 5C10.55 5 11 5.45 11 6V9H14C14.55 9 15 9.45 15 10C15 10.55 14.55 11 14 11Z"
                fill="#ECF0FB"
              />
            </svg>
          </div>
        )}

        <span className="checkBox">{subj.libelle}</span>
      </li>
    ) : (
      <li
        className="d-flex subjectList"
        key={subj.id}
        data-id={subj.id}
        onClick={() => {
          setIsExpanded(subj);
        }}
      >
        {(contentType.includes(`${subj.name}-all`) || contentType.includes(`${subj.name}`)) ? (
          <div className="checked">
            <svg
              width="9"
              height="7"
              viewBox="0 0 9 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.75015 4.38408L1.481 3.11494C1.22087 2.85481 0.800078 2.85481 0.539947 3.11494C0.279816 3.37507 0.279816 3.79586 0.539947 4.05599L2.28161 5.79766C2.54175 6.05779 2.96254 6.05779 3.22267 5.79766L7.631 1.38933C7.89113 1.12919 7.89113 0.708403 7.631 0.448272C7.3709 0.188171 6.95018 0.188141 6.69004 0.448183C6.69001 0.448212 6.68998 0.448242 6.68995 0.448272L2.75015 4.38408Z"
                fill="#3367CD"
                stroke="#3367CD"
              />
            </svg>
          </div>
        ) : (
          <div className="unchecked">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM14 11H11V14C11 14.55 10.55 15 10 15C9.45 15 9 14.55 9 14V11H6C5.45 11 5 10.55 5 10C5 9.45 5.45 9 6 9H9V6C9 5.45 9.45 5 10 5C10.55 5 11 5.45 11 6V9H14C14.55 9 15 9.45 15 10C15 10.55 14.55 11 14 11Z"
                fill="#ECF0FB"
              />
            </svg>
          </div>
        )}
        <span className="checkBox">{subj.libelle}</span>
        <div style={{ marginLeft: "auto", paddingRight: 10 }}>
          <svg
            width="7"
            height="12"
            viewBox="0 0 7 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.78016 5.27985C6.92086 5.42037 6.99999 5.61101 7.00016 5.80985V6.18985C6.99786 6.38827 6.91905 6.57813 6.78016 6.71985L1.64016 11.8499C1.54628 11.9445 1.41848 11.9978 1.28516 11.9978C1.15185 11.9978 1.02405 11.9445 0.930165 11.8499L0.220165 11.1399C0.126101 11.0477 0.0730934 10.9215 0.0730934 10.7899C0.0730934 10.6582 0.126101 10.532 0.220165 10.4399L4.67016 5.99985L0.220165 1.55985C0.125508 1.46597 0.0722656 1.33817 0.0722656 1.20485C0.0722656 1.07153 0.125508 0.943736 0.220165 0.849852L0.930165 0.149852C1.02405 0.055196 1.15185 0.00195312 1.28516 0.00195312C1.41848 0.00195312 1.54628 0.055196 1.64016 0.149852L6.78016 5.27985Z"
              fill="#BDBDBD"
            />
          </svg>
        </div>
      </li>
    );
  });

  const contentsOptions = (
    <>
      <li
        className="d-flex subjectList"
        key="All"
        data-id="All"
        onClick={() => setContentsSelected(`${isExpanded.name}-all`)}
      >
        {contentType.includes(`${isExpanded.name}-all`) ? (
          <div className="checked">
            <svg
              width="9"
              height="7"
              viewBox="0 0 9 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.75015 4.38408L1.481 3.11494C1.22087 2.85481 0.800078 2.85481 0.539947 3.11494C0.279816 3.37507 0.279816 3.79586 0.539947 4.05599L2.28161 5.79766C2.54175 6.05779 2.96254 6.05779 3.22267 5.79766L7.631 1.38933C7.89113 1.12919 7.89113 0.708403 7.631 0.448272C7.3709 0.188171 6.95018 0.188141 6.69004 0.448183C6.69001 0.448212 6.68998 0.448242 6.68995 0.448272L2.75015 4.38408Z"
                fill="#3367CD"
                stroke="#3367CD"
              />
            </svg>
          </div>
        ) : (
          <div className="unchecked">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM14 11H11V14C11 14.55 10.55 15 10 15C9.45 15 9 14.55 9 14V11H6C5.45 11 5 10.55 5 10C5 9.45 5.45 9 6 9H9V6C9 5.45 9.45 5 10 5C10.55 5 11 5.45 11 6V9H14C14.55 9 15 9.45 15 10C15 10.55 14.55 11 14 11Z"
                fill="#ECF0FB"
              />
            </svg>
          </div>
        )}

        <span className="checkBox">Mentors & Alumni</span>
      </li>
      <li
        className="d-flex subjectList"
        key="Alumni"
        data-id="Alumni"
        onClick={() => setContentsSelected(`${isExpanded.name}`)}
      >
        {contentType.includes(`${isExpanded.name}`) ? (
          <div className="checked">
            <svg
              width="9"
              height="7"
              viewBox="0 0 9 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.75015 4.38408L1.481 3.11494C1.22087 2.85481 0.800078 2.85481 0.539947 3.11494C0.279816 3.37507 0.279816 3.79586 0.539947 4.05599L2.28161 5.79766C2.54175 6.05779 2.96254 6.05779 3.22267 5.79766L7.631 1.38933C7.89113 1.12919 7.89113 0.708403 7.631 0.448272C7.3709 0.188171 6.95018 0.188141 6.69004 0.448183C6.69001 0.448212 6.68998 0.448242 6.68995 0.448272L2.75015 4.38408Z"
                fill="#3367CD"
                stroke="#3367CD"
              />
            </svg>
          </div>
        ) : (
          <div className="unchecked">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM14 11H11V14C11 14.55 10.55 15 10 15C9.45 15 9 14.55 9 14V11H6C5.45 11 5 10.55 5 10C5 9.45 5.45 9 6 9H9V6C9 5.45 9.45 5 10 5C10.55 5 11 5.45 11 6V9H14C14.55 9 15 9.45 15 10C15 10.55 14.55 11 14 11Z"
                fill="#ECF0FB"
              />
            </svg>
          </div>
        )}

        <span className="checkBox">Alumni</span>
      </li>
    </>
  );

  return (
    <>
      <div
        tabIndex={0}
        className="post_header__target"
        color={"#3367CD"}
        onClick={() => setOpen(!open)}
        onBlur={() => setOpen(false)}
        id="PopoverSubject"
      >
        <div className="d-flex pt-2">
          {!selectedSubject.includes(0) ? (
            <svg
              width="16"
              height="10"
              viewBox="0 0 16 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.6665 4.33331C11.7732 4.33331 12.6598 3.43998 12.6598 2.33331C12.6598 1.22665 11.7732 0.333313 10.6665 0.333313C9.55984 0.333313 8.6665 1.22665 8.6665 2.33331C8.6665 3.43998 9.55984 4.33331 10.6665 4.33331ZM5.33317 4.33331C6.43984 4.33331 7.3265 3.43998 7.3265 2.33331C7.3265 1.22665 6.43984 0.333313 5.33317 0.333313C4.2265 0.333313 3.33317 1.22665 3.33317 2.33331C3.33317 3.43998 4.2265 4.33331 5.33317 4.33331ZM5.33317 5.66665C3.77984 5.66665 0.666504 6.44665 0.666504 7.99998V8.99998C0.666504 9.36665 0.966504 9.66665 1.33317 9.66665H9.33317C9.69984 9.66665 9.99984 9.36665 9.99984 8.99998V7.99998C9.99984 6.44665 6.8865 5.66665 5.33317 5.66665ZM10.6665 5.66665C10.4732 5.66665 10.2532 5.67998 10.0198 5.69998C10.0332 5.70665 10.0398 5.71998 10.0465 5.72665C10.8065 6.27998 11.3332 7.01998 11.3332 7.99998V8.99998C11.3332 9.23331 11.2865 9.45998 11.2132 9.66665H14.6665C15.0332 9.66665 15.3332 9.36665 15.3332 8.99998V7.99998C15.3332 6.44665 12.2198 5.66665 10.6665 5.66665Z"
                fill="#3367CD"
              />
            </svg>
          ) : (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.00016 1.33331C4.32016 1.33331 1.3335 4.31998 1.3335 7.99998C1.3335 11.68 4.32016 14.6666 8.00016 14.6666C11.6802 14.6666 14.6668 11.68 14.6668 7.99998C14.6668 4.31998 11.6802 1.33331 8.00016 1.33331ZM7.3335 13.2866C4.70016 12.96 2.66683 10.72 2.66683 7.99998C2.66683 7.58665 2.72016 7.19331 2.80683 6.80665L6.00016 9.99998V10.6666C6.00016 11.4 6.60016 12 7.3335 12V13.2866ZM11.9335 11.5933C11.7602 11.0533 11.2668 10.6666 10.6668 10.6666H10.0002V8.66665C10.0002 8.29998 9.70016 7.99998 9.3335 7.99998H5.3335V6.66665H6.66683C7.0335 6.66665 7.3335 6.36665 7.3335 5.99998V4.66665H8.66683C9.40016 4.66665 10.0002 4.06665 10.0002 3.33331V3.05998C11.9535 3.85331 13.3335 5.76665 13.3335 7.99998C13.3335 9.38665 12.8002 10.6466 11.9335 11.5933Z"
                fill="#3367CD"
              />
            </svg>
          )}
        </div>
        <span
          className={
            clubLabel === CHOISIR_DESTINATION
              ? "label-grey-md pt-1 mx-1"
              : "label-blue-md pt-1 mx-1"
          }
        >
          {clubLabel}
        </span>
        <div className="d-flex pt-1">
          {open ? (
            <AiOutlineCaretUp color="#3367CD" className="float-end" />
          ) : (
            <AiOutlineCaretDown color="#BDBDBD" className="float-end" />
          )}
        </div>
      </div>
      <UncontrolledPopover
        placement="bottom"
        target="PopoverSubject"
        trigger="legacy"
      >
        <PopoverBody>
          <Col onFocus={() => setOpen(true)} onBlur={() => setOpen(false)}>
            {!isExpanded.id ? (
              <div style={{ width: 200, cursor: "pointer" }}>
                {subjectElement}
              </div>
            ) : (
              <>
                <div
                  className="d-flex"
                  style={{ width: 200, cursor: "pointer" }}
                  onClick={() => setIsExpanded({})}
                >
                  <div>
                    <svg
                      width="8"
                      height="12"
                      viewBox="0 0 8 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.362413 5.27985C0.221721 5.42037 0.142588 5.61101 0.142413 5.80985V6.18985C0.144717 6.38827 0.223529 6.57813 0.362413 6.71985L5.50241 11.8499C5.5963 11.9445 5.72409 11.9978 5.85741 11.9978C5.99073 11.9978 6.11853 11.9445 6.21241 11.8499L6.92241 11.1399C7.01648 11.0477 7.06948 10.9215 7.06948 10.7899C7.06948 10.6582 7.01648 10.532 6.92241 10.4399L2.47241 5.99985L6.92241 1.55985C7.01707 1.46597 7.07031 1.33817 7.07031 1.20485C7.07031 1.07153 7.01707 0.943736 6.92241 0.849852L6.21241 0.149852C6.11853 0.055196 5.99073 0.00195312 5.85741 0.00195312C5.72409 0.00195312 5.5963 0.055196 5.50241 0.149852L0.362413 5.27985Z"
                        fill="#BDBDBD"
                      />
                    </svg>
                  </div>
                  <div className="filter-label-date">{isExpanded.libelle}</div>
                </div>
                <div>{contentsOptions}</div>
              </>
            )}
            <div
              className="clearAll"
              onClick={() => {
                setSelectedSubject([]);
                setContentType([]);
              }}
            >
              réinitialiser
            </div>
          </Col>
        </PopoverBody>
      </UncontrolledPopover>
    </>
  );
};

export default memo(ClubList);
