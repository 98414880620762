import { useState, useRef, useMemo, useEffect } from "react";
import { useAppDispatch } from "../redux/hooks";
import { changeProofreadingStatus } from "../redux/slices/userSlice";
import { Nav } from "react-bootstrap";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import {
  Collapse,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Navbar,
  NavbarBrand,
  NavbarToggler,
} from "reactstrap";
import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";
import { Switch } from "./forms";
import { IoMdNotifications } from "react-icons/io";
import { useHistory } from "react-router-dom";
import "../styles/home.scss";
import getPusher from "../config/getPusher";

const LayoutHeader = (props: any) => {
  const {
    title,
    isProofreader,
    isActiveProofreader,
    setIsActiveProofreader,
    user,
    isEditor,
    isNqtCollaborator,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const history = useHistory();
  const [notifsNumber, setNotifsNumber] = useState(0);

  const apiBaseUrl = useMemo(
    () =>
      (window.location.href.includes("clubmentors")
        ? "https://"
        : "https://preprod.") + "api.nqt.fr",
    []
  );

  const appBaseUrl = useMemo(
    () =>
      (window.location.href.includes("clubmentors")
        ? "https://"
        : "https://preprod.") + "app.nqt.fr",
    []
  );

  const channel = useRef(
    getPusher(apiBaseUrl).subscribe(`private-user.${user.id}`)
  );

  const handleNotificationsIconClick = () => {
    setNotifsNumber(0);

    window.onbeforeunload = null;
    window.location.href =
      (isEditor || isNqtCollaborator ? apiBaseUrl : appBaseUrl) +
      "/notifications";
  };

  const toggle = () => setIsOpen(!isOpen);

  const dropdownToggle = () => setDropdownOpen(!dropdownOpen);

  const dispatch = useAppDispatch();
  const handleProofreaderChange = (ev: boolean) => {
    setIsActiveProofreader(!isActiveProofreader);
    dispatch(changeProofreadingStatus());
  };

  useEffect(() => {
    channel.current.bind(
      "Illuminate\\Notifications\\Events\\BroadcastNotificationCreated",
      (data: any) => {
        setNotifsNumber((prevCount) => prevCount + 1);
      }
    );
  }, [channel]);

  return (
    <Navbar
      color={"white"}
      expand="lg"
      style={{
        backgroundColor: "white",
        borderBottom: "1px solid #F0F0F0",
        height: "80px",
      }}
      className="navbar-absolute fixed-top"
    >
      <div className="container-fluid">
        <div className="navbar-wrapper pointer">
          <NavbarBrand onClick={() => history.goBack()}>
            <div>
              {title !== "Mon tableau de bord" && (
                <svg
                  style={{ marginLeft: "15px", marginRight: "15px" }}
                  width="7"
                  height="12"
                  viewBox="0 0 7 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.219836 6.71999C0.079143 6.57948 1.05039e-05 6.38884 -0.000164478 6.18999L-0.000164445 5.80999C0.00213965 5.61158 0.0809518 5.42172 0.219836 5.27999L5.35984 0.149995C5.45372 0.0553387 5.58152 0.00209606 5.71484 0.00209607C5.84815 0.00209608 5.97595 0.0553388 6.06984 0.149995L6.77984 0.859995C6.8739 0.952159 6.92691 1.07831 6.92691 1.21C6.92691 1.34169 6.8739 1.46783 6.77984 1.55999L2.32984 5.99999L6.77984 10.44C6.87449 10.5339 6.92773 10.6617 6.92773 10.795C6.92773 10.9283 6.87449 11.0561 6.77984 11.15L6.06984 11.85C5.97595 11.9447 5.84815 11.9979 5.71484 11.9979C5.58152 11.9979 5.45372 11.9447 5.35984 11.85L0.219836 6.71999Z"
                    fill="#3367CD"
                  />
                </svg>
              )}
              {title}
            </div>
          </NavbarBrand>
        </div>
        <NavbarToggler onClick={toggle}>
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
        </NavbarToggler>
        <Collapse isOpen={isOpen} navbar className="justify-content-end">
          <Nav
            navbar
            style={{
              borderLeft: "1px solid #F0F0F0",
              height: "60px",
              marginRight: "2.5rem",
            }}
          >
            {isProofreader && !isNqtCollaborator && !isEditor && (
              <>
                <Switch
                  checked={isActiveProofreader}
                  className="m-2 mt-3"
                  name={"Relecteur"}
                  value={"releter"}
                  onChange={(ev: boolean) => handleProofreaderChange(ev)}
                  offColor={"#8D8D8D"}
                  height={24}
                  width={45}
                  handleDiameter={18}
                  firstLabel={
                    isActiveProofreader
                      ? "mode relecteur activé"
                      : "mode relecteur désactivé"
                  }
                  firstLabelClassName="label-black-trainings mt-3 pt-1 me-1"
                />
                <div
                  style={{
                    borderLeft: "1px solid #F0F0F0",
                    height: "60px",
                  }}
                  className="me-3 ms-3"
                ></div>
              </>
            )}
            <Box sx={{ color: "action.active" }} className="m-2 mt-3">
              <Badge color="error" variant="dot" invisible={notifsNumber < 1}>
                <IoMdNotifications
                  size={25}
                  color="#3367cd"
                  onClick={handleNotificationsIconClick}
                  style={{ cursor: "pointer" }}
                />
              </Badge>
            </Box>

            <Dropdown nav isOpen={dropdownOpen} toggle={dropdownToggle}>
              <DropdownToggle
                nav
                style={{ marginRight: 0, padding: 0, paddingTop: "0.9rem" }}
              >
                <img alt="" className={"avatarHome"} src={user.image} />
                {!dropdownOpen && (
                  <FiChevronDown
                    size={28}
                    className="navLinkIcon"
                    color="#3367cd"
                    style={{ marginRight: 0 }}
                  />
                )}
                {dropdownOpen && (
                  <FiChevronUp
                    size={28}
                    className="navLinkIcon"
                    color="#3367cd"
                    style={{ marginRight: 0 }}
                  />
                )}
                <p>
                  <span className="d-lg-none d-md-block">Some Actions</span>
                </p>
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem
                  tag="p"
                  onClick={() => {
                    window.onbeforeunload = null;
                    window.location.href =
                      isEditor || isNqtCollaborator
                        ? apiBaseUrl +
                          "/dashboard/mon-compte/informations-personnelles"
                        : appBaseUrl + "/profile";
                  }}
                >
                  {user.nom} {user.prenom}
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Nav>
        </Collapse>
      </div>
    </Navbar>
  );
};

export default LayoutHeader;
