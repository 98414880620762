import { useEffect, useRef, useState } from "react";
import { Button, Row, Spinner } from "reactstrap";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import NotificationAlert from "react-notification-alert";
import {
  //  getAllEvents, TODO : uncomment this line when event API returns data
  getCreatedTrainings,
  getNotifications,
  getAllEvents,
  getSubjects,
  getTrainingCourses,
  retrieveTrainingDetail,
  retrieveTrainingDetailSuggestions,
  retrieveTrainingsAwaitingNotation,
  retrieveTrainingsAwaitingProofreading,
  selectAllEvents,
} from "../../redux/slices/trainingSlice";
import Agenda from "./components/Agenda";
import CreatedTraining from "./components/CreatedTraining";
import LearningInterest from "./components/LearningInterest";
import SectorFamilies from "./components/SectorFamilies";
import Highlights from "./components/Highlights";
import Todo from "./components/Todo";
import TrainingCourses from "./components/TrainingCourses";
import TrainingsAwaitingRating from "./components/TrainingsAwaitingRating";
import TrainingsAwaitingReview from "./components/TrainingsAwaitingReview";
import GamificationContainer from "./components/gamification/GamificationContainer";
import { selectUser } from "../../redux/slices/userSlice";
import GamificationService from "../../services/gamification.service";
import useHttp from "../../hooks/use-http";
import UserService from "../../services/user.service";
import { useHistory, useLocation } from "react-router";
import { MdDone } from "react-icons/md";

const PAGE = 1;
const Dashboard = (props: any) => {
  const { isProofreader, isActiveProofreader } = props;  

  const {
    sendRequest: retrieveHighlights,
    data,
    status,
  } = useHttp(GamificationService.getHighlights);
  const location: any = useLocation();

  const [fromTraining, setFromTraining] = useState(
    (location.state && location.state.fromTraining) || false
  );
  const [fromDeleteTraining, setFromDeleteTraining] = useState(
    (location.state && location.state.fromDeleteTraining) || false
  );
  const { sendRequest: resetUser, status: statusReset } = useHttp(
    UserService.resetUser
  );
  const {
    sendRequest: retrieveGamifications,
    data: gamifications,
    status: loading,
  } = useHttp(GamificationService.getGamifications);
  const notificationAlert = useRef();
  const userRole = useAppSelector(selectUser).role;
  const events = useAppSelector(selectAllEvents);
  const isAlumuni = userRole.includes("ALUMNI");
  const isEditor = userRole.includes("EDITOR");
  const dispatch = useAppDispatch();
  const history = useHistory();
  const navigateToDetails = (id: number) => {
    dispatch(retrieveTrainingDetail({ id }));
    dispatch(retrieveTrainingDetailSuggestions({ id }));
    history.push("/trainingDetail");
  };

  useEffect(() => {
    dispatch(
      getTrainingCourses({
        page: PAGE,
        formationStatus: "",
        tag: "trainingCourses",
      })
    );
    dispatch(
      getCreatedTrainings({
        page: PAGE,
        formationStatus: "",
        tag: "createdTrainings",
      })
    );
    dispatch(retrieveTrainingsAwaitingNotation({ page: PAGE }));
    dispatch(retrieveTrainingsAwaitingProofreading({ page: PAGE }));
    dispatch(getSubjects());
    dispatch(getNotifications());
    dispatch(getAllEvents());
    retrieveHighlights();
    retrieveGamifications();
  }, [dispatch, retrieveHighlights, retrieveGamifications]);

  useEffect(() => {
    fromTraining && location.state?.fromTraining &&
      //@ts-ignore
      notificationAlert.current.notificationAlert({
        place: "tr",
        message: (
          <div>
            <p className="label-black fw-bold">
              <MdDone
                size={30}
                color="#50BD89"
                style={{
                  width: 40,
                  height: 40,
                  backgroundColor: "#19875421",
                  padding: 10,
                  borderRadius: "50%",
                }}
                className="me-2"
              />
              {location.state.edit
                ? "Votre formation a été modifiée"
                : location.state.draft
                ? "Votre formation a été enregistrée"
                : location.state.published
                ? "Votre formation a été publiée"
                : "Votre formation a été envoyée pour validation"}
            </p>
          </div>
        ),
        type: "light",
        closeButton: false,
        autoDismiss: 7,
      }) &&
      setFromTraining(false);
  }, [fromTraining, setFromTraining, location]);

  useEffect(() => {
    statusReset === "completed" && history.push("/register", { tag: "reset" });
  }, [statusReset, history]);

  useEffect(() => {
    fromDeleteTraining && location.state?.fromDeleteTraining &&
      //@ts-ignore
      notificationAlert.current.notificationAlert({
        place: "tr",
        message: 
          <div>
            {" "}
            <p>Formation supprimée avec succèes</p>
          </div>,
        type: 'success',
        icon: "nc-icon nc-bell-55",
        closeButton: false,
        autoDismiss: 7,
      }) &&
      setFromDeleteTraining(false);
      window.history.replaceState({}, document.title)
  }, [fromDeleteTraining, setFromDeleteTraining, location]);

  return (
    <div className={`dashboard ${isEditor ? "mt-0" : ""}`}>
      {/* <Row className="d-flex justify-content-end">
        <Button
          className="btn-round-sm-secondary ms-2 validate mt-3 w-25"
          color="primary"
          onClick={() => {
            resetUser();
          }}
        >
          {statusReset === "pending" && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="me-1"
            />
          )}
          Réinitialiser les données de l'utilisateur
        </Button>
      </Row> */}
      <Row>
        {/**
        //@ts-ignore */}
        <NotificationAlert ref={notificationAlert} />
        <LearningInterest />
        <SectorFamilies />
        <Highlights
          data={data}
          status={status}
          navigateToDetails={navigateToDetails}
        />
      </Row>
      <GamificationContainer
        status={loading}
        isAlumuni={isAlumuni}
        gamificationData={gamifications && Object.values(gamifications)}
      />
      <Todo />
      <Agenda events={events} userRole={userRole}/>
      <Row className="pb-4">
        <TrainingsAwaitingReview
          isActiveProofreader={isActiveProofreader}
          isProofreader={isProofreader}
        />
        <TrainingsAwaitingRating
          isActiveProofreader={isActiveProofreader}
          isProofreader={isProofreader}
        />
      </Row>
      <Row className="pb-4">
        <TrainingCourses isAlumuni={isAlumuni} />
        {!isAlumuni ? <CreatedTraining /> : <></>}
      </Row>
    </div>
  );
};

export default Dashboard;
