import { Col, Card, CardBody, CardTitle, Row } from "reactstrap";
import Box from "../../../components/widget/Box";
import { useAppSelector,useAppDispatch } from "../../../redux/hooks";
import { selectUser,usersActions } from "../../../redux/slices/userSlice";
import { useState, useEffect, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import useHttp from "../../../hooks/use-http";
import UserService from "../../../services/user.service";
import NotificationAlert from "react-notification-alert";

const EditInterest = (props:any) => {
    const { show, setShow, setSelectedCategorie, selectedCategorie } = props;
    const notificationAlert = useRef(null);
    const [selection,setSelection]=useState<any>([{}]);
    const { sendRequest, status,error } = useHttp(UserService.setInteretApprentissage);
    const profile = useAppSelector(selectUser);
    const stepPercentage = 100 / profile.interetApprentissage.length;
    let percentage = 0;
    const subjectList = profile.interetApprentissage.map((cat) => {
        const selected = cat.sujets.filter((suj) => suj.selected);
        if (selected.length > 0) {
            percentage += stepPercentage;
        }
        return selected.map((select) => (
            <Box key={select.id} content={select.libelle}></Box>
        ));
    });

    const handleSubmit = () => {
        const data = profile.interetApprentissage.map((cat) => {
            const selected = cat.sujets.filter((suj) => suj.selected);
            return selected.map((select) => select.id);
        });
        sendRequest(data.flat());
    };
    const dispatch = useAppDispatch();
    const subject =
        profile.interetApprentissage.find(
            (interet) => interet.id === +selectedCategorie
        )?.sujets || [];
    const setSubjectSelected = (e: any) => {
        dispatch(
            usersActions.updateSubject({
                catId: selectedCategorie,
                subjectId: +e.currentTarget.dataset.id,
            })
        );
        setSelection([...selection,{catId: selectedCategorie,subjectId:+e.currentTarget.dataset.id}])
    };

    const subjectElement = subject.map((subj) => {
        return (
            <li
                className="d-flex col-md-3"
                key={subj.id}
                data-id={subj.id}
                style={{ width: "250px" }}
                onClick={setSubjectSelected.bind(this)}
            >
                {subj.selected ? (
                    <div className="checked">
                        <svg
                            width="9"
                            height="7"
                            viewBox="0 0 9 7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M2.75015 4.38408L1.481 3.11494C1.22087 2.85481 0.800078 2.85481 0.539947 3.11494C0.279816 3.37507 0.279816 3.79586 0.539947 4.05599L2.28161 5.79766C2.54175 6.05779 2.96254 6.05779 3.22267 5.79766L7.631 1.38933C7.89113 1.12919 7.89113 0.708403 7.631 0.448272C7.3709 0.188171 6.95018 0.188141 6.69004 0.448183C6.69001 0.448212 6.68998 0.448242 6.68995 0.448272L2.75015 4.38408Z"
                                fill="#3367CD"
                                stroke="#3367CD"
                            />
                        </svg>
                    </div>
                ) : (
                    <div className="unchecked">
                        <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM14 11H11V14C11 14.55 10.55 15 10 15C9.45 15 9 14.55 9 14V11H6C5.45 11 5 10.55 5 10C5 9.45 5.45 9 6 9H9V6C9 5.45 9.45 5 10 5C10.55 5 11 5.45 11 6V9H14C14.55 9 15 9.45 15 10C15 10.55 14.55 11 14 11Z"
                                fill="#ECF0FB"
                            />
                        </svg>
                    </div>
                )}

                <span className="checkBox">{subj.libelle}</span>
            </li>
        );
    });
    const selSelectedId = (e: any) => {
        setSelectedCategorie(+e.currentTarget.dataset.id);
    };

    const categories = profile.interetApprentissage.map((categorie) => {
        return (
            <li
                data-id={categorie.id}
                className={categorie.id === +selectedCategorie ? `list active` : `list`}
                key={categorie.id}
                onClick={selSelectedId.bind(this)}
            >
                {categorie.libelle}
            </li>
        );
    });

    //Effect change listener
    useEffect(() => {
        if (status === 'completed') {
            //@ts-ignore
            notificationAlert.current.notificationAlert({
                place: "tr",
                message:
                    error === null ? (
                        <div> {"Vos intérêts ont bien été sauvegardés"}</div>
                    ) : (
                        <div>{error}</div>
                    ),
                type:
                    error === null
                        ? "success"
                        : "danger",
                icon: "nc-icon nc-bell-55",
                closeButton: false,
                autoDismiss: 7,
            });
        }

    }, [status,error]);


    //when i select new subjects and i click on close, i update the subjects + intitialize the selection
    const renitializeValues= ()=>{
        for(let i=0;i<selection.length;i=i+1)
        {
            dispatch(
                usersActions.updateSubject({
                    catId: selection[i].catId,
                    subjectId: selection[i].subjectId,
                })
            )
        }
        setSelection([{}])
        setSelectedCategorie(profile.interetApprentissage[0]?.id)
    }

    return (
        <>
            <NotificationAlert ref={notificationAlert} />
            <Modal show={show}>
                <Modal.Body>
                    <Row className="center-container mb-4">
                        <Col md="10">
                            <Card body color="light" outline className="card p-0">
                                <CardBody className="d-flex">
                                    <Col md="12">
                                        <CardTitle className="d-flex">
                                            <div className="title">
                                                Veuillez modifier vos intérêts d’apprentissage
                                            </div>
                                            <div data-tip="Intérêts d’apprentissage">
                                                <svg
                                                    width="18"
                                                    height="18"
                                                    viewBox="0 0 18 18"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M8.99996 17.3333C4.39996 17.3333 0.666626 13.5999 0.666626 8.99992C0.666626 4.39992 4.39996 0.666584 8.99996 0.666584C13.6 0.666584 17.3333 4.39992 17.3333 8.99992C17.3333 13.5999 13.6 17.3333 8.99996 17.3333ZM9.83329 4.83325H8.16663V6.49992H9.83329V4.83325ZM9.83329 8.16658H8.16663V13.1666H9.83329V8.16658Z"
                                                        fill="#BDBDBD"
                                                    />
                                                </svg>
                                            </div>
                                            <ReactTooltip />
                                        </CardTitle>
                                        <hr className="line" />
                                        <div className="expertiseList">{subjectList.flat()}</div>
                                        <div className="category">Choisissez une catégorie</div>
                                        <div>
                                            <ul className="lists">{categories}</ul>
                                        </div>
                                        <div className="subject">
                                            Sélectionnez un sujet d’apprentissage
                                        </div>
                                        {subjectElement}
                                    </Col>
                                    <Row className="mt-20 mb-3 center-container"></Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            gap: "20px",
                        }}
                    >
                        <Button
                            className="btn-round-sm"
                            onClick={() => {
                                handleSubmit();
                                setShow(false);
                            }}
                            color="primary"
                            type="submit"
                            disabled={Math.round(percentage) === 0}
                        >
                            Sauvegarder
                        </Button>
                        <Button
                            className="btn-round-sm"
                            color="primary"
                            type="submit"
                            onClick={() => {
                                setShow(false);
                                renitializeValues()
                            }}
                        >
                            Fermer
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default EditInterest;
