import { Col, Row } from "reactstrap";
import { useAppSelector } from "../../../../redux/hooks";
import { selectTrainingCoursesCount } from "../../../../redux/slices/trainingSlice";
import TrainingCountCard from "./TrainingCountCard";

const TrainingCount = () => {
  const count = useAppSelector(selectTrainingCoursesCount);

  return (
    <Row>
      <Col md="12">
        <Row className="center-container">
          <Col md="4">
            <TrainingCountCard
              trainingLabel={"Formations"}
              trainingStatus={"en cours"}
              state={"ongoing"}
              count={count}
            />
          </Col>
          <Col md="4">
            <TrainingCountCard
              trainingLabel={"Formations"}
              trainingStatus={"enregistrées"}
              state={"registred"}
              count={count}
            />
          </Col>
          <Col md="4">
            <TrainingCountCard
              trainingLabel={"Formations"}
              trainingStatus={"terminées"}
              state={"finished"}
              count={count}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default TrainingCount;
