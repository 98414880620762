import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Box from "../../../../../components/widget/Box";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import {
  selectUser,
  usersActions,
} from "../../../../../redux/slices/userSlice";
const Interest = (props: any) => {
  const { handleSubmit } = props;
  const dispatch = useAppDispatch();
  const profile = useAppSelector(selectUser);
  const [selectedCategorie, setSelectedCategorie] = useState(
    profile.interetApprentissage[0]?.id
  );
  useEffect(() => {
    const index = profile.interetApprentissage.findIndex(
      (cat) => cat.selectedCategorie
    );
    setSelectedCategorie(
      index !== -1
        ? profile.interetApprentissage[index]?.id
        : profile.interetApprentissage[0]?.id
    );
  }, [profile.interetApprentissage]);

  const selSelectedId = (e: any) => {
    setSelectedCategorie(+e.currentTarget.dataset.id);
  };
  const stepPercentage = 100 / profile.interetApprentissage.length;
  let percentage = 0;
  const subjectList = profile.interetApprentissage.map((cat) => {
    const selected = cat.sujets.filter((suj) => suj.selected);
    if (selected.length > 0) {
      percentage += stepPercentage;
    }
    return selected.map((select) => (
      <Box key={select.id} content={select.libelle}></Box>
    ));
  });
  const categories = profile.interetApprentissage.map((categorie) => {
    return (
      <li
        data-id={categorie.id}
        className={categorie.id === +selectedCategorie ? `list active` : `list`}
        key={categorie.id}
        onClick={selSelectedId.bind(this)}
      >
        {categorie.libelle}
      </li>
    );
  });

  const subject =
    profile.interetApprentissage.find(
      (interet) => interet.id === +selectedCategorie
    )?.sujets || [];
  const setSubjectSelected = (e: any) => {
    dispatch(
      usersActions.updateSubject({
        catId: selectedCategorie,
        subjectId: +e.currentTarget.dataset.id,
      })
    );
  };
  const subjectElement = subject.map((subj) => {
    return (
      <li
        className="d-flex col-md-3"
        key={subj.id}
        data-id={subj.id}
        onClick={setSubjectSelected.bind(this)}
      >
        {subj.selected ? (
          <div className="checked">
            <svg
              width="9"
              height="7"
              viewBox="0 0 9 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.75015 4.38408L1.481 3.11494C1.22087 2.85481 0.800078 2.85481 0.539947 3.11494C0.279816 3.37507 0.279816 3.79586 0.539947 4.05599L2.28161 5.79766C2.54175 6.05779 2.96254 6.05779 3.22267 5.79766L7.631 1.38933C7.89113 1.12919 7.89113 0.708403 7.631 0.448272C7.3709 0.188171 6.95018 0.188141 6.69004 0.448183C6.69001 0.448212 6.68998 0.448242 6.68995 0.448272L2.75015 4.38408Z"
                fill="#3367CD"
                stroke="#3367CD"
              />
            </svg>
          </div>
        ) : (
          <div className="unchecked">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM14 11H11V14C11 14.55 10.55 15 10 15C9.45 15 9 14.55 9 14V11H6C5.45 11 5 10.55 5 10C5 9.45 5.45 9 6 9H9V6C9 5.45 9.45 5 10 5C10.55 5 11 5.45 11 6V9H14C14.55 9 15 9.45 15 10C15 10.55 14.55 11 14 11Z"
                fill="#ECF0FB"
              />
            </svg>
          </div>
        )}

        <span className="checkBox">{subj.libelle}</span>
      </li>
    );
  });

  return (
    <Row className="center-container mb-4">
      <Col md="10">
        <Card body color="light" outline className="card p-0">
          <CardBody className="d-flex">
            <div className="progress-profile">
              <CircularProgressbarWithChildren
                value={Math.round(percentage)}
                background={true}
                styles={{
                  // Customize the root svg element
                  root: {},
                  // Customize the path, i.e. the "completed progress"
                  path: {
                    // Path color
                    stroke: `rgba(0, 0, 255)`,
                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                    strokeLinecap: "butt",
                    // Customize transition animation
                    transition: "stroke-dashoffset 0.5s ease 0s",
                    strokeWidth: "3px",
                  },
                  // Customize the circle behind the path, i.e. the "total progress"
                  trail: {
                    // Trail color
                    stroke: "rgb(255,255, 255)",
                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                    strokeLinecap: "butt",
                    strokeWidth: "10px",
                  },
                  // Customize background - only used when the `background` prop is true
                  background: {
                    fill: "#3367CD",
                  },
                }}
              >
                <svg
                  width="14"
                  height="16"
                  viewBox="0 0 14 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.625 10.125C9.625 11.625 7.75 12.75 7.75 13.875H6.25C6.25 12.75 4.375 11.625 4.375 10.125C4.375 8.6775 5.5525 7.5 7 7.5C8.4475 7.5 9.625 8.6775 9.625 10.125ZM7.75 14.625H6.25V15.75H7.75V14.625ZM12.25 9.75C12.25 11.01 11.8075 12.1575 11.065 13.065L12.13 14.13C13.135 12.9525 13.75 11.4225 13.75 9.75C13.75 7.695 12.8275 5.8575 11.38 4.62L10.315 5.685C11.4925 6.645 12.25 8.115 12.25 9.75ZM10 3.75L7 0.75V3C3.2725 3 0.25 6.0225 0.25 9.75C0.25 11.4225 0.865 12.9525 1.87 14.13L2.935 13.065C2.1925 12.1575 1.75 11.01 1.75 9.75C1.75 6.855 4.105 4.5 7 4.5V6.75L10 3.75Z"
                    fill="white"
                  />
                </svg>
              </CircularProgressbarWithChildren>
            </div>
            <Col md="12">
              <CardTitle className="d-flex">
                <div className="title">Intérêts d’apprentissage</div>
                <div data-tip="Intérêts d’apprentissage">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.99996 17.3333C4.39996 17.3333 0.666626 13.5999 0.666626 8.99992C0.666626 4.39992 4.39996 0.666584 8.99996 0.666584C13.6 0.666584 17.3333 4.39992 17.3333 8.99992C17.3333 13.5999 13.6 17.3333 8.99996 17.3333ZM9.83329 4.83325H8.16663V6.49992H9.83329V4.83325ZM9.83329 8.16658H8.16663V13.1666H9.83329V8.16658Z"
                      fill="#BDBDBD"
                    />
                  </svg>
                </div>
                <ReactTooltip />
              </CardTitle>
              <hr className="line" />
              <div className="expertiseList">{subjectList.flat()}</div>
              <div className="category">Choisissez une catégorie</div>
              <div>
                <ul className="lists">{categories}</ul>
              </div>
              <div className="subject">
                Sélectionnez un sujet d’apprentissage
              </div>
              <ul>{subjectElement}</ul>
              <Row className="mt-20 mb-3 center-container">
                <Col xs="6" sm="3" className="position-absolute bottom-0 end-0">
                  <Button
                    className="btn-round-sm"
                    onClick={() => {
                      handleSubmit();
                    }}
                    color="primary"
                    type="submit"
                    disabled={Math.round(percentage) === 0}
                  >
                    Sauvegarder les intérêts
                  </Button>
                </Col>
              </Row>
            </Col>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default Interest;
