import { Card, Col, Progress, Row } from "reactstrap";
import { CKEditor } from "ckeditor4-react";

import { ReactComponent as PrimaryText } from "../../../../assets/img/icons/PrimaryText.svg";
import { IoMdClose } from "react-icons/io";

const AsyncText = (props: any) => {
  //Init props
  const {
    sectionKey,
    setFieldValue,
    sectionContentKey,
    values,
    addOrRemoveSectionContent,
  } = props;

  return (
    <Card body color="light" outline className="cardIntro mt-5 p-4">
      <div className="d-flex flex-row align-items-center">
        <div className="ms-2 iconOutlined">
          <PrimaryText color="#8D8D8D" />
        </div>
        <div className="d-flex flex-column  w-100 mt-3">
          <p className={"mb-0 ms-3 label-primary-nqt-md"}>Texte</p>
          <p className="pt-1 ms-3 label-grey-normal">
            Rédigez votre contenu directement dans l’éditeur.
          </p>
        </div>
        <>
          <Progress multi style={{ height: 4, width: 24 }} className="me-2">
            <Progress
              bar
              value="50"
              className={
                values.sections[sectionKey].sectionContent[sectionContentKey]
                  .html
                  ? "bg-primary"
                  : "bg-pink"
              }
            />
          </Progress>
          <IoMdClose
            size={15}
            color="#3367cd"
            onClick={() =>
              addOrRemoveSectionContent(
                "remove",
                "text",
                sectionContentKey,
                sectionKey
              )
            }
            className="ms-2 float-end pointer closeIconPrimary"
          />
        </>
      </div>

      <Row>
        <hr className="hr-text-grey p-0 mt-3"></hr>
      </Row>

      <Row>
        <Col md="12">
          <p className="label-black mt-3 mb-5">
            Rédigez votre contenu directement dans l’éditeur.
          </p>
          <CKEditor
            initData={
              values.sections[sectionKey].sectionContent[sectionContentKey].html
            }
            onChange={(event: any) =>
              setFieldValue(
                `[sections[${sectionKey}].sectionContent[${sectionContentKey}]].html`,
                event.editor.getData()
              )
            }
          />
        </Col>
      </Row>
    </Card>
  );
};
export default AsyncText;
