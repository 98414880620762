import { useEffect } from "react";
import { useMedia } from "react-media";
import { Col, Row } from "reactstrap";
import Layout from "../../../components/Layout";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectUser } from "../../../redux/slices/userSlice";
import { getCreatedTrainingsCount } from "../../../redux/slices/trainingSlice";
import CreatedTrainingsCount from "./components/CreatedTraningsCount";
import NonPublishedTrainings from "./components/NonPublishedTrainings";
import PublishedTrainings from "./components/PublishedTrainings";

const CreatedTrainingsContainer = () => {
  const GLOBAL_MEDIA_QUERIES = {
    small: "(max-width: 1150px)",
    large: "(min-width: 1151px)",
  };
  const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });

  const userRole = useAppSelector(selectUser).role;
  const isEditor = userRole.includes("EDITOR");
  const isNqtCollaborator = userRole.includes("NQT_COLLABORATOR");

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getCreatedTrainingsCount());
  }, [dispatch]);

  return (
    <Layout title="Formations Créées">
    <div className={`center-container ${isEditor ? "mt-5" : ""}`}>
      <Row>
        <Col md="12">
          <Row className="center-container">
            <CreatedTrainingsCount showToBeValidatedTrainingsBox={!isEditor && !isNqtCollaborator}/>
          </Row>
        </Col>
      </Row>
      <Row className="p-2 ml-2">
        <Col md="12">
          <Row>
            <p className="label-black-md pt-4 ml-4">Mes formations non publiées</p>
            <NonPublishedTrainings
              dispatch={dispatch}
              scrollable={matches.small}
            />
          </Row>
        </Col>
      </Row>
      <Row className="p-2 ml-2">
        <Col md="12">
          <Row>
            <p className="label-black-md ml-4">Mes formations publiées</p>
            <PublishedTrainings
              dispatch={dispatch}
              scrollable={matches.small}
            />
          </Row>
        </Col>
      </Row>
    </div>
    </Layout>
  );
};

export default CreatedTrainingsContainer;
