import moment from "moment";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import React, { useState, useEffect, useRef } from "react";
import {
  HiOutlineArrowNarrowLeft,
  HiOutlineArrowNarrowRight,
} from "react-icons/hi";
import { Calendar } from "react-date-range";
//@ts-ignore
import EllipsisText from "react-ellipsis-text";
import ReactTooltip from "react-tooltip";
import {
  Row,
  Col,
  Button,
  Input,
  UncontrolledPopover,
  PopoverBody,
} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import FiltersEditeur from "./FiltersEditeur";
import ValidatedTrainingTable from "./ValidatedTableTraining";
import { Modal } from "react-bootstrap";
import { ValidatedTrainings } from "../../../../config/training";
import EditorService from "../../../../services/editor.service";
import useHttp from "../../../../hooks/use-http";
import {
  editorActions,
  retrieveAlumniTraining,
  retrieveValidatedTraining,
  selectAlumniTraining,
  selectAlumniTrainingCount,
  selectAlumniTrainingId,
  selectValidatedTraining,
  selectValidatedTrainingCount,
} from "../../../../redux/slices/editorSlice";
import { selectUser } from "../../../../redux/slices/userSlice";
import { AiOutlineCaretDown, AiOutlineCaretUp } from "react-icons/ai";
import { ReactComponent as AvatarIcon } from '../../../../assets/img/icons/avatar-person.svg'

const ValidatedTraining = (props: any) => {
  const { scrollable, matches, tag, isAlumni } = props;
  const alumniTraining = useAppSelector(selectAlumniTraining);
  const validatedTraining = useAppSelector(selectValidatedTraining);
  const validatedCount = useAppSelector(selectValidatedTrainingCount);
  const alumniCount = useAppSelector(selectAlumniTrainingCount);
  const trainings = isAlumni ? alumniTraining : validatedTraining;
  const totalCount = isAlumni ? alumniCount : validatedCount;
  const alumniTrainingId = useAppSelector(selectAlumniTrainingId);
  const profile = useAppSelector(selectUser);
  const [pageNumber, setPage] = useState(1);
  const [modalCancelShow, setModalCancelShow] = useState(false);
  const [modalProtractShow, setModalProtractShow] = useState(false);
  const [motif, setMotif] = useState("");
  const [rowData, setRowData] = useState<ValidatedTrainings>(trainings[0]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedHour, setSelectedHour] = useState("16");
  const [selectedMinutes, setSelectedMinutes] = useState("00");
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const filtersData = {
    nom: "",
    sujets: [],
    createurs: [],
    types: [],
  };
  const [filters, setFilters] = useState(filtersData);

  const notificationAlert = useRef();
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      isAlumni
        ? dispatch(retrieveAlumniTraining({ page: pageNumber, filters }))
        : dispatch(retrieveValidatedTraining({ page: pageNumber, filters }));
    }, 100);
    return () => clearTimeout(delayDebounceFn);
  }, [dispatch, pageNumber, filters, isAlumni]);

  const {
    sendRequest,
    error: publishingError,
    status: publishingStatus,
  } = useHttp(EditorService.publishTraining);

  const {
    sendRequest: reject,
    error: rejectError,
    status: rejectStatus,
  } = useHttp(EditorService.rejectTraining);

  const {
    sendRequest: reset,
    error: resetError,
    status: resetStatus,
  } = useHttp(EditorService.resetTraining);

  const {
    sendRequest: schedule,
    error: scheduleError,
    status: scheduleStatus,
  } = useHttp(EditorService.scheduleTraining);

  const {
    sendRequest: alumniSchedule,
    error: AlumniScheduleError,
    status: AlumniScheduleStatus,
  } = useHttp(EditorService.scheduleAlumniTraining);

  const {
    sendRequest: publishAlumniTraining,
    error: alumniPublishingError,
    status: alumniPublishingStatus,
  } = useHttp(EditorService.publishAlumniTraining);

  useEffect(() => {
    if (
      publishingStatus === "completed" ||
      alumniPublishingStatus === "completed"
    ) {
      //@ts-ignore
      notificationAlert.current.notificationAlert({
        place: "tr",
        message:
          publishingError === null && alumniPublishingError === null ? (
            <div>
              {" "}
              <p>Formation publiée avec succès</p>
              <p
                onClick={() => reset(rowData?.id)}
                className="label-blue-md pointer"
              >
                annuler
              </p>
            </div>
          ) : (
            <div>{publishingError ?? alumniPublishingError}</div>
          ),
        type:
          publishingError === null && alumniPublishingError === null
            ? "success"
            : "danger",
        icon: "nc-icon nc-bell-55",
        closeButton: false,
        autoDismiss: 7,
      });
      isAlumni
        ? dispatch(retrieveAlumniTraining({ page: pageNumber, filters }))
        : dispatch(retrieveValidatedTraining({ page: pageNumber, filters }));
    }
  }, [
    publishingStatus,
    publishingError,
    alumniPublishingStatus,
    alumniPublishingError,
  ]);

  useEffect(() => {
    if (rejectStatus === "completed") {
      //@ts-ignore
      notificationAlert.current.notificationAlert({
        place: "tr",
        message:
          rejectError === null ? (
            <div> {"Formation refusée avec succès"}</div>
          ) : (
            <div>{rejectError}</div>
          ),
        type: rejectError === null ? "success" : "danger",
        icon: "nc-icon nc-bell-55",
        closeButton: false,
        autoDismiss: 7,
      });
      isAlumni
        ? dispatch(retrieveAlumniTraining({ page: pageNumber, filters }))
        : dispatch(retrieveValidatedTraining({ page: pageNumber, filters }));
    }
  }, [rejectStatus, rejectError]);

  useEffect(() => {
    if (resetStatus === "completed") {
      //@ts-ignore
      notificationAlert.current.notificationAlert({
        place: "tr",
        message:
          resetError === null ? (
            <div> {"Formation non publiée"}</div>
          ) : (
            <div>{resetError}</div>
          ),
        type: resetError === null ? "success" : "danger",
        icon: "nc-icon nc-bell-55",
        closeButton: false,
        autoDismiss: 7,
      });
      isAlumni
        ? dispatch(retrieveAlumniTraining({ page: pageNumber, filters }))
        : dispatch(retrieveValidatedTraining({ page: pageNumber, filters }));
    }
  }, [resetStatus, resetError]);

  useEffect(() => {
    if (
      scheduleStatus === "completed" ||
      AlumniScheduleStatus === "completed"
    ) {
      //@ts-ignore
      notificationAlert.current.notificationAlert({
        place: "tr",
        message:
          scheduleError === null && AlumniScheduleError === null ? (
            <div> {"Formation programmée avec succès"}</div>
          ) : (
            <div>{scheduleError ?? AlumniScheduleError}</div>
          ),
        type:
          scheduleError === null && AlumniScheduleError === null
            ? "success"
            : "danger",
        icon: "nc-icon nc-bell-55",
        closeButton: false,
        autoDismiss: 7,
      });
    }
  }, [
    scheduleStatus,
    scheduleError,
    AlumniScheduleStatus,
    AlumniScheduleError,
  ]);

  const showNotification = () => {
    //@ts-ignore
    notificationAlert.current.notificationAlert({
      place: "tr",
      message: (
        <div>{"Veuillez choisir le public destiné à suivre la formation"}</div>
      ),
      type: "danger",
      icon: "nc-icon nc-bell-55",
      closeButton: false,
      autoDismiss: 7,
    });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "",
        isSorted: false,
        width: 50,
        accessor: "creatorImage",
        Cell: (props: any) => {
          return props.row.original.createur.image ? (
            <img
              alt="profile"
              className={
                tag === 'assign'
                  ? 'avatar-profile mt-3'
                  : 'avatar-profile ms-4 mt-3'
              }
              style={{ maxWidth: 32, marginTop: '10%' }}
              src={props.row.original.createur.image}
            />
          ) : (
            <AvatarIcon
              style={{ width: 30, height: 30, borderRadius: '50%' }}
            />
          )
        },
      },
      {
        Header: "Nom de formation",
        isSorted: false,
        width: 200,
        accessor: "nom",
      },
      {
        Header: "Sujets",
        isSorted: false,
        width: 100,
        accessor: "sujets",
        Cell: (props: any) => {
          let text = "";
          props.row.original.sujets.map(
            (subject: any) =>
              (text = text !== "" ? text + ", " + subject.label : subject.label)
          );

          return (
            <>
              <EllipsisText text={text} length={20} />
              <ReactTooltip>{text}</ReactTooltip>
            </>
          );
        },
      },
      {
        Header: "Type",
        isSorted: false,
        width: 130,
        accessor: "type",
        Cell: (props: any) => {
          return (
            <svg
              width="16"
              height="18"
              viewBox="0 0 16 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.8333 2.49999H13V1.66666C13 1.20833 12.625 0.833328 12.1667 0.833328C11.7083 0.833328 11.3333 1.20833 11.3333 1.66666V2.49999H4.66667V1.66666C4.66667 1.20833 4.29167 0.833328 3.83333 0.833328C3.375 0.833328 3 1.20833 3 1.66666V2.49999H2.16667C1.24167 2.49999 0.508333 3.24999 0.508333 4.16666L0.5 15.8333C0.5 16.75 1.24167 17.5 2.16667 17.5H13.8333C14.75 17.5 15.5 16.75 15.5 15.8333V4.16666C15.5 3.24999 14.75 2.49999 13.8333 2.49999ZM13 15.8333H3C2.54167 15.8333 2.16667 15.4583 2.16667 15V6.66666H13.8333V15C13.8333 15.4583 13.4583 15.8333 13 15.8333ZM4.66667 8.33333H7.16667C7.625 8.33333 8 8.70833 8 9.16666V11.6667C8 12.125 7.625 12.5 7.16667 12.5H4.66667C4.20833 12.5 3.83333 12.125 3.83333 11.6667V9.16666C3.83333 8.70833 4.20833 8.33333 4.66667 8.33333Z"
                fill="#BDBDBD"
              />
            </svg>
          );
        },
      },
      {
        Header: "Relecteurs",
        isSorted: false,
        width: 170,
        Cell: (props: any) => {
          return props.row.original.reviewers.map(
            (relecteur: any, idx: number) => 
              relecteur.image ? (
                <img
                  key={relecteur.id}
                  alt="profile"
                  className="avatar-profile"
                  style={
                    idx === 0
                      ? { maxWidth: 32, marginTop: '10%' }
                      : {
                          maxWidth: 32,
                          marginTop: '10%',
                          position: 'relative',
                          right: `${idx * 3}%`,
                        }
                  }
                  src={relecteur.image}
                />
              ) : (
                <AvatarIcon
                  style={{
                    width: 32,
                    height: 32,
                    borderRadius: '50%',
                  }}
                />
              ),
          );
        },
      },
      {
        Header: "Validée le",
        isSorted: true,
        width: 150,
        Cell: (props: any) => {
          return props.row.original.dateValide
            ? moment(props.row.original.dateValide).format("DD/MM/YYYY")
            : "-";
        },
        accessor: "dateValide",
      },
      {
        Header: "Notes",
        isSorted: false,
        width: 100,
        accessor: "notes",
        Cell: (props: any) => {
          let text = "";
          props.row.original.notes.map(
            (note: string) => (text = text !== "" ? text + ", " + note : note)
          );

          return (
            <>
              <EllipsisText text={text} length={20} />
              <ReactTooltip>{text}</ReactTooltip>
            </>
          );
        },
      },
      {
        Header: "Public Cible",
        isSorted: false,
        width: 100,
        accessor: "diffusion",
        Cell: (props: any) => {
          return (
            <>
              <div
                tabIndex={props.row.original.id}
                className="diffusion_header__target"
                color={"#3367CD"}
                onClick={() => {
                  // profile.role.includes('EDITOR-ALUMNI')
                  setOpen(!open);
                  dispatch(
                    editorActions.updateId({ id: props.row.original.id })
                  );
                }}
                onBlur={() => setOpen(false)}
                style={profile.role.includes("EDITOR-ALUMNI") ? { pointerEvents: "none" } : {}}
                id="PopoverDiffusion"
              >
                <span className="label-blue-md pt-1 mx-1">
                  {`${props.row.original.diffusion_for ?? '-'}`}
                </span>
                <div className="d-flex pt-2">
                  {open ? (
                    <AiOutlineCaretUp color="#3367CD" className="float-end" />
                  ) : (
                    <AiOutlineCaretDown color="#3367CD" className="float-end" />
                  )}
                </div>
              </div>
              <UncontrolledPopover
                placement="bottom"
                target="PopoverDiffusion"
                trigger="legacy"
              >
                <PopoverBody>
                  <Col
                    onFocus={() => {
                      setOpen(true);
                      dispatch(
                        editorActions.updateId({ id: props.row.original.id })
                      );
                    }}
                    onBlur={() => setOpen(false)}
                  >
                    <>
                      <div
                        className="d-flex"
                        style={{ width: 200, cursor: "pointer" }}
                      >
                        <div className="filter-label-date">Publier Pour</div>
                      </div>
                      <div>
                        {" "}
                        <li
                          className="d-flex subjectList"
                          key="all"
                          data-id="all"
                          onClick={() =>
                            dispatch(
                              editorActions.updateDiffusion({
                                diffusion_for: "Mentors & Alumni",
                              })
                            )
                          }
                        >
                          {trainings.find(
                            (training) => training.id === alumniTrainingId
                          )?.diffusion_for === "Mentors & Alumni" ? (
                            <div className="checked">
                              <svg
                                width="9"
                                height="7"
                                viewBox="0 0 9 7"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M2.75015 4.38408L1.481 3.11494C1.22087 2.85481 0.800078 2.85481 0.539947 3.11494C0.279816 3.37507 0.279816 3.79586 0.539947 4.05599L2.28161 5.79766C2.54175 6.05779 2.96254 6.05779 3.22267 5.79766L7.631 1.38933C7.89113 1.12919 7.89113 0.708403 7.631 0.448272C7.3709 0.188171 6.95018 0.188141 6.69004 0.448183C6.69001 0.448212 6.68998 0.448242 6.68995 0.448272L2.75015 4.38408Z"
                                  fill="#3367CD"
                                  stroke="#3367CD"
                                />
                              </svg>
                            </div>
                          ) : (
                            <div className="unchecked">
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM14 11H11V14C11 14.55 10.55 15 10 15C9.45 15 9 14.55 9 14V11H6C5.45 11 5 10.55 5 10C5 9.45 5.45 9 6 9H9V6C9 5.45 9.45 5 10 5C10.55 5 11 5.45 11 6V9H14C14.55 9 15 9.45 15 10C15 10.55 14.55 11 14 11Z"
                                  fill="#ECF0FB"
                                />
                              </svg>
                            </div>
                          )}

                          <span className="checkBox">Mentors & Alumni</span>
                        </li>
                        <li
                          className="d-flex subjectList"
                          key="Alumni"
                          data-id="Alumni"
                          onClick={() =>
                            dispatch(
                              editorActions.updateDiffusion({
                                diffusion_for: "Alumni",
                              })
                            )
                          }
                        >
                          {trainings.find(
                            (training) => training.id === alumniTrainingId
                          )?.diffusion_for === "Alumni" ? (
                            <div className="checked">
                              <svg
                                width="9"
                                height="7"
                                viewBox="0 0 9 7"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M2.75015 4.38408L1.481 3.11494C1.22087 2.85481 0.800078 2.85481 0.539947 3.11494C0.279816 3.37507 0.279816 3.79586 0.539947 4.05599L2.28161 5.79766C2.54175 6.05779 2.96254 6.05779 3.22267 5.79766L7.631 1.38933C7.89113 1.12919 7.89113 0.708403 7.631 0.448272C7.3709 0.188171 6.95018 0.188141 6.69004 0.448183C6.69001 0.448212 6.68998 0.448242 6.68995 0.448272L2.75015 4.38408Z"
                                  fill="#3367CD"
                                  stroke="#3367CD"
                                />
                              </svg>
                            </div>
                          ) : (
                            <div className="unchecked">
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM14 11H11V14C11 14.55 10.55 15 10 15C9.45 15 9 14.55 9 14V11H6C5.45 11 5 10.55 5 10C5 9.45 5.45 9 6 9H9V6C9 5.45 9.45 5 10 5C10.55 5 11 5.45 11 6V9H14C14.55 9 15 9.45 15 10C15 10.55 14.55 11 14 11Z"
                                  fill="#ECF0FB"
                                />
                              </svg>
                            </div>
                          )}

                          <span className="checkBox">Alumnis</span>
                        </li>
                        <li
                          className="d-flex subjectList"
                          key="Mentors"
                          data-id="Mentors"
                          onClick={() =>
                            dispatch(
                              editorActions.updateDiffusion({
                                diffusion_for: "Mentors",
                              })
                            )
                          }
                        >
                          {trainings.find(
                            (training) => training.id === alumniTrainingId
                          )?.diffusion_for === "Mentors" ? (
                            <div className="checked">
                              <svg
                                width="9"
                                height="7"
                                viewBox="0 0 9 7"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M2.75015 4.38408L1.481 3.11494C1.22087 2.85481 0.800078 2.85481 0.539947 3.11494C0.279816 3.37507 0.279816 3.79586 0.539947 4.05599L2.28161 5.79766C2.54175 6.05779 2.96254 6.05779 3.22267 5.79766L7.631 1.38933C7.89113 1.12919 7.89113 0.708403 7.631 0.448272C7.3709 0.188171 6.95018 0.188141 6.69004 0.448183C6.69001 0.448212 6.68998 0.448242 6.68995 0.448272L2.75015 4.38408Z"
                                  fill="#3367CD"
                                  stroke="#3367CD"
                                />
                              </svg>
                            </div>
                          ) : (
                            <div className="unchecked">
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM14 11H11V14C11 14.55 10.55 15 10 15C9.45 15 9 14.55 9 14V11H6C5.45 11 5 10.55 5 10C5 9.45 5.45 9 6 9H9V6C9 5.45 9.45 5 10 5C10.55 5 11 5.45 11 6V9H14C14.55 9 15 9.45 15 10C15 10.55 14.55 11 14 11Z"
                                  fill="#ECF0FB"
                                />
                              </svg>
                            </div>
                          )}

                          <span className="checkBox">Mentors</span>
                        </li>
                      </div>
                    </>
                  </Col>
                </PopoverBody>
              </UncontrolledPopover>
            </>
          );
        },
      },
      {
        Header: "Actions",
        isSorted: false,
        width: 100,
        accessor: "actions",
        Cell: (props: any) => {
          return (
            <div className="d-flex">
              <div
                id="cancel"
                className="action"
                onClick={() => {
                  setRowData(props.row.original);
                  setModalCancelShow(true);
                }}
              >
                {" "}
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM2 10C2 5.58 5.58 2 10 2C11.85 2 13.55 2.63 14.9 3.69L3.69 14.9C2.63 13.55 2 11.85 2 10ZM10 18C8.15 18 6.45 17.37 5.1 16.31L16.31 5.1C17.37 6.45 18 8.15 18 10C18 14.42 14.42 18 10 18Z"
                    fill="#BDBDBD"
                  />
                </svg>
              </div>
              <div
                id="protract"
                className="action"
                onClick={() => {
                  if (
                    !props.row.original.diffusion_for ||
                    props.row.original.diffusion_for === "-"
                  ) {
                    // showNotification();
                    isAlumni? showNotification():setRowData(props.row.original);
setModalProtractShow(true);
                  } else {
                    setRowData(props.row.original);
                    setModalProtractShow(true);
                  }
                }}
              >
                {" "}
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18ZM9.78 5H9.72C9.32 5 9 5.32 9 5.72V10.44C9 10.79 9.18 11.12 9.49 11.3L13.64 13.79C13.98 13.99 14.42 13.89 14.62 13.55C14.83 13.21 14.72 12.76 14.37 12.56L10.5 10.26V5.72C10.5 5.32 10.18 5 9.78 5Z"
                    fill="#BDBDBD"
                  />
                </svg>
              </div>
              <div
                id="accept"
                className="action"
                onClick={() => {
                  if (
                    !props.row.original.diffusion_for ||
                    props.row.original.diffusion_for === "-"
                  ) {
                   isAlumni? showNotification() : publishAlumniTraining({
                    formationId: props.row.original.id,
                    diffusion_for: props.row.original.diffusion_for,
                  })
                  } else {
                    setRowData(props.row.original);
                    isAlumni
                      ? publishAlumniTraining({
                          formationId: props.row.original.id,
                          diffusion_for: props.row.original.diffusion_for,
                        })
                      : sendRequest(props.row.original.id);
                  }
                }}
              >
                {" "}
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM14.59 5.58L8 12.17L5.41 9.59L4 11L8 15L16 7L14.59 5.58Z"
                    fill="#BDBDBD"
                  />
                </svg>
              </div>
            </div>
          );
        },
      },
    ],
    [trainings]
  );
  isAlumni !== true && columns.splice(columns.length - 2, 1);
  profile.role.includes('EDITOR-ALUMNI') && columns.pop();
  return (
    <>
      {/** 
      //@ts-ignore */}
      <NotificationAlert ref={notificationAlert} />
      <FiltersEditeur
        setFilters={setFilters}
        filtersData={filtersData}
      ></FiltersEditeur>
      <Row style={{ width: "100%", marginLeft: 3 }}>
        <Col className={scrollable ? "overflow-scroll" : ""}>
          <Row>
            <ValidatedTrainingTable
              data={trainings}
              columns={columns}
              matches={matches}
              tag={tag}
              status={"validated"}
            />
          </Row>
          <Row>
            <Col md="12">
              <div className="d-flex d-column justify-content-center">
                <HiOutlineArrowNarrowLeft
                  onClick={() => pageNumber !== 1 && setPage(pageNumber - 1)}
                  className={
                    pageNumber !== 1 ? "mt-3 me-2 pointer" : "mt-3 me-2 "
                  }
                  color={pageNumber !== 1 ? "#3367CD" : "#BDBDBD"}
                  size={20}
                />
                <p className="label-grey-normal text-center pt-3 pointer opacity-50">
                  page
                </p>
                <div
                  className="ms-2 mt-3 me-2"
                  style={{
                    width: 20,
                    height: 20,
                    backgroundColor: "#ECF0FB",
                    color: "#3367CD",
                  }}
                >
                  <label className="text-center ms-1">{pageNumber}</label>
                </div>
                <p className="label-grey-normal text-center pt-3 pointer opacity-50">
                  sur {totalCount}
                </p>
                <HiOutlineArrowNarrowRight
                  onClick={() =>
                    pageNumber !== totalCount && totalCount !==0  && setPage(pageNumber + 1)
                  }
                  className={
                    pageNumber !== totalCount && totalCount !==0
                      ? "mt-3 ms-2 pointer"
                      : "mt-3 ms-2 "
                  }
                  size={20}
                  color={pageNumber !== totalCount && totalCount !==0 ? "#3367CD" : "#BDBDBD"}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal
        show={modalProtractShow}
        onHide={() => setModalProtractShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton style={{ borderBottom: 0 }}>
          <Row>
            <div className="label-black-xmd">Programmer la publication</div>
            <div className="d-flex">
            {rowData?.createur.image ? (
                <img
                  alt="profile"
                  className="avatar-profile"
                  style={{ maxWidth: 32 }}
                  src={rowData.createur.image}
                />
              ) : (
                <AvatarIcon
                  style={{
                    width: 32,
                    height: 32,
                    borderRadius: '50%',
                  }}
                />
              )}
              <div className="label-grey text-center px-2">{rowData?.nom}</div>
            </div>
          </Row>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column ">
          <Row className="d-flex flex-column align-items-center">
            <Col md="9" className="calendar-box">
              <Calendar
                date={selectedDate}
                onChange={(date) => setSelectedDate(date)}
              />
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <p className="label-black mt-3">Heure de publication</p>
              <div className="d-flex">
                <Input
                  name="startTimeHour"
                  onChange={(event) => setSelectedHour(event.target.value)}
                  type="number"
                  min="00"
                  max="23"
                  placeholder="16"
                  style={{
                    padding: 5,
                    textAlign: "center",
                    width: 45,
                    color: "#bdbdbd",
                  }}
                />
                <p
                  className="label-grey-normal mt-3 mx-1"
                  style={{ color: "#BDBDBD" }}
                >
                  h
                </p>
                <Input
                  name="startTimeMinute"
                  onChange={(event) => setSelectedMinutes(event.target.value)}
                  type="number"
                  placeholder="00"
                  max="59"
                  min="00"
                  style={{
                    padding: 5,
                    textAlign: "center",
                    width: 45,
                    color: "#bdbdbd",
                  }}
                />
              </div>
            </Col>
          </Row>
          <hr className="line"></hr>
          <Row>
            <Col className="d-flex">
              <div className="label-grey-md px-2">Le </div>
              <div className="label-blue-md">
                {format(selectedDate, "dd/MM/yyyy", {
                  locale: fr,
                })}
              </div>
              <div className="label-grey-md px-2">à </div>
              <div className="label-blue-md">
                {format(
                  new Date(
                    `${format(selectedDate, "MM/dd/yyyy", {
                      locale: fr,
                    })} ${selectedHour}:${selectedMinutes}`
                  ),
                  "HH:mm",
                  {
                    locale: fr,
                  }
                )}
              </div>
            </Col>
          </Row>
          <Row className="d-flex flex-column align-items-center">
            <Col className="d-flex justify-content-end">
              <Button
                className="btn-round-sm-secondary mt-3 me-2"
                onClick={() => setModalProtractShow(false)}
                color="secondary"
                type="submit"
              >
                Annuler
              </Button>
              <Button
                className="btn-round-sm mt-3"
                onClick={() => {
                  isAlumni
                    ? alumniSchedule({
                        formationId: rowData?.id,
                        dateHour: format(
                          new Date(
                            `${format(selectedDate, "MM/dd/yyyy", {
                              locale: fr,
                            })} ${selectedHour}:${selectedMinutes}`
                          ),
                          "yyyy-MM-dd HH:mm",
                          {
                            locale: fr,
                          }
                        ),
                        diffusion_for: rowData?.diffusion_for,
                      })
                    : schedule({
                        formationId: rowData?.id,
                        dateHour: format(
                          new Date(
                            `${format(selectedDate, "MM/dd/yyyy", {
                              locale: fr,
                            })} ${selectedHour}:${selectedMinutes}`
                          ),
                          "yyyy-MM-dd HH:mm",
                          {
                            locale: fr,
                          }
                        ),
                      });
                  setModalProtractShow(false);
                }}
                color="primary"
                type="submit"
              >
                Envoyer
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <Modal
        show={modalCancelShow}
        onHide={() => setModalCancelShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton style={{ borderBottom: 0 }}>
          <Row>
            <div className="label-black-xmd">
              Refuser la publication de cette formation
            </div>
            <div className="label-grey">
              Expliquez pourquoi cette formation a été refusée.{" "}
            </div>
          </Row>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column ">
          <Row className="d-flex flex-column ">
            <Col className="d-flex flex-column ">
              <div>
                <textarea
                  onChange={(event) => setMotif(event.target.value)}
                  style={{ width: "100%", height: "84px" }}
                  placeholder="Ex : La formation ne respecte pas ..."
                ></textarea>
              </div>
            </Col>
          </Row>
          <Row className="d-flex flex-column align-items-center">
            <Col className="d-flex justify-content-end">
              <Button
                className="btn-round-sm-secondary mt-3 me-2"
                onClick={() => setModalCancelShow(false)}
                color="secondary"
                type="submit"
              >
                Annuler
              </Button>
              <Button
                className="btn-round-sm mt-3"
                disabled={motif === ""}
                onClick={() => {
                  reject({ formationId: rowData?.id, comment: motif });
                  setModalCancelShow(false);
                }}
                color="primary"
                type="submit"
              >
                Envoyer
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ValidatedTraining;
