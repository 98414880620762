import { useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import { Card, Progress, Row } from "reactstrap";

import { ReactComponent as PrimaryDone } from "../../../../assets/img/icons/PrimaryDone.svg";

import { Switch } from "../../../../components/forms";

import AutoEval from "./AutoEval";
import QuizEval from "./QuizEval";

const AsyncEval = (props: any) => {
  //Init props
  const {
    sectionKey,
    sectionContentKey,
    values,
    setFieldValue,
    setValues,
    matches,
    addOrRemoveSectionContent,
    tag,
    status,
  } = props;

  const getValidationClassName = () => {
    const sectionContent =
      values.sections[sectionKey].sectionContent[sectionContentKey];
    let validation = false;
    if (sectionContent.autoEval) {
      sectionContent.themes.map((thematic: any) => {
        if (thematic.title && thematic.topics[0]) {
          validation = true;
        }
      });
    } else {
      for (let index = 0; index < sectionContent.questions.length; index++) {
        let counter = 0;
        if (sectionContent.questions[index].title) {
          sectionContent.questions[index].answers.map((answer: any) => {
            if (answer.isCorrect) {
              counter++;
            }
          });
          (sectionContent.questions[index].choice === "unique" &&
            counter === 1) ||
          (sectionContent.questions[index].choice === "multiple" && counter > 1)
            ? (validation = true)
            : (validation = false);
          if (!validation) {
            break;
          }
        } else {
          validation = false;
        }
      }
    }
    return validation ? "bg-primary" : "bg-pink";
  };
  const handleEvalChange = (ev: boolean) => {
    let trainingValues: any = JSON.parse(JSON.stringify(values));

    ev
      ? (trainingValues.sections[sectionKey].sectionContent[sectionContentKey] =
          {
            type: "eval",
            autoEval: true,
            themes: [{ title: "", topics: [{ title: "" }] }],
          })
      : (trainingValues.sections[sectionKey].sectionContent[sectionContentKey] =
          {
            type: "eval",
            autoEval: false,
            questions: [
              {
                title: "",
                choice: "unique",
                answers: [{ title: "", isCorrect: false }],
              },
            ],
          });
    setValues(trainingValues);
  };

  const PrepareTrainingEditValues = () => {
    let array: any = { ...values };
    const content =
      array.sections[sectionKey].sectionContent[sectionContentKey];
    content.autoEval
      ? content.themes.map((thematic: any) =>
          thematic.topics.push({ title: "" })
        )
      : content.questions.map((question: any) =>
          question.answers.push({ title: "", isCorrect: false })
        );
    setValues(array);
  };

  useEffect(() => {
    if (tag === "edit" || (tag === "edit" && status === 'false')) {
      PrepareTrainingEditValues();
    }
  }, [tag, status]);
  return (
    <Card body color="light" outline className="cardIntro mt-5 p-4">
      <div className="d-flex flex-row align-items-center">
        <div className="m-2 iconOutlined">
          <PrimaryDone color="#8D8D8D" />
        </div>{" "}
        <div className="d-flex flex-column  w-100 mt-1">
          <div className="w-auto">
            <p className={"mb-0 ms-3 label-primary-nqt-md"}>
              Module d'évaluation
            </p>
            <p className="pt-1 ms-3 label-grey-normal">Le module d'évaluation vous permet de créer un questionnaire pour tester les connaissances acquises lors de la formation. Vous pouvez sélectionner le mode quiz ou auto-évaluation</p>
            <Switch
              name={`[sections[${sectionKey}].sectionContent][${sectionContentKey}].autoEval`}
              value={
                values.sections[sectionKey].sectionContent[sectionContentKey]
                  .autoEval
              }
              onChange={(ev: boolean) => handleEvalChange(ev)}
              firstLabel="Quiz"
              firstLabelClassName={
                values.sections[sectionKey].sectionContent[sectionContentKey]
                  .autoEval
                  ? "label-grey-normal left me-2 ms-3"
                  : "label-primary-nqt-sm left me-2 ms-3"
              }
              secondLabel="Auto-évaluation"
              secondLabelClassName={
                values.sections[sectionKey].sectionContent[sectionContentKey]
                  .autoEval
                  ? "label-primary-nqt-sm right ms-2"
                  : "label-grey-normal right ms-2"
              }
            />
          </div>
        </div>
        <>
          <Progress multi style={{ height: 4, width: 24 }} className="me-2">
            <Progress bar value="50" className={getValidationClassName()} />
          </Progress>
          <IoMdClose
            size={15}
            color="#3367cd"
            onClick={() =>
              addOrRemoveSectionContent(
                "remove",
                "eval",
                sectionContentKey,
                sectionKey
              )
            }
            className="ms-2 float-end pointer closeIconPrimary"
          />
        </>
      </div>

      <Row>
        <hr className="hr-text-grey p-0 mt-3"></hr>
      </Row>

      {values.sections[sectionKey].sectionContent[sectionContentKey]
        .autoEval ? (
        <AutoEval
          sectionKey={sectionKey}
          values={values}
          sectionContentKey={sectionContentKey}
          setFieldValue={setFieldValue}
          setValues={setValues}
        />
      ) : (
        <QuizEval
          matches={matches}
          sectionKey={sectionKey}
          values={values}
          sectionContentKey={sectionContentKey}
          setFieldValue={setFieldValue}
          setValues={setValues}
        />
      )}
    </Card>
  );
};
export default AsyncEval;
