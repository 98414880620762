import { Field, Formik } from "formik";
import { useState } from "react";
import { useMedia } from "react-media";

import { Button, Card, Col, Form, Row } from "reactstrap";
type CorrectAnswerType = {
  [id: number]: number;
};
const TrainingPreviewQuizz = (props: any) => {
  const { setStepG, activeSection, data } = props;
  const sectionData = data.sections[activeSection];
  const indexcontent = sectionData.sectionContent.findIndex(
    (content:any) => content.type === "eval" && content.autoEval === false
  );
  const questions = sectionData.sectionContent[indexcontent].questions?.filter(
    (quest:any) => quest.title !== null
  );

  const GLOBAL_MEDIA_QUERIES = {
    small: "(max-width: 991px)",
    large: "(min-width: 992px)",
  };
  const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
  const sumValues = (obj: CorrectAnswerType) => Object.values(obj).reduce((a, b) => a + b);
  const questionNumber = questions?.length.toString() || "0";
  const [step, setStep] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState([1000]);
  const [validateClicked, setValidateClicked] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [correctAnswer, setCorrectAnswer] = useState(0);
  const [correctAnswersState, setCorrectAnswersState] = useState<CorrectAnswerType>({});
  const [totalCorrectAnswersState, setTotalCorrectAnswersState] = useState<CorrectAnswerType>({});
  const [isComplete, setisComplete] = useState(false);
  const question = questions && questions[step];
  const onSelectAnswer = (index: number) => {
    if (question?.choice === "unique") {
      if (selectedAnswer.includes(index)) {
        setSelectedAnswer([1000]);
      } else {
        setSelectedAnswer([index]);
      }
    } else {
      const answer = [...selectedAnswer];
      const idx = answer.findIndex((val) => val === index);
      if (idx !== -1) {
        answer.splice(idx, 1);
      } else {
        answer.push(index);
      }
      setSelectedAnswer(answer);
    }
  };
  const correctAnswers =
    question?.answers.filter((answer:any) => answer.isCorrect) || [];
  const validateAnswer = () => {
    setValidateClicked(true);
    if (question) {
      setIsCorrect(
        correctAnswers.every(
          (val:any) =>
            selectedAnswer.includes(
              question.answers?.findIndex((q:any) => q.title === val.title)
            ) &&
            ((question.choice === "multiple" &&
              selectedAnswer.length - 1 === correctAnswers.length) ||
              (question.choice === "unique" &&
                selectedAnswer.length === correctAnswers.length))
        )
      );
        selectedAnswer.forEach((element) => {
          if (element !== 1000 && question.answers[element].isCorrect) {
            setCorrectAnswer((prev) => prev + 1);
          }
        });
    }
  };

  const elementStyle = (idx: number) => {
    if (question) {
      if (!validateClicked && selectedAnswer.includes(idx)) {
        return { background: "#ECF0FB", border: "1px solid #3367CD" };
      } else if (
        validateClicked &&
        (isCorrect || question.answers[idx].isCorrect) &&
        selectedAnswer.includes(idx)
      ) {
        return { background: "#ECF0FB", border: "1px solid #3367CD" };
      } else if (
        question.choice === "unique" &&
        validateClicked &&
        !isCorrect &&
        selectedAnswer.includes(idx)
      ) {
        return {
          background: "rgba(80, 189, 137, 0.1)",
          border: "1px solid #50BD89",
        };
      } else if (
        question.choice === "multiple" &&
        validateClicked &&
        !question.answers[idx].isCorrect &&
        selectedAnswer.includes(idx)
      ) {
        return {
          background: "rgba(255, 102, 169, 0.1)",
          border: "1px solid #FF66A9",
        };
      } else if (
        question.choice === "multiple" &&
        validateClicked &&
        question.answers[idx].isCorrect &&
        !selectedAnswer.includes(idx)
      ) {
        return {
          background: "rgba(80, 189, 137, 0.1)",
          border: "1px dashed #50BD89",
        };
      } else {
        return { background: "#FFFFFF", border: "1px solid #ECF0FB" };
      }
    }
  };
  const numberStyle = (idx: number) => {
    if (!validateClicked && selectedAnswer.includes(idx)) {
      return { background: "#3367CD", color: "#FFFFFF" };
    } else if (validateClicked && isCorrect && selectedAnswer.includes(idx)) {
      return { background: "#3367CD", color: "#FFFFFF" };
    } else if (validateClicked && !isCorrect && selectedAnswer.includes(idx)) {
      return { background: "#50BD89", color: "#FFFFFF" };
    } else if (validateClicked && !isCorrect && !selectedAnswer.includes(idx)) {
      return { background: "#FF66A9", color: "#FFFFFF" };
    } else {
      return { background: "#ECF0FB", color: "#3367CD" };
    }
  };
  const resetState = (isSuiv: boolean) => {
    if (question && questions) {
      setCorrectAnswersState((prev) => {
        const cAnswer = {...prev};
        cAnswer[step] =  correctAnswer;
        return cAnswer;
      });
      setTotalCorrectAnswersState((prev) => {
        const cAnswer = {...prev};
        cAnswer[step] = correctAnswers.length;
        return cAnswer;
      });
      if (step < questions.length - 1) {
        if (isSuiv) {
          setStep((prev) => prev + 1);
        } else {
          setStep((prev) => prev - 1);
        }
        setSelectedAnswer([1000]);
        setValidateClicked(false);
        setIsCorrect(false);
        setCorrectAnswer(0);
      } else {
        setisComplete(true);
      }
    }
  };
  const stepProgress = ((100 / +questionNumber) * (step + 1)).toString() + "%";
  return !isComplete ? (
    matches.large ? (
      <Row className="questions-container">
        <Col
          md="12"
          lg="6"
          className="questions d-flex"
          style={{ padding: 70 }}
        >
          <div className="d-flex">
            <div className="question-number">
              {(step + 1).toString().padStart(2, "0")}
            </div>
            <div className="question-total">
              /{questionNumber.padStart(2, "0")}
            </div>
          </div>
          <div className="preview-center">
            <div className="question">{question?.title}</div>
            {question?.choice === "unique" && (
              <div className="type">Une seule bonne réponse est attendu</div>
            )}
            {question?.choice === "multiple" && (
              <div className="type">
                Plusieurs bonnes réponses sont attendues.{" "}
              </div>
            )}
          </div>
          <div className="quizz-progress">
            <svg
              width="265"
              height="4"
              viewBox="0 0 265 4"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                opacity="0.3"
                width={"100%"}
                height="4"
                rx="2"
                fill="#ECF0FB"
              />
              <rect width={stepProgress} height="4" rx="2" fill="white" />
            </svg>
          </div>
        </Col>
        <Col md="12" lg="6" className="answers">
          <div className="answer">
            {validateClicked && (
              <div className="result">
                <span className="label-grey-md-normal">Résultat</span>
                {isCorrect && (
                  <span className="label-grey-md">
                    Bravo ! Vous avez trouvé la bonne réponse !
                  </span>
                )}
                {!isCorrect && correctAnswer === 0 && (
                  <span className="label-grey-md">
                    Oups, vous vous êtes trompée de réponse(s).
                  </span>
                )}
                {!isCorrect && correctAnswer > 0 && (
                  <span className="label-blue-md">
                    {correctAnswer} bonne réponse sur {correctAnswers.length}
                  </span>
                )}
              </div>
            )}
            {question &&
              question.answers
                .filter((val:any) => val.title !== null)
                .map((val:any, idx: number) => {
                  return (
                    (!validateClicked ||
                      (validateClicked &&
                        (selectedAnswer.includes(idx) || val.isCorrect))) && (
                      <div
                        key={idx}
                        className="answer-element"
                        style={elementStyle(idx)}
                        onClick={onSelectAnswer.bind(this, idx)}
                      >
                        {question.choice === "unique" && (
                          <div
                            className="answer-number"
                            style={numberStyle(idx)}
                          >
                            {" "}
                            {idx + 1}{" "}
                          </div>
                        )}
                        {question.choice === "multiple" && (
                          <Formik
                            initialValues={{
                              checked: [],
                            }}
                            onSubmit={() => {}}
                          >
                            {({ values }) => (
                              <Form>
                                <Field
                                  type="checkbox"
                                  value={idx}
                                  className="ms-3 me-1 mt-3 choice"
                                  checked={selectedAnswer.includes(idx)}
                                  disabled={validateClicked}
                                  onChange={() => onSelectAnswer(idx)}
                                />
                              </Form>
                            )}
                          </Formik>
                        )}
                        <div className="answer-response">{val.title}</div>
                      </div>
                    )
                  );
                })}
          </div>
          <div className="btn-answer">
            {validateClicked && step > 0 && (
              <div
                className="btn-prec"
                onClick={() => {
                  resetState(false);
                }}
              >
                <svg
                  width="20"
                  height="14"
                  viewBox="0 0 20 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20 6.49997V7.49997C20 7.77612 19.7761 7.99997 19.5 7.99997H3.33L7.78 12.44C7.87466 12.5339 7.9279 12.6617 7.9279 12.795C7.9279 12.9283 7.87466 13.0561 7.78 13.15L7.07 13.85C6.97612 13.9446 6.84832 13.9979 6.715 13.9979C6.58168 13.9979 6.45388 13.9446 6.36 13.85L0.22 7.71997C0.0793075 7.57946 0.000175052 7.38882 0 7.18997V6.80997C0.00230401 6.61156 0.081116 6.4217 0.22 6.27997L6.36 0.149974C6.45388 0.055318 6.58168 0.0020752 6.715 0.0020752C6.84832 0.0020752 6.97612 0.055318 7.07 0.149974L7.78 0.859974C7.87406 0.952138 7.92707 1.07828 7.92707 1.20997C7.92707 1.34166 7.87406 1.46781 7.78 1.55997L3.33 5.99997H19.5C19.7761 5.99997 20 6.22383 20 6.49997Z"
                    fill="white"
                  />
                </svg>
                <div className="px-1">Question précédente</div>
              </div>
            )}
            {validateClicked && (
              <div
                className="btn-suiv"
                onClick={() => {
                  resetState(true);
                }}
              >
                <div className="px-1">Question suivante</div>
                <svg
                  width="20"
                  height="14"
                  viewBox="0 0 20 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 6.5001V7.5001C0 7.77624 0.223858 8.0001 0.5 8.0001H16.67L12.22 12.4401C12.1253 12.534 12.0721 12.6618 12.0721 12.7951C12.0721 12.9284 12.1253 13.0562 12.22 13.1501L12.93 13.8501C13.0239 13.9448 13.1517 13.998 13.285 13.998C13.4183 13.998 13.5461 13.9448 13.64 13.8501L19.78 7.7201C19.9207 7.57958 19.9998 7.38894 20 7.1901V6.8101C19.9977 6.61168 19.9189 6.42182 19.78 6.2801L13.64 0.150096C13.5461 0.0554401 13.4183 0.00219727 13.285 0.00219727C13.1517 0.00219727 13.0239 0.0554401 12.93 0.150096L12.22 0.860096C12.1259 0.95226 12.0729 1.07841 12.0729 1.2101C12.0729 1.34179 12.1259 1.46793 12.22 1.5601L16.67 6.0001H0.5C0.223858 6.0001 0 6.22395 0 6.5001Z"
                    fill="white"
                  />
                </svg>
              </div>
            )}
            {!validateClicked && (
              <div className="btn-suiv" onClick={validateAnswer}>
                <div className="px-1">Valider mes réponses</div>
              </div>
            )}
          </div>
        </Col>
      </Row>
    ) : (
      <Row>
        <Col
          md="12"
          lg="6"
          className="questions"
          style={{ padding: 70, height: "100%" }}
        >
          <div className="d-flex">
            <div className="question-number">
              {(step + 1).toString().padStart(2, "0")}
            </div>
            <div className="question-total">
              /{questionNumber.padStart(2, "0")}
            </div>
            <div style={{ paddingLeft: 10 }}>
              <svg
                width="265"
                height="4"
                viewBox="0 0 265 4"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  opacity="0.3"
                  width={"100%"}
                  height="4"
                  rx="2"
                  fill="#ECF0FB"
                />
                <rect width={stepProgress} height="4" rx="2" fill="white" />
              </svg>
            </div>
          </div>
          <div style={{ marginTop: 40 }}>
            <div className="question">{question?.title}</div>
          </div>
        </Col>
        <Col className="answers-mobile">
          <div style={{ height: 100 }}>
            {question?.choice === "unique" && (
              <div
                className="type"
                style={{ color: "#8D8D8D", textAlign: "center" }}
              >
                Une seule bonne réponse est attendu
              </div>
            )}
            {question?.choice === "multiple" && (
              <div
                className="type"
                style={{ color: "#8D8D8D", textAlign: "center" }}
              >
                Plusieurs bonnes réponses sont attendues.{" "}
              </div>
            )}
          </div>
          <Col md="12" lg="6" className="answers-mobile">
            <div className="answer">
              {validateClicked && (
                <div className="result">
                  <span className="label-grey-md-normal">Résultat</span>
                  {isCorrect && (
                    <span className="label-grey-md">
                      Bravo ! Vous avez trouvé la bonne réponse !
                    </span>
                  )}
                  {!isCorrect && correctAnswer === 0 && (
                    <span className="label-grey-md">
                      Oups, vous vous êtes trompée de réponse(s).
                    </span>
                  )}
                  {!isCorrect && correctAnswer > 0 && (
                    <span className="label-blue-md">
                      {correctAnswer} bonne réponse sur {correctAnswers.length}
                    </span>
                  )}
                </div>
              )}
              {question &&
                question.answers
                  .filter((val:any) => val.title !== null)
                  .map((val:any, idx: number) => {
                    return (
                      (!validateClicked ||
                        (validateClicked &&
                          (selectedAnswer.includes(idx) || val.isCorrect))) && (
                        <div
                          key={idx}
                          className="answer-element"
                          style={elementStyle(idx)}
                          onClick={onSelectAnswer.bind(this, idx)}
                        >
                          {question.choice === "unique" && (
                            <div
                              className="answer-number"
                              style={numberStyle(idx)}
                            >
                              {" "}
                              {idx + 1}{" "}
                            </div>
                          )}
                          {question.choice === "multiple" && (
                            <Formik
                              initialValues={{
                                checked: [],
                              }}
                              onSubmit={() => {}}
                            >
                              {({ values }) => (
                                <Form>
                                  <Field
                                    type="checkbox"
                                    value={idx}
                                    className="ms-3 me-1 mt-3 choice"
                                    checked={selectedAnswer.includes(idx)}
                                    disabled={validateClicked}
                                    onChange={() => onSelectAnswer(idx)}
                                  />
                                </Form>
                              )}
                            </Formik>
                          )}
                          <div className="answer-response">{val.title}</div>
                        </div>
                      )
                    );
                  })}
            </div>
            <div className="btn-answer-mobile">
              {validateClicked && step > 0 && (
                <div
                  className="btn-prec"
                  onClick={() => {
                    resetState(false);
                  }}
                >
                  <svg
                    width="20"
                    height="14"
                    viewBox="0 0 20 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20 6.49997V7.49997C20 7.77612 19.7761 7.99997 19.5 7.99997H3.33L7.78 12.44C7.87466 12.5339 7.9279 12.6617 7.9279 12.795C7.9279 12.9283 7.87466 13.0561 7.78 13.15L7.07 13.85C6.97612 13.9446 6.84832 13.9979 6.715 13.9979C6.58168 13.9979 6.45388 13.9446 6.36 13.85L0.22 7.71997C0.0793075 7.57946 0.000175052 7.38882 0 7.18997V6.80997C0.00230401 6.61156 0.081116 6.4217 0.22 6.27997L6.36 0.149974C6.45388 0.055318 6.58168 0.0020752 6.715 0.0020752C6.84832 0.0020752 6.97612 0.055318 7.07 0.149974L7.78 0.859974C7.87406 0.952138 7.92707 1.07828 7.92707 1.20997C7.92707 1.34166 7.87406 1.46781 7.78 1.55997L3.33 5.99997H19.5C19.7761 5.99997 20 6.22383 20 6.49997Z"
                      fill="white"
                    />
                  </svg>
                  <div className="px-1">Question précédente</div>
                </div>
              )}
              {validateClicked && (
                <div
                  className="btn-suiv"
                  onClick={() => {
                    resetState(true);
                  }}
                >
                  <div className="px-1">Question suivante</div>
                  <svg
                    width="20"
                    height="14"
                    viewBox="0 0 20 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 6.5001V7.5001C0 7.77624 0.223858 8.0001 0.5 8.0001H16.67L12.22 12.4401C12.1253 12.534 12.0721 12.6618 12.0721 12.7951C12.0721 12.9284 12.1253 13.0562 12.22 13.1501L12.93 13.8501C13.0239 13.9448 13.1517 13.998 13.285 13.998C13.4183 13.998 13.5461 13.9448 13.64 13.8501L19.78 7.7201C19.9207 7.57958 19.9998 7.38894 20 7.1901V6.8101C19.9977 6.61168 19.9189 6.42182 19.78 6.2801L13.64 0.150096C13.5461 0.0554401 13.4183 0.00219727 13.285 0.00219727C13.1517 0.00219727 13.0239 0.0554401 12.93 0.150096L12.22 0.860096C12.1259 0.95226 12.0729 1.07841 12.0729 1.2101C12.0729 1.34179 12.1259 1.46793 12.22 1.5601L16.67 6.0001H0.5C0.223858 6.0001 0 6.22395 0 6.5001Z"
                      fill="white"
                    />
                  </svg>
                </div>
              )}
              {!validateClicked && (
                <div className="btn-suiv" onClick={validateAnswer}>
                  <div className="px-1">Valider mes réponses</div>
                </div>
              )}
            </div>
          </Col>
        </Col>
      </Row>
    )
  ) : (
    <div className="container error-container">
      <Col md="12">
        <Row className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
          <Col md="8">
            <Card className="error-card">
              <div className="m-5 d-flex">
                {sumValues(correctAnswersState) * 2 > sumValues(totalCorrectAnswersState) ? (
                  <div className="score-success">{sumValues(correctAnswersState)}</div>
                ) : (
                  <div className="score-echec">{sumValues(correctAnswersState)}</div>
                )}
                <div className="totale">/{sumValues(totalCorrectAnswersState)}</div>
              </div>
              {sumValues(correctAnswersState) * 2 > sumValues(totalCorrectAnswersState) ? (
                <h5 className="score-msg">
                  Félicitations ! Vous avez fait un super score ! 🎉
                </h5>
              ) : (
                <h5 className="score-msg">
                  Oups, c’est un peu juste pour valider cette formation.
                </h5>
              )}
              <Row className="d-flex flex-column align-items-center">
                <Col md="8" className="d-flex flex-column align-items-center">
                {sumValues(correctAnswersState) * 2 > sumValues(totalCorrectAnswersState) ? (
                    <p className="label-black-nqt-sm text-center">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam.
                    </p>
                  ) : (
                    <p className="label-black-nqt-sm text-center">
                      Vous devriez la refaire une autre fois !
                    </p>
                  )}
                </Col>
              </Row>
              <Row className="d-flex flex-column align-items-center">
                <Button
                  className="btn-round-sm"
                  onClick={() => setStepG(1)}
                  color="primary"
                  type="submit"
                >
                  Revenir à l’éditeur
                </Button>
              </Row>
            </Card>
          </Col>
        </Row>
      </Col>
    </div>
  );
};

export default TrainingPreviewQuizz;
