
import React, { useEffect, useState } from "react";
import { HiOutlineArrowNarrowLeft, HiOutlineArrowNarrowRight } from "react-icons/hi";
import ReactTooltip from "react-tooltip";
import { Col, Row } from "reactstrap";
import { useAppSelector } from "../../../../redux/hooks";
import { retrieveAllUnpublishedTrainings, selectAllUnpublishedTrainings, selectAllUnpublishedTrainingsCount } from "../../../../redux/slices/trainingSlice";
//@ts-ignore
import EllipsisText from "react-ellipsis-text";
import EditorFilters from "./EditorPublishedTrainingsFilters";
import EditorTable from "./EditorTable";
import moment from "moment";
import { ReactComponent as AvatarIcon } from '../../../../assets/img/icons/avatar-person.svg'

const EditorUnpublishedTrainings = (props: any) => {
  const { scrollable, dispatch } = props;
  const trainings = useAppSelector(selectAllUnpublishedTrainings);
  const totalCount = useAppSelector(selectAllUnpublishedTrainingsCount);
  const [pageNumber, setPage] = useState(1);
  const filtersData = {
    nom: "",
    sujets: [],
    createurs: [],
    datePub: {
      start: "",
      end: "",
    },
    contenu: {
      type: [],
      typeContenu: [],
    },
    dateTenu: {
      start: "",
      end: "",
    },
  };
  const [filters, setFilters] = useState(filtersData);
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      dispatch(retrieveAllUnpublishedTrainings({ page: pageNumber, filters }));
    }, 100);
    return () => clearTimeout(delayDebounceFn);
  }, [dispatch, pageNumber, filters]);
  const columns = React.useMemo(
    () => [
      {
        Header: "",
        isSorted: false,
        width: 50,
        accessor: "creatorImage",
        Cell: (props: any) => {
          return props.row.original.createur.image ? (
            <img
              alt="profile"
              className="avatar-profile"
              style={{ maxWidth: 32, marginTop: '10%' }}
              src={props.row.original.createur.image}
            />
          ) : (
            <AvatarIcon
              style={{ width: 32, height: 32, borderRadius: '50%' }}
            />
          )
        },
      },
      {
        Header: "Nom de formation",
        isSorted: false,
        width: 120,
        accessor: "nom",
      },
      {
        Header: "Sujets",
        isSorted: false,
        width: 100,
        accessor: "sujets",
        Cell: (props: any) => {
          let text = "";
          props.row.original.sujets.map(
            (subject: any) =>
              (text = text !== "" ? text + ", " + subject.label : subject.label)
          );

          return (
            <>
              <EllipsisText text={text} length={"20"} />
              <ReactTooltip>{text}</ReactTooltip>
            </>
          );
        },
      },
      {
        Header: "Type",
        isSorted: false,
        width: 50,
        accessor: "type",
        Cell: (props: any) => {
          return (
            <svg
              width="16"
              height="18"
              viewBox="0 0 16 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.8333 2.49999H13V1.66666C13 1.20833 12.625 0.833328 12.1667 0.833328C11.7083 0.833328 11.3333 1.20833 11.3333 1.66666V2.49999H4.66667V1.66666C4.66667 1.20833 4.29167 0.833328 3.83333 0.833328C3.375 0.833328 3 1.20833 3 1.66666V2.49999H2.16667C1.24167 2.49999 0.508333 3.24999 0.508333 4.16666L0.5 15.8333C0.5 16.75 1.24167 17.5 2.16667 17.5H13.8333C14.75 17.5 15.5 16.75 15.5 15.8333V4.16666C15.5 3.24999 14.75 2.49999 13.8333 2.49999ZM13 15.8333H3C2.54167 15.8333 2.16667 15.4583 2.16667 15V6.66666H13.8333V15C13.8333 15.4583 13.4583 15.8333 13 15.8333ZM4.66667 8.33333H7.16667C7.625 8.33333 8 8.70833 8 9.16666V11.6667C8 12.125 7.625 12.5 7.16667 12.5H4.66667C4.20833 12.5 3.83333 12.125 3.83333 11.6667V9.16666C3.83333 8.70833 4.20833 8.33333 4.66667 8.33333Z"
                fill="#BDBDBD"
              />
            </svg>
          );
        },
      },
      {
        Header: "Date de publication",
        isSorted: false,
        width: 100,
        accessor: "datePub",
        Cell: (props: any) => {
          return props.row.original.datePub
            ? moment(props.row.original.datePub).format("DD/MM/YYYY")
            : "-";
        },
      },
    ],
    []
  );
  return (
    <>
      <EditorFilters setFilters={setFilters} filtersData={filtersData} />
      <Row style={{ width: '100%', marginLeft: 3 }}>
        <Col className={scrollable ? "overflow-scroll" : ""}>
          <Row>
            <EditorTable
              data={trainings}
              columns={columns}
            />
          </Row>
          <Row>
            <Col md="12">
              <div className="d-flex d-column justify-content-center">
                <HiOutlineArrowNarrowLeft
                  onClick={() => pageNumber !== 1 && setPage(pageNumber - 1)}
                  className={
                    pageNumber !== 1 ? "mt-3 me-2 pointer" : "mt-3 me-2 "
                  }
                  color={pageNumber !== 1 ? "#3367CD" : "#BDBDBD"}
                  size={20}
                />
                <p className="label-grey-normal text-center pt-3 pointer opacity-50">
                  page
                </p>
                <div
                  className="ms-2 mt-3 me-2"
                  style={{
                    width: 20,
                    height: 20,
                    backgroundColor: "#ECF0FB",
                    color: "#3367CD",
                  }}
                >
                  <label className="text-center ms-1">{pageNumber}</label>
                </div>
                <p className="label-grey-normal text-center pt-3 pointer opacity-50">
                  sur {totalCount}
                </p>
                <HiOutlineArrowNarrowRight
                  onClick={() =>
                    pageNumber !== totalCount && totalCount !==0 && setPage(pageNumber + 1)
                  }
                  className={
                    pageNumber !== totalCount && totalCount !==0 
                      ? "mt-3 ms-2 pointer"
                      : "mt-3 ms-2"
                  }
                  size={20}
                  color={pageNumber !== totalCount && totalCount !==0  ? "#3367CD" : "#BDBDBD"}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default EditorUnpublishedTrainings;
