import ErrorContainer from "../components/ErrorContainer";
import Home from "../components/Home";
import Welcome from "../components/welcome";
import CreatedTrainingsContainer from "../views/training/createdTrainings/CreatedTrainingsContainer";
import DashboardContainer from "../views/dashboard/DashboardContainer";
import TrainingContainer from "../views/training/trainingAdd/TrainingAddContainer";
import TrainingCoursesContainer from "../views/training/trainingCourses/TrainingCoursesContainer";
import TrainingsCatalogContainer from "../views/training/trainingCatalog/TrainingsCatalogContainer";
import TrainingPreviewContainer from "../views/training/trainingPreview/TrainingPreviewContainer";
import RegisterContainer from "../views/user/register/RegisterContainer";
import TrainingDetailContainer from "../views/training/trainingDetail/TrainingDetailContainer";
import TrainingLaunchContainer from "../views/training/trainingLaunch/TrainingLaunchcontainer";
import EditorDashboardContainer from "../views/editor/editorTrainings/EditorTrainingsContainer";
import Editor from "../components/Editor";
import DashboardEditor from "../views/editor/editorDashboard/EditorDashboardContainer";
import ProofreadersListingContainer from "../views/editor/proofreadersListing/ProofreadersListingContainer";
import ClubNqtContainer from "../views/club-nqt/community/ClubNqtContainer";
import PostViewContainer from "../views/club-nqt/postView/PostViewContainer";
import GamificationScaleContainer from "../views/editor/gamification/GamificationScaleContainer";
import PendingTrainingsContainer from "../views/training/pendingTrainings/PendingTrainingsContainer";
import PublishedTrainingDetailContainer from "../views/training/publishedTrainingDetails/PublishedTrainingDetailContainer";
import TutorialContainer from "../views/tutorial/TutorialContainer";
import Collaborator from "../components/NQTCollaborator";

export const routes = [
  {
    path: "/register",
    name: "User Profile",
    component: RegisterContainer,
    session: false,
  },
  {
    path: "/error",
    name: "Error",
    component: ErrorContainer,
  },
  {
    path: "/welcome",
    name: "Welcome",
    component: Welcome,
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/Editor",
    name: "Editeur",
    component: Editor,
  },
  {
    path: "/collaborateur",
    name: "Collaborateur",
    component: Collaborator,
  },
  
  {
    path: "/home/:trainingId",
    name: "Home",
    component: TrainingsCatalogContainer,
  },
  {
    path: "/createdTrainings",
    name: "Formations créées",
    component: CreatedTrainingsContainer,
  },
  {
    path: "/trainingPreview/:trainingId",
    name: "Preview",
    component: TrainingPreviewContainer,
  },
  {
    path: "/PreviewTraining",
    name: "trainingPreviewDetail",
    component: TrainingPreviewContainer,
  },
  {
    path: "/trainingLaunch/:trainingId",
    name: "Lancement de formation",
    component: TrainingLaunchContainer,
  },
  {
    path: "/trainingDetail",
    name: "Détail de formation",
    component: TrainingDetailContainer,
  },
  {
    path: "/publishedTrainingDetail",
    name: "Détail de formation",
    component: PublishedTrainingDetailContainer,
  },
  {
    path: "/publishedTrainings",
    name: "Formations publiées",
    component: EditorDashboardContainer,
  },
  {
    path: "/postView/:idPost",
    name: "Post",
    component: PostViewContainer,
  },
  {
    path: "/trainingAwaitingProofreader",
    name: "Formations En Attente De Relecteurs",
    component: PendingTrainingsContainer,
  },
  {
    path: "/trainingAwaitingRating",
    name: "Formations En Attente De Notation",
    component: PendingTrainingsContainer,
  },
  {
    path: "/CreatedCourses",
    name: "Mon parcours de formation",
    component: TrainingCoursesContainer,
  },
  {
    path: "/training",
    name: "Créer une formation",
    component: TrainingContainer,
  },
  {
    path: "/training/:trainingId",
    name: "Modifier une formation",
    component: TrainingContainer,
  },
  {
    path: "/catalogue",
    name: "Consulter le catalogue",
    component: TrainingsCatalogContainer,
  },
  {
    path: "/clubNqt",
    name: "Réseau social",
    component: ClubNqtContainer,
  },
  {
    path: "/tutorial",
    name: "Tutoriel",
    component: TutorialContainer,
  },
];
export default routes;

export const homeRoutes = [
  {
    path: "/home",
    name: "Mon tableau de bord",
    component: DashboardContainer,
  },
  {
    path: "/training",
    name: "Créer une formation",
    component: TrainingContainer,
  },
  {
    path: "/catalogue",
    name: "Consulter le catalogue",
    component: TrainingsCatalogContainer,
  },
  {
    path: "/clubNqt",
    name: "Réseau social",
    component: ClubNqtContainer,
  },

];

export const collaborateurRoutes = [
  {
    path: "/collaborateur",
    name: "Mon tableau de bord",
    component: DashboardContainer,
  },
  {
    path: "/training",
    name: "Créer une formation",
    component: TrainingContainer,
  },
  {
    path: "/catalogue",
    name: "Consulter le catalogue",
    component: TrainingsCatalogContainer,
  },
  {
    path: "/clubNqt",
    name: "Réseau social",
    component: ClubNqtContainer,
  },

];

export const EditeurRoutes = [
  {
    path: "/training-list",
    name: "Formations",
    component: DashboardEditor,
  },
  {
    path: "/proofreading-list",
    name: "Relecteurs",
    component: ProofreadersListingContainer,
  },
  {
    path: "/gamification-scale",
    name: "Barème de gamification",
    component: GamificationScaleContainer,
  },
];

export const homeRoutesMobile = [
  {
    path: "/home",
    name: "Accueil",
    component: DashboardContainer,
  },
  {
    path: "/home",
    name: "Discussion",
    component: DashboardContainer,
  },
  {
    path: "/home",
    name: "Offres",
    component: DashboardContainer,
  },
  {
    path: "/home",
    name: "Évènements",
    component: DashboardContainer,
  },

];
